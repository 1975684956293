/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl } from "@mui/base";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectLicenses } from "../licenseSlice";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { Box, FormLabel, IconButton, TextField, Tooltip } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InfoIcon from '@mui/icons-material/Info';

export default function FechaCertificado({ control, setValue, ausencia }) {
  
  const data = useSelector(selectLicenses);
  const { form } = useSelector(selectLicenses);
  const [errorForm, setMensajeError] = React.useState('');

  

  const [open, setOpen] = React.useState(false);

  const getDate = (days, startDate) => {
    const currentDate = startDate ?? moment(new Date());
    const result = currentDate.add(days, "days");
    return result;
  };

  const getDiasCertificado = (days) => {
    const fechaCertificado = new Date(form.fechaCertificado);
    const addDays = Number(days) + 1;
    fechaCertificado.setDate(fechaCertificado.getDate() + addDays);
    const fechaValid = fechaCertificado >= new Date();
    
    if (days <= 365 && days > 0 && fechaValid) {
      setMensajeError('')
      return days;
    }
    else if(days === '0')
    {
      setMensajeError('No podés crear una solicitud de licencia médica por 0 días')
    }
    else
      setMensajeError('No podés continuar con la carga de tu licencia, por favor revisá las fechas de Ausentismo y Fecha del Certificado')
  };

  useEffect(() => {
    setValue("fechaInicio", form.idNotificacion && data.allLicences.find(e => e.idNotificacion == form.idNotificacion).statusId == 3? dayjs(new Date(data.allLicences.find(e => e.idNotificacion == form.idNotificacion).startDate)) :dayjs(new Date()), { shouldTouch: true });
    setValue("fechaCertificado", moment(), { shouldTouch: true });
    setValue("licencia",form.licencia,{shouldTouch:true})
  }, [])

  
  return (
    <Box mx='auto'>
      <FormControl id="input-fecha-certificado">
        <Box mt={2}>
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Fecha de certificado
          </FormLabel>
          <Tooltip title="Si tu certificado tiene más de 7 días, deberás solicitar uno nuevo">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <Controller
            name="fechaCertificado"
            control={control}
            render={({ field: { onChange } }) => (
              <LocalizationProvider
                adapterLocale="es"
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  open={open}
                  onClose={() => setOpen(false)}
                  defaultValue={dayjs(form.fechaCertificado)}
                  renderInput={(props) => <TextField {...props} fullWidth />}
                  slotProps={{ textField: { size: "small", error: false, fullWidth: true, onClick: () => setOpen(true) } }}
                  onChange={onChange}
                  maxDate={dayjs(new Date())}
                  minDate={dayjs(getDate(-7))}
                />
              </LocalizationProvider>
            )}
          />
        </Box>
      </FormControl>

      <FormControl>
        <Box mt={2}>
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Inicio del ausentismo laboral
          </FormLabel>
          <Controller
            name="fechaInicio"
            control={control}
            render={() => (
              <LocalizationProvider
                adapterLocale="es"
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  disabled
                  defaultValue={form.idNotificacion && data.allLicences.find(e => e.idNotificacion == form.idNotificacion).statusId == 3? dayjs(new Date(data.allLicences.find(e => e.idNotificacion == form.idNotificacion).startDate)) :dayjs(new Date())}
                  value={form.idNotificacion && data.allLicences.find(e => e.idNotificacion == form.idNotificacion).statusId == 3? dayjs(new Date(data.allLicences.find(e => e.idNotificacion == form.idNotificacion).startDate)) :dayjs(new Date())}

                  renderInput={(props) => <TextField {...props} fullWidth />}
                  slotProps={{ textField: { size: "small", error: false, fullWidth: true } }}
                />
              </LocalizationProvider>
            )}
          />
        </Box>
      </FormControl>
      <FormControl>
        <Box mt={2} display="flex" flexDirection="column">
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Dias del certificado
          </FormLabel>
          <Controller
            name="diasCertificado"
            control={control}
            render={({ field: { onChange } }) => (
              <TextField
                variant="outlined"
                size="small"
                defaultValue={form.diasCertificado}
                onChange={(e) => onChange(getDiasCertificado(e.target.value))}
                type="number"
              ></TextField>
            )}
          />
        </Box>
        <FormLabel name='mensajeErrorDiasCertificado' sx={{ color: '#FF0000', display: errorForm }}>{errorForm}</FormLabel>

      </FormControl>

    </Box>
  );
}
