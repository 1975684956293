import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  selectDocuments,
  documentsMobile,
  setDocumentsMobile,
  setDocumentsMobile2
} from "../../../../files/filesSlice";
import { Button, ButtonBase } from "@mui/material";
import { postCertificadoMobile } from "../../../licenseSlice";
import { selectDatosPersonales } from "../../../../user/userSlice";
const videoConstraints = {
  width: 540,
  facingMode: "environment",
};
function obtenerFechaHoraActual() {
  const ahora = new Date();
  const dia = ahora.getDate().toString().padStart(2, "0");
  const mes = (ahora.getMonth() + 1).toString().padStart(2, "0"); // Los meses van de 0 a 11
  const año = ahora.getFullYear();
  const horas = ahora.getHours().toString().padStart(2, "0");
  const minutos = ahora.getMinutes().toString().padStart(2, "0");
  const segundos = ahora.getSeconds().toString().padStart(2, "0");

  const fechaHoraActual = `${año}-${mes}-${dia}-${horas}-${minutos}-${segundos}.jpeg`;
  return fechaHoraActual;
}

const Camera = ({docRespaldatoria, setOpenCamera, setError, cameraStream, setCameraStream, requestCameraPermission }) => {

  const checkAndRequestCameraPermission = useCallback(() => {
    const permissions = window.cordova.plugins.permissions;

    permissions.hasPermission(
      permissions.CAMERA,
      function (status) {
        if (!status.hasPermission) {
          permissions.requestPermission(
            permissions.CAMERA,
            function (status) {
              if (status.hasPermission) {
                setCameraStream(true);
                // alert("accedi a la camara");
              } else {
                // alert("cancele la camara");
              }
            },
            function (error) {
              // Error al solicitar permisos.
              // alert("cancele permisos");
            }
          );
        } else {
          setCameraStream(true);
          // alert("El usuario ya tiene permisos de cámara.");
        }
      },
      function (error) {
        // Error al verificar permisos.
      }
    );
  }, []);

  React.useEffect(() => {
    // alert('window.cordova: ', + JSON.stringify(window.cordova))
    // alert('window.cordova.plugins: ' + JSON.stringify(window.cordova.plugins))
    // alert('window.cordova.plugins.permissions: ' + JSON.stringify(window.cordova.plugins.permissions))

    if (window.cordova && window.cordova.plugins && window.cordova.plugins.permissions) {
      checkAndRequestCameraPermission();
    } else {
      // alert("cordova.plugins.permissions not available");
    }
  }, [checkAndRequestCameraPermission]);

  const { documentsMobile, documents } = useSelector(selectDocuments);
  const { datos } = useSelector(selectDatosPersonales);

  const webcamRef = useRef(null);

  const dispatch = useDispatch();
  const [captura, setCaptura] = useState(null);
  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCaptura(imageSrc);
  }, [webcamRef]);

  const confirm = () => {
    let photo = {
      CUIT: datos.cuit.toString(),
      Key: "certificado",
      Name: obtenerFechaHoraActual(),
      Image: captura.split(",")[1],
      UserId: datos.id,
      SendMeta4: false,
    };
    const files = [...documentsMobile, photo];
    const filesLenght = [...files, ...documents];
    if (filesLenght.length > 3) {
      setError({
        show: true,
        data: `Solo se pueden adjuntar hasta 3 archivos como máximo.`,
      });
      return;
    }
    if (docRespaldatoria == true)
      dispatch(setDocumentsMobile2(files));
    else
      dispatch(setDocumentsMobile(files));

    setOpenCamera(false);
  };

  return (
    <>
      <div className="m-4 flex justify-center items-center flex-col z-50">
        <button className="absolute top-1 right-1 px-[6px] text-sm z-50 rounded-full bg-[#5836ff] text-white" onClick={() => setOpenCamera(false)}>x</button>
        {captura ? (
          <>
            <img
              src={captura}
              alt="captura"
              className="w-[540px] object-cover"
            />
            <Button
              sx={{ mt: 1 }}
              onClick={() => { setCaptura(null) }}
            >
              Intentar de Nuevo
            </Button>
          </>
        ) :
          <>
            <Webcam
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              mirrored={false}
            />{" "}
            <Button
              sx={{ mt: 1 }}
              onClick={capture}
            >
              Capturar
            </Button>
          </>
        }

        {captura && (
          <>
            <Button onClick={confirm}>Confirmar</Button>
          </>
        )}
      </div>
    </>
  );
};

export default Camera;
