import {
    FormControl,
    FormLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    TextField,
    Typography,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody
} from "@mui/material";
import { fetchSaldoDisponible, selectLicenses } from "../licenseSlice";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useEffect } from "react";
import { selectDatosPersonales } from "../../user/userSlice";

export default function FechaVacaciones({ control }) {
    const { form, parameters, data } = useSelector(selectLicenses);
    const { datos } = useSelector(selectDatosPersonales);

    const fechaInicio = new Date(form.fechaInicio);
    fechaInicio.setDate(fechaInicio.getDate() - 1);

    const dispatch = useDispatch();

    const generateDate = (days, startDate) => {
        const currentDate =
            startDate === "" || startDate === undefined
                ? moment(new Date())
                : moment(startDate);
        const result = currentDate.add(days, "days");
        return result;
    };
    const handleDateChange = (newDate) => {
        if (newDate && newDate.toDate) {
            newDate = newDate.toDate();
        }
        return newDate;
    };

    useEffect(() => {
        dispatch(
            fetchSaldoDisponible({ idhr: datos.idHR, codRegimen: form.regimen })
        );
    }, []);

    return (
        <>
            <FormControl fullWidth>
                <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                    Fecha de Inicio
                </FormLabel>
                <Controller
                    name="fechaInicio"
                    control={control}
                    render={({ field: { onChange } }) => (
                        <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
                            <DatePicker
                                key="fechaInicio"
                                defaultValue={form.fechaInicio ? dayjs(form.fechaInicio):''}
                                onChange={(e) => onChange(handleDateChange(e))}
                                renderInput={(props) => <TextField {...props} fullWidth />}
                                slotProps={{ textField: { size: "small", error: false } }}
                                minDate={
                                    parameters.diasRegistroRetroactivo > 0
                                        ? dayjs(
                                            generateDate(-Number(parameters.diasRegistroRetroactivo))
                                        )
                                        : dayjs(
                                            generateDate(Number(parameters.minDiasRegistroAnticipado))
                                        )
                                }
                                maxDate={dayjs(generateDate(parameters.diasRegistroAnticipado))}
                            />
                        </LocalizationProvider>
                    )}
                />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
                <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                    Fecha de Fin
                </FormLabel>
                <Controller
                    name="fechaFin"
                    control={control}
                    render={({ field: { onChange } }) => (
                        <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
                            <DatePicker
                                key="fechaFin"
                                defaultValue={form.fechaFin ? dayjs(form.fechaFin):''}
                                renderInput={(props) => <TextField {...props} fullWidth />}
                                slotProps={{ textField: { size: "small", error: false } }}
                                onChange={onChange}
                                minDate={dayjs(form.fechaInicio)
                                }
                                maxDate={
                                    parameters.maxDiasGoce > 0
                                        ? dayjs(
                                            generateDate(
                                                parameters.maxDiasGoce,
                                                handleDateChange(fechaInicio)
                                            )
                                        )
                                        : undefined
                                }
                            />
                        </LocalizationProvider>
                    )}
                />
            </FormControl>

            <FormGroup sx={{ mt: 2 }}>
                <FormControlLabel
                    control={
                        <Controller
                            name="avisoASuperior"
                            control={control}
                            render={({ field: { onChange, checked } }) => (
                                <Checkbox checked={form.avisoASuperior} onChange={onChange} />
                            )}
                        />
                    }
                    label="Di aviso a mi superior"
                />
            </FormGroup>

            <Typography variant="body1" sx={{ mt: 2 }}>
            Recordá que para gestionar una licencia por MIA primero deberás haberle dado aviso a tu superior.
            </Typography>

            <>
                <Box mt={2}>
                    <TableContainer component={Paper}>
                        <Table
                            style={{ backgroundColor: "#deeaee" }}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Año saldo</TableCell>
                                    <TableCell align="center">Días teóricos</TableCell>
                                    <TableCell align="center">Días consumidos</TableCell>
                                    <TableCell align="center">Días pendientes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.saldoDisponible?.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <TableCell align="center">{row.anioSaldo}</TableCell>
                                        <TableCell align="center">{row.diasTeoricos}</TableCell>
                                        <TableCell align="center">{row.diasConsumidos}</TableCell>
                                        <TableCell align="center">{row.diasPendientes}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </>

        </>
    );
}
