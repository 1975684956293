import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  IconButton,
  TablePagination,
  Tooltip,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { auditadasBtnAction, fetchAuditProLicenciasAsync } from "../occupationalMedicineSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../utils";
import { useNavigate } from "react-router-dom";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  CheckCircleOutline,
  Cancel,
  HelpOutline,
  Block,
} from "@mui/icons-material";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const { allLicences } = useSelector((state) => state.auditProLicence);
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={
          page >= Math.ceil(count / rowsPerPage) - 1 ||
          allLicences?.length === 0
        }
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function AuditProTable({
  activeButton,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  filters,
  pageAct,
  activeBtn,
  setPendientes,
  pendientes,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { datos, perfilActual } = useSelector((state) => state.usuario);
  const { allLicences, totalCount, auditadasBtn } = useSelector(
    (state) => state.auditProLicence
  );
  
  const initialColumns = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      headerClassName: "bg-[#5836FF] text-white",
      align: "center",
      renderCell: (params) => (
        <span
          onClick={() =>
           { 
            if(!pendientes){
              const filtersParsed = JSON.stringify(filters);
              sessionStorage.setItem('filters',filtersParsed)
            }else{
              sessionStorage.clear();
            }
            navigate(
              `/occupational-medicine/${params.row.id}?pageAct=${page}&activeBtn=${activeButton}&licenseTypeId=${params.row.idTipoLicencia}&queryPendientes=${pendientes}`
            )}
          }
          className="underline hover:scale-125 duration-75 cursor-pointer text-xs text-center my-[10px]"
        >
          {params.row.id}
        </span>
      ),
      flex: 1,
      maxWidth: 60,
    },
    {
      field: "idhr",
      headerName: "ID HR",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-center text-xs  my-[10px]">
          {params.row.idhr}
        </span>
      ),
      flex: 1,
    },
    {
      field: "cuit",
      headerName: "CUIL",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span
          onClick={() =>
            navigate(
              `/occupational-medicine/history/${params.row.id}?pageAct=${page}&activeBtn=${activeButton}&licenseTypeId=${params.row.idTipoLicencia}`
            )
          }
          className="underline hover:scale-125 duration-75 cursor-pointer text-xs text-center my-[10px]"
        >
          {`${params.row.cuit?.toString().substring(0, 2)}-${params.row.cuit?.toString().substring(2, 10)}-${params.row.cuit?.toString().substring(10)}`}
        </span>
      ),
      flex: 1,
      minWidth: 120,
    },
    {
      field: "lastName",
      headerName: "Apellido/s",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center  my-[10px]">
          {params.row.lastName?.includes(" ")
            ? params.row.lastName?.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.lastName}
        </span>
      ),
      flex: 1,
      minWidth: 90,
    },
    {
      field: "firstName",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Nombre/s",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center  my-[10px]">
          {params.row.firstName?.includes(" ")
            ? params.row.firstName?.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.firstName}
        </span>
      ),
      flex: 1,
      minWidth: 90,
    },
    {
      field: "subtitle",
      headerName: "Licencia",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center mx-[10px] my-[10px]">
          {params.row.subtitle.includes(" ")
            ? params.row.subtitle.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.subtitle}
        </span>
      ),
      flex: 1,
    },
    {
      field: "diagnostico",
      headerName: "Diagnóstico",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      minWidth: 160,
      renderCell: (params) => (
        <span className="text-xs text-center  my-[10px]">
          {params.row.diagnostico?.includes(" ")
            ? params.row.diagnostico
                .split(" ")
                .reduce((result, word, index) => {
                  if (index % 3 === 0) {
                    result.push([]);
                  }
                  result[result.length - 1].push(word);
                  return result;
                }, [])
                .map((wordGroup, index) => (
                  <React.Fragment key={index}>
                    {wordGroup.join(" ")}
                    <br />
                  </React.Fragment>
                ))
            : params.row.diagnostico}
        </span>
      ),
      flex: 1,
    },
    {
      field: "asignacion",
      headerName: "Asignación",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center  my-[10px]">
          {!params.row?.asignacion
            ? "-"
            : params.row?.asignacion?.includes(" ")
            ? params.row?.asignacion?.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row?.asignacion}
        </span>
      ),
      flex: 1,
      minWidth: 100,
    },
    {
      field: "destino",
      headerName: "Destino",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center  my-[10px]">
          {!params.row?.destino
            ? "-"
            : params.row?.destino?.includes(" ")
            ? params.row?.destino?.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row?.destino}
        </span>
      ),
      flex: 1,
    },
    {
      field: "startDate",
      headerClassName: "bg-[#5836FF] text-white",
      headerName:
        activeButton === 3 ? (
          <div className="text-xs text-center w-full">
            Fecha <br /> de <br /> Ausentismo
          </div>
        ) : (
          "Inicio"
        ),
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs text-center">
          {formatDate(params.row.startDate)}
        </span>
      ),
      flex: 1,
    },
    {
      field: "fechaNuevaCitacion",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center w-full">
          Nueva <br /> Citación
        </div>
      ),
      renderCell: (params) => (
        <span className="text-xs text-center">
          {formatDate(params.row.fechaNuevaCitacion)}
        </span>
      ),
      sortable: false,
      align: "center",
      flex: 1,
    },
    {
      field: "diasCertificado",
      headerClassName: "bg-[#5836FF] text-white",
      headerName:
        activeButton === 3 ? (
          <div className="text-xs text-center w-full">
            Dias <br /> Pedidos
          </div>
        ) : (
          <div className="text-xs text-center w-full">
            Dias del <br /> Certificado
          </div>
        ),
      sortable: false,
      align: "center",
      renderCell: (params) =>
        params.row.diasCertificado ? (
          <span className="text-xs text-center">
            {params.row.diasCertificado} día
            {params.row.diasCertificado > 1 ? "s" : null}
          </span>
        ) : null,
      flex: 1,
      minWidth: 90,
    },
    {
      field: "diasAprobados",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center">
          Dias <br /> Aprobados
        </div>
      ),
      sortable: false,
      align: "center",
      renderCell: (params) =>
        params.row.diasAprobados ? (
          <span className="text-xs text-center">
            {params.row.diasAprobados} día
            {params.row.diasAprobados > 1 ? "s" : null}
          </span>
        ) : null,
      flex: 1,
    },
    {
      field: "statusId",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Estado",
      align: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.status}>
          <span>
            {params.row.statusId === 0 ? (
              <Block style={{ color: "gray" }} />
            ) : params.row.statusId === 1 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 2 ? (
              <PendingActionsIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 3 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 4 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 5 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 6 ? (
              <DoDisturbOnIcon style={{ color: "red" }} />
            ) : params.row.statusId === 7 ? (
              <CheckCircleOutline style={{ color: "green" }} />
            ) : params.row.statusId === 8 ? (
              <Cancel style={{ color: "red" }} />
            ) : params.row.statusId === 9 ? (
              <Block style={{ color: "gray" }} />
            ) : params.row.statusId === 10 ? (
              <CheckCircleOutline style={{ color: "green" }} />
            ) : params.row.statusId === 11 ? (
              <Cancel style={{ color: "red" }} />
            ) : params.row.statusId === 12 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 13 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 14 ? (
              <HelpOutline style={{ color: "orange" }} />
            ) : params.row.statusId === 15 ? (
              <CheckCircleOutline style={{ color: "green" }} />
            ) : params.row.statusId === 16 ? (
              <CheckCircleOutline style={{ color: "orange" }} />
            ) : params.row.statusId === 17 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : null}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "tomadaPor",
      align: "center",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center">
          Tomada <br /> por
        </div>
      ),
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <span className="text-xs text-center my-2">
          {params.row.tomadaPor?.includes(" ")
            ? params.row.tomadaPor.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.tomadaPor}
        </span>
      ),
    },
  ];
  const [columns, setColumns] = React.useState(initialColumns);

  useEffect(() => {
    if (activeButton === 0) {
      setColumns(
        initialColumns.filter((column) => column.field !== "fechaNuevaCitacion")
      );
    }
    if (activeButton === 1) {
      setColumns(
        initialColumns.filter(
          (column) =>
            column.field !== "diagnostico" &&
            column.field !== "asignacion" &&
            column.field !== "destino" &&
            column.field !== "startdate" &&
            column.field !== "fechaNuevaCitacion" &&
            column.field !== "diasCertificado" &&
            column.field !== "diasAprobados"
        )
      );
    }
    if (activeButton === 2) {
      setColumns(
        initialColumns.filter(
          (column) =>
            column.field !== "asignacion" &&
            column.field !== "destino" &&
            column.field !== "fechaNuevaCitacion" &&
            column.field !== "diasCertificado" &&
            column.field !== "diasAprobados"
        )
      );
    }
    if (activeButton === 3) {
      setColumns(
        initialColumns.filter((column) => column.field !== "subtitle")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeButton, page]);

  const handleChangePage = (event, newPage) => {
    const filterArray = [
      { code: "perfil", description: perfilActual.idPerfil },
    ];
    for (const key in filters) {
      let value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        if (value === true) {
          value = "1";
        }
        if (key === "cuil") {
          value = value.replace(/[^0-9]/g, "");
        }
        filterArray.push({ code: key, description: value });
      }
    }
    setPage(newPage);
    dispatch(
      fetchAuditProLicenciasAsync({
        id: datos.id,
        filters: filterArray,
        btn: activeButton,
        pageNumber: newPage,
        pageSize: rowsPerPage,
        pendientes,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      fetchAuditProLicenciasAsync({
        id: datos.id,
        filters: [{ code: "perfil", description: perfilActual.idPerfil }],
        btn: activeButton,
        pageNumber: 0,
        pageSize: parseInt(event.target.value, 10),
        pendientes,
      })
    );
  };
  

  if (allLicences?.length === 0)
    return (
      <>
        <div className="flex gap-1 p-[10px]">
          <button
            onClick={() => {
              sessionStorage.clear();
              dispatch(auditadasBtnAction(false))
              setPendientes(true)}}
            className={`${
              pendientes ? "bg-[#5836FF] font-medium" : "bg-[#5836ff53]"
            } p-2 rounded-t-md text-white`}
          >
            Pendientes
          </button>
          <button
            onClick={() => {
              sessionStorage.clear();
              dispatch(auditadasBtnAction(true))
              setPendientes(false)
            }}
            className={`${
              !pendientes ? "bg-[#5836FF] font-medium" : "bg-[#5836ff53]"
            } p-2 rounded-t-md text-white`}
          >
            Auditadas
          </button>
        </div>
        <p className="text-xl text-center mt-5">
          {auditadasBtn ? "Es necesario utilizar filtros para iniciar la búsqueda" : "No existen licencias para los filtros seleccionados"}
        </p>
      </>
    );
  return (
    <div style={{ width: "100%", padding: "10px" }}>
      <div className="relative w-full">
        <div className="w-full flex ">
          <TablePagination
            className="border-0 p-0 ml-auto"
            rowsPerPageOptions={[
              5,
              10,
              25,
              { label: "All", value: totalCount },
            ]}
            colSpan={8}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Licencias por pagina"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          <p className="flex w-[100px] mx-2 items-center">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[28px] h-[28px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
        </div>
        <div className="w-[100%] relative">
          <div className="absolute -top-9 left-0 flex gap-1">
            <button
              onClick={() => {
                sessionStorage.clear();
                dispatch(auditadasBtnAction(false))
                setPendientes(true)}}
              className={`${
                pendientes ? "bg-[#5836FF] font-medium" : "bg-[#5836ff53]"
              } p-2 rounded-t-md text-white`}
            >
              Pendientes
            </button>
            <button
              onClick={() => {
                sessionStorage.clear();
                dispatch(auditadasBtnAction(true))
                setPendientes(false)}}
              className={`${
                !pendientes ? "bg-[#5836FF] font-medium" : "bg-[#5836ff53]"
              } p-2 rounded-t-md text-white`}
            >
              Auditadas
            </button>
          </div>
          {allLicences?.length === 0 ? (
            <p className="text-xl text-center mt-5">
              No existen licencias para los filtros seleccionados
            </p>
          ) : (
            <DataGrid
              className="border-0"
              getRowHeight={() => "auto"}
              hideFooterRowCount
              rows={allLicences}
              columns={columns}
              hideFooterPagination
            />
          )}
        </div>
        <div className="w-full flex">
          <TablePagination
            className="border-0 p-0 ml-auto"
            rowsPerPageOptions={[
              5,
              10,
              25,
              { label: "All", value: totalCount },
            ]}
            colSpan={8}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Licencias por pagina"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          <p className="flex w-[100px] mx-2 items-center">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[28px] h-[28px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AuditProTable;
