import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { selectDocuments, setDocuments } from "../../files/filesSlice";
import { selectSettings } from "../../parameters/parametersSlice";
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomAlert from "../../alert";
export default function DropDown() {

    const { extensions } = useSelector(selectSettings);
    const [error, setError] = useState({ show: false, data: '' });
    
    const dispatch = useDispatch();
    const { documents } = useSelector(selectDocuments);

    function sizeValidator(file) {
        if (file.size > 5000000) {
            return {
                code: "file-too-large",
                message: `Tamaño de archivo excedido`
            };
        }
        return null
    }

    const onDrop = useCallback(acceptedFiles => {
        const files = [...documents, ...acceptedFiles]
        if(files.length > 5)
        {
            setError({ show: true, data: `Solo se pueden adjuntar hasta 5 archivos como máximo.` })
            return;
        }
        dispatch(setDocuments(files))
    }, [documents])


    const removeFile = file => () => {
        const newFiles = [...documents]
        newFiles.splice(newFiles.indexOf(file), 1)
        dispatch(setDocuments(newFiles))
    }

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        accept: JSON.parse(extensions),
        validator: sizeValidator
    });

    useEffect(() => {
        const validExtns = JSON.parse(extensions);
        var propiedades = Object.getOwnPropertyNames(validExtns);
        let valid = '';
        propiedades.forEach(element => {
            valid += validExtns[element]
        });
        fileRejections.map(({ errors }) => {
            setError({ show: true, data: errors[0]?.message })
        });
    }, [fileRejections]);

    const handleErrorClose = () => {
        setError({ show: false });
    };

    return (
        <>
            <div
                {...getRootProps({ className: "dropzone" })}
                className="border-2 rounded-md py-4 pb-2 px-2"
            >
                <input {...getInputProps()} />
                <p className="text-center text-[#5836ff]">
                    Arrastrar archivos para adjuntar ó{" "}
                    <button
                        className="p-2 bg-[#5836ff] text-white rounded-md shadow-sm cursor-pointer"
                    >
                        Explorar en disco
                    </button>
                </p>
            </div>
            <div>
                {
                    documents?.map(doc =>
                        <>
                            <Box mt={2} display='flex' justifyContent='space-evenly'>
                                <Typography key={doc.id}>
                                    <InsertDriveFileIcon />
                                    {doc.name}
                                </Typography>
                                <Button onClick={removeFile(doc)}><DeleteForeverIcon sx={{ color: '#ff3d8b' }} /></Button>
                            </Box>
                        </>
                    )
                }
            </div>
            <CustomAlert error={error} severity='error' onClose={handleErrorClose} />
        </>
    )
}