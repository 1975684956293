import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop , Alert, Snackbar } from "@mui/material";
import {onClose} from './alertSlice'
const AlertGlobal = () => {
  const {message,severity,open} = useSelector((state) => state.alerts);

  const dispatch = useDispatch();

  React.useEffect(()=>{
    open && setTimeout(()=>{
      dispatch(onClose())
    },6000)
  },[open])

  return (
    alert && (
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ paddingTop: 15, maxWidth: 500 }}
        autoHideDuration={6000}
        onClose={()=>dispatch(onClose())}
      >
        <Alert
          onClose={()=>dispatch(onClose())}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    )
  );
};

export default AlertGlobal;
