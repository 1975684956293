/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl } from "@mui/base";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import CustomAlert from "../../alert";
import DocRequerido from "../doc-respaldatoria/requerido";
import DocRespaldatoriaAdicional from "../doc-respaldatoria/adicional";
import { selectDocuments } from "../../files/filesSlice";
import { useSelector } from "react-redux";
import DNIFamiliarRequerido from "../doc-respaldatoria/dni-familiar";

export default function Certificado({ setValue }) {
  const [error, setError] = React.useState({ show: false, data: "" });
  const { documents, dniFamiliar, documentsMobile, dniFamiliarMobile } =
    useSelector(selectDocuments);
  const { form } = useSelector((state) => state.licencia);
  const handleClose = () => {
    setError({ show: false });
  };

  const [cameraStream, setCameraStream] = useState(null);

  const requestCameraPermission = useCallback(async () => {
    try {
      await navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" } })
        .then((x) => {
          setCameraStream(true);
        });
    } catch (error) {
      setCameraStream(false);
      console.error("Error al obtener los permisos de la cámara:", error);
    }
  }, []);
  useEffect(() => {
    let documentsLength = documents.length + documentsMobile.length;
    let dniLength = dniFamiliar.length + dniFamiliarMobile.length;
    let certificadoValido = documentsLength > 0;
    let dniFamiliarValido = dniLength > 0;
    setValue("certificado", certificadoValido, { shouldTouch: true });
    setValue("dniFamiliar", dniFamiliarValido, { shouldTouch: true });
  }, [documents, dniFamiliar, documentsMobile, dniFamiliarMobile]);

  return (
    <>
      {/* <TestMobile/> */}
      <FormControl id="input-certificado" fullWidth>
        <Box mt={2} mb={3}>
          <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
            Certificado médico (obligatorio)
          </Typography>
          <DocRequerido cameraStream={cameraStream} setCameraStream={setCameraStream} requestCameraPermission={requestCameraPermission}/>
        </Box>
      </FormControl>
      <Divider />
      {form?.tipoLicencia?.code === "MEF" && (
        <>
          <FormControl id="input-certificado" fullWidth>
            <Box mt={2} mb={3}>
              <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
                DNI Familia frente y dorso (obligatorio)
              </Typography>
              <DNIFamiliarRequerido cameraStream={cameraStream} setCameraStream={setCameraStream} requestCameraPermission={requestCameraPermission}/>
            </Box>
          </FormControl>
          <Divider />
        </>
      )}
      <FormControl id="input-adicionales" fullWidth>
        <Box mt={3}>
          <Typography color="#5836ff" sx={{ mr: 1 }}>
            Otros documentos
          </Typography>
          <DocRespaldatoriaAdicional cameraStream={cameraStream} setCameraStream={setCameraStream} requestCameraPermission={requestCameraPermission}/>
        </Box>
      </FormControl>
      <CustomAlert error={error} severity="error" onClose={handleClose} />
    </>
  );
}
