import { ContentPaste } from "@mui/icons-material";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import * as React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import {
  handleConfirmModal,
  handlechangeProfileModal,
  selectModals,
} from "../modalSlice";
import { getTokenBukeala, selectDatosPersonales } from "../../user/userSlice";

const ChangeProfile = () => {
  const usuario = useSelector(selectDatosPersonales);
  const modals = useSelector(selectModals);
  const dispatch = useDispatch();
  const theme = createTheme({
    typography: {
      h1: {
        fontSize: 20,
        fontWeight: 600,
        color: "#00D6D1",
      },
      h2: {
        fontSize: 20,
      },
      label: {
        fontWeight: 500,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          // Personaliza el estilo de todos los botones
          root: {
            borderRadius: "4px", // Cambia el borde
            fontWeight: "bold", // Cambia el peso de la fuente
          },
        },
      },
    },
  });

  const selectProfile = (perfil) => {
    if (
      perfil.idPerfil === "TURNERA_NIVEL_01" ||
      perfil.idPerfil === "TURNERA_NIVEL_02" ||
      perfil.idPerfil === "TURNERA_NIVEL_03" ||
      perfil.idPerfil === "TURNERA_NIVEL_04" ||
      perfil.idPerfil === "AT_PROFESIONAL"
    ) {
      dispatch(
        getTokenBukeala({
          cuit: usuario.datos.cuit,
          rol: perfil.idPerfil,
        })
      ).then((x) => {
        dispatch(
          handleConfirmModal({
            open: true,
            title: `Estas queriendo cambiar el perfil a ${perfil.nombrePerfil}`,
            content:
              "Recordá que al cambiar el perfil se perderá toda la información que no hayas guardado. ¿Deseás continuar?",
            bukeala: x.payload.content,
            showCancel: true,
            perfil: perfil,
          })
        );
        //x.payload.content,
      });
    }else{
      dispatch(
        handleConfirmModal({
          open: true,
          title: `Estas queriendo cambiar el perfil a ${perfil.nombrePerfil}`,
          content:
            "Recordá que al cambiar el perfil se perderá toda la información que no hayas guardado. ¿Deseás continuar?",
          redirectTo: perfil.rootPath,
          showCancel: true,
          perfil: perfil,
        })
      );
    }
    dispatch(handlechangeProfileModal({ open: false }));
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog
          sx={{ zIndex: 99999999999999 }}
          style={{ zIndex: 999999999999999 }}
          open={modals.changeProfileModal.open}
          onClose={() => dispatch(handlechangeProfileModal({ open: true }))}
          maxWidth="sm"
        >
          <DialogTitle
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h1">
              {usuario.datos.nombre == undefined
                ? ""
                : usuario.datos.nombre.concat(" ", usuario.datos.apellido)}
            </Typography>
            <Typography variant="p">Seleccioná tu perfil</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Container sx={{ display: "flex", flexDirection: "column" }}>
                <MenuList>
                  {typeof usuario.perfiles.filter === "function" ? (
                    usuario.perfiles
                      .filter(
                        (perfil) =>
                          perfil.idPerfil !== usuario.perfilActual.idPerfil
                      )
                      .map((perfil, i) => (
                        <span>
                          <MenuItem
                            key={"perfilId" + i}
                            onClick={() => {
                              selectProfile(perfil);
                            }}
                          >
                            <ListItemText style={{ color: "#000" }}>
                              {perfil.nombrePerfil}
                            </ListItemText>
                          </MenuItem>
                          <Divider />
                        </span>
                      ))
                  ) : (
                    <span></span>
                  )}
                </MenuList>
              </Container>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={() =>
                dispatch(handlechangeProfileModal({ open: false }))
              }
              sx={{ textTransform: "none", width: "200px" }}
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};
export default ChangeProfile;
