import { Box, Button, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAdmLicenciasAsync } from "../administrativeSlice";

//TODO: hacer themes
const sxProps = {
  menu: {
    display: "flex",
    alignItems:"center",
    flexWrap: "wrap",
    bgcolor: "#f5f5f5",
    p: "10px",
    title: {
      ml: "30px",
      color: "#5836ff",
      h5: {
        fontWeight: "bolder",
      },
    },
    buttons: {
      display: "flex",
      justifyContent: "center",
      ml: "auto",
      mr: "30px",
      button: {
        textTransform: "none",
      },
    },
  },
};
export default function AdministrativeMenu({ activeButton, setActiveButton , setFilters , initialFilters , autocompleteRef , setPage , page , rowsPerPage , setRowsPerPage}) {
  const dispatch = useDispatch();
  const { datos, perfilActual } = useSelector((state) => state.usuario);

  const handleButtonChange = (e) => {
    setPage(0)
    setRowsPerPage(5)
    setFilters(initialFilters);
    const ele = autocompleteRef.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
    if (e.target.name === "pendientes") {
      setActiveButton(0);
      dispatch(
        fetchAllAdmLicenciasAsync({
          id: datos.idHR,
          filters: [
            { code: "activeButton", description: "pendientes" },
            { code: "perfil", description: perfilActual.idPerfil },
          ],
          pageNumber:0,
          pageSize:5
        })
      );
    } else {
      setActiveButton(1);
      dispatch(
        fetchAllAdmLicenciasAsync({
          id: datos.idHR,
          filters: [
            { code: "activeButton", description: "gestionados" },
            { code: "perfil", description: perfilActual.idPerfil },
          ],
          pageNumber:0,
          pageSize:5
        })
      );
    }
  };
  return (
    <>
      <Box sx={{ ...sxProps.menu }}>
          <Box className="flex ml-auto mr-auto sm:ml-auto sm:mr-[40px] my-3" gap={1}>
            <Button
              name="pendientes"
              variant={activeButton === 0 ? "contained" : "outlined"}
              className="btn-outline-primary"
              onClick={handleButtonChange}
            >
              Pendientes
            </Button>
            <div className="hidden sm:block">
            <Button
              name="gestionados"
              variant={activeButton === 1 ? "contained" : "outlined"}
              className="btn-outline-primary"
              onClick={handleButtonChange}
            >
              Gestionadas en mi repartición
            </Button>
            </div>
            <div className="sm:hidden block">
            <Button
              name="gestionados"
              variant={activeButton === 1 ? "contained" : "outlined"}
              className="btn-outline-primary"
              onClick={handleButtonChange}
            >
              Gestionadas
            </Button>
            </div>
          </Box>
      </Box>
    </>
  );
}
