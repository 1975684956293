import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { removeHistory } from "../occupationalMedicineSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";

const TableLicenseHistory = (props) => {

    const { history } = useSelector((state) => state.auditProLicence);

    const [row, setRow] = useState(0);


    const [data, setData] = useState([{}]);

    useEffect(() => {
        setData(history?.filter((item) => item.field !== "ACCION"))
    }, [history])

    return (
        <Dialog
            maxWidth="md"
            open={props.openInfoAuditoria}
            onClose={() => props.setOpenInfoAuditoria(false)}
        >
            <DialogTitle>
                <h1 className="text-[#5836ff] font-medium text-xl mb-1">Información de auditoria</h1>
                <IconButton
                    aria-label="close"
                    onClick={() => props.setOpenInfoAuditoria(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {data?.length === 0 ? (
                    <div>No existe información para esta licencia</div>
                ) : (
                    <div className="flex flex-col gap-3">
                        <div className="flex justify-between gap-3 border-b rounded-md text-xs items-center">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                <tbody>
                                    <tr className="odd:bg-white  even:bg-gray-50  border-b ">
                                        <th scope="row" className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                            AUDITOR
                                        </th>
                                        <td colSpan={2} className="px-4 py-2">
                                            {data[row].auditor}
                                        </td>
                                    </tr>
                                    <tr className="odd:bg-white  even:bg-gray-50  border-b ">
                                        <th scope="row" className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                            FECHA
                                        </th>
                                        <td colSpan={2} className="px-4 py-2">
                                            {dayjs(data[row].createdDate).format('YYYY-MM-DD HH:mm')}
                                            </td>
                                    </tr>
                                    <tr className="odd:bg-white  even:bg-gray-50  border-b ">
                                        <th scope="row" className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                            {data[row].field}
                                        </th>
                                        <td colSpan={2} className="px-4 py-2">
                                            {data[row].newValue}
                                        </td>
                                    </tr>
                                    {data[row].fieldTwo &&
                                        <tr className="odd:bg-white  even:bg-gray-50  border-b ">
                                            <th scope="row" className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                                {data[row].fieldTwo}
                                            </th>
                                            <td colSpan={2} className="px-4 py-2">
                                                {data[row].newValueTwo}
                                            </td>
                                        </tr>
                                    }
                                    {data[row].fieldThree &&
                                        <tr className="odd:bg-white  even:bg-gray-50  border-b ">
                                            <th scope="row" className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                                {data[row].fieldThree}
                                            </th>
                                            <td colSpan={2} className="px-4 py-2">
                                                {data[row].newValueThree}
                                            </td>
                                        </tr>
                                    }
                                    {data[row].fieldFour &&
                                        <tr className="odd:bg-white  even:bg-gray-50  border-b ">
                                            <th scope="row" className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                                {data[row].fieldFour}
                                            </th>
                                            <td colSpan={2} className="px-4 py-2">
                                                {data[row].newValueFour}
                                            </td>
                                        </tr>
                                    }
                                    {data[row].fieldFive &&
                                        <tr className="odd:bg-white  even:bg-gray-50  border-b ">
                                            <th scope="row" className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                                {data[row].fieldFive}
                                            </th>
                                            <td colSpan={2} className="px-4 py-2">
                                                {data[row].newValueFive}
                                            </td>
                                        </tr>
                                    }
                                    {data[row].fieldSix &&
                                        <tr className="odd:bg-white  even:bg-gray-50  border-b ">
                                            <th scope="row" className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap ">
                                                {data[row].fieldSix}
                                            </th>
                                            <td colSpan={2} className="px-4 py-2">
                                                {data[row].newValueSix}
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </DialogContent>
            <DialogActions
                sx={{ display: "flex", justifyContent: "center", pb: 3 }}
            >
                {
                    data.length > 1 &&

                    <Button
                        disabled={row == 0}
                        onClick={() => {
                            setRow(row - 1)
                        }}
                    >
                        Anterior
                    </Button>

                }
                {data.length > 1 &&
                    <Button
                        disabled={row == data.length - 1}
                        onClick={() => {
                            setRow(row + 1)
                        }}
                    >
                        Siguiente
                    </Button>
                }

            </DialogActions>
        </Dialog>
    );
}

export default TableLicenseHistory;