import React, { useState } from "react";
import { useLocation, useNavigate,useMatch  } from "react-router-dom";
import AccountMenu from "../user/user-menu";
import { useDispatch, useSelector } from "react-redux";
import { selectDatosPersonales } from "../user/userSlice";
import "./styles.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { reset } from "../license/licenseSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { resetDocuments } from "../files/filesSlice";
const Header = ({ logoMiA , onClickNotification }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const matchFocoPost = useMatch('/foco-post/:id');
  const usuario = useSelector(selectDatosPersonales);
  const [open, setOpen] = useState(false);
  if (
    location.pathname === "/login" ||
    location.pathname === "/select-profile" ||
    location.pathname === "/registration" || 
    location.pathname === "/mia-ios" || 
    matchFocoPost
  ) {
    return null;
  }

  const handleClickOpen = () => {
    if(location.pathname === "/license-request-form"){
      setOpen(true);
    }else{
      handleReset();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = () => {
    setOpen(false);
    //dispatch(reset())
    if (location.pathname !== usuario.perfilActual.rootPath) {
      dispatch(resetDocuments());
    }
    navigate(usuario.perfilActual.rootPath);
  };

  return (
    <header className="hidden sm:block">
      <nav className="navbar bg-body-tertiary">
        <div
          style={{ display: "flex" }}
          className="d-flex w-100 flex-column flex-sm-row"
        >
          <div
            onClick={handleClickOpen}
            className="navbar-brand w-full sm:ml-8 ml-0"
          >
            <img
              src={logoMiA}
              className="cursor-pointer object-cover w-[300px] hidden sm:block"
              alt="logoMia"
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <form
              className="flex flex-col align-items-center justify-content-md-end w-full pl-4 mr-10"
              role="search"
            >
              <AccountMenu onClickNotification={onClickNotification}/>
            </form>
          </div>
        </div>
      </nav>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ color: "#5836ff",textAlign: "center"  }}>
            Volver al inicio
          </DialogTitle>
          <DialogContent sx={{ textAlign: "center" }}>
              Se perderán todos los cambios no guardados
          </DialogContent>
          <DialogActions
             sx={{ display: "flex", justifyContent: "center", pb: 3 }}
          >
            <Button onClick={handleClose} size="small"
            color="terciary"
            variant="outlined"
            style={{ textTransform: "capitalize" }}>Cancelar</Button>
            <Button onClick={handleReset} autoFocus size="small"
            color="terciary"
            variant="contained"
            style={{ textTransform: "capitalize", color: "#FFF" }}>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </header>
  );
};
export default Header;
