/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl } from "@mui/base";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectLicenses } from "../licenseSlice";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { Box, FormLabel, IconButton, TextField, Tooltip } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InfoIcon from '@mui/icons-material/Info';

export default function FechaCertificadoFamiliar({ control, setValue }) {
    const { form } = useSelector(selectLicenses);
    const [mensajeErrorDiasCertificado, setMensajeError] = React.useState(' ');
    const [display, setDisplay] = React.useState(' ');
    const [diasCertif, setDiasCertif] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const data = useSelector(selectLicenses);
    React.useEffect(() => {

        if (mensajeErrorDiasCertificado.trim() !== '') {

            setValue("diasCertificado", null, { shouldTouch: true });
        }

    }, [mensajeErrorDiasCertificado]);

    const [mensajeErrorDiasSolicitados, setMensajeErrorDiasSolicitados] = React.useState(' ');
    const [displayDiasSolicitados, setDisplayDiasSolicitados] = React.useState(' ');

    const onChangeFechaCertif = () => {

        setValue("diasCertificado", undefined, { shouldTouch: true });

        if (diasCertif > 0) {
            const fechaCertif = form.fechaCertificado.clone().add(diasCertif, 'days');
            if (fechaCertif < moment(form.fechaInicio)) {

                setMensajeError('Revisá que la fecha de Inicio de Ausentismo y la Fecha de Certificado coincidan, o no podrás continuar con la carga de tu licencia.');
                setDisplay(' ')
                setValue("diasCertificado", null, { shouldTouch: true });
            }
            else {
                setMensajeError(' ');
                setDisplay('none')
                setValue("diasCertificado", diasCertif, { shouldTouch: true });
            }
        }

    }


    const onChangeInputDiasCertif = (event) => {

        if (event.target.value == '') {
            setMensajeError(' ');
            setDisplay('none')
            setDiasCertif(0)
            return
        }
        const diaCertificado = parseInt(event.target.value, 10);
        setDiasCertif(diaCertificado)
        const fechaCertif = form.fechaCertificado.clone().add(diaCertificado, 'days');
        setMensajeError(' ');
        setDisplay('none')

        // Valido cantidad de dias del certificado
        if (diaCertificado !== undefined && diaCertificado > 365) {
            setMensajeError('La duración no puede ser mayor a 365 días');
            setDisplay(' ')
            setValue("diasCertificado", null, { shouldTouch: true });
        }
        // Valido diferencia de dias del certificado respecto de la fecha de inicio del ausentismo
        else if (fechaCertif < moment(form.fechaInicio)) {
            setMensajeError('Revisá que la fecha de Inicio de Ausentismo y la Fecha de Certificado coincidan, o no podrás continuar con la carga de tu licencia.');
            setDisplay(' ')
        }
    }
    
    useEffect(() => {
        setValue("fechaInicio",form.idNotificacion &&  data.allLicences.find(e => e.idNotificacion == form.idNotificacion).statusId == 3? dayjs(new Date(data.allLicences.find(e => e.idNotificacion == form.idNotificacion).startDate)) :dayjs(new Date()), { shouldTouch: true });
        setValue("fechaCertificado", moment(), { shouldTouch: true });
        setValue("licencia",form.licencia,{shouldTouch:true})
    }, [])


    const onChangeInputDiasSolic = (event) => {
        setMensajeErrorDiasSolicitados(' ');
        setDisplayDiasSolicitados('none')
        const diaSolicitados = parseInt(event.target.value, 10);
        const fechaInicioAusentismo = form.fechaInicio
        const fechaCertificado = moment(new Date(form.fechaCertificado))
        const diferenciaEntreIniAusentYFechaCert = fechaInicioAusentismo.diff(fechaCertificado, 'days');

        if (diaSolicitados < 1 ||
            diaSolicitados > form.diasCertificado - diferenciaEntreIniAusentYFechaCert) {
            setMensajeErrorDiasSolicitados('Los dias solicitados no debe superar los dias permitidos');
            return
        }
        else {
            setMensajeErrorDiasSolicitados(' ');
            setDisplayDiasSolicitados('none')
        }
        return diaSolicitados
    }

    const getDate = (days, startDate) => {
        const currentDate = startDate ?? moment(new Date());
        const result = currentDate.add(days, 'days')
        return result;
    }

    return (
        <>
            <FormControl id="input-fecha-certificado-familiar" fullWidth>
                <Box mt={2}>
                    <div style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}>
                        <FormLabel sx={{ color: '#5836ff', fontWeight: 'bold' }}>Fecha de certificado</FormLabel>
                        <Tooltip title="El certificado médico tiene una duración de 7 días. Transcurrido ese tiempo deberás solicitar uno nuevo.">
                            <IconButton>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Controller
                        name="fechaCertificado"
                        control={control}
                        render={({ field: { onChange } }) => (
                            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    open={open}
                                    onClose={()=> setOpen(false)}
                                    defaultValue={dayjs(form.fechaCertificado)}
                                    renderInput={(props) => <TextField {...props} fullWidth />}
                                    slotProps={{ textField: { size: 'small', error: false, fullWidth: true, onClick: () => setOpen(true) } }}
                                    onChange={onChange}
                                    onSelectedSectionsChange={onChangeFechaCertif}
                                    maxDate={dayjs(moment(new Date()))}
                                    minDate={dayjs(getDate(-7))}
                                />
                            </LocalizationProvider>
                        )}
                    />
                </Box>

            </FormControl>

            <FormControl fullWidth>
                <Box mt={2}>
                    <FormLabel sx={{ color: '#5836ff', fontWeight: 'bold' }}>Inicio del ausentismo laboral</FormLabel>
                    <Controller
                        name="fechaInicio"
                        control={control}
                        render={() => (
                            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disabled
                                    defaultValue={form.idNotificacion && data.allLicences.find(e => e.idNotificacion == form.idNotificacion).statusId == 3? dayjs(new Date(data.allLicences.find(e => e.idNotificacion == form.idNotificacion).startDate)) :dayjs(new Date())}
                                    value={form.idNotificacion && data.allLicences.find(e => e.idNotificacion == form.idNotificacion).statusId == 3? dayjs(new Date(data.allLicences.find(e => e.idNotificacion == form.idNotificacion).startDate)) :dayjs(new Date())}
                                    renderInput={(props) => <TextField {...props} fullWidth />}
                                    slotProps={{ textField: { size: 'small', error: false, fullWidth: true } }}
                                />
                            </LocalizationProvider>
                        )}
                    />
                </Box>

            </FormControl>
            <FormControl fullWidth >
                <Box mt={2} display='flex' flexDirection='column'>
                    <FormLabel sx={{ color: '#5836ff', fontWeight: 'bold' }}>Dias del certificado</FormLabel>
                    <Controller

                        name="diasCertificado"
                        control={control}
                        render={({ field: { onChange } }) => (
                            <TextField inputProps={{ min: 0 }} size="small"
                                fullWidth
                                defaultValue={form.diasCertificado}
                                onChange={onChange}
                                onChangeCapture={onChangeInputDiasCertif}
                                type="number">
                            </TextField>
                        )}
                    />
                </Box>

            </FormControl>
            {mensajeErrorDiasCertificado !== " " && (
                <FormLabel name='mensajeErrorDiasCertificado' sx={{ color: '#FF0000', display: { display },mt:1 }}>{mensajeErrorDiasCertificado}</FormLabel>
            )}
            <FormControl fullWidth >
                <Box mt={2} display='flex' flexDirection='column'>
                    <FormLabel sx={{ color: '#5836ff', fontWeight: 'bold' }}>Dias solicitados</FormLabel>
                    <Controller
                        name="diasSolicitados"
                        control={control}
                        render={({ field: { onChange } }) => (
                            <TextField inputProps={{ min: 0 }} size="small"
                                fullWidth
                                defaultValue={form.diasSolicitados}
                                onChange={(e) => onChange(onChangeInputDiasSolic(e))}
                                type="number">
                            </TextField>
                        )}
                    />
                </Box>

            </FormControl>
            <FormLabel name='mensajeErrorDiasSolicitados' sx={{ color: '#FF0000', display: { displayDiasSolicitados } }}>{mensajeErrorDiasSolicitados}</FormLabel>
        </>
    );
}