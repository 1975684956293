import { FormControl } from "@mui/base";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchDiagnosticosAsync, selectLicenses } from "../licenseSlice";
import { Autocomplete, Box, FormLabel, TextField } from "@mui/material";

export default function Descripcion({ control }) {
  return (
    <>
      <FormControl id="input-diagnostico" fullWidth>
        <Box mt={2}>
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Descripción de la enfermedad profesional
          </FormLabel>
          <Controller
            name="descripcion"
            control={control}
            rules={{ required: true, minLength: 10 }}
            render={({ field: { onChange, value } }) => (
              <>
                <TextField
                  multiline
                  fullWidth
                  rows={3}
                  placeholder="Describí las tares que realizás y/o condiciones medioambientales a las que te exponés cotidianamente y qué consecuencias te produjeron"
                  value={value}
                  onChange={(e)=>{
                    if (e.target.value.length > 255) {
                      return;
                    } else {
                      onChange(e)
                    }}}
                />
                {value?.length > 0 && value?.length > 255 && (
                  <p className="text-red-600 pt-2">Máximo 255 caracteres</p>
                )}
                {value?.length > 0 && value?.length < 10 && (
                  <p className="text-red-600 pt-2">Mínimo de 10 caracteres</p>
                )}
              </>
            )}
          />
        </Box>
      </FormControl>
    </>
  );
}
