import React from "react";

import { FormLabel } from "@mui/material";

function Index({
  files,
  getInputProps,
  getRootProps,
  customTitle,
  handleDelete
}) {
  const fileList = files.map((file, index) => (
    <li className="border rounded-md p-2 w-fit" key={index}>
      {file.name} - {file.size} bytes
      <span
        className="cursor-pointer ml-2 text-red-500"
        onClick={() => handleDelete(index)}
      >
        X
      </span>
    </li>
  ));

  return (
    <section>
      <FormLabel>
        {customTitle ?? "Archivos adjuntos a la publicación"}
      </FormLabel>
      <div
        {...getRootProps({ className: "dropzone" })}
        className="border-2 rounded-md py-5 px-5"
      >
        <input {...getInputProps()} />
        <p className="text-center text-[#5836ff]">
          Arrastrar archivos para adjuntar ó{" "}
          <button className="p-2 bg-[#5836ff] text-white rounded-md shadow-sm cursor-pointer">
            Explorar en disco
          </button>
        </p>
      </div>
      {fileList.length > 0 && (
        <aside className="mt-3">
          <FormLabel>Archivos subidos</FormLabel>
          <ul className="flex gap-2 flex-col">{fileList}</ul>
        </aside>
      )}
    </section>
  );
}

export default Index;
