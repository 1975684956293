/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FamilyForm from "../../user/family";
import { Controller, useForm } from "react-hook-form";
import {
  getUserStaticData,
  selectStaticData,
} from "../../parameters/parametersSlice";
import {
  deleteFamiliarAsync,
  getFamiliaresAsync,
  saveFamiliarAsync,
  selectDatosPersonales,
  updateFamiliarAsync,
} from "../../user/userSlice";
import CustomAlert from "../../alert";
import { selectLicenses } from "../licenseSlice";

export default function Familiar({ control, setValue }) {
  const dispatch = useDispatch();
  const { form } = useSelector(selectLicenses);
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    content: "",
    reset: false,
  });
  const { user } = useSelector(selectStaticData);
  const [openModalFamily, setOpen] = React.useState({
    open: false,
    editMode: true,
  });
  const [customAlert, setAlert] = React.useState({
    show: false,
    data: "",
    severity: "success",
  });
  const [selectUser, setUser] = React.useState();
  const { datos, familiares } = useSelector(selectDatosPersonales);

  const handleClose = () => {
    setOpen({ open: false });
  };

  useEffect(() => {
    dispatch(getUserStaticData()).then((x) => {
      dispatch(getFamiliaresAsync(datos.id));
    });
  }, []);

  const saveFamiliar = (event) => {
    if (openModalFamily.editMode) {
      const data = { ...event, userId: datos.id, id: event.id };
      dispatch(updateFamiliarAsync(data)).then((x) => {
        if (x.payload.success) {
          setAlert({
            show: true,
            data: x.payload.message,
            severity: "success",
          });
          setOpen({ open: false });
          dispatch(getFamiliaresAsync(datos.id));
        } else {
          setOpen({ open: false });
          setAlert({ show: true, data: x.payload.message, severity: "error" });
        }
      });
    } else {
      const data = { ...event, userId: datos.id };
      dispatch(saveFamiliarAsync(data)).then((x) => {
        if (x.payload.success) {
          setAlert({
            show: true,
            data: x.payload.message,
            severity: "success",
          });
          setOpen({ open: false });
          setUser();
        } else {
          setOpen({ open: false });
          setAlert({ show: true, data: x.payload.message, severity: "error" });
        }
      });
    }
  };

  const handleDeleteFamiliar = () => {
    setDialogOpen({
      open: true,
      message: (
        <p>
          Estás por eliminar a {selectUser.apellido} {selectUser.nombre} de tu
          lista de familiares a cargo. <br /> ¿Deseás continuar?
        </p>
      ),
      editMode: true,
    });
  };

  const deleteFamiliar = () => {
    dispatch(deleteFamiliarAsync({ id: selectUser?.id })).then((x) => {
      setDialogOpen({
        open: true,
        editMode: false,
        message: x.payload.message,
      });
      setTimeout(() => {
        setDialogOpen({ open: false, editMode: false, message: "" });
      }, 2000);
      if (x.payload.success) {
        setValue("familiar", null, { shouldTouch: true });
        setUser();
      }
    });
  };

  const formatFamiliar = (familiar) => {
    if (familiar) {
      const parentesco = user?.familyKindship?.find(
        (x) => x.id === familiar.parentesco
      );
      return `${familiar.apellido} ${familiar.nombre} - ${parentesco?.description}`;
    }
  };


  return (
    <>
      <FormControl id="input-diagnostico" fullWidth>
        <Box mb={2}>
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Agregar familiar
          </FormLabel>
          <Typography>
            Seleccioná al/la familiar por quien vas a solicitar la Licencia por
            enfermedad familiar.
          </Typography>
        </Box>
        {
          form.regimen == "DOC" &&
          <Controller
            name="familiar"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                size="small"
                labelId="familiar"
                className="input-container"
                defaultValue={form?.familiar}
                onChange={(e) => {
                  setValue("familiar", familiares?.find(

                    (x) => x.id === e.target.value
                  ))
                }}
              >
                {familiares?.filter(e => e.tipoDocumento != 1 && e.parentesco != 7 && e.parentesco != 9).map((dt, i) => (
                      <MenuItem
                        key={`dt-${i}`}
                        onClick={() => setUser(dt)}
                        value={dt.id}
                      >
                        {formatFamiliar(dt)}
                      </MenuItem>
                    ))}

              </Select>
            )}
          />
        }
        {
          form.regimen != "DOC" &&
          <Controller
            name="familiar"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                size="small"
                labelId="familiar"
                className="input-container"
                defaultValue={form?.familiar}
                onChange={(e) => {
                  setValue("familiar", familiares?.find(

                    (x) => x.id === e.target.value
                  ))
                }}
              >
                
                    {familiares?.filter(e => e.tipoDocumento != 1).map((dt, i) => (
                      <MenuItem
                        key={`dt-${i}`}
                        onClick={() => {
                          setUser(dt)
                        }}
                        value={dt.id}
                      >
                        {formatFamiliar(dt)}
                      </MenuItem>
                    ))}
                 

              </Select>
            )}
          />
        }

      </FormControl>
      <div>
        <Box pt={2} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            disabled={!selectUser}
            variant="outlined"
            onClick={() => setOpen({ open: true, editMode: true })}
          >
            Editar familiar
          </Button>
          <Button
            color="error"
            disabled={!selectUser}
            variant="outlined"
            onClick={handleDeleteFamiliar}
          >
            Eliminar
          </Button>
        </Box>
        <Box pt={3}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setOpen({ open: true, editMode: false });
            }}
          >
            Agregar familiar
          </Button>
        </Box>
        <Dialog
          open={openModalFamily.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={{ color: "#1d5a87" }}>Agregar familiar</DialogTitle>
          <DialogContent>
            <FamilyForm
              familiarId={form.familiar}
              handleSave={(x) => saveFamiliar(x)}
              handleClose={handleClose}
              editMode={openModalFamily.editMode}
              license={form.licencia}
              regimen={form.regimen}
            ></FamilyForm>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={dialogOpen.open}
          onClose={() => setDialogOpen({ open: false, editMode: false })}
        >
          <DialogContent sx={{ textAlign: "center" }}>
            {dialogOpen.message}
          </DialogContent>
          {dialogOpen.editMode && (
            <DialogActions
              sx={{ display: "flex", justifyContent: "center", pb: 3 }}
            >
              <Button
                onClick={() => setDialogOpen({ open: false, editMode: false })}
                size="small"
                color="err"
                sx={{ color: "#fff" }}
                variant="contained"
                autoFocus
              >
                No
              </Button>
              <Button
                onClick={deleteFamiliar}
                size="small"
                variant="contained"
                autoFocus
              >
                Si
              </Button>
            </DialogActions>
          )}
        </Dialog>

        <CustomAlert
          error={customAlert}
          onClose={() => setAlert({ show: false })}
          severity={customAlert.severity}
        ></CustomAlert>
      </div>
    </>
  );
}