import api from "../api";

export const exportExcel = async (url,data, fileName) => {
    api.post(url, data, { responseType: 'blob' }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  }

export function sizeValidator(file) {
    if (file.size > 5000000) {
        return {
            code: "file-too-large",
            message: `Tamaño de archivo excedido`
        };
    }
    return null
}