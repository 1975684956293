/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormControl,
  FormLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Typography
} from "@mui/material";
import { selectLicenses } from "../licenseSlice";
import {  useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useEffect, useState } from "react";

export default function FechaNG({ control, setValue }) {

  useEffect(() => {
    setValue("fechaInicio", moment(), { shouldTouch: true });
}, [])
  
  const { form, parameters } = useSelector(selectLicenses);
  
  const getDate = (days, startDate) => {
    const currentDate =
      startDate === "" || startDate === undefined
        ? moment(new Date())
        : moment(startDate);
    const result = currentDate.add(days, "days");
    return result;
  };
  const handleDateChange = (newDate) => {
    if (newDate && newDate.toDate) {
      newDate = newDate.toDate();
    }
    return newDate;
  };

  const [nacMult, setNacMult] = useState(false);
  const [cantidadHijos, setCantidadHijos] = useState(1);

  const handleNacMultChange = (event) => {
    const isChecked = event.target.checked;
    setNacMult(isChecked);
    if (!isChecked) {
      // Resetea el número de hijos si no es un nacimiento múltiple
      setCantidadHijos(1);
    }
  };

  // Function para manejar el cambio del input "cantidadHijos"
  const handleCantidadHijosChange = (event) => {
    const cantidad = parseInt(event.target.value);
    setCantidadHijos(cantidad);
  };

  function calcDate(
    fechaInicioAdd,
    diasNacimientoNG,
    diasNacimientoNGAdicional,
    cantidadHijos
  ) {
    if (Number(cantidadHijos) === 1) {
      const fechaInicio = fechaInicioAdd;

      const fechaInicioDate = new Date(fechaInicio);

      fechaInicioDate.setDate(
        fechaInicioDate.getDate() + Number(diasNacimientoNG)
      );
      return fechaInicioDate.toISOString();
    } 
    else if (cantidadHijos > 1) {
      let diasAdicionales =
        Number(diasNacimientoNG) +
        Number(diasNacimientoNGAdicional) * Number(cantidadHijos);
      const fechaInicioDate = new Date(form.fechaInicio);

      fechaInicioDate.setDate(fechaInicioDate.getDate() + diasAdicionales);

      return fechaInicioDate.toISOString();
    }
  }

  const [fechaFinal, setFechaFinal] = useState("");

  useEffect(() => {
    if (form.fechaInicio) {
      const calculatedFechaFin = calcDate(
        form.fechaInicio,
        parameters.diasNacimientoNG,
        parameters.diasNacimientoNGAdicional,
        cantidadHijos
      );
      setFechaFinal(calculatedFechaFin);
      setValue("fechaFin", calculatedFechaFin, { shouldTouch: true });
    }
  }, [form.fechaInicio, cantidadHijos]);

  useEffect(() => {
    setValue("cantidadHijos", 1, { shouldTouch: true });
  }, []);

  return (
    <>
      <FormControl fullWidth className="flex">
        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
          ¿Es un nacimiento múltiple?
        </FormLabel>
        <FormControlLabel
          control={
            <Checkbox checked={nacMult} onChange={handleNacMultChange} />
          }
          label="Sí"
        />
      </FormControl>
      {nacMult && (
        <FormControl fullWidth>
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Cantidad de hijos
          </FormLabel>
          <Controller
            name="cantidadHijos"
            control={control}
            rules={{
              validate: {
                isGreaterThanOne: (value) =>
                  (nacMult && value > 1) || (!nacMult && value === 1),
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                size="small"
                type="number"
                value={cantidadHijos}
                onChange={(e) => {
                  field.onChange(e);
                  handleCantidadHijosChange(e);
                }}
                inputProps={{ min: 1, max: 10 }} // Ajusta los valores mínimos y máximos según sea necesario
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error
                    ? "El valor debe ser mayor a 1 si es un nacimiento múltiple, o 1 si no lo es."
                    : null
                }
              />
            )}
          />
        </FormControl>
      )}
      <FormControl fullWidth>
        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
          Fecha de Nacimiento
        </FormLabel>
        {nacMult && (
          <small className="text-[#5836ff] mb-1">
            Si el alumbramiento múltiple se produce en distintas fechas, deberás completar el campo con la fecha de quien nació primero/a.
          </small>
        )}

        <Controller
          name="fechaInicio"
          control={control}
          render={({ field: { onChange } }) => (
            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
              <DatePicker
                key="fechaInicio"
                onChange={(e) => onChange(handleDateChange(e))}
                renderInput={(props) => <TextField {...props} fullWidth />}
                slotProps={{ textField: { size: "small", error: false } }}
                minDate={
                  parameters.diasRegistroRetroactivo > 0
                    ? dayjs(
                        getDate(-Number(parameters.diasRegistroRetroactivo))
                      )
                    : dayjs(
                        getDate(Number(parameters.minDiasRegistroAnticipado))
                      )
                }
                maxDate={dayjs(getDate(0))}
              />
            </LocalizationProvider>
          )}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
          Fecha de Fin
        </FormLabel>
        <Controller
          name="fechaFin"
          control={control}
          render={({ field: { onChange } }) => (
            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
              <DatePicker
                key="fechafin"
                renderInput={(props) => <TextField {...props} fullWidth />}
                slotProps={{ textField: { size: "small", error: false } }}
                onChange={(newDate) => {
                  onChange(newDate);
                  setFechaFinal(newDate.toISOString());
                }}
                disabled
                value={dayjs(fechaFinal)}
              />
            </LocalizationProvider>
          )}
        />
      </FormControl>
      <FormGroup sx={{ mt: 2 }}>
        <FormControlLabel
          control={
            <Controller
              name="avisoASuperior"
              control={control}
              render={({ field: { onChange, checked } }) => (
                <Checkbox checked={form.avisoASuperior} onChange={onChange} />
              )}
            />
          }
          label="Di aviso a mi superior"
        />
      </FormGroup>
      <Typography variant="body1" sx={{ mt: 2 }}>
      Recordá que para gestionar una licencia por MIA primero deberás haberle dado aviso a tu superior.
      </Typography>
    </>
  );
}
