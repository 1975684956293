import { configureStore } from '@reduxjs/toolkit';
import userReducer  from '../features/user/userSlice';
import licenseReducer  from '../features/license/licenseSlice';
import admLicenseReducer  from '../features/administrative/administrativeSlice';
import admSupLicenseReducer  from '../features/administrative-superior/administrativeSuperiorSlice';
import auditLicenseReducer  from '../features/audit/auditSlice';
import auditProLicence  from '../features/occupational-medicine/occupationalMedicineSlice';
import modalReducer  from '../features/modals/modalSlice';
import spinnerReducer  from '../features/spinner/spinnerSlice';
import focoReducer  from '../features/foco/focoSlice';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import parametersSlice from '../features/parameters/parametersSlice';
import notificationsSlice from '../features/notifications-menu/notificationsSlice';
import  filesSlice  from '../features/files/filesSlice';
import  alertSlice  from '../features/alerts/alertSlice';

const persistConfig = {
  key: 'root',
  storage: storage
};
const persistedUserReducer = persistReducer(persistConfig, userReducer);
//const persistedFilesReducer = persistReducer(persistConfig, filesSlice);

const store = configureStore({
  reducer: {
    usuario : persistedUserReducer,
    licencia: licenseReducer,
    modal : modalReducer,
    loading : spinnerReducer,
    foco: focoReducer,
    admLicence:admLicenseReducer,
    admSupLicence:admSupLicenseReducer,
    auditLicence: auditLicenseReducer,
    parameters: parametersSlice,
    files: filesSlice,
    notifications: notificationsSlice,
    auditProLicence:auditProLicence,
    alerts:alertSlice
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
});
const persistor = persistStore(store);
export { store, persistor };
