import React from "react";
import {
  fetchAllTypeLicenciasAsync,
  fetchAutorizadasPor,
  fetchLicenciasAsync,
  fetchRegimenesAsync,
  selectLicenses,
} from "../license/licenseSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAdmSupLicenciasAsync } from "./administrativeSuperiorSlice";
import api, { APPCONFIG } from "../../api";
import { exportExcel } from "../../utils/helper";
import { toggleLoading } from "../spinner/spinnerSlice";
import { Box, Button, Typography } from "@mui/material";
import AdministrativeSuperiorFilters from "./administrative-superior-filters";
import AdministrativeSuperiorTable from "./administrative-superior-table";
import { verificarObjetos } from "../../utils";
import { useLocation } from "react-router-dom";
const initialFilters = {
  id: "",
  cuit: "",
  firstName: "",
  lastName: "",
  subtitle: "",
  unidades: "",
  startDate: null,
  endDate: null,
  status: "",
};

function AdministrativeSuperior() {
  const [filters, setFilters] = React.useState(initialFilters);
  const { autorizadasPor } = useSelector(selectLicenses);
  const { datos, perfilActual } = useSelector((state) => state.usuario);
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageAct = queryParams.get("pageAct");
  const [page, setPage] = React.useState(Number(pageAct));
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleBuscar = () => {
    const filterArray = [
      { code: "perfil", description: perfilActual.idPerfil },
    ];
    for (const key in filters) {
      let value = filters[key];
      if (value !== "" && value !== null) {
        if (key === "cuit") {
          value = value.replace(/[^0-9]/g, "");
        }
        filterArray.push({ code: key, description: value });
      }
    }
    dispatch(
      fetchAllAdmSupLicenciasAsync({
        id: datos.idHR,
        filters: filterArray,
        pageNumber: 0,
        pageSize: rowsPerPage,
      })
    ).then((res) => {
      setPage(0);
    });
  };

  const autocompleteRef = React.useRef();

  const handleReset = () => {
    setFilters(initialFilters);
    const ele = autocompleteRef.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
    dispatch(
      fetchAllAdmSupLicenciasAsync({
        id: datos.idHR,
        filters: [{ code: "perfil", description: perfilActual.idPerfil }],
        pageNumber: 0,
        pageSize: rowsPerPage,
      })
    );
  };

  const handleDownload = () => {
    const filter = [
      { code: "perfil", description: perfilActual.idPerfil },
    ];
    for (const key in filters) {
      let value = filters[key];
      if (value !== "" && value !== null) {
        if (key === "cuit") {
          value = value.replace(/[^0-9]/g, "");
        }
        filter.push({ code: key, description: value });
      }
    }
    // if (!filter.find((filtro) => filtro.code === "status")) {
    //   filter.push({ code: "status", description: activeButton });
    // }
    const url = `${APPCONFIG.APIs.LicenseAPI}/Administrative/Export?id=${datos.idHR}&pageNumber=${page}&pageSize=${rowsPerPage}`;
    exportExcel(
      url,
      { filter },
      "LicenciasAutorizante.xlsx",
      dispatch,
      toggleLoading
    );
  };

  React.useEffect(() => {
    //dispatch(fetchLicenciasAsync({idHr:"001648121",codRegimen:"GRAL",idGrupoLicencias:"ADM"}));
    dispatch(fetchAutorizadasPor(datos.idHR));
    dispatch(
      fetchAllAdmSupLicenciasAsync({
        id: datos.idHR,
        filters: [{ code: "perfil", description: perfilActual.idPerfil }],
        pageNumber: page,
        pageSize: rowsPerPage,
      })
    );
  }, []);

  return (
    <>
      <Box sx={{ bgcolor: "#f5f5f5", p: "10px", color: "#5836ff" }}>
        <Typography
          variant="h5"
          className="h5"
          sx={{ fontWeight: "bolder", ml: "30px" }}
        >
          Revisor/a
        </Typography>
      </Box>
      <AdministrativeSuperiorFilters
        filters={filters}
        setFilters={setFilters}
        autorizadasPor={autorizadasPor}
        autocompleteRef={autocompleteRef}
        disabled={verificarObjetos(filters, initialFilters)}
        handleBuscar={handleBuscar}
        handleReset={handleReset}
      />
      <div className="hidden sm:block">
      <Box className="d-flex justify-content-center w-100 gap-2">
        <Button variant="contained" onClick={handleBuscar}>
          Buscar
        </Button>
        <Button
          disabled={verificarObjetos(filters, initialFilters)}
          variant="outlined"
          onClick={handleReset}
        >
          Limpiar
        </Button>
      </Box>
      </div>
      <AdministrativeSuperiorTable
        onExport={handleDownload}
        filters={filters}
        page={page}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
      />
    </>
  );
}

export default AdministrativeSuperior;
