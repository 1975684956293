import React, { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LicTypesArray } from "../../../../interfaces/LicTypesEnum";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
function FiltersHistory({
  filters,
  setFilters,
  handleBuscar,
  disabled,
  handleReset,
  id,
}) {
  const handleChange = (event) => {
    switch (event.target.name) {
      case "tipoLicencia":
        setFilters({ ...filters, tipoLicencia: event.target.value });
        break;
      default:
        break;
    }
  };

  const handleChangeDates = (action, event) => {
    switch (action) {
      case "fechaInicio":
        setFilters({ ...filters, fechaInicio: event });
        break;
      case "fechaFin":
        setFilters({ ...filters, fechaFin: event });
        break;
      default:
        break;
    }
  };
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const pageAct = queryParams.get("pageAct");
  const activeBtn = queryParams.get("activeBtn");
  const navigate = useNavigate();
  return (
    <>
      <Box className="p-3 d-flex gap-4 justify-content-start flex-wrap">
        <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(e) => handleChangeDates("fechaInicio", e)}
            sx={{ width: "185px" }}
            slotProps={{ textField: { size: "small" } }}
            className="datepicker"
            value={filters.fechaInicio}
            label="Fecha Inicio"
          />
          <DatePicker
            onChange={(e) => handleChangeDates("fechaFin", e)}
            sx={{ width: "185px" }}
            slotProps={{ textField: { size: "small" } }}
            className="datepicker"
            value={filters.fechaFin}
            label="Fecha Fin"
          />
        </LocalizationProvider>
        <Box className="d-flex justify-content-center gap-2 ml-auto">
          <Button variant="contained" onClick={handleBuscar}>
            Buscar
          </Button>
          <Button disabled={disabled} variant="outlined" onClick={handleReset}>
            Limpiar
          </Button>
        </Box>
      </Box>
      <Button
        variant="outlined"
        style={{ marginLeft: "15px" }}
        startIcon={<ArrowBackIosIcon />}
        onClick={() => navigate(`/occupational-medicine/history/${id}?pageAct=${pageAct}&activeBtn=${activeBtn}`)}
      >
        Volver
      </Button>
    </>
  );
}

export default FiltersHistory;
