import * as React from "react";
import Menu from "./audit-menu";
import Filters from "./audit-filters";
import { Box, Button } from "@mui/material";
import { verificarObjetos } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAuditLicenciasAsync } from "./auditSlice";
import {
  fetchAllTypeLicenciasAsync,
  fetchLicenciasAsync,
  fetchRegimenesAsync,
} from "../license/licenseSlice";
import AuditTable from "./audit-table";
import api, { APPCONFIG } from "../../api";
import { exportExcel } from "../../utils/helper";
import { useLocation } from "react-router-dom";
import { toggleLoading } from "../spinner/spinnerSlice";
import { exportExcelAsync } from "../files/filesSlice";

const initialFilters = {
  id: "",
  idHr: "",
  //medicas, hospital, largo tratamiento
  cuil: "",
  //embarazo
  apellido: "",
  nombre: "",
  //pae, largo tratamiento, hospitalizacion, medicas
  tipoLicencia: "",
  fechaDenuncia: null,
  fechaSiniestro: null,
  nroSiniestroOtorgado: "",
  nroSiniestroDefinitivo: "",
  nroExpediente: "",
  estado: "",
  inaccRAP: false,
  tomada: false,
};

export default function Audit() {
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [activeButton, setActiveButton] = React.useState(0);
  const [filters, setFilters] = React.useState(initialFilters);
  const { datos, perfilActual } = useSelector((state) => state.usuario);
  const handleBuscar = () => {
    const filterArray = [
      { code: "perfil", description: perfilActual.idPerfil },
    ];
    for (const key in filters) {
      let value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        if (value === true) {
          value = "1";
        }
        if (key === "cuil") {
          value = value.replace(/[^0-9]/g, "");
        }
        filterArray.push({ code: key, description: value });
      }
    }

    dispatch(
      fetchAllAuditLicenciasAsync({
        id: datos.id,
        filters: filterArray,
        pageNumber: 0,
        pageSize: rowsPerPage,
      })
    ).then((res) => {
      setPage(0);
    });
  };

  const handleDownload = () => {
    const filter = [{ code: "perfil", description: perfilActual.idPerfil }];
    for (const key in filters) {
      const value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        filter.push({ code: key, description: value });
      }
    }
    const url = `${APPCONFIG.APIs.LicenseAPI}/Auditor/Export?id=${datos.id}&pageNumber=${page}&pageSize=${rowsPerPage}`;
    dispatch(exportExcelAsync({filter:{filter},url})).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "LicenciasAuditor.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };

  const handleReset = () => {
    setFilters(initialFilters);
    dispatch(
      fetchAllAuditLicenciasAsync({
        id: datos.id,
        filters: [{ code: "perfil", description: perfilActual.idPerfil }],
        pageNumber: 0,
        pageSize: rowsPerPage,
      })
    ).then((res) => {
      setPage(0);
    });
  };

  React.useEffect(() => {
    dispatch(fetchRegimenesAsync(datos.idHR)).then((res) => {
      dispatch(
        fetchLicenciasAsync({
          idHr: datos.idHR,
          codRegimen: "GRAL",
          idGrupoLicencias: "ART",
        })
      ).then((x) => {
        dispatch(fetchAllTypeLicenciasAsync());
        dispatch(
          fetchAllAuditLicenciasAsync({
            id: datos.id,
            filters: [{ code: "perfil", description: perfilActual.idPerfil }],
            pageNumber: page,
            pageSize: rowsPerPage,
          })
        );
      });
    });
  }, []);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageAct = queryParams.get("pageAct");
  const [page, setPage] = React.useState(Number(pageAct));
  React.useEffect(() => {
    if (pageAct) setPage(Number(pageAct));
  }, []);
  return (
    <div>
      <Menu title={"Licencias"} subtitle={"Contingencia ART"} />
      <Filters
        onFilterChange={handleBuscar}
        filters={filters}
        setFilters={setFilters}
        activeButton={activeButton}
      />
      <Box className="d-flex justify-content-center w-100 gap-2">
        <Button variant="contained" onClick={handleBuscar}>
          Buscar
        </Button>
        <Button
          disabled={verificarObjetos(filters, initialFilters)}
          variant="outlined"
          onClick={handleReset}
        >
          Limpiar
        </Button>
      </Box>
      <AuditTable
        filters={filters}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
}
