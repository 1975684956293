import * as React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { reset } from "./userSlice";

const TermsAndConditions = ({ show, handleClose, handleAccept }) => {
  const [accepted, setAccepted] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCancel = () => {
    dispatch(reset());
    navigate("/login");
  };

  return (
    <>
      <Dialog open={show} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ color: "#1d5a87" }} className="terms">
          <h1 className="text-center">APLICACIÓN "MIA"</h1>
          <h2 className="text-center">
            TÉRMINOS Y CONDICIONES GENERALES DE AUTOGESTIÓN <br /> Y POLÍTICAS
            DE PRIVACIDAD
          </h2>
          <h5 className="text-center">
            Para poder iniciar sesión deberás aceptar de forma obligatoria las
            condiciones del acuerdo.
          </h5>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p className="font-semibold text-xs sm:text-base">
              TÉRMINOS Y CONDICIONES DE USO DE LA APLICACIÓN MI AUTOGESTIÓN
              (MIA)
            </p>
            <p className="text-xs sm:text-base">
              Los términos y condiciones que a continuación se detallan (en
              adelante, los “Términos y Condiciones”) regulan el acceso y la
              utilización de la Aplicación Mi Autogestión (MIA) del Gobierno de
              la Ciudad Autónoma de Buenos Aires (en adelante “MIA” y “GCABA”,
              respectivamente).{" "}
            </p>
            <p className="font-semibold text-xs sm:text-base">1. OBJETO DEL SERVICIO.</p>
            <p className="text-xs sm:text-base">
              La Aplicación “MIA” fue desarrollada con el objeto de facilitar al
              Usuario, en su rol de empleado de GCABA, la autogestión de
              trámites relativos a Recursos Humanos. “MIA” abarca la gestión de
              licencias médicas y administrativas, seguida de una auditoría a
              cargo de profesionales auditores médicos, auditores
              administrativos, responsables de presentismo y/o superiores
              jerárquicos. <br />A los efectos de la aprobación de licencias por
              afecciones médicas, se podría requerir al empleado la gestión de
              médico a domicilio, así como la asistencia a un turno virtual o
              presencial con un/a auditor/a médico/a.
              <br />
              Cabe destacar que la finalidad de la plataforma no consiste en la
              prestación de servicios médicos para tratar eventuales afecciones.
              El tratamiento médico de afecciones del Usuario y/o familiares a
              su cargo deberán canalizarse a través del sistema de salud. Por
              consiguiente, en situaciones de carácter urgente y/o emergencias,
              deberá dirigirse al centro de salud más cercano o comunicarse
              telefónicamente con su servicio de emergencias.
            </p>
            <p className="font-semibold text-xs sm:text-base">2. DEFINICIONES.</p>
            <p className="text-xs sm:text-base">
              <span className="font-medium">“GCABA”:</span>
              se refiere al Gobierno de la Ciudad Autónoma de Buenos Aires CUIT
              34- 99903208-9. <br />
              <span className="font-medium">“MIA” / “Aplicación”:</span>
              se refiere a la aplicación Mi Autogestión, por medio de la cual se
              brindarán los servicios. <br />{" "}
              <span className="font-medium">“Servicios”:</span> refiere a los
              servicios brindados mediante la aplicación “MIA” para la
              autogestión del empleado/a del GCABA, para la tramitación de
              licencias, contemplando el seguimiento, la auditoría médica o
              administrativa y la gestión del reconocimiento médico a domicilio.
              <br />
              <span className="font-medium">“Usuario”:</span>
              refiere a todo/a trabajador/a del Gobierno de la Ciudad Autónoma
              de Buenos Aires, que reúna las características previstas en estos
              Términos y Condiciones y que acceda y utilice la Aplicación y el
              Servicio.
            </p>
            <p className="font-semibold text-xs sm:text-base">
              3. ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES.
            </p>
            <p className="text-xs sm:text-base">
              Los presentes Términos y Condiciones regulan los requisitos de
              acceso y uso del Servicio a través de la plataforma MIA, con el
              alcance y hasta los límites que aquí se definen. <br />
              El Usuario debe leer, entender y aceptar los Términos y
              Condiciones, previo al acceso a la Aplicación MIA y al uso del
              Servicio. <br />
              Toda modificación en los Términos y Condiciones entrará en
              vigencia una vez que el usuario las haya leído y aceptado. <br />
              Al descargar, instalar, acceder, usar, obtener o brindar
              información desde y hacia la Aplicación, se considerará que el
              Usuario ha leído y aceptado los Términos y Condiciones y la
              Política de Privacidad de Datos Personales.
            </p>
            <p className="font-semibold text-xs sm:text-base">4. CAPACIDAD.</p>
            <p className="text-xs sm:text-base">
              El Servicio sólo está disponible para los usuarios comprendidos en
              los términos de las presentes cláusulas. No podrán utilizar el
              Servicio las personas que no sean empleadas del GCABA, los menores
              de edad o aquellos empleados del GCABA (por cualquier modalidad de
              contratación bajo relación de dependencia) que hayan sido
              suspendidos temporalmente o inhabilitados definitivamente.{" "}
            </p>
            <p className="font-semibold text-xs sm:text-base">5. REQUERIMIENTOS.</p>
            <p className="text-xs sm:text-base">
              El Usuario que haya aceptado los Términos y Condiciones y desee
              utilizar la Aplicación, deberá reunir los siguientes
              requerimientos. <br />
              <br />{" "}
              <span className="font-medium">
                Revestir la calidad de empleado/a (incluido dentro de la nómina
                del GCABA) en algunas de las siguientes modalidades de empleo
                público:{" "}
              </span>
              <ul className="list-disc">
                <li>
                  {" "}
                  Escalafón General (Carrera de la Administración Pública y
                  Carrera Enfermería y Enfermedades Técnico Profesionales de la
                  Salud)
                </li>
                <li> Plantas transitorias</li>
                <li> Docentes (transitorios, interinos y suplentes)</li>
                <li>Personal de la Procuración General de la Ciudad</li>
                <li>Personal de la Sindicatura General de la Ciudad</li>
                <li>Profesionales de la Salud</li>
                <li>Residentes</li>
                <li>
                  Personal de la Oficina de Transparencia y Control Externo
                  (PDC)
                </li>
                <li>
                  Personal del Cuerpo Especial de Seguridad y Vigilancia regido
                  por el Decreto N° 210/PEN/89
                </li>
                <li>Régimen Gerencial</li>
              </ul>{" "}
              <span className="font-medium">
                {" "}
                Contar con un dispositivo móvil o PC que contenga las aptitudes
                técnicas necesarias señaladas en el apartado “REQUISITOS
                TÉCNICOS” de estos Términos y Condiciones.
              </span>{" "}
              <br /> <br />
              El Servicio únicamente se encuentra disponible para ser utilizado
              a través de la Aplicación MIA, no estando autorizado ni disponible
              a través de otro sistema.
            </p>
            <p className="font-semibold text-xs sm:text-base">6. REQUISITOS TÉCNICOS.</p>
            <p className="text-xs sm:text-base">
              Para una correcta y eficaz utilización de la Aplicación, y su
              correspondiente descarga, los dispositivos móviles deberán cumplir
              los siguientes requisitos técnicos: <br />
              <ul className="list-disc">
                <li>
                  {" "}
                  Dispositivos con sistema operativo ANDROID: teléfono celular o
                  Tablet con ANDROID 4.4 o superior, un mínimo de 1 Gigabytes de
                  memoria R.A.M., resolución mínima de pantalla de 540x960 y un
                  mínimo de 100 Megabytes de espacio de almacenamiento
                  disponible.
                </li>
                <li>
                  Dispositivos con sistema operativo IOS.: teléfono celular
                  modelo “iPhone 5” o superior.
                </li>
                <li>
                  {" "}
                  Aquellos Usuarios que accedan mediante una PC deben contar con
                  alguno de los siguientes navegadores:
                </li>
                <ul className="list-decimal">
                  <li> Google Chrome</li>
                  <li> Apple Safari</li>
                  <li>Mozilla Firefox</li>
                  <li> Microsoft Edge</li>
                </ul>
              </ul>
            </p>
            <p className="font-semibold text-xs sm:text-base">7. REGISTRACIÓN.</p>
            <p className="text-xs sm:text-base">
              Es obligatorio para el Usuario completar la registración que le
              sea solicitada en todos sus campos, con datos válidos para poder
              utilizar el Servicio que brinda la Aplicación. El Usuario deberá
              completarlo con su información personal de manera exacta, precisa
              y verdadera ("Datos Personales"), asumiendo el compromiso de
              actualizar los Datos Personales conforme resulte necesario. El
              Usuario garantiza y responde, en cualquier caso, de la veracidad,
              exactitud, vigencia y autenticidad de sus Datos Personales. <br />
              El Usuario accederá a su cuenta personal (la "Cuenta") mediante el
              ingreso de su Código Único de Identificación Laboral (“CUIL”),
              resultando responsable por todas las operaciones desde allí
              efectuadas. <br />
              La Cuenta es personal, única e intransferible, y está prohibido
              que un mismo Usuario registre o posea más de una Cuenta. <br />
              En el caso que el Usuario detecte el uso no autorizado de su
              Cuenta, así como el ingreso por terceros no autorizados a la
              misma, este se compromete a notificar al GCABA en forma inmediata
              por medio idóneo y fehaciente. <br />
              Se aclara que está prohibida la venta, cesión o transferencia de
              la Cuenta bajo ningún título.
            </p>
            <p className="font-semibold text-xs sm:text-base">8. RESPONSABILIDAD DEL USUARIO.</p>
            <p className="text-xs sm:text-base">
              La registración en la aplicación implica el conocimiento y
              aceptación de pleno derecho de todas las cláusulas contenidas en
              los presentes Términos y Condiciones, las modificaciones que
              pudieran efectuarse en la aplicación, el funcionamiento de la
              misma y las implicancias de todos los actos que fueran efectuados
              a través de aquella, conforme lo expuesto en el apartado 3 de los
              presentes Términos y Condiciones. <br />
              El Usuario declara conocer y aceptar que los servicios prestados a
              través de la aplicación son para la gestión y autorización de
              licencias médicas y/ o administrativas y no reemplazan ni
              sustituyen la consulta médica. <br />
              El Usuario es responsable por la utilización, protección y
              resguardo de su Clave para acceder a la aplicación. El Usuario se
              obliga a no falsear su identidad ni sustituir la identidad de otra
              persona existente o no existente en la aplicación. <br />
              El Usuario no utilizará ni aplicará cualesquiera recursos
              técnicos, lógicos o tecnológicos en cuya virtud puedan
              beneficiarse directa o indirectamente el mismo Usuario o terceras
              personas, con o sin ánimo de lucro, de la explotación no
              autorizada de los contenidos o servicios de la aplicación. <br />
              El Usuario no utilizará la aplicación para la realización de actos
              o actividades contrarias a la ley, a la moral, a las buenas
              costumbres aceptadas o al orden público establecido, o con fines o
              efectos ilícitos, prohibidos o lesivos de derechos o intereses de
              terceros. <br />
              El Usuario deberá cumplir con todos los requisitos técnicos
              necesarios para el correcto uso de la aplicación. La
              disponibilidad de tales requisitos técnicos está a cargo del
              Usuario, y el GCABA no será responsable por ningún tipo de daño o
              perjuicio que su instalación pudiera causar. <br />
              Toda presentación o declaración de datos realizada por el Usuario
              a través de la aplicación tendrá el carácter de declaración
              jurada. El Usuario será responsable por la certeza y veracidad de
              los datos manifestados.
            </p>
            <p className="font-semibold text-xs sm:text-base">9. EXCLUSIÓN DE RESPONSABILIDAD.</p>
            <p className="text-xs sm:text-base">
              El GCBA no será responsable por agravamientos de los eventuales
              cuadros clínicos, en caso de que el Usuario no realice, con
              posterioridad, un tratamiento médico por la afección que motiva la
              solicitud de su licencia laboral.
            </p>
            <p className="font-semibold text-xs sm:text-base">10. INDEPENDENCIA.</p>
            <p className="text-xs sm:text-base">
              Los Términos y Condiciones no crean ningún contrato de sociedad,
              de mandato, ni de franquicia entre el Usuario y el GCABA. El
              Usuario reconoce y acepta que el GCABA no es parte en ninguna
              operación más allá de lo que expresamente aquí se detalle, ni
              asume responsabilidad por la veracidad o exactitud de los anuncios
              o la capacidad del Usuario para el uso de la herramienta.
            </p>
            <p className="font-semibold text-xs sm:text-base">11. PROPIEDAD INTELECTUAL. </p>
            <p className="text-xs sm:text-base">
              Todos los contenidos, programas o cualquier otro elemento
              susceptible de protección por la legislación de propiedad
              intelectual o industrial que fueran desarrollados para la
              Aplicación y resulten accesibles a la misma, son de propiedad
              exclusiva del GCABA y quedan expresamente reservados todos los
              derechos sobre los mismos. <br />
              Los contenidos de las pantallas relativas al Servicio de la
              Aplicación como así también los programas, bases de datos, redes,
              archivos que permiten al Usuario, Prestadores o Empleadores
              acceder y usar su Cuenta, son de propiedad del GCABA y están
              protegidas por las leyes y los tratados internacionales de derecho
              de autor, marcas, patentes, modelos y diseños industriales. El uso
              indebido y/o la reproducción total o parcial de dichos contenidos
              quedan prohibidos.
            </p>
            <p className="font-semibold text-xs sm:text-base">
              12. POLÍTICA DE PRIVACIDAD DE DATOS INGRESADOS A LA APLICACIÓN.
            </p>
            <p className="text-xs sm:text-base">
              Con el fin de informar acerca de las Políticas de Privacidad y el
              tratamiento de los datos suministrados por el Usuario, se presenta
              a continuación el apartado “Política de Privacidad y Protección de
              Datos Personales”.
            </p>
            <p className="font-semibold text-xs sm:text-base">13. JURISDICCIÓN Y LEY APLICABLE.</p>
            <p className="text-xs sm:text-base">
              Estos Términos y Condiciones estarán regidos en todos sus puntos
              por las leyes vigentes en la República Argentina. En caso de
              surgir controversias en la interpretación o implementación, las
              partes involucradas convienen solucionarlas amistosamente de común
              acuerdo. De persistir las mismas, acuerdan someterse a la
              competencia de los Tribunales en lo Contencioso Administrativo y
              Tributario de la Ciudad Autónoma de Buenos Aires, renunciando
              expresamente a cualquier otro fuero y/o jurisdicción que pudiera
              corresponder.
            </p>
            <p className="font-semibold text-xs sm:text-base">14. DOMICILIOS.</p>
            <p className="text-xs sm:text-base">
              El domicilio legal del GCABA a los efectos de toda notificación
              judicial o extrajudicial conforme lo establecido por el artículo
              20 de la Ley N° 1.218 (Texto consolidado por Ley N° 6.588), es el
              de la Procuración General de la Ciudad de Buenos Aires,
              Departamento Oficios Judiciales y Cédulas, sito en la calle
              Uruguay 458 de esta ciudad (Resolución N° 77-PG-06).
            </p>
            <p className="text-center font-bold">
              POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES
            </p>
            <p className="font-semibold text-xs sm:text-base">INTRODUCCIÓN </p>
            <p className="text-xs sm:text-base">
              Las Políticas de Privacidad que a continuación se detallan regulan
              el acceso y la utilización de la aplicación Mi Autogestión (MIA)
              del Gobierno de la Ciudad Autónoma de Buenos Aires cuyo objeto es
              facilitar al Usuario empleado de GCABA la autogestión de trámites
              relativos a Recursos Humanos.
              <br />
              EL GCABA declara que cuenta con una política de protección de la
              privacidad de datos personales, aspecto que coadyuva al
              cumplimiento de las previsiones del Art. 43 de la Constitución
              Nacional, la Ley N° 25.326 y su Decreto Reglamentario N°1558/2001,
              la Ley Nº 1845 y demás normas reglamentarias.
            </p>
            <p className="font-semibold text-xs sm:text-base">1. OBJETO</p>
            <p className="text-xs sm:text-base">
              El presente documento define los criterios y procedimientos a
              implementar por parte del GCABA, con el objeto de lograr la
              protección integral de los datos personales de los Usuarios que se
              encuentran asentados en los bancos de datos y de este modo
              garantizar el derecho al honor, a la intimidad, y demás derechos
              reconocidos por las correspondientes leyes, así como también, el
              acceso a dicha información de conformidad con lo establecido en el
              artículo 43, párrafo tercero de la Constitución Nacional, y la Ley
              25.326 Protección de Datos Personales y la Ley de la Ciudad Ley
              1.845.
            </p>
            <p className="font-semibold text-xs sm:text-base">2. DEFINICIONES</p>
            <p className="text-xs sm:text-base">
              A los efectos de la presente política de privacidad, se realizan
              las siguientes definiciones:
              <ul className="list-disc">
                <li>
                  {" "}
                  Datos personales: Se entiende por “datos personales” a toda
                  aquella información de cualquier tipo referida a personas
                  físicas o jurídicas a las que el GCABA tenga acceso.
                </li>
                <li>
                  {" "}
                  Datos sensibles: Se entiende por “datos sensibles” a todo
                  aquel dato que revele origen racial, étnico, opiniones
                  políticas, convicciones religiosas, afiliación sindical e
                  información referente a la salud o a la vida sexual.
                </li>
                <li>
                  {" "}
                  Base de datos: Se entiende por “base de datos” al conjunto
                  organizado de datos personales que sean objeto de tratamiento
                  o procesamiento, electrónico o no, cualquiera que fuere la
                  modalidad de su formación, almacenamiento, organización o
                  acceso.{" "}
                </li>
                <li>
                  {" "}
                  Tratamiento de datos: Se entiende por “Tratamiento de Datos”,
                  a todas aquellas operaciones o procedimientos, sean
                  electrónicos o no, que permitan la recolección, conservación,
                  ordenación, almacenamiento, modificación, relacionamiento,
                  evaluación, bloqueo, destrucción, y en general el
                  procesamiento de datos personales, así como también su cesión
                  a terceros a través de comunicaciones, consultas,
                  interconexiones o transferencias.
                </li>
                <li>
                  {" "}
                  Titular de los datos: Se entiende por “Titular de los Datos”,
                  a toda persona física o jurídica, cuyos datos sean objeto de
                  tratamiento.
                </li>
              </ul>
            </p>
            <p className="font-semibold text-xs sm:text-base">
              3. PRINCIPIOS DE PROTECCIÓN EN EL TRATAMIENTO DE DATOS PERSONALES{" "}
            </p>
            <p className="font-semibold text-xs sm:text-base">3.1. CALIDAD DE LOS DATOS</p>
            <p className="text-xs sm:text-base">
              <ul className="list-disc">
                <li>
                  {" "}
                  Los datos personales sujetos a tratamiento serán
                  exclusivamente aquellos que sean recolectados por las bases de
                  datos declaradas. Los mismos deberán ser ciertos, adecuados,
                  pertinentes y no excesivos, en cumplimiento del artículo 4º de
                  la ley 25.326 y artículo 6º de la ley 1.845 de Protección de
                  Datos Personales.
                </li>
                <li>
                  {" "}
                  Toda recolección de datos se hará a través de la Aplicación
                  tanto en su versión web o mobile
                </li>
                <li>
                  {" "}
                  El proceso de recolección de datos se realiza bajo la
                  utilización de herramientas especialmente diseñadas para tal
                  fin. Las mismas incluyen los campos necesarios para obtener la
                  información requerida, proporcionando al Usuario instrucciones
                  claras sobre cómo ingresar los datos de manera apropiada. De
                  este modo, se garantiza que la información sea recopilada de
                  manera confiable y se respeten los objetivos de cada base de
                  datos.
                </li>
                <li>
                  {" "}
                  En caso de que excepcionalmente se recaben datos bajo una
                  modalidad diferente a la señalada, se observarán idénticos
                  estándares. Los profesionales a cargo de la recolección de
                  datos y la administración de las respectivas bases serán
                  responsables de verificar el cumplimiento de los criterios en
                  conjunto con el área legal a su cargo.{" "}
                </li>
                <li>
                  {" "}
                  Los datos personales recolectados ingresarán a las bases de
                  datos mencionadas, y se someterán a tratamiento exclusivamente
                  con el propósito que motiva su obtención. Esto implica todo lo
                  relativo a las aprobaciones de licencias y trámites
                  solicitados, así como la recopilación de datos estadísticos
                  orientados al perfeccionamiento de la gestión de RRHH en
                  GCABA.
                </li>
                <li>
                  {" "}
                  En el caso de requerir actualizaciones de datos, se informará
                  debidamente a los titulares de los datos que las consultas a
                  realizarse son exclusivamente a tal fin.
                </li>
                <li>
                  {" "}
                  Los datos personales que formen parte de las bases de datos de
                  GCABA se almacenarán de tal manera que facilite a sus
                  titulares ejercer su derecho de acceso.
                </li>
              </ul>
            </p>
            <p className="font-semibold text-xs sm:text-base">3.2. CONSENTIMIENTO</p>
            <p className="text-xs sm:text-base">
              <ul className="list-disc">
                <li>
                  El usuario al brindar sus datos a la aplicación “MIA” otorga
                  su consentimiento respecto de los “Términos y Condiciones” y
                  la “Política de Privacidad y Protección de Datos Personales”.
                </li>
                <li>
                  Este consentimiento sólo podrá ser otorgado por el titular del
                  dato, o sus responsables legales en caso de tratarse de una
                  persona menor de edad.
                </li>
                <li>
                  {" "}
                  En estricto cumplimiento del plexo normativo vigente, se
                  podrán adquirir datos sin consentimiento del Usuario cuando se
                  obtengan de fuentes de acceso público irrestricto y se limiten
                  a información básica como nombre, DNI, identificación
                  tributaria o previsional, ocupación, fecha de nacimiento y
                  domicilio.
                </li>
              </ul>
            </p>
            <p className="font-semibold text-xs sm:text-base">
              3.3. CESIONES DE DATOS PERSONALES A TERCEROS
            </p>
            <p className="text-xs sm:text-base">
              <ul className="list-disc">
                <li>
                  {" "}
                  Los datos personales incluidos en las bases de datos podrán
                  ser cedidos por imperativo legal y/o a quienes GCABA considere
                  necesario para el cumplimiento de la finalidad aludida. Dentro
                  de esta cláusula se encuadran las cesiones que deban
                  realizarse a organismos como ANSES, AFIP, ART,
                  Superintendencia de Servicios de Salud, requerimientos
                  judiciales a través de oficios, entidades bancarias para el
                  pago de haberes o pago a proveedores.
                </li>
              </ul>
            </p>
            <p className="font-semibold text-xs sm:text-base">
              4. CONFIDENCIALIDAD DE LOS DATOS PERSONALES
            </p>
            <p className="text-xs sm:text-base">
              Todos los agentes del GCABA y de las empresas proveedoras de
              servicios que tengan vinculación directa con “MIA” deberán
              suscribir un Documento de Reserva, Compromiso y Confidencialidad,
              el cual mantendrá vigencia aún concluido el vínculo laboral, a
              excepción de lo reglado por el inciso 2 del art. 10 de la Ley N°
              25.326.
              <br />
              La actividad de los profesionales médicos auditores, se encuentra
              regida bajo la Ley 17.132 “El ejercicio de la medicina,
              odontología y actividades de colaboración de las mismas”, debiendo
              velar por el estricto cumplimiento del Art. 11 que establece:
              “Todo aquello que llegare a conocimiento de las personas cuya
              actividad se reglamenta en la presente ley, con motivo o en razón
              de su ejercicio, no podrá darse a conocer -salvo los casos que
              otras leyes así lo determinen o cuando se trate de evitar un mal
              mayor y sin perjuicio de lo previsto en el Código Penal sino a
              instituciones, sociedades, revistas o publicaciones científicas,
              prohibiéndose facilitarlo o utilizarlo con fines de propaganda,
              publicidad, lucro o beneficio personal”, como así también las
              disposiciones del art. 157 del código penal. <br />
              Por otro lado, en el caso de que se celebren acuerdos que
              impliquen tratamiento de datos por parte de terceros, dicha
              obligación quedará regulada por la suscripción de un Acuerdo de
              Servicios y Confidencialidad.
            </p>
            <p className="font-semibold text-xs sm:text-base">
              5. SEGURIDAD DE LOS DATOS PERSONALES.
            </p>
            <p className="text-xs sm:text-base">
              Los datos personales se encuentran resguardados conforme lo
              establecido por la RESOL-2018-47-APN-AAIP de la Agencia de Acceso
              a la Información Pública.
            </p>
            <p className="font-semibold text-xs sm:text-base">6. TRANSFERENCIA INTERNACIONAL</p>
            <p className="text-xs sm:text-base">
              En caso de resultar necesario celebrar transferencias de alguno o
              la totalidad de los datos personales alojados en las bases de
              datos a un destinatario responsable de bases de datos ubicado
              fuera del territorio nacional, deberá ponderarse si el mismo se
              encuentra sometido a legislación considerada adecuada según lo
              prescrito por la Ley Nº 25.326 y su Decreto Reglamentario
              Nº1558/2001 y artículo 12 de la Ley Nº 1845. <br />
              En dichos casos será requerido el consentimiento de los titulares
              de los datos. En caso de ser necesaria la cesión a responsables de
              otros países que no contaran con el estándar anteriormente
              referido, se requerirá el consentimiento del titular o un Contrato
              de Transferencia Internacional homologado por la Dirección
              Nacional de Protección de Datos Personales del Ministerio de
              Justicia y Derechos Humanos de la Nación.
            </p>
            <p className="font-semibold text-xs sm:text-base">
              7. TRATAMIENTO POR CUENTA DE TERCEROS
            </p>
            <p className="text-xs sm:text-base">
              En todos aquellos casos en los que sea necesario —a los efectos de
              contratar bienes y servicios de terceros—, compartir y/o ceder
              datos personales de los incluidos en alguna de las bases de datos,
              deberá suscribirse con aquel tercero, un Acuerdo de Servicios y
              Confidencialidad con Terceros, el cual contempla las previsiones
              básicas para este tipo de tratamientos (cfr. arts. 9, 10 y 25 de
              la Ley Nº 25.326).
            </p>
            <p className="font-semibold text-xs sm:text-base">
              8. DERECHOS DE LOS TITULARES DE LOS DATOS. PROCEDIMIENTO PARA SU
              EJERCICIO
            </p>
            <p className="text-xs sm:text-base">
              A continuación, se detallan los procedimientos para el ejercicio
              de los derechos de acceso, rectificación, actualización y
              supresión de los datos de carácter personal objeto de tratamiento.{" "}
              <br />
              Dichos procedimientos se incluyen en esta Política de Privacidad,
              para garantizar el correcto tratamiento de los datos de carácter
              personal y evitar su alteración, pérdida, tratamiento o acceso no
              autorizado. <br />
              Los titulares de los datos incluidos en las bases de datos podrán
              ejercer de forma gratuita sus derechos de acceso, rectificación,
              actualización y supresión. <br />
              Al tratarse de derechos personalísimos, sólo podrán ser ejercidos
              por el titular, quien deberá acreditar su identidad mediante la
              presentación de DNI. En situaciones excepcionales en las cuales el
              derecho sea ejercido por un representante legal, se requerirá la
              acreditación de la identidad del titular y toda documentación que
              certifique la condición de representante.
            </p>
            <p className="font-semibold text-xs sm:text-base">
              8.1. PROCEDIMIENTO PARA EL EJERCICIO DEL DERECHO DE ACCESO POR
              PARTE DEL TITULAR DE LOS DATOS
            </p>
            <p className="text-xs sm:text-base">
              El titular o su representante legal deberá contactarse con el
              Responsable Administrativo de Presentismo (en adelante “RAP”) o
              Equipo de Conducción del organismo público del GCABA en el cual
              preste servicio, y presentar una nota de solicitud expresando el
              derecho que desea ejercer, indicando su nombre, número de
              documento, número de teléfono de contacto y dirección de mail a
              donde le será enviada la respuesta.
              <br />
              El RAP o Equipo de Conducción remitirá el pedido a la Mesa de
              Ayuda GCABA por medio de la carga de un ticket en la herramienta
              de gestión de incidencias. La mencionada Mesa de Ayuda estará a
              cargo del traslado del ticket a las áreas encargadas de dar
              respuesta a la solicitud.
              <br />
              Las áreas requeridas deberán recabar la información solicitada y
              remitirla nuevamente a la Mesa de Ayuda de GCABA, que deberá
              generar un informe que se le presentará al solicitante. Este
              informe contendrá, de forma legible e inteligible, el tratamiento
              efectuado al ticket de incidencia y, en su caso la indicación de
              los datos objeto de tratamiento contenidos en las bases de datos y
              los resultantes de cualquier elaboración, proceso o tratamiento,
              así como el origen de los datos, los cesionarios de los mismos y
              la especificación de los usos y fines para los que se almacenaron.
              <br />
              La información se enviará desde la Mesa de Ayuda GCABA al RAP o
              Equipo de Conducción, para ser remitida finalmente a la dirección
              de mail del/la titular del dato indicada en su solicitud, dentro
              de los 10 (diez) días corridos a contar de la fecha de recepción
              de la solicitud.
            </p>
            <p className="font-semibold text-xs sm:text-base">
              8.2. PROCEDIMIENTO PARA EL EJERCICIO DE LOS DERECHOS DE
              ACTUALIZACIÓN, RECTIFICACIÓN Y/O SUPRESIÓN POR PARTE DEL TITULAR
              DE LOS DATOS.
            </p>
            <p className="text-xs sm:text-base">
              El titular de los datos o su representante legal deberán
              comunicarse con el RAP o Equipo de Conducción, munidos de
              documento de identidad, y presentar una nota de solicitud, a
              través del medio que el RAP o Equipo de Conducción indique,
              expresando el derecho que desea ejercer, nombre, número de
              documento, número de teléfono de contacto y dirección de mail a
              donde remitir la respuesta. Deberá acreditar además un interés
              legítimo y acompañar la documentación y/o información necesaria
              para sostener su solicitud. <br />
              El RAP o Equipo de Conducción del organismo correspondiente,
              remitirá el pedido a la Mesa de Ayuda GCABA por medio de la carga
              de un ticket en la herramienta de gestión de incidencias y de
              corresponder, gestionará el incidente en el marco de las bases de
              datos que corresponda para dicho incidente.
              <br />
              El titular podrá requerir la actualización o rectificación de sus
              datos cuando acredite que los mismos son incompletos o inexactos.
              Asimismo, podrá solicitar la supresión de estos si resultaren
              excesivos o inadecuados. La supresión no procederá cuando los
              datos deban ser conservados por el plazo previsto en las
              disposiciones legales aplicables. En caso de que se determine que
              la solicitud es procedente, se dará curso a lo peticionado dentro
              de un plazo máximo de 5 (cinco) días hábiles administrativos.
              <br />
              La Mesa de Ayuda derivará el pedido a las áreas responsables de
              las bases de datos que se vean afectadas para que se realice la
              actualización, rectificación y/o supresión que correspondiera en
              función de lo solicitado. Cada área deberá informar a la Mesa de
              Ayuda de GCABA las acciones realizadas para que se pueda proceder
              a la resolución del incidente por medio del cierre del ticket en
              cuestión. El RAP o Equipo de Conducción podrá controlar y/o
              verificar que el procedimiento se lleve a cabo en tiempo y forma
              y, posteriormente, informar al titular del dato la resolución del
              caso.
            </p>
            <p className="font-semibold text-xs sm:text-base">9. CUMPLIMIENTO DE LA LEY 25.326</p>
            <p className="text-xs sm:text-base">
              Los Usuarios que deseen más información relativa al Régimen de
              Protección de Datos Personales vigente en la República Argentina
              podrán dirigirse a: <br />
              Agencia de Acceso a la Información Pública: <br />
              (54-11) 3988-3968 /{" "}
              <a href="mailto:datospersonales@aaip.gob.ar">
                datospersonales@aaip.gob.ar
              </a>{" "}
              <br />
              La misma es el órgano de Control de la Ley Nº 25.326, tiene la
              atribución de atender las denuncias y reclamos que se interpongan
              con relación al incumplimiento de las normas sobre protección de
              datos personales.
            </p>
            <p className="font-semibold text-xs sm:text-base">
              10. ENCARGADO DE PROTECCIÓN DE DATOS
            </p>
            <p className="text-xs sm:text-base">
              El GCABA con carácter general se encargará de coordinar y
              controlar las medidas definidas en este documento de seguridad. En
              ningún caso, esto supone una exoneración de la responsabilidad a
              las empresas que provean servicios a GCABA.
            </p>
            <p className="font-semibold text-xs sm:text-base">
              11. IMPLEMENTACIÓN Y EJECUCIÓN DE LA POLÍTICA.
            </p>
            <p className="text-xs sm:text-base">
              La presente política de privacidad será implementada por el GCABA
              que nombrará empleados responsables, y coordinará la gestión de la
              aplicación MIA.
            </p>
            <p className="font-semibold text-xs sm:text-base">
              12. AUDITORÍA SOBRE CUMPLIMIENTO DE LA POLÍTICA DE PRIVACIDAD
            </p>
            <p className="text-xs sm:text-base">
              El GCABA realizará auditorías anuales para verificar el
              cumplimiento de esta política de privacidad, y determinar las
              medidas correctivas necesarias en caso de detectar alguna falencia
              en los procedimientos implementados.
            </p>
            <p className="font-semibold text-xs sm:text-base">
              13. DETERMINACIÓN DE RESPONSABILIDADES EN CASO DE INCUMPLIMIENTO
            </p>
            <p className="text-xs sm:text-base">
              La falta de observancia de las previsiones de esta política de
              privacidad generará sanciones a los responsables involucrados.
            </p>
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={accepted}
                onChange={() => setAccepted(!accepted)}
                color="primary"
              />
            }
            label="Acepto los términos y condiciones"
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            my: "6px",
          }}
          className="flex-col sm:flex-row gap-2"
        >
          <Button
            variant="contained"
            onClick={handleCancel}
            color="err"
            sx={{ textTransform: "none", width: "200px", color:"#fff" }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleAccept}
            color="primary"
            disabled={!accepted}
            className="!mx-0"
            sx={{ textTransform: "none", width: "200px" }}
          >
            Estoy de acuerdo
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TermsAndConditions;
