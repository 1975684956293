import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { handleConfirmModal, selectModals } from '../modalSlice';
import { setProfile } from '../../user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: "10px",
  minHeight: 100,
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  h2: {
    fontSize: 20
  },
  padding: 3,
  borderRadius:"15px"
};

const ConfirmModal = () => {

  const modals = useSelector(selectModals);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConfirm = () => {
    if(modals.confirmModal.bukeala){
      window.location.href = modals.confirmModal.bukeala;
    }
    if (modals.confirmModal.redirectTo){
      dispatch(setProfile(modals.confirmModal.perfil))
      navigate(modals.confirmModal.redirectTo)
    }

    dispatch(handleConfirmModal({ open: false, accepted:true }))
  }

  return (
    <React.Fragment>
      <Modal
        open={modals.confirmModal.open}
        onClose={() => dispatch(handleConfirmModal({ open: false }))}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        style={{zIndex:99999}}
        sx={{zIndex:99999}}
      >
        <Box sx={{ ...style, width: 450 }}>
          <h2 className='text-center' id="child-modal-title">{modals.confirmModal.title}</h2>
          <p className='text-center' id="child-modal-description">
            {modals.confirmModal.content}
          </p>
          <Box sx={{ display: 'flex', justifyContent:'center',gap:"10px", flexDirection: modals.confirmModal?.domicilio ? "column" : null }}>
            <Button variant={modals.confirmModal?.domicilio ? "outlined" : "contained"} size="small" onClick={handleConfirm}>{modals.confirmModal?.domicilio ? "No, quiero continuar con el domicilio ingresado." : "Confirmar"}</Button>
            <Button hidden={!modals.confirmModal.showCancel} color={modals.confirmModal?.domicilio ? "success" : "primary"} size='small' variant="outlined" onClick={() => dispatch(handleConfirmModal({ open: false }))}>{modals.confirmModal?.domicilio ? "Si, quiero modificar los datos." : "Cancelar"}</Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
export default ConfirmModal;