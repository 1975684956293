import React from "react";
import "./App.scss";
import "./index.css";
import License from "./features/license";
import { Route, Routes } from "react-router-dom";
import Audit from "./features/audit";
import AuditDetail from "./features/audit/audit-detail";
import Maintenance from "./features/maintenance";
import AuditHistory from "./features/audit/audit-history";
import AdmHistory from "./features/administrative/administratrive-history";
import AdmSupHistory from "./features/administrative-superior/administrative-superior-history";
import AuditProHistory from "./features/occupational-medicine/history";
import AuditAllHistory from "./features/audit/audit-allhistory";
import AuditProAllHistory from "./features/occupational-medicine/allhistory";
import Header from "./features/header";
import HeaderMb from "./features/header/menu-mobile";
import Administrative from "./features/administrative";
import AdministrativeSuperior from "./features/administrative-superior";
import AdministrativeDetail from "./features/administrative/administrative-detail";
import AdministrativeSuperiorDetail from "./features/administrative-superior/administrative-superior-detail";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import SelectProfile from "./features/user/select-profile";
import OccupationalMedicine from "./features/occupational-medicine";
import OccupationalMedicineDetail from "./features/occupational-medicine/detail";
import Footer from "./features/footer";
import Registration from "./features/user/registration";
import AuditPrestadora from "./features/audit-prestadora";
import LogIn from "./features/user/login";
import FocoRRHH from "./features/foco";
import FocoUser from "./features/foco/foco-user";
import FocoCreate from "./features/foco/foco-create";
import FocoId from "./features/foco/foco-id";
import Profile from "./features/user/profile";
import RequestForm from "./features/license/license-request-form";
import Logout from "./features/user/logout";
import { hasAccessToRoute } from "./utils";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logoMiA from "./assets/img/logoMiA2.png";
import MiaIos from "./features/mia-ios";
import FocoPost from "./features/foco/foco-post";

export default function App() {
  const { perfilActual } = useSelector((state) => state.usuario);

  const theme = createTheme({
    typography: {
      fontFamily: "Rubik, sans-serif", // Cambia la fuente
    },
    palette: {
      primary: {
        main: "#5836FF", // Cambia el color principal
      },
      secondary: {
        main: "#00D6D1", // Cambia el color secundario
      },
      terciary: {
        main: "#556fff",
      },
      err: {
        main: "#ff3d8b",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          // Personaliza el estilo de todos los botones
          root: {
            borderRadius: "4px", // Cambia el borde
            fontWeight: "600 !important", // Cambia el peso de la fuente
          },
        },
      },  
    },
  });

  const [openNotification, setOpenNotification] = React.useState({
    open: false,
    licenseId: null,
  });

  const onClickNotification = (id) => {
    setOpenNotification({
      open: true,
      licenseId: id,
    });
  };
  const [open, setOpen] = React.useState(false)

  if(process.env.REACT_APP_MAINTENANCE === 'true'){
    return (
      <Maintenance/>
    )
  }else{    
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Header logoMiA={logoMiA} onClickNotification={onClickNotification} />
          <HeaderMb open={open} setOpen={setOpen} onClickNotification={onClickNotification} />
          <div className="min-h-[80vh]" onClick={()=>setOpen(false)}>
            <Routes>
              <Route path="/" element={<LogIn />} />
              <Route path="/mia-ios" element={<MiaIos />} />
              <Route path="/foco" element={<FocoUser />} />
              <Route path="/foco/create" element={<FocoCreate />} />
              <Route path="/foco/:id" element={<FocoId />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/home" element={<LogIn />} />
              <Route path="select-profile" element={<SelectProfile />} />
              <Route path="Login" element={<LogIn />} />
              <Route path="/foco-rrhh" element={<FocoRRHH />} />
              <Route path="/foco-post/:id" element={<FocoPost />} />

              {hasAccessToRoute("/license", perfilActual) && (
                <>
                  <Route
                    path="/license"
                    element={
                      <License
                        setOpenNotification={setOpenNotification}
                        openNotification={openNotification}
                      />
                    }
                  />
                  <Route path="/license-request-form" element={<RequestForm />} />
                  <Route
                    path="/license-request-form/:ausencia"
                    element={<RequestForm />}
                  />
                </>
              )}
              {hasAccessToRoute("/administrative-superior", perfilActual) && (
                <>
                  <Route
                    path="/administrative-superior"
                    element={<AdministrativeSuperior />}
                  />
                  <Route
                    path="/administrative-superior/:id"
                    element={<AdministrativeSuperiorDetail />}
                  />
                  <Route
                    path="/administrative-superior/history/:id"
                    element={<AdmSupHistory />}
                  />
                </>
              )}
              {hasAccessToRoute("/audit-prestadora", perfilActual) && (
                <>
                  <Route path="/audit-prestadora" element={<AuditPrestadora />} />
                </>
              )}
              {hasAccessToRoute("/occupational-medicine", perfilActual) && (
                <>
                  <Route
                    path="/occupational-medicine"
                    element={<OccupationalMedicine />}
                  />
                  <Route
                    path="/occupational-medicine/:id"
                    element={<OccupationalMedicineDetail />}
                  />
                  <Route
                    path="/occupational-medicine/history/:id"
                    element={<AuditProHistory />}
                  />
                  <Route
                    path="/occupational-medicine/allhistory/:id"
                    element={<AuditProAllHistory />}
                  />
                </>
              )}
              {hasAccessToRoute("/audit", perfilActual) && (
                <>
                  <Route path="/audit" element={<Audit />} />
                  <Route path="/audit/:id" element={<AuditDetail />} />
                  <Route path="/audit/history/:id" element={<AuditHistory />} />
                  <Route
                    path="/audit/allhistory/:id"
                    element={<AuditAllHistory />}
                  />
                </>
              )}
              {hasAccessToRoute("/administrative", perfilActual) && (
                <>
                  <Route path="/administrative" element={<Administrative />} />
                  <Route
                    path="/administrative/:id"
                    element={<AdministrativeDetail />}
                  />
                  <Route
                    path="/administrative/history/:id"
                    element={<AdmHistory />}
                  />
                </>
              )}
              <Route path="Profile" element={<Profile />} />
              <Route path="Registration" element={<Registration />} />
              <Route path="*" element={<LogIn />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </ThemeProvider>
    );
  }
}
