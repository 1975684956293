import moment from "moment";

export const formatDate = (date, includeHour, lt) => {
  const momentDate = moment(date);
  if (!momentDate.isValid()) {
    return "";
  }
  const format = includeHour ? "DD/MM/YYYY HH:mm" : lt ? "DD/MM/YYYY" : "DD/MM/YYYY";
  return momentDate.format(format);
};

export const transformQuery = (arrayObjetos) => {
  const queryString = arrayObjetos
    .map((obj) => {
      return Object.keys(obj)
        .map((key) => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
        })
        .join("&");
    })
    .join("&");
  return queryString;
};

export const verificarObjetos = (objeto1, objeto2) => {
  const clavesObjeto1 = Object.keys(objeto1);
  const clavesObjeto2 = Object.keys(objeto2);

  // Verificar si la cantidad de claves es la misma
  if (clavesObjeto1.length !== clavesObjeto2.length) {
    return false;
  }

  for (let i = 0; i < clavesObjeto1.length; i++) {
    const clave = clavesObjeto1[i];

    // Verificar si la clave existe en ambos objetos
    if (!objeto2.hasOwnProperty(clave)) {
      return false;
    }

    // Verificar si el valor de la clave es el mismo en ambos objetos
    if (objeto1[clave] !== objeto2[clave]) {
      return false;
    }
  }

  // Si no se encontraron diferencias, los objetos son iguales
  return true;
};

export const hasAccessToRoute = (route, profile) => {
  // Verifica si la ruta comienza con el rootPath del perfil actual
  return route.startsWith(profile.rootPath);
};
