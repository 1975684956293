/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import {
  fetchDatosLicencia,
  fetchUnidadesOrganizativasAsync,
  getSolapamientoAusenteAsync,
  selectLicenses,
  setUnidadesOrganizativas,
} from "../licenseSlice";
import { Link } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  minHeight: 100,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  h2: {
    fontSize: 20,
  },
  padding: 3,
  borderRadius: "15px",
};
export default function Step3({ control, user, setValue }) {
  const dispatch = useDispatch();
  const { form, data } = useSelector(selectLicenses);
  const { rootPath } = useSelector((state) => state.usuario.perfilActual);
  const [isOpenAusencia, setIsOpenAusencia] = useState(false);
  const [isOpenSolapa, setIsOpenSolapa] = useState(false);

  useEffect(() => {
    dispatch(fetchDatosLicencia(form.licencia.code)).then((x) => {
      dispatch(
        fetchUnidadesOrganizativasAsync({
          idHr: user.datos.idHR,
          codRegimen: form.regimen,
        })
      );
    });
  }, []);

  const handleSolapamiento = () => {
    setIsOpenAusencia(false);
    const hoy = new Date();
    const mañana = new Date(hoy);
    mañana.setDate(hoy.getDate() + 1);
    const pasadoMañana = new Date(hoy);
    pasadoMañana.setDate(hoy.getDate() + 2);
    dispatch(
      getSolapamientoAusenteAsync({
        idhr: user.datos.idHR,
        idRol: form.unidadesOrganizativas[0]?.idrol,
        fechaDesde: form.tipoLicencia.code === "MEH" ? form.fechaInicio : null,
        fechaHasta: form.tipoLicencia.code === "MEH" ? form.fechaInicio : null,
      })
    ).then((res) => {
      if (res.payload.respuesta === "SI") {
        setIsOpenSolapa(true);
      } else {
        setValue("isAusencia", true, { shouldTouch: true });
        setIsOpenAusencia(false);
      }
    });
  };

  const [selectedCodes, setSelectedCodes] = useState([]);

  const handleCheckboxChange = (code, isChecked) => {
    if (isChecked) {
      // Agregar el código si no está en la lista
      setSelectedCodes((prevSelectedCodes) => [...prevSelectedCodes, code]);
    } else {
      // Filtrar los códigos que no sean el deseleccionado
      setSelectedCodes((prevSelectedCodes) =>
        prevSelectedCodes.filter((c) => c !== code)
      );
    }
  };

  useEffect(() => {
    const selectedUnidadesOrganizativas = data.unidadesOrganizativas.filter(
      (uo) => selectedCodes.includes(uo.code)
    );

    // Actualizar el estado del formulario
    dispatch(setUnidadesOrganizativas(selectedUnidadesOrganizativas));
  }, [selectedCodes]);


  return (
    <>
      <FormControl fullWidth>
        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold", mb: 1 }}>
          Unidad/es Organizativa/s
        </FormLabel>
        {data.unidadesOrganizativas
          ?.filter(
            (value, index, self) =>
              index === self.findIndex((v) => v.code === value.code)
          )
          .map((uo, i) => (
            <FormControlLabel
              key={`uo-${i}`}
              label={uo.description}
              control={
                <Checkbox
                  disabled={data.unidadesOrganizativas.length === 1}
                  checked={
                    data.unidadesOrganizativas.length === 1 ||
                    form.unidadesOrganizativas?.some((x) => x.code === uo.code)
                  }
                  key={`ch-${i}`}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    handleCheckboxChange(uo.code, isChecked);
                  }}
                />
              }
            />
          ))}
        {/* {data.unidadesOrganizativas
          ?.filter(
            (value, index, self) =>
              index === self.findIndex((v) => v.code === value.code)
          )
          .map((uo, i) => (
            <FormControlLabel
              key={`uo-${i}`}
              label={uo.description}
              control={
                <Checkbox
                  disabled={data.unidadesOrganizativas.length === 1}
                  checked={
                    data.unidadesOrganizativas.length === 1 ||
                    form.unidadesOrganizativas?.find(
                      (x) => x.code === uo.code && x.checked
                    )
                  }
                  key={`ch-${i}`}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    let updatedUnidades = [];

                    if (isChecked) {
                      // Agregar todas las unidades con el mismo code
                      updatedUnidades = data.unidadesOrganizativas.map((unit) =>
                        unit.code === uo.code
                          ? { ...unit, checked: true }
                          : unit
                      );
                    } else {
                      // Quitar todas las unidades con el mismo code
                      updatedUnidades = data.unidadesOrganizativas.map((unit) =>
                        unit.code === uo.code
                          ? { ...unit, checked: false }
                          : unit
                      );
                    }

                    dispatch(setUnidadesOrganizativas(updatedUnidades));
                  }}
                />
              }
            />
          ))} */}

        <Typography variant="body1" fullWidth sx={{ mt: 1 }}>
          Si no visualizás alguna de la/s unidad/es organizativa/s para la/s que
          solicitás la licencia, comunícate con tu RAP, área de RH o equipo de
          conducción.
        </Typography>
      </FormControl>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
          Observaciones
        </FormLabel>
        <Typography variant="body1" fullWidth sx={{ mb: 1 }}>
          Si tu solicitud tiene alguna particularidad en relación a la/s
          Unidad/es Organizativa/s, informala aquí. (Opcional)
        </Typography>
        <Controller
          name="observaciones"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              multiline
              fullWidth
              inputProps={{ maxLength: 250 }}
              rows={3}
              placeholder="Máximo 250 caracteres."
              value={value}
              onChange={(e) => {
                if (e.target.value.length > 250) {
                  return;
                }
                onChange(e);
              }}
            />
          )}
        />
      </FormControl>
      {(form.tipoLicencia.code === "MED" ||
        form.tipoLicencia.code === "MEF") && (
        <Box mt={4}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#5836ff",
              color: "white",
              fontSize: "12px",
            }}
            disabled={!form.unidadesOrganizativas.length > 0}
            onClick={() => setIsOpenAusencia(true)}
          >
            Si aún no contás con el certificado médico correspondiente para
            justificar tu licencia, ingresá aquí para notificar tu ausencia.
          </Button>
        </Box>
      )}
      {form.tipoLicencia.code === "MEH" && (
        <>
          <Box mt={4}>
            <Button
              variant="outlined"
              disabled={!form.unidadesOrganizativas.length > 0}
              onClick={() => {
                setValue("fechaInicio", moment().toISOString());
                setIsOpenAusencia(true);
              }}
              sx={{ fontSize: "small", textTransform: "capitalize" }}
              className="group"
            >
              <NotificationsActiveOutlinedIcon
                fontSize="large"
                className="p-1 text-white bg-[#5836ff] rounded-full group-hover:scale-110"
              />
              <p>
                Con fecha de hospitalización programada, ingresá aquí para
                notificar tu ausencia.
              </p>
            </Button>
          </Box>
        </>
      )}
      <Modal
        open={isOpenAusencia | isOpenSolapa}
        onClose={
          isOpenAusencia
            ? () => {
                setIsOpenAusencia(false);
              }
            : () => {
                setIsOpenSolapa(false);
              }
        }
      >
        <Box sx={{ ...style }}>
          {isOpenAusencia ? (
            form.tipoLicencia.code === "MED" ||
            form.tipoLicencia.code === "MEF" ? (
              <p className="text-center">
                Te recordamos que deberás adjuntar el certificado médico
                correspondiente dentro de las 24 hs. de notificada tu ausencia,
                para poder completar la solicitud de licencia. Pasadas las 24
                hs. sin adjuntar el certificado médico, tu solicitud quedará en
                estado injustificada, así como el/los día/s que te ausentes al
                trabajo.
              </p>
            ) : form.tipoLicencia.code === "MEH" ? (
              <>
                {" "}
                <FormControl className="max-w-[350px] mx-auto">
                  <Box mt={2} className="flex flex-col">
                    <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                      Fecha de inicio hospitalización programada
                    </FormLabel>
                    <Controller
                      name="fechaInicio"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <LocalizationProvider
                          adapterLocale="es"
                          dateAdapter={AdapterDayjs}
                        >
                          <DatePicker
                            key="fechaInicio"
                            defaultValue={dayjs(moment(new Date()))}
                            renderInput={(props) => <TextField {...props} />}
                            slotProps={{
                              textField: { size: "small", error: false },
                            }}
                            onChange={onChange}
                            minDate={dayjs(moment(new Date()))}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Box>
                </FormControl>
                <p className="text-center my-2">
                  Tenés hasta 30 días después de la fecha ingresada de
                  hospitalización para adjuntar el certificado médico
                  correspondiente. Podés anular la notificación de
                  hospitalización programada hasta el día anterior de la misma.
                  En caso de que se suspenda el mismo día deberás adjuntar una
                  constancia de cancelación para completar la solicitud.
                </p>
              </>
            ) : null
          ) : (
            <p className="text-center">
              Ya contás con al menos una licencia abierta para la Unidad
              Organizativa que estás seleccionando.
            </p>
          )}
          <Box className="flex gap-2 w-full justify-center">
            {isOpenAusencia ? (
              <>
                <Button
                  onClick={() => {
                    setValue("fechaInicio", undefined);
                    setIsOpenAusencia(false);
                  }}
                  variant="outlined"
                  className="w-full border-2"
                >
                  Volver
                </Button>
                <Button
                  onClick={handleSolapamiento}
                  variant="contained"
                  color="terciary"
                  style={{ color: "#fff" }}
                  className="w-full"
                >
                  Aceptar
                </Button>
              </>
            ) : (
              <Link to={rootPath}>
                <Button variant="outlined">Cerrar</Button>
              </Link>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
}
