/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import {
  selectLicenses,
  fetchLicenciasAsync,
  fetchSteps,
} from "../licenseSlice";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";

export default function Step2({ control, user , setValue}) {
  const dispatch = useDispatch();
  const { form, data } = useSelector(selectLicenses);

  useEffect(() => {
    dispatch(
      fetchLicenciasAsync({
        idHr: user.datos.idHR,
        codRegimen: form.regimen,
        idGrupoLicencias: form.tipoLicencia.code,
      })
    );
  }, []);

 

  const handleSteps = (values) => {
  
    dispatch(
      fetchSteps({
        licenseCode: form.tipoLicencia.description === "Alumbramiento" ? 'ALUM' : values.code,
        idGrupoLicencias: form.tipoLicencia.code,
      })
    );
    
  };

  return (
    <>
      <FormControl fullWidth>
        <Typography
          variant="label"
          sx={{ color: "#5836ff", fontWeight: "bold" }}
        >
          Licencia
        </Typography>
        <Controller
          name="licencia"
          control={control}
          rules={{ required: true, minLength: 5 }}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              freeSolo
              value={form.licencia}
              size="small"
              options={data.licencias}
              getOptionLabel={(option) => option.description ?? ""}
              onChange={(e, values) => {
                  setValue("fechaInicio",undefined);
                  setValue("fechaFin",undefined)
                  setValue("observaciones",undefined)
                  handleSteps(values)
                  onChange(values);
                }}
              // onSelect={() => handleSteps(value)}
              renderInput={(params) => (
                <TextField {...params} InputLabelProps={{ shrink: false }} />
              )}
            />
          )}
        />
      </FormControl>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!data.licencias.length}
      >
        <CircularProgress color="inherit" size={100} />
      </Backdrop>
    </>
  );
}
