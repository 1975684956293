import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, IconButton, TablePagination, Tooltip } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { fetchAllAuditLicenciasHistoryAsync } from "../../auditSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckCircleOutline,
  Cancel,
  HelpOutline,
  Block,
} from "@mui/icons-material";
import { formatDate } from "../../../../utils";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const { allHistoryLicences } = useSelector((state) => state.auditLicence);
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1 || allHistoryLicences?.length === 0 || allHistoryLicences?.length < rowsPerPage}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function HistoryTable({ idhr,  filters,
  page,
  setPage,
  setRowsPerPage,
  rowsPerPage }) {

  const dispatch = useDispatch();
  const { allHistoryLicences , totalCount} = useSelector((state) => state.auditLicence);

  const columns = [
    {
      field: "inaccRAP",
      headerName: "RAP",
      sortable: false,
      headerClassName: "bg-[#5836FF] text-white",
      width: 80,
      flex:1
    },
    {
      field: "idhr",
      headerName: "ID HR",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-center text-xs  my-[10px]">
          {params.row.idhr}
        </span>
      ),
      flex: 1,
    },
    {
      field: "cuit",
      headerName: "CUIL",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-center text-xs  my-[10px]">
          {`${params.row.cuit.toString().substring(0, 2)}-${params.row.cuit
            .toString()
            .substring(2, 10)}-${params.row.cuit.toString().substring(10)}`}
        </span>
      ),
      flex: 1,
      minWidth: 120,
    },
    {
      field: "lastName",
      headerName: "Apellido/s",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center  my-[10px]">
          {params.row.lastName.includes(" ")
            ? params.row.lastName.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.lastName}
        </span>
      ),
      flex: 1,
      minWidth: 90,
    },
    {
      field: "firstName",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Nombre/s",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center  my-[10px]">
          {params.row.firstName.includes(" ")
            ? params.row.firstName.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.firstName}
        </span>
      ),
      flex: 1,
      minWidth: 90,
    },
    {
      field: "title",
      headerName: "Licencia",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center mx-[10px] my-[10px]">
          {params.row.title}
        </span>
      ),
      flex: 1,
    },
    {
      field: "fechaDenuncia",
      headerName: "Denuncia",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {formatDate(params.row.fechaDenuncia)}
        </span>
      ),
    },
    {
      field: "fechaSiniestro",
      headerName: "Siniestro",
      align: "center",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {formatDate(params.row.fechaSiniestro)}
        </span>
      ),
    },
    {
      field: "nroSiniestroOtorgado",
      headerName: "Otorgado",
      sortable: false,
      align: "center",
      headerClassName: "bg-[#5836FF] text-white",
      flex: 1,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {params.row.nroSiniestroOtorgado}
        </span>
      ),
    },
    {
      field: "nroSiniestroDefinido",
      headerName: "Definido",
      sortable: false,
      align: "center",
      headerClassName: "bg-[#5836FF] text-white",
      flex: 1,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {params.row.nroSiniestroDefinido}
        </span>
      ),
    },
    {
      field: "nroExpediente",
      headerName: (
        <div className="text-xs text-center w-full">
          Nro. <br />
          Expedientee
        </div>
      ),
      sortable: false,
      headerClassName: "bg-[#5836FF] text-white",
      flex: 1,
      renderCell: (params) => (
        <span className="text-xs break-words text-center mx-[10px] my-[10px]">
          {params.row.nroExpediente?.includes("/")
            ? params.row.nroExpediente?.split("/").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.nroExpediente}
        </span>
      ),
    },
    {
      field: "statusId",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Estado",
      align: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.status}>
          <span>
            {params.row.statusId === 0 ? (
              <Block style={{ color: "gray" }} />
            ) : params.row.statusId === 1 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 2 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 3 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 4 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 5 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 6 ? (
              <DoDisturbOnIcon style={{ color: "red" }} />
            ) : params.row.statusId === 7 ? (
              <CheckCircleOutline style={{ color: "green" }} />
            ) : params.row.statusId === 8 ? (
              <Cancel style={{ color: "red" }} />
            ) : params.row.statusId === 9 ? (
              <Block style={{ color: "gray" }} />
            ) : params.row.statusId === 10 ? (
              <CheckCircleOutline style={{ color: "green" }} />
            ) : params.row.statusId === 11 ? (
              <Cancel style={{ color: "red" }} />
            ) : params.row.statusId === 12 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 13 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 14 ? (
              <HelpOutline style={{ color: "orange" }} />
            ) : params.row.statusId === 15 ? (
              <HelpOutline style={{ color: "orange" }} />
            ) : params.row.statusId === 16 ? (
              <CheckCircleOutline style={{ color: "green" }} />
            ) : params.row.statusId === 17 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : null}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "tomadaPor",
      align: "center",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center">
          Tomada <br /> por
        </div>
      ),
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <span className="text-xs text-center my-2">
          {params.row.tomadaPor?.includes(" ")
            ? params.row.tomadaPor.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.tomadaPor}
        </span>
      ),
    },
  ];
  const handleChangePage = (_event, newPage) => {
    const filterArray = [];
    for (const key in filters) {
      const value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        filterArray.push({ code: key, description: value });
      }
    }
    //{code:"status",description:pendientes}
    setPage(newPage);
    dispatch(
      fetchAllAuditLicenciasHistoryAsync({ id: idhr, filters: filterArray ,         pageNumber: newPage,
        pageSize:rowsPerPage})
    )
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      fetchAllAuditLicenciasHistoryAsync({ id: idhr, filters:[] ,         pageNumber: 0,
        pageSize:parseInt(event.target.value, 10)})
    )
  };
  if (allHistoryLicences?.length === 0)
    return (
      <p className="text-xl text-center mt-5">
        No existen licencias para los filtros seleccionados
      </p>
    );
  return (
    <div style={{ width: "100%", padding: "10px" }}>
      <div className="relative w-full">
      <div className="flex w-full">
        <TablePagination
           className="border-0 p-0 ml-auto"
          rowsPerPageOptions={[
            5,
            10,
            25,
            { label: "All", value: totalCount },
          ]}
          colSpan={8}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Licencias por pagina"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
                  <p className="flex w-[100px] mx-2 items-center">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[28px] h-[28px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
      </div>
      <div className="w-[100%]">
      {allHistoryLicences?.length === 0 ?             <p className="text-xl text-center mt-5">
              No existen licencias para los filtros seleccionados
            </p> : (
          <DataGrid
          className="border-0"
            hideFooterRowCount
            getRowHeight={() => "auto"}
            rows={allHistoryLicences}
            columns={columns}
            hideFooterPagination
          />
      )}
      </div>
      <div className="w-full flex ">
      <TablePagination
         className="border-0 p-0 ml-auto"
        rowsPerPageOptions={[
          5,
          10,
          25,
          { label: "All", value: totalCount },
        ]}
        colSpan={8}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Licencias por pagina"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      </div>
      </div>
    </div>
  );
}

export default HistoryTable;
