import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import { TableHead } from "@mui/material";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPublicacionesRRHH } from "../focoSlice";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const { allRRHHPublicaciones  } = useSelector((state) => state.foco);
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1 || allRRHHPublicaciones?.length === 0 || allRRHHPublicaciones?.length < rowsPerPage}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const parseDate = (date) => {
  const fechaFormateada = date.toDate
    ? moment(date.toDate()).format("DD/MM/YYYY")
    : moment(date).format("DD/MM/YYYY");
  return fechaFormateada;
};

export default function FocoTable({ filters ,page , setPage , rowsPerPage , setRowsPerPage}) {
  const { allRRHHPublicaciones , totalCount} = useSelector((state) => state.foco);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Avoid a layout jump when reaching the last page with empty rows.

  const handleChangePage = (event, newPage) => {
    const filterArray = [];
    for (const key in filters) {
      const value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        filterArray.push({ code: key, description: value });
      }
    }
    setPage(newPage);
    dispatch(fetchPublicacionesRRHH({ filters: filterArray , pageNumber : newPage , pageSize:rowsPerPage}));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(fetchPublicacionesRRHH({ filters: [] , pageNumber : 0 , pageSize:parseInt(event.target.value, 10)}));
  };

  const handleNavigate = (id) => {
    navigate(`/foco/${id}`);
  };


  if (allRRHHPublicaciones?.length === 0) {
    return (
      <div className="w-100 my-5">
        {" "}
        <h2 style={{ textAlign: "center" }}>
          No existen datos para los valores seleccionados.
        </h2>
      </div>
    );
  } else {
    return (
      <TableContainer className="px-5">
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Fecha de Creación</StyledTableCell>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Tipo de Publicación</StyledTableCell>
              <StyledTableCell>Categoría</StyledTableCell>
              <StyledTableCell>Titulo</StyledTableCell>
              <StyledTableCell>Estado</StyledTableCell>
              <StyledTableCell>Fecha de la Publicación</StyledTableCell>
              <StyledTableCell>Fecha Fin Publicación</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allRRHHPublicaciones?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {parseDate(row.createdat)}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  onClick={() => handleNavigate(row.id)}
                  style={{ cursor: "pointer" }}
                >
                  {row.id}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.publicationtype}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.category === "null" ? "---" : row.category}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.title}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" className="uppercase">
                  {row.status}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.startdate && parseDate(row.startdate)}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  {row.enddate && parseDate(row.enddate)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <TablePagination
                labelRowsPerPage="Publicaciones por pagina"
                className="data-table"
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: totalCount }]}
                colSpan={8}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }
}
