import * as React from "react";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import SvgIcon from "@mui/material/SvgIcon";
import "./styles.scss";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;

export default function AssistantMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { perfilActual } = useSelector((state) => state.usuario);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [auditorMedico, setAuditorMedico] = React.useState(false);
  const [empleados, setEmpleados] = React.useState(false);
  const [revisor, setRevisor] = React.useState(false);

  const handleDescargar = (url) => {
    const pdfURL = window.location.origin + url;
    const link = document.createElement("a");
    link.href = pdfURL;
    link.download = url.split("/")[3];
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };
  if (
    perfilActual.idPerfil !== "EMPLEADO" &&
    perfilActual.idPerfil !== "AU_PROFESIONAL" &&
    perfilActual.idPerfil !== "AUTORIDAD_SUPERIOR" &&
    perfilActual.idPerfil !== "AUTORIZANTE" &&
    perfilActual.idPerfil !== "AU_PROFESIONAL_LT" &&
    perfilActual.idPerfil !== "AUDITOR_ART"
  )
    return null;
  return (
    <div style={{ cursor: "pointer" }}>
      <Tooltip className="hideMobile" title="Manuales">
        <SvgIcon
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          className="icon-link"
          style={{ width: "30px", height: "30px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <path
              d="M11.7256 20.2286V5.4172M11.7256 20.2286L10.2681 18.7712C9.44512 17.9481 8.32937 17.4858 7.16545 17.4858H3.49609C2.89016 17.4858 2.3999 16.9946 2.3999 16.3886V4.86863C2.3999 4.26269 2.89111 3.77148 3.49704 3.77148H7.71352C8.87745 3.77148 9.9937 4.23385 10.8167 5.05687L11.7256 5.96577L12.6345 5.05687C13.4575 4.23385 14.5738 3.77148 15.7377 3.77148H20.5028C21.1087 3.77148 21.5999 4.26269 21.5999 4.86863V16.3886C21.5999 16.9946 21.1087 17.4858 20.5028 17.4858H16.2863C15.1224 17.4858 14.0061 17.9481 13.1831 18.7712L11.7256 20.2286Z"
              stroke="#5836ff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </SvgIcon>
      </Tooltip>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        style={{ marginTop: "20px" }}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "300px",
          },
        }}
      >
        {perfilActual.idPerfil === "EMPLEADO" && (
          <>
            <ListItemButton onClick={() => setEmpleados(!empleados)}>
              <ListItemText primary="Empleados/as" />
              {empleados ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={empleados} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Manual 1 - PT.SG.PG.R.PDC.CSV v01.12.20.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual de Licencias Médicas para PT, SG, PG, R, PDC y CSV" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Manual 1 - Lic. Médicas - CAP.CEETPS.CPH.RG v01.12.20.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual de Licencias Médicas para CAP, CEETPS, CPH y RG" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Manual 1 - Lic. Médicas - Escalafón Docente v01.12.20.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual de Licencias Médicas para Escalafón Docente" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Manual 3 - Lic. Administrativas - Escalafón Docente 171121.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual de Licencias Administrativas para Escalafón Docente" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Manual 3 - Lic. Administrativas 151021.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual de Licencias Administrativas" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Instructivo ART para ella empleadoa.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual de Licencias ART" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/DDJJ Mudanza - IF-2021-30648083-GCABA-MHFGC.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="MDDJJ Mudanza" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Glosario Lic. Administrativas Docentes vf.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Glosario Lic. Administrativas Docentes" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Glosario Lic. Administrativas Escalafón General vf.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Glosario Lic. Administrativas Escalafón General" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}

        {(perfilActual.idPerfil === "AU_PROFESIONAL" ||
          perfilActual.idPerfil === "AU_PROFESIONAL_LT") && (
            <>
              <ListItemButton onClick={() => setAuditorMedico(!auditorMedico)}>
                <ListItemText primary="Auditor/a Médico" />
                {auditorMedico ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={auditorMedico} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    onClick={() =>
                      handleDescargar(
                        "/manuales/Auditor-médico/MANUAL MIA Auditor-a profesional y Auditor-a prof. de largo tratamiento.0908.pdf"
                      )
                    }
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manual para Auditor/a Profesional y Auditor/a Profesional de Largo Tratamiento" />
                  </ListItemButton>
                </List>
              </Collapse>
            </>
          )}

        {(perfilActual.idPerfil === "AUTORIDAD_SUPERIOR" || perfilActual.idPerfil === "AUTORIZANTE") && (
          <>
            <ListItemButton onClick={() => setRevisor(!revisor)}>
              <ListItemText primary="Revisor/a y Autorizante" />
              {revisor ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={revisor} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Revisor-autorizante/MIA 3 ROL REVISOR.A 041121.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual para Revisora/a" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Revisor-autorizante/MIA 3 ROL AUTORIZANTE 151021.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual para Autorizante" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}

        {perfilActual.idPerfil === "AUDITOR_ART" && (
          <>
            <ListItemButton onClick={() => setRevisor(!revisor)}>
              <ListItemText primary="Auditor/a ART" />
              {revisor ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={revisor} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/AuditorART/Instructivo Rol Auditor.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual para Auditor/a ART" />
                </ListItemButton>

               
              </List>
            </Collapse>
          </>
        )}
      </Menu>
    </div>
  );
}
