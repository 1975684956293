/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  anularAusenteAsync,
  anularLicenseAsync,
  fetchAllLicenciasAsync,
  fetchDiagnosticosAsync,
  fetchLicenciasAsync,
  fetchOneLicenciaAsync,
  fetchSteps,
  fetchUnidadesOrganizativasAsync,
  setUnidadesOrganizativas,
  updateForm,
  updateLicenseAutomaticAsync,
} from "../license/licenseSlice";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils";
import ModalDetails from "../license/modalDetails";
import CustomAlert from "../alert";
import axios from "axios";
import { LicStatusEnum } from "../../interfaces/LicStatusEnum";
import DocRespaldatoria from "../license/doc-respaldatoria";
import { onOpen } from "../alerts/alertSlice";
import { LicTypesArray } from "../../interfaces/LicTypesEnum";

const motivosPermitidos = [
  "Debe aportar epicrisis y/o parte quirúrgico.",
  "El certificado adjunto es ilegible.",
  "El certificado no contiene membrete del profesional o institucional.",
  "El certificado ya fue auditado, presente uno nuevo con fecha del día.",
  "No acompaña estudios complementarios que avalen condición médica.",
  "No aporta carnet donde indique datos de vacunación.",
];

const CentrosMedicos = [
  {
    id:20,
    description: "CEMET VIRTUAL CORTO TRATAMIENTO"
  },
  {
    id:21,
    description: "CEMET VIRTUAL COVID"
  },
  {
    id:17,
    description: "CEMET VIRTUAL SALUD MENTAL"
  },
  {
    id:0,
    description: "DGAMT (SEDE CENTRAL)"
  },
  {
    id:30,
    description: "SALUD MENTAL SEDE CENTRAL"
  },
  {
    id:'OTROS',
    description: "OTROS"
  }]

export default function Card({
  license,
  activeButton,
  refreshData,
  licenseTypes,
  dates,
  ord,
  openNotification,
  setOpenNotification,
}) {
  const dispatch = useDispatch();
  const { datos, perfilActual, domicilio, sesion } = useSelector(
    (state) => state.usuario
  );
  const { allTypeLicences, form, data } = useSelector(
    (state) => state.licencia
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [error, setError] = useState({ show: false, data: "" });
  const navigate = useNavigate();
  const [responseOpen, setResponseOpen] = React.useState({
    message: "",
    open: false,
  });
  const handleErrorClose = () => {
    setError({ show: false });
  };
  const [openDetail, setOpenDetail] = React.useState(false);
  const [comentario, setComentario] = React.useState("");

  const handleOpenDetail = (license) => {
    setOpenDetail(true);
  };


  useEffect(() => {
    if (openNotification.open && openNotification.licenseId === license.id) {
      setOpenDetail(true);
    }
  }, [openNotification]);

  const handleAnularNotificacion = (e) => {
    e.preventDefault();
    let data = {
      operacion: "ACCION",
      idHR: datos.idHR,
      perfilMiA: perfilActual.idPerfil,
      idRole: 1,
      accion: "ANULAR",
      comentario: comentario,
      intervieneRAP: "NO",
      estado: "ANULADA",
      fecInicio: formatDate(license.startDate),
      idNotificacion: license.idNotificacion,
      idGrupoLicencias: license.tipoLicencia,
      usuarioMiA: `${datos.cuit.toString().substring(0, 2)}-${datos.cuit
        .toString()
        .substring(2, 10)}-${datos.cuit.toString().substring(10)}`,
      codLicencia: license.licenseCode,
    };
    setDialogOpen(false);
    dispatch(anularAusenteAsync(data)).then((res) => {
      if (res.payload.success) {
        setResponseOpen({ open: true, message: "Licencia anulada con exito" });
        setTimeout(() => {
          setResponseOpen({ open: false, message: "" });
        }, 3000);
        let filters = [];
        if (activeButton === "abiertas") {
          filters = licenseTypes.filter((x) => x.code !== "ABI");
          dispatch(
            fetchAllLicenciasAsync({
              id: datos.id,
              filters: [
                ...filters,
                { code: "ABI", description: "Licencias Abiertas" },
              ],
              ord,
            })
          );
        } else if (activeButton === "medicas") {
          filters = licenseTypes.filter(
            (x) => x.code !== "ADM" && x.code !== "ART" && x.code !== "ABI"
          );
          dispatch(fetchAllLicenciasAsync({ id: datos.id, filters, ord }));
        } else if (activeButton === "administrativas") {
          filters = licenseTypes.filter((x) => x.code === "ADM");
          dispatch(fetchAllLicenciasAsync({ id: datos.id, filters, ord }));
        } else if (activeButton === "art") {
          filters = licenseTypes.filter((x) => x.code === "ART");
          dispatch(fetchAllLicenciasAsync({ id: datos.id, filters, ord }));
        } else if (activeButton === "historial") {
          filters = [{ code: "HIS", description: "Historial Licencias" }];
          if (dates.desde)
            filters.push({ code: "desde", description: dates.desde });
          if (dates.hasta)
            filters.push({ code: "hasta", description: dates.hasta });
          dispatch(fetchAllLicenciasAsync({ id: datos.idHR, filters, ord }));
        }
        setHiddenAnular(true);
      } else {
        setResponseOpen({
          open: true,
          message: "Ups.. Hubo un error al intentar anular la licencia",
        });
        setTimeout(() => {
          setResponseOpen({ open: false, message: "" });
        }, 3000);
      }
    });
  };

  const completarAusenciaAsync = (e) => {
    e.preventDefault();
    const tipoLicencia = allTypeLicences.filter(
      (licencia) => licencia.code === license.tipoLicencia
    )[0];
    // dispatch(updateForm({ regimen: form.regimen, tipoLicencia: tipoLicencia }));
    dispatch(
      fetchLicenciasAsync({
        idHr: datos.idHR,
        codRegimen: license.codeRegimen,
        idGrupoLicencias: tipoLicencia.code,
      })
    ).then((res) => {
      dispatch(
        updateForm({
          ...form,
          regimen: license.codeRegimen,
          tipoLicencia: tipoLicencia,
          licencia: res.payload[0],
          idNotificacion: license.idNotificacion,
        })
      );
      dispatch(setUnidadesOrganizativas(license.unidades));
      dispatch(
        fetchSteps({
          licenseCode: license.licenseCode,
          idGrupoLicencias: tipoLicencia.code,
        })
      )
        .then((res) => {
          dispatch(
            fetchUnidadesOrganizativasAsync({
              idHr: datos.idHR,
              codRegimen: form.regimen,
            })
          );
        })
        .then((res) => navigate("/license-request-form/ausencia"));
    });
  };

  const completarLicenciaSustita = (e) => {
    e.preventDefault();
    const tipoLicencia = allTypeLicences.filter(
      (licencia) => licencia.code === license.tipoLicencia
    )[0];
    // dispatch(updateForm({ regimen: form.regimen, tipoLicencia: tipoLicencia }));
    dispatch(
      fetchLicenciasAsync({
        idHr: datos.idHR,
        codRegimen: license.codeRegimen,
        idGrupoLicencias: tipoLicencia.code,
      })
    ).then((res) => {
      dispatch(
        updateForm({
          ...form,
          regimen: license.codeRegimen,
          tipoLicencia: tipoLicencia,
          licencia: res.payload[0],
          motivoAnulamiento: license.motivoNoAprobacion,
          idTramiteOriginal: license.id,
        })
      );
      dispatch(setUnidadesOrganizativas(license.unidades));
      dispatch(
        fetchSteps({
          licenseCode: license.licenseCode,
          idGrupoLicencias: tipoLicencia.code,
        })
      )
        .then((res) => {
          dispatch(
            fetchUnidadesOrganizativasAsync({
              idHr: datos.idHR,
              codRegimen: license.codeRegimen,
            })
          );
        })
        .then((res) => navigate("/license-request-form/sustituta"));
    });
  };

  const handleAnular = (e) => {
    e.preventDefault();
    if (license.idNotificacion) {
      handleAnularNotificacion(e);
    } else {
      handleAnularLicencia(e);
    }
  };

  const [hiddenAnular, setHiddenAnular] = useState(false);

  const handleAnularLicencia = (e) => {
    e.preventDefault();
    setDialogOpen(false);
    dispatch(
      anularLicenseAsync({
        licenseId: license.id,
        comentario: comentario,
      })
    ).then((x) => {
      if (x.payload.success) {
        setResponseOpen({ open: true, message: "Licencia anulada con exito" });
        setTimeout(() => {
          setResponseOpen({ open: false, message: "" });
        }, 2000);
        setHiddenAnular(true);
        let filters = [];
        if (activeButton === "abiertas") {
          filters = licenseTypes.filter((x) => x.code !== "ABI");
          dispatch(
            fetchAllLicenciasAsync({
              id: datos.id,
              filters: [
                ...filters,
                { code: "ABI", description: "Licencias Abiertas" },
              ],
              ord,
            })
          );
        } else if (activeButton === "medicas") {
          filters = licenseTypes.filter(
            (x) => x.code !== "ADM" && x.code !== "ART" && x.code !== "ABI"
          );
          dispatch(fetchAllLicenciasAsync({ id: datos.id, filters, ord }));
        } else if (activeButton === "administrativas") {
          filters = licenseTypes.filter((x) => x.code === "ADM");
          dispatch(fetchAllLicenciasAsync({ id: datos.id, filters, ord }));
        } else if (activeButton === "art") {
          filters = licenseTypes.filter((x) => x.code === "ART");
          dispatch(fetchAllLicenciasAsync({ id: datos.id, filters, ord }));
        } else if (activeButton === "historial") {
          filters = [{ code: "HIS", description: "Historial Licencias" }];
          if (dates.desde)
            filters.push({ code: "desde", description: dates.desde });
          if (dates.hasta)
            filters.push({ code: "hasta", description: dates.hasta });
          dispatch(fetchAllLicenciasAsync({ id: datos.idHR, filters, ord }));
        }
      } else {
        setError({ show: true, data: x.payload.message });
      }
    });
  };

  const handleCloseModal = () => {
    setOpenDetail(false);
    refreshData();
  };

  const [openBukeala, setOpenBukeala] = React.useState({
    url: null,
    open: true,
  });
  const [openBukealaError, setOpenBukealaError] = React.useState(false);

  const handleOpenBukeala = () => {
    let birthDate = datos.fecNacimiento.split("-");
    let formatDate = `${birthDate[2]}-${birthDate[1]}-${birthDate[0]}`;
    let formatPhone = `${domicilio.telefono.countryCode}${domicilio.telefono.areaCode}${domicilio.telefono.phoneNumber}`;
    let codeDiagnostico = licDiagnostic?.diagnosticId;
    // Obtener la fecha actual
    const fechaActual =
      license.tipoLicencia === "LT"
        ? new Date(license.fechaNuevaCitacion)
        : new Date(license.fechaCitacion);

    // Obtener el día, mes y año
    const dia = fechaActual.getDate();
    const mes = fechaActual.getMonth() + 1; // Sumamos 1 porque los meses comienzan en 0 (enero es 0)
    const año = fechaActual.getFullYear();

    // Formatear la fecha en el formato "dd-mm-yyyy"
    const fechaFormateada = `${dia.toString().padStart(2, "0")}-${mes
      .toString()
      .padStart(2, "0")}-${año}`;
      
    axios
      .post(process.env.REACT_APP_BUKEALA_GET_HASH_HEY, {
        hashKey: process.env.REACT_APP_BUKEALA_HASH_KEY,
        partnerCode: process.env.REACT_APP_BUKEALA_PARTNER_CODE,
        completeName: `${license.firstName} ${license.lastName}`,
        gender: "U",
        birthDate: formatDate,
        leaveCode: license.id,
        leaveTypeCode: license.tipoLicencia === "LT" ? "L" : "S",
        leaveAddressLatitude: "0",
        leaveAddressLongitude: "0",
        canReassignBooking: "YES",
        identificationType: "1",
        identificationNumber: datos.nroDocumento,
        firstBookingDate: fechaFormateada,
        bookingComponentCode: codeDiagnostico,
        bookingComponentName: license.diagnostico,
        externalToken: sesion.token,
        email: datos.email,
        phone: formatPhone,
        branchCode: license.destino != null?CentrosMedicos.find(e=> e.description == license.destino).id:"0"
      })
      .then((res) => {
        setOpenBukeala({ url: res.data.patientHashKey, open: true });
      })
      .catch((error) => {
        console.error("Error en la solicitud a Bukeala:", error);
        setOpenBukealaError(true);
      });
  };

  const [licDiagnostic, setLicDiagnostic] = useState({});
  useEffect(() => {
    if (license.asignacion || license.destino || license.fechaNuevaCitacion) {
      dispatch(fetchDiagnosticosAsync(license.diagnostico)).then((res) =>
        setLicDiagnostic(res.payload[0])
      );
    }
  }, []);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function isFechaPasada(createdDate) {
    if (license.tipoLicencia === "MED" || license.tipoLicencia === "MEF") {
      const fechaCreacion = new Date(createdDate); // Suponiendo que createdDate es un string que representa la fecha
      const ahora = new Date();
      const diferenciaEnMilisegundos = ahora - fechaCreacion;
      const diferenciaEnMinutos = diferenciaEnMilisegundos / (1000 * 60); // 1000 milisegundos = 1 segundo, 60 segundos = 1 minuto

      return diferenciaEnMinutos > 30;
    }
  }

  const validarFecha = () => {
    const isValid = new Date() >= new Date(license.startDate);
    return isValid;
  };

  const validarFecha24 = (objeto) => {
    // Verificar si la propiedad horaAnulacion es null
    if (objeto.horaAnulacion === null) {
      return false;
    }

    // Obtener la fecha actual
    const fechaActual = new Date();

    // Obtener la fecha de horaAnulacion
    const fechaAnulacion = new Date(objeto.horaAnulacion);

    // Calcular la diferencia en milisegundos
    const diferenciaMilisegundos = fechaActual - fechaAnulacion;

    // Calcular la diferencia en horas
    const diferenciaHoras = diferenciaMilisegundos / (1000 * 60 * 60);

    // Verificar si han pasado más de 24 horas
    const hanPasado24Horas = diferenciaHoras > 24;

    return hanPasado24Horas;
  };

  const [openSustituta, setOpenSustituta] = React.useState(false);
  return (
    <>
      {license.tipoLicencia == "LT" ?
        (<Box className="card">

          <CustomAlert
            error={error}
            severity="error"
            onClose={handleErrorClose}
          />

          <Box
            className="card-header rounded-t-md"
          >
            <Box component="span" className="title">
              <Typography variant="subtile1">
                {license.idNotificacion
                  ? "Notificación de ausencia"
                  : license.subtitle}
              </Typography>
              {activeButton !== "historial" && (
                <Typography variant="subtile1" className="idNumber">
                  #{license.id}
                </Typography>
              )}
            </Box>
            <Typography
              variant="subtile1"
              className="subtitle"
            // style={{color: license.status === 'CARGA DE DOC RESPALDATORIA PENDIENTE' || license.status === 'DOC RESPALDATORIA A MODIFICAR' ? 'white ' : ''}}
            >
              {license.title}
            </Typography>
            {license.docRespaldatoriaPendiente &&
              validarFecha() &&
              (license.statusId === 2 || license.statusId === 18) && (
                <p
                  onClick={handleOpen}
                  className="flex text-left text-[#ff3d8b] underline font-bold cursor-pointer w-fit"
                >
                  {" "}
                  <WarningAmberIcon className="mr-1" /> Tenés pendiente la carga
                  de documentación respaldatoria
                </p>
              )}
            {license.idNotificacion && (
              <button
                hidden={
                  license.statusId === LicStatusEnum.ANULADA ||
                  license.statusId === LicStatusEnum.RECHAZADA ||
                  license.statusId === LicStatusEnum.CERRADA ||
                  license.statusId === LicStatusEnum.INVALID ||
                  license.statusId === LicStatusEnum.RECHAZADAPORINCONSISTENCIA ||
                  license.statusId === LicStatusEnum.NOTIFICACION_AUSENCIA_COMPLETA
                }
                onClick={(e) => completarAusenciaAsync(e)}
                className="flex text-left text-[#ff3d8b] underline font-bold text-sm cursor-pointer w-fit"
              >
                Licencia Pendiente de carga de información y documentación. Para
                cargarla Ingresá aquí
              </button>
            )}
          </Box>

          <Box className="card-body flex justify-around" gap={1}>
            {license.tipoLicencia !== "ART" && (
              <>
                <Box className="card-dates">
                  <Box className="card-dates-start">
                    <Typography className="card-label">
                      {license.idNotificacion
                        ? "SOLICITUD"
                        : "INICIO DEL AUSENTISMO LABORAL"}
                    </Typography>
                    <Typography className="card-value">
                      {license.idNotificacion
                        ? formatDate(license.startDate, true)
                        : formatDate(license.startDate)}
                    </Typography>
                  </Box>
                  {/* {license.endDate !== "0001-01-01T00:00:00" && (
                    <Box className="card-dates-end">
                      <Typography className="card-label">
                        {license.idNotificacion ? "VALIDEZ" : "FECHA FIN"}
                      </Typography>
                      <Typography className="card-value">
                        {license.idNotificacion
                          ? formatDate(license.endDate, true)
                          : formatDate(license.endDate)}
                      </Typography>
                    </Box>
                  )} */}
                </Box>
              </>
            )}

            <Box>
              <Typography className="card-label">ESTADO</Typography>

              <Typography
                style={{ fontWeight: 900 }}
                className={`uppercase ${license.statusId === LicStatusEnum.AUTORIZADA ||
                  license.statusId === LicStatusEnum.APROBADA
                  ? "text-[#00ccc8]"
                  : license.statusId === LicStatusEnum.RECHAZADA ||
                    license.statusId === LicStatusEnum.ANULADA
                    ? "text-[#ff3d8b]"
                    : "text-black"
                  }`}
              >
                {license.status}
              </Typography>
            </Box>

            {license.asignacion ||
              license.destino ||
              license.fechaNuevaCitacion ? (
              <>

                {(activeButton !== "historial" && license.regimen) && (
                  <Box>
                    <Typography className="card-label">REGIMEN</Typography>
                    <Typography className="card-value">
                      {license.regimen}
                    </Typography>
                  </Box>
                )}
                <Box className="flex">
                  <Box className="flex flex-col gap-1">
                    {license.asignacion && (
                      <Box>
                        <Typography className="card-label">ASIGNACIÓN</Typography>
                        <Typography className="card-value">
                          {license.asignacion}
                        </Typography>
                      </Box>
                    )}
                    {license.destino && (
                      <Box>
                        <Typography className="card-label">DESTINO</Typography>
                        <Typography className="card-value">
                          {license.destino}
                        </Typography>
                      </Box>
                    )}

                    {license.fechaDeTurno && license.appointmentId != null &&
                      license.statusId != 15 ?
                      <Box>
                        <Typography className="card-label">
                          FECHA DE TURNO
                        </Typography>
                        <Typography className="card-value">
                          {
                            formatDate(license.fechaDeTurno, license.appointmentId)
                          }
                        </Typography>
                      </Box> : <></>
                    }


                    {(license.fechaNuevaCitacion && license.appointmentId != null &&
                      license.statusId != 15) && (
                        <Box>
                          <Typography className="card-label">
                            FECHA DE NUEVA CITACIÓN
                          </Typography>
                          <Typography className="card-value">
                            {formatDate(license.fechaNuevaCitacion, false, true)}
                          </Typography>
                        </Box>
                      )}

                    {license.fechaNuevaCitacion && license.statusId != 15 && license.appointmentId == null &&
                      <Box>
                        <Typography className="card-label">
                          FECHA DE NUEVA CITACIÓN
                        </Typography>
                        <Typography className="card-value">
                          {formatDate(license.fechaNuevaCitacion, false, true)}
                        </Typography>
                      </Box>
                    }

                    {license.fechaNuevaCitacion && license.statusId == 15 && license.appointmentId == null &&
                      <Box>
                        <Typography className="card-label">
                          FECHA DE ALTA
                        </Typography>
                        <Typography className="card-value">
                          {formatDate(license.fechaNuevaCitacion, false, true)}
                        </Typography>
                      </Box>
                    }
                  </Box>
                  <div className="flex flex-col gap-1 items-center justify-center ml-auto">
                    <Button
                      variant="outlined"
                      color="terciary"
                      style={{ textTransform: "capitalize" }}
                      onClick={() => handleOpenDetail(license)}
                      className="w-[110px]"
                      startIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 30 26"
                          fill="#556fff"
                        >
                          <path
                            d="M13 7.5C13 6.94772 12.5523 6.5 12 6.5C11.4477 6.5 11 6.94772 11 7.5H13ZM11 16.5C11 17.0523 11.4477 17.5 12 17.5C12.5523 17.5 13 17.0523 13 16.5H11ZM16.5 13C17.0523 13 17.5 12.5523 17.5 12C17.5 11.4477 17.0523 11 16.5 11V13ZM7.5 11C6.94772 11 6.5 11.4477 6.5 12C6.5 12.5523 6.94772 13 7.5 13V11ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM11 7.5L11 12H13L13 7.5H11ZM11 12V16.5H13V12H11ZM16.5 11H12V13H16.5V11ZM12 11H7.5V13H12V11Z"
                            fill="#556fff"
                          />
                        </svg>
                      }
                    >
                      Ver más
                    </Button>
                    <Button
                      className="w-[110px] tracking-widest"
                      hidden={
                        (license.fechaCitacion &&
                          license.statusId === LicStatusEnum.INGRESADA) ||
                        license.id < 1999999 ||
                        license.statusId ===
                        LicStatusEnum.PENDIENTE_LARGO_TRATAMIENTO ||
                        license.statusId ===
                        LicStatusEnum.AUTORIZADA_LARGO_TRATAMIENTO ||
                        license.statusId ===
                        LicStatusEnum.ALTA_LARGO_TRATAMIENTO ||
                        license.statusId === LicStatusEnum.AUTORIZADA ||
                        license.statusId === LicStatusEnum.RECHAZADA ||
                        license.statusId === LicStatusEnum.ANULADA
                      }
                      variant="outlined"
                      style={{ textTransform: "capitalize" }}
                      color="err"
                      onClick={() => setDialogOpen(true)}
                      startIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="rotate-45 mr-1"
                          width="28"
                          height="25"
                          viewBox="0 0 24 28"
                          fill="#ff3d8b"
                        >
                          <path
                            d="M13 7.5C13 6.94772 12.5523 6.5 12 6.5C11.4477 6.5 11 6.94772 11 7.5H13ZM11 16.5C11 17.0523 11.4477 17.5 12 17.5C12.5523 17.5 13 17.0523 13 16.5H11ZM16.5 13C17.0523 13 17.5 12.5523 17.5 12C17.5 11.4477 17.0523 11 16.5 11V13ZM7.5 11C6.94772 11 6.5 11.4477 6.5 12C6.5 12.5523 6.94772 13 7.5 13V11ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM11 7.5L11 12H13L13 7.5H11ZM11 12V16.5H13V12H11ZM16.5 11H12V13H16.5V11ZM12 11H7.5V13H12V11Z"
                            fill="#ff3d8b"
                          />
                        </svg>
                      }
                    >
                      Anular
                    </Button>
                  </div>
                </Box>
                <button
                  hidden={
                    license.statusId === LicStatusEnum.ANULADA ||
                    license.statusId === LicStatusEnum.RECHAZADA ||
                    license.statusId === LicStatusEnum.CERRADA ||
                    license.statusId === LicStatusEnum.INVALID ||
                    license.statusId ===
                    LicStatusEnum.RECHAZADAPORINCONSISTENCIA ||
                    license.statusId ===
                    LicStatusEnum.PENDIENTE_LARGO_TRATAMIENTO ||
                    license.statusId === LicStatusEnum.ALTA_LARGO_TRATAMIENTO ||
                    license.statusId === LicStatusEnum.CERRADA ||
                    license.appointmentId ||
                    license.fechaDeTurno
                  }
                  onClick={handleOpenBukeala}
                  className="rounded-md w-full bg-rose-600 text-white p-2 text-center "
                >
                  Podés gestionar y consultar tu{" "}
                  <span className="font-semibold">turno</span> desde acá{" "}
                  <span className="underline cursor-pointer">Agendar</span>
                </button>
              </>
            ) : (
              <>

                {(activeButton !== "historial" && license.regimen) && (
                  <Box>
                    <Typography className="card-label">REGIMEN</Typography>
                    <Typography className="card-value">
                      {license.regimen}
                    </Typography>
                  </Box>
                )}
                <Box className="box-btn">
                  {activeButton !== "historial" && (
                    <>
                      <div
                        className="box-btn MuiBox-root css-0"
                        style={{ display: "block" }}
                      >
                        <div className="w-full justify-between flex">
                          <div className="flex flex-col">
                            <Typography className="card-label">
                              UNIDADES ORGANIZATIVA/S
                            </Typography>
                            <Typography className="card-value">
                              {license.unidades?.map((x, i) => (
                                <p className="mb-3 sm:mb-1" key={i}>
                                  {x.description}
                                </p>
                              ))}
                            </Typography>
                          </div>
                          <div
                            className="w-fit flex items-center sm:flex-row flex-col gap-2 mr:0 sm:mr-1"
                            style={{ marginTop: "10px" }}
                          >
                            <Button
                              variant="outlined"
                              color="terciary"
                              style={{ textTransform: "capitalize" }}
                              onClick={() => handleOpenDetail(license)}
                              className="w-[110px]"
                              startIcon={
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 30 26"
                                  fill="#556fff"
                                >
                                  <path
                                    d="M13 7.5C13 6.94772 12.5523 6.5 12 6.5C11.4477 6.5 11 6.94772 11 7.5H13ZM11 16.5C11 17.0523 11.4477 17.5 12 17.5C12.5523 17.5 13 17.0523 13 16.5H11ZM16.5 13C17.0523 13 17.5 12.5523 17.5 12C17.5 11.4477 17.0523 11 16.5 11V13ZM7.5 11C6.94772 11 6.5 11.4477 6.5 12C6.5 12.5523 6.94772 13 7.5 13V11ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM11 7.5L11 12H13L13 7.5H11ZM11 12V16.5H13V12H11ZM16.5 11H12V13H16.5V11ZM12 11H7.5V13H12V11Z"
                                    fill="#556fff"
                                  />
                                </svg>
                              }
                            >
                              Ver mas
                            </Button>

                            <Button
                              className="w-[110px] tracking-widest ml:0 sm:ml-1"
                              hidden={
                                !license.anular ||
                                license.id < 1999999 ||
                                isFechaPasada(license.createDate) ||
                                hiddenAnular ||
                                (license.fechaCitacion &&
                                  license.statusId === LicStatusEnum.INGRESADA) ||
                                license.statusId ===
                                LicStatusEnum.PENDIENTE_LARGO_TRATAMIENTO ||
                                license.statusId ===
                                LicStatusEnum.AUTORIZADA_LARGO_TRATAMIENTO ||
                                license.statusId ===
                                LicStatusEnum.ALTA_LARGO_TRATAMIENTO ||
                                (license.title === "Examen / Días de estudio" &&
                                  license.statusId ===
                                  LicStatusEnum.CARGA_DOC_RESPALDATORIA_PENDIENTE) ||
                                license.statusId === LicStatusEnum.AUTORIZADA ||
                                license.statusId === LicStatusEnum.RECHAZADA ||
                                license.statusId ===
                                LicStatusEnum.DOC_RESPALDATORIA_MODIFICAR ||
                                license.statusId === LicStatusEnum.ANULADA ||
                                !(license.tipoLicencia == "MED" && license.statusId == LicStatusEnum.INGRESADA)
                              }
                              variant="outlined"
                              style={{ textTransform: "capitalize" }}
                              color="err"
                              onClick={() => setDialogOpen(true)}
                              startIcon={
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="rotate-45 mr-1"
                                  width="28"
                                  height="25"
                                  viewBox="0 0 24 28"
                                  fill="#ff3d8b"
                                >
                                  <path
                                    d="M13 7.5C13 6.94772 12.5523 6.5 12 6.5C11.4477 6.5 11 6.94772 11 7.5H13ZM11 16.5C11 17.0523 11.4477 17.5 12 17.5C12.5523 17.5 13 17.0523 13 16.5H11ZM16.5 13C17.0523 13 17.5 12.5523 17.5 12C17.5 11.4477 17.0523 11 16.5 11V13ZM7.5 11C6.94772 11 6.5 11.4477 6.5 12C6.5 12.5523 6.94772 13 7.5 13V11ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM11 7.5L11 12H13L13 7.5H11ZM11 12V16.5H13V12H11ZM16.5 11H12V13H16.5V11ZM12 11H7.5V13H12V11Z"
                                    fill="#ff3d8b"
                                  />
                                </svg>
                              }
                            >
                              Anular
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Box>

                

                {(license.tipoLicencia === "MED" ||
                  license.tipoLicencia === "MEH" ||
                  license.tipoLicencia === "MEF") && (
                    <p
                      hidden={
                        license.statusId !== LicStatusEnum.ANULADA ||
                        motivosPermitidos.find(
                          (x) => x === license.motivoNoAprobacion
                        ) === undefined ||
                        license.sustitute || validarFecha24(license)
                      }
                      className="rounded-md w-full bg-rose-600 text-center text-white p-2 flex flex-col sm:flex-row justify-between"
                    >
                      Aún tenés tiempo para generar tu licencia sustituta
                      <span
                        onClick={() => setOpenSustituta(true)}
                        className="shadow rounded-md bg-rose-700  font-medium cursor-pointer hover:scale-105 py-1 duration-200"
                      >
                        COMENZÁ DESDE AQUÍ
                      </span>
                    </p>
                  )}
              </>
            )}

          </Box>
        </Box>) :
        (
          <Box className="card">
            {
              <CustomAlert
                error={error}
                severity="error"
                onClose={handleErrorClose}
              />
            }
            <Box
              className="card-header rounded-t-md"
            // style={{
            //   backgroundColor: license.status === 'CARGA DE DOC RESPALDATORIA PENDIENTE' || license.status === 'DOC RESPALDATORIA A MODIFICAR' ?'#F56461 ' : 'rgb(225, 225, 225)',
            //   color: license.status === 'CARGA DE DOC RESPALDATORIA PENDIENTE' || license.status === 'DOC RESPALDATORIA A MODIFICAR' ?'white ' : ''}
            //   }
            >
              <Box component="span" className="title">
                <Typography variant="subtile1">
                  {license.idNotificacion
                    ? license.tipoLicencia == "MEH"?"Hospitalización programada":"Notificación de ausencia"
                    : license.subtitle}
                </Typography>
                {activeButton !== "historial" && (
                  <Typography variant="subtile1" className="idNumber">
                    #{license.id}
                  </Typography>
                )}
              </Box>
              <Typography
                variant="subtile1"
                className="subtitle"
              // style={{color: license.status === 'CARGA DE DOC RESPALDATORIA PENDIENTE' || license.status === 'DOC RESPALDATORIA A MODIFICAR' ? 'white ' : ''}}
              >
                {license.title}
              </Typography>
              {license.docRespaldatoriaPendiente &&
                validarFecha() &&
                (license.statusId === 2 || license.statusId === 18) && (
                  <p
                    onClick={handleOpen}
                    className="flex text-left text-[#ff3d8b] underline font-bold cursor-pointer w-fit"
                  >
                    {" "}
                    <WarningAmberIcon className="mr-1" /> Tenés pendiente la carga
                    de documentación respaldatoria
                  </p>
                )}
              {license.idNotificacion && (
                <button
                  hidden={
                    license.statusId === LicStatusEnum.ANULADA ||
                    license.statusId === LicStatusEnum.RECHAZADA ||
                    license.statusId === LicStatusEnum.CERRADA ||
                    license.statusId === LicStatusEnum.INVALID ||
                    license.statusId === LicStatusEnum.RECHAZADAPORINCONSISTENCIA ||
                    license.statusId === LicStatusEnum.NOTIFICACION_AUSENCIA_COMPLETA
                  }
                  onClick={(e) => completarAusenciaAsync(e)}
                  className="flex text-left text-[#ff3d8b] underline font-bold text-sm cursor-pointer w-fit"
                >
                  Licencia Pendiente de carga de información y documentación. Para
                  cargarla Ingresá aquí
                </button>
              )}
            </Box>

            <Box className="card-body flex justify-around" gap={1}>
              {license.tipoLicencia !== "ART" && (
                <>
                  <Box className="card-dates">
                    <Box className="card-dates-start">
                      <Typography className="card-label">
                        {license.idNotificacion? license.tipoLicencia == "MEH"?"INICIO": "SOLICITUD"
                          : "INICIO DEL AUSENTISMO LABORAL"}
                      </Typography>
                      <Typography className="card-value">
                        {license.idNotificacion
                          ? formatDate(license.startDate, true)
                          : formatDate(license.startDate)}
                      </Typography>
                    </Box>
                    {license.endDate !== "0001-01-01T00:00:00" && (
                      <Box className="card-dates-end">
                        <Typography className="card-label">
                          {license.idNotificacion ?   license.tipoLicencia == "MEH"?<>VALIDEZ<span className="text-left text-[#ff3d8b]  text-xs">*</span></>: "VALIDEZ" : "FECHA FIN"}
                        </Typography>
                        <Typography className="card-value">
                          {license.idNotificacion
                            ? formatDate(license.endDate, true)
                            : formatDate(license.endDate)}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </>
              )}
              {license.tipoLicencia === "ART" && (
                <>
                  <Box className="card-dates">
                    <Box className="card-dates-start">
                      <Typography className="card-label">
                        FECHA DE DENUNCIA
                      </Typography>
                      <Typography className="card-value">
                        {formatDate(license.fechaDenuncia)}
                      </Typography>
                    </Box>
                    <Box className="card-dates-end">
                      <Typography className="card-label">
                        FECHA DE SINIESTRO
                      </Typography>
                      <Typography className="card-value">
                        {formatDate(license.fechaSiniestro)}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
              <Box>
                <Typography className="card-label">ESTADO</Typography>
                <Typography
                  style={{ fontWeight: 900 }}
                  className={`uppercase ${license.statusId === LicStatusEnum.AUTORIZADA ||
                    license.statusId === LicStatusEnum.APROBADA
                    ? "text-[#00ccc8]"
                    : license.statusId === LicStatusEnum.RECHAZADA ||
                      license.statusId === LicStatusEnum.ANULADA
                      ? "text-[#ff3d8b]"
                      : "text-black"
                    }`}
                >
                  {license.status}
                </Typography>
              </Box>
              {license.asignacion ||
                license.destino ||
                license.fechaNuevaCitacion ? (
                <>
                  {(activeButton !== "historial" && license.regimen) && (
                    <Box>
                      <Typography className="card-label">REGIMEN</Typography>
                      <Typography className="card-value">
                        {license.regimen}
                      </Typography>
                    </Box>
                  )}
                  <Box className="flex">
                    <Box className="flex flex-col gap-1">
                      {license.asignacion && (
                        <Box>
                          <Typography className="card-label">ASIGNACIÓN</Typography>
                          <Typography className="card-value">
                            {license.asignacion}
                          </Typography>
                        </Box>
                      )}
                      {license.destino && (
                        <Box>
                          <Typography className="card-label">DESTINO</Typography>
                          <Typography className="card-value">
                            {license.destino}
                          </Typography>
                        </Box>
                      )}
                      {license.fechaDeTurno && license.appointmentId != null &&
                        license.statusId != 15 ?
                        <Box>
                          <Typography className="card-label">
                            FECHA DE TURNO
                          </Typography>
                          <Typography className="card-value">
                            {
                              formatDate(license.fechaDeTurno, license.appointmentId)
                            }
                          </Typography>
                        </Box> : <></>
                      }
                      {(license.fechaNuevaCitacion && license.appointmentId != null &&
                        license.statusId != 15) && (
                          <Box>
                            <Typography className="card-label">
                              FECHA DE NUEVA CITACIÓN
                            </Typography>
                            <Typography className="card-value">
                              {formatDate(license.fechaNuevaCitacion, license.statusId !==
                                LicStatusEnum.ALTA_LARGO_TRATAMIENTO)}
                            </Typography>
                          </Box>
                        )}

                      {(license.fechaNuevaCitacion &&
                        license.statusId == 16) && (
                          <Box>
                            <Typography className="card-label">
                              FECHA DE NUEVA CITACIÓN
                            </Typography>
                            <Typography className="card-value">
                              {formatDate(license.fechaNuevaCitacion, license.statusId !==
                                LicStatusEnum.ALTA_LARGO_TRATAMIENTO)}
                            </Typography>
                          </Box>
                        )}

                      {license.fechaCitacion && (
                        <Box>
                          <Typography className="card-label">
                            FECHA DE CITACIÓN
                          </Typography>
                          <Typography className="card-value">
                            {formatDate(license.fechaCitacion, false, true)}
                          </Typography>
                        </Box>
                      )}

                    </Box>
                    <div className="flex flex-col gap-1 items-center justify-center ml-auto">
                      
                      <Button
                        variant="outlined"
                        color="terciary"
                        style={{ textTransform: "capitalize" }}
                        onClick={() => handleOpenDetail(license)}
                        className="w-[110px]"
                        startIcon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 30 26"
                            fill="#556fff"
                          >
                            <path
                              d="M13 7.5C13 6.94772 12.5523 6.5 12 6.5C11.4477 6.5 11 6.94772 11 7.5H13ZM11 16.5C11 17.0523 11.4477 17.5 12 17.5C12.5523 17.5 13 17.0523 13 16.5H11ZM16.5 13C17.0523 13 17.5 12.5523 17.5 12C17.5 11.4477 17.0523 11 16.5 11V13ZM7.5 11C6.94772 11 6.5 11.4477 6.5 12C6.5 12.5523 6.94772 13 7.5 13V11ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM11 7.5L11 12H13L13 7.5H11ZM11 12V16.5H13V12H11ZM16.5 11H12V13H16.5V11ZM12 11H7.5V13H12V11Z"
                              fill="#556fff"
                            />
                          </svg>
                        }
                      >
                        Ver más
                      </Button>
                      <Button
                        className="w-[110px] tracking-widest"
                        hidden={
                          (license.fechaCitacion &&
                            license.statusId === LicStatusEnum.INGRESADA) ||
                          license.id < 1999999 ||
                          license.statusId ===
                          LicStatusEnum.PENDIENTE_LARGO_TRATAMIENTO ||
                          license.statusId ===
                          LicStatusEnum.AUTORIZADA_LARGO_TRATAMIENTO ||
                          license.statusId ===
                          LicStatusEnum.ALTA_LARGO_TRATAMIENTO ||
                          license.statusId === LicStatusEnum.AUTORIZADA ||
                          license.statusId === LicStatusEnum.RECHAZADA ||
                          license.statusId === LicStatusEnum.ANULADA
                        }
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        color="err"
                        onClick={() => setDialogOpen(true)}
                        startIcon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="rotate-45 mr-1"
                            width="28"
                            height="25"
                            viewBox="0 0 24 28"
                            fill="#ff3d8b"
                          >
                            <path
                              d="M13 7.5C13 6.94772 12.5523 6.5 12 6.5C11.4477 6.5 11 6.94772 11 7.5H13ZM11 16.5C11 17.0523 11.4477 17.5 12 17.5C12.5523 17.5 13 17.0523 13 16.5H11ZM16.5 13C17.0523 13 17.5 12.5523 17.5 12C17.5 11.4477 17.0523 11 16.5 11V13ZM7.5 11C6.94772 11 6.5 11.4477 6.5 12C6.5 12.5523 6.94772 13 7.5 13V11ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM11 7.5L11 12H13L13 7.5H11ZM11 12V16.5H13V12H11ZM16.5 11H12V13H16.5V11ZM12 11H7.5V13H12V11Z"
                              fill="#ff3d8b"
                            />
                          </svg>
                        }
                      >
                        Anular
                      </Button>
                    </div>
                  </Box>
                  
                  <button
                    hidden={
                      license.statusId === LicStatusEnum.ANULADA ||
                      license.statusId === LicStatusEnum.RECHAZADA ||
                      license.statusId === LicStatusEnum.CERRADA ||
                      license.statusId === LicStatusEnum.INVALID ||
                      license.statusId ===
                      LicStatusEnum.RECHAZADAPORINCONSISTENCIA ||
                      license.statusId ===
                      LicStatusEnum.PENDIENTE_LARGO_TRATAMIENTO ||
                      license.statusId === LicStatusEnum.ALTA_LARGO_TRATAMIENTO ||
                      license.statusId === LicStatusEnum.CERRADA ||
                      license.appointmentId ||
                      license.fechaDeTurno
                    }
                    onClick={handleOpenBukeala}
                    className="rounded-md w-full bg-rose-600 text-white p-2 text-center "
                  >
                    Podés gestionar y consultar tu{" "}
                    <span className="font-semibold">turno</span> desde acá{" "}
                    <span className="underline cursor-pointer">Agendar</span>
                  </button>
                </>
              ) : (
                <>
                  {(activeButton !== "historial" && license.regimen) && (
                    <Box>
                      <Typography className="card-label">REGIMEN</Typography>
                      <Typography className="card-value">
                        {license.regimen}
                      </Typography>
                    </Box>
                  )}
                  <Box className="box-btn">
                    {activeButton !== "historial" && (
                      <>
                        <div
                          className="box-btn MuiBox-root css-0"
                          style={{ display: "block" }}
                        >
                          <div className="w-full justify-between flex">
                            <div className="flex flex-col">
                              <Typography className="card-label">
                                UNIDADES ORGANIZATIVA/S
                              </Typography>
                              <Typography className="card-value">
                                {license.unidades?.map((x, i) => (
                                  <p className="mb-3 sm:mb-1" key={i}>
                                    {x.description}
                                  </p>
                                ))}
                              </Typography>
                              {
                                license.tipoLicencia == "MEH" && license.idNotificacion &&
                                  <p className="flex text-left text-[#ff3d8b] text-sm w-fit ">*Plazo para carga de documentación respaldatoria.</p>
                              }
                            </div>
                            
                            
                            
                            <div
                              className="w-fit flex items-center sm:flex-row flex-col gap-2 mr:0 sm:mr-1"
                              style={{ marginTop: "10px" }}
                            >
                              
                              <Button
                                variant="outlined"
                                color="terciary"
                                style={{ textTransform: "capitalize" }}
                                onClick={() => handleOpenDetail(license)}
                                className="w-[110px]"
                                startIcon={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 30 26"
                                    fill="#556fff"
                                  >
                                    <path
                                      d="M13 7.5C13 6.94772 12.5523 6.5 12 6.5C11.4477 6.5 11 6.94772 11 7.5H13ZM11 16.5C11 17.0523 11.4477 17.5 12 17.5C12.5523 17.5 13 17.0523 13 16.5H11ZM16.5 13C17.0523 13 17.5 12.5523 17.5 12C17.5 11.4477 17.0523 11 16.5 11V13ZM7.5 11C6.94772 11 6.5 11.4477 6.5 12C6.5 12.5523 6.94772 13 7.5 13V11ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM11 7.5L11 12H13L13 7.5H11ZM11 12V16.5H13V12H11ZM16.5 11H12V13H16.5V11ZM12 11H7.5V13H12V11Z"
                                      fill="#556fff"
                                    />
                                  </svg>
                                }
                              >
                                Ver mas
                              </Button>

                              <Button
                                className="w-[110px] tracking-widest ml:0 sm:ml-1"
                                hidden={
                                  !license.anular ||
                                  license.id < 1999999 ||
                                  isFechaPasada(license.createDate) ||
                                  hiddenAnular ||
                                  (license.fechaCitacion &&
                                    license.statusId === LicStatusEnum.INGRESADA) ||
                                  license.statusId ===
                                  LicStatusEnum.PENDIENTE_LARGO_TRATAMIENTO ||
                                  license.statusId ===
                                  LicStatusEnum.AUTORIZADA_LARGO_TRATAMIENTO ||
                                  license.statusId ===
                                  LicStatusEnum.ALTA_LARGO_TRATAMIENTO ||
                                  (license.title === "Examen / Días de estudio" &&
                                    license.statusId ===
                                    LicStatusEnum.CARGA_DOC_RESPALDATORIA_PENDIENTE) ||
                                  license.statusId === LicStatusEnum.AUTORIZADA ||
                                  license.statusId === LicStatusEnum.RECHAZADA ||
                                  license.statusId ===
                                  LicStatusEnum.DOC_RESPALDATORIA_MODIFICAR ||
                                  license.statusId === LicStatusEnum.ANULADA
                                }
                                variant="outlined"
                                style={{ textTransform: "capitalize" }}
                                color="err"
                                onClick={() => setDialogOpen(true)}
                                startIcon={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="rotate-45 mr-1"
                                    width="28"
                                    height="25"
                                    viewBox="0 0 24 28"
                                    fill="#ff3d8b"
                                  >
                                    <path
                                      d="M13 7.5C13 6.94772 12.5523 6.5 12 6.5C11.4477 6.5 11 6.94772 11 7.5H13ZM11 16.5C11 17.0523 11.4477 17.5 12 17.5C12.5523 17.5 13 17.0523 13 16.5H11ZM16.5 13C17.0523 13 17.5 12.5523 17.5 12C17.5 11.4477 17.0523 11 16.5 11V13ZM7.5 11C6.94772 11 6.5 11.4477 6.5 12C6.5 12.5523 6.94772 13 7.5 13V11ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM11 7.5L11 12H13L13 7.5H11ZM11 12V16.5H13V12H11ZM16.5 11H12V13H16.5V11ZM12 11H7.5V13H12V11Z"
                                      fill="#ff3d8b"
                                    />
                                  </svg>
                                }
                              >
                                Anular
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Box>
                
                  {(license.tipoLicencia === "MED" ||
                    license.tipoLicencia === "MEH" ||
                    license.tipoLicencia === "MEF") && (
                      <p
                        hidden={
                          license.statusId !== LicStatusEnum.ANULADA ||
                          motivosPermitidos.find(
                            (x) => x === license.motivoNoAprobacion
                          ) === undefined ||
                          license.sustitute || validarFecha24(license)
                        }
                        className="rounded-md w-full bg-rose-600 text-center text-white p-2 flex flex-col sm:flex-row justify-between"
                      >
                        Aún tenés tiempo para generar tu licencia sustituta
                        <span
                          onClick={() => setOpenSustituta(true)}
                          className="shadow rounded-md bg-rose-700  font-medium cursor-pointer hover:scale-105 py-1 duration-200"
                        >
                          COMENZÁ DESDE AQUÍ
                        </span>
                      </p>
                    )}
                </>
              )}
            </Box>
          </Box>
        )}

      <Dialog
        open={(openBukeala.open && openBukeala.url) || openBukealaError}
        onClose={() => {
          setOpenBukeala({ url: null, open: false });
          setOpenBukealaError(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {
          openBukealaError ?
            <>
              <DialogTitle id="alert-dialog-title">
                Error
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Ocurrio un error. Verifique que su email sea correcto o intente de nuevo más tarde
                </DialogContentText>
              </DialogContent>
            </>
            :
            <div className="w-[500px] h-[500px] flex justify-center items-center">
              <iframe
                className="w-full h-full"
                title="bukeala"
                src={`${process.env.REACT_APP_BUKEALA_FINDCUSTOMER}${openBukeala.url}`}
                frameborder="0"
              ></iframe>
            </div>
        }
      </Dialog>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent>
          <Box className="flex flex-col gap-2">
            <h1 className="text-xl text-center font-semibold text-[#5836ff]">
              {license.idNotificacion ? (
                <p>
                  Estás por anular una notificación de ausencia. <br />
                  ¿Deseás continuar?
                </p>
              ) : (
                "¿Estás seguro/a de anular tu solicitud?"
              )}
            </h1>
            <p className="text-center">
              Recordá que, en caso de continuar, esta acción no puede
              revertirse. Escribí el motivo por el cual se anula la solicitud de
              licencia.
            </p>
            <TextField
              multiline
              fullWidth
              rows={3}
              value={comentario}
              onChange={(e) => {
                if (e.target.value.length > 250) {
                  return;
                }
                setComentario(e.target.value);
              }}
            />
          </Box>
          {(comentario.length < 10 || comentario.length > 250) && (
            <p className="mt-1 text-red-500">
              Mínimo 10 caracteres - Máximo 250 caracteres
            </p>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            pb: 3,
            pl: 4,
            pr: 4,
          }}
        >
          <Button
            onClick={() => setDialogOpen(false)}
            size="small"
            variant="contained"
            autoFocus
            color="err"
            style={{ color: "#FFF" }}
            className="w-full"
          >
            NO
          </Button>
          <Button
            className="w-full"
            disabled={comentario.length < 10 || comentario.length > 250}
            onClick={handleAnular}
            size="small"
            variant="contained"
            autoFocus
          >
            SI
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openSustituta} onClose={() => setOpenSustituta(false)}>
        <DialogContent>
          <Box className="flex flex-col gap-2">
            <h1 className="text-xl text-center font-semibold text-[#5836ff]">
              Solicitá tu Licencia Sustituta
            </h1>
            <p className="text-center font-medium">
              Tenés tiempo hasta las 23:59 hs. del día de hoy.
            </p>
            <p className="text-center">
              ¿Deseas generar una nueva licencia{" "}
              {
                LicTypesArray?.find((lic) => lic.code === license.tipoLicencia)
                  ?.label
              }{" "}
              que reemplace la ANULADA?
            </p>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            pb: 3,
            pl: 4,
            pr: 4,
          }}
        >
          <Button
            onClick={() => setOpenSustituta(false)}
            size="small"
            variant="contained"
            autoFocus
            color="err"
            style={{ color: "#FFF" }}
            className="w-full"
          >
            NO
          </Button>
          <Button
            className="w-full"
            onClick={completarLicenciaSustita}
            size="small"
            variant="contained"
            autoFocus
          >
            SI
          </Button>
        </DialogActions>
      </Dialog>
      <div className="absolute flex justify-center items-center">
        <DocRespaldatoria
          refreshData={refreshData}
          open={open}
          creacion={false}
          handleClose={handleClose}
          licenseId={license.id}
        />
      </div>
      <Dialog
        open={responseOpen.open}
        onClose={() =>
          setResponseOpen({
            message: "",
            open: false,
          })
        }
      >
        <DialogContent>{responseOpen.message}</DialogContent>
      </Dialog>
      {openDetail && (
        <ModalDetails
          setOpenNotification={setOpenNotification}
          onClose={handleCloseModal}
          refreshData={refreshData}
          openDetail={openDetail}
          setOpenDetail={setOpenDetail}
          license={license}
        />
      )}
    </>
  );
}
