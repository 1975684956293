import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import YourData from "./yourData";
import ContactData from "./contactData";
import CurrentAddress from "./currentAddress";
import ConfirmData from "./confirmData";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TermsAndConditions from "../termsAndConditions";
import {
  reset,
  saveUserAsync,
  setFirstEmail,
  setTerminosYCondiciones,
} from "../userSlice";
import Logout from "@mui/icons-material/Logout";
import { useAuth0 } from "@auth0/auth0-react";
export default function Registration() {
  const usuario = useSelector((state) => state.usuario);
  const [terminosYCondiciones, showModal] = React.useState(
    usuario.datos.aceptaTerminosYCondiciones
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();
  const [activeStep, setActiveStep] = React.useState(0);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [emailExist, setEmailExist] = React.useState(null);
  const steps = [
    "Tus datos",
    "Datos de Contacto",
    "Domicilio Actual",
    "Confirmá tus Datos",
  ];
  const [errEmail, setErrEmail] = React.useState(false);
  const forms = [
    <YourData />,
    <ContactData
      emailExist={emailExist}
      setEmailExist={setEmailExist}
      errEmail={errEmail}
      setErrEmail={setErrEmail}
    />,
    <CurrentAddress />,
    <ConfirmData />,
  ];

  React.useEffect(() => {
    if (usuario.datos?.idHR === "") navigate("/login");
  }, []);

  const handleNext = async () => {
    if (activeStep === 3) {
      dispatch(
        saveUserAsync({
          datosPersonales: usuario.datos,
          domicilio: usuario.domicilio,
          telefono: usuario.domicilio.telefono,
        })
      ).then((response) => {
        if (response.error) {
          setError({
            show: true,
            data: response?.payload?.errors[0]?.description,
          });
        } else {
          dispatch(setFirstEmail(usuario.datos.email));
          navigate("/select-profile");
        }
      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    setModalOpen(true);
  };

  const handleModalCancel = () => {
    setModalOpen(false);
  };

  const handleCancelRegistration = () => {
    dispatch(reset());
    navigate("/login");
  };

  const handleAccept = () => {
    showModal(true);
    dispatch(setTerminosYCondiciones());
  };
  const [error, setError] = React.useState({ show: false, data: "" });

  const handleLogout = () => {
    loginWithRedirect();
  };

  const validateTotalDigits = () => {
    let areaCode = usuario.domicilio?.telefono?.areaCode;
    let phoneNumber = usuario.domicilio?.telefono?.phoneNumber;
    const totalDigits = (areaCode + phoneNumber).length;
    return totalDigits !== 10;
  };

  return (
    <>
      <div className="absolute right-5 -top-5">
        <Button onClick={handleCancel} className="flex items-center">
          <Logout fontSize="small" className="mr-2" />
          <Typography variant="label">Cerrar sesión</Typography>
        </Button>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-5 mb-2 mb-lg-0">
        <>
          {
            <TermsAndConditions
              show={!terminosYCondiciones}
              handleAccept={handleAccept}
            />
          }
          <Grid>
            <Box>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step color="primary" key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {forms[activeStep]}

              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    pt: 2,
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    hidden={activeStep === 0}
                    onClick={handleBack}
                    sx={{
                      width: "200px",
                      mr: 1,
                      textTransform: "none",
                      mx: "auto",
                    }}
                  >
                    Volver
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={
                      activeStep === 1
                        ? validateTotalDigits() ||
                          usuario.domicilio?.telefono?.countryCode?.length <
                            1 ||
                          usuario.datos?.email?.length < 3 ||
                          errEmail ||
                          emailExist :
                        activeStep === 2
                        ? !usuario.domicilio?.validado
                        : false
                    }
                    //     ||
                    //     ((activeStep === 2) && !usuario.domicilio?.validado)
                    // }
                    sx={{ width: "200px", textTransform: "none", mx: "auto" }}
                  >
                    {" "}
                    {activeStep === steps.length - 1
                      ? "Confirmar"
                      : "Siguiente"}
                  </Button>
                </Box>
              </React.Fragment>
              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 2,
                    pb: 2,
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="err"
                    onClick={handleCancel}
                    sx={{ width: "200px", mr: 1, textTransform: "none" }}
                  >
                    Cancelar
                  </Button>
                </Box>
              </React.Fragment>
            </Box>
          </Grid>
          <Dialog open={modalOpen} onClose={handleModalCancel}>
            <DialogTitle>¿Deseás abandonar el registro?</DialogTitle>
            <DialogContent>
              <p>Se perderán todos los datos cargados</p>
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "center", pb: 3 }}
            >
              <Button
                onClick={handleModalCancel}
                size="small"
                color="primary"
                variant="outlined"
              >
                Cancelar
              </Button>
              <Button
                onClick={handleCancelRegistration}
                size="small"
                color="primary"
                variant="contained"
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={error.show} onClose={handleModalCancel}>
            <DialogTitle>{error.data}</DialogTitle>
            <DialogContent>
              <p className="text-center">Por favor, complete sus datos nuevamente</p>
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "center", pb: 3 }}
            >
              <Button
                onClick={handleLogout}
                size="small"
                color="primary"
                variant="contained"
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      </div>
    </>
  );
}
