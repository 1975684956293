import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open:false,
  severity:undefined,
  message: ""
};

export const alertSlice = createSlice({
  name:"alerts",
  initialState,
  reducers: {
    onClose:() => initialState,
    onOpen: (state,action) => {
      state.open = true;
      state.severity = action.payload.severity;
      state.message = action.payload.message;
    }
  } 
})

export const {onClose , onOpen} = alertSlice.actions;
export default alertSlice.reducer;