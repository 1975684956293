import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState, createRef } from "react";
import InfoIcon from "@mui/icons-material/Info";
import {
  fetchHistoryLicenseById,
  fetchLiberarLicencia,
  fetchOneArtLicenciaAsync,
  fetchTomarLicencia,
  postExecuteAction,
  removeOneLicense,
  putObservarFile
} from "../auditSlice";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import { formatDate } from "../../../utils";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Localidades, Provincias } from "../../user/registration/locations";
import { removeHistory } from "../auditSlice";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { LicStatusEnum } from "../../../interfaces/LicStatusEnum";
import { handleConfirmModal } from "../../modals/modalSlice";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import TableLicenseHistory from "./TableLicenseHistory";

function Detail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { oneLicence, history } = useSelector((state) => state.auditLicence);
  const { datos, perfilActual } = useSelector((state) => state.usuario);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const licenseTypeId = queryParams.get("licenseTypeId");
  useEffect(() => {
    dispatch(fetchOneArtLicenciaAsync({ id, licenseTypeId })).then((x) => {
      setEditarNroExpediente(x.payload.nroExpediente);
      if (x.payload.idTomadaPor && x.payload.idTomadaPor !== datos.id) {
        dispatch(
          handleConfirmModal({
            open: true,
            content: `La licencia está tomada por el/la auditor/a: ${x.payload.tomadaPor}. No podrás realizar ninguna acción hasta que se libere.`,
            showCancel: false,
          })
        );
      }
    });
    return () => {
      dispatch(removeOneLicense());
    };
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModal, handleModal] = React.useState({
    open: false,
    message: "",
    action: "",
  });

  const handleTomarLicencia = () => {
    dispatch(
      fetchTomarLicencia({ userId: datos.id, licenceId: oneLicence.id })
    ).then((res) => {
      dispatch(fetchOneArtLicenciaAsync({ id, licenseTypeId }));
      handleModal({ open: false });
      setRtaActionLicencia({
        open: true,
        success: res.payload.success,
        message: res.payload.message,
      });
    });
  };

  const handleLiberarLicencia = () => {
    dispatch(
      fetchLiberarLicencia({ userId: datos.id, licenceId: oneLicence.id })
    ).then((res) => {
      dispatch(fetchOneArtLicenciaAsync({ id, licenseTypeId }));
      handleModal({ open: false });
      setRtaActionLicencia({
        open: true,
        success: res.payload.success,
        message: res.payload.message,
      });
    });
  };

  const [openHistory, setOpenHistory] = React.useState(false);
  const [openInfoAuditoria, setOpenInfoAuditoria] = React.useState(false);

  useEffect(() => {
    if (openHistory) {
      dispatch(fetchHistoryLicenseById(oneLicence.id));
    } else {
      dispatch(removeHistory());
    }
  }, [openHistory]);

  const [openAprobar, setOpenAprobar] = React.useState(false);
  const [openNoAprobar, setOpenNoAprobar] = React.useState(false);
  const [openCerrar, setOpenCerrar] = React.useState(false);
  const [firstValue, setFirstValue] = useState(undefined);
  const [secondValue, setSecondValue] = useState(undefined);
  const [thirdValue, setThirdValue] = useState(undefined);
  const [input1, input2, input3] = Array.from({ length: 3 }, () =>
    createRef(null)
  );

  const onEditBlur = (value, index) => {
    if (index === 0) {
      let inputValue = value.slice(0, 8);
      const leadingZeros = "00000000";
      inputValue = leadingZeros?.slice(0, 8 - inputValue.length) + inputValue;
      setFirstValue(inputValue);
    } else if (index === 1) {
      let inputValue = value.slice(0, 3);
      const leadingZeros = "000";
      inputValue = leadingZeros?.slice(0, 3 - inputValue.length) + inputValue;
      setSecondValue(inputValue);
    } else if (index === 2) {
      let inputValue = value.slice(0, 2);
      const leadingZeros = "00";
      inputValue = leadingZeros?.slice(0, 2 - inputValue.length) + inputValue;
      setThirdValue(inputValue);
    }
  };

  const [handleAceptarAprobar, setHandleAceptarAprobar] = useState(false);
  const [handleAceptarNoAprobar, setHandleAceptarNoAprobar] = useState(false);
  const [handleAceptarCerrar, setHandleAceptarCerrar] = useState(false);
  const [motivoNoAprobar, setMotivoNoAprobar] = useState(null);
  const [motivoCerrar, setMotivoCerrar] = useState(null);
  const [newDateSiniestro, setNewDateSiniestro] = useState(null);
  const [editarDateSiniestro, setEditarDateSiniestro] = useState(null);
  const [editarHoraSiniestro, setEditarHoraSiniestro] = useState(null);

  const [rtaActionLicencia, setRtaActionLicencia] = React.useState({
    success: false,
    open: false,
    message: "",
  });

  const getInitialData = () => {
    const initialData = {
      operacion: "ACCION",
      fecOperacion: formatDate(moment()),
      idHR: oneLicence.idhr,
      numSiniestroProv: oneLicence.nroSiniestroOtorgado,
      idTramite: `${oneLicence.id}`,
      perfilMiA: "AUDITOR_ART",
      prevalidacion: "NO",
      fecDenuncia: formatDate(oneLicence.fechaDenuncia),
      fecSiniestro:
        newDateSiniestro === null
          ? formatDate(oneLicence.fechaSiniestro)
          : formatDate(newDateSiniestro),
      idRegimen: oneLicence.codeRegimen,
      tipoART: oneLicence.licenseCode[0],
      descripcionSiniestro: oneLicence.descripcionSiniestro ?? "",
      horaSiniestro: formatDate(oneLicence.horaSiniestro, true)
        .split(" ")[1]
        .concat(":00"),
      horarioLaboralHasta: formatDate(oneLicence.horarioLaboralHasta, true)
        .split(" ")[1]
        .concat(":00"),
      horaOperacion: formatDate(moment(), true).split(" ")[1].concat(":00"),
      latitud: 0,
      idGrupoLicencias: oneLicence.tipoLicencia,
      longitud: 0,
      horarioLaboralDesde: formatDate(oneLicence.horarioLaboralDesde, true)
        .split(" ")[1]
        .concat(":00"),
      usuarioMiA: `${datos.cuit
        .toString()
        .substring(0, 2)}-${datos.cuit
          .toString()
          .substring(2, 10)}-${datos.cuit.toString().substring(10)}`,
      codLicencia: oneLicence.licenseCode,
    };
    return initialData;
  };

  const handleAction = (event) => {
    switch (event) {
      case "LIBERAR":
        handleLiberarLicencia();
        break;
      case "TOMAR":
        handleTomarLicencia();
        break;
      case "CERRAR":
        handleSubmitCerrar();
        break;
      case "EDITAR":
        handleSubmitEditar();
        break;
      default:
        break;
    }
  };

  const handleSubmitAprobar = () => {
    const initialData = getInitialData();
    const data = {
      ...initialData,
      accion: "APROBAR",
      estado: "APROBADA",
      numSiniestroDef: firstValue + "/" + secondValue + "/" + thirdValue,
    };

    dispatch(
      postExecuteAction({
        data,
        action: 1,
        userId: datos.id,
        licenseId: oneLicence.id,
      })
    ).then((res) => {
      setFirstValue(undefined);
      setSecondValue(undefined);
      setThirdValue(undefined);
      setOpenAprobar(false);
      setHandleAceptarAprobar(false);
      setRtaActionLicencia({
        open: true,
        success: res.payload.success,
        message: res.payload.message,
      });
      dispatch(fetchOneArtLicenciaAsync({ id, licenseTypeId }));
    });
  };

  const handleSubmitNoAprobar = () => {
    const initialData = getInitialData();
    const data = {
      ...initialData,
      accion: "NO APROBAR",
      motivoNoAprobacion: motivoNoAprobar,
      estado: "RECHAZADA",
    };
    dispatch(
      postExecuteAction({
        data,
        action: 2,
        userId: datos.id,
        licenseId: oneLicence.id,
      })
    ).then((res) => {
      setOpenNoAprobar(false);
      setHandleAceptarNoAprobar(false);

      if (res.payload.success) navigate(perfilActual.rootPath);
      else {
        setRtaActionLicencia({
          open: true,
          success: res.payload.success,
          message: res.payload.message,
        });
      }
    });
  };

  function supera72Horas(fechaParametro) {
    // Obtén la fecha actual en milisegundos
    var fechaActual = new Date().getTime();

    // Convierte la fecha del parámetro a objeto Date
    var fechaParametroObj = new Date(fechaParametro);

    // Obtén la fecha del parámetro en milisegundos
    var fechaParametroMillis = fechaParametroObj.getTime();

    // Calcula la diferencia en milisegundos
    var diferencia = fechaActual - fechaParametroMillis;

    // Convierte 72 horas a milisegundos (1 hora = 3600 segundos = 3600 * 1000 milisegundos)
    var limiteMillis = 72 * 3600 * 1000;

    // Compara la diferencia con el límite de 72 horas y devuelve true si es mayor
    return diferencia > limiteMillis;
  }

  const handleSubmitCerrar = () => {
    const initialData = getInitialData();
    let fechaAlta = moment(fechaCierre);
    fechaAlta.add(1, "days");
    const data = {
      ...initialData,
      accion: "CERRAR",
      estado: "CERRADA",
      motivoCierre: motivoCerrar,
      numSiniestroDef: firstValue + "/" + secondValue + "/" + thirdValue,
      fechaAlta: formatDate(fechaAlta),
      fechaCierre: formatDate(fechaCierre),
      numExpediente: "",
    };

    dispatch(
      postExecuteAction({
        data,
        action: 3,
        userId: datos.id,
        licenseId: oneLicence.id,
      })
    ).then((res) => {
      setOpenCerrar(false);
      setHandleAceptarCerrar(false);
      setRtaActionLicencia({
        open: true,
        success: res.payload.success,
        message: res.payload.message,
      });
      dispatch(fetchOneArtLicenciaAsync({ id, licenseTypeId }));
    });
  };

  const [openEditar, setOpenEditar] = useState(false);

  const getAge = () => {
    const birthdate = new Date(datos?.fecNacimiento);
    const diff = new Date() - birthdate;
    const age = Math.floor(diff / 31557600000);
    return age;
  };

  const handleSubmitEditar = () => {
    const initialData = getInitialData();
    const data = {
      ...initialData,
      accion: "MODIFICAR",
      fecSiniestro:
        editarDateSiniestro === null
          ? formatDate(oneLicence.fechaSiniestro)
          : formatDate(editarDateSiniestro),
      motivoNoAprobacion: motivoNoAprobar,
      estado: oneLicence.status,
      horaSiniestro:
        editarHoraSiniestro === null
          ? formatDate(oneLicence.horaSiniestro, true)
            .split(" ")[1]
            .concat(":00")
          : formatDate(editarHoraSiniestro, true).split(" ")[1].concat(":00"),
      numSiniestroDef: firstValue + "/" + secondValue + "/" + thirdValue,
      numExpediente: editarNroExpediente,
    };

    dispatch(
      postExecuteAction({
        data,
        action: 5,
        userId: datos.id,
        licenseId: oneLicence.id,
      })
    ).then((res) => {
      setFirstValue(undefined);
      setSecondValue(undefined);
      setThirdValue(undefined);
      setOpenEditar(false);
      handleModal({ open: false });
      setRtaActionLicencia({
        open: true,
        success: res.payload.success,
        message: res.payload.message,
      });
      dispatch(fetchOneArtLicenciaAsync({ id, licenseTypeId }));
    });
  };

  const [editarNroExpediente, setEditarNroExpediente] = useState("");

  const handleDownloadFiles = (file) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = file.name;
    link.target = "_blank"; // Abre el archivo en una nueva ventana/tab
    link.click();
    URL.revokeObjectURL(link.href); // Liberar recursos después de la descarga
  };
  useEffect(() => {
    if (oneLicence?.nroSiniestroDefinido !== null) {
      let firstValue = oneLicence?.nroSiniestroDefinido?.split("/")[0];
      let secondValue = oneLicence?.nroSiniestroDefinido?.split("/")[1];
      let thirdValue = oneLicence?.nroSiniestroDefinido?.split("/")[2];
      setFirstValue(firstValue);
      setSecondValue(secondValue);
      setThirdValue(thirdValue);
    }
  }, [oneLicence, openEditar]);

  const navigate = useNavigate();

  const pageAct = queryParams.get("pageAct");
  const [editImg, setEditImg] = useState({
    scale: 1,
    rotate: 0,
  });
  const [fechaCierre, setFechaCierre] = React.useState(null);
  const [fechaAlta, setFechaAlta] = React.useState(null);
  const [verCertificado, setVerCertificado] = useState({
    open: false,
    isImage: null,
    src: null,
    name: null,
    type: null,
    fileObj: null,
  });
  return (
    <>
      <div>
        <header className="flex flex-wrap bg-[#f5f5f5] p-[15px] items-center justify-between px-[30px]">
          <h1 className="text-[#5836ff] font-medium text-2xl">
            Detalle de la Solicitud
          </h1>
          <Link to={`/audit?pageAct=${pageAct}`}>
            <Button variant="contained">Volver a licencias</Button>
          </Link>
        </header>
        <section className="text-end flex w-full justify-center items-center sm:justify-end px-[30px] gap-2 py-[10px]">
          {oneLicence?.tomadaPor && (
            <Typography variant="p" fontWeight="medium" mr="auto" p={2}>
              Tomada por: {oneLicence?.tomadaPor}
            </Typography>
          )}
          {oneLicence?.statusId === LicStatusEnum.ANULADA ||
            oneLicence?.statusId === LicStatusEnum.RECHAZADA ||
            oneLicence?.statusId === LicStatusEnum.CERRADA ? (
            <Button disabled>No hay acciones para esta licencia</Button>
          ) : oneLicence?.statusId === LicStatusEnum.APROBADA &&
            oneLicence?.idTomadaPor === datos.id ? (
            <>
              <Button
                color="terciary"
                variant="outlined"
                onClick={() => {
                  handleModal({
                    open: true,
                    action: "LIBERAR",
                    message: `¿Querés liberar la licencia ID: <b>${oneLicence?.id}</b>?`,
                  });
                  handleClose();
                }}
              >
                Liberar
              </Button>
              <Button
                color="err"
                variant="outlined"
                onClick={() => {
                  handleClose();
                  setOpenCerrar(true);
                }}
              >
                Cerrar licencia
              </Button>
            </>
          ) : (
            <Button
              disabled={
                (oneLicence?.idTomadaPor &&
                  oneLicence?.idTomadaPor !== datos.id) ||
                oneLicence.isHistorical
              }
              variant="outlined"
              onClick={handleClick}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Acciones
            </Button>
          )}
          {(oneLicence?.statusId === LicStatusEnum.EN_REVISION ||
            oneLicence?.statusId === LicStatusEnum.REGISTRADAENPRESTADORA ||
            oneLicence?.statusId === LicStatusEnum.NO_REGISTRADA ||
            oneLicence?.statusId === LicStatusEnum.APROBADA) && (
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleModal({
                      open: true,
                      action: "TOMAR",
                      message: `Estás por tomar la licencia ID: <b>${oneLicence?.id}.</b> <br> ¿Deseás Continuar?`,
                    });
                    handleClose();
                  }}
                >
                  Tomar licencia
                </MenuItem>
              </Menu>
            )}
          {oneLicence?.tomadaPor && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleModal({
                    open: true,
                    action: "LIBERAR",
                    message: `¿Querés liberar la licencia ID: <b>${oneLicence?.id}</b>?`,
                  });
                  handleClose();
                }}
              >
                Liberar licencia
              </MenuItem>
              {oneLicence?.idTomadaPor === datos.id && (
                <>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenAprobar(true);
                    }}
                  >
                    Aprobar
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenNoAprobar(true);
                    }}
                  >
                    No aprobar
                  </MenuItem>
                </>
              )}
              {oneLicence?.idTomadaPor === datos.id &&
                oneLicence?.statusId === LicStatusEnum.APROBADA && (
                  <>
                    <MenuItem>Cerrar</MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpenEditar(true);
                        handleClose();
                      }}
                    >
                      Editar
                    </MenuItem>
                  </>
                )}
            </Menu>
          )}
          <Button
            onClick={() => navigate(`/audit/history/${id}?pageAct=${pageAct}&licenseTypeId=${licenseTypeId}`)}
            variant="outlined"
          >
            Ver historial
          </Button>
        </section>
        <div className="flex flex-col sm:flex-row px-[30px] py-[10px]">
          <aside className="w-full">
            <h1 className="font-medium text-slate-400 mb-2">
              Información de la licencia
            </h1>
            <Divider />
            <section className="flex flex-wrap gap-5 py-4">
              <TextField
                label="ID"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.id}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Estado"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.status}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Licencia"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.subtitle}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Tipo de Licencia"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.title}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Fecha y hora de creación"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formatDate(oneLicence.createDate, true)}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Fecha de Denuncia"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formatDate(oneLicence.fechaDenuncia)}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Fecha y hora de siniestro"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {
                        formatDate(oneLicence.fechaSiniestro, true).split(
                          " "
                        )[0]
                      }{" "}
                      {formatDate(oneLicence.horaSiniestro, true).split(" ")[1]}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Jornada laboral"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="font-light">
                      {
                        formatDate(oneLicence.horarioLaboralDesde, true)?.split(
                          " "
                        )[1]
                      }{" "}
                      a{" "}
                      {
                        formatDate(oneLicence.horarioLaboralHasta, true)?.split(
                          " "
                        )[1]
                      }
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Nro siniestro otorgado"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="font-light">
                      {oneLicence.nroSiniestroOtorgado}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Nro siniestro definitivo"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="font-light">
                      {oneLicence.nroSiniestroDefinido ?? "---"}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Nro expediente"
                id="nroexpediente"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="font-light">
                      {oneLicence.nroExpediente ?? "---"}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Descripción"
                disabled
                id="outlined-disabled"
                className="w-[40%]"
                multiline
                maxRows={3}
                defaultValue={oneLicence.descripcionSiniestro ?? ""}
                value={oneLicence.descripcionSiniestro ?? ""}
                variant="standard"
              />
            </section>

            <Box p={2}>
              <Button
                onClick={() => {
                  setOpenEditar(true);
                  handleClose();
                }}
                disabled={oneLicence?.idTomadaPor !== datos.id}
                variant="outlined"
              >
                Editar
              </Button>
            </Box>

            <Box p={2}>
              <Button
                onClick={() => {
                  setOpenInfoAuditoria(true);
                  dispatch(fetchHistoryLicenseById(oneLicence.id));
                }}
                variant="outlined"
              >
                Ver Otra Información Auditoria
              </Button>
            </Box>

            <h1 className="font-medium text-slate-400 mb-2">
              Parte médico de ingreso
            </h1>
            <Divider />
            <div className="flex flex-col gap-3 mt-2">
              {oneLicence?.files
                ?.filter((fl) => !fl.path.includes("otrosDocumentos"))
                ?.map((file) => {
                  const byteCharacters = atob(file.bytes);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);

                  const blob = new Blob([byteArray], { type: file.type });

                  // Obtén la fecha de creación del archivo como un objeto Date
                  const createdDate = new Date(file.createdDate);

                  // Crea el objeto File con el Blob y los atributos necesarios
                  const fileObj = new File([blob], file.name, {
                    lastModified: createdDate.getTime(),
                    type: file.type,
                  });
                  const fileURL = URL.createObjectURL(fileObj);
                  const srcImg = `data:${file.type};base64,${file.bytes}`;
                  const isImage = file.type.includes("image/");
                  return (
                    <div key={file.name} className="flex gap-4">
                      {file.type.includes("image/") ? (
                        <img
                          src={srcImg}
                          alt={file.name}
                          className="w-[200px] h-[200px] rounded-md object-cover"
                        />
                      ) : (
                        <embed
                          src={fileURL}
                          type={file.type}
                          className="w-[200px] h-[200px] rounded-md object-cover"
                        />
                      )}
                      {/* <button onClick={() => handleDownloadFiles(fileObj)}>Descargar</button> */}
                      <div className="flex flex-col justify-center text-center">
                        {/* <CheckCircleOutlineIcon className="text-green-600 mx-auto" />
                        <span>Certificado</span>
                        <span className="text-green-600">Valido</span> */}
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setVerCertificado({
                              open: true,
                              isImg: isImage,
                              src: isImage ? srcImg : fileURL,
                              type: file.type,
                              name: file.name,
                              fileObj,
                              fileId: file.id,
                              isObserved: file.isObserved === 0 ? false : true,
                            });
                          }}
                        >
                          Ver
                        </Button>
                        {file.isObserved === 1 && (
                          <div className="flex flex-col mt-1 justify-center items-center">
                            <span className="font-semibold">Observado</span>
                            <span>{formatDate(file.observationDateTime)}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="h-12"></div>
            <h1 className="font-medium text-slate-400 mb-2">
              Otra documentación respaldatoria
            </h1>
            <Divider />
            <div className="flex flex-col gap-3 mt-2">
              {oneLicence?.files
                ?.filter((fl) => !fl.path.includes("certificado"))
                ?.map((file) => {
                  const byteCharacters = atob(file.bytes);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);

                  const blob = new Blob([byteArray], { type: file.type });

                  // Obtén la fecha de creación del archivo como un objeto Date
                  const createdDate = new Date(file.createdDate);

                  // Crea el objeto File con el Blob y los atributos necesarios
                  const fileObj = new File([blob], file.name, {
                    lastModified: createdDate.getTime(),
                    type: file.type,
                  });
                  const fileURL = URL.createObjectURL(fileObj);
                  const srcImg = `data:${file.type};base64,${file.bytes}`;
                  const isImage = file.type.includes("image/");
                  return (
                    <div key={file.name} className="flex gap-4">
                      {file.type.includes("image/") ? (
                        <img
                          src={srcImg}
                          alt={file.name}
                          className="w-[200px] h-[200px] rounded-md object-cover"
                        />
                      ) : (
                        <embed
                          src={fileURL}
                          type={file.type}
                          className="w-[200px] h-[200px] rounded-md object-cover"
                        />
                      )}
                      {/* <button onClick={() => handleDownloadFiles(fileObj)}>Descargar</button> */}
                      <div className="flex flex-col justify-center text-center">
                        <CheckCircleOutlineIcon className="text-green-600 mx-auto" />
                        <span>Certificado</span>
                        <span className="text-green-600">Valido</span>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setVerCertificado({
                              open: true,
                              isImg: isImage,
                              src: isImage ? srcImg : fileURL,
                              type: file.type,
                              name: file.name,
                              fileObj,
                              fileId: file.id,
                              isObserved: file.isObserved === 0 ? false : true,
                            });
                          }}
                        >
                          Ver
                        </Button>
                        {file.isObserved === 1 && (
                          <div className="flex flex-col mt-1 justify-center items-center">
                            <span className="font-semibold">Observado</span>
                            <span>{formatDate(file.observationDateTime)}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="h-12"></div>
            <h1 className="font-medium text-slate-400 mb-3">Alertas</h1>
            {oneLicence.a === 1 ? (
              <span className="px-2 py-1 rounded-md shadow-sm bg-red-600 text-white">
                INACCIÓN RAP
              </span>
            ) : null}
            <Divider />
          </aside>
          <Divider orientation="vertical" flexItem />
          <aside className="w-full">
            <h1 className="sm:ml-[15px] font-medium text-slate-400 mb-2">
              Información personal
            </h1>
            <Divider />
            <section className="flex flex-wrap gap-5 py-4 sm:ml-[15px] ">
              <TextField
                label="ID HR"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.idhr}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Nombre"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.firstName}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Apellido"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.lastName}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Cuil"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.cuit}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Nro. Documento"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.documento}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Nro. Telefono"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.celular?.slice(0, 13)}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Edad"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{getAge()}</InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Dirección"
                id="outlined-start-adornment"
                disabled
                multiline
                maxRows={3}
                className="w-[40%]"
                value={oneLicence?.direccion ?? ""}
                type="text"
                variant="standard"
              />
              <TextField
                label="Email"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.email}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </section>
            <h1 className="sm:ml-[15px] font-medium text-slate-400 mb-2">
              {oneLicence.licenseCode === "1ACC_TRAB"
                ? "Dirección del accidente"
                : oneLicence.licenseCode === "2ACC_TRAB"
                  ? "Lugar donde ocurrió el accidente"
                  : "Domicilio de Trabajo"}
            </h1>
            <Divider />
            <section className="flex flex-wrap gap-5 py-4 sm:ml-[15px] ">
              <TextField
                label="Calle/Estación"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.domicilioAccidente?.calle}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Numero/Altura"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.domicilioAccidente?.altura}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Entre calles/Estaciones"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">---</InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Piso"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.domicilioAccidente?.piso
                        ? oneLicence.domicilioAccidente?.piso
                        : "---"}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Departamento"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.domicilioAccidente?.depto
                        ? oneLicence.domicilioAccidente?.depto
                        : "---"}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Codigo postal"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.domicilioAccidente?.codPostal
                        ? oneLicence.domicilioAccidente?.codPostal
                        : "---"}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Provincia"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {
                        Provincias.find(
                          (prov) =>
                            prov.id ===
                            oneLicence.domicilioAccidente?.codProvincia
                        )?.descripcion
                      }
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Localidad"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {
                        Localidades.find(
                          (loc) =>
                            loc.id ===
                            oneLicence.domicilioAccidente?.codLocalidad &&
                            loc.idProvincia ===
                            oneLicence.domicilioAccidente?.codProvincia
                        )?.descripcion
                      }
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <Button variant="outlined" onClick={() => setOpenHistory(true)}>
                Historial de Licencia
              </Button>
            </section>
          </aside>
        </div>
      </div>

      <Dialog open={openHistory} onClose={() => setOpenHistory(false)}>
        <DialogContent>
          <h1 className="text-[#5836ff] font-medium text-xl text-center">
            Historial de Licencia
          </h1>
          {history?.filter(
            (item) => item.newValue === "TOMADA" || item.newValue === "LIBERAR"
          )?.length === 0 ? (
            <div>No existe un historial para esta licencia</div>
          ) : (
            <div>
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-3 py-3">
                      AUDITOR
                    </th>
                    <th scope="col" class="px-3 py-3">
                      ACCIÓN
                    </th>
                    <th scope="col" class="px-3 py-3">
                      FECHA
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {history
                    ?.filter(
                      (item) =>
                        item.newValue === "TOMADA" ||
                        item.newValue === "LIBERAR"
                    )
                    ?.map((item) => (
                      <tr key={item.id}>
                        <th scope="row">{item.auditor}</th>
                        <td class="px-3 py-4">{item.newValue}</td>
                        <td class="px-3 py-4">
                          {dayjs(item.createdDate).format('YYYY-MM-DD HH:mm')}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => setOpenHistory(false)}
            size="small"
            variant="outlined"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={verCertificado.open}
        onClose={() =>
          setVerCertificado({
            dni: false,
            open: false,
            isImage: null,
            src: null,
            name: null,
            type: null,
            fileObj: null,
            fileId: null,
          })
        }
      >
        <DialogContent className="text-center flex flex-col gap-3">
          <div className="w-full flex flex-col gap-2">
            <div className="flex gap-1 mx-auto">
              <span>{verCertificado.dni ? "DNI:" : "Certificado:"}</span>
              <span className="text-green-600">Valido</span>
              <CheckCircleOutlineIcon className="text-green-600" />
            </div>
            {verCertificado.isImg ? (
              <TransformWrapper>
                {({ zoomIn, zoomOut }) => (
                  <>
                    <div className="flex mx-auto items-center">
                      <span className="mr-2">Editar imagen</span>
                      <Button
                        size="small"
                        onClick={() => zoomOut()}
                      >
                        <RemoveIcon />
                      </Button>
                      <Button
                        size="small"
                        onClick={() => zoomIn()}
                      >
                        <AddIcon />
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setEditImg({ ...editImg, rotate: editImg.rotate + 90 });
                        }}
                      >
                        <RotateRightIcon />
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setEditImg({ ...editImg, rotate: editImg.rotate - 90 });
                        }}
                      >
                        <RotateLeftIcon />
                      </Button>
                      <Button
                        size="small"
                        onClick={() => handleDownloadFiles(verCertificado.fileObj)}
                      >
                        <FileDownloadIcon />
                      </Button>
                    </div>
                    <TransformComponent>
                      <img
                        onP
                        src={verCertificado.src}
                        alt={verCertificado.name}
                        style={{
                          scale: `${editImg.scale}`,
                          transform: `rotate(${editImg.rotate}deg)`,
                          width: '100%',
                        }}
                        className={`w-[400px] h-[400px] mx-auto rounded-md object-contain`}
                      />

                    </TransformComponent>
                  </>
                )}
              </TransformWrapper>
            ) : (
              <>
                <div className="flex mx-auto items-center">
                  <span className="mr-2">Editar imagen</span>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditImg({ ...editImg, scale: editImg.scale - 0.2 });
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditImg({ ...editImg, scale: editImg.scale + 0.2 });
                    }}
                  >
                    <AddIcon />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditImg({ ...editImg, rotate: editImg.rotate + 90 });
                    }}
                  >
                    <RotateRightIcon />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditImg({ ...editImg, rotate: editImg.rotate - 90 });
                    }}
                  >
                    <RotateLeftIcon />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleDownloadFiles(verCertificado.fileObj)}
                  >
                    <FileDownloadIcon />
                  </Button>
                </div>
                <embed
                  src={verCertificado.src}
                  type={verCertificado.type}
                  style={{
                    width: '900px',
                    scale: `${editImg.scale}`,
                    transform: `rotate(${editImg.rotate}deg)`,
                  }}
                  className={`w-[600px] h-[400px] mx-auto rounded-md object-contain`}
                />
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() =>
              setVerCertificado({
                open: false,
                isImage: null,
                src: null,
                name: null,
                type: null,
                fileObj: null,
                fileId: null,
              })
            }
            size="small"
            variant="outlined"
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={verCertificado.isObserved}
            onClick={() =>
              dispatch(putObservarFile(verCertificado.fileId)).then((res) => {
                dispatch(fetchOneArtLicenciaAsync({ id, licenseTypeId }));
                setVerCertificado({ ...verCertificado, isObserved: true });
              })
            }
          >
            Observar
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        maxWidth="md"
        open={openInfoAuditoria}
        onClose={() => setOpenInfoAuditoria(false)}
      >
        <DialogContent>
          <h1 className="text-[#5836ff] font-medium text-xl text-center mb-1">
            Información de auditoria
          </h1>
          {history?.filter((item) => item.field !== "ACCION")?.length === 0 ? (
            <div>No existe un información para esta licencia</div>
          ) : (
            <div className="flex flex-col gap-3">
              {history
                ?.filter((item) => item.field !== "ACCION")
                ?.map((item) => (
                  <div className="flex justify-between gap-3 border-b rounded-md text-xs items-center">
                    <p className="font-medium flex flex-col text-gray-700">
                      Auditor <span>{item.auditor}</span>{" "}
                    </p>
                    <div className="flex flex-col gap-2">
                      <p className="font-medium flex flex-col text-gray-700">
                        Campo <span>{item.field}</span>{" "}
                      </p>
                      <p className="font-medium flex flex-col text-gray-700">
                        Valor asignado <span>{item.newValue}</span>{" "}
                      </p>
                      {item.oldValue && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor previo <span>{item.oldValue}</span>{" "}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      {item.fieldTwo && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Campo <span>{item.fieldTwo}</span>{" "}
                        </p>
                      )}
                      {item.newValueTwo && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor asignado <span>{item.newValueTwo}</span>{" "}
                        </p>
                      )}
                      {item.oldValueTwo && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor previo <span>{item.oldValueTwo}</span>{" "}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      {item.fieldThree && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Campo <span>{item.fieldThree}</span>{" "}
                        </p>
                      )}
                      {item.newValueThree && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor asignado <span>{item.newValueThree}</span>{" "}
                        </p>
                      )}
                      {item.oldValueThree && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor previo <span>{item.oldValueThree}</span>{" "}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      {item.fieldFour && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Campo <span>{item.fieldFour}</span>{" "}
                        </p>
                      )}
                      {item.newValueFour && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor asignado <span>{item.newValueFour}</span>{" "}
                        </p>
                      )}
                      {item.oldValueFour && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor previo <span>{item.oldValueFour}</span>{" "}
                        </p>
                      )}
                    </div>

                    <p className="font-medium flex flex-col text-gray-700">
                      Fecha{" "}
                      <span>{new Date(item.createdDate).toLocaleString()}</span>{" "}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              removeHistory();
              setOpenInfoAuditoria(false);
            }}
            size="small"
            variant="outlined"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog> */}

      <TableLicenseHistory openInfoAuditoria={openInfoAuditoria} setOpenInfoAuditoria={setOpenInfoAuditoria} />
      <Dialog
        open={openEditar}
        onClose={() => {
          setEditarNroExpediente(oneLicence.nroExpediente);
          setEditarHoraSiniestro(null);
          setOpenEditar(false);
          setFirstValue(undefined);
          setSecondValue(undefined);
          setThirdValue(undefined);
        }}
      >
        <DialogContent className="text-center">
          <h1 className="text-[#5836ff] font-medium text-xl">
            Editar información de la licencia
          </h1>
          <Box mt={2} className="flex flex-col">
            <div
              style={{
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <FormLabel
                sx={{
                  color: "#5836ff",
                  fontWeight: "bold",
                  textAlign: "start",
                }}
              >
                Fecha del siniestro
              </FormLabel>
              <Tooltip title="Importante: Podés cargar una denuncia con hasta 2 años de retroactividad.">
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </div>

            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(oneLicence?.fechaSiniestro)}
                renderInput={(props) => <TextField {...props} />}
                slotProps={{ textField: { size: "small", error: false } }}
                minDate={dayjs(oneLicence?.fechaSiniestro).subtract(2, "year")}
                maxDate={dayjs(moment(new Date()))}
                onChange={(e) => setEditarDateSiniestro(e.toISOString())}
              />
            </LocalizationProvider>
          </Box>
          {oneLicence?.licenseCode?.length > 0 &&
            oneLicence.licenseCode[0] !== "3" && (
              <Box mt={2} className="flex flex-col">
                <FormLabel
                  sx={{
                    color: "#5836ff",
                    fontWeight: "bold",
                    textAlign: "start",
                  }}
                >
                  Hora del Siniestro
                </FormLabel>
                <LocalizationProvider
                  adapterLocale="es"
                  dateAdapter={AdapterDayjs}
                >
                  <TimePicker
                    value={dayjs(oneLicence.horaSiniestro)}
                    onChange={(e) => setEditarHoraSiniestro(e.toISOString())}
                  />
                </LocalizationProvider>
              </Box>
            )}
          <Box mt={2} className="flex flex-col">
            <FormLabel
              sx={{ color: "#5836ff", fontWeight: "bold", textAlign: "start" }}
            >
              Número de siniestro definitivo
            </FormLabel>
            <div className="flex items-center gap-1">
              <TextField
                size="small"
                type="number"
                placeholder="00000000"
                className="w-[40%]"
                value={firstValue}
                defaultValue={oneLicence?.nroSiniestroDefinido?.split("/")[0]}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,8}$/.test(value)) {
                    setFirstValue(value);
                    if (value.length === 8) {
                      input2.current.focus();
                    }
                  }
                }}
                onBlur={(e) => {
                  onEditBlur(e.target.value, 0);
                }}
                inputRef={input1}
                maxLength={8}
              ></TextField>
              /
              <TextField
                size="small"
                type="number"
                placeholder="000"
                className="w-[30%]"
                value={secondValue}
                defaultValue={oneLicence?.nroSiniestroDefinido?.split("/")[1]}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,3}$/.test(value)) {
                    setSecondValue(value);
                    if (value.length === 3) {
                      input3.current.focus();
                    }
                  }
                }}
                onBlur={(e) => {
                  onEditBlur(e.target.value, 1);
                }}
                inputRef={input2}
                maxLength={3}
              ></TextField>
              /
              <TextField
                size="small"
                type="number"
                placeholder="00"
                className="w-[20%]"
                value={thirdValue}
                defaultValue={oneLicence?.nroSiniestroDefinido?.split("/")[2]}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,2}$/.test(value)) {
                    setThirdValue(value);
                  }
                }}
                onBlur={(e) => {
                  onEditBlur(e.target.value, 2);
                }}
                inputRef={input3}
                maxLength={2}
              ></TextField>
            </div>
          </Box>
          <Box mt={2} className="flex flex-col">
            <FormLabel
              sx={{ color: "#5836ff", fontWeight: "bold", textAlign: "start" }}
            >
              Número de expediente
            </FormLabel>
            <TextField
              disabled={oneLicence?.status === "REGISTRADA EN PRESTADORA"}
              size="small"
              value={editarNroExpediente}
              onChange={(e) => {
                if (e.target.value.length > 35) {
                  return;
                }
                setEditarNroExpediente(e.target.value);
              }}
            ></TextField>
            {editarNroExpediente?.length > 34 && (
              <p className="mt-1 text-red-500">Máximo 35 caracteres</p>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              setEditarNroExpediente(oneLicence.nroExpediente);
              setEditarHoraSiniestro(null);
              setOpenEditar(false);
              setFirstValue(undefined);
              setSecondValue(undefined);
              setThirdValue(undefined);
            }}
            size="small"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={!firstValue || !secondValue || !thirdValue}
            size="small"
            color="primary"
            variant="contained"
            autoFocus
            onClick={() =>
              handleModal({
                open: true,
                action: "EDITAR",
                message: ` <h1>¿Estás seguro/a de querer editar esta solicitud?</h1>
              <small>Esta acción no se puede deshacer.</small>`,
              })
            }
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={handleAceptarAprobar}
        onClose={() => setHandleAceptarAprobar(false)}
      >
        <DialogContent className="text-center flex flex-col gap-3">
          <h1>Estás por aprobar una solicitud ¿deseás continuar?</h1>
          <small>Esta acción no se puede deshacer.</small>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => setHandleAceptarAprobar(false)}
            size="small"
            variant="outlined"
          >
            No
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            autoFocus
            onClick={handleSubmitAprobar}
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={handleAceptarNoAprobar}
        onClose={() => setHandleAceptarNoAprobar(false)}
      >
        <DialogContent className="text-center flex flex-col gap-3">
          <h1>Estás por reprobar una solicitud ¿Deseás continuar?</h1>
          <small>Esta acción generará una notificación al empleado/a</small>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => setHandleAceptarNoAprobar(false)}
            size="small"
            variant="outlined"
          >
            No
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            autoFocus
            onClick={handleSubmitNoAprobar}
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={handleAceptarCerrar}
        onClose={() => setHandleAceptarCerrar(false)}
      >
        <DialogContent className="text-center flex flex-col gap-3">
          <h1>Estás por cerrar una solicitud ¿Deseás continuar?</h1>
          <small>Esta acción no se puede deshacer.</small>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => setHandleAceptarCerrar(false)}
            size="small"
            variant="outlined"
          >
            No
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            autoFocus
            onClick={handleSubmitCerrar}
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ paddingTop: 15, maxWidth: 500 }}
        open={rtaActionLicencia.open}
        autoHideDuration={4500}
        onClose={() =>
          setRtaActionLicencia({
            success: false,
            open: false,
            message: "",
          })
        }
      >
        <Alert
          onClose={() =>
            setRtaActionLicencia({
              success: false,
              open: false,
              message: "",
            })
          }
          severity={rtaActionLicencia.success ? "success" : "error"}
        >
          {rtaActionLicencia.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={openModal.open}
        onClose={() => handleModal({ open: false })}
      >
        <DialogContent className="text-center">
          <div dangerouslySetInnerHTML={{ __html: openModal.message }} />
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => handleModal({ open: false })}
            size="small"
            variant="outlined"
          >
            No
          </Button>
          <Button
            onClick={() => handleAction(openModal.action)}
            size="small"
            color="primary"
            variant="contained"
            autoFocus
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAprobar}
        onClose={() => {
          setNewDateSiniestro(null);
          setOpenAprobar(false);
          setFirstValue(undefined);
          setSecondValue(undefined);
          setThirdValue(undefined);
        }}
      >
        <DialogContent className="text-center">
          <h1 className="text-[#5836ff] font-medium text-xl">Aprobar</h1>
          <div className="mt-2 flex flex-col gap-3 bg-blue-200/30 p-3 rounded-md shadow-sm">
            <TextField
              label="Número de siniestro otorgado"
              id="outlined-start-adornment"
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {oneLicence.nroSiniestroOtorgado}
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
            <TextField
              label="Fecha de siniestro otorgado"
              id="outlined-start-adornment"
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {formatDate(oneLicence.fechaSiniestro)}
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </div>
          <Box mt={2} className="flex flex-col">
            <FormLabel
              sx={{ color: "#5836ff", fontWeight: "bold", textAlign: "start" }}
            >
              Número de siniestro definido*
            </FormLabel>
            <div className="flex items-center gap-1">
              <TextField
                size="small"
                type="number"
                placeholder="00000000"
                className="w-[40%]"
                value={firstValue}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,8}$/.test(value)) {
                    setFirstValue(value);
                    if (value.length === 8) {
                      input2.current.focus();
                    }
                  }
                }}
                onBlur={(e) => {
                  onEditBlur(e.target.value, 0);
                }}
                inputRef={input1}
                maxLength={8}
              ></TextField>
              /
              <TextField
                size="small"
                type="number"
                id="second"
                placeholder="000"
                className="w-[30%]"
                value={secondValue}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,3}$/.test(value)) {
                    setSecondValue(value);
                    if (value.length === 3) {
                      input3.current.focus();
                    }
                  }
                }}
                onBlur={(e) => {
                  onEditBlur(e.target.value, 1);
                }}
                inputRef={input2}
                maxLength={3}
              ></TextField>
              /
              <TextField
                size="small"
                type="number"
                id="third"
                placeholder="00"
                className="w-[20%]"
                value={thirdValue}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,2}$/.test(value)) {
                    setThirdValue(value);
                  }
                }}
                onBlur={(e) => {
                  onEditBlur(e.target.value, 2);
                }}
                inputRef={input3}
                maxLength={2}
              ></TextField>
            </div>
          </Box>
          <Box mt={2} className="flex flex-col">
            <FormLabel
              sx={{ color: "#5836ff", fontWeight: "bold", textAlign: "start" }}
            >
              Fecha de ocurrencia del siniestro*
            </FormLabel>
            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs(oneLicence.fechaSiniestro)}
                renderInput={(props) => <TextField {...props} />}
                slotProps={{ textField: { size: "small", error: false } }}
                minDate={dayjs(oneLicence.fechaSiniestro).subtract(2, "year")}
                maxDate={dayjs(moment(new Date()))}
                onChange={(e) => setNewDateSiniestro(e.toISOString())}
              />
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              setNewDateSiniestro(null);
              setOpenAprobar(false);
              setFirstValue(undefined);
              setSecondValue(undefined);
              setThirdValue(undefined);
            }}
            size="small"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => setHandleAceptarAprobar(true)}
            disabled={
              !firstValue ||
              firstValue?.length < 8 ||
              !secondValue ||
              secondValue?.length < 3 ||
              !thirdValue ||
              thirdValue?.length < 2
            }
            size="small"
            color="primary"
            variant="contained"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openNoAprobar}
        onClose={() => {
          setMotivoNoAprobar(null);
          setOpenNoAprobar(false);
        }}
      >
        <DialogContent className="text-center">
          <h1 className="text-[#5836ff] font-medium text-xl">No Aprobar</h1>
          <div className="mt-2 flex flex-col gap-3 bg-blue-200/30 p-3 rounded-md shadow-sm">
            <TextField
              label="Número de siniestro otorgado"
              id="outlined-start-adornment"
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {oneLicence.nroSiniestroOtorgado}
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
            <TextField
              label="Fecha de siniestro"
              id="outlined-start-adornment"
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {formatDate(oneLicence.fechaSiniestro)}
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </div>
          <FormControl size="small" sx={{ width: "100%", marginTop: "15px" }}>
            <InputLabel id="demo-simple-select-label">Motivo</InputLabel>
            <Select
              value={motivoNoAprobar}
              onChange={(e) => setMotivoNoAprobar(e.target.value)}
              name="motivo"
              label="Motivo"
            >
              <MenuItem
                value={"No existe número de siniestro otorgado por la ART"}
              >
                No existe número de siniestro otorgado por la ART
              </MenuItem>
              <MenuItem
                value={
                  "El siniestro no generó baja laboral que requiera justificar días"
                }
              >
                El siniestro no generó baja laboral que requiera justificar días
              </MenuItem>
              <MenuItem
                value={
                  "El número de siniestro ya tiene fecha de alta médica, otorgada por ART"
                }
              >
                El número de siniestro ya tiene fecha de alta médica, otorgada
                por ART
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              setMotivoNoAprobar(null);
              setOpenNoAprobar(false);
            }}
            size="small"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={!motivoNoAprobar}
            onClick={() => setHandleAceptarNoAprobar(true)}
            size="small"
            color="primary"
            variant="contained"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCerrar}
        onClose={() => {
          setMotivoCerrar(null);
          setOpenCerrar(false);
        }}
      >
        <DialogContent className="text-center">
          <h1 className="text-[#5836ff] font-medium text-xl">Cerrar</h1>
          <div className="mt-2 flex flex-col gap-3 bg-blue-200/30 p-3 rounded-md shadow-sm">
            <TextField
              label="Número de siniestro otorgado"
              id="outlined-start-adornment"
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {oneLicence.nroSiniestroOtorgado}
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
            <TextField
              label="Fecha de siniestro"
              id="outlined-start-adornment"
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {formatDate(oneLicence.fechaSiniestro)}
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />
          </div>
          <FormControl size="small" sx={{ width: "100%", marginTop: "15px" }}>
            <InputLabel id="demo-simple-select-label">Motivo</InputLabel>
            <Select
              value={motivoCerrar}
              onChange={(e) => {
                setFechaAlta(null);
                setMotivoCerrar(e.target.value);
              }}
              name="motivo"
              label="Motivo"
            >
              <MenuItem value={"Alta médica/Tratamiento cumplido"}>
                Alta médica/Tratamiento cumplido
              </MenuItem>
              <MenuItem value={"Fallecimiento"}>Fallecimiento</MenuItem>
              <MenuItem
                value={"Abandono del tratamiento por parte del/la empleado/a"}
              >
                Abandono del tratamiento por parte del/la empleado/a
              </MenuItem>
              <MenuItem
                value={
                  "Interrupción del tratamiento por afección de nueva patología"
                }
              >
                Interrupción del tratamiento por afección de nueva patología
              </MenuItem>
              <MenuItem
                value={
                  "Rechazado: siniestro no contemplado en la Ley Nº 24.557"
                }
              >
                Rechazado: siniestro no contemplado en la Ley Nº 24.557
              </MenuItem>
              <MenuItem value={"El reingreso fue denegado por la prestadora"}>
                El reingreso fue denegado por la prestadora
              </MenuItem>
              <MenuItem value={"Cierre por baja de los roles afectados a la licencia"}>
                Cierre por baja de los roles afectados a la licencia
              </MenuItem>
            </Select>
          </FormControl>
          <Box mt={2} className="flex flex-col">
            <FormLabel
              sx={{ color: "#5836ff", fontWeight: "bold", textAlign: "start" }}
            >
              Fecha de cierre
            </FormLabel>
            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
              <DatePicker
                renderInput={(props) => <TextField {...props} />}
                slotProps={{ textField: { size: "small", error: false } }}
                onChange={(e) => setFechaCierre(e.toISOString())}
                minDate={dayjs(moment(oneLicence.fechaDenuncia))}
              />
            </LocalizationProvider>
          </Box>
          {motivoCerrar === "Alta médica/Tratamiento cumplido" && (
            <Box mt={2} className="flex flex-col">
              <FormLabel
                sx={{
                  color: "#5836ff",
                  fontWeight: "bold",
                  textAlign: "start",
                }}
              >
                Fecha de alta
              </FormLabel>
              <LocalizationProvider
                adapterLocale="es"
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  renderInput={(props) => <TextField {...props} />}
                  slotProps={{ textField: { size: "small", error: false } }}
                  onChange={(e) => setFechaAlta(e.toISOString())}
                  minDate={dayjs(moment(oneLicence.fechaDenuncia))}
                />
              </LocalizationProvider>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              setMotivoCerrar(null);
              setOpenCerrar(false);
            }}
            size="small"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            disabled={
              motivoCerrar === "Alta médica/Tratamiento cumplido"
                ? !fechaAlta
                : !motivoCerrar || !fechaCierre
            }
            onClick={() => setHandleAceptarCerrar(true)}
            size="small"
            color="primary"
            variant="contained"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Detail;
