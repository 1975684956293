import api, { APPCONFIG } from "../../api";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
const initialState = {
    documents: [],
    extraDocuments: [],
    dniFamiliar: [],
    documentsMobile: [],
    extraDocumentsMobile: [],
    dniFamiliarMobile: [],
}

export const postCertificado = createAsyncThunk(
    "files/postCertificado",
    async (data, { rejectWithValue }) => {
        const url = `${APPCONFIG.APIs.LicenseAPI}/Files/`;
        try {
            const key = data.get("key");
            const response = await api.post(url, data, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            return { files: response.data, key };
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const exportExcelAsync = createAsyncThunk(
    "files/exportExcelAsync",
    async (data) => {
        const response = await api.post(data.url, data.filter, { responseType: "blob" })
        return response.data;
    }
);

const saveFileToLocalStorage = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        const storedImages = JSON.parse(localStorage.getItem("files")) || [];
        const imageInfo = {
            name: file.name,
            type: file.type,
            size: file.size,
        };
        storedImages.push(imageInfo);
        localStorage.setItem("files", JSON.stringify(storedImages));
        localStorage.setItem(file.name, base64Data);
    };
    reader.readAsDataURL(file);
};

const convertImageToFile = (imageItem) => {
    const byteCharacters = atob(imageItem.Image);
    const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });
    const file = new File([blob], imageItem.CUIT + "-" + imageItem.Name, {
        type: 'image/jpeg',
        lastModified: new Date().getTime(),
        name: imageItem.CUIT + "-" + imageItem.Name
    });

    return file;
};

export const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        resetDocuments: () => initialState,
        setDocuments: (state, action) => {
            state.documents = action.payload;
            const serializedFiles = action.payload.map((file) => ({
                name: file.name,
                type: file.type,
                size: file.size,
                // ... otras propiedades que puedan ser necesarias
            }));
            localStorage.setItem("files", JSON.stringify(serializedFiles));
            action.payload.forEach(file => saveFileToLocalStorage(file))
        },
        setDNIFamiliar: (state, action) => {
            state.dniFamiliar = action.payload;
        },
        setExtraDocuments: (state, action) => {
            state.extraDocuments = action.payload;
        },
        setRemoveDocumentsMobile: (state, action) => {
            state.documentsMobile = action.payload;
        },
        setDocumentsMobile2: (state, action) => {
            const unprocessedFiles = action.payload.filter(item => item.CUIT);

            const newlyConvertedFiles = unprocessedFiles.map(convertImageToFile);
            const allFiles = [...state.documentsMobile, ...newlyConvertedFiles];

            state.documentsMobile = allFiles;
        },
        setDocumentsMobile: (state,action) =>{
            state.documentsMobile = action.payload;
        },
        setExtraDocumentsMobile: (state, action) => {
            state.extraDocumentsMobile = action.payload;
        },
        setDNIFamiliarMobile: (state, action) => {
            state.dniFamiliarMobile = action.payload;
        },
        setRemoveExtraDocumentsMobile: (state, action) => {
            state.extraDocumentsMobile = action.payload;
        },
        setExtraDocumentsMobile2: (state, action) => {
            const unprocessedFiles = action.payload.filter(item => item.CUIT);

            const newlyConvertedFiles = unprocessedFiles.map(convertImageToFile);
            const allFiles = [...state.extraDocumentsMobile, ...newlyConvertedFiles];

            state.extraDocumentsMobile = allFiles;
        },
    },
    extraReducers: (builder) => {
        // builder
        //     .addCase(postCertificado.fulfilled, (state, action) => {
        //         state.documents = action.payload;
        //     })
    },
});

export const {
    resetDocuments,
    setDocuments,
    setExtraDocuments,
    setDNIFamiliar,
    setDocumentsMobile,
    setDNIFamiliarMobile,
    setExtraDocumentsMobile,
    setRemoveDocumentsMobile,
    setRemoveExtraDocumentsMobile,
    setExtraDocumentsMobile2,
    setDocumentsMobile2
} = filesSlice.actions;

export default filesSlice.reducer;
export const selectDocuments = (state) => state.files;