import {
  Button,
  Box,
  Typography
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuMobileHeader from "../../header/menu-mobile/index";
import { getTokenBukeala } from "../../user/userSlice";

export default function _Menu({ activeButton, setActiveButton }) {
  const { perfilActual, datos } = useSelector((state) => state.usuario);

  const buttons = [
    {
      id: 0,
      label: "Médicas",
    },
    {
      id: 1,
      label: "Embarazo y Alumbramiento",
    },
    {
      id: 2,
      label: "Hospitalización",
    },
    {
      id: 3,
      label: "Largo tratamiento",
    },
    {
      id: 4,
      label: "PAE",
    },
  ];

  const dispatch = useDispatch();

  return (
    <Box className="licenseMenu" justifyContent={"unset !important"}>
      <div className="title-btn">
        <div style={{ display: "flow" }}>
          <MenuMobileHeader
            activeButton={activeButton}
            setActiveButton={setActiveButton}
            perfil={perfilActual.idPerfil}
          />
          <Box component="span" className="licenseTitle">
            <Typography variant="h5" className="h5">
              Licencias
            </Typography>
          </Box>
        </div>
      </div>
      {buttons.map((button,key) => (
        <Box key={key} className="licenseButtons" gap={1}>
          <Button
            key={button.id}
            disabled={button.id === 4}
            variant={activeButton === button.id ? "contained" : "outlined"}
            onClick={() => setActiveButton(button.id)}
          >
            {button.label}
          </Button>
        </Box>
      ))}
      {perfilActual.idPerfil === "AU_PROFESIONAL_LT" && (
        <Button
          className="!ml-auto"
          onClick={() => {
            dispatch(
              getTokenBukeala({
                cuit: datos.cuit,
                rol: "AU_PROFESIONAL_LT",
              })
            ).then((res) => {
              window.open(res.payload.content, "_blank");
              //window.location.href = ;
            });
          }}
        >
          Ver agenda de turnos
        </Button>
      )}
    </Box>
  );
}
