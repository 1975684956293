import React, { useEffect } from "react";
import { Typography, Box, Checkbox, FormControlLabel, Divider } from "@mui/material";
import { selectLicenses } from "../licenseSlice";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { selectDatosPersonales } from "../../user/userSlice";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import moment from "moment";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { selectDocuments } from "../../files/filesSlice";
import { selectStaticData } from "../../parameters/parametersSlice";

export default function EmbarazoResumen({ control }) {
    const { extraDocuments, documents } = useSelector(selectDocuments);
    const { form, data } = useSelector(selectLicenses);
    const { datos, domicilio } = useSelector(selectDatosPersonales);
    const { pregnancySettings } = useSelector(selectStaticData);
    const { regimen, tipoLicencia, licencia, unidadesOrganizativas, primerInforme, ultimoInforme, observaciones } = form;

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          // Cancel the event as returning a string will prompt the user
          const message = '¿Seguro que quieres recargar la página?';
          event.returnValue = message;
          // Return the message for older browsers
          return message;
        };
    
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []); // Empty dependency array ensures that the effect runs only once
    const parseDate = (date) => {
        const fechaFormateada = date.toDate ? moment(date.toDate()).format('DD/MM/YYYY') : moment(date).format('DD/MM/YYYY')
        return fechaFormateada
    }

    const formatDomicilio = () => {
        return `${domicilio.calle} ${domicilio.altura} ${domicilio.piso ?? ''} ${domicilio.depto ?? ''} - CP: ${domicilio.codPostal} `
    }
    const formatPhone = () => {
        return `${domicilio.telefono.countryCode} ${domicilio.telefono.areaCode} ${domicilio.telefono.phoneNumber}`
    }
    return (
        <>
            <Box bgcolor="#5836ff" p={2} my={2} color="white">
                <Typography variant="subtitle1">
                    <TaskAltIcon sx={{ mr: 2 }} /> Revisá que la información sea la correcta
                </Typography>
            </Box>

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Información general de tu licencia
            </Typography>
            <Divider sx={{ border: "1px solid black", mb: 3 }} />

            <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Régimen:</Typography>
                <Typography>{data.regimenes.find(r => r.code === regimen)?.description}</Typography>
            </Box>

            <Box mb={1}>
                <Typography color="#5836ff" sx={{ fontWeight: 'bold' }}>Unidad/es Organizativa/s:</Typography>
                {unidadesOrganizativas?.map(x =>
                    <Typography key={x.code}>
                        {x.code} {x.description}
                    </Typography>
                )}
            </Box>

            <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Observaciones:</Typography>
                <Typography>{observaciones || "Sin observaciones"}</Typography>
            </Box>

            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>
                Información de la solicitud
            </Typography>
            <Divider sx={{ border: "1px solid black", mb: 3 }} />
            <Box display="flex" flexDirection="row" mb={1} justifyContent='space-between'>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Tipo de Licencia:</Typography>
                <Typography>{tipoLicencia?.description}</Typography>
            </Box>

            <Box display="flex" flexDirection="row" mb={1} justifyContent='space-between'>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Licencia:</Typography>
                <Typography>{licencia?.description}</Typography>
            </Box>

            <Box display="flex" flexDirection="row" mb={1} justifyContent='space-between'>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Fecha probable de parto:</Typography>
                <Typography>{parseDate(form.fechaProbable)}</Typography>
            </Box>
            <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Inicio del ausentismo laboral:</Typography>
                <Typography>{parseDate(form.fechaInicio)}</Typography>
            </Box>
            <Box display="flex" flexDirection="row" mb={1} alignItems='center' justifyContent='space-between'>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Días previos a la fecha de parto hasta los cuales puedo trabajar:</Typography>
                <Typography>{form.preparto ? form.diasPreparto : pregnancySettings.days}</Typography>
            </Box>

            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>
                Datos del/la médico/a
            </Typography>
            <Divider sx={{ border: "1px solid black", mb: 3 }} />

            <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Matrícula:</Typography>
                <Typography>{form.tipoMatricula === 1 ? 'MP' : 'MN'} {form.matricula}</Typography>
            </Box>
            <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Nombres:</Typography>
                <Typography>{form.nombre}</Typography>
            </Box>
            <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Apellidos:</Typography>
                <Typography>{form.apellido}</Typography>
            </Box>
            <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Especialidad médica:</Typography>
                <Typography>{form.especialidad}</Typography>
            </Box>

            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>
                Información de contacto
            </Typography>
            <Divider sx={{ border: "1px solid black", mb: 3 }} />
            <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Domicilio:</Typography>
                <Typography>{formatDomicilio()}</Typography>
            </Box>

            <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Correo electrónico:</Typography>
                <Typography>{datos.email}</Typography>
            </Box>

            <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Teléfono:</Typography>
                <Typography>{formatPhone()}</Typography>
            </Box>

            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>
                Documentos Adjuntos
            </Typography>
            <Divider sx={{ border: "1px solid black", mb: 3 }} />
            <Box mb={1}>
                {documents?.map(x =>
                    <>
                        <Typography key={x.id}>
                            <AttachFileIcon></AttachFileIcon>
                            {x.name}
                        </Typography>
                    </>
                )}
            </Box>
            <Box mb={1}>
                {extraDocuments?.map(x =>
                    <>
                        <Typography key={x.id}>
                            <AttachFileIcon></AttachFileIcon>
                            {x.name}
                        </Typography>
                    </>
                )}
            </Box>
            <Box mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Primer informe:</Typography>
                {primerInforme?.map(x =>
                    <>
                        <Typography key={x.id}>
                            <AttachFileIcon></AttachFileIcon>
                            {x.name}
                        </Typography>
                    </>
                )}
            </Box>
            <Box mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Último informe:</Typography>
                {ultimoInforme?.map(x =>
                    <>
                        <Typography key={x.id}>
                            <AttachFileIcon></AttachFileIcon>
                            {x.name}
                        </Typography>
                    </>
                )}
            </Box>
            <Box mt={2}>
                <FormControlLabel
                    control={
                        <Controller
                            name="confirmacion"
                            control={control}
                            render={({ field: { onChange } }) => (
                                <Checkbox
                                    checked={form.confirmacion}
                                    onChange={onChange}
                                    sx={{ pt: 0 }}
                                />
                            )}
                        />
                    }
                    label="Entiendo que esta solicitud tiene carácter de declaración jurada y confirmo que los datos ingresados son verdaderos."
                    sx={{ alignItems: "flex-start" }}
                />
            </Box>
        </>
    );
}
