import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, IconButton, TablePagination, Tooltip } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  CheckCircleOutline,
  Cancel,
  HelpOutline,
  Block,
} from "@mui/icons-material";
import { fetchAllAuditProLicenciasAllHistoryAsync } from "../../occupationalMedicineSlice";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const { allAuditHistoryLicences } = useSelector(
    (state) => state.auditProLicence
  );
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1 || allAuditHistoryLicences?.length === 0 || allAuditHistoryLicences?.length < rowsPerPage}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function HistoryTable({ idhr , page , setPage , rowsPerPage , setRowsPerPage , filters }) {
  const { allAuditHistoryLicences, totalCount } = useSelector(
    (state) => state.auditProLicence
  );
  const parseDate = (date) => {
    const fechaFormateada = date.toDate
      ? moment(date.toDate()).format("DD/MM/YYYY")
      : moment(date).format("DD/MM/YYYY");
    return fechaFormateada;
  };
  const columns = [
    {
      field: "title",
      headerName: "Licencia",
      sortable: false,
      headerClassName: "bg-[#5836FF] text-white",
      align: "center",
      flex:1,
      renderCell: (params) => (
        <span className="text-} break-words text-center  my-[10px]">
          {params.row.title}
        </span>
      ),
    },
    {
      field: "startDate",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center">
          Fecha de <br /> Inicio
        </div>
      ),
      align:"center",
      sortable: false,
      renderCell: (params) => <span className="text-center">{parseDate(params.row.startDate)}</span>,
      flex:1
    },
    {
      field: "endDate",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center">
          Fecha <br /> Fin
        </div>
      ),
      sortable: false,
      align:"center",
      renderCell: (params) => <span className="text-center">{parseDate(params.row.endDate)}</span>,
      flex:1
    },
    {
      field: "statusId",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Estado",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.status}>
        <span>
          {params.row.statusId === 0 ? (
            <Block style={{ color: "gray" }} />
          ) : params.row.statusId === 1 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 2 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 3 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 4 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 5 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 6 ? (
            <DoDisturbOnIcon style={{ color: "red" }} />
          ) : params.row.statusId === 7 ? (
            <CheckCircleOutline style={{ color: "green" }} />
          ) : params.row.statusId === 8 ? (
            <Cancel style={{ color: "red" }} />
          ) : params.row.statusId === 9 ? (
            <Block style={{ color: "gray" }} />
          ) : params.row.statusId === 10 ? (
            <CheckCircleOutline style={{ color: "green" }} />
          ) : params.row.statusId === 11 ? (
            <Cancel style={{ color: "red" }} />
          ) : params.row.statusId === 12 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 13 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 14 ? (
            <HelpOutline style={{ color: "orange" }} />
          ) : null}
        </span>
        </Tooltip>
      ),
      flex:1
    },
    {
      field: "tomadaPor",
      align: "center",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center">
          Tomada <br /> por
        </div>
      ),
      sortable: false,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {params.row.tomadaPor?.includes(" ")
            ? params.row.tomadaPor.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.tomadaPor}
        </span>
      ),
      flex:1
    },
  ];
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    const filterArray = [];
    for (const key in filters) {
      let value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        if (value === true) {
          value = "1";
        }
        if (key === "cuil") {
          value = value.replace(/[^0-9]/g, "");
        }
        filterArray.push({ code: key, description: value });
      }
    }
    setPage(newPage);
    dispatch(
      fetchAllAuditProLicenciasAllHistoryAsync({
        id: idhr,
        filters: filterArray,
        pageNumber:newPage,
        pageSize:rowsPerPage
      }))
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      fetchAllAuditProLicenciasAllHistoryAsync({
        id: idhr,
        filters: [],
        pageNumber:0,
        pageSize:parseInt(event.target.value, 10)
      }))
  };

  if (allAuditHistoryLicences?.length === 0)
    return (
      <p className="text-xl text-center mt-5">
        No existen licencias para los filtros seleccionados
      </p>
    );
  return (
    <div style={{ width: "100%", padding: "10px" }}>
      <div className="relative w-full">
      <div className="w-full flex ">
        <TablePagination
          className="border-0 p-0 ml-auto"
          rowsPerPageOptions={[5, 10, 25,{ label: "All", value: totalCount }]}
          colSpan={8}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Licencias por pagina"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
              <p className="flex w-[100px] mx-2 items-center">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[24px] h-[24px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
        </div>
        <div className="w-[100%]">
        {allAuditHistoryLicences?.length === 0 ? <p className="text-xl text-center mt-5">
        No existen licencias para los filtros seleccionados
      </p> : (
          <DataGrid
            className="border-0"
            getRowHeight={() => "auto"}
            hideFooterRowCount
            rows={allAuditHistoryLicences}
            columns={columns}
            hideFooterPagination
          />
        )}
        </div>
        <div className="w-full flex ">
        <TablePagination
          className="border-0 p-0 ml-auto"
          rowsPerPageOptions={[5, 10, 25,{ label: "All", value: totalCount }]}
          colSpan={8}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Licencias por pagina"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
                      <p className="flex w-[100px] mx-2 items-center">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[24px] h-[24px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
      </div>
      </div>
    </div>
  );
}

export default HistoryTable;
