import React, { useEffect, useState } from "react";
import { Controller ,useForm} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCentrosMedicos,
  fetchDatosMedicos,
  selectLicenses,
} from "../licenseSlice";
import {
  Autocomplete,
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  createFilterOptions,
   
} from "@mui/material";
import { createTheme ,ThemeProvider} from '@mui/material/styles';
import { ConstructionOutlined } from "@mui/icons-material";


export default function InformacionMedica({ control, setValue }) {
  const dispatch = useDispatch();
  const { data, form } = useSelector(selectLicenses);

  const [errorNombre, setErrorNombre] = useState({
    error:false,
    message:""
  });

  const [errorApellido, setErrorApellido] = useState({
    error:false,
    message:""
  });

  const [errorEspecialidad, setErrorEspecialidad] = useState({
    error:false,
    message:""
  });


  const filter = createFilterOptions();
  const tipoMatriculas = [
    { id: 1, value: "MP" },
    { id: 2, value: "MN" },
  ];
  const lugaresAtencion = [
    { id: 1, value: "Centro Médico" },
    { id: 2, value: "Consultorio Particular" },
    { id: 3, value: "Domicilio Particular" },
  ];
  const [localData, setLocalData] = useState({
    nombre: "",
    apellido: "",
    especialidad: ""
  });
  const {reset } = useForm();
  const theme = createTheme({
    components: {
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              color: '#ff3d8b'
              }
            }
          }
    }
  })

  const [datosMedicos, setDatosMedicos] = useState([]); 
  const getCentrosMedicos = (value) => {
    if (value.length > 2) dispatch(fetchCentrosMedicos(value));
  };



  useEffect(() => {
   
    if (!data.datosMedicos) {
      dispatch(fetchDatosMedicos(""));
    }
    if (!data.centrosMedicos) {
      dispatch(fetchCentrosMedicos(""));
    }
    if (form.apellido){
      setLocalData({
        nombre: form.nombre,
        apellido: form.apellido,
        especialidad: form.especialidad
      });
    }
  
  return () => {
    reset(); 
    reset({matricula:""})
  };
}, []) 
 

 

const getDatosMedicos = (value) => {
  if (value.length > 2) {
    dispatch(fetchDatosMedicos(value)).then((action) => {
      if (fetchDatosMedicos.fulfilled.match(action)) {
        const uniqueDatosMedicos = [
          ...new Map(action.payload.map(item => [item['matricula'], item])).values()
        ];
        setDatosMedicos(uniqueDatosMedicos); 
      }
    });
  }
};

  const handleSelectMatricula = (value) => {
    if (value) {
      if (value.hasOwnProperty("code")) {
        sessionStorage.setItem("newMatricula",true)
        setValue("newMatricula", true, { shouldTouch: true });
        setValue("changedLicense", false, { shouldTouch: true });
        setValue("nombre", value.name ,{ shouldTouch: true });
        setValue("apellido", value.lastName, { shouldTouch: true });
        setValue("especialidad", value.especialidad, { shouldTouch: true });
        const matriculaNumber = Number(value?.matricula.split(' ')[0])
        setValue("matricula",  matriculaNumber,{ shouldTouch: true });
      } else {
        sessionStorage.removeItem("newMatricula");
        setValue("nombre", value.name, { shouldTouch: true });
        setValue("apellido", value.lastName, { shouldTouch: true });
        setValue("especialidad", value.especialidad, { shouldTouch: true });

        setLocalData({
          nombre: value.name,
          apellido: value.lastName,
          especialidad: value.especialidad
        });
      }
    } else {
      setValue("nombre", "", { shouldTouch: true });
      setValue("apellido", "", { shouldTouch: true });
      setValue("especialidad", "", { shouldTouch: true });
      setLocalData({
        nombre: "",
        apellido: "",
        especialidad: ""
      });
    }
    return Number(value?.matricula);
  };

  const handleChange = (e,field) => {
     
   
    if(localData.apellido !==form.apellido || localData.nombre !== form.nombre){
      setValue("changedLicense", true, { shouldTouch: true });
    
      const { value } = e.target;
     
      setLocalData((prevState) => ({
          ...prevState,
          [field]: value
        }));
    }
  };

  return (
    <>
      <FormControl id="input-info-medica" fullWidth>
        <Box mt={2}>
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Tipo de matrícula
          </FormLabel>
          <Controller
            name="tipoMatricula"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                size="small"
                fullWidth
                labelId="tipoMatricula"
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
              >
                {tipoMatriculas.map((matricula, i) => (
                  <MenuItem key={`matricula-${i}`} value={matricula.id}>
                    {matricula.value}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>
      </FormControl>
      <FormControl fullWidth>
  <Box mt={2} display="flex" flexDirection="column">
    <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
      Matrícula
    </FormLabel>
    <Controller
      name="matricula"
      control={control}
      rules={{ required: true, minLength: 5 }}
      render={({ field: { onChange,value } }) => (
        <Autocomplete
        handleHomeEndKeys
        freeSolo
        size="small"
        options={datosMedicos}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.matricula ? `${option.matricula}` : '';
        }}
        onChange={(e, values) => onChange(handleSelectMatricula(values))}
        defaultValue={data.datosMedicos?.find((x) => x.matricula === form.matricula)}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(option => option.matricula.toString() === inputValue.trim());
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              code: "Nuevo",
              matricula: `${inputValue}`,
            });
          }
          return filtered;
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {option.matricula} - {option.lastName ?? "AGREGAR NUEVA"} {option.name ?? ""}
          </li>
        )}
        renderInput={(params) => (
          <ThemeProvider theme={theme}>
            <TextField
              {...params}
              variant="outlined"
              onChange={(e) => getDatosMedicos(e.target?.value)}
              InputLabelProps={{ shrink: false }}
              helperText={
                value && !sessionStorage.getItem("newMatricula")
                  ? "Si los datos no coinciden con los del certificado, podés modificarlos."
                  : ""
              }
            />
          </ThemeProvider>
          )}
        />
      )}
    />
  </Box>
</FormControl>
      <FormControl fullWidth>
        <Box mt={2} display="flex" flexDirection="column">
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Nombre
          </FormLabel>
          <Controller
              name="nombre"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  value={value != '' && value != null ? value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚüÜñÑ' ]/g,'') : undefined}  
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚüÜñÑ' ]/g,'')
                    handleChange(e, "nombre");  
                    onChange(e);  
                  }}
                  type="text"
                  helperText={'No se permiten caracteres especiales.'}
                />
              )}
            />
           
        </Box>
      </FormControl>
      <FormControl fullWidth>
        <Box mt={2} display="flex" flexDirection="column">
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Apellido
          </FormLabel>
          <Controller
            name="apellido"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="outlined"
                size="small"
                value={value != '' && value != null ? value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚüÜñÑ' ]/g,'') : undefined} 
                // {
                //   form.matricula
                //     ? data.datosMedicos?.find(
                //         (x) => x.matricula === form.matricula
                //       )?.lastName
                //     : value
                // }
                // disabled={
                //   !form.matricula ||
                //   data.datosMedicos?.find((x) => x.matricula === form.matricula)
                //     ?.lastName
                // }
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚüÜñÑ' ]/g,'')
                  handleChange(e,"apellido");
                  onChange(e);
                }}
                // error={errorApellido.error}
                helperText={'No se permiten caracteres especiales.'}
                type="text"
              ></TextField>
            )}
          />
        </Box>
      </FormControl>
      <FormControl fullWidth>
        <Box mt={2} display="flex" flexDirection="column">
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Especialidad médica
          </FormLabel>
          <Controller
            name="especialidad"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                variant="outlined"
                size="small"
                value={value != '' && value != null ? value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚüÜñÑ' ]/g,'') : undefined} 
                // {
                //   form.matricula
                //     ? data.datosMedicos?.find(
                //         (x) => x.matricula === form.matricula
                //       )?.especialidad
                //     : value
                // }
                // disabled={
                //   !form.matricula   ||
                //   data.datosMedicos?.find((x) => x.matricula === form.matricula)
                //     ?.especialidad
                // }
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚüÜñÑ' ]/g,'')
                  handleChange(e,"especialidad");
                  onChange(e);
                }}
                type="text"
                // error={errorEspecialidad.error}
                helperText={'No se permiten caracteres especiales.'}
              ></TextField>
            )}
          />
        </Box>
      </FormControl>
      <FormControl id="input-info-medica" fullWidth>
        <Box mt={2}>
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Lugar de atención
          </FormLabel>
          <Controller
            name="lugarAtencion"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                size="small"
                fullWidth
                labelId="lugarAtencion"
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
              >
                {lugaresAtencion.map((lugarAtencion, i) => (
                  <MenuItem key={`lugarAtencion-${i}`} value={lugarAtencion.id}>
                    {lugarAtencion.value}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>
      </FormControl>
      {form.lugarAtencion === 2 && (
        <FormControl fullWidth>
          <Box mt={2} display="flex" flexDirection="column">
            <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
              Ingrese la dirección del consultorio
            </FormLabel>
            <Controller
              name="dirConsultorioParticular"
              control={control}
              render={({ field: { onChange } }) => (
                <TextField
                  variant="outlined"
                  size="small"
                  defaultValue={form.dirConsultorioParticular}
                  onChange={onChange}
                  type="text"
                ></TextField>
              )}
            />
          </Box>
        </FormControl>
      )}
      {form.lugarAtencion === 1 && (
        <FormControl id="input-diagnostico" fullWidth>
          <Box mt={2}>
            <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
              Ingresá el centro médico
            </FormLabel>
            <Controller
              name="centroMedico"
              control={control}
              rules={{ required: true, minLength: 5 }}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  size="small"
                  onChange={(event, newValue) => {
                    if (newValue && newValue.inputValue) {
                      localStorage.setItem("newCtroMedico",true)
                      onChange({
                        value: newValue.inputValue,
                      });
                    } else {
                      localStorage.removeItem("newCtroMedico")
                      onChange(newValue);
                    }
                  }}
                  getOptionLabel={(option) =>
                    option.code + " - " + option.name ?? ""
                  }
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    const isExisting = options.some(
                      (option) => inputValue === option.code
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        code: "Nuevo",
                        name: inputValue,
                      });
                    }
                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={data.centrosMedicos}
                  // renderOption={(props, option) => (
                  //   <li {...props}>{option.code}</li>
                  // )}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => getCentrosMedicos(e.target?.value)}
                      InputLabelProps={{ shrink: false }}
                    />
                  )}
                />
              )}
            />
          </Box>
        </FormControl>
      )}
    </>
  );
}
