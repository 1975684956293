/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl } from "@mui/base";
import React, { useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";
import CustomAlert from "../../alert";
import DocRequerido from "../doc-respaldatoria/requerido";
import DocRespaldatoriaAdicional from "../doc-respaldatoria/adicional";
import { selectDocuments } from "../../files/filesSlice";
import { useSelector } from "react-redux";

export default function CertificadoART({ setValue }) {
  const [error, setError] = React.useState({ show: false, data: '' });
  const { documents } = useSelector(selectDocuments);
  const handleClose = () => {
    setError({ show: false });
  };

  useEffect(() => {
    if (documents.length > 0)
      setValue("certificado", true, { shouldTouch: true });
    else
      setValue("certificado", false, { shouldTouch: true });
  }, [documents])

  return (
    <>
      <FormControl id="input-certificado" fullWidth>
        <Box mt={2} mb={3}>
          <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Parte médico de ingreso (PMI)</Typography>
          <DocRequerido />
        </Box>
      </FormControl>
      <Divider />
      <FormControl id="input-adicionales" fullWidth>
        <Box mt={3}>
          <Typography color="#5836ff" sx={{ mr: 1 }}>Otros documentos</Typography>
          <DocRespaldatoriaAdicional />
        </Box>
      </FormControl>
      <CustomAlert error={error} severity='error' onClose={handleClose} />
    </>
  );
}
