import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDocuments,
  setDNIFamiliar,
  setDNIFamiliarMobile,
} from "../../../files/filesSlice";
import { selectSettings } from "../../../parameters/parametersSlice";
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CustomAlert from "../../../alert";
import { sizeValidator } from "../../../../utils/helper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { selectDatosPersonales } from "../../../user/userSlice";
import Camera from "./camera-dni-familiar";
function obtenerFechaHoraActual() {
  const ahora = new Date();
  const dia = ahora.getDate().toString().padStart(2, "0");
  const mes = (ahora.getMonth() + 1).toString().padStart(2, "0"); // Los meses van de 0 a 11
  const año = ahora.getFullYear();
  const horas = ahora.getHours().toString().padStart(2, "0");
  const minutos = ahora.getMinutes().toString().padStart(2, "0");
  const segundos = ahora.getSeconds().toString().padStart(2, "0");

  const fechaHoraActual = `${año}-${mes}-${dia} ${horas}-${minutos}-${segundos}.jpeg`;
  return fechaHoraActual;
}
export default function DNIFamiliarRequerido({ identificador , cameraStream , setCameraStream , requestCameraPermission}) {
  const { extensions } = useSelector(selectSettings);
  const [error, setError] = useState({ show: false, data: "" });
  const { datos } = useSelector(selectDatosPersonales);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { dniFamiliar, dniFamiliarMobile } = useSelector(selectDocuments);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = [...dniFamiliar, ...acceptedFiles];
      const filesLenght = [...files, ...dniFamiliarMobile];
      if (files.length > 2) {
        setError({
          show: true,
          data: `Solo se pueden adjuntar hasta 2 archivos como máximo.`,
        });
        return;
      }
      // Verificar si algún archivo nuevo ya existe en la lista actual
      const duplicateFile = acceptedFiles.find((newFile) => {
        return dniFamiliar.some(
          (existingFile) => existingFile.name === newFile.name
        );
      });

      if (duplicateFile) {
        setError({
          show: true,
          data: `El archivo "${duplicateFile.name}" ya ha sido adjuntado.`,
        });
        return;
      }
      setDialogOpen(true);
      dispatch(setDNIFamiliar(files));
    },
    [dniFamiliar]
  );

  const showCamera = () => {
    const cameraOptions = {
      quality: 50, // Calidad de la imagen (0-100)
      destinationType: 0, // Utilizando valor directo en lugar de Camera.DestinationType.FILE_URI
      sourceType: 1, // Utilizando valor directo en lugar de Camera.PictureSourceType.CAMERA
      encodingType: 0, // Utilizando valor directo en lugar de Camera.EncodingType.JPEG
      targetWidth: 800,
      targetHeight: 600,
    };
    navigator.camera.getPicture(
      (imageData) => {
        try {
          let photo = {
            CUIT: datos.cuit.toString(),
            Key: "dniFamiliar",
            Name: obtenerFechaHoraActual(),
            Image: imageData,
            UserId: datos.id,
            SendMeta4: false,
          };

          const imageFiles = [...dniFamiliarMobile, photo];
          const filesLenght = [...imageFiles, ...dniFamiliar];
          if (filesLenght.length > 2) {
            setError({
              show: true,
              data: `Solo se pueden adjuntar hasta 2 archivos como máximo.`,
            });
            return;
          }
          setDialogOpen(true);

          dispatch(setDNIFamiliarMobile(imageFiles));
        } catch (error) {
          console.log(error)
        }
      },
      (error) => {
        console.error("Error al abrir la cámara:", error);
      },
      cameraOptions
    );
  };

  const removeFile = (file) => () => {
    const newFiles = [...dniFamiliar];
    newFiles.splice(newFiles.indexOf(file), 1);
    dispatch(setDNIFamiliar(newFiles));
  };

  const removeFileMobile = (file) => () => {
    const newFiles = [...dniFamiliarMobile];
    newFiles.splice(newFiles.indexOf(file), 1);
    dispatch(setDNIFamiliarMobile(newFiles));
  };

  const { getRootProps, getInputProps, fileRejections, open } = useDropzone({
    onDrop,
    accept: JSON.parse(extensions),
    validator: sizeValidator,
    capture: "camera",
    noClick: true,
  });

  useEffect(() => {
    const validExtns = JSON.parse(extensions);
    var propiedades = Object.getOwnPropertyNames(validExtns);
    let valid = "";
    propiedades.forEach((element) => {
      valid += validExtns[element];
    });
    fileRejections.map(({ errors }) => {
      setError({ show: true, data: errors[0]?.message });
    });
  }, [fileRejections]);

  const handleErrorClose = () => {
    setError({ show: false });
  };

  const [openCamera, setOpenCamera] = useState(false);

  return (
    <>
    <div>

      <div
        {...getRootProps({ className: "dropzone" })}
        onClick={open}
        className="border-2 rounded-md py-5 px-5"
      >
        <input {...getInputProps()} capture="user" />
        <p className="text-center text-[#5836ff]">
          Arrastrar archivos para adjuntar ó
          <button className="p-2 bg-[#5836ff] text-white rounded-md shadow-sm cursor-pointer">
            Explorar en disco
          </button>
        </p>
      </div>
      <div
        className="border-2 rounded-md py-3 px-5 hideWeb"
        style={{ textAlign: "-webkit-center", marginTop: "10px" }}
      >
        <button
          className="p-2 bg-[#5836ff] text-white rounded-md shadow-sm cursor-pointer"
          onClick={() => setOpenCamera(true)}
        >
          Capturar Imagen
        </button>
      </div>
    </div>
    <Dialog open={openCamera} onClose={() => setOpenCamera(false)} sx={{zIndex:99999999}}>
        <Camera setError={setError} setOpenCamera={setOpenCamera} cameraStream={cameraStream} setCameraStream={setCameraStream} requestCameraPermission={requestCameraPermission}/>
      </Dialog>
      <div className="flex flex-col">
        {dniFamiliar?.map((doc) => (
          <>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography key={doc.id}>
                <InsertDriveFileIcon />
                {doc.name}
              </Typography>
              <Button onClick={removeFile(doc)}>
                <DeleteForeverIcon sx={{ color: "#ff3d8b" }} />
              </Button>
            </Box>
          </>
        ))}
        {dniFamiliarMobile?.map((doc, index) => (
          <>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography key={index}>
                <InsertDriveFileIcon />
                {doc.Name}
              </Typography>
              <Button onClick={removeFileMobile(doc)}>
                <DeleteForeverIcon sx={{ color: "#ff3d8b" }} />
              </Button>
            </Box>
          </>
        ))}
        {(dniFamiliar?.length > 0 || dniFamiliarMobile?.length > 0) && (
          <Button
            className="mx-auto mt-2"
            variant="outlined"
            onClick={() => setDialogOpen(true)}
            color="primary"
          >
            Ver DNI
          </Button>
        )}
      </div>
      <CustomAlert error={error} severity="error" onClose={handleErrorClose} />
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle className="text-center">Archivos Adjuntados</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Aquí está el DNI que has adjuntado:
          </DialogContentText>
          <div className="flex flex-col gap-4 justify-center items-center">
            {dniFamiliar?.map((doc, index) => (
              <img
                key={index}
                src={URL.createObjectURL(doc)}
                alt={`Imagen ${index + 1}`}
                className="object-cover rounded-md w-[300px]"
              />
            ))}
            {dniFamiliarMobile?.map((doc, index) => (
              <img
                key={index}
                src={"data:image/jpeg;base64," + doc.Image}
                alt={`Imagen ${index + 1}`}
                className="object-cover rounded-md w-[300px]"
              />
            ))}
          </div>
          <div className="mt-2">
            <h1 className="text-lg font-semibold">
              Confirmo que es legible y contiene:
            </h1>
            <ul className="list-disc ml-4">
              <li>Nombre y apellido</li>
              <li>Fecha de nacimiento</li>
              <li>Firma</li>
              <li>DNI</li>
              <li>Domicilio</li>
            </ul>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="mx-auto"
            variant="outlined"
            onClick={() => setDialogOpen(false)}
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
