import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";

export default function YourData() {
    const userInformation = useSelector((state) => state.usuario.datos);

    return (
        <React.Fragment>
            <Container sx={{ width: 0.6, py: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="span" sx={{ color: '#5836ff' }} className="text-center mb-3 text-xl font-semibold"><h5>¿Estos son tus datos?</h5></Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ color: '#5836ff' }} className="text-center"><b>ID HR:</b></Typography>
                    <Typography sx={{ mb: 1 }}>{userInformation.idHR}</Typography>
                    <Typography sx={{ color: '#5836ff' }} className="text-center"><b>Nombre:</b></Typography>
                    <Typography sx={{ mb: 1 }}>{userInformation.nombre}</Typography>
                    <Typography sx={{ color: '#5836ff' }} className="text-center"><b>Apellido:</b></Typography>
                    <Typography sx={{ mb: 1 }}>{userInformation.apellido}</Typography>
                    <Typography sx={{ color: '#5836ff' }} className="text-center"><b>Tipo de documento:</b></Typography>
                    <Typography sx={{ mb: 1 }} className="text-center">{userInformation.tipoDocumento}</Typography>
                    <Typography sx={{ color: '#5836ff' }} className="text-center"><b>Nro. de documento:</b></Typography>
                    <Typography sx={{ mb: 1 }} className="text-center">{userInformation.nroDocumento}</Typography>
                    <Typography sx={{ color: '#5836ff' }} className="text-center"><b>Fecha de nacimiento:</b></Typography>
                    <Typography sx={{ mb: 1 }} className="text-center">{moment(userInformation.fecNacimiento).format('D/MM/yyyy')}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: "center" }}>
                        <Typography>Si algún dato no editable que visualizás es incorrecto deberás comunicarte con tu RAP, área de RH o equipo de conducción, para que puedan actualizarlo.</Typography>
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    );

}
