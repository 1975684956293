/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getSolapamientoAusenteAsync,
  postCentrosMedicos,
  postCertificadoMobile,
  postDatosMedicos,
  ResetState,
  UpdateMedicoAsync,
  saveLicenseAsync2,
  updateMedico,
  saveLicenseAusenteAsync,
  selectLicenses,
  updateForm,
} from "../licenseSlice";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WarningIcon from "@mui/icons-material/Warning";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import FormSteps from "./steps-config";
import { selectDatosPersonales } from "../../user/userSlice";
import moment from "moment";
import {
  postCertificado,
  resetDocuments,
  selectDocuments,
} from "../../files/filesSlice";

import { toggleLoading } from "../../spinner/spinnerSlice"; 


 

export default function RequestForm() {
  const { ausencia, sustituta } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const user = useSelector(selectDatosPersonales);
  const license = useSelector(selectLicenses);
  const { documents, extraDocuments, dniFamiliar, documentsMobile, extraDocumentsMobile, dniFamiliarMobile } =
    useSelector(selectDocuments);

  const { control, setValue, watch } = useForm({
    defaultValues: {
      nombre: '',
      apellido: '',
      matricula: '',
    },
    defaultValues: license.form,shouldUnregister: true 
  });


  React.useEffect(() => {
    const subscription = watch((value) => {
      dispatch(updateForm(value));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [formSteps, setFormSteps] = React.useState(
    FormSteps(control, setValue, user, license)
  );

  const stepsValidateSolapamiento = [
    "Step4",
    "FechaCertificado",
    "FechaCertificadoFamiliar",
    "FechaSiniestro",
  ];
  const [steps, setSteps] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState({
    open: false,
    content: "",
    reset: false,
  });

  React.useEffect(() => {
    
    if (ausencia) {
      const newFormSteps = FormSteps(control, setValue, user, license);
      setFormSteps(newFormSteps);
      setSteps([...Array(newFormSteps.length - 2)].map(() => ""));
      setActiveStep(3);
    }
  }, []);

  React.useEffect(() => {
    if (sustituta) {
      const newFormSteps = FormSteps(control, setValue, user, license);
      setFormSteps(newFormSteps);
      setSteps([...Array(newFormSteps.length - 2)].map(() => ""));
      setActiveStep(3);
    }
  }, []);

  const [dialogArt, setDialogArt] = React.useState(false);

  const stepIsComplete = React.useMemo(() => {
    if (!license.data.stepFields[activeStep]) {
      return false;
    }
    if (activeStep === 2) return license.form.unidadesOrganizativas.length > 0;
    if (license.form.tipoLicencia.code === "MEH") {
      
      if(activeStep == 3)
        {
          const fechaAusentismo = new Date(license.form.fechaInicio).getTime();
          const fechaCertificado = new Date(license.form.fechaCertificado).getTime();
          if (fechaCertificado - fechaAusentismo >= 0 ) 
              return true;
          return false
        }
        
    }
    if (
      (license.form.descripcion?.length > 0 &&
        license.form.descripcion?.length < 10) ||
      license.form.descripcionAccidente?.length > 255 ||
      license.form.descripcionAccidente?.length < 10
    ) {
      return false;
    }
    return isStepComplete(license.form, license.data.stepFields[activeStep]);
  }, [license.form, activeStep]);


  const saveDocuments = (data) => {
     dispatch(toggleLoading())
    const formData = new FormData();
    if (documents.length > 0)
      documents.forEach((file) => {
        formData.append("FilesCommon", file);
      });
    if (extraDocuments.length > 0)
      extraDocuments.forEach((files) => {
        formData.append("FilesOtherDocuments", files)
      })
    if (dniFamiliar.length > 0)
      dniFamiliar.forEach((files) => {
        formData.append("FilesFamilyId", files)
      })

    if (license.form.primerInforme.length > 0)
      license.form.primerInforme.forEach((f) => {
        formData.append("primerInforme", f.primerInforme);
      })

    if (license.form.ultimoInforme.length > 0)
      license.form.ultimoInforme.forEach((f) => {
        formData.append("ultimoInforme", f.ultimoInforme);
      })
    
    data.fileMobileCommon = documentsMobile
    data.fileMobileOtherDocuments = extraDocumentsMobile
    data.fileMobileFamilyId = dniFamiliarMobile
    formData.append("data", JSON.stringify(data));
   
   
    dispatch(saveLicenseAsync2(formData)).then((res) => {
      
      if(res.error)
        {
          let message ="";
          if(res.payload.includes("LicService.WebApi.Response.DiagnosticResponse"))
              message = "Ocurrió un error al generar tu licencia. Por favor seleccioná un diagnóstico disponbile"
          else
            message = "Ocurrió un error al generar tu licencia. Por favor intentá de nuevo más tarde."

          setDialogOpen({
            open: true,
            message: message ,
            reset: false,
          });
          return
        }
      else
      {
        if (data.license.newMatricula === true) {
          dispatch(
            postDatosMedicos({
              matricula: license.form.matricula,
              nombre: license.form.nombre,
              apellido: license.form.apellido,
              especialidad: license.form.especialidad,
              userId: data.user.datos.id
            })
          ).then((_x) => sessionStorage.removeItem("newMatricula"));
        }
        if (((res.payload.files?.success && !res.payload.files?.success) || !res.payload.data?.sucess) )
          {
          setDialogOpen({
            open: true,
            message: res.payload.files == undefined ? res.payload.message :res.payload.files.message,
            reset: true,
          });
          if(data.license.changedLicense === true && data.license.newMatricula===undefined ){
            console.log(data)
           dispatch(UpdateMedicoAsync({
            matricula : data.license.matricula ,
            nombre :data.license.nombre,
            apellido:data.license.apellido,
            especialidad:data.license.especialidad,
            userId: data.user.datos.id
            })).then((res) => {
             if(res.error){console.log("error al actualizar el medico/a")}
               else{console.log("medico/a actualizado correctamente")}})
         }
        }
        else {
         
          if (localStorage.getItem("newCtroMedico")) {
            dispatch(
              postCentrosMedicos({
                code: "Nuevo",
                name: license.form.centroMedico.value,
              })
            ).then((x) => localStorage.removeItem("newCtroMedico"));
          }
          setDialogOpen({
            open: true,
            message: res.payload.files.message,
            reset: true,
          });     
        }
      }
    }).catch(err => {
      console.log(err)
    }).finally((e)=>{
      dispatch(toggleLoading())
    });

    
  };



  const saveDocumentsMobile = (licenseId) => {
    let filesToSend = documentsMobile.map((file) => {
      return { ...file, LicenseId: licenseId };
    });
    dispatch(postCertificadoMobile(filesToSend)).then(x => {
      if (extraDocumentsMobile.length > 0 || dniFamiliarMobile.length > 0) {
        if (extraDocumentsMobile.length > 0) {
          saveOtherDocumentsMobile(licenseId);
        }
        if (dniFamiliarMobile.length > 0) {
          saveDNIFAMILIARMobile(licenseId);
        }
      }
    });
  };

  const saveDNIFAMILIARMobile = (licenseId) => {
    let filesToSend = dniFamiliarMobile.map((file) => {
      return { ...file, LicenseId: licenseId };
    });
    dispatch(postCertificadoMobile(filesToSend));
  };

  const saveOtherDocumentsMobile = (licenseId) => {
    let filesToSend = extraDocumentsMobile.map((file) => {
      return { ...file, LicenseId: licenseId };
    });
    dispatch(postCertificadoMobile(filesToSend));
  };



  const saveOtherDocuments = (licenseId) => {
    const key = "otrosDocumentos";
    const formData = new FormData();
    extraDocuments.forEach((file) => {
      formData.append("userId", user.datos.id);
      formData.append("cuit", user.datos.cuit);
      formData.append("key", key);
      formData.append(key, file);
      formData.append("sendMeta4", false);
      formData.append("licenseId", licenseId);
    });
    dispatch(postCertificado(formData))
  };

  const saveDNIFAMILIAR = (licenseId) => {
    const key = "dniFamiliar";
    const formData = new FormData();
    dniFamiliar.forEach((file) => {
      formData.append("userId", user.datos.id);
      formData.append("cuit", user.datos.cuit);
      formData.append("key", key);
      formData.append(key, file);
      formData.append("sendMeta4", false);
      formData.append("licenseId", licenseId);
    });
    dispatch(postCertificado(formData))
  };

  const handleNext = async () => {
    if (activeStep === 1) {
      if (license.form.tipoLicencia.code === "ART") {
        setDialogArt(true);
      }
      const newFormSteps = FormSteps(control, setValue, user, license);
      setFormSteps(newFormSteps);
      setSteps([...Array(newFormSteps.length - 2)].map(() => ""));
    }
    else if (activeStep === formSteps.length - 1) {
      
      const direccion =
        user.domicilio.direccionId === license.form.domicilio
          ? user.domicilio
          : user.otrosDomicilios?.find(
            (x) => x.direccionId === license.form.domicilio
          );
      
      
      const d = {
        altura: direccion?.altura ? direccion?.altura:user.domicilio.altura,
        calle: direccion?.calle? direccion?.calle:user.domicilio.calle,
        codPostal: direccion?.codPostal? direccion?.codPostal:user.domicilio.codPostal,
        depto: direccion?.depto ?direccion?.depto:user.domicilio.depto,
        direccionId: direccion?.direccionId?direccion?.direccionId:user.domicilio.direccionId,
        latitud: direccion?.latitud?direccion?.latitud:user.domicilio.latitud,
        localidad: direccion?.localidad?direccion?.localidad:user.domicilio.localidad,
        longitud: direccion?.longitud?direccion?.longitud:user.domicilio.longitud,
        observaciones: direccion?.observaciones?direccion?.observaciones:user.domicilio.observaciones,
        piso: direccion?.piso?direccion?.piso:user.domicilio.piso,
        provincia: direccion?.provincia?direccion?.provincia:user.domicilio.provincia,
        telefono: user.domicilio.telefono,
        tipoDireccion: direccion?.tipoDireccion? direccion?.tipoDireccion:user.domicilio.tipoDireccion,
        usuarioId: direccion?.usuarioId?direccion?.usuarioId:user.domicilio.usuarioId
      }
      

      const familiar = user.familiares?.find(
        (x) => x.id === license.form.familiar
      );

    
      var data = {
        license: license.form,
        user: user,
        direccion: d,
        familiar
      }
      
      await saveDocuments(data);
      return;
    }

    const validarSolapamiento = stepsValidateSolapamiento.includes(
      formSteps[activeStep].key
    );

    if (validarSolapamiento && !ausencia && !sustituta) {
      const fechaString = "01/01/4000";
      const [dia, mes, año] = fechaString.split("/");
      const fechaHasta = new Date(año, mes - 1, dia);
      dispatch(
        getSolapamientoAusenteAsync({
          idhr: user.datos.idHR,
          idRol: license.form.unidadesOrganizativas[0]?.idrol,
          fechaHasta:
            formSteps[activeStep].key === "FechaSiniestro"
              ? fechaHasta.toISOString()
              : null,
        })
      ).then((res) => {
        if (res.payload.respuesta === "SI") {
          setDialogOpen({
            open: true,
            message:
              "La licencia no puede ser creada en este período porque se solapa con otra.",
            reset: true,
          });
        } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
      });
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 2) {
      setFormSteps(FormSteps(control, setValue, user, license));
      setSteps([]);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancelRequest = () => {
    dispatch(resetDocuments());
    dispatch(ResetState());
    navigate("/license");
  };

  const handleDialogClose = () => {
    if (dialogOpen.reset) {
      dispatch(resetDocuments());
      dispatch(ResetState());
      navigate("/license");
    } else setDialogOpen({ open: false });
  };

  const [confirmarAusencia, setConfirmarAusencia] = React.useState(false);

  const parseDate = (date) => {
    const fechaFormateada = date.toDate
      ? moment(date.toDate()).format("DD/MM/YYYY HH:mm")
      : moment(date).format("DD/MM/YYYY HH:mm");
    return fechaFormateada;
  };

  const obtenerFechaHasta2359 = () => {
    const fechaActual = new Date();
    fechaActual.setTime(fechaActual.getTime() + 24 * 60 * 60 * 1000);
    return parseDate(fechaActual) + " PM"; // Llamamos a la función parseDate para formatear la fecha generada.
  };

  const [ausenciaOpen, setAusenciaOpen] = React.useState(false);

  const handleSubmitAusencia = () => {
    setAusenciaOpen(false);

    dispatch(saveLicenseAusenteAsync({ license: license.form, user })).then(
      (response) => {
        if (response.payload.success) {
          dispatch(resetDocuments());
          dispatch(ResetState());
          navigate("/license");
        } else
          setDialogOpen({
            open: true,
            message: response.payload.message,
            reset: true,
          });
      }
    );
  };
  const makeRandomId = () => {
    const crypto = window.crypto || window.msCrypto;
    var array = new Uint32Array(1);
    crypto.getRandomValues(array);
    return array[0];
  };

  const formatDomicilio = () => {
    const domicilio = user.domicilio;
    return `${domicilio.calle} ${domicilio.altura} ${domicilio.piso ?? ""} ${domicilio.depto ?? ""
      } - CP: ${domicilio.codPostal} `;
  };

  const formatPhone = () => {
    return `${user.domicilio.telefono.countryCode} ${user.domicilio.telefono.areaCode} ${user.domicilio.telefono.phoneNumber}`;
  };

  return (
    <div>
      

      <Container
        sx={{
          pt: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Fade timeout={1000} in={steps.length}>
          <Stepper
            key="stepper"
            activeStep={activeStep - 2}
          //sx={{ width: { xs: 1, sm: 0.4 } }}
          >
            {steps.map((index) => {
              return (
                <Step key={"steps" + makeRandomId()}>
                  <StepLabel key={"step-label" + index} />
                </Step>
              );
            })}
          </Stepper>
        </Fade>
      </Container>
      {!license.form.isAusencia ? (
        <Container sx={{ pb: 4 }}>
          <Container sx={{ width: { xs: 0.8, sm: 0.4 }, py: 6 }}>
            <Typography
              color="#ff3d8b"
              align="center"
              sx={{ pb: 3, fontSize: "28px", fontWeight: 900 }}
            >
              Solicitar Licencia
            </Typography>
            <Box sx={{ mt: steps.length ? 0 : 3 }}>{formSteps[activeStep]}</Box>
          </Container>

          <React.Fragment>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                className="border-2"
                hidden={activeStep === 0}
                disabled={ausencia && activeStep === 3}
                onClick={handleBack}
                sx={{ width: "200px", mr: 1, textTransform: "none" }}
              >
                Volver
              </Button>
              <Button
                variant="contained"
                color="terciary"
                disabled={!stepIsComplete}
                onClick={handleNext}
                sx={{ width: "200px", textTransform: "none", color: "#FFF" }}
              >
                {activeStep !== 1 && activeStep === formSteps.length - 1
                  ? "Confirmar"
                  : "Siguiente"}
              </Button>
            </Box>
          </React.Fragment>
          <React.Fragment>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                justifyContent: "center",
              }}
            >
              <Button
                color="terciary"
                onClick={() => setModalOpen(true)}
                sx={{ width: "200px", textTransform: "none" }}
              >
                Cancelar
              </Button>
            </Box>
          </React.Fragment>
        </Container>
      ) : (
        <Container sx={{ width: { xs: 0.8, sm: 0.4 }, py: 6 }}>
          <Box
            bgcolor="#5836ff"
            p={2}
            my={2}
            className="flex items-center"
            color="white"
          >
            <TaskAltIcon sx={{ mr: 2 }} />
            <Typography variant="subtitle1">
              Revisá que la información sea la correcta
            </Typography>
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Datos asociados a la solicitud
          </Typography>
          <Divider sx={{ border: "1px solid black", mb: 3 }} />

          <Box display="flex" flexDirection="row" mb={1}>
            <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
              Régimen:
            </Typography>
            <Typography>
              {
                license.data.regimenes.find(
                  (r) => r.code === license.form.regimen
                )?.description
              }
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography color="#5836ff" sx={{ fontWeight: "bold" }}>
              Unidad/es Organizativa/s:
            </Typography>
            {license.form.unidadesOrganizativas?.map((x) => (
              <Typography key={x.code}>
                {x.code} {x.description}
              </Typography>
            ))}
          </Box>
          <Box mb={1} className="flex">
            <Typography color="#5836ff" sx={{ fontWeight: "bold" }}>
              Observaciones:
            </Typography>
            <Typography>
              {license.form.observaciones?.length > 0
                ? license.form.observaciones
                : "Sin observaciones"}
            </Typography>
          </Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Información de la solicitud
          </Typography>
          <Divider sx={{ border: "1px solid black", mb: 3 }} />
          <Box display="flex" flexDirection="row" mb={1}>
            <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
              Tipo de Licencia:
            </Typography>
            <Typography>
              {
                license.data.tipoLicencias.find(
                  (tl) => tl.code === license.form.tipoLicencia.code
                )?.description
              }
            </Typography>
          </Box>

          <Box display="flex" flexDirection="row" mb={1}>
            <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
              Licencia:
            </Typography>
            <Typography>{license.form.licencia?.description}</Typography>
          </Box>
          {license.form.tipoLicencia.code === "MEH" ? (
            <>
              <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
                  Generación de la solicitud:
                </Typography>
                <Typography>
                  {moment(new Date()).format("DD/MM/YYYY")}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
                  Fecha de inicio de hospitalización programada:
                </Typography>
                <Typography>
                  {moment(new Date(license.form.fechaInicio)).format(
                    "DD/MM/YYYY"
                  )}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
                  Podés anular la solicitud hasta:
                </Typography>
                <Typography>
                  {moment(new Date(license.form.fechaInicio))
                    .subtract(1, "days")
                    .format("DD/MM/YYYY")}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
                  Domicilio:
                </Typography>
                <Typography>{formatDomicilio()}</Typography>
              </Box>
              <Typography
                variant="subtitle1"
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                Información de contacto
              </Typography>
              <Divider sx={{ border: "1px solid black", mb: 3 }} />
              <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
                  Correo electrónico:
                </Typography>
                <Typography>{user.datos.email}</Typography>
              </Box>
              <Box display="flex" flexDirection="row" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
                  Teléfono:
                </Typography>
                <Typography>{formatPhone()}</Typography>
              </Box>
              <Typography
                variant="subtitle1"
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                Documentación
              </Typography>
              <Divider sx={{ border: "1px solid black", mb: 3 }} />
              <Box display="flex" flexDirection="column" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
                  Documentación a presentar:
                </Typography>
                <Typography>
                  Certificado de hospitalización ó resumen de historia clínica,
                  si al momento de completarla continúas hospitalizado/a informe
                  de epicrisis si contás con el alta
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" mb={1}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
                  Documentación a presentar para anular la solicitud:
                </Typography>
                <Typography>
                  Constancia de cancelación de la hospitalización
                </Typography>
              </Box>
            </>
          ) : (
            <Box display="flex" flexDirection="row" mb={1}>
              <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
                Validez para completar hasta:
              </Typography>
              <Typography>{obtenerFechaHasta2359()}</Typography>
            </Box>
          )}
          <Typography variant="subtitle1" sx={{ mt: 3, fontWeight: "bold" }}>
            Declaración jurada
          </Typography>
          <Divider sx={{ border: "1px solid black", mb: 3 }} />
          <Box mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmarAusencia}
                  onChange={(e) => setConfirmarAusencia(e.target.checked)}
                  sx={{ pt: 0 }}
                />
              }
              label="Entiendo que esta solicitud tiene carácter de declaración jurada y confirmo que los datos ingresados son verdaderos."
              sx={{ alignItems: "flex-start" }}
            />
          </Box>
          <Box className="w-full justify-center flex flex-col mt-3 gap-2">
            <Box className="w-full justify-center flex gap-2">
              <Button
                className="w-full border-2"
                variant="outlined"
                onClick={() => {
                  setValue("isAusencia", false, { shouldTouch: true });
                  setValue("fechaInicio", undefined, { shouldTouch: true });
                }}
              >
                Volver
              </Button>
              <Button
                className="w-full"
                color="terciary"
                variant="contained"
                disabled={!confirmarAusencia}
                onClick={() => setAusenciaOpen(true)}
                style={{ color: "#FFF" }}
              >
                Confirmar
              </Button>
            </Box>
            <Button
              onClick={() => setModalOpen(true)}
              color="terciary"
              className="w-fit mx-auto"
            >
              Cancelar
            </Button>
          </Box>
        </Container>
      )}
      <Dialog open={ausenciaOpen} onClose={() => setAusenciaOpen(false)}>
        <DialogContent className="text-center">
          {license.form.tipoLicencia.code === "MEH" ? (
            "Recordá adjuntar el certificado médico de la hospitalización para completar la licencia. De lo contrario tu solicitud quedará en estado injustificada. Así como el/los día/s que te ausentes al trabajo"
          ) : (
            <p>
              Te recordamos que deberás adjuntar el certificado médico
              correspondiente dentro de las 24 hs. de notificada tu ausencia,
              para poder completar la solicitud de licencia. <br />
              Pasadas las 24 hs. sin adjuntar el certificado médico, tu
              solicitud quedará en estado injustificada, así como el/los día/s
              que te ausentes al trabajo.
            </p>
          )}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            size="small"
            color="terciary"
            variant="contained"
            autoFocus
            sx={{ color: "#fff" }}
            onClick={handleSubmitAusencia}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle sx={{ color: "#5836ff" }}>
          Estás por cancelar la solicitud.
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          ¿Deseás continuar?
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => setModalOpen(false)}
            size="small"
            color="terciary"
            variant="outlined"
            style={{ textTransform: "capitalize" }}
          >
            NO
          </Button>
          <Button
            onClick={handleCancelRequest}
            size="small"
            color="terciary"
            variant="contained"
            style={{ textTransform: "capitalize", color: "#FFF" }}
            autoFocus
          >
            SI
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogArt} onClose={() => setDialogArt(false)}>
        <WarningIcon
          fontSize="large"
          color="warning"
          sx={{ mx: "auto", mt: 2 }}
        />
        <DialogContent className="text-center">
          Si su primer día de ausentismo laboral es{" "}
          <span className="font-semibold">anterior al día de hoy</span>,
          comunicate con tu responsable de Recursos Humanos para{" "}
          <span className="font-semibold">
            justificar los días de inasistencia.
          </span>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => setDialogArt(false)}
            size="small"
            color="terciary"
            variant="contained"
            style={{ color: "#fff" }}
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogOpen.open} onClose={handleDialogClose}>
        <DialogContent className="text-center">{dialogOpen.message}</DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={handleDialogClose}
            size="small"
            color="terciary"
            variant="contained"
            autoFocus
            style={{ color: "#fff" }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const isStepComplete = (form, stepFields) =>
  stepFields.every((field) => {
    const value = form[field];
    if (field === "nroSiniestro") {
      if (form.nroSiniestro === "000000") return false;
    }
    if (value) {
      if (value.hasOwnProperty("$d")) {
        return !Object.values(value).some((valor) => valor == "Invalid Date");
      }
    }
    if (field === "certificadoART") return true;
    if (field === "certificadoAccArt") return true;
    if (!value) return false;

    if (Array.isArray(value)) {
      return value.length > 0;
    } else if (typeof value === "string")
      return value !== null && value.trim() !== "";
    else if (typeof value === "object")
      if (value instanceof Date) return true;
      else return !isEmptyObject(value);
    else return value;
  });

function isEmptyObject(obj) {
  return Object.keys(obj).every((key) => obj[key] === undefined);
}
