import { Box, FormControl, FormLabel, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { selectLicenses } from "../licenseSlice";
import { Controller } from "react-hook-form";

export default function Neonatologia({ control, setValue }) {
    const { form } = useSelector(selectLicenses);

    const validateNeonat = (event) => {
        const isChecked = event.target.checked;
        setValue("neonatologia", isChecked, { shouldTouch: true });
        if (!isChecked)
            setValue("diasNeonatologia", "1", { shouldTouch: true });
    }

    return (
        <>
            <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                ¿El/la recién nacido debió permanecer internado/a en neonatología?
            </FormLabel>
            <FormControl id="check-neonatologia" fullWidth>
                <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'left' }}>
                    <Controller
                        name="neonatologia"
                        control={control}
                        render={({ field }) => (
                            <>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>No</Typography>
                                    <Switch
                                        checked={form?.neonatologia}
                                        onChange={validateNeonat} />
                                    <Typography>Sí</Typography>
                                </Stack>
                            </>
                        )}
                    />
                </Box>
            </FormControl>
            {
                form.neonatologia &&
                <>
                    <FormControl id="input-neonatologia" fullWidth>
                        <Box pt={1}>
                            <Controller
                                name="diasNeonatologia"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                                            Días de internación
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            inputProps={{ min: 0, max: 365 }}
                                            variant="outlined"
                                            size="small"
                                            defaultValue={form.diasNeonatologia}
                                            onChange={onChange}
                                            type="number">
                                        </TextField>
                                    </>
                                )}
                            />
                        </Box>
                    </FormControl>

                </>
            }
        </>
    );
}