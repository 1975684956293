import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useRef } from "react";
import "./styles.scss";
import Tooltip from "@mui/material/Tooltip";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import WallpaperOutlinedIcon from "@mui/icons-material/WallpaperOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import {
  handleProfileModal,
  handlechangeProfileModal,
} from "../../modals/modalSlice";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import Logout from "@mui/icons-material/Logout";
import { persistor } from "../../../app/store.js";
import { reset } from "../../user/userSlice";
import Profile from "../../user/profile";
import ChangeProfile from "../../modals/change-profile";
import ConfirmModal from "../../modals/confirm-modal";
import { resetDocuments } from "../../files/filesSlice";
import NotificationMenu from "../../notifications-menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
const ITEM_HEIGHT = 48;
export default function MenuMobileHeader({ open, setOpen, onClickNotification, mb }) {
  const usuario = useSelector((state) => state.usuario);
  const [openOther, setOpenOther] = React.useState(false);
  const [anchorElManual, setAnchorElManual] = React.useState(null);
  const openManual = Boolean(anchorElManual);
  const { perfilActual } = useSelector((state) => state.usuario);
  const handleClickManual = (event) => {
    
    setAnchorElManual(event.currentTarget);
  };
  const handleCloseManual = () => {
    setAnchorElManual(null);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [auditorMedico, setAuditorMedico] = React.useState(false);
  const [empleados, setEmpleados] = React.useState(false);

  const handleDescargar = (url) => {
    const pdfURL = window.location.origin + url;
    const link = document.createElement("a");
    link.href = pdfURL;
    link.download = url.split("/")[3];
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };
  const change = Boolean(anchorEl);
  const handleSolicitarLicencia = () => {
    navigate("/license-request-form");
  };
  const location = useLocation();

  const iconButtonRef = useRef(null);

  const handleClick = (event) => {
    if (change) setAnchorEl(null);
    else setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCancel = () => {
    dispatch(reset());
    persistor.purge();
    navigate("/login");
  };
  const [openLicenses, setOpenLicenses] = React.useState(false);
  const handleReset = () => {
    setOpenExit(false);
    //dispatch(reset())
    if (location.pathname !== usuario.perfilActual.rootPath) {
      dispatch(resetDocuments());
    }
    navigate(usuario.perfilActual.rootPath);
  };
  const [openExit, setOpenExit] = React.useState(false);
  if (
    location.pathname === "/login" ||
    location.pathname === "/select-profile" ||
    location.pathname === "/registration" ||
    location.pathname === "/mia-ios"
  ) {
    return null;
  }

 
  return (
    <>
      <Menu
        anchorEl={anchorElManual}
        open={openManual}
        onClose={handleCloseManual}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "300px",
          },
        }}
      >
        {perfilActual.idPerfil === "EMPLEADO" && (
          <>
            <ListItemButton onClick={() => setEmpleados(!empleados)}>
              <ListItemText primary="Empleados/as" />
              {empleados ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={empleados} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Manual 1 - PT.SG.PG.R.PDC.CSV v01.12.20.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual de Licencias Médicas para PT, SG, PG, R, PDC y CSV" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Manual 1 - Lic. Médicas - CAP.CEETPS.CPH.RG v01.12.20.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual de Licencias Médicas para CAP, CEETPS, CPH y RG" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Manual 1 - Lic. Médicas - Escalafón Docente v01.12.20.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual de Licencias Médicas para Escalafón Docente" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Manual 3 - Lic. Administrativas - Escalafón Docente 171121.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual de Licencias Administrativas para Escalafón Docente" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Manual 3 - Lic. Administrativas 151021.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual de Licencias Administrativas" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Instructivo ART para ella empleadoa.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manual de Licencias ART" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/DDJJ Mudanza - IF-2021-30648083-GCABA-MHFGC.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="MDDJJ Mudanza" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Glosario Lic. Administrativas Docentes vf.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Glosario Lic. Administrativas Docentes" />
                </ListItemButton>

                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/Glosario Lic. Administrativas Escalafón General vf.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Glosario Lic. Administrativas Escalafón General" />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        )}
        {/* {perfilActual.idPerfil === "AUTORIZANTE" && (
            <>
              <ListItemButton onClick={() => setEmpleados(!empleados)}>
                <ListItemText primary="Autorizante" />
                {empleados ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={empleados} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    onClick={() =>
                      handleDescargar(
                        "/manuales/Empleados-as/MIA 3 ROL AUTORIZANTE 151021.pdf"
                      )
                    }
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="MIA 3 ROL AUTORIZANTE" />
                  </ListItemButton>
                </List>
              </Collapse>
            </>
          )}
          {perfilActual.idPerfil === "AUTORIDAD_SUPERIOR" && (
            <>
              <ListItemButton onClick={() => setEmpleados(!empleados)}>
                <ListItemText primary="Revisor" />
                {empleados ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={empleados} timeout="auto" unmountOnExit>
                <ListItemButton
                  onClick={() =>
                    handleDescargar(
                      "/manuales/Empleados-as/MIA 3 ROL REVISOR.A 041121.pdf"
                    )
                  }
                  sx={{ pl: 4 }}
                >
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="MIA 3 ROL REVISOR.A" />
                </ListItemButton>
              </Collapse>
            </>
          )} */}
        {(perfilActual.idPerfil === "AU_PROFESIONAL" ||
          perfilActual.idPerfil === "AU_PROFESIONAL_LT") && (
            <>
              <ListItemButton onClick={() => setAuditorMedico(!auditorMedico)}>
                <ListItemText primary="Auditor Médico" />
                {auditorMedico ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={auditorMedico} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    onClick={() =>
                      handleDescargar(
                        "/manuales/Auditor-médico/MANUAL MIA Auditor-a profesional y Auditor-a prof. de largo tratamiento.0908.pdf"
                      )
                    }
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manual Auditor-a profesional y Auditor-a prof. de largo tratamiento" />
                  </ListItemButton>
                </List>
              </Collapse>
            </>
          )}

        {perfilActual.idPerfil === "AUDITOR_ART" && (
            <>
              <ListItemButton onClick={() => setAuditorMedico(!auditorMedico)}>
                <ListItemText primary="Auditor Médico" />
                {auditorMedico ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={auditorMedico} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    onClick={() =>
                      handleDescargar(
                        "/manuales/Auditor-médico/MANUAL MIA Auditor-a profesional y Auditor-a prof. de largo tratamiento.0908.pdf"
                      )
                    }
                    sx={{ pl: 4 }}
                  >
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manual Auditor-a profesional y Auditor-a prof. de largo tratamiento" />
                  </ListItemButton>
                </List>
              </Collapse>
            </>
          )}
      </Menu>
      <div className="flex flex-col justify-center py-2 bg-[#5836ff] gap-2 hiddenWeb sticky top-0 z-[2] w-screen">
        <div className="flex items-center justify-between px-2">
          <IconButton
            hidden={usuario.perfilActual.idPerfil !== "EMPLEADO"}
            onClick={() => {
              setAnchorEl(null);
              setOpen(!open);
              setOpenLicenses(false);
            }}
          >
            {open ? (
              <CloseIcon style={{ color: "#fff" }} />
            ) : (
              <MenuIcon style={{ color: "#fff" }} />
            )}
          </IconButton>
          <IconButton
            hidden={
              usuario.perfilActual.idPerfil === "EMPLEADO" ||
              !usuario.perfilActual.hasOwnProperty("idPerfil")
            }
            onClick={() => {
              setAnchorEl(null);
              setOpenOther(!openOther);
            }}
          >
            {openOther ? (
              <CloseIcon style={{ color: "#fff" }} />
            ) : (
              <MenuIcon style={{ color: "#fff" }} />
            )}
          </IconButton>
          <h2 className="text-white mt-2" onClick={() => setOpenExit(true)}>
            Mi Autogestión
          </h2>
          {usuario.perfilActual.idPerfil === "EMPLEADO" && (
            <NotificationMenu
              mb={true}
              onClickNotification={onClickNotification}
            />
          )}
          <Tooltip title="Opciones de usuario">
            <IconButton
              ref={iconButtonRef}
              onClick={handleClick}
              size="small"
              aria-controls={change ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={change ? "true" : undefined}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  bgcolor: "#00D6D1",
                  fontSize: "14px",
                  fontWeight: 900,
                }}
              >
                {usuario.datos.nombre?.charAt(0)}
                {usuario.datos.apellido?.charAt(0)}
              </Avatar>
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div
        className={`h-screen top-0 w-[70vw] bg-white z-10 fixed shadow-nav sm:hidden ${open ? "translate-x-0" : "-translate-x-[110%]"
          } duration-500`}
      >
        <ListItemButton style={{ width: '100%', marginTop: '5%', position: "absolute" }}
          onClick={() => {
            setAnchorEl(null);
            setOpen(!open);
            setOpenLicenses(false);
          }}
        >
          <ListItemIcon sx={{ width: '100%', justifyContent: 'end !important' }}>
            <CloseIcon />
          </ListItemIcon>
          <ListItemText />
        </ListItemButton>
        <div className="flex flex-col gap-[25px] justify-center h-full">
          <MenuItem
            onClick={() => {
              setOpen(false);
              setOpenExit(true);
            }}
          >
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <Typography variant="h6">Inicio</Typography>
          </MenuItem>
          {(perfilActual.idPerfil === "EMPLEADO" ||
            perfilActual.idPerfil === "AU_PROFESIONAL" ||
            perfilActual.idPerfil === "AU_PROFESIONAL_LT" ||
            perfilActual.idPerfil === "AUDITOR_ART") && (
              <MenuItem
                onClick={(e) => {
                  handleClickManual(e);
                }}
              >
                <ListItemIcon>
                  <AutoStoriesOutlinedIcon />
                </ListItemIcon>
                <Typography variant="h6">Manual de Uso</Typography>
              </MenuItem>
            )}
          <MenuItem
            onClick={() => {
              setOpen(false);
              handleSolicitarLicencia();
            }}
          >
            <ListItemIcon>
              <AddCircleOutlineIcon />
            </ListItemIcon>
            <Typography variant="h6">Solicitar Licencia</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenLicenses(!openLicenses);
            }}
          >
            <ListItemIcon>
              <ListAltOutlinedIcon />
            </ListItemIcon>
            <Typography variant="h6">Mis Licencias</Typography>
          </MenuItem>
          <div className={`transition-all duration-500 ${openLicenses ? "h-[250px]" : "h-0 opacity-0"} -my-3`}>
            <MenuItem
              onClick={() => {
                setOpen(false);
                setOpenLicenses(!openLicenses);
                navigate(`${usuario.perfilActual.rootPath}?actBtnMobile=ABI`);
              }}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <Typography variant="p">Licencias Abiertas</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpen(false);
                setOpenLicenses(!openLicenses);
                navigate(`${usuario.perfilActual.rootPath}?actBtnMobile=MED`);
              }}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <Typography variant="p">Médicas</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpen(false);
                setOpenLicenses(!openLicenses);
                navigate(`${usuario.perfilActual.rootPath}?actBtnMobile=ADM`);
              }}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <Typography variant="p">Administrativas</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpen(false);
                setOpenLicenses(!openLicenses);
                navigate(`${usuario.perfilActual.rootPath}?actBtnMobile=ART`);
              }}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <Typography variant="p">Contingencia ART</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpen(false);
                setOpenLicenses(!openLicenses);
                navigate(`${usuario.perfilActual.rootPath}?actBtnMobile=HIS`);
              }}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
              <Typography variant="p">Historial Licencias</Typography>
            </MenuItem>
          </div>
          <MenuItem
            onClick={() => {
              setOpen(false);
              iconButtonRef.current.click();
            }}
          >
            <ListItemIcon>
              <PersonOutlinedIcon />
            </ListItemIcon>
            <Typography variant="h6">Mi Perfil</Typography>
          </MenuItem>
          <Link
            className="text-decoration-none text-[#212529]"
            to="/foco"
            onClick={() => {
              setOpen(false);
            }}
          >
            <MenuItem>
              <ListItemIcon>
                <WallpaperOutlinedIcon />
              </ListItemIcon>
              <Typography variant="h6">Foco</Typography>
            </MenuItem>
          </Link>
          <a
            href="https://asichatbot.buenosaires.gob.ar/autogestion_licencias"
            target="_blank"
            rel="noreferrer"
            className="text-decoration-none text-[#212529]"
            onClick={() => setOpen(false)}
          >
            <MenuItem>
              <ListItemIcon>
                <HelpOutlineOutlinedIcon />
              </ListItemIcon>
              <Typography variant="h6">Asistente Virtual</Typography>
            </MenuItem>
          </a>
        </div>
      </div>
      <div
        className={`h-screen top-0 w-[70vw] bg-white z-[999999] fixed shadow-nav sm:hidden ${openOther ? "translate-x-0" : "-translate-x-[110%]"
          } duration-500`}
      >
        <div className="flex flex-col gap-[25px] justify-center h-full">
          <MenuItem
            onClick={() => {
              setOpenOther(false);
              setOpenExit(true);
            }}
          >
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <Typography variant="h6">Inicio</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenOther(false);
              iconButtonRef.current.click();
            }}
          >
            <ListItemIcon>
              <PersonOutlinedIcon />
            </ListItemIcon>
            <Typography variant="h6">Mi Perfil</Typography>
          </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <AutoStoriesOutlinedIcon />
              </ListItemIcon>
              <Typography variant="h6">Manual de Uso</Typography>
            </MenuItem>
          <hr className="mx-3 my-0" />
          <MenuItem
            onClick={() => {
              setOpenOther(false);
              handleCancel();
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <Typography variant="h6">Cerrar Sesión</Typography>
          </MenuItem>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={change}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ListItem key="datos" sx={{ color: "#16c2a2" }}>
          <PermIdentityIcon />
          <ListItemText
            primary={
              usuario.datos.nombre == undefined
                ? ""
                : usuario.datos.nombre.concat(" ", usuario.datos.apellido)
            }
            sx={{ textAlign: "center" }}
            variant="label"
          />
        </ListItem>
        <MenuItem>
          <ListItemText
            secondary={usuario.perfilActual.nombrePerfil}
            sx={{ textAlign: "center" }}
            variant="label"
          />
        </MenuItem>
        <Divider />
        <MenuItem
          key="edit"
          onClick={() => dispatch(handleProfileModal({ open: true }))}
        >
          <ListItemIcon>
            <ModeEditOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="label">Editar datos personales</Typography>
        </MenuItem>
        <Divider />

        <MenuItem
          key="change-profile"
          onClick={() => dispatch(handlechangeProfileModal({ open: true }))}
        >
          <ListItemIcon>
            <ReplayOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="label">Cambiar perfil</Typography>
        </MenuItem>
        <Divider />
        <MenuItem key="logout" onClick={handleCancel}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Typography variant="label">Cerrar sesión</Typography>
        </MenuItem>
      </Menu>
      <Profile />
      <ChangeProfile />
      <ConfirmModal />
      <Dialog
        open={openExit}
        onClose={() => setOpenExit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ color: "#5836ff", textAlign: "center" }}>
          Volver al inicio
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          Se perderán todos los cambios no guardados
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => setOpenExit(false)}
            size="small"
            color="terciary"
            variant="outlined"
            style={{ textTransform: "capitalize" }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleReset}
            size="small"
            color="terciary"
            variant="contained"
            style={{ textTransform: "capitalize", color: "#FFF" }}
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
