import {
  Autocomplete,
  Box,
  Container,
  FormControl,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { Localidades, Provincias } from "../registration/locations";
const Address = ({ form , defaultDomicilio, isItinere = true}) => {
  const theme = createTheme({
    typography: {
      h1: {
        fontSize: 24,
        fontWeight: 600,
        color: "#1d5a87",
      },
      h2: {
        fontSize: 20,
      },
      label: {
        fontWeight: 500,
      },
    },
  });
  const {
    control,
    watch,
    formState: { errors },
  } = form;
  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ py: 2, display: "flex", flexDirection: "column" }}>

        <Box>
          <FormControl fullWidth>
            <Typography variant="label">Calle/Estación</Typography>
            <Controller
              name="calle"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <TextField {...field} size="small" />}
            />
            {errors.calle && (
              <Typography sx={{ color: "#Ff0000" }}>Campo requerido</Typography>
            )}
          </FormControl>
        </Box>
        <Box
          sx={{
            paddingTop: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap:2
          }}
        >
          <FormControl>
            <Typography variant="label">Número {!isItinere && <span className="text-xs"> (Opcional)</span>}</Typography>
            <Controller
              name="altura"
              control={control}
              rules={{ required: isItinere }}
              render={({ field }) => (
                <TextField {...field} size="small" type="number" />
              )}
            />
            {errors.altura && (
              <Typography sx={{ color: "#Ff0000" }}>Campo requerido</Typography>
            )}
          </FormControl>
          <FormControl>
            <Typography variant="label">Piso {!isItinere && <span className="text-xs"> (Opcional)</span>}</Typography>
            <Controller
              name="piso"
              control={control}
              render={({ field }) => <TextField {...field} size="small" />}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            paddingTop: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap:2
          }}
        >
          <FormControl>
            <Typography variant="label">Depto. {!isItinere && <span className="text-xs"> (Opcional)</span>}</Typography>
            <Controller
              name="depto"
              control={control}
              render={({ field }) => <TextField {...field} size="small" />}
            />
          </FormControl>
          <FormControl>
            <Typography variant="label">Código Postal {!isItinere && <span className="text-xs"> (Opcional)</span>}</Typography>
            <Controller
              name="codPostal"
              control={control}
              rules={{ required: isItinere, pattern: /^[0-9]{1,4}$/ }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  error={Boolean(errors.codPostal)}
                  inputProps={{
                    maxLength: 4,
                    pattern: "[0-9]{1,4}",
                    inputMode: "numeric",
                  }}
                />
              )}
            />
            {errors.codPostal && (
              <Typography sx={{ color: "#Ff0000" }}>
                Campo requerido <br /> Solo números - Maximo 4
              </Typography>
            )}
          </FormControl>
        </Box>
        <Box sx={{mt:2}}>
          <FormControl fullWidth>
            <Typography variant="label">Entre Calles {!isItinere && <span className="text-xs"> (Opcional)</span>}</Typography>
            <Controller
              name="entreCalles"
              control={control}
              render={({ field }) => <TextField {...field} size="small" />}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl fullWidth sx={{ pb: 2, pt: 2 }}>
            <Typography variant="label">Provincia</Typography>
            <Controller
              name="provincia"
              control={control}
              rules={{required:true}}
              render={({ field: { onChange , value} }) => {
                return (
                <Autocomplete
                  freeSolo
                  size="small"
                  defaultValue={
                    Provincias.find((x) => x.id === value?.id) ||
                    null
                  }
                  options={Provincias}
                  getOptionLabel={(option) => option.descripcion}
                  onChange={(e, values) => onChange(values)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: false }}
                    />
                  )}
                />
              )}}
            />
                        {errors.provincia && (
              <Typography sx={{ color: "#Ff0000" }}>Campo requerido</Typography>
            )}
          </FormControl>
          <FormControl fullWidth sx={{ pb: 2 }}>
            <label htmlFor="localidad" style={{ fontWeight: "bold" }}>
              Localidad
            </label>
            <Controller
              name="localidad"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange , value } }) => (
                <Autocomplete
                  freeSolo
                  disabled={!watch("provincia")}
                  size="small"
                  defaultValue={
                    Localidades.find(
                      (x) => x.id === defaultDomicilio?.localidad?.id && x.idProvincia === defaultDomicilio?.provincia?.id
                    ) || null
                  }
                  error={Boolean(errors.localidad)}
                  options={Localidades.filter(
                    (x) => x.idProvincia === watch("provincia")?.id
                  )}
                  getOptionLabel={(option) => option.descripcion}
                  onChange={(e, values) => onChange(values)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: false }}
                    />
                  )}
                />
              )}
            />
            {errors.localidad && (
              <Typography sx={{ color: "#Ff0000" }}>Campo requerido</Typography>
            )}
          </FormControl>
        </Box>
        {/* <Box
          sx={{
            paddingTop: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <FormControl>
            <Typography variant="label">Cód. País</Typography>
            <Controller
              name="countryCode"
              control={control}
              defaultValue="+54"
              value="+54"
              rules={{ required: true }}
              render={({ field }) => (
                <TextField {...field} placeholder="Cód. país" size="small" />
              )}
            />
            {errors.countryCode && (
              <Typography sx={{ color: "#Ff0000" }}>Campo requerido</Typography>
            )}
          </FormControl>
          <FormControl>
            <Typography variant="label">Código de área</Typography>
            <Controller
              name="areaCode"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField {...field} placeholder="Cód. area" size="small" />
              )}
            />
            {errors.areaCode && (
              <Typography sx={{ color: "#Ff0000" }}>Campo requerido</Typography>
            )}
          </FormControl>
          <FormControl>
            <Typography variant="label">Teléfono</Typography>
            <Controller
              name="phoneNumber"
              control={control}
              value={usuario.domicilio?.piso}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField {...field} placeholder="Teléfono" size="small" />
              )}
            />
            {errors.phoneNumber && (
              <Typography sx={{ color: "#Ff0000" }}>Campo requerido</Typography>
            )}
          </FormControl>
        </Box> */}
        <Box
          sx={{
            paddingTop: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FormControl fullWidth>
            <Typography variant="label">Observaciones {!isItinere && <span className="text-xs"> (Opcional)</span>}</Typography>
            <Controller
              name="observaciones"
              control={control}
              rules={{ maxLength: 250 }}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={2}
                  placeholder="Si no contás con el dato de la calle y el número, deberás comentarlo en este campo de observaciones."
                  inputProps={{ maxLength: 250 }}
                  error={Boolean(errors.observaciones)}
                />
              )}
            />
            {errors.observaciones && (
              <Typography sx={{ color: "#Ff0000" }}>
                Campo requerido <br /> Maximo 250 caracteres
              </Typography>
            )}
          </FormControl>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
export default Address;
