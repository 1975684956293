import api, { APPCONFIG } from "../../api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications:[]
};

export const fetchNotificationsAsync = createAsyncThunk(
  "notifications/fetchNotificationsAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Notificaciones/GetNotificationsByUser?UserId=${data.userId}&userToken=${data.token}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const putNotificationStatus = createAsyncThunk(
  "notifications/putNotificationStatus",
  async (UserId) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Notificaciones/SaveNotificationStatus?UserId=${UserId}`;
    const response = await api.post(url);
    return response.data;
  }
);


export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsAsync.fulfilled, (state, action) => {
        state.notifications = action.payload;
      });
  },
});

export default notificationsSlice.reducer;
