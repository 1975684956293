import { Backdrop, Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import Filters from "./filters";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllPublicacionesAsync } from "../focoSlice";
import FocoUserTable from "./user-table";

const initialFilters = {
  publicationtype: "",
  category: "",
  title: "",
};

function Index() {
  const dispatch = useDispatch();
  const [filters, setFilters] = React.useState(initialFilters);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [page, setPage] = React.useState(0);
  const handleBuscar = () => {
    const filterArray = [];
    for (const key in filters) {
      const value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        filterArray.push({ code: key, description: value });
      }
    }
    dispatch(fetchAllPublicacionesAsync({ filters: filterArray , pageNumber: 0, pageSize: rowsPerPage})).then(x=>{
      setPage(0)
    });
  };

  const handleReset = () => {
    setFilters(initialFilters);
    dispatch(fetchAllPublicacionesAsync({ filters: [] , pageNumber: 0 , pageSize: rowsPerPage})).then(x=>{
      setPage(0)
    });
  };

  useEffect(() => {
    dispatch(fetchAllPublicacionesAsync({ filters: [] , pageNumber:page,pageSize:rowsPerPage})).then(res=>setIsLoading(false));
  }, []);

  const [isLoading, setIsLoading] = React.useState(true);
  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" size={100} />
      </Backdrop>
    );
  } else {
    return (
      <Box component="main" className="mb-5 d-flex flex-column gap-4">
        <header className="flex items-center justify-between bg-[#F4F8FF] p-[15px] gap-2 px-[40px]">
          <div className="w-fit">
            <h1 className="text-[#5836ff] font-medium text-3xl">FOCO</h1>
          </div>
        </header>
        <Filters
          handleBuscar={handleBuscar}
          initialFilters={initialFilters}
          filters={filters}
          setFilters={setFilters}
          handleReset={handleReset}
        />
        <FocoUserTable filters={filters}           page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}/>
      </Box>
    );
  }
}

export default Index;
