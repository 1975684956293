import { FormControl } from "@mui/base";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchDiagnosticosAsync, selectLicenses } from "../licenseSlice";
import { Autocomplete, Box, FormLabel, TextField } from "@mui/material";

export default function Diagnostico({ control }) {
  const { data, form } = useSelector(selectLicenses);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data.diagnosticos) {
      dispatch(fetchDiagnosticosAsync(""));
    }
  }, []);

  const getLicense = (value) => {
    if (value.length > 2) dispatch(fetchDiagnosticosAsync(value));
  };

  return (
    <>
      <FormControl id="input-diagnostico" fullWidth>
        <Box mt={2}>
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold", mb:1 }}>
          Seleccioná el Diagnóstico y la información médica.
          </FormLabel>
          <Controller
            name="diagnostico"
            control={control}
            rules={{ required: true, minLength: 5 }}
            render={({ field: { onChange } }) => (
              <Autocomplete
                freeSolo
                size="small"
                options={data.diagnosticos}
                getOptionLabel={(option) => option.description ?? ""}
                onChange={(e, values) => 
                  {
                    if (typeof values == "string")
                        onChange(null)
                    else
                      onChange(values)
                  }}
                defaultValue={form.diagnostico}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => getLicense(e.target?.value)}
                    InputLabelProps={{ shrink: false }}
                  />
                )}
              />
            )}
          />
          <p className="mt-1 text-sm text-gray-500">Transcribilo como lo puso el/la médico/a en el Certificado.</p>
        </Box>
      </FormControl>
    </>
  );
}
