/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { fetchLicenciasAsync, fetchRegimenesAsync } from "../../../license/licenseSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
function FiltersHistory({ id, filters, setFilters ,handleBuscar,disabled,handleReset}) {
  const { data } = useSelector((state) => state.licencia);
  const handleChange = (event) => {
    switch (event.target.name) {
      case "tipoLicencia":
        setFilters({ ...filters, tipoLicencia: event.target.value });
        break;
      default:
        break;
    }
  };

  const handleChangeDates = (action, event) => {
    switch (action) {
      case "fechaInicio":
        setFilters({ ...filters, fechaInicio: event });
        break;
      case "fechaFin":
        setFilters({ ...filters, fechaFin: event });
        break;
      default:
        break;
    }
  };
  const dispatch = useDispatch();
  const { datos } = useSelector((state) => state.usuario);
  useEffect(() => {
    dispatch(fetchRegimenesAsync(datos.idHR)).then((res) => {
      dispatch(
        fetchLicenciasAsync({
          idHr: datos.idHR,
         codRegimen: "GRAL",
          idGrupoLicencias: "ART",
        })
      );
    });
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const pageAct = queryParams.get("pageAct");
  const activeBtn = queryParams.get("activeBtn");
  const navigate = useNavigate();
  return (
    <>
    <Box className="p-3 d-flex gap-4 justify-content-start flex-wrap">
      <FormControl size="small" sx={{ width: "165px" }}>
        <InputLabel id="demo-simple-select-label">Tipo Licencia</InputLabel>
        <Select
          name="tipoLicencia"
          value={filters.tipoLicencia}
          label="Tipo Licencia"
          onChange={handleChange}
        >
          <MenuItem value={""}>Quitar Filtro</MenuItem>
          {data.licencias.map((lc, key) => (
            <MenuItem key={key} value={lc.description}>
              {lc.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
        <DatePicker
          onChange={(e) => handleChangeDates("fechaInicio", e)}
          sx={{ width: "185px" }}
          slotProps={{ textField: { size: "small" } }}
          className="datepicker"
          value={filters.fechaInicio}
          label="Fecha Inicio"
        />
        <DatePicker
          onChange={(e) => handleChangeDates("fechaFin", e)}
          sx={{ width: "185px" }}
          slotProps={{ textField: { size: "small" } }}
          className="datepicker"
          value={filters.fechaFin}
          label="Fecha Fin"
        />
      </LocalizationProvider>
      <Box className="d-flex justify-content-center gap-2 ml-auto">
        <Button variant="contained" onClick={handleBuscar}>
          Buscar
        </Button>
        <Button
          disabled={disabled}
          variant="outlined"
          onClick={handleReset}
        >
          Limpiar
        </Button>
      </Box> 
    </Box>
    <Button
        variant="outlined"
        style={{ marginLeft: "15px" }}
        startIcon={<ArrowBackIosIcon />}
        onClick={() => navigate(`/audit/history/${id}?pageAct=${pageAct}&activeBtn=${activeBtn}`)}
      >
        Volver
      </Button>
    </>
  );
}

export default FiltersHistory;
