import { FormControl } from "@mui/base";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectLicenses } from "../licenseSlice";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { Box, FormLabel, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function FechaSiniestro({ control, setValue }) {
  const { form } = useSelector(selectLicenses);

  const getDate = (days, startDate) => {
    const currentDate = startDate ?? moment(new Date());
    const result = currentDate.add(days, "days");
    return result;
  };

  const [open, setOpen] = useState(false);

  const getNumeroSiniestro = (inputValue) => {
    if(!inputValue)
    return;
    inputValue = inputValue.slice(0, 6);
    const leadingZeros = "000000";
    inputValue = leadingZeros?.slice(0, 6 - inputValue.length) + inputValue;
    setValue("nroSiniestro", inputValue, { shouldTouch: true });
  };


  return (
    <div className="flex flex-col justify-center mx-auto items-center">
      <FormControl>
        <Box mt={2} className="flex flex-col">
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Fecha de ocurrencia/siniestro
          </FormLabel>
          <Controller
            name="fechaSiniestro"
            control={control}
            render={({ field: { onChange } }) => (
              <LocalizationProvider
                adapterLocale="es"
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  open={open}
                  onClose={() => setOpen(false)}
                  defaultValue={form.fechaSiniestro ? dayjs(form.fechaSiniestro) : ''}
                  renderInput={(props) => <TextField {...props} readOnly={true}/>}
                  slotProps={{ textField: { size: "small", error: false, onClick: () => setOpen(true) } }}
                  onChange={(e)=>{
                    onChange(e)}}
                  maxDate={dayjs(moment(new Date()))}
                  minDate={dayjs(getDate(-730))}
                />
              </LocalizationProvider>
            )}
          />
        </Box>
      </FormControl>

      <FormControl fullWidth>
        <Box mt={2}>
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Número de siniestro otorgado
          </FormLabel>
          <Controller
            name="nroSiniestro"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className="flex items-center">
                <p className="px-[12px] my-auto mr-2 py-[5px] rounded-md bg-blue-800/40 text-blue-900 font-bold">
                  B
                </p>
                <TextField
                  className="w-full"
                  variant="outlined"
                  size="small"
                  value={value}
                  onChange={(e) => {
                    const originalValue = e.target.value;
                    const newValue = originalValue.replace(/[^0-9]/g, ''); // Solo permite números
                
                    if (newValue.length > 6) {
                      return; // Limita la longitud a 6 caracteres
                    }
                
                    onChange(newValue);
                  }}
                  placeholder="000000"
                  onBlur={() => getNumeroSiniestro(value)}
                  inputProps={{ maxLength: 6 }}
                ></TextField>
              </div>
            )}
          />
          {form.nroSiniestro === "000000" && (
            <small className="text-red-500">
              El nro de siniestro no puede ser 000000
            </small>
          )}
        </Box>
      </FormControl>
      <FormControl>
        <Box mt={2} className="flex flex-col">
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Fecha de Denuncia
          </FormLabel>
          <Controller
            name="fechaDenuncia"
            control={control}
            render={({ field: { onChange } }) => (
              <LocalizationProvider
                adapterLocale="es"
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  defaultValue={form.fechaDenuncia}
                  value={form.fechaDenuncia}
                  renderInput={(props) => <TextField {...props} />}
                  slotProps={{ textField: { size: "small", error: false } }}
                  onChange={onChange}
                  disabled
                />
              </LocalizationProvider>
            )}
          />
        </Box>
      </FormControl>
    </div>
  );
}
