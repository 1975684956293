import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Provincias, Localidades } from "./locations";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { fetchValidacionDomicilio, validarDomicilio , setCalle , setAltura, setPiso,setDepto, setProvincia, setLocalidad, setCodPostal, setObservaciones } from "../userSlice";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import "./styles.scss";
export default function CurrentAddress() {
  const dispatch = useDispatch();
  const [openModal, setOpen] = useState(false);
  const [openErrorModal, setErrorOpen] = useState(false);

  const domicilio = useSelector((state) => state.usuario.domicilio);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      calle: domicilio?.calle,
      altura: domicilio?.altura,
      piso:domicilio?.piso,
      depto:domicilio?.depto,
      provincia: Provincias.find((x) => x.id === domicilio?.provincia?.id),
      localidad: Localidades.find((x) => x.id === domicilio?.localidad?.id && x.idProvincia === domicilio?.provincia?.id),
      codPostal: domicilio?.codPostal,
      observaciones: domicilio?.observaciones,
    },
  });

  const autocompleteRef = React.useRef();

  const handleClose = () => setOpen(false);

  const handleModificarDatos = () => {
    setOpen(false);
    setErrorOpen(false);
    dispatch(validarDomicilio(false));
  };

  const handleConfirmDatos = () => {
    setOpen(false);
    dispatch(validarDomicilio(true));
  };

  const validar = async (formData) => {
    dispatch(fetchValidacionDomicilio(formData))
      .then((response) => {
        if (!response.payload.validado) {
          setOpen(true);
        }
      })
      .catch((res) => {
        setOpen(true);
      });
  };

  const handleOnChange = (e, bool) => {
    const regex = /^[0-9]+$/;
    if (bool) {
      if (e.target.value === "" || regex.test(e.target.value)) {
        if (domicilio?.validado) {
          dispatch(validarDomicilio(false));
        }
        return true;
      } else {
        alert("Solo se permiten numeros");
        return false;
      }
    }
  };

  const handleOnChangeAlpha = (e, bool) => {
    const regex = /^[a-zA-Z0-9]+$/;
    if (bool) {
      if (e.target.value === "" || regex.test(e.target.value)) {
        if (domicilio.validado) {
          dispatch(validarDomicilio(false));
        }
        return true;
      } else {
        alert("Solo se permiten numeros");
        return false;
      }
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    p: 4,
  };

  return (
    <React.Fragment>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            className="text-center"
            variant="h6"
            component="h2"
          >
            Error al validar tu dirección
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Typography  variant="p">
            El sistema no pudo validar el domicilio.{" "}
              <b>
                {domicilio?.calle} {domicilio?.altura}.
              </b>
              <br />
            </Typography>
            <Typography variant="p">
            ¿Querés modificar los datos?
            </Typography>
            <Box
              sx={{ display: "flex", flexDirection: "column" }}
              className="my-2 gap-3"
            >
              <Button
                size="small"
                variant="outlined"
                onClick={handleConfirmDatos}
              >
                No, quiero continuar con el domicilio ingresado.
              </Button>
              <Button
                size="small"
                color="success"
                variant="outlined"
                onClick={handleModificarDatos}
              >
                Si, quiero modificar los datos.
              </Button>
            </Box>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openErrorModal}
        onClose={() => setErrorOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            className="text-center"
            variant="h6"
            component="h2"
          >
            Error al validar tu dirección
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Typography variant="p" >
            El sistema no pudo validar el domicilio.{" "}
              <b>
                {domicilio?.calle} {domicilio?.altura}.
              </b>
              <br />
            </Typography>
            <Box
              sx={{ display: "flex", flexDirection: "column" }}
              className="my-2 gap-3"
            >
              <Button
                size="small"
                color="success"
                variant="outlined"
                onClick={handleModificarDatos}
              >
                Si, quiero modificar los datos.
              </Button>
            </Box>
          </Typography>
        </Box>
      </Modal>

      <Container sx={{ width: 0.6, py: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <FormControl fullWidth sx={{ pb: 2 }}>
            <label
              htmlFor="calle"
              style={{ color: "#5836ff", fontWeight: "bold", paddingLeft: 3 }}
            >
              Calle
            </label>
            <Controller
              name="calle"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Calle"
                  size="small"
                  onChange={(e) => {
                    dispatch(validarDomicilio(false));
                    field.onChange(e);
                    dispatch(setCalle(e.target.value))
                  }}
                />
              )}
            />
            {(errors.calle || !domicilio?.calle) && (
              <Typography variant="label" className="text-red-500 mt-1">Campo requerido</Typography>
            )}
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }} className="flex-col sm:flex-row">
            <FormControl className="w-full sm:w-[40%]">
              <label
                htmlFor="numero"
                style={{ color: "#5836ff", fontWeight: "bold", paddingLeft: 3 }}
              >
                Número
              </label>
              <Controller
                name="altura"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Número"
                    size="small"
                    onChange={(e) => {
                      if (handleOnChangeAlpha(e, true)) {
                        if(e.target.value.length > 10){
                          return
                        }else {
                          handleOnChangeAlpha(e, true);
                          field.onChange(e);
                          dispatch(setAltura(e.target.value))
                        }
                      }
                    }}
                  />
                )}
              />
              {(errors.altura || !domicilio?.altura) && (
                <Typography variant="label" className="text-red-500 mt-1">Campo requerido</Typography>
              )}
            </FormControl>
            <FormControl className="w-full sm:w-[25%]">
              <label
                htmlFor="piso"
                style={{ color: "#5836ff", fontWeight: "bold", paddingLeft: 3 }}
              >
                Piso
              </label>
              <Controller
                name="piso"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Piso"
                    onChange={(e) => {
                      if (handleOnChangeAlpha(e, true)) {
                        if(e.target.value.length > 10){
                          return
                        }else {
                          handleOnChangeAlpha(e, true);
                          field.onChange(e);
                          dispatch(setPiso(e.target.value))
                        }
                      }
                    }}
                    size="small"
                  />
                )}
              />
            </FormControl>
            <FormControl className="w-full sm:w-[25%]">
              <label
                htmlFor="depto"
                style={{ color: "#5836ff", fontWeight: "bold", paddingLeft: 3 }}
              >
                Depto.
              </label>
              <Controller
                name="depto"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder="Depto"
                    onChange={(e) => {
                      if (handleOnChangeAlpha(e, true)) {
                        if(e.target.value.length > 10){
                          return
                        }else {
                          handleOnChangeAlpha(e, true);
                          field.onChange(e);
                          dispatch(setDepto(e.target.value))
                        }
                      }
                    }}
                    size="small"
                  />
                )}
              />
            </FormControl>
          </Box>
          <FormControl fullWidth sx={{ pb: 2 }}>
            <label
              htmlFor="provincia"
              style={{ color: "#5836ff", fontWeight: "bold", paddingLeft: 3 }}
            >
              Provincia
            </label>
            <Controller
              name="provincia"
              control={control}
              
              render={({ field: { onChange } }) => (
                <Autocomplete
                  freeSolo
                  size="small"
                  options={Provincias}
                  defaultValue={
                    Provincias.find((x) => x.id === domicilio?.provincia?.id) ||
                    null
                  }
                  getOptionLabel={(option) => option.descripcion}
                  onChange={(e, values) => {
                    dispatch(validarDomicilio(false));
                    onChange(values);
                    dispatch(setProvincia(values))
                    dispatch(setLocalidad(null))
                    const ele = autocompleteRef.current.getElementsByClassName(
                      "MuiAutocomplete-clearIndicator"
                    )[0];
                    if (ele) ele.click();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: false }}
                    />
                  )}
                />
              )}
            />
          </FormControl>
          <Box className="d-flex gap-2 flex-column flex-lg-row">
            <FormControl fullWidth sx={{ pb: 2 }}>
              <label
                htmlFor="localidad"
                style={{ color: "#5836ff", fontWeight: "bold", paddingLeft: 3 }}
              >
                Localidad
              </label>
              <Controller
                name="localidad"
                control={control}
                render={({ field: { onChange} }) => (
                  <Autocomplete
                    ref={autocompleteRef}
                    freeSolo
                    disabled={!watch("provincia")}
                    size="small"
                    defaultValue={
                      Localidades.find(
                        (x) => x.id === domicilio?.localidad?.id && x.idProvincia === domicilio?.provincia?.id
                      ) || null
                    }
                    options={Localidades.filter(
                      (x) => x.idProvincia === watch("provincia")?.id
                    )}
                    getOptionLabel={(option) => option.descripcion}
                    onChange={(e, values) => {
                      dispatch(validarDomicilio(false));
                      onChange(values);
                      dispatch(setLocalidad(values))
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: false }}
                      />
                    )}
                  />
                )}
              />
            </FormControl>
            <FormControl className="pb-4 postal">
              <label
                htmlFor="codPostal"
                style={{ color: "#5836ff", fontWeight: "bold", paddingLeft: 3 }}
              >
                Código Postal
              </label>
              <Controller
                name="codPostal"
                control={control}
                rules={{ required: true, pattern: /^[0-9]+$/ }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    onChange={(e) => {
                      if (handleOnChange(e, true)) {
                        if(e.target.value.length > 6){
                          return
                        }else {
                          handleOnChange(e, true);
                          field.onChange(e);
                          dispatch(setCodPostal(e.target.value))
                        }
                      }
                    }}
                  />
                )}
              />
                            {(errors.codPostal || !domicilio?.codPostal) && (
                <Typography variant="label" className="text-red-500 mt-1">Campo requerido</Typography>
              )}
            </FormControl>
          </Box>
          <FormControl fullWidth>
            <label
              htmlFor="observaciones"
              style={{ color: "#5836ff", fontWeight: "bold", paddingLeft: 3 }}
            >
              Observaciones
            </label>
            <Controller
              name="observaciones"
              control={control}
              render={({ field: { onChange, value } }) => (
                <textarea
                  multiline
                  rows={5}
                  required
                  id="observaciones"
                  size="small"
                  value={value}
                  className="p-2 border-2 rounded-md"
                  placeholder="Máximo 250 caracteres"
                  onChange={(e) => {
                    if (e.target.value.length > 250) {
                      // Trunca el valor a 250 caracteres
                      const truncatedText = e.target.value.slice(0, 250);
                      onChange(truncatedText);
                      dispatch(setObservaciones(truncatedText))
                    } else {
                      onChange(e.target.value);
                      dispatch(setObservaciones(e.target.value))
                    }
                  }}
                />
              )}
            />
          </FormControl>
          <FormControl
            sx={{ alignSelf: "center", width: 0.5, marginTop: 1, gap: "10px" }}
          >
            {domicilio.validado === true ? (
              <Button
                size="small"
                color="success"
                variant="text"
                endIcon={<CheckIcon />}
              >
                Dirección validada
              </Button>
            ) : domicilio?.validado === false ? (
              <Button
                size="small"
                color="error"
                variant="text"
                endIcon={<CloseIcon />}
              >
                Dirección no verificada
              </Button>
            ) : null}
            <Button
              size="small"
              disabled={
                domicilio.validado === true ||
                !watch("localidad") ||
                !watch("altura") ||
                !watch("codPostal") ||
                !watch("calle")
              }
              onClick={handleSubmit(validar)}
              color="success"
              variant="contained"
            >
              Validar dirección
            </Button>
          </FormControl>
        </Box>
      </Container>
    </React.Fragment>
  );
}
