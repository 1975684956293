import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import AssistantMenu from "../../assistant-menu";
import NotificationMenu from "../../notifications-menu";
import { reset } from "../userSlice";
import Profile from "../profile";
import ChangeProfile from "../../modals/change-profile";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ListItem, ListItemText } from "@mui/material";
import ConfirmModal from "../../modals/confirm-modal";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  handleProfileModal,
  handlechangeProfileModal,
} from "../../modals/modalSlice";
import { persistor } from "../../../app/store.js";
import { Button } from "@mui/material";
import robot from "../../../assets/img/robot.png";
import "./styles.scss";
export default function AccountMenu({onClickNotification}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { perfilActual } = useSelector((state) => state.usuario);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usuario = useSelector((state) => state.usuario);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCancel = () => {
    // dispatch(reset());
    // persistor.purge();
    // navigate("/login");
    document.forms["cancel"].submit();
  };

  function generateRandomState() {
    const randomBytes = new Uint8Array(32);
    window.crypto.getRandomValues(randomBytes);
    return btoa(String.fromCharCode.apply(null, randomBytes));
  }
  
  // Uso de la función para obtener el valor state
  const stateValue = generateRandomState();
  const url = `${process.env.REACT_APP_DOMAIN_OAUTH}/authorize?client_id=${process.env.REACT_APP_CLIENTID_OAUTH}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=openid&state=${stateValue}`;

  const location = useLocation();

  return (
    <>
        <form id="cancel" action={url} method="post">
      <input type="hidden" name="authorize" value="0" />
      <input type="hidden" name="logout" value="1" />
    </form>
      <Box className="d-flex align-items-center justify-content-center gap-3 justify-content-md-end">
        {perfilActual.idPerfil === "EMPLEADO" && (
          <Tooltip className="hideMobile" title="Publicaciones">
            <Link to="/foco">
              <Button
                color={location.pathname === "/foco" ? "secondary" : "primary"}
                className="hover:text-[#00D6D1] w-[100px]"
                style={{fontSize:"16px"}}
              >
                FOCO RH
              </Button>
            </Link>
          </Tooltip>
        )}
        {perfilActual.idPerfil !== "EMPLEADO" ? null : (
          <Tooltip title="Chat-Bot" className="hideMobile">
            <a
              href="https://asichatbot.buenosaires.gob.ar/autogestion_licencias"
              target="_blank"
              rel="noreferrer"
              className="icon-link w-[30px] h-[30px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M6.1999 8.69026V8.60002M10.9999 8.69026V8.60002M15.7999 8.69026V8.60002M10.6869 15.5913L5.67816 20.6V15.5913H3.7999C2.47442 15.5913 1.3999 14.5168 1.3999 13.1913V3.80003C1.3999 2.47454 2.47442 1.40002 3.7999 1.40002H18.1999C19.5254 1.40002 20.5999 2.47454 20.5999 3.80002V13.1913C20.5999 14.5168 19.5254 15.5913 18.1999 15.5913H10.6869Z"
                  stroke="#5836ff"
                  //stroke="#00D6D1"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </Tooltip>
        )}

        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <>
            {perfilActual.idPerfil === "ADM_NOVEDADES_RH" ? null : (
              <>
                <AssistantMenu />
                {
                  perfilActual.idPerfil === "EMPLEADO" && (
                    <NotificationMenu onClickNotification={onClickNotification}/>
                  )
                }
              </>
            )}
          </>
          <Tooltip title="Opciones de usuario">
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar sx={{ width: 34, height: 34, bgcolor:"#00D6D1" , fontSize:"14px", fontWeight:900 }}>
                {usuario.datos.nombre?.charAt(0)}
                {usuario.datos.apellido?.charAt(0)}
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ListItem key="datos" sx={{ color: "#16c2a2" }}>
          <PermIdentityIcon />
          <ListItemText
            primary={
              usuario.datos.nombre == undefined
                ? ""
                : usuario.datos.nombre.concat(" ", usuario.datos.apellido)
            }
            sx={{ textAlign: "center" }}
            variant="label"
          />
        </ListItem>
        <MenuItem>
          <ListItemText
            secondary={usuario.perfilActual.nombrePerfil}
            sx={{ textAlign: "center" }}
            variant="label"
          />
        </MenuItem>
        <Divider />
        <MenuItem
          key="edit"
          onClick={() => dispatch(handleProfileModal({ open: true }))}
        >
          <ListItemIcon>
            <ModeEditOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="label">Editar datos personales</Typography>
        </MenuItem>
        <Divider />

        <MenuItem
          key="change-profile"
          onClick={() => dispatch(handlechangeProfileModal({ open: true }))}
        >
          <ListItemIcon>
            <ReplayOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="label">Cambiar perfil</Typography>
        </MenuItem>
        <Divider />
        <MenuItem key="logout" onClick={handleCancel}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Typography variant="label">Cerrar sesión</Typography>
        </MenuItem>
      </Menu>
      <Profile />
      <ChangeProfile />
      <ConfirmModal />
    </>
  );
}
