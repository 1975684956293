/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl } from "@mui/base";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchDiagnosticosAsync, selectLicenses } from "../licenseSlice";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormLabel,
  Typography,
} from "@mui/material";
import {
  deleteDomicilioAsync,
  fetchValidacionDomicilioExtra,
  saveDomicilioAsync,
  selectDatosPersonales,
} from "../../user/userSlice";
import { Localidades, Provincias } from "../../user/registration/locations";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Address from "../../user/address";
import { handleConfirmModal, selectModals } from "../../modals/modalSlice";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CustomAlert from "../../alert";

export default function DomicilioReposo({ control, setValue }) {
  const { data, form } = useSelector(selectLicenses);
  const { confirmModal } = useSelector(selectModals);
  const { domicilio, otrosDomicilios, datos } = useSelector(
    selectDatosPersonales
  );
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState({ show: false, data: "" });
  const addressForm = useForm({
    defaultValues: {
      Telefono:{
        AreaCode: domicilio.telefono.areaCode,
        PhoneNumber: domicilio.telefono.phoneNumber,
        CountryCode: domicilio.telefono.countryCode
      }
    },
    rules: {
      codPostal: {
        required: true,
        pattern: /^[0-9]{1,4}$/,
      },
      observaciones: {
        required: true,
      },
      localidad: {
        required: true,
      },
    },
  });
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (!data.diagnosticos) {
      dispatch(fetchDiagnosticosAsync(""));
    }
  }, []);

  const getProvincia = (id) => {
    const result = Provincias.find((x) => x.id === id);
    return result.descripcion;
  };

  const getLocalidad = (id, idProvincia) => {
    const result = Localidades.find(
      (x) => x.id === id && x.idProvincia === idProvincia
    );
    return result?result.descripcion:"Sin dato";
  };

  /*
        <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            className="text-center"
            variant="h6"
            component="h2"
          >
            Error al validar tu dirección
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Typography  variant="p">
            El sistema no pudo validar el domicilio.{" "}
              <b>
                {domicilio?.calle} {domicilio?.altura}.
              </b>
              <br />
            </Typography>
            <Typography variant="p">
            ¿Querés modificar los datos?
            </Typography>
            <Box
              sx={{ display: "flex", flexDirection: "column" }}
              className="my-2 gap-3"
            >
              <Button
                size="small"
                variant="outlined"
                onClick={handleConfirmDatos}
              >
                No, quiero continuar con el domicilio ingresado.
              </Button>
              <Button
                size="small"
                color="success"
                variant="outlined"
                onClick={handleModificarDatos}
              >
                Si, quiero modificar los datos.
              </Button>
            </Box>
          </Typography>
        </Box>
      </Modal>
  */

  const saveDomicilio = (data) => {
    dispatch(handleConfirmModal({ accepted:false }))
    dispatch(fetchValidacionDomicilioExtra(data)).then((response) => {
      if ((!response.payload.validado && !confirmModal.accepted) || response.payload.status === "ZERO_RESULTS") {
        dispatch(
          handleConfirmModal({
            open: true,
            title: `Error al validar tu dirección`,
            content: (
              <div>
                El sistema no pudo validar el domicilio. {data.calle} {data.altura}. <br /> ¿Querés modificar los datos?
              </div>
              ),
            showCancel: true,
            domicilio:true
          })
        );
      } else {
        const usuarioId = datos.id;
        data = { ...data, usuarioId };
        dispatch(saveDomicilioAsync(data)).then((response) => {
          if (response.payload.success) setOpen(false);
          else {
            setOpen(false);
            setError({ show: true, data: response.payload.message });
          }
        });
      }
    });
  };
  const [openDeleteDomicilio, setOpenDeleteDomicilio] = React.useState({
    show: false,
    data: null,
  });
  const handleDeleteDomicilio = () => {
    dispatch(deleteDomicilioAsync(openDeleteDomicilio.data)).then(
      (_response) => {
        if (form.domicilio === openDeleteDomicilio.data.direccionId) {
          setValue("domicilio", null, { shouldTouch: true });
        }
        setOpenDeleteDomicilio({ show: false });
      }
    );
  };

  const domicilios = () => {
    let validateOtrosDom = otrosDomicilios?.length > 0 && otrosDomicilios?.find(x=>x.direccionId)
    if (validateOtrosDom) {
      const combinado = [domicilio].concat(otrosDomicilios);
      return combinado;
    } else return [domicilio];
  };

  return (
    <>
      <FormControl id="input-diagnostico">
        <p className="text-[#5836ff] font-bold mt-2 text-center">
          Domicilio de reposo
        </p>
        <Typography>
          Para solicitar la licencia médica, debés informar un domicilio
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Controller
          name="domicilio"
          control={control}
          render={({ field }) =>
            domicilios()?.map((dm, i) => (
              <FormControlLabel
                key={`domicilio-${i}`}
                className="flex flex-col lg:flex-row mx-auto"
                control={
                  <div
                    className="flex flex-col min-w-[70px] max-w-[70px] mx-auto"
                    style={{ alignSelf: "stretch" }}
                  >
                    <Checkbox
                      {...field}
                      style={{
                        marginRight:
                          domicilio.direccionId === dm.direccionId
                            ? ""
                            : "",
                      }}
                      value={dm} // Asigna el objeto completo a value
                      checked={field.value === dm.direccionId} // Comprueba si el objeto completo coincide con el valor actual
                      onChange={(e) => {
                        if (e.target.checked) {
                          field.onChange(dm.direccionId);
                        } else {
                          field.onChange(null);
                        }
                      }} // Actualiza el valor solo si el checkbox está marcado
                    />
                    <Button
                      hidden={domicilio.direccionId === dm.direccionId}
                      onClick={() =>
                        setOpenDeleteDomicilio({ show: true, data: dm })
                      }
                    >
                      <DeleteForeverIcon sx={{ color: "#ff3d8b" }} />
                    </Button>
                  </div>
                }
                label={
                  <>
                    <Container>
                      <Box
                        pb={3}
                        className="flex flex-col lg:grid grid-cols-2 xxs:w-[180px] mx-auto xs:w-[220px] sm:w-[300px] lg:w-[330px] gap-3 border p-2 rounded-md shadow-md"
                      >
                        <div className="flex flex-col">
                          <FormLabel sx={{ color: "#5836ff", fontWeight: 600 }}>
                            Calle:
                          </FormLabel>
                          <Typography variant="p">
                            {dm.calle} - {dm.altura}
                          </Typography>
                        </div>
                        <div className="flex flex-col">
                          <FormLabel sx={{ color: "#5836ff", fontWeight: 600 }}>
                            Numero:
                          </FormLabel>
                          <Typography variant="p">{dm.altura}</Typography>
                        </div>
                        <div className="flex flex-col">
                          <FormLabel sx={{ color: "#5836ff", fontWeight: 600 }}>
                            Piso:
                          </FormLabel>
                          <Typography variant="p">{dm.piso ?? "-"}</Typography>
                        </div>
                        <div className="flex flex-col">
                          <FormLabel sx={{ color: "#5836ff", fontWeight: 600 }}>
                            Depto:
                          </FormLabel>
                          <Typography variant="p">{dm.depto ?? "-"}</Typography>
                        </div>
                        <div className="flex flex-col">
                          <FormLabel sx={{ color: "#5836ff", fontWeight: 600 }}>
                            CP:
                          </FormLabel>
                          <Typography variant="p">{dm.codPostal}</Typography>
                        </div>
                        <div className="flex flex-col">
                          <FormLabel sx={{ color: "#5836ff", fontWeight: 600 }}>
                            Provincia:
                          </FormLabel>
                          <Typography variant="p">
                            {getProvincia(dm.provincia.id)}
                          </Typography>
                        </div>
                        <div className="flex flex-col">
                          <FormLabel sx={{ color: "#5836ff", fontWeight: 600 }}>
                            Localidad:
                          </FormLabel>
                          <Typography variant="p">
                            {getLocalidad(dm.localidad.id, dm.provincia.id)}
                          </Typography>
                        </div>
                      </Box>
                      {domicilio.direccionId === dm.direccionId && (
                        <p className="text-[#5836ff] font-bold mt-2 text-center">
                          Si el domicilio donde guardarás reposo es diferente al que declaraste en la registración de MIA, ingresá uno nuevo.
                        </p>
                      )}
                    </Container>

                    <Divider sx={{ my: 2 }} />
                  </>
                }
              />
            ))
          }
        />
      </FormControl>
      <div>
        <Button fullWidth onClick={handleClickOpen}>
          Agregar domicilio
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Address form={addressForm}></Address>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={addressForm.handleSubmit((x) => saveDomicilio(x))}
              autoFocus
            >
              Guardar cambios
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDeleteDomicilio.show}
          onClose={() => setOpenDeleteDomicilio({ show: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography>¿Seguro que desea eliminar el domicilio?</Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={() => setOpenDeleteDomicilio({ show: false })}>
              Cancelar
            </Button>
            <Button onClick={handleDeleteDomicilio}>Eliminar</Button>
          </DialogActions>
        </Dialog>
        <CustomAlert
          error={error}
          onClose={() => setError({ show: false })}
          severity="error"
        ></CustomAlert>
      </div>
    </>
  );
}
