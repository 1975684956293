import {
  Box,
  Button,
  Typography
} from "@mui/material";

import { Add } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import MenuMobileHeader from "../../header/menu-mobile/index"
import { useSelector } from 'react-redux';
import { selectDatosPersonales } from '../../user/userSlice';

export default function _Menu({ title }) {

  const usuario = useSelector(selectDatosPersonales);

  return (
    <Box className="licenseMenu">
      <div className="title-btn" style={{width:'100%'}}>
        <div style={{display:"flow"}}>
          <MenuMobileHeader
            perfil={usuario.perfilActual.idPerfil}
          />
          <Box component="span" className="licenseTitle">
            <Typography variant="h5" className="h5">
              {title}
            </Typography>
          </Box>
        </div>
        <Box className="licenseButtons" gap={1}>
          <Link to="/foco/create">
            <Button startIcon={<Add />} variant="contained">
              Nueva Publicación
            </Button>
          </Link>
      </Box>
    </div>
    </Box>
  );
}
