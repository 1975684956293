export const Provincias = [
    { id : "BAI", descripcion : 'Buenos Aires' },
    { id : "CAT", descripcion : 'Catamarca' },
    { id : "CBA", descripcion : 'Córdoba' },
    { id : "CFE", descripcion : 'Ciudad Autónoma de Buenos Aires' },
    { id : "CHA", descripcion : 'Chaco' },
    { id : "CHU", descripcion : 'Chubut' },
    { id : "COR", descripcion : 'Corrientes' },
    { id : "ERI", descripcion : 'Entre Ríos' },
    { id : "FOR", descripcion : 'Formosa' },
    { id : "JUJ", descripcion : 'Jujuy' },
    { id : "LPA", descripcion : 'La Pampa' },
    { id : "LRI", descripcion : 'La Rioja' },
    { id : "MEN", descripcion : 'Mendoza' },
    { id : "MIS", descripcion : 'Misiones' },
    { id : "NEU", descripcion : 'Neuquen' },
    { id : "RNE", descripcion : 'Río Negro' },
    { id : "SAL", descripcion : 'Salta' },
    { id : "SCR", descripcion : 'Santa Cruz' },
    { id : "SDE", descripcion : 'Santiago del Estero' },
    { id : "SFE", descripcion : 'Santa Fe' },
    { id : "SJU", descripcion : 'San Juan' },
    { id : "SLU", descripcion : 'San Luis' },
    { id : "TDF", descripcion : 'Tierra del Fuego' },
    { id : "TUC", descripcion : 'Tucuman' },
    { id : "X0 ", descripcion : 'NO INFORMADO' }
]

export const Localidades = [
{idProvincia: "BAI", id:"0",  descripcion:"SIN INFORMACION"},
{idProvincia: "BAI", id:"1",  descripcion:"ADOLFO ALSINA"},
{idProvincia: "BAI", id:"10", descripcion:"BALCARCE"},
{idProvincia: "BAI", id:"100",descripcion:"PUNTA INDIO"},
{idProvincia: "BAI", id:"101",descripcion:"QUILMES"},
{idProvincia: "BAI", id:"102",descripcion:"RAMALLO"},
{idProvincia: "BAI", id:"103",descripcion:"RAUCH"},
{idProvincia: "BAI", id:"104",descripcion:"RIVADAVIA"},
{idProvincia: "BAI", id:"105",descripcion:"ROJAS"},
{idProvincia: "BAI", id:"106",descripcion:"ROQUE PEREZ"},
{idProvincia: "BAI", id:"107",descripcion:"SAAVEDRA"},
{idProvincia: "BAI", id:"108",descripcion:"SALADILLO"},
{idProvincia: "BAI", id:"109",descripcion:"SALLIQUELO"},
{idProvincia: "BAI", id:"11", descripcion:"BARADERO"},
{idProvincia: "BAI", id:"110",descripcion:"SALTO"},
{idProvincia: "BAI", id:"111",descripcion:"SAN ANDRES DE GILES"},
{idProvincia: "BAI", id:"112",descripcion:"SAN ANTONIO DE ARECO"},
{idProvincia: "BAI", id:"113",descripcion:"SAN CAYETANO"},
{idProvincia: "BAI", id:"114",descripcion:"SAN FERNANDO"},
{idProvincia: "BAI", id:"115",descripcion:"SAN ISIDRO"},
{idProvincia: "BAI", id:"116",descripcion:"SAN MIGUEL"},
{idProvincia: "BAI", id:"117",descripcion:"SAN NICOLAS DE LOS ARROYOS"},
{idProvincia: "BAI", id:"118",descripcion:"SAN PEDRO"},
{idProvincia: "BAI", id:"119",descripcion:"SAN VICENTE"},
{idProvincia: "BAI", id:"12", descripcion:"BENITO JUAREZ"},
{idProvincia: "BAI", id:"120",descripcion:"SUIPACHA"},
{idProvincia: "BAI", id:"121",descripcion:"TANDIL"},
{idProvincia: "BAI", id:"122",descripcion:"TAPALQUE"},
{idProvincia: "BAI", id:"123",descripcion:"TIGRE"},
{idProvincia: "BAI", id:"124",descripcion:"TORDILLO"},
{idProvincia: "BAI", id:"125",descripcion:"TORNQUIST"},
{idProvincia: "BAI", id:"126",descripcion:"TRENQUE LAUQUEN"},
{idProvincia: "BAI", id:"127",descripcion:"TRES ARROYOS"},
{idProvincia: "BAI", id:"128",descripcion:"TRES DE FEBRERO"},
{idProvincia: "BAI", id:"129",descripcion:"TRES LOMAS"},
{idProvincia: "BAI", id:"13", descripcion:"BERAZATEGUI"},
{idProvincia: "BAI", id:"130",descripcion:"VEINTICINCO DE MAYO"},
{idProvincia: "BAI", id:"131",descripcion:"VICENTE LOPEZ"},
{idProvincia: "BAI", id:"132",descripcion:"VILLA GESELL"},
{idProvincia: "BAI", id:"133",descripcion:"VILLARINO"},
{idProvincia: "BAI", id:"134",descripcion:"ZARATE"},
{idProvincia: "BAI", id:"14", descripcion:"BERISSO"},
{idProvincia: "BAI", id:"15", descripcion:"BOLIVAR"},
{idProvincia: "BAI", id:"16", descripcion:"BRAGADO"},
{idProvincia: "BAI", id:"17", descripcion:"CAMPANA"},
{idProvincia: "BAI", id:"18", descripcion:"CAÑUELAS"},
{idProvincia: "BAI", id:"19", descripcion:"CAPITAN SARMIENTO"},
{idProvincia: "BAI", id:"2",  descripcion:"ADOLFO GONZALES CHAVES"},
{idProvincia: "BAI", id:"20", descripcion:"CARLOS CASARES"},
{idProvincia: "BAI", id:"21", descripcion:"CARLOS TEJEDOR"},
{idProvincia: "BAI", id:"22", descripcion:"CARMEN DE ARECO"},
{idProvincia: "BAI", id:"23", descripcion:"CASTELLI"},
{idProvincia: "BAI", id:"24", descripcion:"CHACABUCO"},
{idProvincia: "BAI", id:"25", descripcion:"CHASCOMUS"},
{idProvincia: "BAI", id:"26", descripcion:"CHIVILCOY"},
{idProvincia: "BAI", id:"27", descripcion:"COLON"},
{idProvincia: "BAI", id:"28", descripcion:"CORONEL BRANDSEN"},
{idProvincia: "BAI", id:"29", descripcion:"CNEL DE MARINA LEONARDO ROSALES"},
{idProvincia: "BAI", id:"3",  descripcion:"ALBERTI"},
{idProvincia: "BAI", id:"30", descripcion:"CORONEL DORREGO"},
{idProvincia: "BAI", id:"31", descripcion:"CORONEL PRINGLES"},
{idProvincia: "BAI", id:"32", descripcion:"CORONEL SUAREZ"},
{idProvincia: "BAI", id:"33", descripcion:"DAIREAUX"},
{idProvincia: "BAI", id:"34", descripcion:"DOLORES"},
{idProvincia: "BAI", id:"35", descripcion:"ENSENADA"},
{idProvincia: "BAI", id:"36", descripcion:"ESCOBAR"},
{idProvincia: "BAI", id:"37", descripcion:"ESTEBAN ECHEVERRIA"},
{idProvincia: "BAI", id:"38", descripcion:"EXALTACION DE LA CRUZ"},
{idProvincia: "BAI", id:"39", descripcion:"EZEIZA"},
{idProvincia: "BAI", id:"4",  descripcion:"ALMIRANTE BROWN"},
{idProvincia: "BAI", id:"40", descripcion:"FLORENCIO VARELA"},
{idProvincia: "BAI", id:"41", descripcion:"FLORENTINO AMEGHINO"},
{idProvincia: "BAI", id:"42", descripcion:"GENERAL ALVARADO"},
{idProvincia: "BAI", id:"43", descripcion:"GENERAL ALVEAR"},
{idProvincia: "BAI", id:"44", descripcion:"GENERAL ARENALES"},
{idProvincia: "BAI", id:"45", descripcion:"GENERAL BELGRANO"},
{idProvincia: "BAI", id:"46", descripcion:"GENERAL GUIDO"},
{idProvincia: "BAI", id:"47", descripcion:"GENERAL JUAN MADARIAGA"},
{idProvincia: "BAI", id:"48", descripcion:"GENERAL LAMADRID"},
{idProvincia: "BAI", id:"49", descripcion:"GENERAL LAS HERAS"},
{idProvincia: "BAI", id:"5",  descripcion:"ARRECIFES"},
{idProvincia: "BAI", id:"50", descripcion:"GENERAL LAVALLE"},
{idProvincia: "BAI", id:"51", descripcion:"GENERAL PAZ"},
{idProvincia: "BAI", id:"52", descripcion:"GENERAL PINTO"},
{idProvincia: "BAI", id:"53", descripcion:"GENERAL PUEYRREDON"},
{idProvincia: "BAI", id:"54", descripcion:"GENERAL RODRIGUEZ"},
{idProvincia: "BAI", id:"55", descripcion:"GENERAL SAN MARTIN"},
{idProvincia: "BAI", id:"56", descripcion:"GENERAL VIAMONTE"},
{idProvincia: "BAI", id:"57", descripcion:"GENERAL VILLEGAS"},
{idProvincia: "BAI", id:"58", descripcion:"GUAMINI"},
{idProvincia: "BAI", id:"59", descripcion:"HIPOLITO YRIGOYEN"},
{idProvincia: "BAI", id:"6",  descripcion:"AVELLANEDA"},
{idProvincia: "BAI", id:"60", descripcion:"HURLINGHAM"},
{idProvincia: "BAI", id:"61", descripcion:"ITUZAINGO"},
{idProvincia: "BAI", id:"62", descripcion:"JOSE C. PAZ"},
{idProvincia: "BAI", id:"63", descripcion:"JUNIN"},
{idProvincia: "BAI", id:"64", descripcion:"LA COSTA"},
{idProvincia: "BAI", id:"65", descripcion:"LA MATANZA"},
{idProvincia: "BAI", id:"66", descripcion:"LA PLATA"},
{idProvincia: "BAI", id:"67", descripcion:"LANUS"},
{idProvincia: "BAI", id:"68", descripcion:"LAPRIDA"},
{idProvincia: "BAI", id:"69", descripcion:"LAS FLORES"},
{idProvincia: "BAI", id:"7",  descripcion:"AYACUCHO"},
{idProvincia: "BAI", id:"70", descripcion:"LEANDRO N. ALEM"},
{idProvincia: "BAI", id:"71", descripcion:"LINCOLN"},
{idProvincia: "BAI", id:"72", descripcion:"LOBERIA"},
{idProvincia: "BAI", id:"73", descripcion:"LOBOS"},
{idProvincia: "BAI", id:"74", descripcion:"LOMAS DE ZAMORA"},
{idProvincia: "BAI", id:"75", descripcion:"LUJAN"},
{idProvincia: "BAI", id:"76", descripcion:"MAGDALENA"},
{idProvincia: "BAI", id:"77", descripcion:"MAIPU"},
{idProvincia: "BAI", id:"78", descripcion:"MALVINAS ARGENTINAS"},
{idProvincia: "BAI", id:"79", descripcion:"MAR CHIQUITA"},
{idProvincia: "BAI", id:"8",  descripcion:"AZUL"},
{idProvincia: "BAI", id:"80", descripcion:"MARCOS PAZ"},
{idProvincia: "BAI", id:"81", descripcion:"MERCEDES"},
{idProvincia: "BAI", id:"82", descripcion:"MERLO"},
{idProvincia: "BAI", id:"83", descripcion:"MONTE"},
{idProvincia: "BAI", id:"84", descripcion:"MONTE HERMOSO"},
{idProvincia: "BAI", id:"85", descripcion:"MORENO"},
{idProvincia: "BAI", id:"86", descripcion:"MORON"},
{idProvincia: "BAI", id:"87", descripcion:"NAVARRO"},
{idProvincia: "BAI", id:"88", descripcion:"NECOCHEA"},
{idProvincia: "BAI", id:"89", descripcion:"NUEVE DE JULIO"},
{idProvincia: "BAI", id:"9",  descripcion:"BAHIA BLANCA"},
{idProvincia: "BAI", id:"90", descripcion:"OLAVARRIA"},
{idProvincia: "BAI", id:"91", descripcion:"PATAGONES"},
{idProvincia: "BAI", id:"92", descripcion:"PEHUAJO"},
{idProvincia: "BAI", id:"93", descripcion:"PELLEGRINI"},
{idProvincia: "BAI", id:"94", descripcion:"PERGAMINO"},
{idProvincia: "BAI", id:"95", descripcion:"PILA"},
{idProvincia: "BAI", id:"96", descripcion:"PILAR"},
{idProvincia: "BAI", id:"97", descripcion:"PINAMAR"},
{idProvincia: "BAI", id:"98", descripcion:"PRESIDENTE PERON"},
{idProvincia: "BAI", id:"99", descripcion:"PUAN"},
{idProvincia: "BAI", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "CAT", id:"001",descripcion:"Aconquija"},
{idProvincia: "CAT", id:"002",descripcion:"Ancasti"},
{idProvincia: "CAT", id:"003",descripcion:"Andalgalá"},
{idProvincia: "CAT", id:"004",descripcion:"Antofagasta de la Sierra"},
{idProvincia: "CAT", id:"005",descripcion:"Belén"},
{idProvincia: "CAT", id:"006",descripcion:"Capayán"},
{idProvincia: "CAT", id:"007",descripcion:"Corral Quemado"},
{idProvincia: "CAT", id:"008",descripcion:"El Alto"},
{idProvincia: "CAT", id:"009",descripcion:"El Rodeo"},
{idProvincia: "CAT", id:"010",descripcion:"Fiambalá"},
{idProvincia: "CAT", id:"011",descripcion:"Fray Mamerto Esquiú"},
{idProvincia: "CAT", id:"012",descripcion:"Hualfín"},
{idProvincia: "CAT", id:"013",descripcion:"Huillapima"},
{idProvincia: "CAT", id:"014",descripcion:"Icaño"},
{idProvincia: "CAT", id:"015",descripcion:"La Puerta"},
{idProvincia: "CAT", id:"016",descripcion:"Las Juntas"},
{idProvincia: "CAT", id:"017",descripcion:"Londres"},
{idProvincia: "CAT", id:"018",descripcion:"Los Altos"},
{idProvincia: "CAT", id:"019",descripcion:"Los Varelas"},
{idProvincia: "CAT", id:"020",descripcion:"Mutquín"},
{idProvincia: "CAT", id:"021",descripcion:"Paclín"},
{idProvincia: "CAT", id:"022",descripcion:"Pomán"},
{idProvincia: "CAT", id:"023",descripcion:"Pozo de la Piedra"},
{idProvincia: "CAT", id:"024",descripcion:"Puerta de Corral Quemado"},
{idProvincia: "CAT", id:"025",descripcion:"Puerta de San José"},
{idProvincia: "CAT", id:"026",descripcion:"Recreo"},
{idProvincia: "CAT", id:"027",descripcion:"San Fernando"},
{idProvincia: "CAT", id:"028",descripcion:"San Fernando del Valle de Catamarc"},
{idProvincia: "CAT", id:"029",descripcion:"San José"},
{idProvincia: "CAT", id:"030",descripcion:"Santa María"},
{idProvincia: "CAT", id:"031",descripcion:"Santa Rosa"},
{idProvincia: "CAT", id:"032",descripcion:"Saujil"},
{idProvincia: "CAT", id:"033",descripcion:"Tapso"},
{idProvincia: "CAT", id:"034",descripcion:"Tinogasta"},
{idProvincia: "CAT", id:"035",descripcion:"Valle Viejo"},
{idProvincia: "CAT", id:"036",descripcion:"Villa Vil"},
{idProvincia: "CAT", id:"DES",descripcion:"Desconocido"},
{idProvincia: "CAT", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "CBA", id:"001",descripcion:"Achiras"},
{idProvincia: "CBA", id:"002",descripcion:"Adelia María"},
{idProvincia: "CBA", id:"003",descripcion:"Agua de Oro"},
{idProvincia: "CBA", id:"004",descripcion:"Alcira Gigena"},
{idProvincia: "CBA", id:"005",descripcion:"Alejandro Roca"},
{idProvincia: "CBA", id:"006",descripcion:"Alejo Ledesma"},
{idProvincia: "CBA", id:"007",descripcion:"Alicia"},
{idProvincia: "CBA", id:"008",descripcion:"Almafuerte"},
{idProvincia: "CBA", id:"009",descripcion:"Alpa Corral"},
{idProvincia: "CBA", id:"010",descripcion:"Alta Gracia"},
{idProvincia: "CBA", id:"011",descripcion:"Alto Alegre"},
{idProvincia: "CBA", id:"012",descripcion:"Altos del Chipión"},
{idProvincia: "CBA", id:"013",descripcion:"Arias"},
{idProvincia: "CBA", id:"014",descripcion:"Arroyito"},
{idProvincia: "CBA", id:"015",descripcion:"Arroyo Algodón"},
{idProvincia: "CBA", id:"016",descripcion:"Arroyo Cabral"},
{idProvincia: "CBA", id:"017",descripcion:"Ausonia"},
{idProvincia: "CBA", id:"018",descripcion:"Ballesteros"},
{idProvincia: "CBA", id:"019",descripcion:"Ballesteros Sud"},
{idProvincia: "CBA", id:"020",descripcion:"Balnearia"},
{idProvincia: "CBA", id:"021",descripcion:"Bell Ville"},
{idProvincia: "CBA", id:"022",descripcion:"Bengolea"},
{idProvincia: "CBA", id:"023",descripcion:"Benjamín Gould"},
{idProvincia: "CBA", id:"024",descripcion:"Berrotarán"},
{idProvincia: "CBA", id:"025",descripcion:"Bialet Massé"},
{idProvincia: "CBA", id:"026",descripcion:"Brinkmann"},
{idProvincia: "CBA", id:"027",descripcion:"Buchardo"},
{idProvincia: "CBA", id:"028",descripcion:"Bulnes"},
{idProvincia: "CBA", id:"029",descripcion:"Calchín"},
{idProvincia: "CBA", id:"030",descripcion:"Calchín Oeste"},
{idProvincia: "CBA", id:"031",descripcion:"Camilo Aldao"},
{idProvincia: "CBA", id:"032",descripcion:"Canals"},
{idProvincia: "CBA", id:"033",descripcion:"Cañada de Luque"},
{idProvincia: "CBA", id:"034",descripcion:"Capilla del Carmen"},
{idProvincia: "CBA", id:"035",descripcion:"Capilla del Monte"},
{idProvincia: "CBA", id:"036",descripcion:"Capitán General Bernardo O'Higgins"},
{idProvincia: "CBA", id:"037",descripcion:"Carnerillo"},
{idProvincia: "CBA", id:"038",descripcion:"Carrilobo"},
{idProvincia: "CBA", id:"039",descripcion:"Cavanagh"},
{idProvincia: "CBA", id:"040",descripcion:"Chaján"},
{idProvincia: "CBA", id:"041",descripcion:"Charras"},
{idProvincia: "CBA", id:"042",descripcion:"Chazón"},
{idProvincia: "CBA", id:"043",descripcion:"Chilibroste"},
{idProvincia: "CBA", id:"044",descripcion:"Cintra"},
{idProvincia: "CBA", id:"045",descripcion:"Colazo"},
{idProvincia: "CBA", id:"046",descripcion:"Colonia Almada"},
{idProvincia: "CBA", id:"047",descripcion:"Colonia Bismarck"},
{idProvincia: "CBA", id:"048",descripcion:"Colonia Caroya"},
{idProvincia: "CBA", id:"049",descripcion:"Colonia Italiana"},
{idProvincia: "CBA", id:"050",descripcion:"Colonia Marina"},
{idProvincia: "CBA", id:"051",descripcion:"Colonia Prosperidad"},
{idProvincia: "CBA", id:"052",descripcion:"Colonia San Bartolomé"},
{idProvincia: "CBA", id:"053",descripcion:"Colonia Tirolesa"},
{idProvincia: "CBA", id:"054",descripcion:"Colonia Vignaud"},
{idProvincia: "CBA", id:"055",descripcion:"Córdoba"},
{idProvincia: "CBA", id:"056",descripcion:"Coronel Baigorria"},
{idProvincia: "CBA", id:"057",descripcion:"Coronel Moldes"},
{idProvincia: "CBA", id:"058",descripcion:"Corral de Bustos"},
{idProvincia: "CBA", id:"059",descripcion:"Corralito"},
{idProvincia: "CBA", id:"060",descripcion:"Cosquín"},
{idProvincia: "CBA", id:"061",descripcion:"Costa Sacate"},
{idProvincia: "CBA", id:"062",descripcion:"Cruz Alta"},
{idProvincia: "CBA", id:"063",descripcion:"Cruz del Eje"},
{idProvincia: "CBA", id:"064",descripcion:"Dalmacio Vélez Sársfield"},
{idProvincia: "CBA", id:"065",descripcion:"Deán Funes"},
{idProvincia: "CBA", id:"066",descripcion:"Del Campillo"},
{idProvincia: "CBA", id:"067",descripcion:"Despeñaderos"},
{idProvincia: "CBA", id:"068",descripcion:"Devoto"},
{idProvincia: "CBA", id:"069",descripcion:"El Arañado"},
{idProvincia: "CBA", id:"070",descripcion:"El Fortín"},
{idProvincia: "CBA", id:"071",descripcion:"El Tío"},
{idProvincia: "CBA", id:"072",descripcion:"Elena"},
{idProvincia: "CBA", id:"073",descripcion:"Embalse"},
{idProvincia: "CBA", id:"074",descripcion:"Estación Juárez Celman"},
{idProvincia: "CBA", id:"075",descripcion:"Etruria"},
{idProvincia: "CBA", id:"076",descripcion:"Freyre"},
{idProvincia: "CBA", id:"077",descripcion:"General Baldissera"},
{idProvincia: "CBA", id:"078",descripcion:"General Cabrera"},
{idProvincia: "CBA", id:"079",descripcion:"General Deheza"},
{idProvincia: "CBA", id:"080",descripcion:"General Levalle"},
{idProvincia: "CBA", id:"081",descripcion:"General Roca"},
{idProvincia: "CBA", id:"082",descripcion:"Guatimozín"},
{idProvincia: "CBA", id:"083",descripcion:"Hernando"},
{idProvincia: "CBA", id:"084",descripcion:"Huanchillas"},
{idProvincia: "CBA", id:"085",descripcion:"Huerta Grande"},
{idProvincia: "CBA", id:"086",descripcion:"Huinca Renancó"},
{idProvincia: "CBA", id:"087",descripcion:"Idiazábal"},
{idProvincia: "CBA", id:"088",descripcion:"Inriville"},
{idProvincia: "CBA", id:"089",descripcion:"Isla Verde"},
{idProvincia: "CBA", id:"090",descripcion:"Italó"},
{idProvincia: "CBA", id:"091",descripcion:"James Craik"},
{idProvincia: "CBA", id:"092",descripcion:"Jesús María"},
{idProvincia: "CBA", id:"093",descripcion:"Jovita"},
{idProvincia: "CBA", id:"094",descripcion:"Justiniano Posse"},
{idProvincia: "CBA", id:"095",descripcion:"La Calera"},
{idProvincia: "CBA", id:"096",descripcion:"La Carlota"},
{idProvincia: "CBA", id:"097",descripcion:"La Cautiva"},
{idProvincia: "CBA", id:"098",descripcion:"La Cesira"},
{idProvincia: "CBA", id:"099",descripcion:"La Cruz"},
{idProvincia: "CBA", id:"100",descripcion:"La Falda"},
{idProvincia: "CBA", id:"101",descripcion:"La Francia"},
{idProvincia: "CBA", id:"102",descripcion:"La Granja"},
{idProvincia: "CBA", id:"103",descripcion:"La Laguna"},
{idProvincia: "CBA", id:"104",descripcion:"La Palestina"},
{idProvincia: "CBA", id:"105",descripcion:"La Paquita"},
{idProvincia: "CBA", id:"106",descripcion:"La Para"},
{idProvincia: "CBA", id:"107",descripcion:"La Paz"},
{idProvincia: "CBA", id:"108",descripcion:"La Playosa"},
{idProvincia: "CBA", id:"109",descripcion:"La Puerta"},
{idProvincia: "CBA", id:"110",descripcion:"La Tordilla"},
{idProvincia: "CBA", id:"111",descripcion:"Laborde"},
{idProvincia: "CBA", id:"112",descripcion:"Laboulaye"},
{idProvincia: "CBA", id:"113",descripcion:"Laguna Larga"},
{idProvincia: "CBA", id:"114",descripcion:"Las Acequias"},
{idProvincia: "CBA", id:"115",descripcion:"Las Arrias"},
{idProvincia: "CBA", id:"116",descripcion:"Las Higueras"},
{idProvincia: "CBA", id:"117",descripcion:"Las Junturas"},
{idProvincia: "CBA", id:"118",descripcion:"Las Peñas"},
{idProvincia: "CBA", id:"119",descripcion:"Las Perdices"},
{idProvincia: "CBA", id:"120",descripcion:"Las Varas"},
{idProvincia: "CBA", id:"121",descripcion:"Las Varillas"},
{idProvincia: "CBA", id:"122",descripcion:"Las Vertientes"},
{idProvincia: "CBA", id:"123",descripcion:"Leones"},
{idProvincia: "CBA", id:"124",descripcion:"Los Cisnes"},
{idProvincia: "CBA", id:"125",descripcion:"Los Cocos"},
{idProvincia: "CBA", id:"126",descripcion:"Los Cóndores"},
{idProvincia: "CBA", id:"127",descripcion:"Los Surgentes"},
{idProvincia: "CBA", id:"128",descripcion:"Los Zorros"},
{idProvincia: "CBA", id:"129",descripcion:"Lozada"},
{idProvincia: "CBA", id:"130",descripcion:"Luca"},
{idProvincia: "CBA", id:"131",descripcion:"Lucio V. Mansilla"},
{idProvincia: "CBA", id:"132",descripcion:"Luque"},
{idProvincia: "CBA", id:"133",descripcion:"Malagueño"},
{idProvincia: "CBA", id:"134",descripcion:"Malvinas Argentinas"},
{idProvincia: "CBA", id:"135",descripcion:"Manfredi"},
{idProvincia: "CBA", id:"136",descripcion:"Marcos Juárez"},
{idProvincia: "CBA", id:"137",descripcion:"Marull"},
{idProvincia: "CBA", id:"138",descripcion:"Matorrales"},
{idProvincia: "CBA", id:"139",descripcion:"Mattaldi"},
{idProvincia: "CBA", id:"140",descripcion:"Melo"},
{idProvincia: "CBA", id:"141",descripcion:"Mendiolaza"},
{idProvincia: "CBA", id:"142",descripcion:"Mina Clavero"},
{idProvincia: "CBA", id:"143",descripcion:"Miramar"},
{idProvincia: "CBA", id:"144",descripcion:"Monte Buey"},
{idProvincia: "CBA", id:"145",descripcion:"Monte Cristo"},
{idProvincia: "CBA", id:"146",descripcion:"Monte de los Gauchos"},
{idProvincia: "CBA", id:"147",descripcion:"Monte Leña"},
{idProvincia: "CBA", id:"148",descripcion:"Monte Maíz"},
{idProvincia: "CBA", id:"149",descripcion:"Monte Ralo"},
{idProvincia: "CBA", id:"150",descripcion:"Morrison"},
{idProvincia: "CBA", id:"151",descripcion:"Morteros"},
{idProvincia: "CBA", id:"152",descripcion:"Noetinger"},
{idProvincia: "CBA", id:"153",descripcion:"Nono"},
{idProvincia: "CBA", id:"154",descripcion:"Obispo Trejo"},
{idProvincia: "CBA", id:"155",descripcion:"Olaeta"},
{idProvincia: "CBA", id:"156",descripcion:"Oliva"},
{idProvincia: "CBA", id:"157",descripcion:"Oncativo"},
{idProvincia: "CBA", id:"158",descripcion:"Ordóñez"},
{idProvincia: "CBA", id:"159",descripcion:"Pampayasta Sud"},
{idProvincia: "CBA", id:"160",descripcion:"Pascanas"},
{idProvincia: "CBA", id:"161",descripcion:"Pasco"},
{idProvincia: "CBA", id:"162",descripcion:"Pilar"},
{idProvincia: "CBA", id:"163",descripcion:"Piquilín"},
{idProvincia: "CBA", id:"164",descripcion:"Porteña"},
{idProvincia: "CBA", id:"165",descripcion:"Pozo del Molle"},
{idProvincia: "CBA", id:"166",descripcion:"Pueblo Italiano"},
{idProvincia: "CBA", id:"167",descripcion:"Quebracho Herrado"},
{idProvincia: "CBA", id:"168",descripcion:"Quilino"},
{idProvincia: "CBA", id:"169",descripcion:"Reducción"},
{idProvincia: "CBA", id:"170",descripcion:"Río Ceballos"},
{idProvincia: "CBA", id:"171",descripcion:"Río Cuarto"},
{idProvincia: "CBA", id:"172",descripcion:"Río de los Sauces"},
{idProvincia: "CBA", id:"173",descripcion:"Río Primero"},
{idProvincia: "CBA", id:"174",descripcion:"Río Segundo"},
{idProvincia: "CBA", id:"175",descripcion:"Río Tercero"},
{idProvincia: "CBA", id:"176",descripcion:"Rosales"},
{idProvincia: "CBA", id:"177",descripcion:"Sacanta"},
{idProvincia: "CBA", id:"178",descripcion:"Saira"},
{idProvincia: "CBA", id:"179",descripcion:"Saldán"},
{idProvincia: "CBA", id:"180",descripcion:"Salsacate"},
{idProvincia: "CBA", id:"181",descripcion:"Salsipuedes"},
{idProvincia: "CBA", id:"182",descripcion:"Sampacho"},
{idProvincia: "CBA", id:"183",descripcion:"San Agustín"},
{idProvincia: "CBA", id:"184",descripcion:"San Antonio de Litín"},
{idProvincia: "CBA", id:"185",descripcion:"San Basilio"},
{idProvincia: "CBA", id:"186",descripcion:"San Carlos Minas"},
{idProvincia: "CBA", id:"187",descripcion:"San Esteban"},
{idProvincia: "CBA", id:"188",descripcion:"San Francisco"},
{idProvincia: "CBA", id:"189",descripcion:"San Francisco del Chañar"},
{idProvincia: "CBA", id:"190",descripcion:"San Javier y Yacanto"},
{idProvincia: "CBA", id:"191",descripcion:"San José"},
{idProvincia: "CBA", id:"192",descripcion:"San José de la Dormida"},
{idProvincia: "CBA", id:"193",descripcion:"San José de las Salinas"},
{idProvincia: "CBA", id:"194",descripcion:"San Marcos Sierras"},
{idProvincia: "CBA", id:"195",descripcion:"San Marcos Sud"},
{idProvincia: "CBA", id:"196",descripcion:"San Pedro"},
{idProvincia: "CBA", id:"197",descripcion:"San Pedro Norte"},
{idProvincia: "CBA", id:"198",descripcion:"Santa Catalina"},
{idProvincia: "CBA", id:"199",descripcion:"Santa Eufemia"},
{idProvincia: "CBA", id:"200",descripcion:"Santa María"},
{idProvincia: "CBA", id:"201",descripcion:"Santa Rosa de Calamuchita"},
{idProvincia: "CBA", id:"202",descripcion:"Santa Rosa de Río Primero"},
{idProvincia: "CBA", id:"203",descripcion:"Santiago Temple"},
{idProvincia: "CBA", id:"204",descripcion:"Sarmiento"},
{idProvincia: "CBA", id:"205",descripcion:"Saturnino María Laspiur"},
{idProvincia: "CBA", id:"206",descripcion:"Sebastián Elcano"},
{idProvincia: "CBA", id:"207",descripcion:"Seeber"},
{idProvincia: "CBA", id:"208",descripcion:"Serrano"},
{idProvincia: "CBA", id:"209",descripcion:"Serrezuela"},
{idProvincia: "CBA", id:"210",descripcion:"Silvio Pellico"},
{idProvincia: "CBA", id:"211",descripcion:"Sinsacate"},
{idProvincia: "CBA", id:"212",descripcion:"Tancacha"},
{idProvincia: "CBA", id:"213",descripcion:"Tanti"},
{idProvincia: "CBA", id:"214",descripcion:"Ticino"},
{idProvincia: "CBA", id:"215",descripcion:"Tío Pujio"},
{idProvincia: "CBA", id:"216",descripcion:"Toledo"},
{idProvincia: "CBA", id:"217",descripcion:"Tosquita"},
{idProvincia: "CBA", id:"218",descripcion:"Tránsito"},
{idProvincia: "CBA", id:"219",descripcion:"Ucacha"},
{idProvincia: "CBA", id:"220",descripcion:"Unquillo"},
{idProvincia: "CBA", id:"221",descripcion:"Valle Hermoso"},
{idProvincia: "CBA", id:"222",descripcion:"Viamonte"},
{idProvincia: "CBA", id:"223",descripcion:"Vicuña Mackenna"},
{idProvincia: "CBA", id:"224",descripcion:"Villa Allende"},
{idProvincia: "CBA", id:"225",descripcion:"Villa Ascasubi"},
{idProvincia: "CBA", id:"226",descripcion:"Villa Carlos Paz"},
{idProvincia: "CBA", id:"227",descripcion:"Villa Concepción del Tío"},
{idProvincia: "CBA", id:"228",descripcion:"Villa Cura Brochero"},
{idProvincia: "CBA", id:"229",descripcion:"Villa de Las Rosas"},
{idProvincia: "CBA", id:"230",descripcion:"Villa de María"},
{idProvincia: "CBA", id:"231",descripcion:"Villa de Soto"},
{idProvincia: "CBA", id:"232",descripcion:"Villa del Dique"},
{idProvincia: "CBA", id:"233",descripcion:"Villa del Rosario"},
{idProvincia: "CBA", id:"234",descripcion:"Villa del Totoral"},
{idProvincia: "CBA", id:"235",descripcion:"Villa Dolores"},
{idProvincia: "CBA", id:"236",descripcion:"Villa Fontana"},
{idProvincia: "CBA", id:"237",descripcion:"Villa General Belgrano"},
{idProvincia: "CBA", id:"238",descripcion:"Villa Giardino"},
{idProvincia: "CBA", id:"239",descripcion:"Villa Huidobro"},
{idProvincia: "CBA", id:"240",descripcion:"Villa María"},
{idProvincia: "CBA", id:"241",descripcion:"Villa Nueva"},
{idProvincia: "CBA", id:"242",descripcion:"Villa Rossi"},
{idProvincia: "CBA", id:"243",descripcion:"Villa Rumipal"},
{idProvincia: "CBA", id:"244",descripcion:"Villa Sarmiento (San Alberto)"},
{idProvincia: "CBA", id:"245",descripcion:"Villa Tulumba"},
{idProvincia: "CBA", id:"246",descripcion:"Villa Valeria"},
{idProvincia: "CBA", id:"247",descripcion:"Wenceslao Escalante"},
{idProvincia: "CBA", id:"248",descripcion:"Yacanto de Calamuchita"},
{idProvincia: "CBA", id:"DES",descripcion:"Desconocido"},
{idProvincia: "CBA", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "CFE", id:"DES",descripcion:"Ciudad Autónoma de Buenos Aires"},
{idProvincia: "CHA", id:"001",descripcion:"Barranqueras"},
{idProvincia: "CHA", id:"002",descripcion:"Avia Terai"},
{idProvincia: "CHA", id:"003",descripcion:"Basail"},
{idProvincia: "CHA", id:"004",descripcion:"Campo Largo"},
{idProvincia: "CHA", id:"005",descripcion:"Capitán Solari"},
{idProvincia: "CHA", id:"006",descripcion:"Charadai"},
{idProvincia: "CHA", id:"007",descripcion:"Charata"},
{idProvincia: "CHA", id:"008",descripcion:"Chorotis"},
{idProvincia: "CHA", id:"009",descripcion:"Ciervo Petiso"},
{idProvincia: "CHA", id:"010",descripcion:"Colonia Benítez"},
{idProvincia: "CHA", id:"011",descripcion:"Colonia Elisa"},
{idProvincia: "CHA", id:"012",descripcion:"Colonia Popular"},
{idProvincia: "CHA", id:"013",descripcion:"Colonias Unidas"},
{idProvincia: "CHA", id:"014",descripcion:"Concepción del Bermejo"},
{idProvincia: "CHA", id:"015",descripcion:"Coronel Du Graty"},
{idProvincia: "CHA", id:"016",descripcion:"Corzuela"},
{idProvincia: "CHA", id:"017",descripcion:"Cote Lai"},
{idProvincia: "CHA", id:"018",descripcion:"El Sauzalito"},
{idProvincia: "CHA", id:"019",descripcion:"Enrique Urién"},
{idProvincia: "CHA", id:"020",descripcion:"Fontana"},
{idProvincia: "CHA", id:"021",descripcion:"Fuerte Esperanza"},
{idProvincia: "CHA", id:"022",descripcion:"Gancedo"},
{idProvincia: "CHA", id:"023",descripcion:"General Capdevila"},
{idProvincia: "CHA", id:"024",descripcion:"General José de San Martín"},
{idProvincia: "CHA", id:"025",descripcion:"General Pinedo"},
{idProvincia: "CHA", id:"026",descripcion:"General Vedia"},
{idProvincia: "CHA", id:"027",descripcion:"Hermoso Campo"},
{idProvincia: "CHA", id:"028",descripcion:"Isla del Cerrito"},
{idProvincia: "CHA", id:"029",descripcion:"Juan José Castelli"},
{idProvincia: "CHA", id:"030",descripcion:"La Clotilde"},
{idProvincia: "CHA", id:"031",descripcion:"La Eduvigis"},
{idProvincia: "CHA", id:"032",descripcion:"La Escondida"},
{idProvincia: "CHA", id:"033",descripcion:"La Leonesa"},
{idProvincia: "CHA", id:"034",descripcion:"La Tigra"},
{idProvincia: "CHA", id:"035",descripcion:"La Verde"},
{idProvincia: "CHA", id:"036",descripcion:"Laguna Blanca"},
{idProvincia: "CHA", id:"037",descripcion:"Laguna Limpia"},
{idProvincia: "CHA", id:"038",descripcion:"Lapachito"},
{idProvincia: "CHA", id:"039",descripcion:"Las Breñas"},
{idProvincia: "CHA", id:"040",descripcion:"Las Garcitas"},
{idProvincia: "CHA", id:"041",descripcion:"Las Palmas"},
{idProvincia: "CHA", id:"042",descripcion:"Los Frentones"},
{idProvincia: "CHA", id:"043",descripcion:"Machagai"},
{idProvincia: "CHA", id:"044",descripcion:"Makallé"},
{idProvincia: "CHA", id:"045",descripcion:"Margarita Belén"},
{idProvincia: "CHA", id:"046",descripcion:"Miraflores"},
{idProvincia: "CHA", id:"047",descripcion:"Misión Nueva Pompeya"},
{idProvincia: "CHA", id:"048",descripcion:"Napenay"},
{idProvincia: "CHA", id:"049",descripcion:"Pampa Almirón"},
{idProvincia: "CHA", id:"050",descripcion:"Pampa del Indio"},
{idProvincia: "CHA", id:"051",descripcion:"Pampa del Infierno"},
{idProvincia: "CHA", id:"052",descripcion:"Presidencia de la Plaza"},
{idProvincia: "CHA", id:"053",descripcion:"Presidencia Roca"},
{idProvincia: "CHA", id:"054",descripcion:"Presidencia Roque Sáenz Peña"},
{idProvincia: "CHA", id:"055",descripcion:"Puerto Bermejo"},
{idProvincia: "CHA", id:"056",descripcion:"Puerto Eva Perón"},
{idProvincia: "CHA", id:"057",descripcion:"Puerto Tirol"},
{idProvincia: "CHA", id:"058",descripcion:"Puerto Vilelas"},
{idProvincia: "CHA", id:"059",descripcion:"Quitilipi"},
{idProvincia: "CHA", id:"060",descripcion:"Resistencia"},
{idProvincia: "CHA", id:"061",descripcion:"Samuhú"},
{idProvincia: "CHA", id:"062",descripcion:"San Bernardo"},
{idProvincia: "CHA", id:"063",descripcion:"Santa Sylvina"},
{idProvincia: "CHA", id:"064",descripcion:"Taco Pozo"},
{idProvincia: "CHA", id:"065",descripcion:"Tres Isletas"},
{idProvincia: "CHA", id:"066",descripcion:"Villa Ángela"},
{idProvincia: "CHA", id:"067",descripcion:"Villa Berthet"},
{idProvincia: "CHA", id:"068",descripcion:"Villa Río Bermejito"},
{idProvincia: "CHA", id:"DES",descripcion:"Desconocido"},
{idProvincia: "CHA", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "CHU", id:"001",descripcion:"Comodoro Rivadavia"},
{idProvincia: "CHU", id:"002",descripcion:"Alto Río Senguer"},
{idProvincia: "CHU", id:"003",descripcion:"Camarones"},
{idProvincia: "CHU", id:"004",descripcion:"Cholila"},
{idProvincia: "CHU", id:"005",descripcion:"Corcovado"},
{idProvincia: "CHU", id:"006",descripcion:"Dolavon"},
{idProvincia: "CHU", id:"007",descripcion:"El Hoyo"},
{idProvincia: "CHU", id:"008",descripcion:"El Maitén"},
{idProvincia: "CHU", id:"009",descripcion:"Epuyén"},
{idProvincia: "CHU", id:"010",descripcion:"Esquel"},
{idProvincia: "CHU", id:"011",descripcion:"Gaiman"},
{idProvincia: "CHU", id:"012",descripcion:"Gobernador Costa"},
{idProvincia: "CHU", id:"013",descripcion:"José de San Martín"},
{idProvincia: "CHU", id:"014",descripcion:"Lago Puelo"},
{idProvincia: "CHU", id:"015",descripcion:"Puerto Madryn"},
{idProvincia: "CHU", id:"016",descripcion:"Rada Tilly"},
{idProvincia: "CHU", id:"017",descripcion:"Rawson"},
{idProvincia: "CHU", id:"018",descripcion:"Río Mayo"},
{idProvincia: "CHU", id:"019",descripcion:"Río Pico"},
{idProvincia: "CHU", id:"020",descripcion:"Sarmiento"},
{idProvincia: "CHU", id:"021",descripcion:"Tecka"},
{idProvincia: "CHU", id:"022",descripcion:"Trelew"},
{idProvincia: "CHU", id:"023",descripcion:"Trevelin"},
{idProvincia: "CHU", id:"DES",descripcion:"Desconocido"},
{idProvincia: "CHU", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "COR", id:"001",descripcion:"Alvear"},
{idProvincia: "COR", id:"002",descripcion:"Bella Vista"},
{idProvincia: "COR", id:"003",descripcion:"Berón de Astrada"},
{idProvincia: "COR", id:"004",descripcion:"Bonpland"},
{idProvincia: "COR", id:"005",descripcion:"Caá Catí"},
{idProvincia: "COR", id:"006",descripcion:"Chavarría"},
{idProvincia: "COR", id:"007",descripcion:"Colonia Carlos Pellegrini"},
{idProvincia: "COR", id:"008",descripcion:"Colonia Libertad"},
{idProvincia: "COR", id:"009",descripcion:"Colonia Liebig's"},
{idProvincia: "COR", id:"010",descripcion:"Colonia Santa Rosa"},
{idProvincia: "COR", id:"011",descripcion:"Concepción"},
{idProvincia: "COR", id:"012",descripcion:"Corrientes"},
{idProvincia: "COR", id:"013",descripcion:"Cruz de los Milagros"},
{idProvincia: "COR", id:"014",descripcion:"Curuzú Cuatiá"},
{idProvincia: "COR", id:"015",descripcion:"Empedrado"},
{idProvincia: "COR", id:"016",descripcion:"Esquina"},
{idProvincia: "COR", id:"017",descripcion:"Estación Torrent"},
{idProvincia: "COR", id:"018",descripcion:"Felipe Yofre"},
{idProvincia: "COR", id:"019",descripcion:"Garruchos"},
{idProvincia: "COR", id:"020",descripcion:"Gobernador Agrónomo Valentín Virasoro"},
{idProvincia: "COR", id:"021",descripcion:"Gobernador Martínez"},
{idProvincia: "COR", id:"022",descripcion:"Goya"},
{idProvincia: "COR", id:"023",descripcion:"Guaviraví"},
{idProvincia: "COR", id:"024",descripcion:"Herlitzka"},
{idProvincia: "COR", id:"025",descripcion:"Itá Ibaté"},
{idProvincia: "COR", id:"026",descripcion:"Itatí"},
{idProvincia: "COR", id:"027",descripcion:"Ituzaingó"},
{idProvincia: "COR", id:"028",descripcion:"José Rafael Gómez"},
{idProvincia: "COR", id:"029",descripcion:"Juan Pujol"},
{idProvincia: "COR", id:"030",descripcion:"La Cruz"},
{idProvincia: "COR", id:"031",descripcion:"Lavalle"},
{idProvincia: "COR", id:"032",descripcion:"Lomas de Vallejos"},
{idProvincia: "COR", id:"033",descripcion:"Loreto"},
{idProvincia: "COR", id:"034",descripcion:"Mariano I. Loza"},
{idProvincia: "COR", id:"035",descripcion:"Mburucuyá"},
{idProvincia: "COR", id:"036",descripcion:"Mercedes"},
{idProvincia: "COR", id:"037",descripcion:"Mocoretá"},
{idProvincia: "COR", id:"038",descripcion:"Monte Caseros"},
{idProvincia: "COR", id:"039",descripcion:"Nueve de Julio"},
{idProvincia: "COR", id:"040",descripcion:"Palmar Grande"},
{idProvincia: "COR", id:"041",descripcion:"Parada Pucheta"},
{idProvincia: "COR", id:"042",descripcion:"Paso de la Patria"},
{idProvincia: "COR", id:"043",descripcion:"Paso de los Libres"},
{idProvincia: "COR", id:"044",descripcion:"Pedro R. Fernández"},
{idProvincia: "COR", id:"045",descripcion:"Perugorría"},
{idProvincia: "COR", id:"046",descripcion:"Pueblo Libertador"},
{idProvincia: "COR", id:"047",descripcion:"Ramada Paso"},
{idProvincia: "COR", id:"048",descripcion:"Riachuelo"},
{idProvincia: "COR", id:"049",descripcion:"Saladas"},
{idProvincia: "COR", id:"050",descripcion:"San Antonio de Apipé"},
{idProvincia: "COR", id:"051",descripcion:"San Carlos"},
{idProvincia: "COR", id:"052",descripcion:"San Cosme"},
{idProvincia: "COR", id:"053",descripcion:"San Lorenzo"},
{idProvincia: "COR", id:"054",descripcion:"San Luis del Palmar"},
{idProvincia: "COR", id:"055",descripcion:"San Miguel"},
{idProvincia: "COR", id:"056",descripcion:"San Roque"},
{idProvincia: "COR", id:"057",descripcion:"Santa Ana de los Guácaras"},
{idProvincia: "COR", id:"058",descripcion:"Santa Lucía"},
{idProvincia: "COR", id:"059",descripcion:"Santo Tomé"},
{idProvincia: "COR", id:"060",descripcion:"Sauce"},
{idProvincia: "COR", id:"061",descripcion:"Tabay"},
{idProvincia: "COR", id:"062",descripcion:"Tabepicuá"},
{idProvincia: "COR", id:"063",descripcion:"Tatacuá"},
{idProvincia: "COR", id:"064",descripcion:"Villa Olivari"},
{idProvincia: "COR", id:"065",descripcion:"Yapeyú"},
{idProvincia: "COR", id:"066",descripcion:"Yataytí Calle"},
{idProvincia: "COR", id:"DES",descripcion:"Desconocido"},
{idProvincia: "COR", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "ERI", id:"001",descripcion:"Aldea San Antonio"},
{idProvincia: "ERI", id:"002",descripcion:"Aldea Valle María"},
{idProvincia: "ERI", id:"003",descripcion:"Alejandro Roca"},
{idProvincia: "ERI", id:"004",descripcion:"Aranguren"},
{idProvincia: "ERI", id:"005",descripcion:"Basavilbaso"},
{idProvincia: "ERI", id:"006",descripcion:"Bovril"},
{idProvincia: "ERI", id:"007",descripcion:"Caseros"},
{idProvincia: "ERI", id:"008",descripcion:"Ceibas"},
{idProvincia: "ERI", id:"009",descripcion:"Cerrito"},
{idProvincia: "ERI", id:"010",descripcion:"Chajarí"},
{idProvincia: "ERI", id:"011",descripcion:"Colón"},
{idProvincia: "ERI", id:"012",descripcion:"Colonia Avellaneda"},
{idProvincia: "ERI", id:"013",descripcion:"Colonia Ayuí"},
{idProvincia: "ERI", id:"014",descripcion:"Concepción del Uruguay"},
{idProvincia: "ERI", id:"015",descripcion:"Concordia"},
{idProvincia: "ERI", id:"016",descripcion:"Conscripto Bernardi"},
{idProvincia: "ERI", id:"017",descripcion:"Crespo"},
{idProvincia: "ERI", id:"018",descripcion:"Diamante"},
{idProvincia: "ERI", id:"019",descripcion:"Estancia Grande"},
{idProvincia: "ERI", id:"020",descripcion:"Federación"},
{idProvincia: "ERI", id:"021",descripcion:"Federal"},
{idProvincia: "ERI", id:"022",descripcion:"General Campos"},
{idProvincia: "ERI", id:"023",descripcion:"General Galarza"},
{idProvincia: "ERI", id:"024",descripcion:"General Ramírez"},
{idProvincia: "ERI", id:"025",descripcion:"Gilbert"},
{idProvincia: "ERI", id:"026",descripcion:"Gobernador Mansilla"},
{idProvincia: "ERI", id:"027",descripcion:"Gualeguay"},
{idProvincia: "ERI", id:"028",descripcion:"Gualeguaychú"},
{idProvincia: "ERI", id:"029",descripcion:"Hasenkamp"},
{idProvincia: "ERI", id:"030",descripcion:"Hernandarias"},
{idProvincia: "ERI", id:"031",descripcion:"Hernández"},
{idProvincia: "ERI", id:"032",descripcion:"Herrera"},
{idProvincia: "ERI", id:"033",descripcion:"Ibicuy"},
{idProvincia: "ERI", id:"034",descripcion:"La Criolla"},
{idProvincia: "ERI", id:"035",descripcion:"La Paz"},
{idProvincia: "ERI", id:"036",descripcion:"Larroque"},
{idProvincia: "ERI", id:"037",descripcion:"Libertador San Martín"},
{idProvincia: "ERI", id:"038",descripcion:"Los Charrúas"},
{idProvincia: "ERI", id:"039",descripcion:"Los Conquistadores"},
{idProvincia: "ERI", id:"040",descripcion:"Lucas González"},
{idProvincia: "ERI", id:"041",descripcion:"Maciá"},
{idProvincia: "ERI", id:"042",descripcion:"María Grande"},
{idProvincia: "ERI", id:"043",descripcion:"Nogoyá"},
{idProvincia: "ERI", id:"044",descripcion:"Oro Verde"},
{idProvincia: "ERI", id:"045",descripcion:"Paraná"},
{idProvincia: "ERI", id:"046",descripcion:"Piedras Blancas"},
{idProvincia: "ERI", id:"047",descripcion:"Primero de Mayo"},
{idProvincia: "ERI", id:"048",descripcion:"Pronunciamiento"},
{idProvincia: "ERI", id:"049",descripcion:"Pueblo General Belgrano"},
{idProvincia: "ERI", id:"050",descripcion:"Puerto Yeruá"},
{idProvincia: "ERI", id:"051",descripcion:"Rosario del Tala"},
{idProvincia: "ERI", id:"052",descripcion:"San Benito"},
{idProvincia: "ERI", id:"053",descripcion:"San Gustavo"},
{idProvincia: "ERI", id:"054",descripcion:"San Jaime de la Frontera"},
{idProvincia: "ERI", id:"055",descripcion:"San José"},
{idProvincia: "ERI", id:"056",descripcion:"San José de Feliciano"},
{idProvincia: "ERI", id:"057",descripcion:"San Justo"},
{idProvincia: "ERI", id:"058",descripcion:"San Salvador"},
{idProvincia: "ERI", id:"059",descripcion:"Santa Ana"},
{idProvincia: "ERI", id:"060",descripcion:"Santa Anita"},
{idProvincia: "ERI", id:"061",descripcion:"Santa Elena"},
{idProvincia: "ERI", id:"062",descripcion:"Sauce de Luna"},
{idProvincia: "ERI", id:"063",descripcion:"Seguí"},
{idProvincia: "ERI", id:"064",descripcion:"Tabossi"},
{idProvincia: "ERI", id:"065",descripcion:"Ubajay"},
{idProvincia: "ERI", id:"066",descripcion:"Urdinarrain"},
{idProvincia: "ERI", id:"067",descripcion:"Viale"},
{idProvincia: "ERI", id:"068",descripcion:"Victoria"},
{idProvincia: "ERI", id:"069",descripcion:"Villa Clara"},
{idProvincia: "ERI", id:"070",descripcion:"Villa del Rosario"},
{idProvincia: "ERI", id:"071",descripcion:"Villa Domínguez"},
{idProvincia: "ERI", id:"072",descripcion:"Villa Elisa"},
{idProvincia: "ERI", id:"073",descripcion:"Villa Mantero"},
{idProvincia: "ERI", id:"074",descripcion:"Villa Paranacito"},
{idProvincia: "ERI", id:"075",descripcion:"Villa Urquiza"},
{idProvincia: "ERI", id:"076",descripcion:"Villaguay"},
{idProvincia: "ERI", id:"DES",descripcion:"Desconocido"},
{idProvincia: "ERI", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "FOR", id:"001",descripcion:"Clorinda"},
{idProvincia: "FOR", id:"002",descripcion:"Comandante Fontana"},
{idProvincia: "FOR", id:"003",descripcion:"El Colorado"},
{idProvincia: "FOR", id:"004",descripcion:"El Espinillo"},
{idProvincia: "FOR", id:"005",descripcion:"Estanislao del Campo"},
{idProvincia: "FOR", id:"006",descripcion:"Formosa"},
{idProvincia: "FOR", id:"007",descripcion:"General Lucio V. Mansilla"},
{idProvincia: "FOR", id:"008",descripcion:"General Manuel Belgrano"},
{idProvincia: "FOR", id:"009",descripcion:"General Mosconi"},
{idProvincia: "FOR", id:"010",descripcion:"Herradura"},
{idProvincia: "FOR", id:"011",descripcion:"Ibarreta"},
{idProvincia: "FOR", id:"012",descripcion:"Ingeniero Juárez"},
{idProvincia: "FOR", id:"013",descripcion:"Laguna Blanca"},
{idProvincia: "FOR", id:"014",descripcion:"Laguna Naick Neck"},
{idProvincia: "FOR", id:"015",descripcion:"Laguna Yema"},
{idProvincia: "FOR", id:"016",descripcion:"Las Lomitas"},
{idProvincia: "FOR", id:"017",descripcion:"Mayor Vicente Villafañe"},
{idProvincia: "FOR", id:"018",descripcion:"Misión San Francisco de Laishi"},
{idProvincia: "FOR", id:"019",descripcion:"Misión Tacaaglé"},
{idProvincia: "FOR", id:"020",descripcion:"Palo Santo"},
{idProvincia: "FOR", id:"021",descripcion:"Pirané"},
{idProvincia: "FOR", id:"022",descripcion:"Pozo del Tigre"},
{idProvincia: "FOR", id:"023",descripcion:"Riacho He-Hé"},
{idProvincia: "FOR", id:"024",descripcion:"San Martín II"},
{idProvincia: "FOR", id:"025",descripcion:"Villa Dos Trece"},
{idProvincia: "FOR", id:"026",descripcion:"Villa Escolar"},
{idProvincia: "FOR", id:"027",descripcion:"Villa General Güemes"},
{idProvincia: "FOR", id:"DES",descripcion:"Desconocido"},
{idProvincia: "FOR", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "FUE", id:"DES",descripcion:"Desconocido"},
{idProvincia: "FUE", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "JUJ", id:"001",descripcion:"Abra Pampa"},
{idProvincia: "JUJ", id:"002",descripcion:"Caimancito"},
{idProvincia: "JUJ", id:"003",descripcion:"Calilegua"},
{idProvincia: "JUJ", id:"004",descripcion:"El Aguilar"},
{idProvincia: "JUJ", id:"005",descripcion:"El Carmen"},
{idProvincia: "JUJ", id:"006",descripcion:"El Talar"},
{idProvincia: "JUJ", id:"007",descripcion:"Fraile Pintado"},
{idProvincia: "JUJ", id:"008",descripcion:"Humahuaca"},
{idProvincia: "JUJ", id:"009",descripcion:"La Esperanza"},
{idProvincia: "JUJ", id:"010",descripcion:"La Mendieta"},
{idProvincia: "JUJ", id:"011",descripcion:"La Quiaca"},
{idProvincia: "JUJ", id:"012",descripcion:"Libertador General San Martín"},
{idProvincia: "JUJ", id:"013",descripcion:"Monterrico"},
{idProvincia: "JUJ", id:"014",descripcion:"Palma Sola"},
{idProvincia: "JUJ", id:"015",descripcion:"Palpalá"},
{idProvincia: "JUJ", id:"016",descripcion:"Ciudad Perico"},
{idProvincia: "JUJ", id:"017",descripcion:"San Pedro de Jujuy"},
{idProvincia: "JUJ", id:"018",descripcion:"San Salvador de Jujuy"},
{idProvincia: "JUJ", id:"019",descripcion:"Santa Clara"},
{idProvincia: "JUJ", id:"020",descripcion:"Tilcara"},
{idProvincia: "JUJ", id:"021",descripcion:"Yuto"},
{idProvincia: "JUJ", id:"DES",descripcion:"Desconocido"},
{idProvincia: "JUJ", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "LPA", id:"001",descripcion:"Abramo"},
{idProvincia: "LPA", id:"002",descripcion:"Alpachiri"},
{idProvincia: "LPA", id:"003",descripcion:"Alta Italia"},
{idProvincia: "LPA", id:"004",descripcion:"Anguil"},
{idProvincia: "LPA", id:"005",descripcion:"Arata"},
{idProvincia: "LPA", id:"006",descripcion:"Ataliva Roca"},
{idProvincia: "LPA", id:"007",descripcion:"Bernardo Larroude"},
{idProvincia: "LPA", id:"008",descripcion:"Bernasconi"},
{idProvincia: "LPA", id:"009",descripcion:"Caleufú"},
{idProvincia: "LPA", id:"010",descripcion:"Carro Quemado"},
{idProvincia: "LPA", id:"011",descripcion:"Catriló"},
{idProvincia: "LPA", id:"012",descripcion:"Ceballos"},
{idProvincia: "LPA", id:"013",descripcion:"Colonia Barón"},
{idProvincia: "LPA", id:"014",descripcion:"Conhelo"},
{idProvincia: "LPA", id:"015",descripcion:"Coronel Hilario Lagos"},
{idProvincia: "LPA", id:"016",descripcion:"Doblas"},
{idProvincia: "LPA", id:"017",descripcion:"Eduardo Castex"},
{idProvincia: "LPA", id:"018",descripcion:"Embajador Martini"},
{idProvincia: "LPA", id:"019",descripcion:"General Acha"},
{idProvincia: "LPA", id:"020",descripcion:"General Manuel Campos"},
{idProvincia: "LPA", id:"021",descripcion:"General Pico"},
{idProvincia: "LPA", id:"022",descripcion:"General San Martín"},
{idProvincia: "LPA", id:"023",descripcion:"Guatraché"},
{idProvincia: "LPA", id:"024",descripcion:"Ingeniero Luiggi"},
{idProvincia: "LPA", id:"025",descripcion:"Intendente Alvear"},
{idProvincia: "LPA", id:"026",descripcion:"Jacinto Aráuz"},
{idProvincia: "LPA", id:"027",descripcion:"La Adela"},
{idProvincia: "LPA", id:"028",descripcion:"La Humada"},
{idProvincia: "LPA", id:"029",descripcion:"La Maruja"},
{idProvincia: "LPA", id:"030",descripcion:"Lonquimay"},
{idProvincia: "LPA", id:"031",descripcion:"Luan Toro"},
{idProvincia: "LPA", id:"032",descripcion:"Macachín"},
{idProvincia: "LPA", id:"033",descripcion:"Mauricio Mayer"},
{idProvincia: "LPA", id:"034",descripcion:"Metileo"},
{idProvincia: "LPA", id:"035",descripcion:"Miguel Cané"},
{idProvincia: "LPA", id:"036",descripcion:"Miguel Riglos"},
{idProvincia: "LPA", id:"037",descripcion:"Monte Nievas"},
{idProvincia: "LPA", id:"038",descripcion:"Parera"},
{idProvincia: "LPA", id:"039",descripcion:"Puelches"},
{idProvincia: "LPA", id:"040",descripcion:"Puelén"},
{idProvincia: "LPA", id:"041",descripcion:"Quehué"},
{idProvincia: "LPA", id:"042",descripcion:"Quemú Quemú"},
{idProvincia: "LPA", id:"043",descripcion:"Rancul"},
{idProvincia: "LPA", id:"044",descripcion:"Realicó"},
{idProvincia: "LPA", id:"045",descripcion:"Rolón"},
{idProvincia: "LPA", id:"046",descripcion:"Santa Isabel"},
{idProvincia: "LPA", id:"047",descripcion:"Santa Rosa"},
{idProvincia: "LPA", id:"048",descripcion:"Santa Teresa"},
{idProvincia: "LPA", id:"049",descripcion:"Telén"},
{idProvincia: "LPA", id:"050",descripcion:"Toay"},
{idProvincia: "LPA", id:"051",descripcion:"Tomás Manuel de Anchorena"},
{idProvincia: "LPA", id:"052",descripcion:"Trenel"},
{idProvincia: "LPA", id:"053",descripcion:"Uriburu"},
{idProvincia: "LPA", id:"054",descripcion:"Veinticinco de Mayo"},
{idProvincia: "LPA", id:"055",descripcion:"Vértiz"},
{idProvincia: "LPA", id:"056",descripcion:"Victorica"},
{idProvincia: "LPA", id:"057",descripcion:"Villa Mirasol"},
{idProvincia: "LPA", id:"058",descripcion:"Winifreda"},
{idProvincia: "LPA", id:"DES",descripcion:"Desconocido"},
{idProvincia: "LPA", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "LRI", id:"001",descripcion:"Arauco"},
{idProvincia: "LRI", id:"002",descripcion:"Castro Barros"},
{idProvincia: "LRI", id:"003",descripcion:"Chamical"},
{idProvincia: "LRI", id:"004",descripcion:"Chilecito"},
{idProvincia: "LRI", id:"005",descripcion:"Coronel Felipe Varela"},
{idProvincia: "LRI", id:"006",descripcion:"Famatina"},
{idProvincia: "LRI", id:"007",descripcion:"General Ángel Vicente Peñaloza"},
{idProvincia: "LRI", id:"008",descripcion:"General Belgrano"},
{idProvincia: "LRI", id:"009",descripcion:"General Juan Facundo Quiroga"},
{idProvincia: "LRI", id:"010",descripcion:"General Lamadrid"},
{idProvincia: "LRI", id:"011",descripcion:"General Ocampo"},
{idProvincia: "LRI", id:"012",descripcion:"General San Martín"},
{idProvincia: "LRI", id:"013",descripcion:"Independencia"},
{idProvincia: "LRI", id:"014",descripcion:"La Rioja"},
{idProvincia: "LRI", id:"015",descripcion:"Rosario Vera Peñaloza"},
{idProvincia: "LRI", id:"016",descripcion:"San Blas de los Sauces"},
{idProvincia: "LRI", id:"017",descripcion:"Sanagasta"},
{idProvincia: "LRI", id:"018",descripcion:"Vinchina"},
{idProvincia: "LRI", id:"DES",descripcion:"Desconocido"},
{idProvincia: "LRI", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "MEN", id:"001",descripcion:"Capital"},
{idProvincia: "MEN", id:"002",descripcion:"General Alvear"},
{idProvincia: "MEN", id:"003",descripcion:"Godoy Cruz"},
{idProvincia: "MEN", id:"004",descripcion:"Guaymallén"},
{idProvincia: "MEN", id:"005",descripcion:"Junín"},
{idProvincia: "MEN", id:"006",descripcion:"La Paz"},
{idProvincia: "MEN", id:"007",descripcion:"Las Heras"},
{idProvincia: "MEN", id:"008",descripcion:"Lavalle"},
{idProvincia: "MEN", id:"009",descripcion:"Luján de Cuyo"},
{idProvincia: "MEN", id:"010",descripcion:"Maipú"},
{idProvincia: "MEN", id:"011",descripcion:"Malargüe"},
{idProvincia: "MEN", id:"012",descripcion:"Rivadavia"},
{idProvincia: "MEN", id:"013",descripcion:"San Carlos"},
{idProvincia: "MEN", id:"014",descripcion:"San Martín"},
{idProvincia: "MEN", id:"015",descripcion:"San Rafael"},
{idProvincia: "MEN", id:"016",descripcion:"Santa Rosa"},
{idProvincia: "MEN", id:"017",descripcion:"Tunuyán"},
{idProvincia: "MEN", id:"018",descripcion:"Tupungato"},
{idProvincia: "MEN", id:"DES",descripcion:"Desconocido"},
{idProvincia: "MEN", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "MIS", id:"001",descripcion:"Apóstoles"},
{idProvincia: "MIS", id:"002",descripcion:"Alba Posse"},
{idProvincia: "MIS", id:"003",descripcion:"Almafuerte"},
{idProvincia: "MIS", id:"004",descripcion:"Aristóbulo del Valle"},
{idProvincia: "MIS", id:"005",descripcion:"Arroyo del Medio"},
{idProvincia: "MIS", id:"006",descripcion:"Azara"},
{idProvincia: "MIS", id:"007",descripcion:"Bernardo de Irigoyen"},
{idProvincia: "MIS", id:"008",descripcion:"Bonpland"},
{idProvincia: "MIS", id:"009",descripcion:"Caá Yarí"},
{idProvincia: "MIS", id:"010",descripcion:"Campo Grande"},
{idProvincia: "MIS", id:"011",descripcion:"Campo Ramón"},
{idProvincia: "MIS", id:"012",descripcion:"Campo Viera"},
{idProvincia: "MIS", id:"013",descripcion:"Candelaria"},
{idProvincia: "MIS", id:"014",descripcion:"Capioví"},
{idProvincia: "MIS", id:"015",descripcion:"Caraguatay"},
{idProvincia: "MIS", id:"016",descripcion:"Cerro Azul"},
{idProvincia: "MIS", id:"017",descripcion:"Cerro Corá"},
{idProvincia: "MIS", id:"018",descripcion:"Colonia Alberdi"},
{idProvincia: "MIS", id:"019",descripcion:"Colonia Aurora"},
{idProvincia: "MIS", id:"020",descripcion:"Colonia Delicia"},
{idProvincia: "MIS", id:"021",descripcion:"Colonia Polana"},
{idProvincia: "MIS", id:"022",descripcion:"Colonia Victoria"},
{idProvincia: "MIS", id:"023",descripcion:"Colonia Wanda"},
{idProvincia: "MIS", id:"024",descripcion:"Comandante Andresito"},
{idProvincia: "MIS", id:"025",descripcion:"Concepción de la Sierra"},
{idProvincia: "MIS", id:"026",descripcion:"Corpus"},
{idProvincia: "MIS", id:"027",descripcion:"Dos Arroyos"},
{idProvincia: "MIS", id:"028",descripcion:"Dos de Mayo"},
{idProvincia: "MIS", id:"029",descripcion:"El Alcázar"},
{idProvincia: "MIS", id:"030",descripcion:"El Soberbio"},
{idProvincia: "MIS", id:"031",descripcion:"Eldorado"},
{idProvincia: "MIS", id:"032",descripcion:"Esperanza"},
{idProvincia: "MIS", id:"033",descripcion:"Fachinal"},
{idProvincia: "MIS", id:"034",descripcion:"Florentino Ameghino"},
{idProvincia: "MIS", id:"035",descripcion:"Garuhapé"},
{idProvincia: "MIS", id:"036",descripcion:"Garupá"},
{idProvincia: "MIS", id:"037",descripcion:"General Alvear"},
{idProvincia: "MIS", id:"038",descripcion:"General Urquiza"},
{idProvincia: "MIS", id:"039",descripcion:"Gobernador López"},
{idProvincia: "MIS", id:"040",descripcion:"Gobernador Roca"},
{idProvincia: "MIS", id:"041",descripcion:"Guaraní"},
{idProvincia: "MIS", id:"042",descripcion:"Hipólito Yrigoyen"},
{idProvincia: "MIS", id:"043",descripcion:"Itacaruaré"},
{idProvincia: "MIS", id:"044",descripcion:"Jardín América"},
{idProvincia: "MIS", id:"045",descripcion:"Leandro N. Alem"},
{idProvincia: "MIS", id:"046",descripcion:"Loreto"},
{idProvincia: "MIS", id:"047",descripcion:"Los Helechos"},
{idProvincia: "MIS", id:"048",descripcion:"Mártires"},
{idProvincia: "MIS", id:"049",descripcion:"Mojón Grande"},
{idProvincia: "MIS", id:"050",descripcion:"Montecarlo"},
{idProvincia: "MIS", id:"051",descripcion:"Nueve de Julio"},
{idProvincia: "MIS", id:"052",descripcion:"Oberá"},
{idProvincia: "MIS", id:"053",descripcion:"Olegario Víctor Andrade"},
{idProvincia: "MIS", id:"054",descripcion:"Panambí"},
{idProvincia: "MIS", id:"055",descripcion:"Posadas"},
{idProvincia: "MIS", id:"056",descripcion:"Profundidad"},
{idProvincia: "MIS", id:"057",descripcion:"Puerto Iguazú"},
{idProvincia: "MIS", id:"058",descripcion:"Puerto Leoni"},
{idProvincia: "MIS", id:"059",descripcion:"Puerto Libertad"},
{idProvincia: "MIS", id:"060",descripcion:"Puerto Piray"},
{idProvincia: "MIS", id:"061",descripcion:"Puerto Rico"},
{idProvincia: "MIS", id:"062",descripcion:"Ruiz de Montoya"},
{idProvincia: "MIS", id:"063",descripcion:"San Antonio"},
{idProvincia: "MIS", id:"064",descripcion:"San Ignacio"},
{idProvincia: "MIS", id:"065",descripcion:"San Javier"},
{idProvincia: "MIS", id:"066",descripcion:"San José"},
{idProvincia: "MIS", id:"067",descripcion:"San Martín"},
{idProvincia: "MIS", id:"068",descripcion:"San Pedro"},
{idProvincia: "MIS", id:"069",descripcion:"San Vicente"},
{idProvincia: "MIS", id:"070",descripcion:"Santa Ana"},
{idProvincia: "MIS", id:"071",descripcion:"Santa María (Misiones)"},
{idProvincia: "MIS", id:"072",descripcion:"Santiago de Liniers"},
{idProvincia: "MIS", id:"073",descripcion:"Santo Pipó"},
{idProvincia: "MIS", id:"074",descripcion:"Tres Capones"},
{idProvincia: "MIS", id:"075",descripcion:"Veinticinco de Mayo"},
{idProvincia: "MIS", id:"DES",descripcion:"Desconocido"},
{idProvincia: "MIS", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "NEU", id:"001",descripcion:"Centenario"},
{idProvincia: "NEU", id:"002",descripcion:"Aluminé"},
{idProvincia: "NEU", id:"003",descripcion:"Andacollo"},
{idProvincia: "NEU", id:"004",descripcion:"Añelo"},
{idProvincia: "NEU", id:"005",descripcion:"Bajada del Agrio"},
{idProvincia: "NEU", id:"006",descripcion:"Barrancas (Neuquén)"},
{idProvincia: "NEU", id:"007",descripcion:"Buta Ranquil"},
{idProvincia: "NEU", id:"008",descripcion:"Caviahue-Copahue"},
{idProvincia: "NEU", id:"009",descripcion:"Chos Malal"},
{idProvincia: "NEU", id:"010",descripcion:"Cutral-Co"},
{idProvincia: "NEU", id:"011",descripcion:"El Cholar"},
{idProvincia: "NEU", id:"012",descripcion:"El Huecú"},
{idProvincia: "NEU", id:"013",descripcion:"Huinganco"},
{idProvincia: "NEU", id:"014",descripcion:"Junín de los Andes"},
{idProvincia: "NEU", id:"015",descripcion:"Las Coloradas"},
{idProvincia: "NEU", id:"016",descripcion:"Las Lajas"},
{idProvincia: "NEU", id:"017",descripcion:"Las Ovejas"},
{idProvincia: "NEU", id:"018",descripcion:"Loncopué"},
{idProvincia: "NEU", id:"019",descripcion:"Los Miches"},
{idProvincia: "NEU", id:"020",descripcion:"Mariano Moreno"},
{idProvincia: "NEU", id:"021",descripcion:"Neuquén"},
{idProvincia: "NEU", id:"022",descripcion:"Picún Leufú"},
{idProvincia: "NEU", id:"023",descripcion:"Piedra del Águila"},
{idProvincia: "NEU", id:"024",descripcion:"Plaza Huincul"},
{idProvincia: "NEU", id:"025",descripcion:"Plottier"},
{idProvincia: "NEU", id:"026",descripcion:"Rincón de los Sauces"},
{idProvincia: "NEU", id:"027",descripcion:"San Martín de los Andes"},
{idProvincia: "NEU", id:"028",descripcion:"San Patricio del Chañar"},
{idProvincia: "NEU", id:"029",descripcion:"Senillosa"},
{idProvincia: "NEU", id:"030",descripcion:"Taquimilán"},
{idProvincia: "NEU", id:"031",descripcion:"Tricao Malal"},
{idProvincia: "NEU", id:"032",descripcion:"Villa El Chocón"},
{idProvincia: "NEU", id:"033",descripcion:"Villa La Angostura"},
{idProvincia: "NEU", id:"034",descripcion:"Villa Pehuenia"},
{idProvincia: "NEU", id:"035",descripcion:"Vista Alegre"},
{idProvincia: "NEU", id:"036",descripcion:"Zapala"},
{idProvincia: "NEU", id:"DES",descripcion:"Desconocido"},
{idProvincia: "NEU", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "RNE", id:"001",descripcion:"Allen"},
{idProvincia: "RNE", id:"002",descripcion:"Campo Grande"},
{idProvincia: "RNE", id:"003",descripcion:"Catriel"},
{idProvincia: "RNE", id:"004",descripcion:"Cervantes"},
{idProvincia: "RNE", id:"005",descripcion:"Chichinales"},
{idProvincia: "RNE", id:"006",descripcion:"Chimpay"},
{idProvincia: "RNE", id:"007",descripcion:"Choele Choel"},
{idProvincia: "RNE", id:"008",descripcion:"Cinco Saltos"},
{idProvincia: "RNE", id:"009",descripcion:"Cipolletti"},
{idProvincia: "RNE", id:"010",descripcion:"Comallo"},
{idProvincia: "RNE", id:"011",descripcion:"Contralmirante Cordero"},
{idProvincia: "RNE", id:"012",descripcion:"Coronel Belisle"},
{idProvincia: "RNE", id:"013",descripcion:"Darwin"},
{idProvincia: "RNE", id:"014",descripcion:"El Bolsón"},
{idProvincia: "RNE", id:"015",descripcion:"General Conesa"},
{idProvincia: "RNE", id:"016",descripcion:"General Enrique Godoy"},
{idProvincia: "RNE", id:"017",descripcion:"General Fernández Oro"},
{idProvincia: "RNE", id:"018",descripcion:"General Roca"},
{idProvincia: "RNE", id:"019",descripcion:"Ingeniero Jacobacci"},
{idProvincia: "RNE", id:"020",descripcion:"Ingeniero Luis A. Huergo"},
{idProvincia: "RNE", id:"021",descripcion:"Lamarque"},
{idProvincia: "RNE", id:"022",descripcion:"Los Menucos"},
{idProvincia: "RNE", id:"023",descripcion:"Luis Beltrán"},
{idProvincia: "RNE", id:"024",descripcion:"Mainqué"},
{idProvincia: "RNE", id:"025",descripcion:"Maquinchao"},
{idProvincia: "RNE", id:"026",descripcion:"Pilcaniyeu"},
{idProvincia: "RNE", id:"027",descripcion:"Pomona"},
{idProvincia: "RNE", id:"028",descripcion:"Río Colorado"},
{idProvincia: "RNE", id:"029",descripcion:"San Antonio Oeste"},
{idProvincia: "RNE", id:"030",descripcion:"San Carlos de Bariloche"},
{idProvincia: "RNE", id:"031",descripcion:"Sierra Colorada"},
{idProvincia: "RNE", id:"032",descripcion:"Sierra Grande"},
{idProvincia: "RNE", id:"033",descripcion:"Valcheta"},
{idProvincia: "RNE", id:"034",descripcion:"Viedma"},
{idProvincia: "RNE", id:"035",descripcion:"Villa Regina"},
{idProvincia: "RNE", id:"DES",descripcion:"Desconocido"},
{idProvincia: "RNE", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "SAL", id:"001",descripcion:"Apolinario Saravia"},
{idProvincia: "SAL", id:"002",descripcion:"Aguaray"},
{idProvincia: "SAL", id:"003",descripcion:"Cafayate"},
{idProvincia: "SAL", id:"004",descripcion:"Campo Quijano"},
{idProvincia: "SAL", id:"005",descripcion:"Campo Santo"},
{idProvincia: "SAL", id:"006",descripcion:"Cerrillos"},
{idProvincia: "SAL", id:"007",descripcion:"Chicoana"},
{idProvincia: "SAL", id:"008",descripcion:"Colonia Santa Rosa"},
{idProvincia: "SAL", id:"009",descripcion:"El Bordo"},
{idProvincia: "SAL", id:"010",descripcion:"El Carril"},
{idProvincia: "SAL", id:"011",descripcion:"El Galpón"},
{idProvincia: "SAL", id:"012",descripcion:"El Quebrachal"},
{idProvincia: "SAL", id:"013",descripcion:"Embarcación"},
{idProvincia: "SAL", id:"014",descripcion:"General Güemes"},
{idProvincia: "SAL", id:"015",descripcion:"General Mosconi"},
{idProvincia: "SAL", id:"016",descripcion:"Hipólito Yrigoyen"},
{idProvincia: "SAL", id:"017",descripcion:"Joaquín V. González"},
{idProvincia: "SAL", id:"018",descripcion:"La Merced"},
{idProvincia: "SAL", id:"019",descripcion:"Las Lajitas"},
{idProvincia: "SAL", id:"020",descripcion:"Pichanal"},
{idProvincia: "SAL", id:"021",descripcion:"Profesor Salvador Mazza"},
{idProvincia: "SAL", id:"022",descripcion:"Rivadavia Banda Norte"},
{idProvincia: "SAL", id:"023",descripcion:"Rivadavia Banda Sur"},
{idProvincia: "SAL", id:"024",descripcion:"Rosario de la Frontera"},
{idProvincia: "SAL", id:"025",descripcion:"Rosario de Lerma"},
{idProvincia: "SAL", id:"026",descripcion:"Salta"},
{idProvincia: "SAL", id:"027",descripcion:"San José de Metán"},
{idProvincia: "SAL", id:"028",descripcion:"San Ramón de la Nueva Orán"},
{idProvincia: "SAL", id:"029",descripcion:"Santa Victoria Este"},
{idProvincia: "SAL", id:"030",descripcion:"Santa Victoria Oeste"},
{idProvincia: "SAL", id:"031",descripcion:"Tartagal"},
{idProvincia: "SAL", id:"DES",descripcion:"Desconocido"},
{idProvincia: "SAL", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "SCR", id:"001",descripcion:"Caleta Olivia"},
{idProvincia: "SCR", id:"002",descripcion:"Comandante Luis Piedrabuena"},
{idProvincia: "SCR", id:"003",descripcion:"El Calafate"},
{idProvincia: "SCR", id:"004",descripcion:"Gobernador Gregores"},
{idProvincia: "SCR", id:"005",descripcion:"Las Heras"},
{idProvincia: "SCR", id:"006",descripcion:"Los Antiguos"},
{idProvincia: "SCR", id:"007",descripcion:"Perito Moreno"},
{idProvincia: "SCR", id:"008",descripcion:"Pico Truncado"},
{idProvincia: "SCR", id:"009",descripcion:"Puerto Deseado"},
{idProvincia: "SCR", id:"010",descripcion:"Puerto San Julián"},
{idProvincia: "SCR", id:"011",descripcion:"Puerto Santa Cruz"},
{idProvincia: "SCR", id:"012",descripcion:"Río Gallegos"},
{idProvincia: "SCR", id:"013",descripcion:"Río Turbio"},
{idProvincia: "SCR", id:"014",descripcion:"Veintiocho de Noviembre"},
{idProvincia: "SCR", id:"DES",descripcion:"Desconocido"},
{idProvincia: "SCR", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "SDE", id:"001",descripcion:"Bandera"},
{idProvincia: "SDE", id:"002",descripcion:"Añatuya"},
{idProvincia: "SDE", id:"003",descripcion:"Beltrán"},
{idProvincia: "SDE", id:"004",descripcion:"Campo Gallo"},
{idProvincia: "SDE", id:"005",descripcion:"Clodomira"},
{idProvincia: "SDE", id:"006",descripcion:"Colonia Dora"},
{idProvincia: "SDE", id:"007",descripcion:"Fernández"},
{idProvincia: "SDE", id:"008",descripcion:"Frías"},
{idProvincia: "SDE", id:"009",descripcion:"Ingeniero Forres"},
{idProvincia: "SDE", id:"010",descripcion:"La Banda"},
{idProvincia: "SDE", id:"011",descripcion:"Loreto"},
{idProvincia: "SDE", id:"012",descripcion:"Los Juríes"},
{idProvincia: "SDE", id:"013",descripcion:"Los Telares"},
{idProvincia: "SDE", id:"014",descripcion:"Monte Quemado"},
{idProvincia: "SDE", id:"015",descripcion:"Nueva Esperanza"},
{idProvincia: "SDE", id:"016",descripcion:"Pampa de los Guanacos"},
{idProvincia: "SDE", id:"017",descripcion:"Pinto"},
{idProvincia: "SDE", id:"018",descripcion:"Pozo Hondo"},
{idProvincia: "SDE", id:"019",descripcion:"Quimilí"},
{idProvincia: "SDE", id:"020",descripcion:"San Pedro de Guasayán"},
{idProvincia: "SDE", id:"021",descripcion:"Santiago del Estero"},
{idProvincia: "SDE", id:"022",descripcion:"Selva"},
{idProvincia: "SDE", id:"023",descripcion:"Sumampa"},
{idProvincia: "SDE", id:"024",descripcion:"Suncho Corral"},
{idProvincia: "SDE", id:"025",descripcion:"Termas de Río Hondo"},
{idProvincia: "SDE", id:"026",descripcion:"Tintina"},
{idProvincia: "SDE", id:"027",descripcion:"Villa Atamisqui"},
{idProvincia: "SDE", id:"028",descripcion:"Villa Ojo de Agua"},
{idProvincia: "SDE", id:"DES",descripcion:"Desconocido"},
{idProvincia: "SDE", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "SFE", id:"001",descripcion:"Armstrong"},
{idProvincia: "SFE", id:"002",descripcion:"Arroyo Seco"},
{idProvincia: "SFE", id:"003",descripcion:"Avellaneda"},
{idProvincia: "SFE", id:"004",descripcion:"Calchaquí"},
{idProvincia: "SFE", id:"005",descripcion:"Cañada de Gómez"},
{idProvincia: "SFE", id:"006",descripcion:"Capitán Bermúdez"},
{idProvincia: "SFE", id:"007",descripcion:"Carcarañá"},
{idProvincia: "SFE", id:"008",descripcion:"Casilda"},
{idProvincia: "SFE", id:"009",descripcion:"Ceres"},
{idProvincia: "SFE", id:"010",descripcion:"Coronda"},
{idProvincia: "SFE", id:"011",descripcion:"El Trébol"},
{idProvincia: "SFE", id:"012",descripcion:"Esperanza"},
{idProvincia: "SFE", id:"013",descripcion:"Firmat"},
{idProvincia: "SFE", id:"014",descripcion:"Fray Luis Beltrán"},
{idProvincia: "SFE", id:"015",descripcion:"Frontera"},
{idProvincia: "SFE", id:"016",descripcion:"Funes"},
{idProvincia: "SFE", id:"017",descripcion:"Gálvez"},
{idProvincia: "SFE", id:"018",descripcion:"Granadero Baigorria"},
{idProvincia: "SFE", id:"019",descripcion:"Laguna Paiva"},
{idProvincia: "SFE", id:"020",descripcion:"Las Parejas"},
{idProvincia: "SFE", id:"021",descripcion:"Las Rosas"},
{idProvincia: "SFE", id:"022",descripcion:"Las Toscas"},
{idProvincia: "SFE", id:"023",descripcion:"Malabrigo"},
{idProvincia: "SFE", id:"024",descripcion:"Pérez"},
{idProvincia: "SFE", id:"025",descripcion:"Puerto General San Martín"},
{idProvincia: "SFE", id:"026",descripcion:"Rafaela"},
{idProvincia: "SFE", id:"027",descripcion:"Reconquista"},
{idProvincia: "SFE", id:"028",descripcion:"Recreo"},
{idProvincia: "SFE", id:"029",descripcion:"Roldán"},
{idProvincia: "SFE", id:"030",descripcion:"Rosario"},
{idProvincia: "SFE", id:"031",descripcion:"Rufino"},
{idProvincia: "SFE", id:"032",descripcion:"San Carlos Centro"},
{idProvincia: "SFE", id:"033",descripcion:"San Cristóbal"},
{idProvincia: "SFE", id:"034",descripcion:"San Genaro"},
{idProvincia: "SFE", id:"035",descripcion:"San Javier"},
{idProvincia: "SFE", id:"036",descripcion:"San Jorge"},
{idProvincia: "SFE", id:"037",descripcion:"San Justo"},
{idProvincia: "SFE", id:"038",descripcion:"San Lorenzo"},
{idProvincia: "SFE", id:"039",descripcion:"Santa Fe"},
{idProvincia: "SFE", id:"040",descripcion:"Santo Tomé"},
{idProvincia: "SFE", id:"041",descripcion:"Sastre"},
{idProvincia: "SFE", id:"042",descripcion:"Sunchales"},
{idProvincia: "SFE", id:"043",descripcion:"Tostado"},
{idProvincia: "SFE", id:"044",descripcion:"Totoras"},
{idProvincia: "SFE", id:"045",descripcion:"Venado Tuerto"},
{idProvincia: "SFE", id:"046",descripcion:"Vera"},
{idProvincia: "SFE", id:"047",descripcion:"Villa Cañás"},
{idProvincia: "SFE", id:"048",descripcion:"Villa Constitución"},
{idProvincia: "SFE", id:"049",descripcion:"Villa Gobernador Gálvez"},
{idProvincia: "SFE", id:"050",descripcion:"Villa Ocampo"},
{idProvincia: "SFE", id:"DES",descripcion:"Desconocido"},
{idProvincia: "SFE", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "SJU", id:"001",descripcion:"Angaco"},
{idProvincia: "SJU", id:"002",descripcion:"Albardón"},
{idProvincia: "SJU", id:"003",descripcion:"Calingasta"},
{idProvincia: "SJU", id:"004",descripcion:"Caucete"},
{idProvincia: "SJU", id:"005",descripcion:"Chimbas"},
{idProvincia: "SJU", id:"006",descripcion:"Iglesia"},
{idProvincia: "SJU", id:"007",descripcion:"Jáchal"},
{idProvincia: "SJU", id:"008",descripcion:"Nueve de Julio"},
{idProvincia: "SJU", id:"009",descripcion:"Pocito"},
{idProvincia: "SJU", id:"010",descripcion:"Rawson"},
{idProvincia: "SJU", id:"011",descripcion:"Rivadavia"},
{idProvincia: "SJU", id:"012",descripcion:"San Juan"},
{idProvincia: "SJU", id:"013",descripcion:"San Martín"},
{idProvincia: "SJU", id:"014",descripcion:"Santa Lucía"},
{idProvincia: "SJU", id:"015",descripcion:"Sarmiento"},
{idProvincia: "SJU", id:"016",descripcion:"Ullum"},
{idProvincia: "SJU", id:"017",descripcion:"Valle Fértil"},
{idProvincia: "SJU", id:"018",descripcion:"Veinticinco de Mayo"},
{idProvincia: "SJU", id:"019",descripcion:"Zonda"},
{idProvincia: "SJU", id:"DES",descripcion:"Desconocido"},
{idProvincia: "SJU", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "SLU", id:"001",descripcion:"Alto Pelado"},
{idProvincia: "SLU", id:"002",descripcion:"Alto Pencoso"},
{idProvincia: "SLU", id:"003",descripcion:"Anchorena"},
{idProvincia: "SLU", id:"004",descripcion:"Arizona"},
{idProvincia: "SLU", id:"005",descripcion:"Bagual"},
{idProvincia: "SLU", id:"006",descripcion:"Balde"},
{idProvincia: "SLU", id:"007",descripcion:"Batavia"},
{idProvincia: "SLU", id:"008",descripcion:"Brazley"},
{idProvincia: "SLU", id:"009",descripcion:"Buena Esperanza"},
{idProvincia: "SLU", id:"010",descripcion:"Candelaria"},
{idProvincia: "SLU", id:"011",descripcion:"Carpintería"},
{idProvincia: "SLU", id:"012",descripcion:"Concarán"},
{idProvincia: "SLU", id:"013",descripcion:"Cortaderas"},
{idProvincia: "SLU", id:"014",descripcion:"El Trapiche"},
{idProvincia: "SLU", id:"015",descripcion:"El Volcán"},
{idProvincia: "SLU", id:"016",descripcion:"Fortín El Patria"},
{idProvincia: "SLU", id:"017",descripcion:"Fortuna"},
{idProvincia: "SLU", id:"018",descripcion:"Fraga"},
{idProvincia: "SLU", id:"019",descripcion:"Juan Jorba"},
{idProvincia: "SLU", id:"020",descripcion:"Juan Llerena"},
{idProvincia: "SLU", id:"021",descripcion:"Juana Koslay"},
{idProvincia: "SLU", id:"022",descripcion:"Justo Daract"},
{idProvincia: "SLU", id:"023",descripcion:"La Calera"},
{idProvincia: "SLU", id:"024",descripcion:"La Carolina"},
{idProvincia: "SLU", id:"025",descripcion:"La Florida"},
{idProvincia: "SLU", id:"026",descripcion:"La Punilla"},
{idProvincia: "SLU", id:"027",descripcion:"La Punta"},
{idProvincia: "SLU", id:"028",descripcion:"La Toma"},
{idProvincia: "SLU", id:"029",descripcion:"La Vertiente"},
{idProvincia: "SLU", id:"030",descripcion:"Lafinur"},
{idProvincia: "SLU", id:"031",descripcion:"Las Aguadas"},
{idProvincia: "SLU", id:"032",descripcion:"Las Chacras"},
{idProvincia: "SLU", id:"033",descripcion:"Las Lagunas"},
{idProvincia: "SLU", id:"034",descripcion:"Lavaisse"},
{idProvincia: "SLU", id:"035",descripcion:"Leandro N. Alem"},
{idProvincia: "SLU", id:"036",descripcion:"Los Molles"},
{idProvincia: "SLU", id:"037",descripcion:"Luján"},
{idProvincia: "SLU", id:"038",descripcion:"Merlo"},
{idProvincia: "SLU", id:"039",descripcion:"Naschel"},
{idProvincia: "SLU", id:"040",descripcion:"Navia"},
{idProvincia: "SLU", id:"041",descripcion:"Nogolí"},
{idProvincia: "SLU", id:"042",descripcion:"Nueva Galia"},
{idProvincia: "SLU", id:"043",descripcion:"Papagayos"},
{idProvincia: "SLU", id:"044",descripcion:"Paso Grande"},
{idProvincia: "SLU", id:"045",descripcion:"Potrero de los Funes"},
{idProvincia: "SLU", id:"046",descripcion:"Quines"},
{idProvincia: "SLU", id:"047",descripcion:"Renca"},
{idProvincia: "SLU", id:"048",descripcion:"Saladillo"},
{idProvincia: "SLU", id:"049",descripcion:"San Francisco del Monte de Oro"},
{idProvincia: "SLU", id:"050",descripcion:"San Jerónimo"},
{idProvincia: "SLU", id:"051",descripcion:"San José del Morro"},
{idProvincia: "SLU", id:"052",descripcion:"San Luis"},
{idProvincia: "SLU", id:"053",descripcion:"San Martín"},
{idProvincia: "SLU", id:"054",descripcion:"San Pablo"},
{idProvincia: "SLU", id:"055",descripcion:"Santa Rosa del Conlara"},
{idProvincia: "SLU", id:"056",descripcion:"Talita"},
{idProvincia: "SLU", id:"057",descripcion:"Tilisarao"},
{idProvincia: "SLU", id:"058",descripcion:"Unión"},
{idProvincia: "SLU", id:"059",descripcion:"Villa de la Quebrada"},
{idProvincia: "SLU", id:"060",descripcion:"Villa de Praga"},
{idProvincia: "SLU", id:"061",descripcion:"Villa del Carmen"},
{idProvincia: "SLU", id:"062",descripcion:"Villa General Roca"},
{idProvincia: "SLU", id:"063",descripcion:"Villa Larca"},
{idProvincia: "SLU", id:"064",descripcion:"Villa Mercedes"},
{idProvincia: "SLU", id:"065",descripcion:"Zanjitas"},
{idProvincia: "SLU", id:"DES",descripcion:"Desconocido"},
{idProvincia: "SLU", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "TDF", id:"001",descripcion:"Río Grande"},
{idProvincia: "TDF", id:"002",descripcion:"Ushuaia"},
{idProvincia: "TDF", id:"003",descripcion:"Tolhuin"},
{idProvincia: "TDF", id:"DES",descripcion:"Desconocido"},
{idProvincia: "TDF", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "TUC", id:"001",descripcion:"Aguilares"},
{idProvincia: "TUC", id:"002",descripcion:"Alderetes"},
{idProvincia: "TUC", id:"003",descripcion:"Banda del Río Salí"},
{idProvincia: "TUC", id:"004",descripcion:"Bella Vista"},
{idProvincia: "TUC", id:"005",descripcion:"Burruyacú"},
{idProvincia: "TUC", id:"006",descripcion:"Concepción"},
{idProvincia: "TUC", id:"007",descripcion:"Famaillá"},
{idProvincia: "TUC", id:"008",descripcion:"Graneros"},
{idProvincia: "TUC", id:"009",descripcion:"Juan Bautista Alberdi"},
{idProvincia: "TUC", id:"010",descripcion:"La Cocha"},
{idProvincia: "TUC", id:"011",descripcion:"Las Talitas"},
{idProvincia: "TUC", id:"012",descripcion:"Lules"},
{idProvincia: "TUC", id:"013",descripcion:"Monteros"},
{idProvincia: "TUC", id:"014",descripcion:"San Miguel de Tucumán"},
{idProvincia: "TUC", id:"015",descripcion:"Simoca"},
{idProvincia: "TUC", id:"016",descripcion:"Tafí del Valle"},
{idProvincia: "TUC", id:"017",descripcion:"Tafí Viejo"},
{idProvincia: "TUC", id:"018",descripcion:"Trancas"},
{idProvincia: "TUC", id:"019",descripcion:"Yerba Buena"},
{idProvincia: "TUC", id:"DES",descripcion:"Desconocido"},
{idProvincia: "TUC", id:"X0", descripcion:"NO INFORMADO"},
{idProvincia: "X0",  id:"X0", descripcion:"NO INFORMADO"}
]