import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDatosLicencia,
  fetchUnidadesOrganizativasAsync,
  selectLicenses,
  setUnidadesOrganizativas,
} from "../licenseSlice";

export default function AdmUnidadesOrganizativas({ control, user }) {
  const dispatch = useDispatch();
  const { form, data } = useSelector(selectLicenses);

  useEffect(() => {
    dispatch(fetchDatosLicencia(form.licencia.code)).then((x) => {
      dispatch(
        fetchUnidadesOrganizativasAsync({
          idHr: user.datos.idHR,
          codRegimen: form.regimen,
        })
      );
    });
  }, []);

  const [selectedCodes, setSelectedCodes] = useState([]);

  const handleCheckboxChange = (code, isChecked) => {
    if (isChecked) {
      // Agregar el código si no está en la lista
      setSelectedCodes((prevSelectedCodes) => [...prevSelectedCodes, code]);
    } else {
      // Filtrar los códigos que no sean el deseleccionado
      setSelectedCodes((prevSelectedCodes) =>
        prevSelectedCodes.filter((c) => c !== code)
      );
    }
  };

  useEffect(() => {
    const selectedUnidadesOrganizativas = data.unidadesOrganizativas.filter(
      (uo) => selectedCodes.includes(uo.code)
    );

    // Actualizar el estado del formulario
    dispatch(setUnidadesOrganizativas(selectedUnidadesOrganizativas));
  }, [selectedCodes]);

  return (
    <>
      <FormControl fullWidth>
        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold",mb:1 }}>
          Unidad/es Organizativa/s
        </FormLabel>
        {data.unidadesOrganizativas
          ?.filter(
            (value, index, self) =>
              index === self.findIndex((v) => v.code === value.code)
          )
          .map((uo, i) => (
            <FormControlLabel
              key={`uo-${i}`}
              label={uo.description}
              control={
                <Checkbox
                  disabled={data.unidadesOrganizativas.length === 1}
                  checked={
                    data.unidadesOrganizativas.length === 1 ||
                    form.unidadesOrganizativas?.some((x) => x.code === uo.code)
                  }
                  key={`ch-${i}`}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    handleCheckboxChange(uo.code, isChecked);
                  }}
                />
              }
            />
          ))}


        <Typography variant="body1" fullWidth sx={{mt:1}}>
        Si no visualizás alguna de la/s unidad/es organizativa/s para la/s que solicitás la licencia, comunícate con tu RAP, área de RH o equipo de conducción.
        </Typography>
      </FormControl>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
          Observaciones
        </FormLabel>
        <Typography variant="body1" fullWidth sx={{ mb: 1 }}>
        Si tu solicitud tiene alguna particularidad en relación a la/s Unidad/es Organizativa/s, informala aquí. (Opcional)
        </Typography>
        <Controller
          name="observaciones"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              multiline
              fullWidth
              rows={3}
              placeholder="Máximo 250 caracteres."
              value={value}
              onChange={(e)=>{
                if(e.target.value.length > 250){
                  const truncatedText = e.target.value.slice(0, 250);
                  onChange(truncatedText);
                  return
                }
                onChange(e)}}
            />
          )}
        />
      </FormControl>
    </>
  );
}
