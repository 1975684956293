import React, { useCallback } from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDocumentAsync,
  postCertificado,
  removePrimerInforme,
  selectLicenses,
  setPrimerInfome
} from "../licenseSlice";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDropzone } from "react-dropzone";
import { selectDatosPersonales } from "../../user/userSlice";
import { selectSettings } from "../../parameters/parametersSlice";
import CustomAlert from "../../alert";
function UploadPrimerInforme({ primerInforme }) {
  const { form } = useSelector(selectLicenses);
  const { extensions } = useSelector(selectSettings);
  const [error, setError] = React.useState({ show: false, data: "" });

  const handleClose = () => {
    setError({ show: false });
  };

  const dispatch = useDispatch();
  const { datos } = useSelector(selectDatosPersonales);
  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = [...primerInforme, ...acceptedFiles];
      if (files.length > 3) {
        setError({
          show: true,
          data: `Solo se pueden adjuntar hasta 3 archivos como máximo.`,
        });
        return;
      }
      const duplicateFile = acceptedFiles.find((newFile) => {
        return primerInforme.some(
          (existingFile) => existingFile.name === newFile.name
        );
      });
      if (duplicateFile) {
        setError({
          show: true,
          data: `El archivo "${duplicateFile.name}" ya ha sido adjuntado.`,
        });
        return;
      }
      
      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append("userId", datos.id);
        formData.append("cuit", datos.cuit);
        formData.append("key", "primerInforme");
        formData.append("primerInforme", file);
        dispatch(setPrimerInfome({
          userId : datos.id,
          datos : datos.cuit,
          key : "primerInforme",
          name : file.name,
          primerInforme: file
        }))
      });
      // dispatch(postCertificado(formData));
    },
    [primerInforme]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: JSON.parse(extensions),
  });

  return (
    <section>
      <div
        {...getRootProps({ className: "dropzone" })}
        className="border-2 rounded-md py-5 px-5"
      >
        <input {...getInputProps()} />
        <p className="text-center text-[#5836ff]">
          Arrastrar archivos para adjuntar ó{" "}
          <button className="p-2 bg-[#5836ff] text-white rounded-md shadow-sm cursor-pointer">
            Explorar en disco
          </button>
        </p>
      </div>
      {form.primerInforme?.map((x) => (
        <>
          <Box mt={2} display="flex" justifyContent="space-evenly">
            <Typography key={x.id}>
              <InsertDriveFileIcon />
              {x.name}
            </Typography>
            <Button
              onClick={(e) => {
                dispatch(deleteDocumentAsync(x.id));
                dispatch(removePrimerInforme(x));
              }}
            >
              <DeleteForeverIcon sx={{ color: "#ff3d8b" }} />
            </Button>
          </Box>
        </>
      ))}
      <CustomAlert error={error} severity="error" onClose={handleClose} />
    </section>
  );
}

export default UploadPrimerInforme;
