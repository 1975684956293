import React from "react";

function MiaIos() {

  return (
    <div className="p-2">
      <h1 className="!text-xl">Seguí las instrucciones:</h1>
      <ul className="list-decimal">
        <li>
          Descargá la aplicación MIA para iOS (iPhone) e instalá Mi Autogestión.
          Tené en cuenta que esta operación <br /> puede tardar unos segundos.{" "}
          <a href="itms-services://?action=download-manifest&url=https://mia.dguiaf-gcba.gov.ar/ios/DistributionSummary.plist">Instalar MIA</a>        

        </li>
        <li>
          Una vez instalada la app, seguí estos pasos:
          <ul className="list-disc">
            <li>Entrá en "Configuración" o "Ajustes" de tu teléfono.</li>
            <li>Selecciona la opción "General".</li>
            <li>
              Presioná sobre "Admon. ó Admin. de dispositivos" (dependerá del
              idioma en que esté configurado tu celular).
            </li>
            <li>
              Cliqueá sobre la leyenda "Confiar en Gobierno de la Ciudad de Bs
              As" y presioná el botón de "Confiar".
            </li>
          </ul>
        </li>
        <li>Andá al Escritorio e ingresá a MiA.</li>
      </ul>

      <p>
        <span className="font-semibold">Importante:</span> si al querer ingresar
        a MiA te aparece un cartel avisando de la No confiabilidad del
        desarrollador, <br /> intentalo nuevamente siguiendo correctamente los
        pasos detallados.
      </p>
    </div>
  );
}

export default MiaIos;
