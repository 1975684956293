import React from "react";
import {
  Box,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
function FiltersHistory({ filters, setFilters ,handleBuscar,disabled,handleReset , id , activeBtn , page , licenseTypeId}) {

  const handleChangeDates = (action, event) => {
    switch (action) {
      case "fechaInicio":
        setFilters({ ...filters, fechaInicio: event });
        break;
      case "fechaFin":
        setFilters({ ...filters, fechaFin: event });
        break;
      default:
        break;
    }
  };

  const navigate = useNavigate()

  return (
    <Box className="p-2 mx-auto gap-3 flex justify-center flex-wrap">
      <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
        <DatePicker
          onChange={(e) => handleChangeDates("fechaInicio", e)}
          sx={{ width: "160px" }}
          slotProps={{ textField: { size: "small" } }}
          className="datepicker"
          value={filters.fechaInicio}
          label="Fecha Inicio"
        />
        <DatePicker
          onChange={(e) => handleChangeDates("fechaFin", e)}
          sx={{ width: "160px" }}
          slotProps={{ textField: { size: "small" } }}
          className="datepicker"
          value={filters.fechaFin}
          label="Fecha Fin"
        />
      </LocalizationProvider>
      <Box className="flex gap-2 justify-end ml-auto mr-auto sm:mr-0">
        <Button variant="outlined" startIcon={<ArrowBackIosIcon/>} onClick={()=>navigate(`/administrative/${id}?activeBtn=${activeBtn}&pageAct=${page}&licenseTypeId=${licenseTypeId}`)}>Volver</Button>
        <Button variant="contained" onClick={handleBuscar}>
          Buscar
        </Button>
        <Button
          disabled={disabled}
          variant="outlined"
          onClick={handleReset}
        >
          Limpiar
        </Button>
      </Box> 
    </Box>
  );
}

export default FiltersHistory;
