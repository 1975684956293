import api, { APPCONFIG } from "../../api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allLicences: [],
  oneLicence: {},
  history: [],
  diagnosticos: [],
  totalCount: 0,
  allHistoryLicences: [],
  allAuditHistoryLicences: [],
  auditadasBtn: false
};

export const postExecuteAction = createAsyncThunk(
  "auditProLicence/ExecuteAction",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/AuditorProfesional/Acciones?action=${data.action}&userId=${data.userId}&licenseTypeId=${data.licenseTypeId}`;
    const response = await api.post(url, data.data);
    return response.data;
  }
);

export const fetchTomarLicencia = createAsyncThunk(
  "auditProLicence/fetchTomarLicencia",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/AuditorProfesional/TomarLicencia?userId=${data.userId}&licenseId=${data.licenceId}`;
    const response = await api.post(url);
    return response.data;
  }
);

export const fetchLiberarLicencia = createAsyncThunk(
  "auditProLicence/fetchLiberarLicencia",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/AuditorProfesional/LiberarLicencia?userId=${data.userId}&licenseId=${data.licenceId}`;
    const response = await api.post(url);
    return response.data;
  }
);

export const fetchHistoryLicenseById = createAsyncThunk(
  "auditProLicence/fetchHistoryLicenseById",
  async (licenceId) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/History?licenseId=${licenceId}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchAuditProLicenciasAsync = createAsyncThunk(
  "auditProLicence/fetchAuditProLicenciasAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/AuditorProfesional/licences?id=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&pendientes=${data.pendientes}`;
    const response = await api.post(url, {
      filter: data.filters?.length > 0 ? data.filters : [],
      btn: data.btn,
    });
    return response.data;
  }
);

export const fetchOneAuditProLicenciaAsync = createAsyncThunk(
  "auditProLicence/fetchOneAuditProLicenciaAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License?licenseId=${data.id}&includeFiles=true&licenseTypeId=${data.licenseTypeId}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const postExecuteRevisarComportamiento = createAsyncThunk(
  "auditProLicence/postExecuteRevisarComportamiento",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/AuditorProfesional/RevisarComportamiento?idHr=${data.idHr}&licenseId=${data.licenseId}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const checkRevisarComportamiento = createAsyncThunk(
  "auditProLicence/checkRevisarComportamiento",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/AuditorProfesional/CheckRevisarComportamiento?idHr=${data.idHr}&licenseId=${data.licenseId}`;
    const response = await api.get(url);
    return response.data;
  }
);


export const putObservarFile = createAsyncThunk(
  "auditProLicence/putObservarFile",
  async (fileId) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Files/Observada?fileId=${fileId}`;
    const response = await api.put(url);
    return response.data;
  }
);

export const fetchAuditProDiagnosticosAsync = createAsyncThunk(
  "auditProLicence/getDiagnosticosAuditPro",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/Diagnosticos?diagnostico=${data}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchAllAuditProLicenciasHistoryAsync = createAsyncThunk(
  "auditProLicense/getAllAuditProLicenciasHistoryAsync",
  async (data) => {
    let sort = data.ord === 1 ? "desc" : "asc";
    const url = `${APPCONFIG.APIs.LicenseAPI}/AuditorProfesional/licencesHistory?id=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
    const response = await api.post(url, {
      filter: data.filters,
      sort: { sort },
    });
    return response.data;
  }
);

export const fetchAllAuditProLicenciasAllHistoryAsync = createAsyncThunk(
  "auditProLicense/fetchAllAuditProLicenciasAllHistoryAsync",
  async (data) => {
    let sort = data.ord === 1 ? "desc" : "asc";
    const url = `${APPCONFIG.APIs.LicenseAPI}/AuditorProfesional/allLicencesHistory?id=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
    const response = await api.post(url, {
      filter: data.filters,
      sort: { sort },
    });
    return response.data;
  }
);

export const auditProLicenceSlice = createSlice({
  name: "auditProLicence",
  initialState,
  reducers: {
    removeOneLicense: (state) => {
      state.oneLicence = {};
    },
    removeHistory: (state) => {
      state.history = [];
    },
    auditadasBtnAction: (state , action) => {
      if(action.payload){
        state.auditadasBtn = true;
        state.allLicences = [];
      }else{
        state.auditadasBtn = false;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuditProLicenciasAsync.fulfilled, (state, action) => {
        state.allLicences = action.payload.licenses;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchAllAuditProLicenciasHistoryAsync.fulfilled, (state, action) => {
        state.allHistoryLicences = action.payload.licenses;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchAllAuditProLicenciasAllHistoryAsync.fulfilled, (state, action) => {
        state.allAuditHistoryLicences = action.payload.licenses;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchOneAuditProLicenciaAsync.fulfilled, (state, action) => {
        state.oneLicence = action.payload;
      })
      .addCase(fetchHistoryLicenseById.fulfilled, (state, action) => {
        state.history = action.payload;
      })
      .addCase(fetchAuditProDiagnosticosAsync.fulfilled, (state, action) => {
        state.diagnosticos = action.payload;
      });
  },
});

export const { removeOneLicense , removeHistory , auditadasBtnAction } = auditProLicenceSlice.actions;

export default auditProLicenceSlice.reducer;
