import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@mui/material";
import { selectLicenses } from "../licenseSlice";
import {  useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { selectDatosPersonales } from "../../user/userSlice";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import moment from "moment";
import AttachFileIcon from '@mui/icons-material/AttachFile';
export default function Step5({ control }) {
  const { form, data, parameters } = useSelector(selectLicenses);
  const { datos, domicilio } = useSelector(selectDatosPersonales);
  const {
    regimen,
    unidadesOrganizativas,
    avisoASuperior,
    observaciones,
    certificado
  } = form;

const formatPhone = () => {
  return `${domicilio.telefono.countryCode} ${domicilio.telefono.areaCode} ${domicilio.telefono.phoneNumber}`
}
  const parseDate = (date) => {
    const fechaFormateada = date.toDate
      ? moment(date.toDate()).format("DD/MM/YYYY")
      : moment(date).format("DD/MM/YYYY");
    return fechaFormateada;
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event as returning a string will prompt the user
      const message = '¿Seguro que quieres recargar la página?';
      event.returnValue = message;
      // Return the message for older browsers
      return message;
    };

    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures that the effect runs only once


  return (
    <>
      <Box bgcolor="#5836ff" p={2} my={2} color="white">
        <Typography variant="subtitle1">
          <TaskAltIcon sx={{ mr: 2 }} /> Revisá que la información sea la
          correcta
        </Typography>
      </Box>

      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        Información general de tu licencia
      </Typography>
      <Divider sx={{ border: "1px solid black", mb: 3 }} />

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Régimen:
        </Typography>
        <Typography>
          {data.regimenes.find((r) => r.code === regimen)?.description}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Tipo de Licencia:
        </Typography>
        <Typography>
          {form.tipoLicencia.description}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Licencia:
        </Typography>
        <Typography>{form.licencia.description}</Typography>
      </Box>

      <Box mb={1}>
        <Typography color="#5836ff" sx={{ fontWeight: "bold" }}>
          Unidad/es Organizativa/s:
        </Typography>
        {unidadesOrganizativas?.filter(
            (value, index, self) =>
              index === self.findIndex((v) => v.code === value.code)
          )
          ?.map((x) => (
          <Typography key={x.code}>
            {x.code} {x.description}
          </Typography>
        ))}
      </Box>
      {parameters.circuitoLicencia === "NACIMIENTO NG" && (
        <>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Información del Nacimiento
          </Typography>
          <Divider sx={{ border: "1px solid black", mb: 3 }} />
          <Box display="flex" flexDirection="row" mb={1}>
            <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
              Fecha de Nacimiento:
            </Typography>
            <Typography>{parseDate(form.fechaInicio)}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" mb={1}>
            <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
              Nacimiento Múltiple:
            </Typography>
            <Typography>
              {Number(form.cantidadHijos) === 1 ? "NO" : "SI"}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" mb={1}>
            <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
              Cantidad de hijos/as:
            </Typography>
            <Typography>{form.cantidadHijos}</Typography>
          </Box>
        </>
      )}
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Fecha de Inicio:
        </Typography>
        <Typography>{parseDate(form.fechaInicio)}</Typography>
      </Box>
      {form.fechaFin && (
        <Box display="flex" flexDirection="row" mb={1}>
          <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
            Fecha de Fin:
          </Typography>
          <Typography>
            {form.fechaFin ? parseDate(form.fechaFin) : ""}
          </Typography>
        </Box>
      )}
      {form.tipoLicencia?.code === 'ADM' && 
      <>
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Di aviso a mi superior:
        </Typography>
        <Typography>{avisoASuperior ? "Sí" : "No"}</Typography>
      </Box>
      </>}
      

      <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: "bold" }}>
        Información de contacto
      </Typography>
      <Divider sx={{ border: "1px solid black", mb: 3 }} />

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Correo electrónico:
        </Typography>
        <Typography>{datos.email}</Typography>
      </Box>

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Teléfono:
        </Typography>
        <Typography>{formatPhone()}</Typography>
      </Box>

      {certificado.length > 0 &&
      <>
       <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>
                Documentos Adjuntos
            </Typography>
            <Divider sx={{ border: "1px solid black", mb: 3 }} />
            <Box mb={1}>
                {certificado?.map(x =>
                    <>
                    <Typography key={x.id}>
                    <AttachFileIcon></AttachFileIcon>
                        {x.name}
                    </Typography>
                    </>
                )}
            </Box>

      </>}
      

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Observaciones:
        </Typography>
        <Typography>{observaciones || "Sin observaciones"}</Typography>
      </Box>

      <Box mt={2}>
        <FormControlLabel
          control={
            <Controller
              name="confirmacion"
              control={control}
              render={({ field: { onChange } }) => (
                <Checkbox
                  checked={form.confirmacion}
                  onChange={onChange}
                  sx={{ pt: 0 }}
                />
              )}
            />
          }
          label="Entiendo que esta solicitud tiene carácter de declaración jurada y confirmo que los datos ingresados son verdaderos."
          sx={{ alignItems: "flex-start" }}
        />
      </Box>
    </>
  );
}
