import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, IconButton, TablePagination, Tooltip } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { fetchAllAdmLicenciasAllHistoryAsync, fetchAllAdmLicenciasAsync } from "../../administrativeSuperiorSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { formatDate } from "../../../../utils";
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  CheckCircleOutline,
  Cancel,
  HelpOutline,
  Block,
} from "@mui/icons-material";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { LicStatusEnum } from "../../../../interfaces/LicStatusEnum";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const { allAdmHistoryLicences } = useSelector((state) => state.admSupLicence);
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1 || allAdmHistoryLicences?.length === 0 || allAdmHistoryLicences?.length < rowsPerPage}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function HistoryTable({ idhr , page , setPage , rowsPerPage , setRowsPerPage , filters }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allAdmHistoryLicences , totalCount} = useSelector((state) => state.admSupLicence);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      align: "center",
      sortable: false,
      flex: 1,
      maxWidth: 200,
      headerClassName: "bg-[#5836FF] text-white",
      renderCell: (params) => (
        <span
          onClick={() => navigate(`/administrative-superior/${params.row.id}?pageAct=${page}`)}
          className="underline hover:scale-125 duration-75 cursor-pointer text-xs text-center my-[10px]"
        >
          {params.row.id}
        </span>
      ),
    },
    {
      field: "title",
      headerName: "Licencia",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center mx-[10px] my-[10px]">
          {params.row.title}
        </span>
      ),
      flex: 1,
    },
    {
      field: "startDate",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Fecha Inicio",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs text-center">
          {formatDate(params.row.startDate)}
        </span>
      ),
      flex:1,
    },
    {
      field: "endDate",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Fecha Fin",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs text-center">
          {formatDate(params.row.endDate)}
        </span>
      ),
      flex:1,
    },
    {
      field: "statusId",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Estado",
      align: "center",
      sortable: false,
      flex:1,
      renderCell: (params) => (
        <Tooltip title={params.row.status}>
        <span>
          {params.row.statusId === 0 ? (
            <Block style={{ color: "gray" }} />
          ) : params.row.statusId === 1 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 2 ? (
            <PendingActionsIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 3 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 4 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 5 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ): params.row.statusId === 6 ? (
            <DoDisturbOnIcon style={{ color: "red" }} />
          ): params.row.statusId === 7 ? (
            <CheckCircleOutline style={{ color: "green" }} />
          ): params.row.statusId === 8 ? (
            <Cancel style={{ color: "red" }} />
          ): params.row.statusId === 9 ? (
            <Block style={{ color: "gray" }} />
          ): params.row.statusId === 10 ? (
            <CheckCircleOutline style={{ color: "green" }} />
          ): params.row.statusId === 11 ? (
            <Cancel style={{ color: "red" }} />
          ): params.row.statusId === 12 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ): params.row.statusId === 13 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ): params.row.statusId === 14 ? (
            <HelpOutline style={{ color: "orange" }} />
          ) :params.row.statusId === 15 ? (
            <HelpOutline style={{ color: "orange" }} />
          ):params.row.statusId === 16 ? (
            <CheckCircleOutline style={{ color: "green" }}/>
          ):params.row.statusId === 17 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ): null}
        </span>
        </Tooltip>
      ),
    },
  ];
  const handleChangePage = (event, newPage) => {
    const filterArray = [];
    for (const key in filters) {
      let value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        if (value === true) {
          value = "1";
        }
        if (key === "cuil") {
          value = value.replace(/[^0-9]/g, "");
        }
        filterArray.push({ code: key, description: value });
      }
    }
    setPage(newPage);
    dispatch(
      fetchAllAdmLicenciasAllHistoryAsync({
        id: idhr,
        filters: filterArray,
        pageNumber:newPage,pageSize:rowsPerPage
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      fetchAllAdmLicenciasAllHistoryAsync({
        id: idhr,
        filters: [],
        pageNumber:0,pageSize:parseInt(event.target.value, 10)
      })
    );
  };


  return (
    <div style={{ width: "100%", padding: "10px" }}>
      <div className="w-full flex">
        <TablePagination
          className="w-screen p-0 ml-auto"
          rowsPerPageOptions={[5, 10, 25,{ label: "All", value: totalCount }]}
          colSpan={8}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Licencias por pagina"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      <p className="flex w-[100px] mx-2 items-center">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[24px] h-[24px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
      </div>
          <div className="w-[100%]">
          {allAdmHistoryLicences?.length === 0 ? <p className="text-xl text-center mt-5">
        No existen licencias para los filtros seleccionados
      </p> : (
        <>
        <div className="hidden sm:block">
        <DataGrid
        className="border-0"
        getRowHeight={() => "auto"}
          hideFooterRowCount
          rows={allAdmHistoryLicences}
          columns={columns}
          hideFooterPagination
        />

        </div>
        <div className="sm:hidden flex flex-col justify-center items-center my-3 gap-3">
                {allAdmHistoryLicences?.map((card, index) => (
                  <div
                    className={`w-[90vw] border-2
                    } shadow-md p-3 rounded-md pb-4`}
                    key={index}
                  >
                    <span className="text-gray-500 font-medium mb-2">
                      ID #{card.id}
                    </span>
                    <p className="text-lg text-[#5836ff] font-medium mb-2">
                      {card.title}
                    </p>
                    <p className="flex flex-col mb-0 text-[#5836ff] font-medium">
                      ESTADO
                    </p>
                    <p className="p-1 rounded-sm shadow-sm border text-red-600 w-fit mb-2">
                      {Object.keys(LicStatusEnum)[card.statusId]}
                    </p>
                    <div className="flex justify-between mb-3">
                      <div>
                        <p className="flex flex-col mb-0 text-[#5836ff] font-medium">
                          INICIO
                        </p>
                        <p className="flex flex-col mb-0 text-gray-500">
                          {formatDate(card.startDate)}
                        </p>
                      </div>
                      <div className="mr-3 mt-1">
                        <p className="flex flex-col mb-0 text-[#5836ff] font-medium">
                          FIN
                        </p>
                        <p className="flex flex-col mb-0 text-gray-500">
                          {formatDate(card.endDate)}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-2 justify-between">
                      <Link
                        className="p-2 bg-[#5836ff] text-white px-3 rounded-md shadow-md no-underline"
                        to={`/administrative/${card.id}?pageAct=${page}`}
                      >
                        Abrir
                      </Link>
                      {/* <Checkbox checked={select.includes(card.id)} onClick={(e)=>{
                        e.stopPropagation();
                        if(select.includes(card.id)){
                          dispatch(removeSelect(card.id));
                        }else{
                          dispatch(addSelect(card.id))
                        }
                      }}></Checkbox> */}
                      {/* <button
                        key={card.id}
                        onClick={()=>handleButtonClick(card.id)}
                        className="p-2 bg-[#5836ff] text-white px-3 rounded-md shadow-md no-underline"
                      >
                        {select.includes(card.id)
                          ? "Deseleccionar"
                          : "Seleccionar"}
                      </button> */}
                    </div>
                  </div>
                ))}
              </div>
        </>
      )}
          </div>
          <div className="w-full flex">
      <TablePagination
        className="border-0 p-0 w-screen"
        rowsPerPageOptions={[5, 10, 25,{ label: "All", value: totalCount }]}
        colSpan={8}
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Licencias por pagina"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
                <p className="flex w-[100px] mx-2 items-center">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[24px] h-[24px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
    </div>
    </div>
  );
}

export default HistoryTable;
