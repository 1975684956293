import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuMobileHeader from "../../header/menu-mobile/index";
import { selectDatosPersonales } from "../../user/userSlice";
import { fetchOneLicenciaAsync } from "../licenseSlice";

export default function LicenseMenu({
  activeButton,
  handleButtonChange,
  openNotification,
  setActiveButton,
}) {
  const navigate = useNavigate();
  const handleSolicitarLicencia = () => {
    navigate("/license-request-form");
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (openNotification.open) {
      dispatch(fetchOneLicenciaAsync(openNotification.licenseId)).then((x) => {
        if (x.payload.tipoLicencia === "ADM") {
          setActiveButton("administrativas");
        } else if (
          x.payload.tipoLicencia === "MED" ||
          x.payload.tipoLicencia === "MEF" ||
          x.payload.tipoLicencia === "MEH" ||
          x.payload.tipoLicencia === "EyA"
        ) {
          setActiveButton("medicas");
        } else if (x.payload.tipoLicencia === "ART") {
          setActiveButton("art");
        }
      });
    }
  }, [openNotification]);

  return (
    <>
      {/* <MenuMobileHeader
        activeButton={activeButton}
        handleButtonChange={handleButtonChange}
        perfil={usuario.perfilActual.idPerfil}
      /> */}
      <Box className="licenseMenu">
        <div className="title-btn">
          <div style={{ display: "flow" }}>
            <Box component="span" className="licenseTitle">
              <Typography variant="h5" className="h5" color="#5836ff">
                Mis Licencias
              </Typography>
              <Typography
                variant="body1"
                className="p"
                color="#5836ff"
                style={{ fontWeight: "600" }}
              >
                Visualizá tu información
              </Typography>
            </Box>
          </div>
          
        </div>
        
        <Box className="licenseButtons" gap={1}>
          <Button
            name="abiertas"
            color="terciary"
            variant={activeButton === "abiertas" ? "contained" : "outlined"}
            className={`menuBtn abiertas ${
              activeButton === "abiertas" ? "text-white" : ""
            } `}
            onClick={() => handleButtonChange("abiertas")}
            style={{
              backgroundColor: activeButton === "abiertas" && "#b13dff",
            }}
          >
            Licencias Abiertas
          </Button>
          <Button
            name="medicas"
            color="terciary"
            variant={activeButton === "medicas" ? "contained" : "outlined"}
            className={`menuBtn ${
              activeButton === "medicas" ? "text-white" : ""
            } w-[140px]`}
            onClick={() => handleButtonChange("medicas")}
          >
            Médicas
          </Button>
          <Button
            name="administrativas"
            color="terciary"
            variant={
              activeButton === "administrativas" ? "contained" : "outlined"
            }
            className={`menuBtn administrativas ${
              activeButton === "administrativas" ? "text-white" : ""
            } `}
            onClick={() => handleButtonChange("administrativas")}
            style={{
              backgroundColor: activeButton === "administrativas" && "#ffb13d",
            }}
          >
            Administrativas
          </Button>
          <Button
            name="art"
            color="terciary"
            variant={activeButton === "art" ? "contained" : "outlined"}
            className={`menuBtn ${activeButton === "art" ? "text-white" : ""} `}
            onClick={() => handleButtonChange("art")}
            style={{ backgroundColor: activeButton === "art" && "#ff3d8b" }}
          >
            Contingencia ART
          </Button>
          <Button
            name="historial"
            color="terciary"
            variant={activeButton === "historial" ? "contained" : "outlined"}
            className={`menuBtn ${
              activeButton === "historial" ? "text-white" : ""
            } `}
            onClick={() => handleButtonChange("historial")}
            style={{
              backgroundColor: activeButton === "historial" && "#00bdb8",
            }}
          >
            Historial Licencias
          </Button>
          <Button
            variant="contained"
            className="menuBtn text-white"
            style={{ textTransform: "capitalize", backgroundColor: "#5836ff" }}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 30 26"
                fill="#8efff5"
              >
                <path
                  d="M13 7.5C13 6.94772 12.5523 6.5 12 6.5C11.4477 6.5 11 6.94772 11 7.5H13ZM11 16.5C11 17.0523 11.4477 17.5 12 17.5C12.5523 17.5 13 17.0523 13 16.5H11ZM16.5 13C17.0523 13 17.5 12.5523 17.5 12C17.5 11.4477 17.0523 11 16.5 11V13ZM7.5 11C6.94772 11 6.5 11.4477 6.5 12C6.5 12.5523 6.94772 13 7.5 13V11ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM11 7.5L11 12H13L13 7.5H11ZM11 12V16.5H13V12H11ZM16.5 11H12V13H16.5V11ZM12 11H7.5V13H12V11Z"
                  fill="#8efff5"
                />
              </svg>
            }
            onClick={handleSolicitarLicencia}
            sx={{ ml: 1 }}
          >
            Solicitar Licencia
          </Button>
        </Box>
      </Box>
    </>
  );
}
