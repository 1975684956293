import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  IconButton,
  TablePagination,
  Tooltip,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { fetchAllAuditLicenciasAsync } from "../auditSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {
  CheckCircleOutline,
  Cancel,
  HelpOutline,
  Block,
} from "@mui/icons-material";
import * as XLSX from 'xlsx';

function TablePaginationActions(props) {
  const { allLicences } = useSelector((state) => state.auditLicence);
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        aria-label="previous page"
        disabled={page === 0}
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1 || allLicences?.length === 0 || allLicences?.length < rowsPerPage}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function AuditTable({ page , rowsPerPage ,setPage , setRowsPerPage , filters}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { datos, perfilActual } = useSelector((state) => state.usuario);
  const { allLicences , totalCount} = useSelector((state) => state.auditLicence);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const pageAct = queryParams.get("pageAct");
  React.useEffect(() => {
    if (pageAct) {
      setPage(Number(pageAct));
    }
  }, [pageAct,setPage]);

  const columns = [
    {
      field: "a",
      headerName: "RAP",
      renderCell: (params) => (
        <span> {(params.row.a) === 1 ? <ReportProblemIcon style={{ color: "red" }}/> : null} </span>
      ),
      align: "center",
      sortable: false,
      headerClassName: "bg-[#5836FF] text-white",
      flex: 1,
      maxWidth: 50,
    },
    {
      field: "id",
      headerName: "ID",
      align: "center",
      sortable: false,
      flex: 1,
      maxWidth: 80,
      minWidth: 80,
      headerClassName: "bg-[#5836FF] text-white",
      renderCell: (params) => (
        <span
          onClick={() => navigate(`/audit/${params.row.id}?pageAct=${page}&licenseTypeId=6`)}
          className="underline hover:scale-125 duration-75 cursor-pointer text-xs text-center my-[10px]"
        >
          {params.row.id}
        </span>
      ),
    },
    {
      field: "idhr",
      headerName: "ID HR",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      maxWidth: 100,
      minWidth: 100,
      align: "center",
      renderCell: (params) => (
        <span className="text-center text-xs  my-[10px]">
          {params.row.idhr}
        </span>
      ),
      flex: 1,
    },
    {
      field: "cuit",
      headerName: "CUIL",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-center text-xs  my-[10px]">
          {`${params.row.cuit.toString().substring(0, 2)}-${params.row.cuit
            .toString()
            .substring(2, 10)}-${params.row.cuit.toString().substring(10)}`}
        </span>
      ),
      flex: 1,
      minWidth: 120,
    },
    {
      field: "lastName",
      headerName: "Apellido/s",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center  my-[10px]">
          {params.row.lastName.includes(" ")
            ? params.row.lastName.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.lastName}
        </span>
      ),
      flex: 1,
      minWidth: 90,
    },
    {
      field: "firstName",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Nombre/s",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center  my-[10px]">
          {params.row.firstName.includes(" ")
            ? params.row.firstName.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.firstName}
        </span>
      ),
      flex: 1,
      minWidth: 90,
    },
    {
      field: "title",
      headerName: "Licencia",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center mx-[10px] my-[10px]">
          {params.row.title}
        </span>
      ),
      flex: 1,
      minWidth: 90,
    },
    {
      field: "fechaDenuncia",
      headerName:         <div className="text-xs text-center w-full">
      Fecha <br />
      Denuncia
    </div>,
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {formatDate(params.row.fechaDenuncia)}
        </span>
      ),
    },
    {
      field: "fechaSiniestro",
      headerName: <div className="text-xs text-center w-full">
      Fecha <br />
      Siniestro
    </div>,
      align: "center",
      minWidth: 90,
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {formatDate(params.row.fechaSiniestro)}
        </span>
      ),
    },
    {
      field: "nroSiniestroOtorgado",
      headerName: <div className="text-xs text-center w-full">
      Nro <br />
      Provisorio
    </div>,
      sortable: false,
      minWidth: 90,
      align: "center",
      headerClassName: "bg-[#5836FF] text-white",
      flex: 1,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {params.row.nroSiniestroOtorgado}
        </span>
      ),
    },
    {
      field: "nroSiniestroDefinido",
      headerName: <div className="text-xs text-center w-full">
      Nro <br />
      Definitivo
    </div>,
      sortable: false,
      align: "center",
      minWidth: 110,
      headerClassName: "bg-[#5836FF] text-white",
      flex: 1,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {params.row.nroSiniestroDefinido}
        </span>
      ),
    },
    {
      field: "nroExpediente",
      headerName: (
        <div className="text-xs text-center w-full">
          Nro. <br />
          Expediente
        </div>
      ),
      sortable: false,
      minWidth: 90,
      headerClassName: "bg-[#5836FF] text-white",
      flex: 1,
      renderCell: (params) => (
        <span className="text-xs break-words text-center mx-[10px] my-[10px]">
          {params.row.nroExpediente?.includes("/")
            ? params.row.nroExpediente?.split("/").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.nroExpediente}
        </span>
      ),
    },
    {
      field: "statusId",
      minWidth: 90,
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Estado",
      align: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.status}>
          <span>
            {params.row.statusId === 0 ? (
              <Block style={{ color: "gray" }} />
            ) : params.row.statusId === 1 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 2 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 3 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 4 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 5 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 6 ? (
              <DoDisturbOnIcon style={{ color: "red" }} />
            ) : params.row.statusId === 7 ? (
              <CheckCircleOutline style={{ color: "green" }} />
            ) : params.row.statusId === 8 ? (
              <Cancel style={{ color: "red" }} />
            ) : params.row.statusId === 9 ? (
              <Block style={{ color: "gray" }} />
            ) : params.row.statusId === 10 ? (
              <CheckCircleOutline style={{ color: "green" }} />
            ) : params.row.statusId === 11 ? (
              <Cancel style={{ color: "red" }} />
            ) : params.row.statusId === 12 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 13 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 14 ? (
              <HelpOutline style={{ color: "orange" }} />
            ) : params.row.statusId === 15 ? (
              <HelpOutline style={{ color: "orange" }} />
            ) : params.row.statusId === 16 ? (
              <CheckCircleOutline style={{ color: "green" }} />
            ) : params.row.statusId === 17 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : null}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "tomadaPor",
      minWidth: 90,
      align: "center",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center">
          Tomada <br /> por
        </div>
      ),
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <span className="text-xs text-center my-2">
          {params.row.tomadaPor?.includes(" ")
            ? params.row.tomadaPor.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.tomadaPor}
        </span>
      ),
    },
  ];
  const handleChangePage = (event, newPage) => {
    const filterArray = [
      { code: "perfil", description: perfilActual.idPerfil },
    ];
    for (const key in filters) {
      let value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        if (value === true) {
          value = "1";
        }
        if (key === "cuil") {
          value = value.replace(/[^0-9]/g, "");
        }
        filterArray.push({ code: key, description: value });
      }
    }
    setPage(newPage);
    dispatch(
      fetchAllAuditLicenciasAsync({
        id: datos.id,
        filters: filterArray,
        pageNumber:newPage,pageSize:rowsPerPage
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      fetchAllAuditLicenciasAsync({
        id: datos.id,
        filters: [{ code: "perfil", description: perfilActual.idPerfil }],
        pageNumber:0,pageSize:parseInt(event.target.value, 10)
      })
    );
  };

  if (allLicences?.length === 0)
    return (
      <p className="text-xl text-center mt-5">
        No existen licencias para los filtros seleccionados
      </p>
    );
    
    const onExport = () => {
      const dataToExport = allLicences.map((row) => ({
        RAP: row.a === 1 ? "Inactividad RAP" : "",
        Id: row.id,
        IDHR: row.idhr,
        CUIL: row.cuit,
        Apellido: row.lastName,
        Nombre: row.firstName,
        Licencia: row.title,
        Denuncia: formatDate(row.fechaDenuncia),
        Siniestro: formatDate(row.fechaSiniestro),
        Otorgado: row.nroSiniestroOtorgado,
        Definido: row.nroSiniestroDefinido,
        Expediente: row.nroExpediente,
        Estado: row.status,
        Tomador: row.tomadaPor
      }));
    
      const properties = [];
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    
      const longs = [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20];
      longs.forEach((col) => {
        properties.push({ width: col });
      });
      worksheet["!cols"] = properties;
    
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Licencias");
      XLSX.writeFile(workbook, "LicenciasAuditor.xlsx");
    };

  return (
    <div style={{ width: "100%", padding: "10px" }}>
      <div className="relative w-full">
        <div className="flex w-full justify-between items-center flex-col sm:flex-row mb-2">
          <Button onClick={onExport} variant="contained">
            Exportar
          </Button>
          <TablePagination
            className="w-screen p-0 border-0"
            rowsPerPageOptions={[5, 10, 25,{ label: "All", value: totalCount }]}
            colSpan={8}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Licencias por pagina"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          <p className="flex w-[100px] mx-auto justify-between items-center my-auto">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[24px] h-[24px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
        </div>
        <div className="w-[100%]">
          {allLicences?.length === 0 ?       <p className="text-xl text-center mt-5">
        No existen licencias para los filtros seleccionados
      </p> :           <DataGrid
            className="border-0"
            getRowHeight={() => "auto"}
            hideFooterRowCount
            rows={allLicences}
            columns={columns}
            hideFooterPagination
          />}

        </div>

        <div className="flex w-full justify-between items-center flex-col sm:flex-row">
        <Button onClick={onExport} variant="contained" className="opacity-0 hidden" disabled>
            Exportar
          </Button>
          <TablePagination
            className="border-0 p-0 w-screen"
            rowsPerPageOptions={[5, 10, 25,{ label: "All", value: totalCount }]}
            colSpan={8}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Licencias por pagina"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          <p className="flex w-[100px] mx-auto items-center justify-between my-auto">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[24px] h-[24px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AuditTable;
