/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import loginImg from "../../../assets/img/login-logo.svg";
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync, selectDatosPersonales } from "../userSlice";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomAlert from "../../alert";
const theme = createTheme();

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = React.useState({ show: false, data: "" });
  const usuario = useSelector(selectDatosPersonales);
  const { loginWithRedirect } = useAuth0();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const queryStringManual = window.location.search;
  const urlParamsManual = new URLSearchParams(queryStringManual);
  const paramManual = urlParamsManual.get("code");

  useEffect(() => {
    const queryString = (process.env.REACT_APP_ENVIRONMENT == 'Development') ? "code=123" : window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const param1 = urlParams.get("code");
    if (param1) {
      dispatch(loginAsync({ code: param1 })).then((response) => {
        
        if (response.error) {
          setError({
            show: true,
            data: "Se produjo un error al intentar registrarte. Por favor, intentá nuevamente",
          });
        } else {
           
          if (
            response.payload.new ||
            !response.payload.userData?.datosPersonales
              ?.aceptaTerminosYCondiciones
          ) {
            saveTokenAfterLogin(response.payload.session)
            navigate("/registration");
						return
          } else if (response.payload.sesion?.token)
            saveTokenAfterLogin(response.payload.session)
            navigate("/select-profile");
        }
      });
    } else {
      loginWithRedirect();
    }
  }, []);

  const handleLogout = () => {
    loginWithRedirect();
  };
  const saveTokenAfterLogin=(sesion)=>{
    localStorage.setItem("userSession", JSON.stringify(sesion));
  }

  const manualLogin = () => {
    let values = getValues();
    values = { ...values, code: paramManual };
    dispatch(loginAsync(values)).then((x) => {
      if(x.error)
        {
          setError({
            show: true,
            data: "Se produjo un error al validar sus datos",
          });
        }
      if (!usuario.sesion.token) {
        setError({
          show: true,
          data: "Se produjo un error al validar sus datos",
        });
      } else {
        if (x.payload.new || !x.payload.userData?.datosPersonales?.aceptaTerminosYCondiciones) {
          saveTokenAfterLogin(x.payload.session)
          navigate("/registration");
					return
        } else if (x.payload.sesion.token);
        saveTokenAfterLogin(x.payload.sesion)
        navigate("/select-profile");
      }
    })
  };
  const handleErrorClose = () => {
    setError({ show: false });
  };
  return (
    <ThemeProvider theme={theme}>
      <div>
        {
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            textAlign="center"
            justifyContent="center"
            sx={{ minHeight: "90vh" }}
          >
            <span>
              <Grid item padding={5}>
                <img src={loginImg} width={300} />
              </Grid>
            </span>
            {usuario.sesion.invalidToken || error.data ? (
              <>
                <FormControl>
                  <Typography variant="label">CUIT</Typography>
                  <Controller
                    name="userName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField {...field} size="small" />
                    )}
                  />
                  {errors.userName && (
                    <Typography variant="label">
                      Debe ingresar un CUIT
                    </Typography>
                  )}
                </FormControl>
                <FormControl>
                  <Typography variant="label">Contraseña</Typography>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField {...field} type="password" size="small" />
                    )}
                  />
                  {errors.password && (
                    <Typography variant="label">
                      Debe ingresar la contraseña
                    </Typography>
                  )}
                </FormControl>
                <Box display="flex" maxWidth={250} flexDirection="column">
                  <Button
                    sx={{ mt: 2, mb: 2 }}
                    variant="contained"
                    onClick={handleSubmit(manualLogin)}
                  >
                    Inciar sesión
                  </Button>
                  <Button onClick={handleLogout}>
                    <ArrowBackIcon /> Volver e iniciar sesión con GCBA
                  </Button>
                </Box>
              </>
            ) : (
              <p>Obtiendo datos de su perfil...</p>
            )}
            <CustomAlert
              error={error}
              onClose={handleErrorClose}
              severity="error"
            ></CustomAlert>
          </Grid>
        }
      </div>
    </ThemeProvider>
  );
}
