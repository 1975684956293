import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ThemeProvider,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  createTheme,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Close as CloseIcon } from "@mui/icons-material";
import { formatDate } from "../../utils";
import "./styles.scss";
import { StatusActionEnums } from "../../interfaces/StatusActionEnum";
import api, { APPCONFIG } from "../../api";
import DocRespaldatoria from "./doc-respaldatoria";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchDocumentsAsync } from "./licenseSlice";
import { Localidades, Provincias } from "../user/registration/locations";
import { getDomicilioAsync } from "../user/userSlice";
export default function ModalDetails({
  openDetail,
  onClose,
  refreshData,
  license,
  setOpenNotification,
  setOpenDetail
}) {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState();

  const handleOpen = () => {
    setOpen(true);
  
    
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const dispatch = useDispatch();

  const validarFecha = () => {
    const isValid = new Date() >= new Date(license.startDate);
    return isValid;
  };

  useEffect(() => {
    dispatch(fetchDocumentsAsync(license.id)).then((x) => {
      if (x.payload) setFiles(x.payload);
    });
  }, [open]);

  const handleDownload = (x) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Files?fileId=${x.id}`;
    api.get(url, { responseType: "blob" }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", x.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };

  const theme = createTheme({
    typography: {
      fontFamily: "Arial, sans-serif",
      h1: {
        fontSize: 24,
        fontWeight: 500,
        color: "#5836ff",
      },
      h2: {
        fontSize: 16,
        fontWeight: 900,
        color: "#333333",
      },
      label: {
        fontWeight: 400,
        fontSize: 14,
        color: "#000",
      },
      p: {
        color: "#333333",
      },
    },
    palette: {
      primary: {
        main: "#556fff", // Cambia el color principal
      },
      secondary: {
        main: "#00D6D1", // Cambia el color secundario
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          // Personaliza el estilo de todos los botones
          root: {
            borderRadius: "4px", // Cambia el borde
            fontWeight: "bold", // Cambia el peso de la fuente
          },
        },
      },
    },
  });

  const [domicilio, setDomicilio] = useState(null);

  useEffect(() => {
    if (license.domicilioId) {
      dispatch(getDomicilioAsync(license.domicilioId)).then((res) => {
        if (res.type.includes("fulfilled")) {
          setDomicilio(res.payload);
        }
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
        <Dialog
          open={openDetail}
          fullWidth
          maxWidth="sm"
          scroll="body"
        >
          <DialogContent className="header-detail-modal" sx={{ px: 4 }}>
            <Box
              className="text-left"
              sx={{
                color: "#1d5a87",
                borderBottom: "solid 1px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <DialogTitle variant="h1">Detalle de solicitud</DialogTitle>
              <IconButton
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setOpenNotification({
                    open: false,
                    licenseId: null,
                  });
                  onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <List>
              <ListItem className="flex flex-col xs:flex-row !items-start">
                <Box className="col-6">
                  <Typography className="detail-label" variant="label">
                    ID
                  </Typography>
                  <Typography variant="h2" className="detail-value">
                    #{license.id}
                  </Typography>
                </Box>
                <Box className="col-6">
                  <Typography className="detail-label" variant="label">
                    ESTADO
                  </Typography>
                  <Typography variant="h2" className="detail-value uppercase">
                    {license.status}
                  </Typography>
                </Box>
              </ListItem>
              <ListItem className="flex flex-col xs:flex-row !items-start">
                <Box className="col-6">
                  <Typography variant="label" className="detail-label">
                    AUTORIZANTE
                  </Typography>
                  <Typography variant="h2" className="detail-value">
                    {license.autorizante ?? "N/A"}
                  </Typography>
                </Box>
                <Box className="col-6">
                  <Typography variant="label" className="detail-label">
                    FECHA Y HORA DE CREACIÓN
                  </Typography>
                  <Typography variant="h2" className="detail-value">
                    {formatDate(license.createDate, true)}{" "}
                  </Typography>
                </Box>
              </ListItem>
              <ListItem
                sx={{ my: 1 }}
                className="flex flex-col xs:flex-row !items-start"
              >
                <Typography
                  color={"#5836ff"}
                  style={{ fontWeight: 900, marginTop: "8px" }}
                >
                  INFORMACIÓN DE LICENCIA
                </Typography>
              </ListItem>
              <ListItem className="flex flex-col xs:flex-row !items-start">
                {license.regimen && (
                  <Box className="col-6">
                    <Typography variant="label" className="detail-label">
                      RÉGIMEN
                    </Typography>
                    <Typography variant="h2" className="detail-value">
                      {license.regimen}{" "}
                    </Typography>
                  </Box>
                )}
                <Box className="col-6">
                  <Typography variant="label" className="detail-label">
                    TIPO DE LICENCIA
                  </Typography>
                  <Typography variant="h2" className="detail-value">
                    {license.subtitle}{" "}
                  </Typography>
                </Box>
              </ListItem>
              <ListItem className="flex flex-col xs:flex-row !items-start">
                <Box>
                  <Typography variant="label" className="detail-label">
                    LICENCIA
                  </Typography>
                  <Typography variant="h2" className="detail-value">
                    {license.title}{" "}
                  </Typography>
                </Box>
              </ListItem>
              <ListItem className="flex flex-col xs:flex-row !items-start">
                {license.startDate !== "0001-01-01T00:00:00" && (
                  <Box className="col-6">
                    <Typography variant="label" className="detail-label">
                      {license.idNotificacion
                        ? license.tipoLicencia == "MEH"? "INICIO" : "SOLICITUD"
                        : "INICIO DEL AUSENTISMO LABORAL"}
                    </Typography>
                    <Typography variant="h2" className="detail-value">
                      {license.idNotificacion
                        ? formatDate(license.startDate, true)
                        : formatDate(license.startDate)}
                    </Typography>
                  </Box>
                )}
                {license.endDate !== "0001-01-01T00:00:00" && (
                  <Box className="col-6">
                    <Typography variant="label" className="detail-label">
                      {license.idNotificacion ? "VALIDEZ" : "FECHA FIN"}
                    </Typography>
                    <Typography variant="h2" className="detail-value">
                      {license.idNotificacion
                        ? formatDate(license.endDate, true)
                        : formatDate(license.endDate)}
                    </Typography>
                  </Box>
                )}
              </ListItem>
              <ListItem className="flex flex-col xs:flex-row !items-start">
                <Box>
                  <Typography variant="label" className="detail-label">
                    UNIDAD/ES ORGANIZATIVA/S
                  </Typography>
                  <Typography variant="h2" className="detail-value max-w-[300px]">
                    {license.unidades?.map((x, i) => (
                      <p key={i}>{x.code + " - " + x.description}</p>
                    ))}{" "}
                  </Typography>
                </Box>
              </ListItem>
              {(license.tipoLicencia === "MED" ||
                license.tipoLicencia === "MEF" ||
                license.tipoLicencia === "MEH") && (
                <>
                  <ListItem className="flex flex-col xs:flex-row !items-start">
                    <Box className="col-6">
                      <Typography variant="label" className="detail-label">
                        FECHA CERTIFICADO
                      </Typography>
                      <Typography variant="h2" className="detail-value">
                        {formatDate(license.fechaCertificado)}
                      </Typography>
                    </Box>
                    <Box className="col-6">
                      <Typography variant="label" className="detail-label">
                        DÍAS DEL CERTIFICADO
                      </Typography>
                      <Typography variant="h2" className="detail-value">
                        {license.diasCertificado}
                      </Typography>
                    </Box>
                  </ListItem>

                  <ListItem className="flex flex-col xs:flex-row !items-start">
                    <Box className="col-6">
                      <Typography variant="label" className="detail-label">
                        DIAGNOSTICO
                      </Typography>
                      <Typography variant="h2" className="detail-value">
                        {license.diagnostico}
                      </Typography>
                    </Box>
                    <Box className="col-6">
                      <Typography variant="label" className="detail-label">
                        ASIGNACIÓN
                      </Typography>
                      <Typography variant="h2" className="detail-value">
                        {license.asignacion ?? "-"}
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem className="flex flex-col xs:flex-row !items-start">
                    <Box className="col-6">
                      <Typography variant="label" className="detail-label">
                        DESTINO
                      </Typography>
                      <Typography variant="h2" className="detail-value">
                        {license.destino ?? "-"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="label" className="detail-label">
                        FECHA DE TURNO
                      </Typography>
                      <Typography variant="h2" className="detail-value">
                        {license.fechaDeTurno
                          ? formatDate(license.fechaDeTurno, true)
                          : "-"}
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem className="flex flex-col xs:flex-row !items-start">
                    <Box>
                      <Typography variant="label" className="detail-label">
                        OBSERVACIONES
                      </Typography>
                      <Typography variant="h2" className="detail-value">
                        {license.observaciones ?? "-"}
                      </Typography>
                    </Box>
                  </ListItem>
                  {license.motivoNoAprobacion && (
                    <ListItem className="flex flex-col xs:flex-row !items-start">
                      <Box>
                        <Typography variant="label" className="detail-label">
                          MOTIVO DE{" "}
                          {license.status === "ANULADA" ? "ANULACIÓN" : "RECHAZO"}
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license.motivoNoAprobacion}
                        </Typography>
                      </Box>
                    </ListItem>
                  )}
                  {domicilio && (
                    <>
                      <ListItem
                        className="flex flex-col xs:flex-row !items-start"
                        sx={{ my: 1 }}
                      >
                        <Typography
                          color={"#5836ff"}
                          style={{ fontWeight: 900, marginTop: "8px" }}
                        >
                          DOMICILIO DE REPOSO
                        </Typography>
                      </ListItem>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box className="col-6">
                          <Typography variant="label" className="detail-label">
                            CALLE
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {domicilio?.calle}{" "}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="label" className="detail-label">
                            ALTURA
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {domicilio?.altura}{" "}
                          </Typography>
                        </Box>
                      </ListItem>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box className="col-6">
                          <Typography variant="label" className="detail-label">
                            COD POSTAL
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {domicilio?.codPostal}{" "}
                          </Typography>
                        </Box>
                        <Box className="col-6">
                          <Typography variant="label" className="detail-label">
                            PROVINCIA
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {
                              Provincias.find(
                                (prov) => prov.id === domicilio?.codProvincia
                              )?.descripcion
                            }
                          </Typography>
                        </Box>
                      </ListItem>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box className="col-6">
                          <Typography variant="label" className="detail-label">
                            PISO
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {domicilio?.piso ?? "-"}{" "}
                          </Typography>
                        </Box>
                        <Box className="col-6">
                          <Typography variant="label" className="detail-label">
                            DEPTO
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {domicilio?.depto ?? "-"}{" "}
                          </Typography>
                        </Box>
                      </ListItem>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box className="col-6">
                          <Typography variant="label" className="detail-label">
                            LOCALIDAD
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {
                              Localidades.find(
                                (loc) =>
                                  loc.id === domicilio?.codLocalidad &&
                                  loc.idProvincia === domicilio?.codProvincia
                              )?.descripcion
                            }
                          </Typography>
                        </Box>
                        <Box className="col-6">
                          <Typography variant="label" className="detail-label">
                            OBSERVACIONES
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {domicilio?.observaciones ?? "-"}
                          </Typography>
                        </Box>
                      </ListItem>
                    </>
                  )}
                  <>
                    <ListItem
                      sx={{ my: 1 }}
                      className="flex flex-col xs:flex-row !items-start"
                    >
                      <Typography
                        color={"#5836ff"}
                        style={{ fontWeight: 900, marginTop: "8px" }}
                      >
                        DATOS DEL/LA MÉDICO/A
                      </Typography>
                    </ListItem>
                    <ListItem className="flex flex-col xs:flex-row !items-start">
                      <Box className="col-6">
                        <Typography variant="label" className="detail-label">
                          NOMBRE/S
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license.nombreMedico}{" "}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="label" className="detail-label">
                          APELLIDO/S
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license?.apellidoMedico}{" "}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem className="flex flex-col xs:flex-row !items-start">
                      <Box className="col-6">
                        <Typography variant="label" className="detail-label">
                          MATRÍCULA
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license?.tipoMatricula} {license.nombreMatricula}
                        </Typography>
                      </Box>
                      <Box className="col-6">
                        <Typography variant="label" className="detail-label">
                          ESPECIALIDAD
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license?.especialidadMedico}
                        </Typography>
                      </Box>
                    </ListItem>
                  </>
                </>
              )}
              {license.tipoLicencia === "ADM" && (
                <>
                  {license.motivoNoAprobacion && (
                    <ListItem className="flex flex-col xs:flex-row !items-start">
                      <Box>
                        <Typography variant="label" className="detail-label">
                          MOTIVO DE{" "}
                          {license.status === "ANULADA" ? "ANULACIÓN" : "RECHAZO"}
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license.motivoNoAprobacion}
                        </Typography>
                      </Box>
                    </ListItem>
                  )}
                  {license.comentarioAnular && (
                    <>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box>
                          <Typography variant="label" className="detail-label">
                            COMENTARIO DE{" "}
                            {license.status === "ANULADA"
                              ? "ANULACIÓN"
                              : "RECHAZO"}
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {license.comentarioAnular}
                          </Typography>
                        </Box>
                      </ListItem>
                    </>
                  )}
                </>
              )}

              {license.tipoLicencia === "EyA" && (
                <>
                  {license.motivoNoAprobacion && (
                    <ListItem className="flex flex-col xs:flex-row !items-start">
                      <Box>
                        <Typography variant="label" className="detail-label">
                          MOTIVO DE{" "}
                          {license.status === "ANULADA" ? "ANULACIÓN" : "RECHAZO"}
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license.motivoNoAprobacion}
                        </Typography>
                      </Box>
                    </ListItem>
                  )}
                  {license.comentarioAnular && (
                    <>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box>
                          <Typography variant="label" className="detail-label">
                            COMENTARIO DE{" "}
                            {license.status === "ANULADA"
                              ? "ANULACIÓN"
                              : "RECHAZO"}
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {license.comentarioAnular}
                          </Typography>
                        </Box>
                      </ListItem>
                    </>
                  )}
                  {license.fechaProbable && (
                    <ListItem className="flex flex-col xs:flex-row !items-start">
                      <Box className="col-6">
                        <Typography variant="label" className="detail-label">
                          FECHA PROBABLE DE PARTO
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {formatDate(license.fechaProbable)}{" "}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="label" className="detail-label">
                          DÍAS PREVIOS A LA FPP <br />
                          HASTA LOS CUALES PUEDO TRABAJAR
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {Math.ceil(
                            Math.abs(
                              new Date(license?.fechaProbable) -
                                new Date(license.startDate) + 1
                            ) /
                              (1000 * 60 * 60 * 24)
                          )}
                        </Typography>
                      </Box>
                    </ListItem>
                  )}
                  <>
                    <ListItem
                      className="flex flex-col xs:flex-row !items-start"
                      sx={{ my: 1 }}
                    >
                      <Typography
                        color={"#5836ff"}
                        style={{ fontWeight: 900, marginTop: "8px" }}
                      >
                        DATOS DEL/LA MÉDICO/A
                      </Typography>
                    </ListItem>
                    <ListItem className="flex flex-col xs:flex-row !items-start">
                      <Box className="col-6">
                        <Typography variant="label" className="detail-label">
                          NOMBRE/S
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license.nombreMedico}{" "}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="label" className="detail-label">
                          APELLIDO/S
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license?.apellidoMedico}{" "}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem className="flex flex-col xs:flex-row !items-start">
                      <Box className="col-6">
                        <Typography variant="label" className="detail-label">
                          MATRÍCULA
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license?.tipoMatricula} {license.nombreMatricula}
                        </Typography>
                      </Box>
                      <Box className="col-6">
                        <Typography variant="label" className="detail-label">
                          ESPECIALIDAD
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license?.especialidadMedico}
                        </Typography>
                      </Box>
                    </ListItem>
                  </>
                </>
              )}

              {license.tipoLicencia === "ART" && (
                <>
                  {license.horarioLaboralDesde && (
                    <>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box className="col-6">
                          <Typography variant="label" className="detail-label">
                            HORA SINIESTRO
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {
                              formatDate(license.horaSiniestro, true).split(
                                " "
                              )[1]
                            }
                          </Typography>
                        </Box>
                      </ListItem>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box className="col-6">
                          <Typography variant="label" className="detail-label">
                            HORARIO LABORAL DESDE
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {
                              formatDate(license.horarioLaboralDesde, true).split(
                                " "
                              )[1]
                            }
                          </Typography>
                        </Box>
                        <Box className="col-6">
                          <Typography variant="label" className="detail-label">
                            HORARIO LABORAL HASTA
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {
                              formatDate(license.horarioLaboralHasta, true).split(
                                " "
                              )[1]
                            }
                          </Typography>
                        </Box>
                      </ListItem>
                    </>
                  )}
                  <ListItem className="flex flex-col xs:flex-row !items-start">
                    <Box className="col-6">
                      <Typography variant="label" className="detail-label">
                        FECHA DE DENUNCIA
                      </Typography>
                      <Typography variant="h2" className="detail-value">
                        {formatDate(license.fechaDenuncia)}{" "}
                      </Typography>
                    </Box>
                    <Box className="col-6">
                      <Typography variant="label" className="detail-label">
                        FECHA DE SINIESTRO
                      </Typography>
                      <Typography variant="h2" className="detail-value">
                        {formatDate(license.fechaSiniestro)}{" "}
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem className="flex flex-col xs:flex-row !items-start">
                    <Box className="col-6">
                      <Typography variant="label" className="detail-label">
                        NRO SINIESTRO OTORGADO
                      </Typography>
                      <Typography variant="h2" className="detail-value">
                        {license.nroSiniestroOtorgado}{" "}
                      </Typography>
                    </Box>
                    <Box className="col-6">
                      <Typography variant="label" className="detail-label">
                        NRO SINIESTRO DEFINIDO
                      </Typography>
                      <Typography variant="h2" className="detail-value">
                        {license.nroSiniestroDefinido ?? "-"}{" "}
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem className="flex flex-col xs:flex-row !items-start">
                    <Box>
                      <Typography variant="label" className="detail-label">
                        NRO EXPEDIENTE
                      </Typography>
                      <Typography variant="h2" className="detail-value">
                        {license.nroExpediente ?? "-"}{" "}
                      </Typography>
                    </Box>
                  </ListItem>
                  {license.descripcionSiniestro && (
                    <ListItem className="flex flex-col xs:flex-row !items-start">
                      <Box>
                        <Typography variant="label" className="detail-label">
                          DESCRIPCIÓN
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license.descripcionSiniestro}{" "}
                        </Typography>
                      </Box>
                    </ListItem>
                  )}
                  {license.descripcion && (
                    <ListItem className="flex flex-col xs:flex-row !items-start">
                      <Box>
                        <Typography variant="label" className="detail-label">
                          DESCRIPCIÓN
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license.descripcion}{" "}
                        </Typography>
                      </Box>
                    </ListItem>
                  )}
                  {license.motivoNoAprobacion && (
                    <ListItem className="flex flex-col xs:flex-row !items-start">
                      <Box>
                        <Typography variant="label" className="detail-label">
                          MOTIVO DE{" "}
                          {license.status === "ANULADA" ? "ANULACIÓN" : "RECHAZO"}
                        </Typography>
                        <Typography variant="h2" className="detail-value">
                          {license.motivoNoAprobacion}
                        </Typography>
                      </Box>
                    </ListItem>
                  )}
                  {license.comentarioAnular && (
                    <>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box>
                          <Typography variant="label" className="detail-label">
                            COMENTARIO DE{" "}
                            {license.status === "ANULADA"
                              ? "ANULACIÓN"
                              : "RECHAZO"}
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {license.comentarioAnular}
                          </Typography>
                        </Box>
                      </ListItem>
                    </>
                  )}

                  {license.domicilioAccidente && (
                    <>
                      <ListItem
                        className="flex flex-col xs:flex-row !items-start"
                        sx={{ my: 1 }}
                      >
                        <Typography
                          color={"#5836ff"}
                          style={{ fontWeight: 900, marginTop: "8px" }}
                        >
                          DOMICILIO DEL ACCIDENTE
                        </Typography>
                      </ListItem>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box>
                          <Typography variant="label" className="detail-label">
                            CALLE
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {license.domicilioAccidente?.calle}{" "}
                          </Typography>
                        </Box>
                      </ListItem>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box>
                          <Typography variant="label" className="detail-label">
                            ALTURA
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {license.domicilioAccidente?.altura ?? "-"}{" "}
                          </Typography>
                        </Box>
                      </ListItem>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box>
                          <Typography variant="label" className="detail-label">
                            COD POSTAL
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {license.domicilioAccidente?.codPostal ?? "-"}{" "}
                          </Typography>
                        </Box>
                      </ListItem>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box>
                          <Typography variant="label" className="detail-label">
                            PROVINCIA
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {
                              Provincias.find(
                                (prov) =>
                                  prov.id ===
                                  license.domicilioAccidente?.codProvincia
                              )?.descripcion
                            }
                          </Typography>
                        </Box>
                      </ListItem>
                      <ListItem className="flex flex-col xs:flex-row !items-start">
                        <Box>
                          <Typography variant="label" className="detail-label">
                            LOCALIDAD
                          </Typography>
                          <Typography variant="h2" className="detail-value">
                            {
                              Localidades.find(
                                (loc) =>
                                  loc.id ===
                                    license.domicilioAccidente?.codLocalidad &&
                                  loc.idProvincia ===
                                    license.domicilioAccidente?.codProvincia
                              )?.descripcion
                            }
                          </Typography>
                        </Box>
                      </ListItem>
                    </>
                  )}
                </>
              )}
              {files?.length > 0 && (
                <>
                  <ListItem
                    className="flex flex-col xs:flex-row !items-start"
                    sx={{ my: 1 }}
                  >
                    <Typography
                      color={"#5836ff"}
                      style={{ fontWeight: 900, marginTop: "8px" }}
                    >
                      DOCUMENTOS ADJUNTOS
                    </Typography>
                  </ListItem>
                  <ListItem className="flex flex-col xs:flex-row !items-start">
                    <Box>
                      {files?.map((x, i) => (
                        <Box
                          key={i}
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Button onClick={(e) => handleDownload(x)}>
                            <DownloadIcon />
                            <Typography key={x.id}>
                              {x.name.length > 14
                                ? x.name.slice(0, 15) + "..."
                                : x.name}
                            </Typography>
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  </ListItem>
                </>
              )}
              {license.docRespaldatoriaPendiente &&
                (license.statusId === 2 || license.statusId === 18) && (
                  <Alert severity="warning" sx={{ width: "100%", mt: 2 }}>
                    Podrás cargar la documentación respaldatoria desde la fecha de
                    inicio de tu licencia
                  </Alert>
                )}
            </List>
            {license.docRespaldatoriaPendiente &&
            validarFecha() &&
            (license.statusId === 2 || license.statusId === 18) && (
              <DialogActions
                
                sx={{ display: "flex", justifyContent: "center", pb: 3 }}
              >
                <Button
                  onClick={handleOpen}
                  size="small"
                  color="success"
                  variant="contained"
                >
                  Para cargar la documentación respaldatoria ingresá aquí.
                </Button>
              </DialogActions>
            )}
          </DialogContent>
        
        
        </Dialog>
          <DocRespaldatoria
            refreshData={refreshData}
            open={open}
            creacion={false}
            handleClose={handleClose}
            licenseId={license.id}
          ></DocRespaldatoria>
    </ThemeProvider>
  );
}
