/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl } from "@mui/base";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectLicenses } from "../licenseSlice";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { Alert, Box, Checkbox, FormLabel, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getPregnancySettings, selectStaticData } from "../../parameters/parametersSlice";

export default function FechaParto({ control, setValue }) {
    const { pregnancySettings } = useSelector(selectStaticData);
    const dispatch = useDispatch();
    const { form } = useSelector(selectLicenses);
    const [error, setError] = React.useState();
    const [open, setOpen] = useState(false);

    const getDate = (days, startDate) => {
        const currentDate = startDate ?? moment(new Date());
        const result = currentDate.subtract(days, 'days')
        return result;
    }

    const getMaxDate = (days, startDate) => {
        const currentDate = startDate ?? moment(new Date());
        const result = currentDate.add(days, 'days')
        return result;
    }


    const getInitDate = () => {
        const currentDate = moment(new Date());
        const result = currentDate.add(pregnancySettings.days + 1, 'days')
        return result;
    }

    const validateDays = (days) => {
        const dayNumber = Number(days)
        if (dayNumber >= 15 && dayNumber <= pregnancySettings.days) {
            setError()
            return days;
        }
        else {
            setError(`La cantidad de días debe ser entre 15 y ${pregnancySettings.days} días`)
        }
    }

    useEffect(() => {
        dispatch(getPregnancySettings({ codRegimen: form.regimen }))
    }, []);

    useEffect(() => {
        const sumDay = pregnancySettings.days ? pregnancySettings.days -1 : 0
        const days = form.preparto ? form.diasPreparto : Number(sumDay)
        const startDate = getDate(days, form.fechaProbable)
        if (form.preparto == false || form.preparto == undefined)
            setValue("fechaInicio", startDate, { shouldTouch: true })
            
        else
            setValue("fechaInicio", getDate(form.diasPreparto - 1, form.fechaProbable) , { shouldTouch: true })
        
    }, [form.fechaProbable, form.diasPreparto, form.preparto]);

    useEffect(() => {
        
        if (!form.preparto){
            setValue("diasPreparto", pregnancySettings.days, { shouldTouch: true })
            setError()
        }
            
    }, [form.preparto, form.fechaProbable]);

    return (
        <>
            <FormControl id="input-fecha-certificado" fullWidth>
                <Box pt={2}>
                    <FormLabel sx={{ color: '#5836ff', fontWeight: 'bold' }}>Fecha probable de parto</FormLabel>
                    <Controller
                        name="fechaProbable"
                        control={control}
                        render={({ field: { onChange } }) => (
                            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    open={open}
                                    onClose={()=>setOpen(false)}
                                    defaultValue={form.fechaProbable ? dayjs(form.fechaProbable) : ''}
                                    renderInput={(props) => <TextField {...props} />}
                                    slotProps={{ textField: { size: "small", error: false, fullWidth: true, onClick: () => setOpen(true) } }}
                                    onChange={onChange}
                                    minDate={dayjs(getInitDate())}
                                    maxDate={dayjs(getMaxDate(pregnancySettings.days, getInitDate()))}
                                />
                            </LocalizationProvider>
                        )}
                    />
                </Box>

            </FormControl>

            {pregnancySettings.preparto &&
                <FormControl id="check-preparto" fullWidth>
                    <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'left' }}>
                        <Controller
                            name="preparto"
                            control={control}
                            render={({ field: { onChange } }) => (
                                <>
                                    <Box pt={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Checkbox
                                            checked={form.preparto}
                                            onChange={x => {
                                                onChange(x)
                                                if (!x.target.checked)
                                                    setValue("diasPreparto", null, { shouldTouch: true })
                                            }}
                                            sx={{ pt: 0 }}
                                        />
                                        <Typography variant="p">Tengo apto/a médico para reducir el periodo de preparto. <br />
                                        De no seleccionar la opción del período de preparto, la licencia iniciará conforme lo establece la normativa vigente.
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        />
                    </Box>
                </FormControl>

            }
            {form.preparto &&
                <FormControl id="input-diasPreparto" fullWidth>
                    <Box pt={1}>
                        <Controller
                            name="diasPreparto"
                            control={control}
                            render={({ field: { onChange } }) => (
                                <>
                                    <Typography variant="p">Hasta "x" días previos según prescripción médica. La reducción no podrá ser menor a 15.</Typography>
                                    <TextField
                                        fullWidth
                                        inputProps={{ min: 15, max: pregnancySettings.days }}
                                        variant="outlined"
                                        size="small"
                                        defaultValue={form.diasPreparto}
                                        onBlur={(e) => onChange(validateDays(form.diasPreparto))}
                                        onChange={(e) => onChange(validateDays(e.target.value))}
                                        type="number">
                                    </TextField>
                                </>
                            )}
                        />
                    </Box>
                </FormControl>

            }
            {error && (<Alert severity="warning">{error}</Alert>)}
            <FormControl fullWidth>
                <Box mt={2}>
                    <FormLabel sx={{ color: '#5836ff', fontWeight: 'bold' }}>Fecha de inicio de la licencia</FormLabel>
                    <Controller
                        name="fechaInicio"
                        control={control}
                        render={() => (
                            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disabled
                                    value={(form.fechaInicio)}
                                    renderInput={(props) => <TextField {...props} fullWidth />}
                                    slotProps={{ textField: { size: "small", error: false, fullWidth: true } }}
                                />
                            </LocalizationProvider>
                        )}
                    />
                </Box>

            </FormControl>
        </>
    );
}