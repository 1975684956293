import React from "react";
import { Box, Button, Container, FormControl, Modal, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDatosPersonales,
  setAreaCode,
  setCountryCode,
  setEmail,
  setPhoneNumber,
  checkEmail,
} from "../userSlice";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    minWidth:300,
    minHeight:300,
  };

export default function ContactData({ setEmailExist , errEmail , setErrEmail}) {
  const dispatch = useDispatch();
  const usuario = useSelector(selectDatosPersonales);
  const [modal,setModal] = React.useState(false)
  const validateTotalDigits = (areaCode, phoneNumber) => {
    const totalDigits = (areaCode + phoneNumber).length;
    return totalDigits > 10;
  };
  
  const handleChangeArea = (e) => {
    const regex = /^[1-8][0-9]*$/;
    const areaCode = e.target.value;
    const phoneNumber = usuario.domicilio?.telefono?.phoneNumber || '';
    if (areaCode.length > 4 ) return;
    if(validateTotalDigits(areaCode, phoneNumber)) {
      alert("La suma del Código de Área + Nro de Teléfono no puede superar los 10 dígitos") 
    }
    if (areaCode === "" || regex.test(areaCode)) {
      dispatch(setAreaCode(areaCode));
    } else {
      alert("Solo se permiten números y el número no puede comenzar con 0 o 9");
    }
  };
  
  const handleChangeContacto = (e) => {
    const regex = /^(?!15)[1-9][0-9]*$/;
    const phoneNumber = e.target.value;
    const areaCode = usuario.domicilio?.telefono?.areaCode || '';
    if (phoneNumber.length > 8 || validateTotalDigits(areaCode, phoneNumber)) return;
    if (phoneNumber === "" || regex.test(phoneNumber)) {
      dispatch(setPhoneNumber(phoneNumber));
    } else {
      alert("Solo se permiten números y el número no puede comenzar con 0 o 15");
    }
  };

  const handleChangeEmail = (e) => {
    setErrEmail(false);
      dispatch(setEmail(e.target.value));
  };

  const handleBlurEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = emailRegex.test(usuario.datos?.email);
    if (usuario.datos?.email && !isValidEmail) {
      setErrEmail(true);
    } else {
      setErrEmail(false);
    }
  
    // Luego de la validación, puedes llamar a checkEmail aquí si es necesario
    checkEmail(usuario.datos?.email,usuario.datos?.idHR).then((res) => {
      setEmailExist(res);
      if (res === true) {
        setModal(true);
      }
    });
  };

  return (
    <React.Fragment>
      <Container sx={{ width: 0.7, py: 5 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pb: 2,
          }}
        >
          <FormControl fullWidth>
            <label
              htmlFor="email"
              style={{ color: "#5836ff", fontWeight: "bold", paddingLeft: 3 }}
            >
              Correo Electrónico
            </label>
            <TextField
              required
              id="email"
              value={usuario.datos?.email}
              size="small"
              placeholder="Email"
              onChange={(e)=>{
                if(e.target.value.length > 100){
                  return
                }
                handleChangeEmail(e)}}
                onBlur={handleBlurEmail}
            />
          </FormControl>
          {errEmail && (<Typography sx={{ color: "#Ff0000" ,mt:1}}>
                        Formato invalido
                      </Typography>)}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            pb: 2,
          }}
        >
          <FormControl
            sx={{
              maxWidth: "70px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <label
              htmlFor="countryCode"
              style={{ color: "#5836ff", fontWeight: "bold", paddingLeft: 3 }}
            >
              País
            </label>
            <TextField
              required
              id="countryCode"
              value={"+54"}
              disabled
              size="small"
              placeholder="+54"
            />
          </FormControl>
          <FormControl
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <label
              htmlFor="areaCode"
              style={{ color: "#5836ff", fontWeight: "bold", paddingLeft: 3 }}
            >
              Código Área
            </label>
            <TextField
              required
              id="areaCode"
              value={usuario.domicilio?.telefono?.areaCode}
              size="small"
              placeholder="11"
              onChange={handleChangeArea}
            />
          </FormControl>
        </Box>
        <FormControl sx={{ display: "flex", justifyContent: "space-between" }}>
          <label
            htmlFor="phoneNumber"
            style={{ color: "#5836ff", fontWeight: "bold", paddingLeft: 3 }}
          >
            Teléfono de Contacto
          </label>
          <TextField
            required
            id="phoneNumber"
            value={usuario.domicilio?.telefono?.phoneNumber}
            size="small"
            type="text"
            placeholder="12345678"
            onChange={handleChangeContacto}
          />
        </FormControl>
      </Container>
      <Modal
            open={modal}
            onClose={() => setModal(false)}
            sx={{...style}}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            
          >
            <Box className="box-modal">
              <p className="modal-contain">El correo electrónico {usuario?.datos.email} ya fue registrado en el perfil de otro/a empleado/a. Si te pertenece, escribe a foco_comunicacion@buenosaires.gob.ar con tus datos, teléfono y correo para realizar la denuncia correspondiente.</p>
                <Button variant="contained" onClick={()=>setModal(false)} className="mx-auto">
                  Aceptar
                </Button>
            </Box>
          </Modal>
    </React.Fragment>
  );
}
