import { Add } from "@mui/icons-material";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import FocoFilters from "./foco-filters";
import FocoTable from "./foco-table";
import { Link } from "react-router-dom";
import Menu from "./foco-menu";
import { useDispatch } from "react-redux";
import { verificarObjetos } from "../../utils";
import { fetchPublicacionesRRHH } from "./focoSlice";

const initialFilters = {
  publicationtype: "",
  category: "",
  title: "",
  status: "",
  cdesde: null,
  chasta: null,
  pdesde: null,
  phasta: null,
};

function Foco() {
  const [filters, setFilters] = React.useState(initialFilters);
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const handleBuscar = () => {
    const filterArray = [];
    for (const key in filters) {
      const value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        filterArray.push({ code: key, description: value });
      }
    }
    dispatch(fetchPublicacionesRRHH({ filters: filterArray , pageNumber : 0 , pageSize:rowsPerPage})).then(x=>setPage(0));
  };

  const handleReset = () => {
    setFilters(initialFilters);
    dispatch(fetchPublicacionesRRHH({ filters: [] , pageNumber:0 , pageSize: rowsPerPage })).then(x=>{
      setPage(0)
    });
  };

  useEffect(()=>{
    dispatch(fetchPublicacionesRRHH({ filters: [] , pageNumber : page , pageSize:rowsPerPage}));
  },[])

    return (
      <Box component="main" className="d-flex flex-column gap-4">
        <Menu title="FOCO" />
        <FocoFilters filters={filters} setFilters={setFilters} />
        <Box className="d-flex justify-content-center w-100 gap-2">
          <Button variant="contained" onClick={handleBuscar}>
            Buscar
          </Button>
          <Button
            variant="outlined"
            disabled={verificarObjetos(filters, initialFilters)}
            onClick={handleReset}
          >
            Limpiar
          </Button>
        </Box>
        <FocoTable filters={filters}           page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}/>
      </Box>
    );
  }

export default Foco;
