import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StatusActionEnums } from "../../../interfaces/StatusActionEnum";
import { LicStatusEnum } from "../../../interfaces/LicStatusEnum";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
function AdministrativeSuperiorFilters({
  filters,
  setFilters,
  autorizadasPor,
  autocompleteRef,
  disabled,
  handleBuscar,
  handleReset,
}) {
  const handleChange = (event) => {
    switch (event.target.name) {
      case "id":
        setFilters({ ...filters, id: event.target.value });
        break;
      case "cuit":
        setFilters({ ...filters, cuit: event.target.value });
        break;
      case "firstName":
        setFilters({ ...filters, firstName: event.target.value });
        break;
      case "lastName":
        setFilters({ ...filters, lastName: event.target.value });
        break;
      case "subtitle":
        setFilters({ ...filters, subtitle: event.target.value });
        break;
      case "unidades":
        setFilters({ ...filters, unidades: event.target.value });
        break;
      case "status":
        setFilters({ ...filters, status: event.target.value });
        break;
      default:
        break;
    }
  };

  const handleChangeUO = (data) => {
    if (data) setFilters({ ...filters, unidades: data.unidadOrganizativa });
    else {
      delete filters.unidades;
      setFilters({ ...filters });
    }
  };

  const handleChangeDates = (action, event) => {
    switch (action) {
      case "startDate":
        setFilters({ ...filters, startDate: event });
        break;
      case "endDate":
        setFilters({ ...filters, endDate: event });
        break;
      default:
        break;
    }
  };

  const handleOnChange = (e) => {
    const regex = /^[0-9]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="p-3 hidden sm:flex gap-4 justify-content-start flex-wrap">
        <TextField
          name="id"
          value={filters.id}
          sx={{ width: "165px" }}
          size="small"
          id="outlined-basic"
          label="ID Licencia"
          variant="outlined"
          onChange={(e) => {
            if (handleOnChange(e)) handleChange(e);
          }}
        />
        <TextField
          name="cuit"
          value={filters.cuit}
          sx={{ width: "165px" }}
          size="small"
          id="outlined-basic"
          label="Cuil"
          variant="outlined"
          onChange={handleChange}
        />
        <TextField
          name="firstName"
          value={filters.firstName}
          sx={{ width: "165px" }}
          size="small"
          id="outlined-basic"
          label="Nombre"
          variant="outlined"
          onChange={handleChange}
        />
        <TextField
          name="lastName"
          value={filters.lastName}
          sx={{ width: "165px" }}
          size="small"
          id="outlined-basic"
          label="Apellido"
          variant="outlined"
          onChange={handleChange}
        />
        <TextField
          name="subtitle"
          value={filters.subtitle}
          sx={{ width: "165px" }}
          size="small"
          id="outlined-basic"
          label="Licencia"
          variant="outlined"
          onChange={handleChange}
        />
        <Autocomplete
          sx={{ width: "165px" }}
          freeSolo
          name="unidades"
          ref={autocompleteRef}
          size="small"
          options={autorizadasPor}
          getOptionLabel={(option) => option.descUnidadOrganizativa}
          onChange={(event, newValue) => {
            handleChangeUO(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Unidad Organizativa" />
          )}
        />

        <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(e) => handleChangeDates("startDate", e)}
            sx={{ width: "165px" }}
            slotProps={{ textField: { size: "small" } }}
            className="datepicker"
            value={filters.startDate}
            label="Desde"
          />
          <DatePicker
            onChange={(e) => handleChangeDates("endDate", e)}
            sx={{ width: "165px" }}
            slotProps={{ textField: { size: "small" } }}
            className="datepicker"
            label="Hasta"
            value={filters.endDate}
          />
        </LocalizationProvider>
        <FormControl size="small" sx={{ width: "165px" }}>
          <InputLabel id="demo-simple-select-label">Estado</InputLabel>
          <Select
            name="status"
            value={filters.status}
            label="Estado"
            onChange={handleChange}
          >
            <MenuItem value={""}>Quitar Filtro</MenuItem>
            <MenuItem value={`${LicStatusEnum.AUTORIZADA}`}>
              Autorizada
            </MenuItem>
            <MenuItem value={`${LicStatusEnum.RECHAZADA}`}>Rechazada</MenuItem>
            <MenuItem value={`${LicStatusEnum.EN_REVISION}`}>
              En Revisión
            </MenuItem>
            <MenuItem
              value={`${LicStatusEnum.CARGA_DOC_RESPALDATORIA_PENDIENTE}`}
            >
              Doc. Respaldatoria a Cargar
            </MenuItem>
            <MenuItem value={`${LicStatusEnum.INGRESADA_PENDIENTE_COMPLETAR}`}>
              Pendiente a Completar
            </MenuItem>
            <MenuItem value={`${LicStatusEnum.ANULADA}`}>Anulada</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="block my-3 sm:hidden mx-[20px]">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: "#fff" }}
            className="bgaccordeon"
          >
            <Typography>FILTROS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              name="id"
              value={filters.id}
              fullWidth
              size="small"
              id="outlined-basic"
              label="ID Licencia"
              variant="outlined"
              onChange={(e) => {
                if (handleOnChange(e)) handleChange(e);
              }}
            />
          </AccordionDetails>
          <AccordionDetails>
            <TextField
              name="cuit"
              value={filters.cuit}
              fullWidth
              size="small"
              id="outlined-basic"
              label="Cuil"
              variant="outlined"
              onChange={handleChange}
            />
          </AccordionDetails>
          <AccordionDetails>
            <TextField
              name="firstName"
              value={filters.firstName}
              fullWidth
              size="small"
              id="outlined-basic"
              label="Nombre"
              variant="outlined"
              onChange={handleChange}
            />
          </AccordionDetails>
          <AccordionDetails>
            <TextField
              name="lastName"
              value={filters.lastName}
              fullWidth
              size="small"
              id="outlined-basic"
              label="Apellido"
              variant="outlined"
              onChange={handleChange}
            />
          </AccordionDetails>
          <AccordionDetails>
            <TextField
              name="subtitle"
              value={filters.subtitle}
              fullWidth
              size="small"
              id="outlined-basic"
              label="Licencia"
              variant="outlined"
              onChange={handleChange}
            />
          </AccordionDetails>
          <AccordionDetails>
            <Autocomplete
              fullWidth
              freeSolo
              name="unidades"
              ref={autocompleteRef}
              size="small"
              options={autorizadasPor}
              getOptionLabel={(option) => option.descUnidadOrganizativa}
              onChange={(event, newValue) => {
                handleChangeUO(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Unidad Organizativa" />
              )}
            />
          </AccordionDetails>
          <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
            <AccordionDetails>
              <DatePicker
                onChange={(e) => handleChangeDates("startDate", e)}
                fullWidth
                slotProps={{ textField: { size: "small" } }}
                className="datepicker w-full"
                value={filters.startDate}
                label="Desde"
              />
            </AccordionDetails>
            <AccordionDetails>
              <DatePicker
                onChange={(e) => handleChangeDates("endDate", e)}
                fullWidth
                slotProps={{ textField: { size: "small" } }}
                className="datepicker w-full"
                label="Hasta"
                value={filters.endDate}
              />
            </AccordionDetails>
            <AccordionDetails>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                  name="status"
                  value={filters.status}
                  label="Estado"
                  onChange={handleChange}
                >
                  <MenuItem value={""}>Quitar Filtro</MenuItem>
                  <MenuItem value={`${LicStatusEnum.AUTORIZADA}`}>
                    Autorizada
                  </MenuItem>
                  <MenuItem value={`${LicStatusEnum.RECHAZADA}`}>
                    Rechazada
                  </MenuItem>
                  <MenuItem value={`${LicStatusEnum.EN_REVISION}`}>
                    En Revisión
                  </MenuItem>
                  <MenuItem
                    value={`${LicStatusEnum.CARGA_DOC_RESPALDATORIA_PENDIENTE}`}
                  >
                    Doc. Respaldatoria a Cargar
                  </MenuItem>
                  <MenuItem
                    value={`${LicStatusEnum.INGRESADA_PENDIENTE_COMPLETAR}`}
                  >
                    Pendiente a Completar
                  </MenuItem>
                  <MenuItem value={`${LicStatusEnum.ANULADA}`}>
                    Anulada
                  </MenuItem>
                </Select>
              </FormControl>
            </AccordionDetails>
            <AccordionDetails>
              <Box className="d-flex justify-content-center w-100 gap-2">
                <Button variant="contained" onClick={handleBuscar}>
                  Buscar
                </Button>
                <Button
                  disabled={disabled}
                  variant="outlined"
                  onClick={handleReset}
                >
                  Limpiar
                </Button>
              </Box>
            </AccordionDetails>
          </LocalizationProvider>
        </Accordion>
      </div>
    </>
  );
}

export default AdministrativeSuperiorFilters;
