/* eslint-disable react-hooks/exhaustive-deps */
import { Box, FormControl, InputLabel, MenuItem, Select, TextField , Button } from "@mui/material";
import React from "react";
import { verificarObjetos } from "../../../../utils";

function Index({filters,setFilters,handleReset,handleBuscar,initialFilters}) {

  React.useEffect(() => {
    if (filters.publicationtype !== "Novedades") {
      setFilters({ ...filters, category: "" });
    }
  }, [filters.publicationtype]);

  const handleChange = (event) => {
    switch (event.target.name) {
      case "tipo":
        setFilters({ ...filters, publicationtype: event.target.value });
        break;
      case "categoria":
        setFilters({ ...filters, category: event.target.value });
        break;
      case "titulo":
        setFilters({ ...filters, title: event.target.value });
        break;
      default:
        break;
    }
  };

  return (
    <div className="px-5 flex flex-col sm:flex-row gap-3 justify-between">
    <Box className="d-flex gap-3 flex-wrap">
      <FormControl size="small" className="w-full sm:w-[200px]">
        <InputLabel id="demo-simple-select-label">
          Sección
        </InputLabel>
        <Select
          name="tipo"
          label="Tipo de Publicación"
          value={filters.publicationtype}
          onChange={handleChange}
        >
          <MenuItem value={""}>Quitar Filtro</MenuItem>
          <MenuItem value={"Novedades"}>Novedades</MenuItem>
          <MenuItem value={"Procedimientos"}>Procedimientos</MenuItem>
          <MenuItem value={"Comunicados"}>Comunicados</MenuItem>
        </Select>
      </FormControl>
      <FormControl disabled={filters.publicationtype !== "Novedades"} size="small" className="w-full sm:w-[200px]">
        <InputLabel id="demo-simple-select-label">
          Categoría
        </InputLabel>
        <Select
          name="categoria"
          value={filters.category}
          label="Categoría"
          onChange={handleChange}
        >
          <MenuItem value={""}>Quitar Filtro</MenuItem>
          <MenuItem value={"Concursos"}>Concursos</MenuItem>
          <MenuItem value={"Datos Útiles"}>Datos Útiles</MenuItem>
          <MenuItem value={"Fecha de cobro Haberes"}>
           Fecha de cobro Haberes
          </MenuItem>
          <MenuItem value={"Feriados/Festividades"}>
          Feriados/Festividades
          </MenuItem> 
        </Select>
      </FormControl>
      <TextField
        name="titulo"
        value={filters.title}
        className="w-full sm:w-[300px]"
        size="small"
        id="outlined-basic"
        label="Título"
        variant="outlined"
        onChange={handleChange}
      />
    </Box>
    <Box className="flex gap-2 w-full justify-center sm:w-fit">
      <Button variant="outlined" onClick={handleBuscar}>Buscar</Button>
      <Button variant="outlined" color="warning" disabled={verificarObjetos(filters, initialFilters)} onClick={handleReset}>Limpiar</Button>
    </Box>
    </div>
  );
}

export default Index;
