import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  selectDocuments,
  documentsMobile,
  setDocumentsMobile,
  setDNIFamiliarMobile,
} from "../../../../files/filesSlice";
import { Button, ButtonBase } from "@mui/material";
import { postCertificadoMobile } from "../../../licenseSlice";
import { selectDatosPersonales } from "../../../../user/userSlice";
const videoConstraints = {
  width: 540,
  facingMode: "environment",
};
function obtenerFechaHoraActual() {
  const ahora = new Date();
  const dia = ahora.getDate().toString().padStart(2, "0");
  const mes = (ahora.getMonth() + 1).toString().padStart(2, "0"); // Los meses van de 0 a 11
  const año = ahora.getFullYear();
  const horas = ahora.getHours().toString().padStart(2, "0");
  const minutos = ahora.getMinutes().toString().padStart(2, "0");
  const segundos = ahora.getSeconds().toString().padStart(2, "0");

  const fechaHoraActual = `${año}-${mes}-${dia} ${horas}-${minutos}-${segundos}.jpeg`;
  return fechaHoraActual;
}
const Camera = ({ setOpenCamera , setError ,  cameraStream , setCameraStream , requestCameraPermission }) => {
  const { dniFamiliarMobile, dniFamiliar } = useSelector(selectDocuments);
  const { datos } = useSelector(selectDatosPersonales);
  const webcamRef = useRef(null);

  const dispatch = useDispatch();
  const [captura, setCaptura] = useState(null);
  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCaptura(imageSrc);
  }, [webcamRef]);

  const confirm = () => {
    let photo = {
      CUIT: datos.cuit.toString(),
      Key: "dniFamiliar",
      Name: obtenerFechaHoraActual(),
      Image: captura.split(",")[1],
      UserId: datos.id,
      SendMeta4: false,
    };
    const files = [...dniFamiliarMobile, photo];
    const filesLenght = [...files, ...dniFamiliar];
    if (filesLenght.length > 2) {
      setError({
        show: true,
        data: `Solo se pueden adjuntar hasta 2 archivos como máximo.`,
      });
      return;
    }
    dispatch(setDNIFamiliarMobile(files));
    setOpenCamera(false);
  };

  return (
    <>
      <div className="m-4 flex justify-center items-center flex-col">
        <button className="absolute top-1 right-1 px-[6px] text-sm z-50 rounded-full bg-[#5836ff] text-white" onClick={()=>setOpenCamera(false)}>x</button>
        {captura ? (
          <>
            <img
              src={captura}
              alt="captura"
              className="w-[540px] object-cover"
            />
                        <Button
              sx={{ mt: 1 }}
              onClick={() => {setCaptura(null)}}
            >
              Intentar de Nuevo
            </Button>
          </>
        ) :
          <>
            <Webcam
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              mirrored={false}
            />{" "}
            <Button
              sx={{ mt: 1 }}
              onClick={capture}
            >
              Capturar
            </Button>
          </>
        }

        {captura && (
          <>
            <Button onClick={confirm}>Confirmar</Button>
          </>
        )}
      </div>
    </>
  );
};

export default Camera;
