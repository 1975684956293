import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api, { APPCONFIG } from "../../api";

const initialState = {
  allPublicaciones: [],
  allRRHHPublicaciones: [],
  onePublicacion: {},
  totalCount: 0,
  history: [],
  sucessCases: {
    created: null,
    updated: null,
    deleted: null,
    getOne: null,
    getAll: null,
  },
};

export const fetchAllPublicacionesAsync = createAsyncThunk(
  "foco/getAllPublicacionesAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Foco/Publicaciones/all?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
    const response = await api.post(url, {
      filter: data.filters,
    });
    return response.data;
  }
);

export const fetchPublicacionesRRHH = createAsyncThunk(
  "foco/fetchPublicacionesRRHH",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Foco/Publicaciones/rrhh?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
    const response = await api.post(url, {
      filter: data.filters,
    });
    return response.data;
  }
);


export const fetchOnePublicacionAsync = createAsyncThunk(
  "foco/getOnePublicacionAsync",
  async (id) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Foco/Publicaciones?id=${id}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const deleteOnePublicacionAsync = createAsyncThunk(
  "foco/deleteOnePublicacionAsync",
  async (id) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Foco/${id}`;
    const response = await api.delete(url);
    return response.data;
  }
);

export const postOnePublicacionAsync = createAsyncThunk(
  "foco/postOnePublicacionAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Foco/Crear?userId=${data.userId}`;
    const response = await api.post(url, data.formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }
);

export const fetchHistoryPublicacionLicenseById = createAsyncThunk(
  "auditLicense/fetchHistoryPublicacionLicenseById",
  async (publicacionId) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/History/Publicaciones?publicacionId=${publicacionId}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const updateOnePublicacionAsync = createAsyncThunk(
  "foco/updateOnePublicacionAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Foco/Update?userId=${data.userId}`;
    const response = await api.put(url, data.formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }
);

export const postFocoFiles = createAsyncThunk(
  "foco/postFocoFiles",
  async (data, { rejectWithValue }) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Files/PublicacionFiles`;
    try {
      const key = data.get("key");
      const response = await api.post(url, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return { files: response.data, key };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFocoFiles = createAsyncThunk(
  "foco/updateFocoFiles",
  async (data, { rejectWithValue }) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Files/PublicacionFiles`;
    try {
      const key = data.get("key");
      const response = await api.put(url, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return { files: response.data, key };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFocoFiles = createAsyncThunk(
  "foco/deleteFocoFiles",
  async (data, { rejectWithValue }) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Files/Delete`;
    try {
      const response = await api.post(url, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const putOnePublicacionAsync = createAsyncThunk(
  "foco/putOnePublicacionAsync",
  async (formData) => {
    const { id } = formData;
    const url = `${APPCONFIG.APIs.LicenseAPI}/Foco/Publicaciones/${id}`;
    const response = await api.put(url, formData);
    return response.data;
  }
);

export const eliminarOnePublicacionAsync = createAsyncThunk(
  "foco/eliminarOnePublicacionAsync",
  async (id) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Foco?id=${id}`;
    const response = await api.delete(url);
    return response.data;
  }
);

export const fetchLastId = createAsyncThunk(
  "foco/fetchLastId",
  async () => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Foco/lastId`;
    const response = await api.get(url);
    return response.data;
  }
);

export const focoSlice = createSlice({
  name: "foco",
  initialState,
  reducers: {
    removeHistory: (state) => {
      state.history = [];
    },
    removeOnePublicacion: (state) => {
      state.onePublicacion = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPublicacionesAsync.fulfilled, (state, action) => {
        state.allPublicaciones = action.payload.publicaciones;
        state.totalCount = action.payload.totalCount;
        state.sucessCases.getAll = true;
      })
      .addCase(fetchPublicacionesRRHH.fulfilled, (state, action) => {
        state.allRRHHPublicaciones = action.payload.publicaciones;
        state.totalCount = action.payload.totalCount;
        state.sucessCases.getAll = true;
      })
      .addCase(fetchAllPublicacionesAsync.rejected, (state, action) => {
        state.sucessCases.getAll = false;
      })
      .addCase(fetchPublicacionesRRHH.rejected, (state, action) => {
        state.sucessCases.getAllRRHH = false;
      })
      .addCase(fetchOnePublicacionAsync.fulfilled, (state, action) => {
        state.onePublicacion = action.payload;
        state.sucessCases.getOne = true;
      })
      .addCase(fetchOnePublicacionAsync.rejected, (state, action) => {
        state.sucessCases.getOne = false;
      })
      .addCase(deleteOnePublicacionAsync.fulfilled, (state) => {
        state.sucessCases.deleted = true;
      })
      .addCase(deleteOnePublicacionAsync.rejected, (state) => {
        state.sucessCases.deleted = false;
      })
      .addCase(postOnePublicacionAsync.fulfilled, (state) => {
        state.sucessCases.created = true;
      })
      .addCase(postOnePublicacionAsync.rejected, (state) => {
        state.sucessCases.created = false;
      })
      .addCase(putOnePublicacionAsync.fulfilled, (state) => {
        state.sucessCases.updated = true;
      })
      .addCase(putOnePublicacionAsync.rejected, (state) => {
        state.sucessCases.updated = false;
      })
      .addCase(fetchHistoryPublicacionLicenseById.fulfilled, (state, action) => {
        state.history = action.payload;
      });
  },
});

export const selectFoco = (state) => state.foco;
export default focoSlice.reducer;
 export const { removeOnePublicacion } = focoSlice.actions;
