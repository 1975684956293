import {
    AppBar,
    IconButton,
    Slide,
    TablePagination,
    Toolbar,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import ArrowBackIcon from '@mui/icons-material/ArrowBack';
  import TemplatePort from "../assets/templatePortImg.png";
  import { useNavigate, useParams } from "react-router-dom";
  import moment from 'moment';
  import "./styles.scss";
  import { useDispatch, useSelector } from "react-redux";
  import {
    deleteFocoFiles,
    removeOnePublicacion,
    fetchOnePublicacionAsync,

  } from "../../foco/focoSlice";
  import { formatDate } from "../../../utils";
  import { DeviceFrameset } from "react-device-frameset";
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    minHeight: 100,
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    h2: {
      fontSize: 20,
    },
    padding: 2,
  };

 

export default function FocoPost(props) {

    const dispatch = useDispatch()
    const { id } = useParams();
    const [image, setImage] = React.useState(null);
    const [filesUploads, setFilesUploads] = useState([]);

  
    React.useEffect(()=>{
      const sessionItem = localStorage.getItem("userSession");
      if (sessionItem) {
        const sessionData = JSON.parse(sessionItem);
        const { expiration} = sessionData;
        const now = new Date();
        const expirationDate = new Date(expiration);

        if (now < expirationDate) {
          getPost()
        } else {
          navigate("/login",{ replace: true })
        }
      } else {
        navigate("/login",{ replace: true })
      }
       
    },[])

    const navigate = useNavigate();
     
    const { allPublicaciones, onePublicacion, totalCount } = useSelector(
        (state) => state.foco
      );

    const getPost =()=>{

        dispatch(fetchOnePublicacionAsync(id)).then((res) => {
            const newFilesUploads = [];
            res.payload.files?.forEach((file) => {
              if (file.file.path.includes("imgPublication")) {
                if (file.file.bytes) {
                  setImage("data:image/png;base64,".concat(file.file.bytes));
                } else {
                  setImage(null);
                }
              } else if (file.file.path.includes("filesUpload")) {
                const byteCharacters = atob(file.file.bytes);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
      
                const blob = new Blob([byteArray], { type: file.file.type });
      
                // Obtén la fecha de creación del archivo como un objeto Date
                const createdDate = new Date(file.file.createdDate);
      
                // Crea el objeto File con el Blob y los atributos necesarios
                const fileObj = new File([blob], file.file.name, {
                  lastModified: createdDate.getTime(),
                  type: file.file.type,
                });
      
                newFilesUploads.push(fileObj);
              }
            });
            setFilesUploads(newFilesUploads);
          });
        };
     

    const handleDownloadFiles = () => {
        filesUploads.forEach((file) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(file);
          link.download = file.name;
          link.target = "_blank"; // Abre el archivo en una nueva ventana/tab
          link.click();
          URL.revokeObjectURL(link.href); // Liberar recursos después de la descarga
        });
      };
      

    return (
        <>
        <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={
                    ()=>navigate("/foco",{ replace: true })
                  }
                aria-label="close"
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {onePublicacion.title}
              </Typography>
              <Typography variant="h6" component="div">
                {formatDate(onePublicacion.startdate, true)}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="h-full overflow-y-scroll">
            <div className="px-4 py-2 flex flex-col gap-3 h-[115vh]">
              {image ? (
                <img
                  src={image}
                  alt="imgPublication"
                  className="object-cover h-[500px] mx-auto"
                />
              ) : (
                <div className="h-[500px]">
                  <img
                  src={ TemplatePort}
                  className="object-cover h-[500px] mx-auto"
                />
                </div>
              )}

              <p className="break-words">{onePublicacion.textpublication}</p>
              {filesUploads.length > 0 ? (
                <button
                  onClick={handleDownloadFiles}
                  className="mb-3 w-fit cursor-pointer hover:scale-105 duration-200 font-semibold text-center border rounded-md p-2 shadow-md"
                >
                  Descargar {filesUploads.length} archivo
                  {filesUploads.length > 1 ? "s" : null}
                </button>
              ) : (
                <p className="mb-3 w-fit font-semibold text-center border rounded-md p-2 shadow-md">
                  No hay archivos adjuntos para descargar
                </p>
              )}
            </div>
            <section className="bg-[#5836ff] bottom-0 left-0 h-32 w-full flex flex-col justify-between p-2">
              <h2 className="text-white text-center text-xl">
                Buenos Aires Ciudad
              </h2>
              <small className="text-white text-center">version 1.0.0</small>
              <p className="text-white text-center text-sm font-light">
                RH SECRETARIA DE GESTIÓN DE RECURSOS HUMANOS
              </p>
            </section>
          </div> 
          </>
    )



}