import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { fetchHistoryPublicacionLicenseById } from '../../focoSlice';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { formatDate } from '../../../../utils';

export default function BasicTable({id}) {

  const dispatch = useDispatch()

  const [rows,setRows] = React.useState([])

  React.useEffect(()=>{
    dispatch(fetchHistoryPublicacionLicenseById(id)).then(res=>setRows(res.payload))
  },[])

  return (
    rows.length === 0 ? <p className='w-full p-4 text-xl text-center justify-center'>NO EXISTEN VALORES QUE MOSTRAR</p> :
    <TableContainer component={Paper} className="mt-2">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="bg-[#1976D2]">
          <TableRow>
            <TableCell className="text-white">Nombre y Apellido</TableCell>
            <TableCell className="text-white">Estado</TableCell>
            <TableCell className="text-white">Fecha y Hora</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>        
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.usuario}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.accion}
              </TableCell>
              <TableCell component="th" scope="row">
                {formatDate(row.createdDate,true)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}