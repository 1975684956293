import api, { APPCONFIG } from "../../api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const initialState = {
  staticData: {
    user: {
      documentTypes: [],
      familyKindship: [],
    },
    pregnancySettings: {},
  },
  settings: {},
};
export const getUserStaticData = createAsyncThunk(
  "parameters/getUserStaticData",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/parameters/user`;
    const response = await api.get(url);
    return response.data;
  }
);

export const getSetting = createAsyncThunk(
  "parameters/getSetting",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/parameters/Settings?name=${data}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const getPregnancySettings = createAsyncThunk(
  "parameters/getPregnancySettings",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/parameters/Preparto?codRegimen=${data.codRegimen}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const parametersSlice = createSlice({
  name: "parameters",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserStaticData.fulfilled, (state, action) => {
        state.staticData.user = action.payload;
      })
      .addCase(getSetting.fulfilled, (state, action) => {
        state.settings.extensions = action.payload.value;
      })
      .addCase(getPregnancySettings.fulfilled, (state, action) => {
        state.staticData.pregnancySettings = action.payload;
      });
  },
});
export const { reset } = parametersSlice.actions;
export default parametersSlice.reducer;
export const selectStaticData = (state) => state.parameters.staticData;
export const selectSettings = (state) => state.parameters.settings;
