import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Dropzone from "../dropzone";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import { useDropzone } from "react-dropzone";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLastId,
  postFocoFiles,
  postOnePublicacionAsync,
} from "../focoSlice";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils";
import moment from "moment";

import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  display: "flex",
  flexDirection: "column",
  minHeight: 100,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  h2: {
    fontSize: 20,
  },
  padding: 2,
};
function FocoCreate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (newFiles) => {
      setFiles([...files, ...newFiles]);
    },
  });

  const { datos } = useSelector((state) => state.usuario);
  const [formData, setFormData] = useState({
    title: null,
    publicationType: null,
    imgPortrait: null,
    imgPublication: null,
    textPublication: null,
    filesUpload: [],
    category: null,
  });

  const [exit, setExit] = React.useState(false);

  const [confirmar, setConfirmar] = React.useState(false);

  const [portada, setPortada] = React.useState(null);

  const [image, setImage] = React.useState(null);
  const [error,setError] = React.useState(false);
  const handlePortada = (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if(file && file.type.startsWith('image/') && !file.type.includes('webp')){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPortada({ name: file.name, src: reader.result });
        setFormData({ ...formData, imgPortrait: file });
        setLoading(false);
      };
    }else{
      setError(true)
      setLoading(false)
      setTimeout(()=>{setError(false)},3000)
    }
  };

  const [contenido, setContenido] = React.useState(null);

  const handleContenido = (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if(file && file.type.startsWith('image/') && !file.type.includes('webp')){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setContenido({ name: file.name, src: reader.result });
        setFormData({ ...formData, imgPublication: file });
        setLoading(false);
      };
    }else{
      setError(true)
      setLoading(false)
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (formData.publicationType !== "novedades") {
      setFormData({ ...formData, category: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.publicationType]);

  const handleSubmit = (status) => {
    setConfirmar(false);
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("publicationType", formData.publicationType);
    // formDataToSend.append("imgPortrait", formData.imgPortrait);
    // formDataToSend.append("imgPublication", formData.imgPublication);
    formDataToSend.append("textPublication", formData.textPublication);
    // for (const file of files) {
    //   formDataToSend.append("filesUpload", file);
    // }
    formDataToSend.append("category", formData.category);
    formDataToSend.append("status", status);
    try {
      dispatch(
        postOnePublicacionAsync({ userId: datos.id, formData: formDataToSend })
      ).then((res) => {

        const idPublicacion = res.payload.id;

        const keyImgPortrait = "imgPortrait";
        const formImgPortrait = new FormData();
        formImgPortrait.append("userId", datos.id);
        formImgPortrait.append("key", keyImgPortrait);
        formImgPortrait.append(keyImgPortrait, formData.imgPortrait);
        formImgPortrait.append("publicacionId", idPublicacion);
        dispatch(postFocoFiles(formImgPortrait)).then((res) => {
          const keyImgPublication = "imgPublication";
          const formImgPublication = new FormData();
          formImgPublication.append("userId", datos.id);
          formImgPublication.append("key", keyImgPublication);
          formImgPublication.append(keyImgPublication, formData.imgPublication);
          formImgPublication.append("publicacionId", idPublicacion);
          dispatch(postFocoFiles(formImgPublication)).then((res) => {
            if (files.length !== 0) {
              const keyFilesUpload = "filesUpload";
              const formFilesUpload = new FormData();
              files.forEach((file) => {
                formFilesUpload.append("userId", datos.id);
                formFilesUpload.append("key", keyFilesUpload);
                formFilesUpload.append(keyFilesUpload, file);
                formFilesUpload.append("publicacionId", idPublicacion);
              });
              dispatch(postFocoFiles(formFilesUpload)).then((res) => {
                setLoading(false);
                navigate("/foco-rrhh");
              });
            } else {
              setLoading(false);
              navigate("/foco-rrhh");
            }
          });
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
  };

  const [loading, setLoading] = useState(false);

  const [lastId, setLastId] = useState("");

  useEffect(() => {
    dispatch(fetchLastId()).then((res) => setLastId(res.payload.id));
  }, []);

  const [prevMobile, setPrevMobile] = useState(false);
  const [prevDesktop, setPrevDesktop] = useState(false);

  return (
    <div className="my-5 d-flex flex-column justify-content-center w-100">
      <h2 className="text-center text-xl font-bold">Nueva Publicación</h2>
      <Box
        component={"section"}
        className="w-75 mx-auto d-flex flex-column gap-3"
      >
        <Box className="d-flex gap-4">
          <FormControl sx={{ width: "100px" }}>
            <FormLabel>ID</FormLabel>
            <TextField value={lastId + 1} size="small" disabled />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Titulo de la Publicación</FormLabel>
            <TextField
              size="small"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
            />
          </FormControl>
        </Box>
        <Box className="d-flex w-100 gap-4 flex-column flex-lg-row">
          <FormControl className="input-form" size="small">
            <FormLabel>Tipo de Publicación</FormLabel>
            <Select
              name="publicationType"
              value={formData.publicationType}
              onChange={handleInputChange}
            >
              <MenuItem value={"Novedades"}>Novedades</MenuItem>
              <MenuItem value={"Procedimientos"}>Procedimientos</MenuItem>
              <MenuItem value={"Comunicados"}>Comunicados</MenuItem>
            </Select>
          </FormControl>
          <FormControl className="input-form" size="small">
            <FormLabel>Categoria</FormLabel>
            <Select
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              disabled={formData.publicationType !== "Novedades"}
            >
              <MenuItem value={"Concursos"}>Concursos</MenuItem>
              <MenuItem value={"Datos útiles"}>Datos útiles</MenuItem>
              <MenuItem value={"Fecha de cobro Haberes"}>
                Fecha de cobro Haberes
              </MenuItem>
              <MenuItem value={"Feriados/Festividades"}>
                Feriados/Festividades
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box className="d-flex flex-column flex-md-row gap-3 w-100 file-container">
          <FormControl className="d-flex flex-column gap-1 w-100">
            <FormLabel className="text-center">Imagen de Portada</FormLabel>
            <Button size="small" variant="contained" component="label">
              Subir imagen de portada
              <input type="file" hidden onChange={handlePortada} />
            </Button>
            {portada && (
              <div className="d-flex">
                <div className="d-flex">
                  <Button
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      setFormData({ ...formData, imgPortrait: null });
                      setPortada(null);
                    }}
                  ></Button>
                  <Button
                    startIcon={<RemoveRedEyeIcon />}
                    onClick={() => setImage(portada.src)}
                  ></Button>
                </div>
                <span>{portada.name}</span>
              </div>
            )}
          </FormControl>
          <FormControl className="d-flex flex-column gap-1 w-100">
            <FormLabel className="text-center">
              Contenido de Publicación
            </FormLabel>
            <Button size="small" variant="contained" component="label">
              Subir imagen en tu publicación
              <input type="file" hidden onChange={handleContenido} />
            </Button>
            {contenido && (
              <div className="d-flex">
                <div className="d-flex">
                  <Button
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      setFormData({ ...formData, imgPublication: null });
                      setContenido(null);
                    }}
                  ></Button>
                  <Button
                    startIcon={<RemoveRedEyeIcon />}
                    onClick={() => setImage(contenido.src)}
                  ></Button>
                </div>
                <span>{contenido.name}</span>
              </div>
            )}
          </FormControl>
        </Box>
        <FormControl fullWidth>
          <FormLabel>Texto de la Publicación</FormLabel>
          <TextField
            multiline
            rows={4}
            name="textPublication"
            value={formData.textPublication}
            onChange={handleInputChange}
          />
        </FormControl>
        <Dropzone
          files={files}
          getInputProps={getInputProps}
          getRootProps={getRootProps}
          handleDelete={handleDelete}
        />
        <Box className="flex justify-between flex-col sm:flex-row gap-3">
          <Box className="flex gap-2 flex-col sm:flex-row">
            <Button
              endIcon={<LaptopChromebookIcon />}
              disabled={
                !formData.title ||
                !formData.publicationType ||
                (formData.publicationType === "Novedades"
                  ? !formData.category
                  : false) ||
                !formData.imgPortrait ||
                !formData.imgPublication
              }
              onClick={() => setPrevDesktop(true)}
              variant="outlined"
            >
              Previsualizar
            </Button>
            <Button
              endIcon={<MobileFriendlyIcon />}
              disabled={
                !formData.title ||
                !formData.publicationType ||
                (formData.publicationType === "Novedades"
                  ? !formData.category
                  : false) ||
                !formData.imgPortrait ||
                !formData.imgPublication
              }
              onClick={() => setPrevMobile(true)}
              variant="outlined"
            >
              Previsualizar
            </Button>
          </Box>
          <Box className="flex gap-2 self-center">
            <Button onClick={() => setExit(true)} color="error" variant="outlined">Cancelar</Button>
            {/* <Button
              disabled={
                !formData.title ||
                !formData.publicationType ||
                (formData.publicationType === "Novedades"
                  ? !formData.category
                  : false) ||
                !formData.imgPortrait ||
                !formData.imgPublication
              }
              variant="contained"
              onClick={() => setConfirmar(true)}
            >
              Confirmar
            </Button> */}
            <Button variant="outlined"               disabled={
                !formData.title ||
                !formData.publicationType ||
                (formData.publicationType === "Novedades"
                  ? !formData.category
                  : false) ||
                !formData.imgPortrait ||
                !formData.imgPublication
              } onClick={() => handleSubmit("Borrador")}>
              Borrador
            </Button>
            <Button
              variant="contained"
              disabled={
                !formData.title ||
                !formData.publicationType ||
                (formData.publicationType === "Novedades"
                  ? !formData.category
                  : false) ||
                !formData.imgPortrait ||
                !formData.imgPublication
              }
              onClick={() => handleSubmit("Publicada")}
            >
              Publicar
            </Button>
          </Box>
        </Box>
      </Box>
      {image && (
        <div
          className="see-image"
          style={{ display: image ? "block" : "none" }}
        >
          <Button
            variant="contained"
            className="text-white"
            onClick={() => setImage(null)}
          >
            Atras
          </Button>
          <img src={image} alt="archivo" />
        </div>
      )}
      {prevMobile && (
        <div className="w-full h-full fixed z-50 top-0 flex gap-5 flex-col sm:flex-row justify-center items-center bg-black/50">
          <div className="absolute top-2 right-2 z-50">
            <Button
              variant="contained"
              className="text-white"
              onClick={() => setPrevMobile(false)}
            >
              Atras
            </Button>
          </div>
          <DeviceFrameset
            device="Samsung Galaxy S5"
            color="gold"
            width={"270px"}
            height={"70%"}
          >
            <div className="bg-white h-[100%] mt-1 overflow-hidden overflow-y-scroll no-scrollbar">
              <h1 className="text-center text-2xl font-semibold text-sky-900 border-bottom mx-2">
                {formData.title}
              </h1>
              <h2 className="mt-2 text-sky-900 font-semibold text-center">
                {formatDate(moment())}
              </h2>
              <img
                src={contenido.src}
                alt="contenido"
                className="object-cover px-2 mt-2 mx-auto rounded-md"
              />
              <p className="mt-2 p-2 text-lg text-left break-words min-h-[120px]">
                {formData.textPublication}
              </p>
              {files.length > 0 ? (
                <p className="mb-3 cursor-pointer hover:scale-105 duration-200 font-semibold text-center border rounded-md p-2 shadow-md">
                  Descargar {files.length} archivo
                  {files.length > 1 ? "s" : null}
                </p>
              ) : (
                <p className="mb-3 font-semibold text-center border rounded-md p-2 shadow-md">
                  No hay archivos adjuntos para descargar
                </p>
              )}
              <section className="bg-[#5836ff] left-0 h-32 w-full flex flex-col justify-between p-2">
                <h2 className="text-white text-center text-xl">
                  Buenos Aires Ciudad
                </h2>
                <small className="text-white text-center">version 1.0.0</small>
                <p className="text-white text-center text-sm font-light">
                  RH SECRETARIA DE GESTIÓN DE RECURSOS HUMANOS
                </p>
              </section>
            </div>
          </DeviceFrameset>
        </div>
      )}
      {prevDesktop && (
        <div className="w-full h-full fixed z-50 top-0 flex gap-5 flex-col sm:flex-row justify-center items-center bg-black/50">
          <div className="absolute top-2 right-2 z-50">
            <Button
              variant="contained"
              className="text-white"
              onClick={() => setPrevDesktop(false)}
            >
              Atras
            </Button>
          </div>
          <DeviceFrameset
            device="MacBook Pro"
            color="gold"
            landscape
            width={"60%"}
            height={"70%"}
          >
            <div className="bg-white h-[100%] mt-1 overflow-hidden overflow-y-scroll">
              <h1 className="text-center text-2xl font-semibold text-sky-900 border-bottom mx-2">
                {formData.title}
              </h1>
              <h2 className="mt-2 text-sky-900 font-semibold text-center">
                {formatDate(moment())}
              </h2>
              <img
                src={contenido.src}
                alt="contenido"
                className="object-cover px-3 mt-2 mx-auto rounded-md"
              />
              <p className="mt-2 p-2 text-lg text-left break-words min-h-[120px]">
                {formData.textPublication}
              </p>
              {files.length > 0 ? (
                <p className="mb-3 cursor-pointer hover:scale-105 duration-200 font-semibold text-center border rounded-md p-2 shadow-md">
                  Descargar {files.length} archivo
                  {files.length > 1 ? "s" : null}
                </p>
              ) : (
                <p className="mb-3 font-semibold text-center border rounded-md p-2 shadow-md">
                  No hay archivos adjuntos para descargar
                </p>
              )}
              <section className="bg-[#5836ff] left-0 h-32 w-full flex flex-col justify-between p-2">
                <h2 className="text-white text-center text-xl">
                  Buenos Aires Ciudad
                </h2>
                <small className="text-white text-center">version 1.0.0</small>
                <p className="text-white text-center text-sm font-light">
                  RH SECRETARIA DE GESTIÓN DE RECURSOS HUMANOS
                </p>
              </section>
            </div>
          </DeviceFrameset>
        </div>
      )}

      <Modal
        open={exit}
        onClose={() => setExit(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 350 }}>
          <h2 id="child-modal-title" className="font-bold text-center my-5">
            ¿Desea salir sin guardar los datos ingresados?
          </h2>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <Button variant="outlined" onClick={() => setExit(false)}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={() => navigate("/foco-rrhh")}>
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={confirmar}
        onClose={() => setConfirmar(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 350 }} gap={1}>
          <h2 id="child-modal-title" className="font-bold text-center my-3">
            ¿Que acción desea realizar?
          </h2>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
            <Button variant="outlined" onClick={() => handleSubmit("Borrador")}>
              Borrador
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSubmit("Publicada")}
            >
              Publicar
            </Button>
          </Box>
          <Button onClick={() => setConfirmar(false)} color="error">
            Cancelar
          </Button>
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" size={100} />
      </Backdrop>
      <Snackbar open={error} autoHideDuration={3000}>
        <Alert severity="error">Ups.. Solo se permiten imágenes</Alert>
      </Snackbar>
    </div>
  );
}

export default FocoCreate;
