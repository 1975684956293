import axios from "axios";

export const APPCONFIG = {
    APIs : {
        AuthUserAPI : process.env.REACT_APP_AUTHUSER,
        LicenseAPI : process.env.REACT_APP_LICENSEAPI,
    }
}

const axiosInstance = axios.create({
    //baseURL: APPCONFIG.APIs.MainAPI,
    headers: {
        "Content-type": "application/json;charset=UTF-8",
    }
});



axiosInstance.interceptors.request.use(
    async config => {
      if (config.url.includes('/foco-post/')) {
        const sessionItem = localStorage.getItem("userSession");
        if (sessionItem) {
          const sessionData = JSON.parse(sessionItem);
          const { expiration, userToken } = sessionData;
          const now = new Date();
          const expirationDate = new Date(expiration);
  
          if (now < expirationDate) {
            config.headers["Authorization"] = `Bearer ${userToken}`;
          } else {
            console.error("token expirado");
            throw new Error("Token expirado");
          }
        } else {
          console.error("sesion en LS no encontrada");
          throw new Error("loguearse de nuevo");
        }
        
      } 
      else {
        let tokenUrl = APPCONFIG.APIs.LicenseAPI;
        if (tokenUrl) {
          try {
            const response = await axios.get(tokenUrl + process.env.REACT_APP_TOKEN);
            config.headers["Authorization"] = `Bearer ${response.data}`;
          } catch (error) {
            console.error("Error al obtener el token:", error);
            throw error;
          }
        }
      }
  
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );


  export default axiosInstance;