/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Dropzone from "../dropzone";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import Historial from "./historial";
import "./styles.scss";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFocoFiles,
  eliminarOnePublicacionAsync,
  fetchOnePublicacionAsync,
  postFocoFiles,
  updateFocoFiles,
  updateOnePublicacionAsync,
} from "../focoSlice";
import { formatDate } from "../../../utils";
import { DeviceFrameset } from "react-device-frameset";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  display: "flex",
  flexDirection: "column",
  minHeight: 100,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  h2: {
    fontSize: 20,
  },
  padding: 2,
};
function FocoId() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filesUploads, setFilesUploads] = useState([]);
  const [filesIds, setFilesIds] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (newFiles) => {
      setFilesUploads([...filesUploads, ...newFiles]);
      setIsChanged(true);
      setIsChangedFiles(true);
    },
  });
  const [portada, setPortada] = React.useState(null);
  const [contenido, setContenido] = React.useState(null);
  const [prevIdPortrait, setPrevIdPortrait] = useState(null);
  const [prevIdPublication, setPrevIdPublication] = useState(null);
  const { datos } = useSelector((state) => state.usuario);
  const [data, setData] = useState({
    publicationtype: null,
    category: null,
  });
  const [image, setImage] = React.useState(null);
  const [error,setError] = React.useState(false);
  useEffect(() => {
    dispatch(fetchOnePublicacionAsync(id)).then((res) => {
      setData(res.payload);
      const filesMap = [];
      const newFilesUploads = [];
      res.payload.files?.forEach((file) => {
        if (file.file.path.includes("imgPortrait")) {
          setPrevIdPortrait(file.file.id);
          setPortada({
            name: file.file.name,
            src: "data:image/png;base64,".concat(file.file.bytes),
          });
        } else if (file.file.path.includes("imgPublication")) {
          setPrevIdPublication(file.file.id);
          setContenido({
            id: file.file.id,
            name: file.file.name,
            src: "data:image/png;base64,".concat(file.file.bytes),
          });
        } else if (file.file.path.includes("filesUpload")) {
          const byteCharacters = atob(file.file.bytes);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          const blob = new Blob([byteArray], { type: file.file.type });

          // Obtén la fecha de creación del archivo como un objeto Date
          const createdDate = new Date(file.file.createdDate);

          // Crea el objeto File con el Blob y los atributos necesarios
          const fileObj = new File([blob], file.file.name, {
            lastModified: createdDate.getTime(),
            type: file.file.type,
          });

          newFilesUploads.push(fileObj);
          filesMap.push(file.file.id);
        }
      });
      setFilesUploads(newFilesUploads);
      setFilesIds(filesMap);
    });
  }, [id,dispatch]);

  const handlePortada = (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if(file && file.type.startsWith('image/')){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setIsChanged(true);
        setIsChangedPortrait(true);
        setPortada({ name: file.name, src: reader.result });
        setData({ ...data, imgportrait: file });
        setLoading(false);
      };
    }else{
      setError(true)
      setLoading(false);
      setTimeout(()=>{setError(false)},3000)
    }

  };

  const handleContenido = (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if(file && file.type.startsWith('image/')){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setIsChanged(true);
        setIsChangedPublication(true);
        setContenido({ name: file.name, src: reader.result });
        setData({ ...data, imgpublication: file });
        setLoading(false);
      };
    }else{
      setError(true)
      setLoading(false);
    }

  };

  const handleChange = (e) => {
    setIsChanged(true);
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const [prevMobile, setPrevMobile] = useState(false);
  const [prevDesktop, setPrevDesktop] = useState(false);

  useEffect(() => {
    if (data.publicationtype !== "Novedades") {
      setData({ ...data, category: null });
    }
  }, [data.publicationtype]);

  const handleDelete = (index) => {
    setIsChanged(true);
    setIsChangedFiles(true);
    const newFiles = filesUploads.filter((_, i) => i !== index);
    setFilesUploads(newFiles);
  };

  const [isChanged, setIsChanged] = useState(false);
  const [isChangedPortrait, setIsChangedPortrait] = useState(false);
  const [isChangedPublication, setIsChangedPublication] = useState(false);
  const [isChangedFiles, setIsChangedFiles] = useState(false);
  const [exit, setExit] = React.useState(false);

  const [modificar, setModificar] = React.useState(false);

  const [eliminar, setEliminar] = React.useState(false);
  const [finalizar, setFinalizar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleEliminar = () => {
    setLoading(true);
    dispatch(eliminarOnePublicacionAsync(id)).then((res) =>
      navigate("/foco-rrhh")
    );
  };

  const handleSubmit = (status) => {
    setModificar(false);
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("title", data.title);
    formDataToSend.append("publicationType", data.publicationtype);
    formDataToSend.append("textPublication", data.textpublication);
    formDataToSend.append("category", data.category);
    formDataToSend.append("status", status);
    formDataToSend.append("id", data.id);
    dispatch(
      updateOnePublicacionAsync({ userId: datos.id, formData: formDataToSend })
    ).then(() => {
      setLoading(false);
      const idPublicacion = id;
      if (isChangedPortrait) {
        const keyImgPortrait = "imgPortrait";
        const formImgPortrait = new FormData();
        formImgPortrait.append("userId", datos.id);
        formImgPortrait.append("key", keyImgPortrait);
        formImgPortrait.append(keyImgPortrait, data.imgportrait);
        formImgPortrait.append("publicacionId", idPublicacion);
        formImgPortrait.append("prevId", prevIdPortrait);
        dispatch(updateFocoFiles(formImgPortrait));
      }
      if (isChangedPublication) {
        const keyImgPublication = "imgPublication";
        const formImgPublication = new FormData();
        formImgPublication.append("userId", datos.id);
        formImgPublication.append("key", keyImgPublication);
        formImgPublication.append(keyImgPublication, data.imgpublication);
        formImgPublication.append("publicacionId", idPublicacion);
        formImgPublication.append("prevId", prevIdPublication);
        dispatch(updateFocoFiles(formImgPublication));
      }
      if (isChangedFiles) {
        if (filesIds.length > 0) {
          dispatch(deleteFocoFiles(filesIds)).then((res) => {
            if (filesUploads.length !== 0) {
              const keyFilesUpload = "filesUpload";
              const formFilesUpload = new FormData();
              filesUploads.forEach((file) => {
                formFilesUpload.append("userId", datos.id);
                formFilesUpload.append("key", keyFilesUpload);
                formFilesUpload.append(keyFilesUpload, file);
                formFilesUpload.append("publicacionId", idPublicacion);
              });
              dispatch(postFocoFiles(formFilesUpload));
            }
          });
        } else {
          if (filesUploads.length !== 0) {
            const keyFilesUpload = "filesUpload";
            const formFilesUpload = new FormData();
            filesUploads.forEach((file) => {
              formFilesUpload.append("userId", datos.id);
              formFilesUpload.append("key", keyFilesUpload);
              formFilesUpload.append(keyFilesUpload, file);
              formFilesUpload.append("publicacionId", idPublicacion);
            });
            dispatch(postFocoFiles(formFilesUpload));
          }
        }
      }
      navigate("/foco-rrhh");
    });
  };

  return (
    <div className="my-3 d-flex flex-column justify-content-center w-100">
      <h2 className="text-center text-xl">Detalle</h2>
      <Box
        component={"section"}
        className="w-75 mx-auto d-flex flex-column gap-3"
      >
        <Box className="d-flex gap-4">
          <FormControl sx={{ width: "100px" }}>
            <FormLabel>ID</FormLabel>
            <TextField size="small" value={id} disabled />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel>Titulo de la Publicación</FormLabel>
            <TextField
              size="small"
              name="title"
              value={data.title}
              onChange={handleChange}
            />
          </FormControl>
        </Box>
        {/* <img src={"data:image/png;base64,".concat(data.files[0].file.bytes)} alt="" className="w-full" /> */}
        <Box className="d-flex w-100 gap-4 flex-column flex-lg-row">
          <FormControl className="input-form" size="small">
            <FormLabel>Tipo de Publicación</FormLabel>
            <Select
              name="publicationtype"
              value={data.publicationtype}
              onChange={handleChange}
            >
              <MenuItem value={"Novedades"}>Novedades</MenuItem>
              <MenuItem value={"Procedimientos"}>Procedimientos</MenuItem>
              <MenuItem value={"Comunicados"}>Comunicados</MenuItem>
            </Select>
          </FormControl>
          <FormControl className="input-form" size="small">
            <FormLabel>Categoria</FormLabel>
            <Select
              name="category"
              value={data.category}
              onChange={handleChange}
              disabled={data.publicationtype !== "Novedades"}
            >
              <MenuItem value={"Concursos"}>Concursos</MenuItem>
              <MenuItem value={"Datos útiles"}>Datos útiles</MenuItem>
              <MenuItem value={"Fecha de cobro Haberes"}>
                Fecha de cobro Haberes
              </MenuItem>
              <MenuItem value={"Feriados/Festividades"}>
                Feriados/Festividades
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl className="input-form">
            <FormLabel>Estado</FormLabel>
            <TextField size="small" value={data.status} disabled />
          </FormControl>
        </Box>
        <Box className="d-flex flex-column flex-md-row gap-3 w-100 file-container">
          <FormControl className="d-flex flex-column gap-1 w-100">
            <FormLabel className="text-center">Imagen de Portada</FormLabel>
            <Button size="small" variant="contained" disabled={data.status === "Publicada"} component="label">
              Subir imagen de portada
              <input type="file" hidden onChange={handlePortada} />
            </Button>
            {portada && (
              <div className="d-flex">
                <div className="d-flex">
                  <Button
                    startIcon={<CloseIcon />}
                    disabled={data.status === "Publicada"}
                    onClick={() => {
                      setIsChanged(true);

                      setPortada(null);
                    }}
                  ></Button>
                  <Button
                    startIcon={<RemoveRedEyeIcon />}
                    onClick={() => setImage(portada.src)}
                  ></Button>
                </div>
                <span>{portada.name}</span>
              </div>
            )}
          </FormControl>
          <FormControl className="d-flex flex-column gap-1 w-100">
            <FormLabel className="text-center">
              Contenido de Publicación
            </FormLabel>
            <Button size="small" variant="contained" disabled={data.status === "Publicada"} component="label">
              Subir imagen en tu publicación
              <input type="file" hidden onChange={handleContenido} />
            </Button>
            {contenido && (
              <div className="d-flex">
                <div className="d-flex">
                  <Button
                    startIcon={<CloseIcon />}
                    disabled={data.status === "Publicada"}
                    onClick={() => {
                      setIsChanged(true);
                      setContenido(null);
                    }}
                  ></Button>
                  <Button
                    startIcon={<RemoveRedEyeIcon />}
                    onClick={() => setImage(contenido.src)}
                  ></Button>
                </div>
                <span>{contenido.name}</span>
              </div>
            )}
          </FormControl>
        </Box>
        <FormControl fullWidth>
          <FormLabel>Texto de la Publicación</FormLabel>
          <TextField
            multiline
            rows={4}
            name="textpublication"
            value={data.textpublication}
            onChange={handleChange}
          />
        </FormControl>
        <Dropzone
          files={filesUploads}
          getInputProps={getInputProps}
          getRootProps={getRootProps}
          handleDelete={handleDelete}
        />
        <Box className="flex justify-between flex-col sm:flex-row gap-3">
          <Box className="flex gap-2 flex-col sm:flex-row">
            <Button
              endIcon={<LaptopChromebookIcon />}
              onClick={() => setPrevDesktop(true)}
              variant="outlined"
            >
              Previsualizar
            </Button>
            <Button
              endIcon={<MobileFriendlyIcon />}
              onClick={() => setPrevMobile(true)}
              variant="outlined"
            >
              Previsualizar
            </Button>
          </Box>
          <Box className="flex gap-2">
            <Button onClick={() => setExit(true)} variant="outlined">Cancelar</Button>
            {data.status === "Eliminada" ? null : (
              <Button color="error" variant="outlined" onClick={() => setEliminar(true)}>
                Eliminar
              </Button>
            )}
            {(data.status === "Finalizada" || data.status === "Borrador") ? null : (
              <Button
                color="warning"
                variant="outlined"
                onClick={() => setFinalizar(true)}
              >
                Finalizar
              </Button>
            )}
            {data.status === "Borrador" &&             <Button
              variant="contained"
              onClick={() => handleSubmit("Publicada")}
            >
              Publicar
            </Button>}
            <Button
              variant="contained"
              hidden={data.status === "Publicada"}
              disabled={
                !isChanged ||
                !contenido ||
                !portada ||
                (data.publicationtype === "Novedades"
                  ? !data.category
                  : false) ||
                !data.title
              }
              onClick={() => handleSubmit("Borrador")}
            >
              Modificar
            </Button>
          </Box>
        </Box>
        <Box>
          <FormLabel>Historial de la Publicación</FormLabel>
          <Historial id={id} />
        </Box>
      </Box>
      <Modal
        open={modificar}
        onClose={() => setModificar(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 350 }} gap={1}>
          <h2 id="child-modal-title" className="font-bold text-center my-3">
            ¿Que acción desea realizar?
          </h2>
          <div className="flex gap-2 mx-auto">
            <Button
              variant="contained"
              className="w-full"
              onClick={() => handleSubmit("Publicada")}
            >
              Publicar
            </Button>
            <Button
              className="w-full"
              variant="outlined"
              onClick={() => handleSubmit("Borrador")}
            >
              Borrador
            </Button>
          </div>
          <div className="flex gap-2 mx-auto">
            <Button
              className="w-full"
              variant="outlined"
              onClick={() => setModificar(false)}
              color="error"
            >
              Cancelar
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={eliminar}
        onClose={() => setEliminar(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 350 }} gap={1}>
          <h2 id="child-modal-title" className="font-bold text-center my-3">
            ¿Estás seguro/a de eliminar la publicación?
          </h2>
          <div className="flex gap-2 mx-auto">
            <Button
              className="w-full"
              variant="outlined"
              onClick={() => setEliminar(false)}
            >
              Cancelar
            </Button>
            <Button
              className="w-full"
              variant="contained"
              onClick={handleEliminar}
              color="error"
            >
              Eliminar
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={finalizar}
        onClose={() => setFinalizar(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 350 }} gap={1}>
          <h2 id="child-modal-title" className="font-bold text-center my-3">
            ¿Estás seguro/a de finalizar la publicación?
          </h2>
          <div className="flex gap-2 mx-auto">
            <Button
              className="w-full"
              variant="outlined"
              onClick={() => setFinalizar(false)}
            >
              Cancelar
            </Button>
            <Button
              className="w-full"
              variant="contained"
              onClick={() => handleSubmit("Finalizada")}
              color="error"
            >
              Confirmar
            </Button>
          </div>
        </Box>
      </Modal>
      {image && (
        <div
          className="see-image"
          style={{ display: image ? "block" : "none" }}
        >
          <Button
            variant="contained"
            className="text-white"
            onClick={() => setImage(null)}
          >
            Atras
          </Button>
          <img src={image} alt="archivo" />
        </div>
      )}
      {prevMobile && (
        <div className="w-full h-full fixed z-50 top-0 flex gap-5 flex-col sm:flex-row justify-center items-center bg-black/50">
          <div className="absolute top-2 right-2 z-50">
            <Button
              variant="contained"
              className="text-white"
              onClick={() => setPrevMobile(false)}
            >
              Atras
            </Button>
          </div>
          <DeviceFrameset
            device="Samsung Galaxy S5"
            color="gold"
            width={"270px"}
            height={"70%"}
          >
            <div className="bg-white h-[100%] mt-1 overflow-hidden overflow-y-scroll no-scrollbar">
              <h1 className="text-center text-2xl font-semibold text-sky-900 border-bottom mx-2">
                {data.title}
              </h1>
              <h2 className="mt-2 text-sky-900 font-semibold text-center">
                {formatDate(data.startdate)}
              </h2>
              <img
                src={contenido.src}
                alt="contenido"
                className="object-cover px-2 mt-2 mx-auto rounded-md"
              />
              <p className="mt-2 p-2 text-lg text-left break-words min-h-[120px]">
                {data.textpublication}
              </p>
              {filesUploads.length > 0 ? (
                <p className="mb-3 cursor-pointer hover:scale-105 duration-200 font-semibold text-center border rounded-md p-2 shadow-md">
                  Descargar {filesUploads.length} archivo
                  {filesUploads.length > 1 ? "s" : null}
                </p>
              ) : (
                <p className="mb-3 font-semibold text-center border rounded-md p-2 shadow-md">
                  No hay archivos adjuntos para descargar
                </p>
              )}
              <section className="bg-[#5836ff] left-0 h-32 w-full flex flex-col justify-between p-2">
                <h2 className="text-white text-center text-xl">
                  Buenos Aires Ciudad
                </h2>
                <small className="text-white text-center">version 1.0.0</small>
                <p className="text-white text-center text-sm font-light">
                  RH SECRETARIA DE GESTIÓN DE RECURSOS HUMANOS
                </p>
              </section>
            </div>
          </DeviceFrameset>
        </div>
      )}
      {prevDesktop && (
        <div className="w-full h-full fixed z-50 top-0 flex gap-5 flex-col sm:flex-row justify-center items-center bg-black/50">
          <div className="absolute top-2 right-2 z-50">
            <Button
              variant="contained"
              className="text-white"
              onClick={() => setPrevDesktop(false)}
            >
              Atras
            </Button>
          </div>
          <DeviceFrameset
            device="MacBook Pro"
            color="gold"
            landscape
            width={"60%"}
            height={"70%"}
          >
            <div className="bg-white h-[100%] mt-1 overflow-hidden overflow-y-scroll">
              <h1 className="text-center text-2xl font-semibold text-sky-900 border-bottom mx-2">
                {data.title}
              </h1>
              <h2 className="mt-2 text-sky-900 font-semibold text-center">
                {formatDate(data.startdate)}
              </h2>
              <img
                src={contenido.src}
                alt="contenido"
                className="object-cover px-3 mt-2 mx-auto rounded-md"
              />
              <p className="mt-2 p-2 text-lg text-left break-words min-h-[120px]">
                {data.textpublication}
              </p>
              {filesUploads.length > 0 ? (
                <p className="mb-3 cursor-pointer hover:scale-105 duration-200 font-semibold text-center border rounded-md p-2 shadow-md">
                  Descargar {filesUploads.length} archivo
                  {filesUploads.length > 1 ? "s" : null}
                </p>
              ) : (
                <p className="mb-3 font-semibold text-center border rounded-md p-2 shadow-md">
                  No hay archivos adjuntos para descargar
                </p>
              )}
              <section className="bg-[#5836ff] left-0 h-32 w-full flex flex-col justify-between p-2">
                <h2 className="text-white text-center text-xl">
                  Buenos Aires Ciudad
                </h2>
                <small className="text-white text-center">version 1.0.0</small>
                <p className="text-white text-center text-sm font-light">
                  RH SECRETARIA DE GESTIÓN DE RECURSOS HUMANOS
                </p>
              </section>
            </div>
          </DeviceFrameset>
        </div>
      )}
      <Modal
        open={exit}
        onClose={() => setExit(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 350 }}>
          <h2 id="child-modal-title" className="font-bold text-center my-5">
            ¿Desea salir sin guardar los datos ingresados?
          </h2>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <Button variant="outlined" onClick={() => setExit(false)}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={() => navigate("/foco-rrhh")}>
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" size={100} />
      </Backdrop>
      <Snackbar open={error} autoHideDuration={3000}>
        <Alert severity="error">Ups.. Solo se permiten imágenes</Alert>
      </Snackbar>
    </div>
    
  );
}

export default FocoId;
