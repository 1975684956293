import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDatosPersonales } from "../../user/userSlice";
import { postCertificado } from "../licenseSlice";
import CustomAlert from "../../alert";
import DocRespaldatoriaAdicional from "./adicional";
import { resetDocuments, selectDocuments } from "../../files/filesSlice";
import DocRequerido from "./requerido";
import { handleConfirmModal } from "../../modals/modalSlice";

export default function DocRespaldatoria({ open, handleClose, refreshData, licenseId , creacion=true }) {
    const [error, setError] = useState({ show: false, data: '' });
    const { datos } = useSelector(selectDatosPersonales);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const dispatch = useDispatch();
    const { documents, documentsMobile, extraDocuments, extraDocumentsMobile } = useSelector(selectDocuments);

    const saveDocuments = () => {
        const key = 'docRespaldatoria'
        const formData = new FormData();
        let idDocRespaldo = 0;
        documents.forEach((file) => {
            idDocRespaldo++;
            formData.append("userId", datos.id);
            formData.append("cuit", datos.cuit);
            formData.append("key", 'docRespaldatoria');
            formData.append('docRespaldatoria', file);
            formData.append("sendMeta4", true);
            formData.append("licenseId", licenseId);
            formData.append("idDocRespaldo",idDocRespaldo)
        });

        documentsMobile.forEach((file) => {
            idDocRespaldo++;
            formData.append("userId", datos.id);
            formData.append("cuit", datos.cuit);
            formData.append("key", 'docRespaldatoria');
            formData.append('docRespaldatoria', file);
            formData.append("sendMeta4", true);
            formData.append("licenseId", licenseId);
            formData.append("idDocRespaldo",idDocRespaldo)
        });

        extraDocuments.forEach((file) => {
            idDocRespaldo++;
            formData.append("userId", datos.id);
            formData.append("cuit", datos.cuit);
            formData.append("key", 'docRespaldatoriaAdicional');
            formData.append('docRespaldatoriaAdicional', file);
            formData.append("sendMeta4", true);
            formData.append("licenseId", licenseId);
            formData.append("idDocRespaldo",idDocRespaldo)
        });

        extraDocumentsMobile.forEach((file) => {
            idDocRespaldo++;
            formData.append("userId", datos.id);
            formData.append("cuit", datos.cuit);
            formData.append("key", 'docRespaldatoriaAdicional');
            formData.append('docRespaldatoriaAdicional', file);
            formData.append("sendMeta4", true);
            formData.append("licenseId", licenseId);
            formData.append("idDocRespaldo",idDocRespaldo)
        });

        setOpenConfirm(false);
        handleClose();
        dispatch(postCertificado(formData)).then((e) => {
            
            if(e.error)
                {
                    dispatch(
                        handleConfirmModal({
                            open: true,
                            title: `Error`,
                            content: "Ha ocurrido un error, por favor contáctese con algun administrador.",
                            showCancel: false,
                        })
                    );
                }
                else
                {
                    dispatch(resetDocuments())
                    dispatch(
                        handleConfirmModal({
                            open: true,
                            title: `Hecho`,
                            content: "La documentación respaldatoria fue cargada",
                            showCancel: false,
                        })
                    );
                }
            refreshData();
        });
    }

    const saveOtherDocuments = (idDocRespaldo) => {
        const key = 'docRespaldatoriaAdicional'
        const formData = new FormData();
        idDocRespaldo += 1
        extraDocuments.forEach((file,index) => {
            idDocRespaldo += index
            formData.append("userId", datos.id);
            formData.append("cuit", datos.cuit);
            formData.append("key", key);
            formData.append(key, file);
            formData.append("sendMeta4", true);
            formData.append("licenseId", licenseId);
            formData.append("idDocRespaldo",idDocRespaldo)
        });
        dispatch(postCertificado(formData)).then((e) => {
            
            if(e.error)
            {
                dispatch(
                    handleConfirmModal({
                        open: true,
                        title: `Error`,
                        content: "Ha ocurrido un error, por favor contáctese con algun administrador.",
                        showCancel: false,
                    })
                );
            }
            else
            {
                dispatch(resetDocuments())
                dispatch(
                    handleConfirmModal({
                        open: true,
                        title: `Hecho`,
                        content: "La documentación respaldatoria fue cargada",
                        showCancel: false,
                    })
                );
            }
        });
    }

    const handleClickOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleErrorClose = () => {
        setError({ show: false });
    };

    return (
        <>
            <Dialog scroll="body"
                open={open}
            >

                <DialogContent>
                    <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Documentación respaldatoria (obligatorio)</Typography>
                    <DocRequerido  creacion={creacion} docRespaldatoria={true}/>
                    <Divider />
                    <Box mt={3}>
                        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Otros documentos</Typography>
                        <DocRespaldatoriaAdicional docRespaldatoria={true}/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Volver</Button>
                    <Button disabled={documents.length === 0 && documentsMobile.length === 0} onClick={handleClickOpenConfirm}>Confirmar</Button>
                </DialogActions>
                <CustomAlert error={error} severity='error' onClose={handleErrorClose} />
            </Dialog>

               
                <Dialog
                    maxWidth={"xs"}
                    open={openConfirm}
                    onClose={handleCloseConfirm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    
                   
                >
                    <DialogContent >
                        <p className="text-center">
                            Al confirmar, se guardarán los documentos seleccionados y no podrá modificarlos.
                            ¿Desea continuar?
                        </p>
                    </DialogContent>
                    <div className="flex gap-5 justify-center mb-2">
                        <Button onClick={handleCloseConfirm}>Cancelar</Button>
                        <Button onClick={saveDocuments} autoFocus>
                            Confirmar
                        </Button>
                        </div>
                </Dialog>
            
        </>

    )
}