import {
  FormControl,
  FormLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Typography,
  Stack,
  Switch,
  Box
} from "@mui/material";
import { selectLicenses } from "../licenseSlice";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useState } from "react";

export default function FechaAlumbramiento({ control,setValue }) {
  const { form } = useSelector(selectLicenses);

  const [open, setOpen] = useState(false);

  const getDate = (days, startDate) => {
    const currentDate = startDate ?? moment(new Date());
    const result = currentDate.add(days, "days");
    return result;
  };

  const handleDateChange = (newDate) => {
    if (newDate && newDate.toDate) {
      newDate = newDate.toDate();
    }
    return newDate;
  };

  const handleNacMultChange = (event) => {
    const isChecked = event.target.checked;
    setValue("nacimientoMultiple", isChecked, { shouldTouch: true });
  };

  return (
    <>
      <FormControl id="check-neonatologia" fullWidth>
        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
          ¿Es un nacimiento múltiple?
        </FormLabel>
        <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'left' }}>
          <Controller
            name="nacimientoMultiple"
            control={control}
            render={() => (
              <>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <Switch
                    checked={form?.nacimientoMultiple}
                    onChange={handleNacMultChange}
                  />
                  <Typography>Sí</Typography>
                </Stack>
              </>
            )}
          />
        </Box>
      </FormControl>
      {form.nacimientoMultiple && (
        <FormControl fullWidth>
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Cantidad de hijos
          </FormLabel>
          <Controller
            name="cantidadHijos"
            control={control}
            rules={{
              validate: {
                isGreaterThanOne: (value) =>
                  (form.nacimientoMultiple && value > 1) || (!form.nacimientoMultiple && value === 1),
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                size="small"
                type="number"
                value={form.cantidadHijos}
                onChange={field.onChange}
                inputProps={{ min: 1, max: 10 }} // Ajusta los valores mínimos y máximos según sea necesario
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error
                    ? "El valor debe ser mayor a 1 si es un nacimiento múltiple, o 1 si no lo es."
                    : null
                }
              />
            )}
          />
        </FormControl>
      )}
      <FormControl fullWidth>
        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
          Fecha de Nacimiento
        </FormLabel>
        {form.nacimientoMultiple && (
          <small className="text-[#5836ff]">
            *Si el alumbramiento múltiple se produce en distintas fechas, deberás completar el campo con la fecha de quien nació primero/a.
          </small>
        )}

        <Controller
          name="fechaInicio"
          control={control}
          render={({ field: { onChange } }) => (
            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
              <DatePicker
                key="fechaInicio"
                open={open}
                onClose={()=> setOpen(false)}
                value={form.fechaInicio ? dayjs(form.fechaInicio) : ''}
                onChange={(e) => onChange(handleDateChange(e))}
                renderInput={(props) => <TextField {...props} fullWidth />}
                slotProps={{ textField: { size: "small", error: false, onClick: ()=> setOpen(true) } }}
                minDate={
                  dayjs(getDate(-30))
                }
                maxDate={
                  dayjs(getDate(0))
                }
              />
            </LocalizationProvider>
          )}
        />
      </FormControl>
      <FormGroup sx={{ mt: 2 }}>
        <FormControlLabel
          control={
            <Controller
              name="avisoASuperior"
              control={control}
              render={({ field: { onChange, checked } }) => (
                <Checkbox checked={form.avisoASuperior} onChange={onChange} />
              )}
            />
          }
          label="Di aviso a mi superior"
        />
      </FormGroup>
      <Typography variant="body1" sx={{ mt: 2 }}>
      Recordá que para gestionar una licencia por MIA primero deberás haberle dado aviso a tu superior.
      </Typography>
    </>
  );
}
