import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@mui/material";
import { selectLicenses } from "../licenseSlice";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { selectDatosPersonales } from "../../user/userSlice";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import moment from "moment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { selectDocuments } from "../../files/filesSlice";

export default function MedResume({ control }) {
  const { form, data } = useSelector(selectLicenses);
  const { datos, domicilio, otrosDomicilios, familiares } = useSelector(
    selectDatosPersonales
  );
  const { extraDocuments, documents , dniFamiliar , documentsMobile , extraDocumentsMobile, dniFamiliarMobile} = useSelector(selectDocuments);

  const {
    regimen,
    tipoLicencia,
    licencia,
    unidadesOrganizativas,
    diagnostico,
    observaciones,
    familiar
  } = form;

  

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event as returning a string will prompt the user
      const message = '¿Seguro que quieres recargar la página?';
      event.returnValue = message;
      // Return the message for older browsers
      return message;
    };

    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures that the effect runs only once
  const parseDate = (date) => {
    const fechaFormateada = date.toDate
      ? moment(date.toDate()).format("DD/MM/YYYY")
      : moment(date).format("DD/MM/YYYY");
    return fechaFormateada;
  };
  const getDomicilio = () => {
    const data =
      domicilio?.direccionId === form.domicilio
        ? domicilio
        : otrosDomicilios?.find((x) => x.direccionId === form.domicilio);
    return data;
  };
  const formatDomicilio = () => {
    const domicilio = getDomicilio();
    return `${domicilio.calle} ${domicilio.altura} ${domicilio.piso ?? ""} ${
      domicilio.depto ?? ""
    } - CP: ${domicilio.codPostal} `;
  };
  const formatPhone = () => {
    return `${domicilio.telefono.countryCode} ${domicilio.telefono.areaCode} ${domicilio.telefono.phoneNumber}`;
  };

									
  return (
    <>
      <Box bgcolor="#5836ff" p={2} my={2} color="white">
        <Typography variant="subtitle1">
          <TaskAltIcon sx={{ mr: 2 }} /> Revisá que la información sea la
          correcta
        </Typography>
      </Box>

      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        Información general de tu licencia
      </Typography>
      <Divider sx={{ border: "1px solid black", mb: 3 }} />

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Régimen:
        </Typography>
        <Typography>
          {data.regimenes.find((r) => r.code === regimen)?.description}
        </Typography>
      </Box>

      <Box mb={1}>
        <Typography color="#5836ff" sx={{ fontWeight: "bold" }}>
          Unidad/es Organizativa/s:
        </Typography>
        {unidadesOrganizativas?.filter(
            (value, index, self) =>
              index === self.findIndex((v) => v.code === value.code)
          )
          ?.map((x) => (
          <Typography key={x.code}>
            {x.code} {x.description}
          </Typography>
        ))}
      </Box>

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Observaciones:
        </Typography>
        <Typography>{observaciones || "Sin observaciones"}</Typography>
      </Box>
      <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: "bold" }}>
        Información de la solicitud
      </Typography>
      <Divider sx={{ border: "1px solid black", mb: 3 }} />
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Tipo de Licencia:
        </Typography>
        <Typography>{tipoLicencia?.description}</Typography>
      </Box>

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Licencia:
        </Typography>
        <Typography>{licencia?.description}</Typography>
      </Box>

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Diagnóstico:
        </Typography>
        <Typography>{diagnostico.description}</Typography>
      </Box>

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Fecha del certificado:
        </Typography>
        <Typography>{parseDate(form.fechaCertificado)}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Inicio del ausentismo laboral:
        </Typography>
        <Typography>{parseDate(form.fechaInicio)}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Días del certificado:
        </Typography>
        <Typography>{form.diasCertificado}</Typography>
      </Box>
      
      {form.tipoLicencia?.code === "MEF" && (
        <>
        {
          familiar &&
          (<>
          <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: "bold" }}>
            Datos del/la familiar
          </Typography>
          <Divider sx={{ border: "1px solid black", mb: 3 }} />
          <Box display="flex" flexDirection="row" mb={1}>
            <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
              Nombre:
            </Typography>
            <Typography>
              {familiar.nombre}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" mb={1}>
            <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
              Apellido:
            </Typography>
            <Typography>
              {familiar.apellido}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" mb={1}>
            <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
              DNI:
            </Typography>
            <Typography>
              {familiar.documento}
            </Typography>
          </Box>
           </>)
        }
        </>
      )}
      <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: "bold" }}>
        Datos del/la médico/a
      </Typography>
      <Divider sx={{ border: "1px solid black", mb: 3 }} />

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Matrícula:
        </Typography>
        <Typography>
          {form.tipoMatricula === 1 ? "MP" : "MN"} {form.matricula}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Nombres:
        </Typography>
        <Typography>{form.nombre}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Apellidos:
        </Typography>
        <Typography>{form.apellido}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Especialidad médica:
        </Typography>
        <Typography>{form.especialidad}</Typography>
      </Box>

      <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: "bold" }}>
        Información de contacto
      </Typography>
      <Divider sx={{ border: "1px solid black", mb: 3 }} />
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Domicilio:
        </Typography>
        <Typography>{formatDomicilio()}</Typography>
      </Box>

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Correo electrónico:
        </Typography>
        <Typography>{datos.email}</Typography>
      </Box>

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Teléfono:
        </Typography>
        <Typography>{formatPhone()}</Typography>
      </Box>

      <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: "bold" }}>
        Documentos Adjuntos
      </Typography>
      <Divider sx={{ border: "1px solid black", mb: 3 }} />
      <Box mb={1}>
        {documents?.map((x) => (
          <>
            <Typography key={x.id}>
              <AttachFileIcon></AttachFileIcon>
              {x.name}
            </Typography>
          </>
        ))}
                {documentsMobile?.map((x) => (
          <>
            <Typography key={x.id}>
              <AttachFileIcon></AttachFileIcon>
              {x.Name}
            </Typography>
          </>
        ))}
      </Box>
      <Box mb={1}>
        {extraDocuments?.map((x) => (
          <>
            <Typography key={x.id}>
              <AttachFileIcon></AttachFileIcon>
              {x.name}
            </Typography>
          </>
        ))}
                {extraDocumentsMobile?.map((x) => (
          <>
            <Typography key={x.id}>
              <AttachFileIcon></AttachFileIcon>
              {x.Name}
            </Typography>
          </>
        ))}
      </Box>

      {form.tipoLicencia?.code === "MEF" && (
        <>
          <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: "bold" }}>
            DNI Familiar
          </Typography>
          <Divider sx={{ border: "1px solid black", mb: 3 }} />
          <Box mb={1}>
            {dniFamiliar?.map((x) => (
              <>
                <Typography key={x.id}>
                  <AttachFileIcon></AttachFileIcon>
                  {x.name}
                </Typography>
              </>
            ))}
                        {dniFamiliarMobile?.map((x) => (
              <>
                <Typography key={x.id}>
                  <AttachFileIcon></AttachFileIcon>
                  {x.Name}
                </Typography>
              </>
            ))}
          </Box>
        </>
      )}

      <Box mt={2}>
        <FormControlLabel
          control={
            <Controller
              name="confirmacion"
              control={control}
              render={({ field: { onChange } }) => (
                <Checkbox
                  checked={form.confirmacion}
                  onChange={onChange}
                  sx={{ pt: 0 }}
                />
              )}
            />
          }
          label="Entiendo que esta solicitud tiene carácter de declaración jurada y confirmo que los datos ingresados son verdaderos."
          sx={{ alignItems: "flex-start" }}
        />
      </Box>
    </>
  );
}