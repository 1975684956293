import * as React from "react";
import LicenseMenu from "./license-menu";
import Card from "../card";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllLicenciasAsync,
  fetchAllTypeLicenciasAsync,
  fetchRegimenesAsync,
} from "./licenseSlice";
import { getSetting } from "../parameters/parametersSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { selectModals } from "../modals/modalSlice";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// import Camera from './react-camera'
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default function License({openNotification,setOpenNotification}) {
  const dispatch = useDispatch();
  const { allLicences } = useSelector((state) => state.licencia);
  const { datos, perfilActual } = useSelector((state) => state.usuario);
  const { confirmModal } = useSelector(selectModals);
  const [ord, setOrd] = React.useState(1);
  const [dates, setDates] = React.useState({
    desde: null,
    hasta: null,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const actBtnMobile = queryParams.get("actBtnMobile");
  
  const handleChangeDates = (action, event) => {
    switch (action) {
      case "desde":
        setDates({ ...dates, desde: event });
        break;
      case "hasta":
        setDates({ ...dates, hasta: event });
        break;
      default:
        break;
    }
  };

  const [abiertasName, setAbiertasName] = React.useState([]);
  const [licenseTypes, setLicenses] = React.useState([]);
  const [activeButton, setActiveButton] = React.useState("abiertas");

  const handleButtonChange = (e) => {
    setActiveButton(e);
  };

  React.useEffect(() => {
    dispatch(fetchAllTypeLicenciasAsync()).then((x) => {
      const newLicenses = [
        ...x.payload,
        { id: "00", code: "ABI", description: "Licencias Abiertas" },
      ];
      setLicenses(newLicenses);
      setAbiertasName(x.payload);
    });
    dispatch(fetchRegimenesAsync(datos.idHR));
    dispatch(getSetting("extensions"));
  }, []);

  React.useEffect(()=>{
    if(actBtnMobile === "ABI") setActiveButton("abiertas")
    if(actBtnMobile === "MED") setActiveButton("medicas")
    if(actBtnMobile === "ADM") setActiveButton("administrativas")
    if(actBtnMobile === "ART") setActiveButton("art")
    if(actBtnMobile === "HIS") setActiveButton("historial")
  },[actBtnMobile])

  React.useEffect(() => {
    let filters = [];
    if (activeButton === "abiertas") {
      filters = licenseTypes.filter((x) => x.code !== "ABI");
      setAbiertasName(filters);
      dispatch(
        fetchAllLicenciasAsync({
          id: datos.id,
          filters: [
            ...filters,
            { code: "ABI", description: "Licencias Abiertas" },
            { code: "LT", description: "Largo Tratamiento" },
          ],
          ord,
        })
      );
    } else if (activeButton === "medicas") {
      filters = licenseTypes.filter(
        (x) => x.code !== "ADM" && x.code !== "ART" && x.code !== "ABI"
      );
      setAbiertasName(filters);
      dispatch(fetchAllLicenciasAsync({ id: datos.id, filters, ord }));
    } else if (activeButton === "administrativas") {
      filters = licenseTypes.filter((x) => x.code === "ADM");
      dispatch(fetchAllLicenciasAsync({ id: datos.id, filters, ord }));
    } else if (activeButton === "art") {
      filters = licenseTypes.filter((x) => x.code === "ART");
      dispatch(fetchAllLicenciasAsync({ id: datos.id, filters, ord }));
    } else if (activeButton === "historial") {
      filters = [{ code: "HIS", description: "Historial Licencias" }];
      if (dates.desde)
        filters.push({ code: "desde", description: dates.desde });
      if (dates.hasta)
        filters.push({ code: "hasta", description: dates.hasta });
      dispatch(fetchAllLicenciasAsync({ id: datos.idHR, filters, ord }));
    }
  }, [activeButton, ord, setAbiertasName, dates]);

  const hasCategoryFilter = (activeButton) => {
    var filteredSections = ["abiertas", "medicas"];
    return filteredSections.indexOf(activeButton) > -1;
  };

  React.useEffect(() => {
    if (!confirmModal.open) refreshData([...abiertasName]);
  }, [confirmModal.open]);

  const refreshData = (filter) => {
    if (!filter) return;
    if (activeButton === "abiertas")
      dispatch(
        fetchAllLicenciasAsync({
          id: datos.id,
          filters: [
            ...filter,
            { code: "ABI", description: "Licencias Abiertas" },
          ],
          ord,
        })
      );
    else
      dispatch(fetchAllLicenciasAsync({ id: datos.id, filters: filter, ord }));
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    if (perfilActual.idPerfil === "ADM_NOVEDADES_RH") {
      navigate("/foco-rrhh");
    }
  }, [perfilActual]);

  const handleSolicitarLicencia = () => {
    navigate("/license-request-form");
  };

  return (
    <>
      {/* <Camera/> */}
      <LicenseMenu
      openNotification={openNotification}
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        handleButtonChange={handleButtonChange}
      />

      <Box className="license">

        <Box className="filters -z-50" style={{zIndex:"-50"}}>
        <p className="hiddenWeb">
              {activeButton === "abiertas"
                ?       <Button
                name="abiertas"
                color="terciary"
                variant={"contained"}
                className={`menuBtn abiertas text-white `}
                style={{
                  backgroundColor:"#b13dff",
                }}
              >
                Licencias Abiertas
              </Button>
                : activeButton === "medicas"
                ? <Button
                name="abiertas"
                color="terciary"
                variant={"contained"}
                className={`menuBtn abiertas text-white `}
              >
                Licencias Médicas
              </Button>
                : activeButton === "administrativas"
                ? <Button
                name="abiertas"
                color="terciary"
                variant={"contained"}
                className={`menuBtn abiertas text-white `}
                style={{
                  backgroundColor:"#ffb13d",
                }}
              >
                Licencias Administrativas
              </Button>
                : activeButton === "art"
                ? <Button
                name="abiertas"
                color="terciary"
                variant={"contained"}
                className={`menuBtn abiertas text-white `}
                style={{
                  backgroundColor:"#ff3d8b",
                }}
              >
                Licencias de ART
              </Button>
                : activeButton === "historial"
                ? <Button
                name="abiertas"
                color="terciary"
                variant={"contained"}
                className={`menuBtn abiertas text-white `}
                style={{
                  backgroundColor:"#00bdb8",
                }}
              >
                Otras Licencias
              </Button>
                : null}
            </p>
          <FormControl size="small" style={{ width: "150px" }}>
            <InputLabel id="demo-simple-select-label">
              Ordenar por
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ord}
              label="Ordenar por"
              onChange={(e) => setOrd(e.target.value)}
            >
              <MenuItem key="reciente" value={1}>
                Reciente
              </MenuItem>
              <MenuItem key="antiguas" value={0}>
                Antigua
              </MenuItem>
            </Select>
          </FormControl>
          {activeButton === "historial" && (
            <LocalizationProvider
              className="flex"
              adapterLocale="es"
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                onChange={(e) => handleChangeDates("desde", e)}
                sx={{ width: "220px" }}
                slotProps={{ textField: { size: "small" } }}
                className="datepicker"
                value={dates.desde}
                label="Desde"
              />
              <DatePicker
                onChange={(e) => handleChangeDates("hasta", e)}
                sx={{ width: "220px" }}
                slotProps={{ textField: { size: "small" } }}
                className="datepicker"
                label="Hasta"
                value={dates.hasta}
              />
              <Button
                style={{ textTransform: "capitalize" }}
                color="terciary"
                onClick={() => setDates({ desde: null, hasta: null })}
                variant="outlined"
                className="w-fit"
              >
                Borrar filtros
              </Button>
            </LocalizationProvider>
          )}

          {hasCategoryFilter(activeButton) && (
            <FormControl size="small" sx={{ width: 270 }}>
              <InputLabel id="demo-multiple-checkbox-label">
                Filtrar por
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={abiertasName ? abiertasName : []}
                input={<OutlinedInput label="Filtrar por" />}
                renderValue={(selected) =>
                  selected.map((x) => x.description).join(", ")
                }
                MenuProps={MenuProps}
              >
                {licenseTypes
                  .filter((x) => x.code !== "ABI")
                  .filter(
                    (x) =>
                      activeButton === "abiertas" ||
                      (activeButton === "medicas" &&
                        x.code !== "ADM" &&
                        x.code !== "ART")
                  )
                  .map((x) => (
                    <MenuItem
                      key={x.code}
                      value={x.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAbiertasName([...abiertasName, x]);
                          refreshData([...abiertasName, x]);
                        } else {
                          setAbiertasName(
                            abiertasName.filter((ele) => ele.id !== x.id)
                          );
                          refreshData(
                            abiertasName.filter((ele) => ele.id !== x.id)
                          );
                        }
                        // setTimeout(() => {
                        //   refreshData();q   c
                        // });
                      }}
                    >
                      <Checkbox
                        checked={abiertasName.some((ele) => ele.id === x.id)}
                        size="small"
                      />
                      <ListItemText
                        className="text-left"
                        primary={x.description}
                        size="small"
                      />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Box>
        {allLicences?.length === 0 ? (
          <Typography
            variant="h5"
            textAlign="center"
            color="#5836ff"
            sx={{ fontWeight: "bold", textAlign: "center" , mt:"100px" , fontSize:"18px"}}
          >
            No hay licencias para mostrar
          </Typography>
        ) : (
          <Box className="cards" component="main">
            {allLicences?.map((licence) => (
              <Card
              openNotification={openNotification}
              setOpenNotification={setOpenNotification}
                ord={ord}
                dates={dates}
                licenseTypes={licenseTypes}
                activeButton={activeButton}
                license={licence}
                refreshData={refreshData}
              />
            ))}
            
          </Box>
        )}
        <Tooltip title="Solicitar Licencia">
              <p
                className="fixed hiddenWeb bottom-14 right-5 hover:scale-110 cursor-pointer duration-300 bg-slate-700 w-[70px] h-[70px] flex justify-center items-center rounded-full shadow-md"
                variant="contained"
                onClick={handleSolicitarLicencia}
              >
                <AddOutlinedIcon
                  style={{ color: "#fff", transform: "scale(1.3)" }}
                />
              </p>
            </Tooltip>
      </Box>
      <div className="h-14 w-screen hiddenWeb rounded-t-md flex justify-around text-xs text-white bg-[#5836ff] bottom-0 fixed">
              <button onClick={()=>setActiveButton("abiertas")}>Licencias <br />Abiertas</button>
              <button onClick={()=>setActiveButton("medicas")}>Licencias <br />Médicas</button>
              <button onClick={()=>setActiveButton("administrativas")}>Licencias <br />Administrativas</button>
              <button onClick={()=>setActiveButton("art")}>Licencias <br />ART</button>
              <button onClick={()=>setActiveButton("historial")}>Otras</button>
      </div>
    </>
  );
}
