import React, { useEffect } from "react";
import Menu from "../audit-menu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAuditLicenciasHistoryAsync, fetchOneArtLicenciaAsync } from "../auditSlice";
import FiltersHistory from "./filters";
import { Box, Button } from "@mui/material";
import { verificarObjetos } from "../../../utils";
import HistoryTable from "./history-table";
import { fetchAllTypeLicenciasAsync } from "../../license/licenseSlice";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const initialFilters = {
  tipoLicencia:"",
  fechaInicio: null,
  fechaFin: null,
};
function History() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { oneLicence, history } = useSelector((state) => state.auditLicence);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filters, setFilters] = React.useState(initialFilters);
  const [activeButton, setActiveButton] = React.useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const licenseTypeId = queryParams.get("licenseTypeId");
  const handleBuscar = () => {
    const filterArray = [];
    for (const key in filters) {
      const value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        filterArray.push({ code: key, description: value });
      }
    }
    //{code:"status",description:pendientes}
    dispatch(
      fetchAllAuditLicenciasHistoryAsync({ id: oneLicence.idhr, filters: filterArray ,         pageNumber: 0,
        pageSize:rowsPerPage})
    ).then(x=>{
      setPage(0)
    });
  };

  const handleReset = () => {
    setFilters(initialFilters);
    dispatch(
      fetchAllAuditLicenciasHistoryAsync({
        id: oneLicence.idhr,
        filters: [],
        pageNumber:page,
        pageSize:rowsPerPage
      })
    );
  };

  useEffect(() => {
    dispatch(fetchOneArtLicenciaAsync({id,licenseTypeId})).then(res=>{
      dispatch(
        fetchAllAuditLicenciasHistoryAsync({
          id: res.payload.idhr,
          filters: [],
          pageNumber:0,
          pageSize:rowsPerPage
        })
      );
    });
  }, []);

  React.useEffect(() => {
    dispatch(fetchAllTypeLicenciasAsync());
  }, []);

  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);

  const pageAct = queryParams.get("pageAct");
  return (
    <div>
      <Menu
        title={"Historial de licencia"}
        subtitle={oneLicence.firstName + " " + oneLicence.lastName}
        idHr={oneLicence.idhr}
        cuil={oneLicence.cuit}
      />
      <section className="px-4 py-2">
      <FiltersHistory
        filters={filters}
        setFilters={setFilters}
        activeButton={activeButton}
        handleBuscar={handleBuscar}
        disabled={verificarObjetos(filters, initialFilters)}
        handleReset={handleReset}
      />
        <div className="px-3 flex flex-col sm:flex-row gap-2">
        <Button variant="outlined" startIcon={<ArrowBackIosIcon/>} onClick={()=>navigate(`/audit/${oneLicence.id}?pageAct=${pageAct}&licenseTypeId=${licenseTypeId}`)}>Volver</Button>
        <Button variant="outlined" endIcon={<ArrowForwardIosIcon/>} onClick={()=>navigate(`/audit/allhistory/${oneLicence.id}?pageAct=${pageAct}&licenseTypeId=${licenseTypeId}`)}>Otras licencias</Button>
        </div>
      <HistoryTable idhr={oneLicence.idhr} filters={filters} page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage} />
      </section>
    </div>
  );
}

export default History;
