export const LicStatusEnum = {
    INVALID : 0,
    PENDIENTE_DE_AUTORIZACION_SUPERIOR : 1,
    CARGA_DOC_RESPALDATORIA_PENDIENTE : 2,
    INGRESADA_PENDIENTE_COMPLETAR : 3,
    EN_REVISION : 4,
    INGRESADA : 5,
    ANULADA : 6,
    AUTORIZADA : 7,
    RECHAZADA : 8,
    CERRADA : 9,
    APROBADA : 10,
    RECHAZADAPORINCONSISTENCIA : 11,
    REGISTRADAENPRESTADORA : 12,
    PENDIENTE : 13,
    NO_REGISTRADA : 14,
    ALTA_LARGO_TRATAMIENTO: 15,
    AUTORIZADA_LARGO_TRATAMIENTO: 16,
    PENDIENTE_LARGO_TRATAMIENTO: 17,
    DOC_RESPALDATORIA_MODIFICAR:18,
    NOTIFICACION_AUSENCIA_COMPLETA:19
}