import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@mui/material";
import { selectLicenses } from "../licenseSlice";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { selectDatosPersonales } from "../../user/userSlice";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import moment from "moment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { selectDocuments } from "../../files/filesSlice";

export default function ArtResume({ control }) {
  const { form } = useSelector(selectLicenses);
  const { extraDocuments, documents } = useSelector(selectDocuments);
  const { datos, domicilio, otrosDomicilios } = useSelector(
    selectDatosPersonales
  );
  const {
    tipoLicencia,
    licencia,
    observaciones,
  } = form;

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event as returning a string will prompt the user
      const message = '¿Seguro que quieres recargar la página?';
      event.returnValue = message;
      // Return the message for older browsers
      return message;
    };

    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures that the effect runs only once
  const parseDate = (date) => {
    const fechaFormateada = date.toDate
      ? moment(date.toDate()).format("DD/MM/YYYY")
      : moment(date).format("DD/MM/YYYY");
    return fechaFormateada;
  };
  const getDomicilio = () => {
    const data =
      domicilio?.direccionId === form.domicilio
        ? domicilio
        : otrosDomicilios?.find((x) => x.direccionId === form.domicilio);
    return data;
  };
  const formatDomicilio = () => {
    const domicilio = getDomicilio();
    return `${domicilio.calle ?? ""} ${domicilio.altura ?? ""} ${domicilio.piso ?? ""} ${domicilio.depto ?? ""
      } ${domicilio.codPostal ? "- CP:" : ""} ${domicilio.codPostal ?? ""} `;
  };

  const formatPhone = () => {
    return `${domicilio.telefono.countryCode} ${domicilio.telefono.areaCode} ${domicilio.telefono.phoneNumber}`;
  };

  return (
    <>
      <Box bgcolor="#5836ff" p={2} my={2} color="white">
        <Typography variant="subtitle1">
          <TaskAltIcon sx={{ mr: 2 }} /> Revisá que la información sea la
          correcta
        </Typography>
      </Box>

      <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
        Información general de tu licencia
      </Typography>
      <Divider sx={{ border: "1px solid black", mb: 3 }} />
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Tipo de Licencia:
        </Typography>
        <Typography>
          {
            tipoLicencia.description
          }
        </Typography>
      </Box>

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Licencia:
        </Typography>
        <Typography>{licencia?.description}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Fecha de ocurrencia/siniestro:
        </Typography>
        <Typography>{parseDate(form.fechaSiniestro)}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Número de siniestro otorgado:
        </Typography>
        <Typography>B{form.nroSiniestro}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Fecha de la denuncia:
        </Typography>
        <Typography>{parseDate(form.fechaDenuncia)}</Typography>
      </Box>
      <Box display="flex flex-col" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          {licencia?.description === "Accidente de Trabajo" ||
            licencia?.description === "Accidente In Itinere"
            ? "Descripción del accidente:"
            : licencia?.description === "Enfermedad Profesional"
              ? "Descripción de la enfermedad profesional:"
              : null}
        </Typography>
        <Typography>
          {licencia?.description === "Accidente de Trabajo" ||
            licencia?.description === "Accidente In Itinere"
            ? form.descripcionAccidente
            : licencia?.description === "Enfermedad Profesional"
              ? form.descripcion
              : null}
        </Typography>
      </Box>
      {licencia?.description !== "Enfermedad Profesional" && (
        <>
          <Box display="flex" flexDirection="row" mb={1}>
            <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
              Hora del siniestro:
            </Typography>
            <Typography>
              {form.horaSiniestro.format("HH:mm") + " hs."}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" mb={1}>
            <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
              Jornada Laboral:
            </Typography>
            <Typography>
              {form.jornadaLaboralDesde.format("HH:mm") +
                " - " +
                form.jornadaLaboralHasta.format("HH:mm") +
                " hs."}
            </Typography>
          </Box>
        </>
      )}
      <Box display="flex" flexDirection="column" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          {licencia?.description === "Accidente de Trabajo" ||
            licencia?.description === "Accidente In Itinere"
            ? "Direccion del accidente:"
            : licencia?.description === "Enfermedad Profesional"
              ? "Domicilio:"
              : null}
        </Typography>
        <Typography>{formatDomicilio()}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Observaciones:
        </Typography>
        <Typography>{observaciones || "Sin observaciones"}</Typography>
      </Box>

      <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: "bold" }}>
        Información de contacto
      </Typography>
      <Divider sx={{ border: "1px solid black", mb: 3 }} />

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Correo electrónico:
        </Typography>
        <Typography>{datos.email}</Typography>
      </Box>

      <Box display="flex" flexDirection="row" mb={1}>
        <Typography color="#5836ff" sx={{ mr: 1, fontWeight: "bold" }}>
          Teléfono:
        </Typography>
        <Typography>{formatPhone()}</Typography>
      </Box>

      <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: "bold" }}>
        Documentos Adjuntos
      </Typography>
      <Divider sx={{ border: "1px solid black", mb: 3 }} />
      <Box mb={1}>
        {documents?.map((x) => (
          <>
            <Typography key={x.id}>
              <AttachFileIcon></AttachFileIcon>
              {x.name}
            </Typography>
          </>
        ))}
      </Box>
      <Box mb={1}>
        {extraDocuments?.map(x =>
          <>
            <Typography key={x.id}>
              <AttachFileIcon></AttachFileIcon>
              {x.name}
            </Typography>
          </>
        )}
      </Box>
      <Box mt={2}>
        <FormControlLabel
          control={
            <Controller
              name="confirmacion"
              control={control}
              render={({ field: { onChange } }) => (
                <Checkbox
                  checked={form.confirmacion}
                  onChange={onChange}
                  sx={{ pt: 0 }}
                />
              )}
            />
          }
          label="Entiendo que esta solicitud tiene carácter de declaración jurada y confirmo que los datos ingresados son verdaderos."
          sx={{ alignItems: "flex-start" }}
        />
      </Box>
    </>
  );
}
