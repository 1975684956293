import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from './app/store';
import { Auth0Provider } from '@auth0/auth0-react';
import { PersistGate } from 'redux-persist/integration/react';
import Spinner from './features/spinner';
import Alerts from './features/alerts';

const container = document.getElementById('root');
const root = createRoot(container);

function OAuthWebView() {

    const [cameraPermissionGranted, setCameraPermissionGranted] = React.useState(false);

  React.useEffect(() => {

    if (window.cordova)
    {
        document.addEventListener("deviceready", function() {

            const permissions = window.cordova.plugins.permissions;

            const checkPermissionCallback = (status) => {
            if (!status.hasPermission) {
                const errorCallback = () => {
                alert('Para ingresar a la aplicacion, necesitas aceptar los permisos');
                navigator.app.exitApp()
                };

            permissions.requestPermission(
                permissions.CAMERA,
                async (cameraStatus) => {
                if (!cameraStatus.hasPermission) {
                  errorCallback();
                } else {
                    // Permiso de cámara otorgado, ahora solicitar permiso de acceso a la galería
                    permissions.requestPermission(
                    permissions.READ_EXTERNAL_STORAGE,
                    (galleryStatusStorage) => {
                        if (galleryStatusStorage.hasPermission) {
                          setCameraPermissionGranted(true);
                        } else {
                          // Permisos para Android 13 o superior
                          permissions.requestPermission(
                            permissions.READ_MEDIA_IMAGES,
                            (galleryStatusImage) => {
                                if (!galleryStatusImage.hasPermission) {
                                errorCallback();
                                } else {
                                // Ambos permisos otorgados
                                setCameraPermissionGranted(true);
                                }
                            },
                            errorCallback
                          );
                        }
                    },
                    errorCallback
                    );
                }
                },
                errorCallback
            );
            } else {
                setCameraPermissionGranted(true);
            }
        };

        permissions.hasPermission([permissions.CAMERA, permissions.READ_EXTERNAL_STORAGE, permissions.READ_MEDIA_IMAGE], checkPermissionCallback, null);

        }, false);
    }

  }, []);

  if (cameraPermissionGranted) {
    return (
      <Auth0Provider
        domain={process.env.REACT_APP_DOMAIN_OAUTH}
        clientId={process.env.REACT_APP_CLIENTID_OAUTH}
        authorizationParams={{
          redirect_uri: process.env.REACT_APP_REDIRECT_URI,
          scope: 'openid',
        }}
      >
        <Spinner />
        <Alerts />
        <App />
      </Auth0Provider>
    );
  } else if (!window.cordova){
    return (
        <Auth0Provider
          domain={process.env.REACT_APP_DOMAIN_OAUTH}
          clientId={process.env.REACT_APP_CLIENTID_OAUTH}
          authorizationParams={{
            redirect_uri: process.env.REACT_APP_REDIRECT_URI,
            scope: 'openid',
          }}
        >
          <Spinner />
          <Alerts />
          <App />
        </Auth0Provider>
      );
  }
  else{
    return <Spinner />;
  }
    
}

root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <BrowserRouter>
                <OAuthWebView />
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();