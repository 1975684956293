import { Box, Button, Container, DialogActions, FormControl, FormLabel, MenuItem, Select, TextField, ThemeProvider, Typography, createTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectStaticData } from "../../parameters/parametersSlice";
import { selectDatosPersonales } from "../userSlice";

export default function FamilyForm({ familiarId, editMode, handleClose, handleSave, license,regimen }) {
    const { familiares } = useSelector(selectDatosPersonales);
    const { user } = useSelector(selectStaticData);
    
    const familiar = editMode ? familiares.find(x => x.id === familiarId.id) : {};
    const theme = createTheme({
        typography: {
            h1: {
                fontSize: 24,
                fontWeight: 600,
                color: "#1d5a87",
            },
            h2: {
                fontSize: 20,
            },
            label: {
                fontWeight: 500,
            },
        },
    });

    const { control, getValues, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            id: familiar?.id,
            documento: familiar?.documento,
            apellido: familiar?.apellido,
            nombre: familiar?.nombre,
            parentesco: familiar?.parentesco
        },
        rules:
        {
            documento: {
                required: true,
                valueAsNumber: true,

            }
        }
    });


    return (
        <ThemeProvider theme={theme}>
            <Container
                sx={{ py: 2, display: "flex", flexDirection: "column" }}
            >
                <Box>
                    <FormControl fullWidth>
                        <FormLabel sx={{ color: '#5836ff', fontWeight: 'bold' }}>Nombre</FormLabel>
                        <Controller
                            name="nombre"
                            control={control}

                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField {...field} size="small" />
                            )}
                        />
                        {errors.nombre && (
                            <Typography sx={{ color: "#Ff0000" }}>
                                Campo requerido
                            </Typography>
                        )}
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        paddingTop: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <FormControl fullWidth>
                        <FormLabel sx={{ color: '#5836ff', fontWeight: 'bold' }}>Apellido</FormLabel>
                        <Controller
                            name="apellido"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <TextField {...field} size="small" type="text" />
                            )}
                        />
                        {errors.apellido && (
                            <Typography sx={{ color: "#Ff0000" }}>
                                Campo requerido
                            </Typography>
                        )}
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        paddingTop: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <FormLabel sx={{ color: '#5836ff', fontWeight: 'bold' }}>Tipo de documento</FormLabel>
                    <Controller
                        name="tipoDocumento"
                        control={control}
                        render={({ field: { onChange } }) => (
                            <Select
                                size="small"
                                sx={{ minWidth: 300 }}
                                labelId="tipoDocumento"
                                className="input-container"
                                rules={{ required: true }}
                                defaultValue={familiar?.tipoDocumento}
                                onChange={onChange}
                            >
                                {user.documentTypes.filter(e=> e.id != 1).map((dt, i) => (
                                    <MenuItem
                                        key={`dt-${i}`}
                                        value={dt.id}>
                                        {dt.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </Box>

                <Box
                    sx={{
                        paddingTop: 2,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <FormControl fullWidth>
                        <FormLabel sx={{ color: '#5836ff', fontWeight: 'bold' }}>Documento</FormLabel>
                        <Controller
                            name="documento"
                            control={control}
                            rules={{
                                validate: {
                                    positive: v => parseInt(v) > 0,
                                    lessThanTen: v => parseInt(v) < 99999999,
                                }
                            }}
                            render={({ field }) => (
                                <TextField {...field} inputProps={{ maxLength: 8 }} size="small" type="text" />
                            )}
                        />
                        {errors.documento && (
                            <Typography sx={{ color: "#Ff0000" }}>
                                Campo requerido
                            </Typography>
                        )}
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        paddingTop: 2,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <FormLabel sx={{ color: '#5836ff', fontWeight: 'bold' }}>Parentesco</FormLabel>
                    {regimen == "DOC" && 
                        <Controller
                        name="parentesco"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange } }) => (
                            <Select
                                size="small"
                                sx={{ minWidth: 300 }}
                                labelId="parentesco"
                                className="input-container"
                                defaultValue={familiar?.parentesco}
                                onChange={onChange}
                            >
                               
                                 {user.familyKindship.filter(e=> e.id != 7 && e.id != 9).map((dt, i) => (
                                     <MenuItem
                                         key={`dt-${i}`}
                                         value={dt.id}>
                                         {dt.description}
                                     </MenuItem>
                                 ))}
  
                            </Select>
                        )}
                    />
                    }
                     {regimen != "DOC" && 
                        <Controller
                        name="parentesco"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange } }) => (
                            <Select
                                size="small"
                                sx={{ minWidth: 300 }}
                                labelId="parentesco"
                                className="input-container"
                                defaultValue={familiar?.parentesco}
                                onChange={onChange}
                            >
                                
                                {user.familyKindship.map((dt, i) => (
                                    <MenuItem
                                        key={`dt-${i}`}
                                        value={dt.id}>
                                        {dt.description}
                                    </MenuItem>
                                ))}
                                 
                            </Select>
                        )}
                    />
                    }
                    {errors.parentesco && (
                        <Typography sx={{ color: "#Ff0000" }}>
                            Campo requerido
                        </Typography>
                    )}
                </Box>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button
                        onClick={handleSubmit(() => handleSave(getValues()))}
                        autoFocus
                        variant="contained"
                    >
                        {editMode ? 'Actualizar' : 'Guardar'}
                    </Button>
                </DialogActions>
            </Container>
        </ThemeProvider>
    );
}
