import {
  FormControl,
  FormLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { selectLicenses } from "../licenseSlice";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/es-mx'
import dayjs from "dayjs";
import moment from "moment/moment";
import { useEffect, useState } from "react";

export default function Step4({ control, setValue }) {
  const { form, parameters } = useSelector(selectLicenses);

  const [open, setOpen] = useState(false);
  const [openFinal, setOpenFinal] = useState(false);

  const fechaInicio = new Date(form.fechaInicio);
  fechaInicio.setDate(fechaInicio.getDate());

  const generateDate = (days, startDate) => {
    const currentDate =
      startDate === "" || startDate === undefined
        ? moment(new Date())
        : moment(startDate);
    const result = sumarDias(startDate, days);
    return result;
  };

  const handleDateChange = (newDate) => {

    if (newDate && newDate.toDate) {
      newDate = newDate.toDate();
    }

    return newDate;
  };

  const sumarDias = (fechaInicial, diasASumar) => {
    let fecha = moment(fechaInicial); // Convertir la fecha inicial a un objeto moment
    for (let i = 0; i < diasASumar; i++) {
      fecha = fecha.add(1, 'days'); // Sumar un día
      while (fecha.day() === 6 || fecha.day() === 0 && ["24BISH", "T179H", "G24BISH", "P24BISH"].includes(parameters.codLicencia)) {
        fecha = fecha.add(1, 'days');
      }
    }
    return fecha; // Devolver la nueva fecha después de sumar los días
  }

  const isWeekend = (date) => {

    const day = date.day();
    return day === 0 || day === 6;
  };

  return (
    <>
      <FormControl fullWidth>
        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
          Fecha de Inicio
        </FormLabel>
        <Controller
          name="fechaInicio"
          control={control}
          render={({ field: { onChange } }) => (
            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
              <DatePicker
                open={open}
                onClose={() => setOpen(false)}
                key="fechaInicio"
                defaultValue={form.fechaInicio ? dayjs(form.fechaInicio) : ""}
                onChange={(e) => {
                  setValue("fechaFin", null);
                  onChange(handleDateChange(e));
                }}
                renderInput={(props) => <TextField {...props} fullWidth />}
                slotProps={{ textField: { size: "small", error: false, onClick: () => setOpen(true) } }}
                shouldDisableDate={["24BISH", "T179H", "G24BISH", "P24BISH"].includes(parameters.codLicencia) ? isWeekend : false}
                minDate={
                  parameters.diasRegistroRetroactivo > 0
                    ? dayjs(
                      generateDate(
                        -Number(parameters.diasRegistroRetroactivo)
                      )
                    )
                    : dayjs(
                      generateDate(
                        Number(parameters.minDiasRegistroAnticipado)
                      )
                    )
                }
                maxDate={dayjs(generateDate(parameters.diasRegistroAnticipado))}
              />
            </LocalizationProvider>
          )}
        />
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
          Fecha de Fin
        </FormLabel>
        <Controller
          name="fechaFin"

          control={control}
          render={({ field: { onChange } }) => (
            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
              <DatePicker
                open={openFinal}
                shouldDisableDate={["24BISH", "T179H", "G24BISH", "P24BISH", "C24BISH"].includes(parameters.codLicencia) ? isWeekend : false}
                onClose={() => setOpenFinal(false)}
                key="fechafin"
                disabled={!form.fechaInicio}
                defaultValue={form.fechaFin ? dayjs(form.fechaFin) : ""}
                value={form.fechaFin}
                renderInput={(props) => <TextField {...props} fullWidth />}
                slotProps={{ textField: { size: "small", error: false, onClick: () => setOpenFinal(true) } }}
                onChange={onChange}
                minDate={
                  parameters.minDiasGoce > 0
                    ? dayjs(
                      generateDate(
                        parameters.minDiasGoce - 1,
                        handleDateChange(fechaInicio)
                      )
                    )
                    : dayjs(fechaInicio)
                }
                maxDate={
                  (parameters.codLicencia == "24BISH" && parameters.maxDiasGoce > 0) ?
                    dayjs(handleDateChange(fechaInicio)).endOf('day') /*Verdadero*/
                    :
                    (parameters.maxDiasGoce > 0 && !["24BISH", "T179H", "G24BISH", "P24BISH", "P24AE"].includes(parameters.codLicencia) ?
                      dayjs(generateDate(parameters.maxDiasGoce - 1, handleDateChange(fechaInicio)))
                      : ["24BISH", "T179H", "G24BISH", "P24BISH", "P24AE", "24AE"].includes(parameters.codLicencia) ? dayjs(fechaInicio)
                        : undefined)
                }
              />
            </LocalizationProvider>
          )}
        />
      </FormControl>
      {parameters.esHoraria === "SI" && (
        <div className="flex sm:flex-row flex-col gap-3">
          <FormControl>
            <Box mt={1} className="flex flex-col">
              <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                Desde
              </FormLabel>
              <Controller
                name="horaInicio"
                control={control}
                render={({ field: { onChange } }) => (
                  <LocalizationProvider
                    adapterLocale="es"
                    dateAdapter={AdapterDayjs}
                  >
                    <TimePicker
                      defaultValue={form.horaInicio}
                      onChange={onChange}
                    />
                  </LocalizationProvider>
                )}
              />
            </Box>
          </FormControl>
          <FormControl>
            <Box mt={1} className="flex flex-col">
              <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                Hasta
              </FormLabel>
              <Controller
                name="horaFin"
                control={control}
                render={({ field: { onChange } }) => (
                  <LocalizationProvider
                    adapterLocale="es"
                    dateAdapter={AdapterDayjs}
                  >
                    <TimePicker

                      shouldDisableTime={(timeValue, clockType) => {

                        if (timeValue.hour() * 60 * 60 + timeValue.minute() * 60 >= form.horaInicio.hour() * 60 * 60 + form.horaInicio.minute() * 60
                          && timeValue.hour() * 60 * 60 + timeValue.minute() * 60 <= form.horaInicio.hour() * 60 * 60 + form.horaInicio.minute() * 60
                          + parameters?.maxHorasGoce * 60 * 60 &&
                          (parameters.minHorasGoce != "0" || parameters.maxHorasGoce != "0") && ["24BISH", "T179H", "G24BISH", "P24BISH", "24AE", "P24AE"].includes(parameters.codLicencia)) {


                          return false;
                        }

                        if (timeValue.hour() * 60 * 60 + timeValue.minute() * 60 >= form.horaInicio.hour() * 60 * 60 + form.horaInicio.minute() * 60
                          &&
                          (parameters.minHorasGoce == "0" || parameters.maxHorasGoce == "0") && ["P24AE", "24AE", "24BISH", "T179H", "G24BISH", "P24BISH"].includes(parameters.codLicencia)) {
                          return false;
                        }
                        return true;
                      }}
                      defaultValue={form.horaFin}
                      onChange={onChange}
                    />
                  </LocalizationProvider>
                )}
              />
            </Box>
          </FormControl>
        </div>
      )}

      <FormGroup sx={{ mt: 2 }}>
        <FormControlLabel
          control={
            <Controller
              name="avisoASuperior"
              control={control}
              render={({ field: { onChange, checked } }) => (
                <Checkbox checked={form.avisoASuperior} onChange={onChange} />
              )}
            />
          }
          label="Di aviso a mi superior"
        />
      </FormGroup>

      <Typography variant="body1" sx={{ mt: 2 }}>
        Recordá que para gestionar una licencia por MIA primero deberás haberle
        dado aviso a tu superior.
      </Typography>
    </>
  );
}
