import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  TablePagination,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";
import { formatDate } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllPublicacionesAsync,
  fetchOnePublicacionAsync,
  removeOnePublicacion,
} from "../../focoSlice";
import CloseIcon from "@mui/icons-material/Close";
import TemplatePort from "../../assets/templatePortImg.png";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const { allPublicaciones } = useSelector(
    (state) => state.foco
  );
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={
          page >= Math.ceil(count / rowsPerPage) - 1 ||
          allPublicaciones?.length === 0 ||
          allPublicaciones?.length < rowsPerPage
        }
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
function Index({ page, setPage, rowsPerPage, setRowsPerPage, filters }) {
  const dispatch = useDispatch();
  const { allPublicaciones, onePublicacion, totalCount } = useSelector(
    (state) => state.foco
  );
  const handleChangePage = (event, newPage) => {
    const filterArray = [];
    for (const key in filters) {
      let value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        if (value === true) {
          value = "1";
        }
        if (key === "cuil") {
          value = value.replace(/[^0-9]/g, "");
        }
        filterArray.push({ code: key, description: value });
      }
    }
    setPage(newPage);
    dispatch(
      fetchAllPublicacionesAsync({
        filters: filterArray,
        pageNumber: newPage,
        pageSize: rowsPerPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      fetchAllPublicacionesAsync({
        filters: [],
        pageNumber: 0,
        pageSize: parseInt(event.target.value, 10),
      })
    );
  };
  const navigate = useNavigate();
  const [modal, setModal] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [filesUploads, setFilesUploads] = useState([]);

  if (allPublicaciones?.length === 0) {
    return (
      <div className="w-100 my-5">
        {" "}
        <h2 style={{ textAlign: "center" }}>
          No existen datos para los valores seleccionados.
        </h2>
      </div>
    );
  } else {
    return (
      <>
        <TablePagination
          labelRowsPerPage="Publicaciones por pagina"
          className="w-fit border-0 ml-auto mr-10 p-0"
          rowsPerPageOptions={[4, 8, 12, { label: "All", value: totalCount }]}
          colSpan={4}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 mx-auto md:grid-cols-4 px-5 gap-4">
          {allPublicaciones?.map((row, index) => {
            let srcImgPortrait = null;
            row.files.map((file) => {
              if (file.file.path.includes("imgPortrait")) {
                if (file.file.bytes) {
                  srcImgPortrait = "data:image/png;base64,".concat(
                    file.file.bytes
                  );
                } else {
                  srcImgPortrait = null;
                }
              }
            });
            return (
              <div
                className="w-[250px] cursor-pointer hover:scale-105 duration-300 shadow border rounded-lg flex flex-col relative overflow-hidden"
                key={index}
                onClick={
                  ()=>navigate(`/foco-post/${row.id}`)
                }
              >
                <img
                  src={srcImgPortrait ?? TemplatePort}
                  alt={`imgPortrait${index}`}
                  className="object-contain w-[300px] h-[168px]"
                />
                <span className="inline-block bg-gray-200 mx-auto rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 w-fit my-2 shadow-md ml-1">
                  {formatDate(row.startdate, true)}
                </span>
                <h1 className="font-bold text-gray-700 text-center capitalize break-words text-xl mb-2 mx-2">
                  {row.title}
                </h1>
              </div>
            );
          })}
        </div>
        <TablePagination
          labelRowsPerPage="Publicaciones por pagina"
          className="w-fit border-0 ml-auto mr-10 p-0"
          rowsPerPageOptions={[4, 8, 12, { label: "All", value: totalCount }]}
          colSpan={4}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        <Dialog
          fullScreen
          open={modal}
          onClose={() => {
            dispatch(removeOnePublicacion());
            setModal(false);
            setImage(null);
            setFilesUploads([]);
          }}
          TransitionComponent={Transition}
        >
        </Dialog>
      </>
    );
  }
}
export default Index;
