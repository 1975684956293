import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, IconButton, TablePagination } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  CheckCircleOutline,
  Cancel,
  HelpOutline,
  Block,
} from "@mui/icons-material";
import { formatDate } from "../../../../utils";
import { fetchAllAuditProLicenciasHistoryAsync } from "../../occupationalMedicineSlice";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const { allHistoryLicences } = useSelector((state) => state.auditProLicence);
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={
          page >= Math.ceil(count / rowsPerPage) - 1 ||
          allHistoryLicences?.length === 0 ||
          allHistoryLicences?.length < rowsPerPage
        }
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function HistoryTable({
  idhr,
  filters,
  page,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  activeBtn
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allHistoryLicences, totalCount } = useSelector(
    (state) => state.auditProLicence
  );
  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "bg-[#5836FF] text-white",
      width: 20,
      align: "center",
      renderCell: (params) => (
        <span
          onClick={() =>
            navigate(`/occupational-medicine/${params.row.id}?pageAct=${page}&activeBtn=${activeBtn}&licenseTypeId=${params.row.idTipoLicencia}`)
          }
          className="hover:underline cursor-pointer text-xs text-center my-[10px]"
        >
          {params.row.id}
        </span>
      ),
      flex: 1,
    },
    {
      field: "subtitle",
      headerName: "Licencia",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center  my-[10px]">
          {params.row.subtitle.includes(" ")
            ? params.row.subtitle.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.subtitle}
        </span>
      ),
      flex: 1,
    },
    {
      field: "diagnostico",
      headerName: "Diagnóstico",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      width: 150,
      renderCell: (params) => (
        <span className="text-xs text-center  my-[10px]">
          {params.row.diagnostico?.includes(" ")
            ? params.row.diagnostico
                .split(" ")
                .reduce((result, word, index) => {
                  if (index % 3 === 0) {
                    result.push([]);
                  }
                  result[result?.length - 1].push(word);
                  return result;
                }, [])
                .map((wordGroup, index) => (
                  <React.Fragment key={index}>
                    {wordGroup.join(" ")}
                    <br />
                  </React.Fragment>
                ))
            : params.row.diagnostico}
        </span>
      ),
      flex: 1,
    },
    {
      headerName: "Médico",
      sortable: false,
      headerClassName: "bg-[#5836FF] text-white",
      align: "center",
      renderCell: (params) => {
        let nombreMedico =
          params.row.nombreMedico + " " + params.row.apellidoMedico;
        return (
          <span className="text-xs break-words text-center  my-[10px]">
            {nombreMedico.includes(" ")
              ? nombreMedico.split(" ").map((name, index) => (
                  <React.Fragment key={index}>
                    {name}
                    <br />
                  </React.Fragment>
                ))
              : nombreMedico}
          </span>
        );
      },
      flex: 1,
    },
    {
      field: "diasAprobados",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center">
          Dias <br /> Aprobados
        </div>
      ),
      sortable: false,
      align: "center",
      width: 85,
      renderCell: (params) =>
        params.row.diasAprobados ? (
          <span className="text-xs text-center">
            {params.row.diasAprobados} día
            {params.row.diasAprobados > 1 ? "s" : null}
          </span>
        ) : (
          "---"
        ),
      flex: 1,
    },
    {
      field: "asignacion",
      headerName: "Asignación",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center  my-[10px]">
          {!params.row?.asignacion
            ? "Medicina Laboral"
            : params.row?.asignacion?.includes(" ")
            ? params.row?.asignacion?.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row?.asignacion}
        </span>
      ),
      flex: 1,
    },
    {
      field: "startDate",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center">
          Inicio <br /> Ausentismo
        </div>
      ),
      sortable: false,
      align: "center",
      width: 90,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {formatDate(params.row.startDate)}
        </span>
      ),
      flex: 1,
    },
    {
      field: "endDate",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center">
          Fin <br /> Licencia
        </div>
      ),
      sortable: false,
      align: "center",
      width: 80,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {formatDate(params.row.endDate)}
        </span>
      ),
      flex: 1,
    },
    {
      field: "diasCertificado",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center">
          Dias del <br /> Certificado
        </div>
      ),
      sortable: false,
      align: "center",
      width: 90,
      renderCell: (params) =>
        params.row.diasCertificado ? (
          <span className="text-xs text-center">
            {params.row.diasCertificado} día
            {params.row.diasCertificado > 1 ? "s" : null}
          </span>
        ) : (
          "---"
        ),
      flex: 1,
    },
    {
      field: "statusId",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Estado",
      align: "center",
      width: 70,
      sortable: false,
      renderCell: (params) => (
        <span>
          {params.row.statusId === 0 ? (
            <Block style={{ color: "gray" }} />
          ) : params.row.statusId === 1 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 2 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 3 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 4 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 5 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 6 ? (
            <DoDisturbOnIcon style={{ color: "red" }} />
          ) : params.row.statusId === 7 ? (
            <CheckCircleOutline style={{ color: "green" }} />
          ) : params.row.statusId === 8 ? (
            <Cancel style={{ color: "red" }} />
          ) : params.row.statusId === 9 ? (
            <Block style={{ color: "gray" }} />
          ) : params.row.statusId === 10 ? (
            <CheckCircleOutline style={{ color: "green" }} />
          ) : params.row.statusId === 11 ? (
            <Cancel style={{ color: "red" }} />
          ) : params.row.statusId === 12 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 13 ? (
            <AccessTimeIcon style={{ color: "orange" }} />
          ) : params.row.statusId === 14 ? (
            <HelpOutline style={{ color: "orange" }} />
          ) : null}
        </span>
      ),
      flex: 1,
    },
    {
      field: "tomadaPor",
      align: "center",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: (
        <div className="text-xs text-center">
          Tomada <br /> por
        </div>
      ),
      sortable: false,
      width: 90,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {params.row.tomadaPor?.includes(" ")
            ? params.row.tomadaPor.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.tomadaPor}
        </span>
      ),
      flex: 1,
    },
  ];
  const handleChangePage = (event, newPage) => {
    const filterArray = [];
    for (const key in filters) {
      const value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        filterArray.push({ code: key, description: value });
      }
    }
    //{code:"status",description:pendientes}
    setPage(newPage);
    dispatch(
      fetchAllAuditProLicenciasHistoryAsync({
        id: idhr,
        filters: filterArray,
        pageNumber: newPage,
        pageSize: rowsPerPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      fetchAllAuditProLicenciasHistoryAsync({
        id: idhr,
        filters: [],
        pageNumber: 0,
        pageSize: parseInt(event.target.value, 10),
      })
    );
  };
  if (allHistoryLicences?.length === 0)
    return (
      <p className="text-xl text-center mt-5">
        No existen licencias para los filtros seleccionados
      </p>
    );
  return (
    <div style={{ width: "100%", padding: "10px" }}>
      <div className="relative w-full">
        <div className="w-full flex ">
          <TablePagination
            className="border-0 p-0 ml-auto"
            rowsPerPageOptions={[
              5,
              10,
              25,
              { label: "All", value: totalCount },
            ]}
            colSpan={8}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Licencias por pagina"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          <p className="flex w-[100px] mx-2 items-center">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[28px] h-[28px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
        </div>
        <div className="w-[100%]">
          {allHistoryLicences?.length === 0 ? (
            <p className="text-xl text-center mt-5">
              No existen licencias para los filtros seleccionados
            </p>
          ) : (
            <DataGrid
              className="border-0"
              getRowHeight={() => "auto"}
              hideFooterRowCount
              rows={allHistoryLicences}
              columns={columns}
              hideFooterPagination
            />
          )}
        </div>

        <div className="w-full flex ">
          <TablePagination
            className="border-0 p-0 ml-auto"
            rowsPerPageOptions={[
              5,
              10,
              25,
              { label: "All", value: totalCount },
            ]}
            colSpan={8}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Licencias por pagina"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          <p className="flex w-[100px] mx-2 items-center">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[28px] h-[28px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default HistoryTable;
