import { FormControl } from "@mui/base";
import React from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import {  selectLicenses} from "../licenseSlice";
import { Box, Divider, FormLabel, List, ListItem, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import UploadPrimerInforme from "../upload-file/primerInforme";
import UploadUltimoInforme from "../upload-file/ultimoInforme";
import CustomAlert from "../../alert";
export default function Ecografia({ control }) {
    const { form } = useSelector(selectLicenses)
    const [error, setError] = React.useState({ show: false, data: '' });
    const handleClose = () => {
        setError({ show: false });
      };

    return (
        <>
            <FormControl id="input-certificado" fullWidth>
                <Box>
                    <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                        Ecografía
                    </FormLabel>
                </Box>
                <Typography variant="subtitle1">Cargá los informes de la primer y última ecografía</Typography>
                <Typography variant="subtitle1">Confirmo que es legible y contiene:</Typography>
                <List>
                    <ListItem>
                       <CheckIcon/> Fecha de la atención
                    </ListItem>
                    <ListItem>
                    <CheckIcon/>Nombre y apellido
                    </ListItem>
                    <ListItem>
                    <CheckIcon/>Nombre y apellido del/la médico/a
                    </ListItem>
                    <ListItem>
                    <CheckIcon/>Fecha probable de parto o de gestación
                    </ListItem>
                    <ListItem>
                    <CheckIcon/>DNI del paciente
                    </ListItem>
                    <ListItem>
                    <CheckIcon/>Domicilio del lugar de atención
                    </ListItem>
                </List>



                <Box mt={2} mb={3}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Primer informe (obligatorio)</Typography>
                    <Controller
                        name="file_certificado"
                        control={control}
                        render={() => (
                            <UploadPrimerInforme
                                primerInforme={form.primerInforme}
                            />
                        )}
                    />
                </Box>
            </FormControl>
            <Divider />
            <FormControl id="input-adicionales" fullWidth>
                <Box mt={3}>
                <Typography color="#5836ff" sx={{ mr: 1, fontWeight: 'bold' }}>Último informe (obligatorio)</Typography>
                    <Controller
                        name="file_otros"
                        control={control}
                        render={() => <UploadUltimoInforme ultimoInforme={form.ultimoInforme}/>}
                    />
                </Box>
            </FormControl>
            <CustomAlert error={error} severity='error' onClose={handleClose} />
        </>
    );
}
