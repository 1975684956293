/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { fetchRegimenesAsync, selectLicenses } from "../licenseSlice";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { useEffect } from "react";
import { getSetting } from "../../parameters/parametersSlice";

export default function Step1({ control, setValue }) {
  const dispatch = useDispatch();
  const { datos } = useSelector((state) => state.usuario);
  const { data,form } = useSelector(selectLicenses);

  useEffect(() => {
    dispatch(getSetting('extensions'))
    if (!data.regimenes || data.regimenes?.length === 0)
      dispatch(fetchRegimenesAsync(datos.idHR))
  }, []);

  useEffect(() => {
    setValue('licencia', [], { shouldTouch: true });
  }, [form.tipoLicencia]);

  return (
    <>
      <FormControl fullWidth>
        <FormLabel id="regimen" sx={{ color: "#5836ff", fontWeight: "bold" }}>
          Régimen
        </FormLabel>
        <Controller
          name="regimen"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              size="small"
              labelId="regimen"
              defaultValue={data.regimenes[0]}
              value={value}
              onChange={(e) => {
                onChange(e);
              }}
            >
              {data.regimenes?.map((regimen, i) => (
                <MenuItem key={`regimen-${i}`} value={regimen.code}>
                  {regimen.description}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <FormLabel
          id="tipoLicencia"
          sx={{ color: "#5836ff", fontWeight: "bold" }}
        >
          Tipo de Licencia
        </FormLabel>
        <Controller
          name="tipoLicencia"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              size="small"
              labelId="tipoLicencia"
              defaultValue={data.tipoLicencias.find(c => c.id === value.id)}
              onChange={(e) => {
                onChange(e);
              }}
            >
              {data.tipoLicencias.map((tipo, i) => (
                <MenuItem
                  key={`tipoLicencia-${i}`}
                  value={data.tipoLicencias.find(c => c.id === tipo.id)}
                  >
                  {tipo.description}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </>
  );
}
