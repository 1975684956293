import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  addSelect,
  fetchAllAdmLicenciasAsync,
  removeSelect,
  setSelect,
} from "../administrativeSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../utils";
import Modal from "@mui/material/Modal";
import { updateLicenceStatusAsync } from "../../license/licenseSlice";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Link, useNavigate } from "react-router-dom";
import {
  CheckCircleOutline,
  Cancel,
  HelpOutline,
  Block,
} from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  minHeight: 100,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  h2: {
    fontSize: 20,
  },
  padding: 3,
  borderRadius: "15px",
};

function TablePaginationActions(props) {
  const { allLicences } = useSelector((state) => state.admLicence);
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={
          page >= Math.ceil(count / rowsPerPage) - 1 ||
          allLicences?.length === 0 ||
          allLicences?.length < rowsPerPage
        }
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function AdministrativeTable({
  activeButton,
  onExport,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  filters,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { datos, perfilActual } = useSelector((state) => state.usuario);
  const { allLicences, totalCount, select } = useSelector(
    (state) => state.admLicence
  );
  const [openAut, setOpenAut] = React.useState(false);
  const [openRech, setOpenRech] = React.useState(false);
  const [error, setError] = React.useState(false);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      align: "center",
      headerClassName: "bg-[#5836FF] text-white",
      renderCell: (params) => (
        <span
          onClick={() =>
            navigate(
              `/administrative/${params.row.id}?activeBtn=${activeButton}&pageAct=${page}&licenseTypeId=${params.row.idTipoLicencia}`
            )
          }
          className="underline hover:scale-125 duration-75 cursor-pointer text-xs text-center my-[10px]"
        >
          {params.row.id}
        </span>
      ),
      flex: 1,
      maxWidth: 60,
    },
    {
      field: "cuit",
      headerName: "CUIL",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-center text-xs  my-[10px]">
          {`${params.row.cuit.toString().substring(0, 2)}-${params.row.cuit
            .toString()
            .substring(2, 10)}-${params.row.cuit.toString().substring(10)}`}
        </span>
      ),
      flex: 1,
      minWidth: 120,
    },
    {
      field: "lastName",
      headerName: "Apellido/s",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center  my-[10px]">
          {params.row.lastName.includes(" ")
            ? params.row.lastName.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.lastName}
        </span>
      ),
      flex: 1,
      minWidth: 90,
    },
    {
      field: "firstName",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Nombre/s",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center  my-[10px]">
          {params.row.firstName.includes(" ")
            ? params.row.firstName.split(" ").map((name, index) => (
                <React.Fragment key={index}>
                  {name}
                  <br />
                </React.Fragment>
              ))
            : params.row.firstName}
        </span>
      ),
      flex: 1,
      minWidth: 90,
    },
    {
      field: "title",
      headerName: "Licencia",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      align: "center",
      renderCell: (params) => (
        <span className="text-xs break-words text-center mx-[10px] my-[10px]">
          {params.row.title}
        </span>
      ),
      flex: 1,
    },
    {
      field: "unidades",
      align: "center",
      headerName: (
        <div className="text-xs text-center w-full">
          Unidad <br /> Organizativa
        </div>
      ),
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      renderCell: (params) =>
        params.row.unidades.map((ele) => {
          return (
            <span key={ele.code} className="text-wrap text-xs text-center">
              {ele.description}
            </span>
          );
        }),
      minWidth: 160,
      flex: 1,
    },
    {
      field: "startDate",
      align: "center",
      headerName: "Inicio",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {formatDate(params.row.startDate)}
        </span>
      ),
      flex: 1,
    },
    {
      field: "endDate",
      align: "center",
      headerName: "Fin",
      headerClassName: "bg-[#5836FF] text-white",
      sortable: false,
      renderCell: (params) => (
        <span className="text-xs text-center">
          {formatDate(params.row.endDate)}
        </span>
      ),
      flex: 1,
    },
    {
      field: "statusId",
      headerClassName: "bg-[#5836FF] text-white",
      headerName: "Estado",
      align: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.status}>
          <span>
            {params.row.statusId === 0 ? (
              <Block style={{ color: "gray" }} />
            ) : params.row.statusId === 1 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 2 ? (
              <PendingActionsIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 3 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 4 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 5 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 6 ? (
              <DoDisturbOnIcon style={{ color: "red" }} />
            ) : params.row.statusId === 7 ? (
              <CheckCircleOutline style={{ color: "green" }} />
            ) : params.row.statusId === 8 ? (
              <Cancel style={{ color: "red" }} />
            ) : params.row.statusId === 9 ? (
              <Block style={{ color: "gray" }} />
            ) : params.row.statusId === 10 ? (
              <CheckCircleOutline style={{ color: "green" }} />
            ) : params.row.statusId === 11 ? (
              <Cancel style={{ color: "red" }} />
            ) : params.row.statusId === 12 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 13 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : params.row.statusId === 14 ? (
              <HelpOutline style={{ color: "orange" }} />
            ) : params.row.statusId === 15 ? (
              <HelpOutline style={{ color: "orange" }} />
            ) : params.row.statusId === 16 ? (
              <CheckCircleOutline style={{ color: "green" }} />
            ) : params.row.statusId === 17 ? (
              <AccessTimeIcon style={{ color: "orange" }} />
            ) : null}
          </span>
        </Tooltip>
      ),
    },
  ];
  const handleChangePage = (event, newPage) => {
    const filterArray = [
      { code: "perfil", description: perfilActual.idPerfil },
    ];
    for (const key in filters) {
      let value = filters[key];
      if (value !== "" && value !== null) {
        if (key === "cuit") {
          value = value.replace(/[^0-9]/g, "");
        }
        filterArray.push({ code: key, description: value });
      }
    }
    filterArray.push({
      code: "activeButton",
      description: activeButton === 0 ? "pendientes" : "gestionados",
    });
    setPage(newPage);
    dispatch(
      fetchAllAdmLicenciasAsync({
        id: datos.idHR,
        filters: filterArray,
        pageNumber: newPage,
        pageSize: rowsPerPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      fetchAllAdmLicenciasAsync({
        id: datos.idHR,
        filters: [
          { code: "perfil", description: perfilActual.idPerfil },
          {
            code: "activeButton",
            description: activeButton === 0 ? "pendientes" : "gestionados",
          },
        ],
        pageNumber: 0,
        pageSize: parseInt(event.target.value, 10),
      })
    );
  };

  const handleModalAction = (e) => {
    switch (e.target.name) {
      case "autorizar":
        setModalAut(true);
        break;
      case "rechazar":
        setModalRech(true);
        break;
      default:
        break;
    }
  };

  const [modalAut, setModalAut] = React.useState(false);
  const [modalRech, setModalRech] = React.useState(false);

  const handleSubmitAction = (action) => {
    let data = [];
    select.forEach((licenciaId) => {
      data.push({
        id: licenciaId,
        action: action === "autorizar" ? 1 : 3,
        reason: motivoRechazo,
      });
    });
    dispatch(updateLicenceStatusAsync({ data, userId: datos.id }))
      .then((res) => {
        if (res.payload) {
          dispatch(setSelect([]));
          if (action === "autorizar") {
            setModalAut(false);
            setOpenAut(true);
            setTimeout(() => {
              setOpenAut(false);
            }, 3000);
          } else {
            setModalRech(false);
            setOpenRech(true);
            setTimeout(() => {
              setOpenRech(false);
            }, 3000);
          }
          dispatch(
            fetchAllAdmLicenciasAsync({
              id: datos.idHR,
              filters: [
                { code: "perfil", description: perfilActual.idPerfil },
                {
                  code: "activeButton",
                  description:
                    activeButton === 0 ? "pendientes" : "gestionados",
                },
              ],
              pageNumber: page,
              pageSize: rowsPerPage,
            })
          );
        } else {
          setError(true);
          action === "autorizar" ? setModalAut(false) : setModalRech(false);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setError(true);
        action === "autorizar" ? setModalAut(false) : setModalRech(false);
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    openAut ? setOpenAut(false) : setOpenRech(false);
  };

  const [motivoRechazo, setMotivoRechazo] = React.useState("");
  if (!allLicences || allLicences?.length === 0)
    return (
      <p className="text-xl text-center mt-5">
        No se encuentran licencias pendientes de autorización.
      </p>
    );
  return (
    <div style={{ width: "100%", padding: "10px" }}>
      <div className="flex w-full gap-3 justify-center sm:justify-end my-3">
        <Button onClick={onExport} variant="contained">
          Exportar
        </Button>
        {activeButton === 0 && (
          <>
            <Button
              disabled={select.length === 0}
              variant="contained"
              name="autorizar"
              onClick={handleModalAction}
            >
              Autorizar
            </Button>
            <Button
              disabled={select.length === 0}
              color="error"
              variant="outlined"
              name="rechazar"
              onClick={handleModalAction}
            >
              Rechazar
            </Button>
          </>
        )}
      </div>
      <div className="relative w-full">
        <div className="w-full flex">
          <TablePagination
            className="border-0 p-0 ml-auto"
            rowsPerPageOptions={[
              5,
              10,
              25,
              { label: "All", value: totalCount },
            ]}
            colSpan={8}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Licencias por pagina"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          <p className="flex w-[100px] mx-2 items-center">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[24px] h-[24px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
        </div>
        <div className="w-[100%]">
          {allLicences?.length === 0 ? (
            <p className="text-xl text-center mt-5">
              No se encuentran licencias pendientes de autorización.
            </p>
          ) : (
            <>
              <div className="hidden sm:block">
                <DataGrid
                  className="border-0"
                  getRowHeight={() => "auto"}
                  hideFooterRowCount
                  rows={allLicences}
                  columns={columns}
                  hideFooterPagination
                  checkboxSelection={activeButton === 0}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    dispatch(setSelect(newRowSelectionModel));
                  }}
                  // onStateChange={(e) => {
                  //   dispatch(setSelect(e.rowSelection))}}
                  isRowSelectable={(params) => {
                    return !params.row.isHistorical;
                  }}
                />
              </div>
              <div className="sm:hidden flex flex-col justify-center items-center my-3 gap-3">
                {allLicences?.map((card, index) => (
                  <div
                    className={`w-[90vw] ${
                      select.includes(card.id)
                        ? "border-2 border-blue-700"
                        : "border-2"
                    } shadow-md p-3 rounded-md pb-4`}
                    key={index}
                  >
                    <span className="text-gray-500 font-medium mb-2">
                      ID #{card.id}
                    </span>
                    <p className="text-lg text-[#5836ff] font-medium mb-2">
                      {card.title}
                    </p>
                    <p className="flex flex-col mb-0 text-[#5836ff] font-medium">
                      ESTADO
                    </p>
                    <p className="p-1 rounded-sm shadow-sm border text-red-600 w-fit mb-2 max-w-[250px]">
                      {card.status}
                    </p>
                    <p className="flex flex-col mb-0 text-[#5836ff] font-medium">
                      NOMBRE
                    </p>
                    <p className="flex flex-col mb-2 text-gray-500">
                      {card.firstName + " " + card.lastName}
                    </p>
                    <div className="flex justify-between mb-3">
                      <div>
                        <p className="flex flex-col mb-0 text-[#5836ff] font-medium">
                          INICIO
                        </p>
                        <p className="flex flex-col mb-0 text-gray-500">
                          {formatDate(card.startDate)}
                        </p>
                      </div>
                      <div className="mr-3 mt-1">
                        <p className="flex flex-col mb-0 text-[#5836ff] font-medium">
                          FIN
                        </p>
                        <p className="flex flex-col mb-0 text-gray-500">
                          {formatDate(card.endDate)}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-2 justify-between">
                      <Link
                        className="p-2 bg-[#5836ff] text-white px-3 rounded-md shadow-md no-underline"
                        to={`/administrative/${card.id}?activeBtn=${activeButton}&pageAct=${page}`}
                      >
                        Abrir
                      </Link>
                      <button
                        className={`p-2 border border-[#5836ff] ${
                          select.includes(card.id)
                            ? "bg-[#00d6d1] text-white"
                            : "bg-white text-[#5836ff]"
                        }  font-medium px-3 rounded-md shadow-md  ${
                          card.isHistorical
                            ? "text-gray-600 line-through"
                            : "text-[#5836ff]"
                        }`}
                        disabled={card.isHistorical}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (select.includes(card.id)) {
                            dispatch(removeSelect(card.id));
                          } else {
                            dispatch(addSelect(card.id));
                          }
                        }}
                      >
                        {/* {select.includes(card.id) ? "Quitar" : "Seleccionar"} */}
                        Seleccionar
                      </button>
                      {/* <Checkbox checked={select.includes(card.id)} onClick={(e)=>{
                        e.stopPropagation();
                        if(select.includes(card.id)){
                          dispatch(removeSelect(card.id));
                        }else{
                          dispatch(addSelect(card.id))
                        }
                      }}></Checkbox> */}
                      {/* <button
                        key={card.id}
                        onClick={()=>handleButtonClick(card.id)}
                        className="p-2 bg-[#5836ff] text-white px-3 rounded-md shadow-md no-underline"
                      >
                        {select.includes(card.id)
                          ? "Deseleccionar"
                          : "Seleccionar"}
                      </button> */}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="w-full flex">
          <TablePagination
            className="border-0 p-0 ml-auto"
            rowsPerPageOptions={[
              5,
              10,
              25,
              { label: "All", value: totalCount },
            ]}
            colSpan={8}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Licencias por pagina"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
          <p className="flex w-[100px] mx-2 items-center">
            Pagina:{" "}
            <span className="bg-[#5836FF] ml-1 pr-[1px] text-white w-[24px] h-[24px] flex items-center rounded-full justify-center">
              {page + 1}
            </span>{" "}
          </p>
        </div>
      </div>
      <Modal
        open={modalAut | modalRech}
        onClose={
          modalAut ? () => setModalAut(false) : () => setModalRech(false)
        }
      >
        <Box sx={{ ...style, width: 350 }}>
          {modalAut ? (
            <h1 className="font-semibold text-xl text-center">
              Estás por autorizar {select.length > 1 ? select.length : "una"}{" "}
              solicitud{select.length > 1 ? "es" : null} de licencia. ¿Deseás
              continuar?
            </h1>
          ) : (
            <h1 className="font-semibold text-xl text-center">
              Estás por rechazar {select.length > 1 ? select.length : "una"}{" "}
              solicitud{select.length > 1 ? "es" : null} de licencia. ¿Deseás
              continuar?
            </h1>
          )}
          {modalAut ? (
            <p className="text-center">Esta acción no se puede deshacer.</p>
          ) : (
            <div>
              <FormControl fullWidth>
                <Select size="small" defaultValue="Razones de servicio">
                  <MenuItem selected value="Razones de servicio">
                    Razones de servicio
                  </MenuItem>
                </Select>
              </FormControl>

              <p className="text-center">
                Recordá que, en caso de continuar, esta acción no puede
                revertirse. Además, deberás escribir el motivo del rechazo, el
                que será enviado al/la empleado/a solicitante para su
                notificación.
              </p>
            </div>
          )}
          {modalRech && (
            <TextField
              placeholder="Máximo 1000 caracteres"
              value={motivoRechazo}
              onChange={(e) => {
                if (e.target.value.length > 1000) {
                  // Trunca el valor a 250 caracteres
                  const truncatedText = e.target.value.slice(0, 1000);
                  setMotivoRechazo(truncatedText);
                } else {
                  setMotivoRechazo(e.target.value);
                }
              }}
            />
          )}
          <Box className="flex gap-2 w-full justify-center">
            <Button
              variant="outlined"
              onClick={
                modalAut ? () => setModalAut(false) : () => setModalRech(false)
              }
            >
              No
            </Button>
            <Button
              variant="contained"
              color={modalAut ? "primary" : "error"}
              onClick={() =>
                handleSubmitAction(modalAut ? "autorizar" : "rechazar")
              }
            >
              Si
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={openAut | openRech}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {openAut ? "Licencia autorizada éxito" : "Licencia Rechaza con éxito"}
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={3000}>
        <Alert severity="error">Ups.. Algo salio mal</Alert>
      </Snackbar>
    </div>
  );
}

export default AdministrativeTable;
