/* eslint-disable react-hooks/exhaustive-deps */
import { Box, TextField } from "@mui/material";
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function FocoFilters({filters,setFilters}) {


  React.useEffect(() => {
    if (filters.publicationtype !== "Novedades") {
      setFilters({ ...filters, category: "" });
    }
  }, [filters.publicationtype]);

  const handleChange = (event) => {
    switch (event.target.name) {
      case "tipo":
        setFilters({ ...filters, publicationtype: event.target.value });
        break;
      case "categoria":
        setFilters({ ...filters, category: event.target.value });
        break;
      case "titulo":
        setFilters({ ...filters, title: event.target.value });
        break;
      case "estado":
        setFilters({ ...filters, status: event.target.value });
        break;
      default:
        break;
    }
  };

  const handleChangeDates = (action, event) => {
    switch (action) {
      case "cdesde":
        setFilters({ ...filters, cdesde: event });
        break;
      case "chasta":
        setFilters({ ...filters, chasta:  event });
        break;
      case "pdesde":
        setFilters({ ...filters, pdesde:  event });
        break;
      case "phasta":
        setFilters({ ...filters, phasta:  event });
        break;
      default:
        break;
    }
  };

  return (
    <Box className="p-3 d-flex gap-3 justify-content-center flex-wrap">
      <FormControl size="small" sx={{ width: "180px" }}>
        <InputLabel id="demo-simple-select-label">
          Tipo de Publicación
        </InputLabel>
        <Select
          name="tipo"
          value={filters.publicationtype}
          label="Tipo de Publicación"
          onChange={handleChange}
        >
          <MenuItem value={""}>Quitar Filtro</MenuItem>
          <MenuItem value={"Novedades"}>Novedades</MenuItem>
          <MenuItem value={"Procedimientos"}>Procedimientos</MenuItem>
          <MenuItem value={"Comunicados"}>Comunicados</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        disabled={filters.publicationtype !== "Novedades"}
        size="small"
        sx={{ width: "165px" }}
      >
        <InputLabel id="demo-simple-select-label">Categoría</InputLabel>
        <Select
          name="categoria"
          value={filters.category}
          label="Categoría"
          onChange={handleChange}
        >
          <MenuItem value={""}>Quitar Filtro</MenuItem>
          <MenuItem value={"Concursos"}>Concursos</MenuItem>
          <MenuItem value={"Datos Útiles"}>Datos Útiles</MenuItem>
          <MenuItem value={"Fecha de cobro Haberes"}>
           Fecha de cobro Haberes
          </MenuItem>
          <MenuItem value={"Feriados/Festividades"}>
          Feriados/Festividades
          </MenuItem> 
        </Select>
      </FormControl>
      <TextField
        name="titulo"
        value={filters.title}
        sx={{ width: "165px" }}
        size="small"
        id="outlined-basic"
        label="Título"
        variant="outlined"
        onChange={handleChange}
      />
      <FormControl size="small" sx={{ width: "165px" }}>
        <InputLabel id="demo-simple-select-label">Estado</InputLabel>
        <Select
          name="estado"
          value={filters.status}
          label="Estado"
          onChange={handleChange}
        >
          <MenuItem value={""}>Quitar Filtro</MenuItem>
          <MenuItem value={"Borrador"}>Borrador</MenuItem>
          <MenuItem value={"Publicada"}>Publicada</MenuItem>
          <MenuItem value={"Eliminada"}>Eliminada</MenuItem>
          <MenuItem value={"Finalizada"}>Finalizada</MenuItem>
        </Select>
      </FormControl>
      <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
        <DatePicker
          value={filters.cdesde}
          onChange={(e) => handleChangeDates("cdesde", e)}
          sx={{ width: "165px" }}
          slotProps={{ textField: { size: "small" } }}
          className="datepicker"
          label="Creación Desde"
        />
        <DatePicker
        value={filters.chasta}
          onChange={(e) => handleChangeDates("chasta", e)}
          sx={{ width: "165px" }}
          slotProps={{ textField: { size: "small" } }}
          className="datepicker"
          label="Creación Hasta"
        />
        <DatePicker
        value={filters.pdesde}
          onChange={(e) => handleChangeDates("pdesde", e)}
          sx={{ width: "180px" }}
          slotProps={{ textField: { size: "small" } }}
          className="datepicker"
          label="Publicación Desde"
        />
        <DatePicker
        value={filters.phasta}
          onChange={(e) => handleChangeDates("phasta", e)}
          sx={{ width: "180px" }}
          slotProps={{ textField: { size: "small" } }}
          className="datepicker"
          label="Publicación Hasta"
        />
      </LocalizationProvider>
    </Box>
  );
}

export default FocoFilters;
