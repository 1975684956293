/* eslint-disable no-unused-vars */
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { createRef, useEffect, useRef, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import { formatDate, verificarObjetos } from "../../../utils";
import {
  fetchUnidadesOrganizativasAsync,
  fetchTopesSaldosAsync,
  fetchDiagnosticosAsync,
} from "../../license/licenseSlice";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { StatusActionEnums } from "../../../interfaces/StatusActionEnum";
import { Localidades, Provincias } from "../../user/registration/locations";
import moment from "moment";
import {
  fetchOneAuditProLicenciaAsync,
  postExecuteAction,
  putObservarFile,
  removeOneLicense,
  fetchHistoryLicenseById,
  fetchTomarLicencia,
  fetchLiberarLicencia,
  removeHistory,
  fetchAuditProDiagnosticosAsync,
  postExecuteRevisarComportamiento,
  checkRevisarComportamiento,
} from "../occupationalMedicineSlice";
import { getDomicilioAsync, getFamiliaresAsync } from "../../user/userSlice";
import { handleConfirmModal } from "../../modals/modalSlice";
import { LicStatusEnum } from "../../../interfaces/LicStatusEnum";
import {
  getSetting,
  getUserStaticData,
  selectStaticData,
} from "../../parameters/parametersSlice";
import DropDown from "../dropdown/Dropdown";
import {
  postCertificado,
  resetDocuments,
  selectDocuments,
} from "../../files/filesSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LicTypesEnum } from "../../../interfaces/LicTypesEnum";
import TableLicenseHistory from "./TableLicenseHistory";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { ZoomIn, ZoomOut } from "@mui/icons-material";

//comentario test
function Detail() {
  const { user } = useSelector(selectStaticData);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);
  const { oneLicence, history, diagnosticos } = useSelector(
    (state) => state.auditProLicence
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const licenseTypeId = queryParams.get("licenseTypeId");
  const queryPendientes = queryParams.get("queryPendientes");
  const { data } = useSelector((state) => state.licencia);
  const { datos, familiares, perfilActual } = useSelector(
    (state) => state.usuario
  );


  const [historyLicense, setHistoryLicense] = useState([{}]);

  const [domicilio, setDomicilio] = useState({});
  const [rtaActionLicencia, setRtaActionLicencia] = React.useState({
    success: false,
    open: false,
    message: "",
  });
  const [newNacimientoMult, setNewNacimientoMult] = useState(false);
  const [newCantidad, setNewCantidadHijos] = useState(2);
  const [newDiasFPP, setNewDiasFPP] = useState(null);
  const [openInfoAuditoria, setOpenInfoAuditoria] = React.useState(false);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  useEffect(() => {
    dispatch(getUserStaticData());
    dispatch(getSetting("extensions"));
    dispatch(fetchOneAuditProLicenciaAsync({ id, licenseTypeId })).then(
      (res) => {
        if (!res.payload.unidades[0]?.idRol) {
          dispatch(
            fetchUnidadesOrganizativasAsync({
              idHr: res.payload.idhr,
              codRegimen: res.payload.codeRegimen,
            })
          );
        }
        setNewFechaNacimiento(res.payload.startDate);
        setNewFechaProbable(res.payload.fechaProbable);
        setNewNeonatologia(res.payload.neonatologia);
        setNewNacimientoMult(res.payload.nacimientoMultiple);
        setNewCantidadHijos(res.payload.cantidadHijos);
        setNewDiasNeonatologia(res.payload.diasNeonatologia);
        setNewDiasAprobados(res.payload.diasCertificado);
        setNewDiasSolicitados(res.payload.diasSolicitados);
        if (res.payload.fechaProbable) {
          setNewDiasFPP(
            Math.ceil(
              Math.abs(
                new Date(res.payload?.fechaProbable) -
                  new Date(res.payload.startDate) + 1
              ) /
                (1000 * 60 * 60 * 24)
            )
          );
        }
        if (res.payload.idTomadaPor && res.payload.idTomadaPor !== datos.id) {
          dispatch(
            handleConfirmModal({
              open: true,
              content: `La licencia está tomada por el/la auditor/a: ${res.payload.tomadaPor}. No podrás realizar ninguna acción hasta que se libere.`,
              showCancel: false,
            })
          );
        }
        dispatch(fetchAuditProDiagnosticosAsync(res.payload.diagnostico)).then(
          (res) => {
            setNewDiagnostic(res.payload[0]);
          }
        );
        dispatch(
          checkRevisarComportamiento({
            idHr: res.payload.idhr,
            licenseId: res.payload.id,
          })
        ).then((res) => {
          setIsCompRevisar(res.payload.data);
        });
        dispatch(getFamiliaresAsync(res.payload.userId));
        dispatch(getDomicilioAsync(res.payload.domicilioId)).then((res) => {
          setDomicilio(res.payload);
        });
      }
    );
    return () => {
      dispatch(removeOneLicense());
    };
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModal, handleModal] = React.useState({
    open: false,
    message: "",
    action: "",
  });

  const handleTomarLicencia = () => {
    dispatch(
      fetchTomarLicencia({ userId: datos.id, licenceId: oneLicence.id })
    ).then((res) => {
      dispatch(fetchOneAuditProLicenciaAsync({ id, licenseTypeId }));
      handleModal({ open: false });
      setRtaActionLicencia({
        open: true,
        success: res.payload.success,
        message: res.payload.message,
      });
    });
  };

  const handleLiberarLicencia = () => {
    dispatch(
      fetchLiberarLicencia({ userId: datos.id, licenceId: oneLicence.id })
    ).then((res) => {
      dispatch(fetchOneAuditProLicenciaAsync({ id, licenseTypeId }));
      handleModal({ open: false });
      setRtaActionLicencia({
        open: true,
        success: res.payload.success,
        message: res.payload.message,
      });
    });
  };
  const [openHistory, setOpenHistory] = React.useState(false);

  useEffect(() => {
    if (openHistory) {
      dispatch(fetchHistoryLicenseById(oneLicence.id));
    } else {
      setTimeout(() => {
        dispatch(removeHistory());
      }, 500);
    }
  }, [openHistory]);

  const [openAprobar, setOpenAprobar] = React.useState(false);
  const [openNoAprobar, setOpenNoAprobar] = React.useState(false);
  const [openChangeCT, setOpenChangeCT] = React.useState(false);
  const [openAnular, setOpenAnular] = React.useState(false);
  const [openAusente, setOpenAusente] = React.useState(false);
  const [openLargoTratamiento, setOpenLargoTratamiento] = React.useState(false);
  const [openDerivacion, setOpenDerivacion] = React.useState(false);

  const [handleAceptarAprobar, setHandleAceptarAprobar] = useState(false);
  const [motivoNoAprobar, setMotivoNoAprobar] = useState(null);
  const [motivoAnular, setMotivoAnular] = useState(null);

  const [motivoAprobar, setMotivoAprobar] = useState(null);
  const [datosAusencia, setDatosAusencia] = useState(null);
  const [motivoLT, setMotivoLT] = useState(null);
  const [motivoAlumbramiento, setMotivoAlumbramiento] = useState(null);
  const [motivoEmbarazo, setMotivoEmbarazo] = useState(null);
  const [enfGrave, setEnfGrave] = useState(false);
  const [compRev, setCompRev] = useState(false);
  const [observacionesAprobar, setObservacionesAprobar] = useState("");
  const [observacionesChangeCT, setObservacionesChangeCT] = useState("");
  const [comentarioAprobar, setComentarioAprobar] = useState("");
  const [changeDiasFPP, setChangeDiasFPP] = useState(false);
  const [observacionesAnular, setObservacionesAnular] = useState("");
  useEffect(() => {
    if (
      Number(newDiasFPP) !==
      Math.ceil(
        Math.abs(
          new Date(oneLicence?.fechaProbable) - new Date(oneLicence?.startDate)
        ) /
          (1000 * 60 * 60 * 24) + 1 
      )
    ) {
      setMotivoEmbarazo(
        "Se modifica días de periodo preparto, según documentación respaldatoria"
      );
    } else {
      setMotivoEmbarazo(null);
    }
  }, [changeDiasFPP, newDiasFPP]);
  const [observacionesNoAprobar, setObservacionesNoAprobar] = useState("");
  const [observacionLT, setObservacionLT] = useState("");
  const [newFechaNacimiento, setNewFechaNacimiento] = useState(null);
  const [newFechaProbable, setNewFechaProbable] = useState(null);

  const getInitialData = () => {
    const idRole = oneLicence?.unidades[0]?.idRol
      ? oneLicence?.unidades[0]?.idRol
      : data.unidadesOrganizativas[0]?.idrol;

    let initialData = {
      operacion: "ACCION",
      idHR: oneLicence.idhr,
      idTramite: `${oneLicence.id}`,
      perfilMiA: perfilActual.idPerfil,
      idRole: idRole,
      codAreaCel: oneLicence.celular.slice(3, 5),
      tipoAtencion: "1",
      intervieneRAP: "NO",
      idGrupoLicencias: oneLicence.tipoLicencia,
      usuarioMiA: `${datos.cuit.toString().substring(0, 2)}-${datos.cuit
        .toString()
        .substring(2, 10)}-${datos.cuit.toString().substring(10)}`,
      codLicencia: oneLicence.licenseCode,
      nroCelular: oneLicence.celular.slice(5),
    };
    return initialData;
  };

  const handleAction = (event) => {
    switch (event) {
      case "LIBERAR":
        handleLiberarLicencia();
        break;
      case "TOMAR":
        handleTomarLicencia();
        break;
      default:
        break;
    }
  };

  const handleSubmitDerivar = () => {
    const initialData = getInitialData();
    let data = {
      ...initialData,
      motivoAccion: optDerivacion.motivo,
      accion: "MODIFICAR ASIGNACION",
      tipoAtencion: "6",
      idRole: oneLicence?.unidades[0]?.idRol,
      asignar: optDerivacion.asignar,
      estado: "INGRESADA",
    };
    if (optDerivacion.centroMedico) {
      data = {
        ...data,
        idCemet: optDerivacion.centroMedico,
        fechaCitacion: optDerivacion.fechaCitacion,
      };
    }
    dispatch(
      postExecuteAction({ data, action: 9, userId: datos.id, licenseTypeId })
    ).then((res) => {
      setOpenDerivacion(false);
      setOptDerivacion({
        asignar: null,
        centroMedico: null,
        fechaCitacion: null,
        motivo: "",
      });
      setRtaActionLicencia({
        open: true,
        success: res.payload.success,
        message: res.payload.message,
      });
      dispatch(fetchOneAuditProLicenciaAsync({ id, licenseTypeId }));
    });
  };

  const handleSubmitChangeCT = () => {
    const initialData = getInitialData();
    let data = {
      ...initialData,
      perfilMiA: "AU_PROFESIONAL_LT",
      accion: "PASAR A CT",
      estado: "INGRESADA",
      idGrupoLicencias: oneLicence?.prevType,
      observacionesMedico: observacionesChangeCT,
    };
    dispatch(
      postExecuteAction({
        data,
        action: 13,
        userId: datos.id,
        licenseTypeId,
      })
    ).then((res) => {
      setOpenChangeCT(false);
      setRtaActionLicencia({
        open: true,
        success: res.payload.success,
        message: res.payload.message,
      });
      let prevIdType =
        oneLicence.prevType === "MED"
          ? LicTypesEnum.MEDICA
          : LicTypesEnum.MEDICA_HOSPITALIZACION;
      navigate(`/occupational-medicine/${id}?licenseTypeId=${prevIdType}`);
      dispatch(fetchOneAuditProLicenciaAsync({ id, licenseTypeId }));
    });
  };

  const { documents } = useSelector(selectDocuments);

  const saveDocuments = () => {
    const key = "otrosDocumentos";
    const formData = new FormData();
    documents.forEach((file) => {
      formData.append("userId", oneLicence?.userId);
      formData.append("cuit", oneLicence?.cuit);
      formData.append("key", key);
      formData.append(key, file);
      formData.append("sendMeta4", false);
      formData.append("licenseId", oneLicence?.id);
    });
    return dispatch(postCertificado(formData)).catch((err) => console.log(err));
  };

  const handleSubmitAprobar = () => {
    const initialData = getInitialData();
    let endDate = moment(oneLicence.startDate);
    endDate.add(
      oneLicence.tipoLicencia === "MEF"
        ? newDiasSolicitados - 1
        : newDiasAprobados - 1,
      "days"
    );
    let data = {
      ...initialData,
      accion: "EDITAR Y AUTORIZAR",
      estado: "AUTORIZADA",
      fecFin: formatDate(endDate),
      observacionesMedico: observacionesAprobar,
      motivoAccion: motivoAprobar,
      idDiagnostico: newDiagnostic?.diagnosticId,
      fecInicio: formatDate(oneLicence.startDate),
      diasJustificados:
        oneLicence.tipoLicencia === "MEF"
          ? newDiasSolicitados?.toString()
          : newDiasAprobados?.toString(),
    };
    if (oneLicence.tipoLicencia === "MEF") {
      data = { ...data, enfGraveFamiliar: enfGrave ? "SI" : "NO" };
    }
    if (oneLicence.subtitle === "Alumbramiento") {
      delete data.diasJustificados;
      delete data.idDiagnostico;
      delete data.observacionesMedico;
      data = {
        ...data,
        accion: "EyA-EDITAR y AUTORIZAR",
        diasNeonatologia: newNeonatologia
          ? newDiasNeonatologia.toString()
          : "0",
        alumbramientoMultiple: newNacimientoMult ? newCantidad.toString() : "0",
        motivoAccion: motivoAlumbramiento,
        fecNacimiento: formatDate(newFechaNacimiento),
        fecFin: formatDate(oneLicence.startDate),
      };
    }
    if (oneLicence.subtitle === "Embarazo") {
      delete data.diasJustificados;
      delete data.idDiagnostico;
      delete data.observacionesMedico;
      data = {
        ...data,
        accion: "EyA-EDITAR y AUTORIZAR",
        motivoAccion: motivoEmbarazo,
        diasPreparto: newDiasFPP.toString(),
        fecProbableParto: formatDate(newFechaProbable),
        fecFin: formatDate(newFechaProbable),
        preParto15Dias: changeDiasFPP ? "SI" : "NO",
        idGrupoLicencias: "EyA",
        acortarPeriodoPreparto: changeDiasFPP ? "SI" : "NO",
      };
    }
    if (oneLicence.subtitle === "Largo Tratamiento") {
      let endDateLT = moment(oneLicence.startDate);
      let diasJustificados = Math.ceil(
        Math.abs(new Date(oneLicence.startDate) - new Date(fechaCitAlta)) /
          (1000 * 60 * 60 * 24)
      );
      endDateLT.add(diasJustificados - 1, "days");
      data = {
        ...data,
        fecInicio: formatDate(oneLicence.startDate, false, true),
        fecFin: formatDate(endDateLT, false, true),
        perfilMiA: "AU_PROFESIONAL_LT",
        accion: "AUTORIZAR LT",
        idGrupoLicencias: oneLicence?.prevType,
        fecNuevaCitacion: formatDate(fechaCitAlta),
        diasAprobados: Math.ceil(
          Math.abs(new Date(oneLicence.startDate) - new Date(fechaCitAlta)) /
            (1000 * 60 * 60 * 24)
        ),
        diasJustificados: Math.ceil(
          Math.abs(new Date(oneLicence.startDate) - new Date(fechaCitAlta)) /
            (1000 * 60 * 60 * 24)
        ).toString(),
        motivoAccion: motivoLT,
        idDiagnostico: diagnosticLT
          ? diagnosticLT.diagnosticId
          : diagnosticos[0]?.diagnosticId,
        estado: nuevaCitacion ? "AUTORIZADA LT" : "ALTA LT",
        observacionesMedico: comentarioAprobar,
      };
    }
    dispatch(
      postExecuteAction({
        data,
        action:
          oneLicence.subtitle === "Alumbramiento"
            ? 10
            : oneLicence.subtitle === "Embarazo"
            ? 11
            : oneLicence.subtitle === "Largo Tratamiento"
            ? 12
            : 1,
        userId: datos.id,
        licenseTypeId,
      })
    ).then((res) => {
      if(res.payload.success){
        if(documents.length > 0){
          saveDocuments()
          .then(x=>{
            dispatch(resetDocuments())
            setOpenAprobar(false);
            setHandleAceptarAprobar(false);
            setRtaActionLicencia({
              open: true,
              success: res.payload.success,
              message: res.payload.message,
            });
            dispatch(fetchOneAuditProLicenciaAsync({ id, licenseTypeId }));
            if (compRev) {
              dispatch(
                postExecuteRevisarComportamiento({
                  idHr: oneLicence.idhr,
                  licenseId: oneLicence.id,
                })
              );
              setIsCompRevisar(true);
            }
          })
          .cath(err=>{
            dispatch(resetDocuments())
          })
        }else{
          dispatch(resetDocuments())
          setOpenAprobar(false);
          setHandleAceptarAprobar(false);
          setRtaActionLicencia({
            open: true,
            success: res.payload.success,
            message: res.payload.message,
          });
          dispatch(fetchOneAuditProLicenciaAsync({ id, licenseTypeId }));
          if (compRev) {
            dispatch(
              postExecuteRevisarComportamiento({
                idHr: oneLicence.idhr,
                licenseId: oneLicence.id,
              })
            );
            setIsCompRevisar(true);
          }
        }
      }else{
        dispatch(resetDocuments())
        setOpenAprobar(false);
          setHandleAceptarAprobar(false);
          setRtaActionLicencia({
            open: true,
            success: res.payload.success,
            message: res.payload.message,
          });
      }
    });
  };

  const handleSubmitReportarAusente = () => {
    const initialData = getInitialData();
    let data = {
      ...initialData,
      accion: "RECHAZAR",
      estado: "RECHAZADA",
      diasJustificados: "0",
      motivoAccion:
        datosAusencia === "" ? "Ausente, no asististe al turno" : datosAusencia,
    };
    dispatch(
      postExecuteAction({
        data,
        action: 14,
        userId: datos.id,
        licenseTypeId,
      })
    ).then((res) => {
      setOpenAusente(false);
      setRtaActionLicencia({
        open: true,
        success: res.payload.success,
        message: res.payload.message,
      });
      dispatch(fetchOneAuditProLicenciaAsync({ id, licenseTypeId }));
      if (compRev) {
        dispatch(
          postExecuteRevisarComportamiento({
            idHr: oneLicence.idhr,
            licenseId: oneLicence.id,
          })
        );
        setIsCompRevisar(true);
        setCompRev(false);
      }
    });
  };

  const handleSubmitNoAprobar = () => {
    const initialData = getInitialData();
    const data = {
      ...initialData,
      accion: "RECHAZAR",
      observacionesMedico: observacionesNoAprobar,
      motivoAccion: motivoNoAprobar,
      estado: "RECHAZADA",
      diasJustificados: "0",
    };
    dispatch(
      postExecuteAction({ data, action: 2, userId: datos.id, licenseTypeId })
    ).then((res) => {
      setOpenNoAprobar(false);
      if (res.payload.success) navigate(perfilActual.rootPath);
      else {
        setRtaActionLicencia({
          open: true,
          success: res.payload.success,
          message: res.payload.message,
        });
      }
    });
  };

  const handleSubmitLargoTratamiento = () => {
    const initialData = getInitialData();
    const data = {
      ...initialData,
      perfilMiA: "AU_PROFESIONAL_LT",
      accion: "PASAR A LT",
      estado: "INGRESADA",
      observacionesMedico: observacionLT,
    };
    dispatch(
      postExecuteAction({ data, action: 8, userId: datos.id, licenseTypeId })
    ).then((res) => {
      setOpenLargoTratamiento(false);
      setObservacionLT("");
      setRtaActionLicencia({
        open: true,
        success: res.payload.success,
        message: res.payload.message,
      });
      navigate(
        `/occupational-medicine/${id}?licenseTypeId=${LicTypesEnum.LARGO_TRATAMIENTO}`
      );
      dispatch(
        fetchOneAuditProLicenciaAsync({
          id,
          licenseTypeId: LicTypesEnum.LARGO_TRATAMIENTO,
        })
      );
    });
  };

  const handleSubmitAnular = () => {
    const initialData = getInitialData();
    const data = {
      ...initialData,
      accion: "ANULAR",
      observacionesMedico: observacionesAnular,
      motivoAccion: motivoAnular,
      estado: "ANULADA",
    };
    dispatch(
      postExecuteAction({ data, action: 7, userId: datos.id, licenseTypeId })
    ).then((res) => {
      setOpenAnular(false);
      setRtaActionLicencia({
        open: true,
        success: res.payload.success,
        message: res.payload.message,
      });
      dispatch(fetchOneAuditProLicenciaAsync({ id, licenseTypeId }));
    });
  };

  const navigate = useNavigate();

  const pageAct = queryParams.get("pageAct");
  const activeBtn = queryParams.get("activeBtn");

  const [newDiagnostic, setNewDiagnostic] = React.useState({});
  const [diagnosticLT, setDiagnosticLT] = React.useState(null);
  const [newDiasAprobados, setNewDiasAprobados] = React.useState({});
  const [newDiasSolicitados, setNewDiasSolicitados] = React.useState({});
  const [newNeonatologia, setNewNeonatologia] = useState(false);
  const [newDiasNeonatologia, setNewDiasNeonatologia] = useState(0);
  const [isCompRevisar, setIsCompRevisar] = useState(false);
  useEffect(() => {}, [oneLicence]);

  useEffect(() => {
    dispatch(fetchDiagnosticosAsync(""));
  }, []);

  const [openInfoLaboral, setOpenInfoLaboral] = React.useState(false);

  const [uOrganizativa, setUOrganizativa] = React.useState([]);

  const handleInfoLaboral = () => {
    setOpenInfoLaboral(true);
    dispatch(
      fetchUnidadesOrganizativasAsync({
        idHr: oneLicence.idhr,
        codRegimen: oneLicence.codeRegimen,
      })
    ).then((res) => setUOrganizativa(res.payload));
  };

  const handleInfoSaldos = () => {
    setOpenSaldo(true);
    dispatch(
      fetchTopesSaldosAsync({
        idHr: oneLicence.idhr,
        codRegimen: oneLicence.codeRegimen,
      })
    ).then((res) => setInfoSaldo(res.payload.saldosLicenciasMED));
  };

  const handleDownloadFiles = (file) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(file);
    link.download = file.name;
    link.target = "_blank"; // Abre el archivo en una nueva ventana/tab
    link.click();
    URL.revokeObjectURL(link.href); // Liberar recursos después de la descarga
  };

  const [verCertificado, setVerCertificado] = useState({
    open: false,
    isImage: null,
    src: null,
    name: null,
    type: null,
    fileObj: null,
  });

  const [editImg, setEditImg] = useState({
    scale: 1,
    rotate: 0,
  });

  const [openSaldo, setOpenSaldo] = useState(false);
  const [infoSaldo, setInfoSaldo] = React.useState([]);

  const getLicense = (value) => {
    if (value.length > 2) dispatch(fetchDiagnosticosAsync(value));
  };

  const handleChangeDiasAprobados = (e) => {
    setNewDiasAprobados(Number(e.target.value));
    if (Number(e.target.value) >= oneLicence.diasSolicitados) {
      setNewDiasSolicitados(oneLicence.diasSolicitados);
    } else {
      setNewDiasSolicitados(Number(e.target.value));
    }
  };

  const disabledAprobar = () => {
    if (oneLicence.subtitle === "Largo Tratamiento") {
      return !fechaCitAlta || comentarioAprobar.length < 1 || !motivoLT;
    } else if (oneLicence.subtitle === "Embarazo") {
      if (!motivoEmbarazo)
        {
          return true;
        } 
          
      if (newDiasFPP < 15 || newDiasFPP > oneLicence.diaMaximoPreParto) 
        {
          return true;
        }
    } else if (oneLicence.subtitle === "Alumbramiento") {
      return (
        (newNeonatologia && newDiasNeonatologia < 1) ||
        (newNacimientoMult && newCantidad < 2) ||
        !motivoAlumbramiento
      );
    } else {
      if (newDiagnostic?.description !== oneLicence?.diagnostico) {
        return (
          observacionesAprobar.length === 0 ||
          newDiasAprobados < 1 ||
          newDiasAprobados > newDiagnostic.topeTer ||
          ((oneLicence.tipoLicencia === "MEF" ||
            oneLicence.tipoLicencia === "MED" ||
            oneLicence.tipoLicencia === "MEH") &&
            !motivoAprobar)
        );
      }
      return (
        !newDiagnostic ||
        newDiasAprobados < 1 ||
        newDiasAprobados > newDiagnostic.topeTer ||
        ((oneLicence.tipoLicencia === "MEF" ||
          oneLicence.tipoLicencia === "MED" ||
          oneLicence.tipoLicencia === "MEH") &&
          !motivoAprobar)
      );
    }
  };

  const [optDerivacion, setOptDerivacion] = useState({
    asignar: null,
    centroMedico: null,
    fechaCitacion: null,
    motivo: "",
  });

  const disabledDerivar = () => {
    if (!optDerivacion.asignar) return true;
    if (optDerivacion.motivo === "") return true;
    else if (optDerivacion.asignar === "CENTRO MÉDICO") {
      if (!optDerivacion.centroMedico) return true;
      if (oneLicence?.tipoLicencia !== "LT") {
        return !optDerivacion.fechaCitacion;
      }
    }
  };

  const [nuevaCitacion, setNuevaCitacion] = useState(true);
  const [altaMedica, setAltaMedica] = useState(false);
  const [fechaCitAlta, setFechaCitAlta] = useState(null);

  const getAge = (licence) => {
    const birthdate = new Date(licence.fechaNacimiento);
    const diff = new Date() - birthdate;
    const age = Math.floor(diff / 31557600000);
    return age;
  };

  return (
    <>
      <div>
        <header className="flex flex-wrap bg-[#f5f5f5] p-[15px] items-center justify-between px-[30px]">
          <h1 className="text-[#5836ff] font-medium text-2xl">
            Detalle de la Solicitud
          </h1>
          <Link
            to={`/occupational-medicine?pageAct=${pageAct}&activeBtn=${activeBtn}&queryPendientes=${queryPendientes}`}
          >
            <Button variant="contained">Volver a licencias</Button>
          </Link>
        </header>
        <section className="text-end flex w-full justify-center items-center sm:justify-end px-[30px] gap-2 py-[10px]">
          <Typography variant="p" fontWeight="medium" mr="auto" p={2}>
            Tomada por: {oneLicence?.tomadaPor ? oneLicence?.tomadaPor : "-"}
          </Typography>
          <Button
            disabled={
              (oneLicence?.idTomadaPor &&
                oneLicence?.idTomadaPor !== datos.id) ||
              (oneLicence?.tipoLicencia === "LT" &&
                perfilActual.idPerfil !== "AU_PROFESIONAL_LT") ||
              oneLicence?.tipoLicencia === "CT" ||
              oneLicence.isHistorical ||
              oneLicence.statusId === 15
            }
            variant="outlined"
            onClick={handleClick}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Acciones
          </Button>
          {(oneLicence?.statusId === LicStatusEnum.INGRESADA ||
            oneLicence?.statusId === LicStatusEnum.APROBADA ||
            oneLicence?.statusId === LicStatusEnum.RECHAZADA ||
            oneLicence?.statusId === LicStatusEnum.AUTORIZADA ||
            oneLicence?.statusId === LicStatusEnum.EN_REVISION ||
            oneLicence?.statusId ===
              LicStatusEnum.AUTORIZADA_LARGO_TRATAMIENTO ||
            oneLicence?.statusId === LicStatusEnum.ALTA_LARGO_TRATAMIENTO ||
            oneLicence?.statusId ===
              LicStatusEnum.PENDIENTE_LARGO_TRATAMIENTO) && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleModal({
                    open: true,
                    action: "TOMAR",
                    message: `Estás por tomar la licencia ID: <b>${oneLicence?.id}.</b> ¿Deseás Continuar?`,
                  });
                  handleClose();
                }}
              >
                Tomar licencia
              </MenuItem>
            </Menu>
          )}
          {oneLicence?.tomadaPor && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleModal({
                    open: true,
                    action: "LIBERAR",
                    message: `¿Querés liberar la licencia ID: <b>${oneLicence?.id}</b>?`,
                  });
                  handleClose();
                }}
              >
                Liberar licencia
              </MenuItem>
              {oneLicence?.idTomadaPor === datos.id && (
                <>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setOpenAprobar(true);
                    }}
                  >
                    Editar y Aprobar
                  </MenuItem>
                  {perfilActual.idPerfil === "AU_PROFESIONAL_LT" &&
                  oneLicence.subtitle === "Largo Tratamiento" ? (
                    <MenuItem
                      disabled={
                        oneLicence.status === "AUTORIZADA LARGO TRATAMIENTO"
                      }
                      onClick={() => {
                        handleClose();
                        setOpenChangeCT(true);
                      }}
                    >
                      Cambiar a Corto Tratamiento
                    </MenuItem>
                  ) : (
                    <>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenNoAprobar(true);
                        }}
                      >
                        Rechazar
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenAnular(true);
                        }}
                      >
                        Anular
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenAusente(true);
                        }}
                      >
                        Reportar ausente
                      </MenuItem>
                    </>
                  )}
                  {(oneLicence.tipoLicencia === "MED" ||
                    oneLicence.tipoLicencia === "MEH") && (
                    <>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenDerivacion(true);
                        }}
                      >
                        Derivación
                      </MenuItem>
                    </>
                  )}
                  {/* {
                    (oneLicence.tipoLicencia === "LT" && oneLicence?.statusId === LicStatusEnum.AUTORIZADA_LARGO_TRATAMIENTO) && (
                      <>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setOpenDerivacion(true);
                          }}
                        >
                          Derivación
                        </MenuItem>
                      </>
                    )
                  } */}
                  {(oneLicence.tipoLicencia === "MED" ||
                    oneLicence.tipoLicencia === "MEH") && (
                    <>
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          setOpenLargoTratamiento(true);
                        }}
                      >
                        Cambiar a Largo Tratamiento
                      </MenuItem>
                    </>
                  )}
                </>
              )}
            </Menu>
          )}
          <Button
            onClick={() =>
              navigate(
                `/occupational-medicine/history/${id}?pageAct=${pageAct}&activeBtn=${activeBtn}&licenseTypeId=${licenseTypeId}`
              )
            }
            //onClick={() => setOpenHistory(true)}
            variant="outlined"
          >
            Ver historial
          </Button>
          <Button
            onClick={handleInfoSaldos}
            //onClick={() => setOpenHistory(true)}
            variant="outlined"
          >
            Topes y Saldos
          </Button>
        </section>
        <div className="w-full flex flex-col sm:flex-row px-[30px] py-[10px] ">
          <aside className="w-full">
            <h1 className="font-medium ml-2 text-slate-400 mb-2 text-2xl">
              Información de la licencia
            </h1>
            <Divider />
            <section className="flex flex-wrap gap-5 py-4">
              <TextField
                label={<p style={{ color: "#626262" }}>ID</p>}
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <p position="start" style={{ color: "#000" }}>
                      {oneLicence.id}
                    </p>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label={<p style={{ color: "#626262" }}>Estado</p>}
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <p position="start" style={{ color: "#000" }}>
                      {oneLicence.status}
                    </p>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label={<p style={{ color: "#626262" }}>Tipo de Licencia</p>}
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <p position="start" className="min-w-[500px] text-black">
                      {oneLicence.subtitle}
                    </p>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label={
                  <p style={{ color: "#626262" }}>Fecha y hora de creación</p>
                }
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <p position="start" className="sm:min-w-[500px] text-black">
                      {formatDate(oneLicence.createDate, true)}
                    </p>
                  ),
                }}
                variant="standard"
              />
              <div className="w-full">
                <TextField
                  label={<p style={{ color: "#626262" }}>Licencia</p>}
                  id="outlined-start-adornment"
                  disabled
                  className="w-[40%]"
                  InputProps={{
                    startAdornment: (
                      <div className="min-w-[400px] text-black">
                        {oneLicence.title}
                      </div>
                    ),
                  }}
                  variant="standard"
                />
              </div>
              {oneLicence.diagnostico ? (
                <TextField
                  label={<p style={{ color: "#626262" }}>Diagnostico</p>}
                  disabled
                  id="outlined-disabled"
                  className="w-[40%]"
                  multiline
                  maxRows={4}
                  defaultValue={oneLicence.diagnostico ?? ""}
                  value={oneLicence.diagnostico ?? ""}
                  variant="standard"
                />
              ) : null}
              {oneLicence.diagnostico ? (
                <TextField
                  label={<p style={{ color: "#626262" }}>Ter</p>}
                  id="outlined-start-adornment"
                  disabled
                  className="w-[40%]"
                  InputProps={{
                    startAdornment: (
                      <p position="start" className="text-black">
                        {diagnosticos.length > 0 ? diagnosticos[0].ter : null}{" "}
                        {diagnosticos.length > 0
                          ? diagnosticos[0].ter === 1
                            ? "dia"
                            : "dias"
                          : null}
                      </p>
                    ),
                  }}
                  variant="standard"
                />
              ) : null}
              {oneLicence.tipoLicencia !== "EyA" && (
                <TextField
                  label={
                    <p style={{ color: "#626262" }}>Fecha de Certificado</p>
                  }
                  id="outlined-start-adornment"
                  disabled
                  className="w-[40%]"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {formatDate(oneLicence.fechaCertificado)}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
              )}
              <TextField
                label={
                  <p style={{ color: "#626262" }}>
                    {oneLicence.tipoLicencia !== "EyA"
                      ? "Inicio de Ausentismo"
                      : "Fecha de inicio de la licencia"}
                  </p>
                }
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formatDate(oneLicence.startDate)}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              {oneLicence.tipoLicencia !== "MEH" &&
                oneLicence.tipoLicencia !== "EyA" && (
                  <TextField
                    label={"Días del certificado"}
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {oneLicence.diasCertificado}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                )}
              <TextField
                label={"Días Aprobados"}
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.diasAprobados ?? "-"}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              {oneLicence.subtitle === "Embarazo" && (
                <TextField
                  label={"Fecha probable de Parto"}
                  id="outlined-start-adornment"
                  disabled
                  className="w-[40%]"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {oneLicence.fechaProbable
                          ? formatDate(oneLicence.fechaProbable)
                          : "-"}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
              )}
              {oneLicence.subtitle === "Embarazo" && (
                <TextField
                  label={
                    "Días previos a la FPP hasta los cuales puedo trabajar"
                  }
                  id="outlined-start-adornment"
                  disabled
                  className="w-[50%]"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {newDiasFPP ? newDiasFPP : "-"}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
              )}
              {oneLicence.status === "AUTORIZADA" && (
                <TextField
                  label={"Fecha Fin"}
                  id="outlined-start-adornment"
                  disabled
                  className="w-[40%]"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {formatDate(moment(oneLicence.endDate))}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
              )}
              {oneLicence.status === "AUTORIZADA" && (
                <TextField
                  label={"Fecha Reincorporación"}
                  id="outlined-start-adornment"
                  disabled
                  className="w-[40%]"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {formatDate(moment(oneLicence.endDate).add(1, "days"))}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
              )}
              <TextField
                label="Tipo de Asignación"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence?.asignacion ?? "-"}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Centro Médico"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence?.destino ?? "-"}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              {oneLicence.status !== "ALTA LARGO TRATAMIENTO" && (
                <TextField
                  label="Fecha de Turno"
                  id="outlined-start-adornment"
                  disabled
                  className="w-[40%]"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {oneLicence?.fechaCitacion
                          ? formatDate(oneLicence?.fechaCitacion)
                          : oneLicence?.fechaNuevaCitacion
                          ? formatDate(oneLicence?.fechaNuevaCitacion)
                          : "-"}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
              )}
              {oneLicence.status === "ALTA LARGO TRATAMIENTO" &&
                oneLicence?.fechaNuevaCitacion && (
                  <TextField
                    label="Fecha de Alta"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {formatDate(oneLicence?.fechaNuevaCitacion)}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                )}
              {oneLicence?.motivoDerivacion && (
                <TextField
                  label="Motivo Derivación"
                  id="outlined-start-adornment"
                  disabled
                  className="w-[40%]"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {oneLicence?.motivoDerivacion ?? "-"}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
              )}
            </section>
            <Box p={2}>
              <Button
                onClick={() => {
                  dispatch(fetchHistoryLicenseById(oneLicence.id)).then((e)=>{
                    setOpenInfoAuditoria(true);
                  });
                }}
                variant="outlined"
              >
                Ver Otra Información Auditoria
              </Button>
            </Box>
            <h1 className="font-medium text-slate-400 ml-2 mb-2 text-2xl">
              Certificados
            </h1>
            <Divider />
            <div className="flex flex-col gap-3 mt-2">
              {oneLicence?.files
                ?.filter((fl) => !fl.path.includes("dniFamiliar"))
                ?.map((file) => {
                  const byteCharacters = atob(file.bytes);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);

                  const blob = new Blob([byteArray], { type: file.type });

                  // Obtén la fecha de creación del archivo como un objeto Date
                  const createdDate = new Date(file.createdDate);

                  // Crea el objeto File con el Blob y los atributos necesarios
                  const fileObj = new File([blob], file.name, {
                    lastModified: createdDate.getTime(),
                    type: file.type,
                  });
                  const fileURL = URL.createObjectURL(fileObj);
                  const srcImg = `data:${file.type};base64,${file.bytes}`;
                  const isImage = file.type.includes("image/");
                  return (
                    <div key={file.name} className="flex gap-4">
                      {file.type.includes("image/") ? (
                        <img
                          src={srcImg}
                          alt={file.name}
                          className="w-[200px] h-[200px] rounded-md object-cover"
                        />
                      ) : (
                        <embed
                          src={fileURL}
                          type={file.type}
                          className="w-[200px] h-[200px] rounded-md object-cover"
                        />
                      )}
                      {/* <button onClick={() => handleDownloadFiles(fileObj)}>Descargar</button> */}
                      <div className="flex flex-col justify-center text-center">
                        {/* <CheckCircleOutlineIcon className="text-green-600 mx-auto" /> */}
                        {/* <span>Certificado</span> */}
                        {/* <span className="text-green-600">Valido</span> */}
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setVerCertificado({
                              open: true,
                              isImg: isImage,
                              src: isImage ? srcImg : fileURL,
                              type: file.type,
                              name: file.name,
                              fileObj,
                              fileId: file.id,
                              isObserved: file.isObserved === 0 ? false : true,
                            });
                          }}
                        >
                          Ver
                        </Button>
                        {file.isObserved === 1 && (
                          <div className="flex flex-col mt-1 justify-center items-center">
                            <span className="font-semibold">Observado</span>
                            <span>{formatDate(file.observationDateTime)}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            {oneLicence.tipoLicencia === "MEF" && (
              <>
                <Divider />
                <h1 className="font-medium text-slate-400 ml-2 mb-2 text-2xl">
                  DNI Familiar
                </h1>
                <Divider className="mt-2" />
                <div className="flex flex-col gap-3 mt-2">
                  {oneLicence?.files
                    ?.filter((fl) => fl.path.includes("dniFamiliar"))
                    ?.map((file) => {
                      const byteCharacters = atob(file.bytes);
                      const byteNumbers = new Array(byteCharacters.length);
                      for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                      }
                      const byteArray = new Uint8Array(byteNumbers);

                      const blob = new Blob([byteArray], { type: file.type });

                      // Obtén la fecha de creación del archivo como un objeto Date
                      const createdDate = new Date(file.createdDate);

                      // Crea el objeto File con el Blob y los atributos necesarios
                      const fileObj = new File([blob], file.name, {
                        lastModified: createdDate.getTime(),
                        type: file.type,
                      });
                      const fileURL = URL.createObjectURL(fileObj);
                      const srcImg = `data:${file.type};base64,${file.bytes}`;
                      const isImage = file.type.includes("image/");
                      return (
                        <div key={file.name} className="flex gap-4">
                          {file.type.includes("image/") ? (
                            <img
                              src={srcImg}
                              alt={file.name}
                              className="w-[200px] h-[200px] rounded-md object-cover"
                            />
                          ) : (
                            <embed
                              src={fileURL}
                              type={file.type}
                              className="w-[200px] h-[200px] rounded-md object-cover"
                            />
                          )}
                          {/* <button onClick={() => handleDownloadFiles(fileObj)}>Descargar</button> */}
                          <div className="flex flex-col justify-center text-center">
                            {/* <CheckCircleOutlineIcon className="text-green-600 mx-auto" /> */}
                            <span>DNI</span>
                            {/* <span className="text-green-600">Valido</span> */}
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setVerCertificado({
                                  dni: true,
                                  open: true,
                                  isImg: isImage,
                                  src: isImage ? srcImg : fileURL,
                                  type: file.type,
                                  name: file.name,
                                  fileObj,
                                  fileId: file.id,
                                  isObserved:
                                    file.isObserved === 0 ? false : true,
                                });
                              }}
                            >
                              Ver
                            </Button>
                            {file.isObserved === 1 && (
                              <div className="flex flex-col mt-1 justify-center items-center">
                                <span className="font-semibold">Observado</span>
                                <span>
                                  {formatDate(file.observationDateTime)}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
            {isCompRevisar && (
              <p className="bg-red-600 mt-2 text-white rounded-md p-1 w-fit shadow-md px-2">
                Comportamiento a revisar
              </p>
            )}
            {oneLicence.idTramiteOriginal && (
              <p className="bg-red-600 mt-2 text-white rounded-md p-1 w-fit shadow-md px-2">
                Anulación Previa DGAMT
              </p>
            )}
            {oneLicence.diasCertificado > diagnosticos[0]?.ter &&
              (oneLicence.tipoLicencia === "MEH" ||
                oneLicence.tipoLicencia === "MEF" ||
                oneLicence.tipoLicencia === "MED") && (
                <p className="bg-red-600 mt-2 text-white rounded-md p-1 w-fit shadow-md px-2">
                  Ter excedido
                </p>
              )}
            {oneLicence.diagnosticoConsecutivo && (
              <p className="bg-red-600 mt-2 text-white rounded-md p-1 w-fit shadow-md px-2">
                Diagnostico Consecutivo
              </p>
            )}
          </aside>
          <Divider orientation="vertical" flexItem />
          <aside className="w-full">
            <h1 className="font-medium ml-2 text-slate-400 mb-2 text-2xl">
              Información personal
            </h1>
            <Divider />
            <section className="flex flex-wrap gap-5 py-4 sm:ml-[15px]">
              <TextField
                label="ID HR"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.idhr}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
             
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleInfoLaboral}
                >
                  Info. Laboral
                </Button>
              

              <TextField
                label="Nombre"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.firstName}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Apellido"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.lastName}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Edad"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getAge(oneLicence)}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Cuil"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {`${oneLicence.cuit
                        ?.toString()
                        .substring(0, 2)}-${oneLicence.cuit
                        ?.toString()
                        .substring(2, 10)}-${oneLicence.cuit
                        ?.toString()
                        .substring(10)}`}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Nro. Documento"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.documento?.length > 8
                        ? oneLicence.documento.slice(2, 10)
                        : oneLicence.documento}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Nro. Telefono"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.celular?.slice(0, 13)}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Dirección"
                id="outlined-start-adornment"
                disabled
                multiline
                maxRows={3}
                className="w-[40%]"
                value={oneLicence?.direccion ?? ""}
                variant="standard"
              />
              <TextField
                label="Email"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.email}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </section>
            <h1 className="font-medium text-slate-400 ml-2 mb-2 text-2xl">
              Información del/la Médico/a tratante
            </h1>
            <Divider />
            <section className="flex flex-wrap gap-5 py-4 sm:ml-[15px]">
              <TextField
                label="Nombre/s"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.nombreMedico ?? "---"}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Apellido/s"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.apellidoMedico ?? "---"}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Matricula"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.tipoMatricula || oneLicence.nombreMatricula
                        ? oneLicence.tipoMatricula +
                          " " +
                          oneLicence.nombreMatricula
                        : "---"}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Especialidad"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.especialidadMedico ?? "---"}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </section>
            {oneLicence.subtitle !== "Embarazo" && (
              <>
                <h1 className="font-medium text-slate-400 ml-2 mb-2 text-2xl">
                  Domicilio de Reposo
                </h1>
                <Divider />
                <section className="flex flex-wrap gap-5 py-4 sm:ml-[15px]">
                  <TextField
                    label="Calle"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {domicilio.calle ?? "---"}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    label="Número"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {domicilio.altura ?? "---"}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    label="Piso"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {domicilio.piso ?? "---"}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    label="Departamento"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {domicilio.depto ?? "---"}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    label="Provincia"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {Provincias.find(
                            (x) => x.id === domicilio?.codProvincia
                          )?.descripcion ?? "---"}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    label="Localidad"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {Localidades.find(
                            (x) =>
                              x.id === domicilio?.codLocalidad &&
                              x.idProvincia === domicilio?.codProvincia
                          )?.descripcion ?? "---"}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    label="Cod. Postal"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {domicilio.codPostal ?? "---"}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    label="Observaciones"
                    id="outlined-start-adornment"
                    disabled
                    multiline
                    maxRows={5}
                    className="w-[90%]"
                    value={domicilio.observaciones ?? "-"}
                  />
                </section>
              </>
            )}
            {oneLicence.tipoLicencia === "MEF" && (
              <>
                <h1 className="font-medium text-slate-400 ml-2 mb-2 text-2xl">
                  Información del Familiar
                </h1>
                <Divider />
                <section className="flex flex-wrap gap-5 py-4 sm:ml-[15px]">
                  <TextField
                    label="Nombre/s"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            familiares?.find(
                              (x) => x.id === oneLicence.userFamilyId
                            )?.nombre
                          }
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    label="Apellido/s"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            familiares?.find(
                              (x) => x.id === oneLicence.userFamilyId
                            )?.apellido
                          }
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    label="Nro. Documento"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            familiares?.find(
                              (x) => x.id === oneLicence.userFamilyId
                            )?.documento
                          }
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    label="Parentesco"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            user?.familyKindship.find(
                              (x) =>
                                x.id ===
                                familiares?.find(
                                  (x) => x.id === oneLicence.userFamilyId
                                )?.parentesco
                            )?.description
                          }
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                </section>
              </>
            )}
            <Button variant="outlined" onClick={() => setOpenHistory(true)}>
              Historial de Licencia
            </Button>
          </aside>
        </div>
        <Snackbar open={error} autoHideDuration={3000}>
          <Alert severity="error">Ups.. Algo salio mal</Alert>
        </Snackbar>
      </div>
      <Dialog open={openHistory} onClose={() => setOpenHistory(false)}>
        <DialogContent>
          {history?.length < 1 ? (
            <h1 className="text-[#5836ff] font-medium text-xl text-center">
              No existe historial para esta licencia
            </h1>
          ) : (
            <>
              <h1 className="text-[#5836ff] font-medium text-xl text-center">
                Historial de Licencia
              </h1>
              <div>
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-200 ">
                    <tr>
                      <th scope="col" class="px-3 py-3">
                        AUDITOR
                      </th>
                      <th scope="col" class="px-3 py-3">
                        ACCIÓN
                      </th>
                      <th scope="col" class="px-3 py-3">
                        FECHA
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((item) => (
                      <tr key={item.id} className="bg-white border-b">
                        <th
                          scope="row"
                          class="px-1 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {item.auditor}
                        </th>
                        <td class="px-3 py-4">{item.newValue}</td>
                        <td class="px-3 py-4">
                          {dayjs(item.createdDate).format('YYYY-MM-DD HH:mm')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => setOpenHistory(false)}
            size="small"
            variant="outlined"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openSaldo} onClose={() => setOpenSaldo(false)}>
        <DialogContent>
          <div className="flex flex-col gap-2">
            {infoSaldo?.map((res, index) => (
              <div
                key={index}
                className="border-1 flex-col p-3 rounded-md shadow-md"
              >
                <h1 className="text-center text-lg mb-2 font-semibold">
                  {res.nombreLicencia}
                </h1>
                {res.detalle?.map((det, index) => (
                  <div key={index} className="w-full flex flex-col">
                    <h2 className="mx-auto text-center mt-2 text-[17px]">
                      {det.nombreTope}
                    </h2>
                    <div className="flex mb-2 justify-center gap-4">
                      <div className="flex flex-col">
                        <span className="text-center font-medium">TOPE</span>
                        <span className="text-center border-1">{det.tope}</span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-center font-medium">
                          Consumido
                        </span>
                        <span className="text-center border-1">
                          {det.consumido}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-center font-medium">Saldo</span>
                        <span className="text-center border-1">
                          {det.saldo}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => setOpenSaldo(false)}
            size="small"
            variant="outlined"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={verCertificado.open}
        onClose={() =>
          setVerCertificado({
            dni: false,
            open: false,
            isImage: null,
            src: null,
            name: null,
            type: null,
            fileObj: null,
            fileId: null,
          })
        }
      // PaperProps={{ style: { maxWidth:'1000px', width: '1500px' } }}
      >
        <DialogContent
          className="text-center flex flex-col gap-3"
        >
          <div className="w-full flex flex-col gap-2">
            <div className="flex gap-1 mx-auto">
              <span>{verCertificado.dni ? "DNI:" : "Certificado:"}</span>
              <span className="text-green-600">Valido</span>
              <CheckCircleOutlineIcon className="text-green-600" />
            </div>
            {verCertificado.isImg ? (
              <TransformWrapper>
                {({ zoomIn, zoomOut }) => (
                  <>
                    <div className="flex mx-auto items-center">
                      <span className="mr-2">Editar imagen</span>
                      <Button
                        size="small"
                        onClick={() => zoomOut()}
                      >
                        <RemoveIcon />
                      </Button>
                      <Button
                        size="small"
                        onClick={() => zoomIn()}
                      >
                        <AddIcon />
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setEditImg({ ...editImg, rotate: editImg.rotate + 90 });
                        }}
                      >
                        <RotateRightIcon />
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setEditImg({ ...editImg, rotate: editImg.rotate - 90 });
                        }}
                      >
                        <RotateLeftIcon />
                      </Button>
                      <Button
                        size="small"
                        onClick={() => handleDownloadFiles(verCertificado.fileObj)}
                      >
                        <FileDownloadIcon />
                      </Button>
                    </div>
                    <TransformComponent>
                      <img
                        src={verCertificado.src}
                        alt={verCertificado.name}
                        style={{
                          scale: `${editImg.scale}`,
                          transform: `rotate(${editImg.rotate}deg)`,
                          width: '100%',
                        }}
                        className={`w-[400px] h-[400px] mx-auto rounded-md object-contain`}
                      />

                    </TransformComponent>
                  </>
                )}
              </TransformWrapper>
            ) : (
              <>
                <div className="flex mx-auto items-center">
                  <span className="mr-2">Editar imagen</span>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditImg({ ...editImg, scale: editImg.scale - 0.2 });
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditImg({ ...editImg, scale: editImg.scale + 0.2 });
                    }}
                  >
                    <AddIcon />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditImg({ ...editImg, rotate: editImg.rotate + 90 });
                    }}
                  >
                    <RotateRightIcon />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditImg({ ...editImg, rotate: editImg.rotate - 90 });
                    }}
                  >
                    <RotateLeftIcon />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleDownloadFiles(verCertificado.fileObj)}
                  >
                    <FileDownloadIcon />
                  </Button>
                </div>
                <embed
                  src={verCertificado.src}
                  type={verCertificado.type}
                  style={{
                    width: '900px',
                    scale: `${editImg.scale}`,
                    transform: `rotate(${editImg.rotate}deg)`,
                  }}
                  className={`w-[600px] h-[400px] mx-auto rounded-md object-contain`}
                />
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() =>
              setVerCertificado({
                open: false,
                isImage: null,
                src: null,
                name: null,
                type: null,
                fileObj: null,
                fileId: null,
              })
            }
            size="small"
            variant="outlined"
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={verCertificado.isObserved}
            onClick={() =>
              dispatch(putObservarFile(verCertificado.fileId)).then((res) => {
                dispatch(fetchOneAuditProLicenciaAsync({ id, licenseTypeId }));
                setVerCertificado({ ...verCertificado, isObserved: true });
              })
            }
          >
            Observar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openInfoLaboral} onClose={() => setOpenInfoLaboral(false)}>
        <DialogContent className="text-center flex flex-col gap-3">
          <h1 className="font-semibold text-xl">
            Cargos Activos del/a Empleado/a
          </h1>
          {uOrganizativa?.map((uorg) => (
            <div className="flex flex-col gap-2" key={uorg.code}>
              <div>
                <p className="font-medium text-[#5836ff] text-lg">
                  Unidad Organizativa
                </p>
                <LightTooltip title={uorg.rutaunidadorganizativa}>
                  <Button>{uorg.code} - {uorg.description}</Button>
                </LightTooltip>
              </div>
              <div>
                <p className="font-medium text-[#5836ff] text-lg">
                  Código de Registro
                </p>
                <p>
                  {uorg.codregimen} - {uorg.codregistro} -{" "}
                  {uorg.desccodregistro}
                </p>
              </div>
              <div>
                <p className="font-medium text-[#5836ff] text-lg">Función</p>
                <p>{uorg.descpuesto}</p>
              </div>
              <div>
                <p className="font-medium text-[#5836ff] text-lg">
                  Fecha de Inicio
                </p>
                <p>{uorg.fechadesde}</p>
              </div>
              <hr />
            </div>
          ))}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            size="small"
            color="primary"
            variant="contained"
            autoFocus
            onClick={() => setOpenInfoLaboral(false)}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ paddingTop: 15, maxWidth: 500 }}
        open={rtaActionLicencia.open}
        autoHideDuration={4500}
        onClose={() =>
          setRtaActionLicencia({
            success: false,
            open: false,
            message: "",
          })
        }
      >
        <Alert
          onClose={() =>
            setRtaActionLicencia({
              success: false,
              open: false,
              message: "",
            })
          }
          severity={rtaActionLicencia.success ? "success" : "error"}
        >
          {rtaActionLicencia.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={openModal.open}
        onClose={() => handleModal({ open: false })}
      >
        <DialogContent className="text-center">
          <div dangerouslySetInnerHTML={{ __html: openModal.message }} />
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => handleModal({ open: false })}
            size="small"
            variant="outlined"
          >
            No
          </Button>
          <Button
            onClick={() => handleAction(openModal.action)}
            size="small"
            color="primary"
            variant="contained"
            autoFocus
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAprobar}
        onClose={() => {
          setOpenAprobar(false);
          dispatch(resetDocuments());
          setMotivoAprobar(null);
          setMotivoLT(null);
          setComentarioAprobar("");
        }}
      >
        <DialogContent>
          <h1 className="text-[#5836ff] font-medium text-xl text-center">
            Editar y Auditar
          </h1>
          {oneLicence.subtitle === "Alumbramiento" ? (
            <>
              <div className="mt-2 flex flex-col gap-3 bg-blue-200/30 p-3 rounded-md shadow-sm min-w-[500px]">
                <TextField
                  label="Tipo de dictamen"
                  disabled
                  id="outlined-disabled"
                  multiline
                  maxRows={1}
                  value={
                    "Editar y autorizar solicitud de licencia por alumbramiento"
                  }
                  variant="standard"
                />
              </div>
              <div className="mt-3 flex flex-col gap-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={newNeonatologia}
                      onChange={(e) => setNewNeonatologia(e.target.checked)}
                      name="neo"
                    />
                  }
                  label="¿Él/la recién nacido/a debió permanecer internado/a en neonatología?"
                />
                {newNeonatologia && (
                  <>
                    <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                      Dias de internación
                    </FormLabel>
                    <TextField
                      id="outlined-start-adornment"
                      size="small"
                      defaultValue={newDiasNeonatologia}
                      value={newDiasNeonatologia}
                      type="number"
                      onChange={(e) => setNewDiasNeonatologia(e.target.value)}
                    />
                  </>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={newNacimientoMult}
                      onChange={(e) => setNewNacimientoMult(e.target.checked)}
                      name="nacMult"
                    />
                  }
                  label="¿Es un nacimiento múltiple?"
                />
                {newNacimientoMult && (
                  <>
                    <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                      Cantidad de hijos/as
                    </FormLabel>
                    <FormControl size="small">
                      <Select
                        value={newCantidad}
                        onChange={(e) => setNewCantidadHijos(e.target.value)}
                      >
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
                <div className="flex flex-col mt-2 w-full gap-1">
                  <FormLabel
                    sx={{
                      color: "#5836ff",
                      fontWeight: "bold",
                      textAlign: "start",
                    }}
                  >
                    Fecha de Nacimiento
                  </FormLabel>
                  <p>
                    En caso de alumbramiento múltiple, si nacieron en fechas
                    distintas completá la fecha de el/la que nació primero/a.
                  </p>
                  <LocalizationProvider
                    adapterLocale="es"
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      value={dayjs(newFechaNacimiento)}
                      onChange={(e) => setNewFechaNacimiento(e.toISOString())}
                      renderInput={(props) => <TextField {...props} />}
                      slotProps={{ textField: { size: "small", error: false } }}
                      disablePast
                    />
                  </LocalizationProvider>
                </div>
                <FormControl
                  size="small"
                  sx={{ width: "100%", marginTop: "15px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Motivo *
                  </InputLabel>
                  <Select
                    value={motivoAlumbramiento}
                    onChange={(e) => setMotivoAlumbramiento(e.target.value)}
                    name="motivo"
                    label="Motivo *"
                  >
                    <MenuItem value={"Se aprueba la licencia"}>
                      Se aprueba la licencia
                    </MenuItem>
                    <MenuItem
                      value={
                        "Se modifica cantidad de hijos/as según documentación respaldatoria"
                      }
                    >
                      Se modifica cantidad de hijos/as según documentación
                      respaldatoria
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </>
          ) : oneLicence.subtitle === "Embarazo" ? (
            <>
              <div className="mt-2 flex flex-col gap-3 bg-blue-200/30 p-3 rounded-md shadow-sm min-w-[500px]">
                <TextField
                  label="Tipo de dictamen"
                  disabled
                  id="outlined-disabled"
                  multiline
                  maxRows={1}
                  value={
                    "Editar y autorizar solicitud de licencia por embarazo"
                  }
                  variant="standard"
                />
              </div>
              <div className="flex flex-col mt-2 w-full gap-1">
                <FormLabel
                  sx={{
                    color: "#5836ff",
                    fontWeight: "bold",
                    textAlign: "start",
                  }}
                >
                  Fecha probable de parto
                </FormLabel>
                <LocalizationProvider
                  adapterLocale="es"
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    value={dayjs(newFechaProbable)}
                    onChange={(e) => setNewFechaProbable(e.toISOString())}
                    renderInput={(props) => <TextField {...props} />}
                    slotProps={{ textField: { size: "small", error: false } }}
                    disablePast
                  />
                </LocalizationProvider>
              </div>
              <div className="flex flex-col mt-3 gap-2">
                <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                  Cantidad de días apto/a para trabajar antes de la FPP.
                </FormLabel>
                <TextField
                  id="outlined-start-adornment"
                  size="small"
                  className="max-w-[100px]"
                  defaultValue={newDiasFPP}
                  type="number"
                  onChange={(e) => {
                    setChangeDiasFPP(true);
                    setNewDiasFPP(e.target.value);
                  }}
                />
                {(newDiasFPP < 15 || newDiasFPP > oneLicence.diaMaximoPreParto) && (
                  <p className="p-2 border rounded-md w-fit mt-2 bg-red-500 text-white">
                    {`Error los dias deben ser superior a 15 y menor igual a ${oneLicence.diaMaximoPreParto}`}
                  </p>
                )}
              </div> 
              {
              Number(newDiasFPP)  !==
                Math.ceil(
                  Math.abs(
                    new Date(oneLicence?.fechaProbable) -
                      new Date(oneLicence?.startDate)
                  ) /
                    (1000 * 60 * 60 * 24)
                ) +1 ? (
                <>
                  <div className="flex flex-col w-full gap-1 mt-2">
                    <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                      Motivo
                    </FormLabel>
                    <TextField
                      multiline
                      maxRows={2}
                      id="outlined-start-adornment"
                      size="small"
                      disabled
                      value="Se modifica días de periodo preparto, según documentación respaldatoria"
                    />
                  </div>
                </>
              ) : (
                <FormControl
                  size="small"
                  sx={{ width: "100%", marginTop: "15px" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Motivo *
                  </InputLabel>
                  <Select
                    value={motivoEmbarazo}
                    onChange={(e) => setMotivoEmbarazo(e.target.value)}
                    name="motivo"
                    label="Motivo *"
                  >
                    <MenuItem value={"Se aprueba la licencia"}>
                      Se aprueba la licencia
                    </MenuItem>
                    <MenuItem
                      value={
                        "Se modifica cantidad de hijos/as según documentación respaldatoria"
                      }
                    >
                      Se modifica cantidad de hijos/as según documentación
                      respaldatoria
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </>
          ) : oneLicence.subtitle === "Largo Tratamiento" ? (
            <>
              <div className="mt-2 flex flex-col gap-3 bg-blue-200/30 p-3 rounded-md shadow-sm min-w-[500px] max-w-[500px]">
                <TextField
                  label="Tipo de dictamen"
                  disabled
                  id="outlined-disabled"
                  multiline
                  maxRows={1}
                  value={"Largo Tratamiento"}
                  variant="standard"
                />
                <TextField
                  label="Diagnostico inicial"
                  disabled
                  id="outlined-disabled"
                  multiline
                  maxRows={2}
                  defaultValue={oneLicence.diagnostico ?? ""}
                  value={oneLicence.diagnostico ?? ""}
                  variant="standard"
                />
                <div className="flex gap-3 w-full flex-col sm:flex-row">
                  <TextField
                    label={"Días solicitados"}
                    id="outlined-start-adornment"
                    disabled
                    className="w-[33%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {oneLicence.diasCertificado}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  {oneLicence.diagnostico ? (
                    <TextField
                      label="Ter"
                      id="outlined-start-adornment"
                      disabled
                      className="w-[33%]"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {diagnosticos.length > 0
                              ? diagnosticos[0].ter
                              : null}{" "}
                            {diagnosticos.length > 0
                              ? diagnosticos[0].ter === 1
                                ? "dia"
                                : "dias"
                              : null}
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  ) : null}
                  <TextField
                    label="Fecha de creación"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[33%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {formatDate(oneLicence.createDate)}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                </div>

                <div className="flex gap-2 w-full flex-col sm:flex-row">
                  
                <TextField
                    label="Fecha Inicio de ausentismo"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[33%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {formatDate(oneLicence.startDate)}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    label="Fecha de certificado"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[33%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {formatDate(oneLicence.fechaCertificado)}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                </div>

              </div>
              <div className="flex gap-2 mt-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nuevaCitacion}
                      onChange={(e) => {
                        if (e.target.checked) setAltaMedica(false);
                        setNuevaCitacion(e.target.checked);
                      }}
                      name="nuevaCitacion"
                    />
                  }
                  label="Nueva citación"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={altaMedica}
                      onChange={(e) => {
                        if (e.target.checked) setNuevaCitacion(false);
                        setAltaMedica(e.target.checked);
                      }}
                      name="altaMedica"
                    />
                  }
                  label="Alta médica"
                />
              </div>
              <div className="flex flex-col mt-2 w-full gap-1">
                <FormLabel
                  sx={{
                    color: "#5836ff",
                    fontWeight: "bold",
                    textAlign: "start",
                  }}
                >
                  Fecha de {nuevaCitacion ? "Nueva Citación" : "Alta Médica"}
                </FormLabel>
                <LocalizationProvider
                  adapterLocale="es"
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    value={dayjs(fechaCitAlta)}
                    onChange={(e) => setFechaCitAlta(e.toISOString())}
                    renderInput={(props) => <TextField {...props} />}
                    slotProps={{ textField: { size: "small", error: false } }}
                    disablePast
                  />
                </LocalizationProvider>
                <p className="text-[#5836ff] mt-1">
                  Recordá "Cambiar CEMET" al finalizar esta auditoría si querés
                  que la próxima citación sea VideoConsulta
                </p>
              </div>
              {fechaCitAlta && (
                <p className="p-2 text-[#5836ff] bg-cyan-100 shadow-md rounded-md w-fit mt-1">
                  {Math.ceil(
                    Math.abs(
                      new Date(oneLicence.startDate) - new Date(fechaCitAlta)
                    ) /
                      (1000 * 60 * 60 * 24)
                  )}{" "}
                  día
                  {Math.ceil(
                    Math.abs(
                      new Date(oneLicence.startDate) - new Date(fechaCitAlta)
                    ) /
                      (1000 * 60 * 60 * 24)
                  ) === 1
                    ? null
                    : "s"}{" "}
                  aprobado
                  {Math.ceil(
                    Math.abs(
                      new Date(oneLicence.createDate) - new Date(fechaCitAlta)
                    ) /
                      (1000 * 60 * 60 * 24)
                  ) === 1
                    ? null
                    : "s"}
                </p>
              )}
              <div className="flex flex-col mt-3 gap-2">
                <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                  Escribí una nota y comentario (Obligatorio)
                </FormLabel>
                <textarea
                  value={comentarioAprobar}
                  onChange={(e) => {
                    try {
                      if (e.target.value.length > 2000) {
                        return;
                      } else {
                        setComentarioAprobar(e.target.value);
                      }
                    } catch (error) {
                      console.error(
                        "Error al manejar cambios en el campo de observaciones:",
                        error
                      );
                    }
                  }}
                  className="border rounded-md p-2"
                  placeholder="Máximo 2000 caracteres."
                />
              </div>
              <div className="flex flex-col mt-3 gap-2">
                <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                  Nuevo Diagnóstico (Opcional)
                </FormLabel>
                <div className="flex gap-2 items-center">
                  <Autocomplete
                    freeSolo
                    size="small"
                    fullWidth
                    options={data.diagnosticos}
                    getOptionLabel={(option) => option.description ?? ""}
                    onChange={(e, values) => setDiagnosticLT(values)}
                    value={diagnosticLT}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => getLicense(e.target?.value)}
                        InputLabelProps={{ shrink: false }}
                      />
                    )}
                  />
                  <div className="flex flex-col w-[150px] gap-2">
                    <p className="text-[#5836ff]">
                      TER: {diagnosticLT?.ter}{" "}
                      {diagnosticLT?.ter === 1 ? "día" : "días"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 mt-2">
                <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                  Agregar documentación
                </FormLabel>
                <DropDown />
              </div>
              <FormControl
                size="small"
                sx={{ width: "100%", marginTop: "15px" }}
              >
                <InputLabel id="demo-simple-select-label">Motivo *</InputLabel>
                <Select
                  value={motivoLT}
                  onChange={(e) => setMotivoLT(e.target.value)}
                  name="motivo"
                  label="Motivo *"
                >
                  {nuevaCitacion ? (
                    <MenuItem
                      value={"Se aprueba licencia hasta nueva citación"}
                    >
                      Se aprueba licencia hasta nueva citación
                    </MenuItem>
                  ) : (
                    <MenuItem value={"Se otorga alta médica"}>
                      Se otorga alta médica
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          ) : (
            <>
              <div className="mt-2 flex flex-col gap-3 bg-blue-200/30 p-3 rounded-md shadow-sm min-w-[300px]">
                <TextField
                  label="Diagnostico inicial"
                  disabled
                  id="outlined-disabled"
                  multiline
                  maxRows={2}
                  defaultValue={oneLicence.diagnostico ?? ""}
                  value={oneLicence.diagnostico ?? ""}
                  variant="standard"
                />
                <div className="flex gap-4">
                  {oneLicence.tipoLicencia !== "MEH" && (
                    <TextField
                      label={"Días del certificado"}
                      id="outlined-start-adornment"
                      disabled
                      className="w-[40%]"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {oneLicence.diasCertificado}
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  )}
                  {oneLicence.tipoLicencia === "MEF" && (
                    <TextField
                      label={"Días solicitados"}
                      id="outlined-start-adornment"
                      disabled
                      className="w-[35%]"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {oneLicence.diasSolicitados}
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  )}
                  <TextField
                    label="Inicio de Ausentismo"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {formatDate(oneLicence?.startDate)}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  {oneLicence.diagnostico ? (
                    <TextField
                      label="Ter"
                      id="outlined-start-adornment"
                      disabled
                      className="w-[20%]"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {diagnosticos.length > 0
                              ? diagnosticos[0].ter
                              : null}{" "}
                            {diagnosticos.length > 0
                              ? diagnosticos[0].ter === 1
                                ? "dia"
                                : "dias"
                              : null}
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                  ) : null}
                </div>
              </div>
              <div className="flex flex-col mt-3 gap-2">
                <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                  Diagnóstico *
                </FormLabel>
                <div className="flex gap-2 items-center">
                  <Autocomplete
                    freeSolo
                    size="small"
                    fullWidth
                    options={data.diagnosticos}
                    getOptionLabel={(option) => option.description ?? ""}
                    onChange={(e, values) => setNewDiagnostic(values)}
                    defaultValue={newDiagnostic}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => getLicense(e.target?.value)}
                        InputLabelProps={{ shrink: false }}
                      />
                    )}
                  />
                  <div className="flex flex-col w-[150px] gap-2">
                    <p className="text-[#5836ff]">
                      TER: {newDiagnostic?.ter}{" "}
                      {newDiagnostic?.ter === 1 ? "día" : "días"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-3 gap-2">
                <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                  Observaciones{" "}
                  {newDiagnostic?.description !== oneLicence?.diagnostico &&
                    "*"}
                </FormLabel>
                <textarea
                  value={observacionesAprobar}
                  onChange={(e) => {
                    try {
                      if (e.target.value.length > 2000) {
                        return;
                      } else {
                        setObservacionesAprobar(e.target.value);
                      }
                    } catch (error) {
                      console.error(
                        "Error al manejar cambios en el campo de observaciones:",
                        error
                      );
                    }
                  }}
                  className="border rounded-md p-2"
                  placeholder="Máximo 2000 caracteres."
                />
              </div>
              <div className="flex items-center mt-3 gap-2">
                <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                  Dias aprobados *
                </FormLabel>
                <TextField
                  id="outlined-start-adornment"
                  size="small"
                  className="max-w-[100px]"
                  defaultValue={oneLicence.diasCertificado}
                  value={newDiasAprobados}
                  type="number"
                  onChange={handleChangeDiasAprobados}
                />
              </div>
              {newDiasAprobados > newDiagnostic?.topeTer && (
                <p className="p-2 border rounded-md w-fit mt-2 bg-red-500 text-white">
                  Error: los días ingresados a aprobar superan el tope del TER.
                </p>
              )}
              {oneLicence.tipoLicencia === "MEF" && (
                <>
                  <div className="flex items-center mt-3 gap-2">
                    <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                      Dias solicitados aprobados *
                    </FormLabel>
                    <TextField
                      disabled
                      id="outlined-start-adornment"
                      size="small"
                      className="max-w-[100px]"
                      defaultValue={oneLicence.diasSolicitados}
                      value={newDiasSolicitados}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={enfGrave}
                          onChange={() => setEnfGrave(!enfGrave)}
                          name="enfGrave"
                        />
                      }
                      label="Enfermedad Grave"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={compRev}
                          onChange={(e) => setCompRev(!compRev)}
                          name="CompRev"
                        />
                      }
                      label="Comportamiento a revisar"
                    />
                  </div>
                  <FormControl
                    size="small"
                    sx={{ width: "100%", marginTop: "15px" }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Motivo *
                    </InputLabel>
                    <Select
                      value={motivoAprobar}
                      onChange={(e) => setMotivoAprobar(e.target.value)}
                      name="motivo"
                      label="Motivo *"
                    >
                      <MenuItem
                        value={
                          "Deberá actualizar el certificado en caso de solicitar extensión de licencia."
                        }
                      >
                        Deberá actualizar el certificado en caso de solicitar
                        extensión de licencia.
                      </MenuItem>
                      <MenuItem
                        value={
                          "Se aprueba con modificación de diagnóstico por evaluación médica."
                        }
                      >
                        Se aprueba con modificación de diagnóstico por
                        evaluación médica.
                      </MenuItem>
                      <MenuItem value={"Se aprueba la licencia."}>
                        Se aprueba la licencia.
                      </MenuItem>
                      <MenuItem
                        value={
                          "Se aprueba por el saldo de días que tiene para esta incidencia."
                        }
                      >
                        Se aprueba por el saldo de días que tiene para esta
                        incidencia.
                      </MenuItem>
                      <MenuItem
                        value={
                          "Se aprueba por menos días de los solicitados, por evaluación de Auditoria."
                        }
                      >
                        Se aprueba por menos días de los solicitados, por
                        evaluación de Auditoria.
                      </MenuItem>
                      <MenuItem
                        value={
                          "Se aprueba por menos días, deberá aportar estudios complementarios."
                        }
                      >
                        Se aprueba por menos días, deberá aportar estudios
                        complementarios.
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <div className="flex flex-col gap-2 mt-2">
                    <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                      Agregar documentación
                    </FormLabel>
                    <DropDown />
                  </div>
                </>
              )}
              {(oneLicence.tipoLicencia === "MED" ||
                oneLicence.tipoLicencia === "MEH") && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={compRev}
                        onChange={(e) => setCompRev(!compRev)}
                        name="CompRev"
                      />
                    }
                    label="Comportamiento a revisar"
                  />
                  <FormControl
                    size="small"
                    sx={{ width: "100%", marginTop: "15px" }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Motivo *
                    </InputLabel>
                    <Select
                      value={motivoAprobar}
                      onChange={(e) => setMotivoAprobar(e.target.value)}
                      name="motivo"
                      label="Motivo *"
                    >
                      <MenuItem
                        value={
                          "Deberá actualizar el certificado en caso de solicitar extensión de licencia."
                        }
                      >
                        Deberá actualizar el certificado en caso de solicitar
                        extensión de licencia.
                      </MenuItem>
                      <MenuItem
                        value={
                          "Se aprueba con modificación de diagnóstico por evaluación médica."
                        }
                      >
                        Se aprueba con modificación de diagnóstico por
                        evaluación médica.
                      </MenuItem>
                      <MenuItem value={"Se aprueba la licencia."}>
                        Se aprueba la licencia.
                      </MenuItem>
                      <MenuItem
                        value={
                          "Se aprueba por el saldo de días que tiene para esta incidencia."
                        }
                      >
                        Se aprueba por el saldo de días que tiene para esta
                        incidencia.
                      </MenuItem>
                      <MenuItem
                        value={
                          "Se aprueba por menos días de los solicitados, por evaluación de Auditoria."
                        }
                      >
                        Se aprueba por menos días de los solicitados, por
                        evaluación de Auditoria.
                      </MenuItem>
                      <MenuItem
                        value={
                          "Se aprueba por menos días, deberá aportar estudios complementarios."
                        }
                      >
                        Se aprueba por menos días, deberá aportar estudios
                        complementarios.
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <div className="flex flex-col gap-2 mt-2">
                    <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                      Agregar documentación
                    </FormLabel>
                    <DropDown />
                  </div>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              setOpenAprobar(false);
              dispatch(resetDocuments());
              setMotivoAprobar(null);
              setObservacionesAprobar("");
              setComentarioAprobar("");
              setMotivoLT(null);
            }}
            size="small"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => setHandleAceptarAprobar(true)}
            size="small"
            disabled={disabledAprobar()}
            color="primary"
            variant="contained"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAusente}
        onClose={() => {
          setOpenAusente(false);
          setDatosAusencia("");
        }}
      >
        <DialogContent>
          <h1 className="text-[#5836ff] font-medium text-xl text-center">
            Reportar Ausente
          </h1>
          <>
            <div className="mt-2 flex flex-col gap-3 bg-blue-200/30 p-3 rounded-md shadow-sm min-w-[300px]">
              <TextField
                label="Diagnostico inicial"
                disabled
                id="outlined-disabled"
                multiline
                maxRows={2}
                defaultValue={oneLicence.diagnostico ?? ""}
                value={oneLicence.diagnostico ?? ""}
                variant="standard"
              />
              <div className="flex gap-4">
                <TextField
                  label={"Duración"}
                  id="outlined-start-adornment"
                  disabled
                  className="w-[40%]"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">0</InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
                <TextField
                  label="Fecha de inicio"
                  id="outlined-start-adornment"
                  disabled
                  className="w-[40%]"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {formatDate(oneLicence?.startDate)}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />
                {oneLicence.diagnostico ? (
                  <TextField
                    label="Ter"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[20%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {diagnosticos.length > 0 ? diagnosticos[0].ter : null}{" "}
                          {diagnosticos.length > 0
                            ? diagnosticos[0].ter === 1
                              ? "dia"
                              : "dias"
                            : null}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                ) : null}
              </div>
            </div>
            <div className="flex flex-col mt-3 gap-2">
              <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                Días aprobados
              </FormLabel>
              <TextField
                id="outlined-start-adornment"
                size="small"
                disabled
                className="max-w-[100px]"
                value={0}
                type="number"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={compRev}
                    onChange={(e) => setCompRev(!compRev)}
                    name="CompRev"
                  />
                }
                label="Comportamiento a revisar"
              />
            </div>
            <div className="flex flex-col mt-3 gap-2">
              <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                Datos de ausencia{" "}
              </FormLabel>
              <textarea
                value={datosAusencia}
                onChange={(e) => {
                  try {
                    if (e.target.value.length > 2000) {
                      const truncatedText = e.target.value.slice(0, 2000);
                      setDatosAusencia(truncatedText);
                    } else {
                      setDatosAusencia(e.target.value);
                    }
                  } catch (error) {
                    console.error(
                      "Error al manejar cambios en el campo de observaciones:",
                      error
                    );
                  }
                }}
                className="border rounded-md p-2"
                placeholder="Máximo 2000 caracteres."
              />
            </div>
          </>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              setOpenAusente(false);
              setDatosAusencia("");
            }}
            size="small"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSubmitReportarAusente}
            size="small"
            disabled={!datosAusencia}
            color="primary"
            variant="contained"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={handleAceptarAprobar}
        onClose={() => setHandleAceptarAprobar(false)}
      >
        <DialogContent className="text-center flex flex-col gap-3">
          <h1 className="text-lg">
            ¿Desea aprobar la licencia {oneLicence.id}?
          </h1>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => setHandleAceptarAprobar(false)}
            size="small"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            autoFocus
            onClick={handleSubmitAprobar}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openNoAprobar}
        onClose={() => {
          setMotivoNoAprobar(null);
          setOpenNoAprobar(false);
        }}
      >
        <DialogContent>
          <h1 className="text-[#5836ff] font-medium text-xl text-center">
            Estás por rechazar una solicitud de licencia. ¿Deseás continuar?
          </h1>
          <p className="text-center text-[#5836ff] mt-2">
            Recordá que, en caso de continuar, esta acción no puede revertirse.
            Seleccioná el motivo por el cual se rechaza la solicitud de
            licencia. Tené en cuenta que le será enviado al/la empleado/a
            solicitante para su notificación.
          </p>
          <div className="flex flex-col mt-3 gap-2">
            <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
              Observaciones
            </FormLabel>
            <TextField
              multiline
              fullWidth
              rows={2}
              value={observacionesNoAprobar}
              placeholder="Máximo 2000 caracteres."
              onChange={(e) => {
                if (e.target.value.length > 2000) {
                  return;
                } else {
                  setObservacionesNoAprobar(e.target.value);
                }
              }}
            />
          </div>
          <FormControl size="small" sx={{ width: "100%", marginTop: "15px" }}>
            <InputLabel id="demo-simple-select-label">Motivo *</InputLabel>
            {oneLicence.subtitle === "Embarazo" && (
              <Select
                value={motivoNoAprobar}
                onChange={(e) => setMotivoNoAprobar(e.target.value)}
                name="motivo"
                label="Motivo *"
              >
                <MenuItem
                  value={
                    "El certificado adjunto presenta irregularidades en su confección"
                  }
                >
                  El certificado adjunto presenta irregularidades en su
                  confección
                </MenuItem>
                <MenuItem
                  value={
                    "El certificado no reúne los requisitos médicos necesarios."
                  }
                >
                  El certificado no reúne los requisitos médicos necesarios.
                </MenuItem>
                <MenuItem
                  value={
                    "La documentación respaldatoria no cumple los requisitos solicitados"
                  }
                >
                  La documentación respaldatoria no cumple los requisitos
                  solicitados
                </MenuItem>
                <MenuItem
                  value={
                    "No hay síntomas que acrediten la patología declaradas"
                  }
                >
                  No hay síntomas que acrediten la patología declaradas
                </MenuItem>
              </Select>
            )}
            {oneLicence.subtitle === "Alumbramiento" && (
              <Select
                value={motivoNoAprobar}
                onChange={(e) => setMotivoNoAprobar(e.target.value)}
                name="motivo"
                label="Motivo *"
              >
                <MenuItem
                  value={
                    "El certificado adjunto presenta irregularidades en su confección"
                  }
                >
                  El certificado adjunto presenta irregularidades en su
                  confección
                </MenuItem>
                <MenuItem
                  value={
                    "El certificado no contiene membrete profesional o institucional."
                  }
                >
                  El certificado no contiene membrete profesional o
                  institucional.
                </MenuItem>
                <MenuItem
                  value={
                    "El certificado no reúne los requisitos médicos necesarios."
                  }
                >
                  El certificado no reúne los requisitos médicos necesarios.
                </MenuItem>
                <MenuItem
                  value={
                    "La documentación respaldatoria no cumple los requisitos solicitados"
                  }
                >
                  La documentación respaldatoria no cumple los requisitos
                  solicitados
                </MenuItem>
                <MenuItem
                  value={
                    "No acompaña estudios complementarios que avalen la condición médica"
                  }
                >
                  No acompaña estudios complementarios que avalen la condición
                  médica
                </MenuItem>
                <MenuItem
                  value={"No hay síntomas que acrediten la patología declarada"}
                >
                  No hay síntomas que acrediten la patología declarada
                </MenuItem>
              </Select>
            )}
            {oneLicence.subtitle !== "Alumbramiento" &&
              oneLicence.subtitle !== "Embarazo" && (
                <Select
                  value={motivoNoAprobar}
                  onChange={(e) => setMotivoNoAprobar(e.target.value)}
                  name="motivo"
                  label="Motivo *"
                >
                  <MenuItem value={"Ausente a turno."}>
                    Ausente a turno.
                  </MenuItem>
                  <MenuItem value={"Ausente en la visita médica domiciliaria."}>
                    Ausente en la visita médica domiciliaria.
                  </MenuItem>
                  <MenuItem
                    value={
                      "El certificado adjunto presenta irregularidades en su confección."
                    }
                  >
                    El certificado adjunto presenta irregularidades en su
                    confección.
                  </MenuItem>
                  <MenuItem
                    value={
                      "El certificado no reúne los requisitos médicos necesarios."
                    }
                  >
                    El certificado no reúne los requisitos médicos necesarios.
                  </MenuItem>
                  <MenuItem
                    value={
                      "La documentación respaldatoria no cumple los requisitos solicitados."
                    }
                  >
                    La documentación respaldatoria no cumple los requisitos
                    solicitados.
                  </MenuItem>
                  <MenuItem
                    value={
                      "Licencia cargada fuera del plazo horario estipulado según normativa."
                    }
                  >
                    Licencia cargada fuera del plazo horario estipulado según
                    normativa.
                  </MenuItem>
                  <MenuItem
                    value={
                      "Lugar de atención es incongruente con el lugar declarado de reposo."
                    }
                  >
                    Lugar de atención es incongruente con el lugar declarado de
                    reposo.
                  </MenuItem>
                  <MenuItem
                    value={
                      "Lugar de atención es incongruente con el lugar declarado de reposo."
                    }
                  >
                    Lugar de atención es incongruente con el lugar declarado de
                    reposo.
                  </MenuItem>
                  {(oneLicence.tipoLicencia === "MED" ||
                    oneLicence.tipoLicencia === "MEF") && (
                    <MenuItem
                      value={"No aporta certificado médico con sintomatología."}
                    >
                      No aporta certificado médico con sintomatología.
                    </MenuItem>
                  )}
                  <MenuItem
                    value={
                      "No hay saldo de días para la solicitud de esta licencia."
                    }
                  >
                    No hay saldo de días para la solicitud de esta licencia.
                  </MenuItem>
                  <MenuItem
                    value={
                      "No hay síntomas que acrediten la patología declarada."
                    }
                  >
                    No hay síntomas que acrediten la patología declarada.
                  </MenuItem>
                </Select>
              )}
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              setMotivoNoAprobar(null);
              setOpenNoAprobar(false);
            }}
            size="small"
            variant="outlined"
          >
            No
          </Button>
          <Button
            disabled={!motivoNoAprobar || observacionesNoAprobar.length === 0}
            onClick={handleSubmitNoAprobar}
            size="small"
            color="primary"
            variant="contained"
            autoFocus
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openChangeCT}
        onClose={() => {
          setOpenChangeCT(false);
        }}
      >
        <DialogContent>
          <h1 className="text-[#5836ff] font-medium text-xl text-center">
            Estás por convertir la Licencia {oneLicence?.id} a Corto
            tratamiento. ¿Deseás continuar?
          </h1>
          <div className="flex flex-col mt-3 gap-2">
            <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
              Observaciones
            </FormLabel>
            <TextField
              multiline
              fullWidth
              rows={2}
              value={observacionesChangeCT}
              placeholder="Máximo 2000 caracteres."
              onChange={(e) => {
                if (e.target.value.length > 2000) {
                  return;
                } else {
                  setObservacionesChangeCT(e.target.value);
                }
              }}
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              setOpenChangeCT(false);
            }}
            size="small"
            variant="outlined"
          >
            No
          </Button>
          <Button
            onClick={handleSubmitChangeCT}
            size="small"
            color="primary"
            variant="contained"
            autoFocus
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAnular}
        onClose={() => {
          setMotivoAnular(null);
          setOpenAnular(false);
        }}
      >
        <DialogContent>
          <h1 className="text-[#5836ff] font-medium text-xl text-center">
            Estás por anular una solicitud de licencia. ¿Deseás continuar?
          </h1>
          <p className="text-center text-[#5836ff] mt-2">
            Recordá que, en caso de continuar, esta acción no puede revertirse.
            Seleccioná el motivo por el cual se anula la solicitud de licencia.
            Tené en cuenta que le será enviado al/la empleado/a solicitante para
            su notificación.
          </p>
          <div className="flex flex-col mt-3 gap-2">
            <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
              Observaciones
            </FormLabel>
            <TextField
              multiline
              fullWidth
              rows={2}
              value={observacionesAnular}
              placeholder="Máximo 2000 caracteres."
              onChange={(e) => {
                if (e.target.value.length > 2000) {
                  return;
                } else {
                  setObservacionesAnular(e.target.value);
                }
              }}
            />
          </div>
          <FormControl size="small" sx={{ width: "100%", marginTop: "15px" }}>
            <InputLabel id="demo-simple-select-label">Motivo *</InputLabel>
            {oneLicence.tipoLicencia === "MED" && (
              <Select
                value={motivoAnular}
                onChange={(e) => {
                  setMotivoAnular(e.target.value);
                }}
                name="motivo"
                label="Motivo *"
              >
                <MenuItem
                  value={
                    "Corresponde gestionar licencia médica familiar para este caso."
                  }
                >
                  Corresponde gestionar licencia médica familiar para este caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia por alumbramiento para este caso."
                  }
                >
                  Corresponde gestionar licencia por alumbramiento para este
                  caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia por embarazo para este caso."
                  }
                >
                  Corresponde gestionar licencia por embarazo para este caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia por hospitalización para este caso."
                  }
                >
                  Corresponde gestionar licencia por hospitalización para este
                  caso.
                </MenuItem>
                <MenuItem value={"Corresponde solicitar Contingencia ART."}>
                  Corresponde solicitar Contingencia ART.
                </MenuItem>
                <MenuItem
                  value={"Corresponde solicitar Licencia Administrativa."}
                >
                  Corresponde solicitar Licencia Administrativa.
                </MenuItem>
                <MenuItem
                  value={"Debe aportar epicrisis y/o parte quirúrgico."}
                >
                  Debe aportar epicrisis y/o parte quirúrgico.
                </MenuItem>
                <MenuItem value={"El certificado adjunto es ilegible."}>
                  El certificado adjunto es ilegible.
                </MenuItem>
                <MenuItem
                  value={
                    "El certificado no contiene membrete del profesional o institucional."
                  }
                >
                  El certificado no contiene membrete del profesional o
                  institucional.
                </MenuItem>
                <MenuItem
                  value={
                    "El certificado ya fue auditado, presente uno nuevo con fecha del día."
                  }
                >
                  El certificado ya fue auditado, presente uno nuevo con fecha
                  del día.
                </MenuItem>
                <MenuItem
                  value={
                    "No acompaña estudios complementarios que avalen condición médica."
                  }
                >
                  No acompaña estudios complementarios que avalen condición
                  médica.
                </MenuItem>
                <MenuItem
                  value={"No aporta carnet donde indique datos de vacunación."}
                >
                  No aporta carnet donde indique datos de vacunación.
                </MenuItem>
                <MenuItem
                  value={
                    "No corresponde licencia por enfermedad para el periodo solicitado."
                  }
                >
                  No corresponde licencia por enfermedad para el periodo
                  solicitado.
                </MenuItem>
                <MenuItem
                  value={
                    "Posee una licencia anterior pasada a Largo Tratamiento."
                  }
                >
                  Posee una licencia anterior pasada a Largo Tratamiento.
                </MenuItem>
              </Select>
            )}
            {oneLicence.tipoLicencia === "MEF" && (
              <Select
                value={motivoAnular}
                onChange={(e) => {
                  setMotivoAnular(e.target.value);
                }}
                name="motivo"
                label="Motivo *"
              >
                <MenuItem
                  value={
                    "Corresponde gestionar licencia médica para este caso."
                  }
                >
                  Corresponde gestionar licencia médica familiar para este caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia por alumbramiento para este caso."
                  }
                >
                  Corresponde gestionar licencia por alumbramiento para este
                  caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia por embarazo para este caso."
                  }
                >
                  Corresponde gestionar licencia por embarazo para este caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia por hospitalización para este caso."
                  }
                >
                  Corresponde gestionar licencia por hospitalización para este
                  caso.
                </MenuItem>
                <MenuItem
                  value={"Corresponde solicitar Licencia Administrativa."}
                >
                  Corresponde solicitar Licencia Administrativa.
                </MenuItem>
                <MenuItem
                  value={"Debe aportar epicrisis y/o parte quirúrgico."}
                >
                  Debe aportar epicrisis y/o parte quirúrgico.
                </MenuItem>
                <MenuItem value={"El certificado adjunto es ilegible."}>
                  El certificado adjunto es ilegible.
                </MenuItem>
                <MenuItem
                  value={
                    "El certificado no contiene membrete del profesional o institucional."
                  }
                >
                  El certificado no contiene membrete del profesional o
                  institucional.
                </MenuItem>
                <MenuItem
                  value={
                    "El certificado ya fue auditado, presente uno nuevo con fecha del día."
                  }
                >
                  El certificado ya fue auditado, presente uno nuevo con fecha
                  del día.
                </MenuItem>
                <MenuItem
                  value={
                    "No acompaña estudios complementarios que avalen condición médica."
                  }
                >
                  No acompaña estudios complementarios que avalen condición
                  médica.
                </MenuItem>
                <MenuItem
                  value={"No aporta carnet donde indique datos de vacunación."}
                >
                  No aporta carnet donde indique datos de vacunación.
                </MenuItem>
                <MenuItem
                  value={
                    "No corresponde licencia por enfermedad para el periodo solicitado."
                  }
                >
                  No corresponde licencia por enfermedad para el periodo
                  solicitado.
                </MenuItem>
              </Select>
            )}
            {oneLicence.tipoLicencia === "MEH" && (
              <Select
                value={motivoAnular}
                onChange={(e) => {
                  setMotivoAnular(e.target.value);
                }}
                name="motivo"
                label="Motivo *"
              >
                <MenuItem
                  value={
                    "Corresponde gestionar licencia médica familiar para este caso."
                  }
                >
                  Corresponde gestionar licencia médica familiar para este caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia médica para este caso."
                  }
                >
                  Corresponde gestionar licencia médica para este caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia por alumbramiento para este caso."
                  }
                >
                  Corresponde gestionar licencia por alumbramiento para este
                  caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia por embarazo para este caso."
                  }
                >
                  Corresponde gestionar licencia por embarazo para este caso.
                </MenuItem>
                <MenuItem
                  value={"Corresponde solicitar Licencia Administrativa."}
                >
                  Corresponde solicitar Licencia Administrativa.
                </MenuItem>
                <MenuItem value={"Corresponde solicitar Contingencia ART."}>
                  Corresponde solicitar Contingencia ART.
                </MenuItem>
                <MenuItem
                  value={"Debe aportar epicrisis y/o parte quirúrgico."}
                >
                  Debe aportar epicrisis y/o parte quirúrgico.
                </MenuItem>
                <MenuItem value={"El certificado adjunto es ilegible."}>
                  El certificado adjunto es ilegible.
                </MenuItem>
                <MenuItem
                  value={
                    "El certificado no contiene membrete del profesional o institucional."
                  }
                >
                  El certificado no contiene membrete del profesional o
                  institucional.
                </MenuItem>
                <MenuItem
                  value={
                    "El certificado ya fue auditado, presente uno nuevo con fecha del día."
                  }
                >
                  El certificado ya fue auditado, presente uno nuevo con fecha
                  del día.
                </MenuItem>
                <MenuItem
                  value={
                    "No acompaña estudios complementarios que avalen condición médica."
                  }
                >
                  No acompaña estudios complementarios que avalen condición
                  médica.
                </MenuItem>
              </Select>
            )}
            {oneLicence.subtitle === "Alumbramiento" && (
              <Select
                value={motivoAnular}
                onChange={(e) => {
                  setMotivoAnular(e.target.value);
                }}
                name="motivo"
                label="Motivo *"
              >
                <MenuItem
                  value={
                    "Corresponde gestionar licencia médica familiar para este caso."
                  }
                >
                  Corresponde gestionar licencia médica familiar para este caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia médica para este caso."
                  }
                >
                  Corresponde gestionar licencia médica para este caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia por embarazo para este caso."
                  }
                >
                  Corresponde gestionar licencia por embarazo para este caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde solicitar licencia por hospitalización para este caso"
                  }
                >
                  Corresponde solicitar licencia por hospitalización para este
                  caso
                </MenuItem>
                <MenuItem value={"El certificado adjunto es ilegible."}>
                  El certificado adjunto es ilegible.
                </MenuItem>
                <MenuItem
                  value={
                    "El certificado ya fue auditado, presente uno nuevo con fecha del día."
                  }
                >
                  El certificado ya fue auditado, presente un nuevo con fecha
                  del día.
                </MenuItem>
                <MenuItem
                  value={
                    "La documentación respaldatoria no se corresponde con lo solicitado"
                  }
                >
                  La documentación respaldatoria no se corresponde con lo
                  solicitado
                </MenuItem>
                <MenuItem
                  value={
                    "Se debe modifica la fecha probable de parto, según documentación"
                  }
                >
                  Se debe modifica la fecha probable de parto, según
                  documentación
                </MenuItem>
              </Select>
            )}
            {oneLicence.subtitle === "Embarazo" && (
              <Select
                value={motivoAnular}
                onChange={(e) => {
                  setMotivoAnular(e.target.value);
                }}
                name="motivo"
                label="Motivo *"
              >
                <MenuItem
                  value={
                    "Corresponde gestionar licencia médica familiar para este caso."
                  }
                >
                  Corresponde gestionar licencia médica familiar para este caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia médica para este caso."
                  }
                >
                  Corresponde gestionar licencia médica para este caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde gestionar licencia por alumbramiento para este caso."
                  }
                >
                  Corresponde gestionar licencia por alumbramiento para este
                  caso.
                </MenuItem>
                <MenuItem
                  value={
                    "Corresponde solicitar licencia por hospitalización para este caso"
                  }
                >
                  Corresponde solicitar licencia por hospitalización para este
                  caso
                </MenuItem>
                <MenuItem
                  value={"Corresponde solicitar Licencia Administrativa"}
                >
                  Corresponde solicitar Licencia Administrativa
                </MenuItem>
                <MenuItem
                  value={"Debe aportar epicrisis y/o parte quirúrgico."}
                >
                  Debe aportar epicrisis y/o parte quirúrgico.
                </MenuItem>
                <MenuItem value={"El certificado adjunto es ilegible."}>
                  El certificado adjunto es ilegible.
                </MenuItem>
                <MenuItem
                  value={
                    "El certificado no contiene membrete del profesional o institucional."
                  }
                >
                  El certificado no contiene membrete del profesional o
                  institucional.
                </MenuItem>
                <MenuItem
                  value={
                    "El certificado ya fue auditado, presente uno nuevo con fecha del día."
                  }
                >
                  El certificado ya fue auditado, presente uno nuevo con fecha
                  del día.
                </MenuItem>
                <MenuItem
                  value={
                    "La documentación respaldatoria no se corresponde con lo solicitado"
                  }
                >
                  La documentación respaldatoria no se corresponde con lo
                  solicitado
                </MenuItem>
                <MenuItem
                  value={
                    "No acompaña estudios complementarios que avalen condición médica."
                  }
                >
                  No acompaña estudios complementarios que avalen condición
                  médica.
                </MenuItem>
                <MenuItem
                  value={
                    "Se debe modificar la fecha probable de parto, según documentación"
                  }
                >
                  Se debe modificar la fecha probable de parto, según
                  documentación
                </MenuItem>
                <MenuItem
                  value={
                    "Se debe modificar periodo preparto, conforme orden médica"
                  }
                >
                  Se debe modificar periodo preparto, conforme orden médica
                </MenuItem>
              </Select>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              setMotivoAnular(null);
              setOpenAnular(false);
            }}
            size="small"
            variant="outlined"
          >
            No
          </Button>
          <Button
            disabled={!motivoAnular || observacionesAnular.length === 0}
            onClick={handleSubmitAnular}
            size="small"
            color="primary"
            variant="contained"
            autoFocus
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openLargoTratamiento}
        onClose={() => {
          setObservacionLT("");
          setOpenLargoTratamiento(false);
        }}
      >
        <DialogContent>
          <h1 className="text-[#5836ff] font-medium text-xl text-center">
            Estás por convertir la Licencia {oneLicence.id} a Largo tratamiento.
            ¿Deseás continuar?
          </h1>
          <div className="flex flex-col mt-3 gap-2 text-center">
            <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
              Observaciones
            </FormLabel>
            <TextField
              multiline
              fullWidth
              rows={2}
              value={observacionLT}
              placeholder="Máximo 2000 caracteres."
              onChange={(e) => {
                if (e.target.value.length > 2000) {
                  return;
                } else {
                  setObservacionLT(e.target.value);
                }
              }}
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              setObservacionLT("");
              setOpenLargoTratamiento(false);
            }}
            size="small"
            variant="outlined"
          >
            No
          </Button>
          <Button
            onClick={handleSubmitLargoTratamiento}
            size="small"
            color="primary"
            variant="contained"
            autoFocus
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Modal Ver Otra Información Auditoria */}
      <TableLicenseHistory openInfoAuditoria={openInfoAuditoria} setOpenInfoAuditoria={setOpenInfoAuditoria}/>
    

      <Dialog
        open={openDerivacion}
        onClose={() => {
          setOpenDerivacion(false);
        }}
      >
        <DialogContent>
          <h1 className="text-[#5836ff] font-medium text-xl text-center">
            Reasignar solicitud de licencia
          </h1>
          <div className="flex flex-col mt-3 gap-2">
            <FormControl size="small" sx={{ width: "100%", marginTop: "15px" }}>
              <InputLabel id="demo-simple-select-label">Asignar a</InputLabel>
              {oneLicence?.tipoLicencia === "LT" ? (
                <Select
                  value={optDerivacion.asignar}
                  onChange={(e) => {
                    setOptDerivacion({
                      ...optDerivacion,
                      asignar: e.target.value,
                      centroMedico: null,
                    });
                  }}
                  name="asignar"
                  label="Asignar a"
                >
                  <MenuItem value={null}>SELECCIONE UNA OPCIÓN.</MenuItem>
                  <MenuItem value={"CENTRO MÉDICO"}>CENTRO MÉDICO</MenuItem>
                </Select>
              ) : (
                <Select
                  value={optDerivacion.asignar}
                  onChange={(e) => {
                    setOptDerivacion({
                      ...optDerivacion,
                      asignar: e.target.value,
                      centroMedico: null,
                    });
                  }}
                  name="asignar"
                  label="Asignar a"
                >
                  <MenuItem value={null}>SELECCIONE UNA OPCIÓN.</MenuItem>
                  <MenuItem value={"CENTRO MÉDICO"}>CENTRO MÉDICO</MenuItem>
                  <MenuItem value={"MEDICINA LABORAL"}>
                    MEDICINA LABORAL
                  </MenuItem>
                  <MenuItem value={"VISITA A DOMICILIO"}>
                    VISITA A DOMICILIO
                  </MenuItem>
                </Select>
              )}
            </FormControl>
            {optDerivacion.asignar === "CENTRO MÉDICO" && (
              <FormControl
                size="small"
                sx={{ width: "100%", marginTop: "15px" }}
              >
                <InputLabel id="demo-simple-select-label">
                  Centro médico
                </InputLabel>
                {oneLicence?.tipoLicencia === "LT" ? (
                  <Select
                    value={optDerivacion.centroMedico}
                    onChange={(e) => {
                      setOptDerivacion({
                        ...optDerivacion,
                        centroMedico: e.target.value,
                      });
                    }}
                    name="centro-medico"
                    label="Centro médico"
                  >
                    <MenuItem value={null}>SELECCIONE UNA OPCIÓN.</MenuItem>
                    <MenuItem value={"20"}>
                      CEMET VIRTUAL CORTO TRATAMIENTO
                    </MenuItem>
                    <MenuItem value={"17"}>CEMET VIRTUAL SALUD MENTAL</MenuItem>
                    <MenuItem value={"0"}>DGAMT (SEDE CENTRAL)</MenuItem>
                    <MenuItem value={"30"}>SALUD MENTAL SEDE CENTRAL</MenuItem>
                  </Select>
                ) : (
                  <Select
                    value={optDerivacion.centroMedico}
                    onChange={(e) => {
                      setOptDerivacion({
                        ...optDerivacion,
                        centroMedico: e.target.value,
                      });
                    }}
                    name="centro-medico"
                    label="Centro médico"
                  >
                    <MenuItem value={null}>SELECCIONE UNA OPCIÓN.</MenuItem>
                    <MenuItem value={"20"}>
                      CEMET VIRTUAL CORTO TRATAMIENTO
                    </MenuItem>
                    <MenuItem value={"21"}>CEMET VIRTUAL COVID</MenuItem>
                    <MenuItem value={"17"}>CEMET VIRTUAL SALUD MENTAL</MenuItem>
                    <MenuItem value={"0"}>DGAMT (SEDE CENTRAL)</MenuItem>
                    <MenuItem value={"30"}>SALUD MENTAL SEDE CENTRAL</MenuItem>
                    <MenuItem value={"OTROS"}>OTROS</MenuItem>
                  </Select>
                )}
              </FormControl>
            )}
            {optDerivacion.centroMedico !== null &&
              optDerivacion.centroMedico !== "OTROS" && (
                <Box className="flex flex-col gap-2">
                  {oneLicence?.tipoLicencia !== "LT" && (
                    <>
                      <FormLabel
                        sx={{
                          color: "#5836ff",
                          fontWeight: "bold",
                          textAlign: "start",
                        }}
                      >
                        Fecha de citación
                      </FormLabel>
                      <LocalizationProvider
                        adapterLocale="es"
                        dateAdapter={AdapterDayjs}
                      >
                        <DatePicker
                          value={optDerivacion.fechaCitacion}
                          onChange={(e) =>
                            setOptDerivacion({
                              ...optDerivacion,
                              fechaCitacion: e,
                            })
                          }
                          renderInput={(props) => <TextField {...props} />}
                          slotProps={{
                            textField: { size: "small", error: false },
                          }}
                          disablePast
                        />
                      </LocalizationProvider>
                    </>
                  )}
                </Box>
              )}
            {optDerivacion.asignar !== null && (
              <>
                <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
                  Motivo
                </FormLabel>
                <textarea
                  value={optDerivacion.motivo}
                  onChange={(e) => {
                    try {
                      if (e.target.value.length > 250) {
                        return;
                      } else {
                        setOptDerivacion({
                          ...optDerivacion,
                          motivo: e.target.value,
                        });
                      }
                    } catch (error) {
                      console.error(
                        "Error al manejar cambios en el campo de observaciones:",
                        error
                      );
                    }
                  }}
                  className="border rounded-md p-2"
                  placeholder="Máximo 250 caracteres."
                />
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              setOpenDerivacion(false);
            }}
            size="small"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSubmitDerivar}
            size="small"
            color="primary"
            disabled={disabledDerivar()}
            variant="contained"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Detail;
