export const LicTypesEnum = {
  ADMINISTRATIVA : 1,
  MEDICA : 2,
  MEDICA_FAMILIAR : 3,
  MEDICA_HOSPITALIZACION : 4,
  EMBARAZO : 5,
  CONTINGENCIA_ART : 6,
  ALUMBRAMIENTO : 7,
  LARGO_TRATAMIENTO : 8
}

export const LicTypesArray = [
  { label: "Administrativa", value: 1 , code:"ADM"},
  { label: "Médica", value: 2 , code:"MED"},
  { label: "Médica Familiar", value: 3 , code: "MEF"},
  { label: "Médica por Hospitalización", value: 4 , code: "MEH"},
  { label: "Embarazo", value: 5 , code: "EyA"},
  { label: "Contingencia ART", value: 6 , code:"ART"},
  { label: "Alumbramiento", value: 7 ,code:"EyA"},
  { label: "Largo Tratamiento", value: 8 ,code:"LT"}
];