import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    confirmModal : {
        open : false,
        title : '',
        content : '',
        accepted : false,
        showCancel : true,
        domicilio : false
    },
    changeProfileModal :  {
        open : false
    },
    profileModal : {
        open : false
    },
    familyModal:{
        open:false
    },
    modalDetailsOpen:{
        open:false
    }
}
export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        handleConfirmModal: (state,action) => {
            state.confirmModal = action.payload;
        },
        handleProfileModal: (state,action) => {
            state.profileModal = action.payload;
        },
        handleFamilyModal: (state,action) => {
            state.familyModal = action.payload;
        },
        handlechangeProfileModal: (state,action) => {
            state.changeProfileModal = action.payload;
        },
        handleModalDetailsOpen: (state, action) => {
            state.modalDetailsOpen = action.payload;
          },
    },
  });
  
  export const selectModals = (state) => state.modal;
  export default modalSlice.reducer;
  export const {handleConfirmModal,handleModalDetailsOpen,handleProfileModal,handleFamilyModal,handlechangeProfileModal } = modalSlice.actions;