/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDiagnosticosAsync,
  selectLicenses,
} from "../../license/licenseSlice";
import { LicStatusEnum } from "../../../interfaces/LicStatusEnum";

function Filters({
  filters,
  setFilters,
  activeButton,
  autocompleteRef,
  pendientes,
}) {
  const { data } = useSelector(selectLicenses);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.diagnosticos) {
      dispatch(fetchDiagnosticosAsync(""));
    }
  }, []);

  const getLicense = (value) => {
    if (value.length > 2) dispatch(fetchDiagnosticosAsync(value));
  };
  const handleChangeAutoComplete = (value) => {
    setFilters({ ...filters, diagnostico: value });
  };
  const handleChange = (event) => {
    switch (event.target.name) {
      case "id":
        setFilters({ ...filters, id: event.target.value });
        break;
      case "idHr":
        setFilters({ ...filters, idHr: event.target.value });
        break;
      case "tipoLicencia":
        setFilters({ ...filters, tipoLicencia: event.target.value });
        break;
      // case "diagnostico":
      //   setFilters({ ...filters, diagnostico: event.target.value });
      //   break;
      case "apellido":
        setFilters({ ...filters, apellido: event.target.value });
        break;
      case "cuil":
        const { name, value } = event.target;
        const isValidInput = /^[0-9-]*$/.test(value);
        if (isValidInput) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
          }));
        }
        break;
      case "estado":
        setFilters({ ...filters, estado: event.target.value });
        break;
      case "tomada":
        setFilters({ ...filters, tomada: event.target.checked });
        break;
      default:
        break;
    }
  };

  const handleChangeDates = (action, event) => {
    switch (action) {
      case "nuevaCitacionDesde":
        setFilters({ ...filters, nuevaCitacionDesde: event });
        break;
      case "nuevaCitacionHasta":
        setFilters({ ...filters, nuevaCitacionHasta: event });
        break;
      case "ausentismo":
        setFilters({ ...filters, ausentismo: event });
        break;
      default:
        break;
    }
  };

  return (
    <Box className="p-3 d-flex gap-4 justify-content-start flex-wrap">
      <TextField
        name="id"
        value={filters.id}
        sx={{ width: "165px" }}
        size="small"
        id="outlined-basic"
        label="LICENCIA ID"
        variant="outlined"
        type="number"
        onChange={handleChange}
      />
      <TextField
        name="idHr"
        type="number"
        value={filters.idHr}
        sx={{ width: "165px" }}
        size="small"
        id="outlined-basic"
        label="ID HR"
        variant="outlined"
        onChange={handleChange}
      />
      {activeButton === 1 && (
        <FormControl size="small" sx={{ width: "165px" }}>
          <InputLabel id="demo-simple-select-label">Tipo Licencia</InputLabel>
          <Select
            name="tipoLicencia"
            value={filters.tipoLicencia}
            label="Tipo Licencia"
            onChange={handleChange}
          >
            <MenuItem value={""}>Quitar Filtro</MenuItem>
            <MenuItem value={"embarazo"}>Embarazo</MenuItem>
            <MenuItem value={"alumbramiento"}>Alumbramiento</MenuItem>
          </Select>
        </FormControl>
      )}
      {(activeButton === 0 || activeButton === 2 || activeButton === 3) && (
        <>
          <Autocomplete
            ref={autocompleteRef}
            freeSolo
            size="small"
            name="diagnostico"
            sx={{ width: "200px" }}
            options={data.diagnosticos}
            getOptionLabel={(option) => option.description ?? ""}
            defaultValue={filters.diagnostico}
            onChange={(e, value) => {
              handleChangeAutoComplete(value?.description ?? "");
            }}
            renderInput={(params) => {
              return (
                <TextField
                  placeholder="Diagnostico"
                  {...params}
                  onChange={(e) => {
                    getLicense(e.target?.value);
                    setFilters({
                      ...filters,
                      diagnostico: e.target?.value.toUpperCase(),
                    });
                  }}
                  InputLabelProps={{ shrink: false }}
                />
              );
            }}
          />
        </>
      )}
      <TextField
        name="apellido"
        value={filters.apellido}
        sx={{ width: "165px" }}
        size="small"
        id="outlined-basic"
        label="Apellido"
        variant="outlined"
        onChange={handleChange}
      />
      <TextField
        name="cuil"
        value={filters.cuil}
        sx={{ width: "165px" }}
        size="small"
        id="outlined-basic"
        label="Cuil"
        variant="outlined"
        onChange={handleChange}
      />
      {activeButton === 3 ? (
        <FormControl size="small" sx={{ width: "165px" }}>
          <InputLabel id="demo-simple-select-label">Estado</InputLabel>
          <Select
            name="estado"
            value={filters.estado}
            label="Estado"
            onChange={handleChange}
          >
            <MenuItem value={""}>Quitar Filtro</MenuItem>
            {pendientes && (
              <MenuItem
                value={LicStatusEnum.PENDIENTE_LARGO_TRATAMIENTO.toString()}
              >
                Pendiente - Largo Tratamiento
              </MenuItem>
            )}
            {pendientes && (
              <MenuItem
                value={LicStatusEnum.AUTORIZADA_LARGO_TRATAMIENTO.toString()}
              >
                Autorizada - Largo Tratamiento
              </MenuItem>
            )}
            {!pendientes && (
              <MenuItem value={LicStatusEnum.ALTA_LARGO_TRATAMIENTO.toString()}>
                Alta - Largo Tratamiento
              </MenuItem>
            )}
          </Select>
        </FormControl>
      ) : pendientes ? null : (
        <FormControl size="small" sx={{ width: "165px" }}>
          <InputLabel id="demo-simple-select-label">Estado</InputLabel>
          <Select
            name="estado"
            value={filters.estado}
            label="Estado"
            onChange={handleChange}
          >
            <MenuItem value={""}>Quitar Filtro</MenuItem>
            <MenuItem value={LicStatusEnum.ANULADA.toString()}>
              Anulada
            </MenuItem>
            <MenuItem value={LicStatusEnum.AUTORIZADA.toString()}>
              Autorizada
            </MenuItem>
            {/* <MenuItem value={LicStatusEnum.INGRESADA.toString()}>Ingresada</MenuItem> */}
            <MenuItem value={LicStatusEnum.RECHAZADA.toString()}>
              Rechazada
            </MenuItem>
          </Select>
        </FormControl>
      )}

      <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
        {(activeButton === 0 ||
          activeButton === 2 ||
          activeButton === 3 ||
          activeButton === 4) && (
          <DatePicker
            onChange={(e) => handleChangeDates("ausentismo", e)}
            sx={{ width: "185px" }}
            slotProps={{ textField: { size: "small" } }}
            className="datepicker"
            value={filters.ausentismo}
            label="Fecha Ausentismo"
          />
        )}
        {activeButton === 3 && (
          <>
            <DatePicker
              onChange={(e) => handleChangeDates("nuevaCitacionDesde", e)}
              sx={{ width: "220px" }}
              slotProps={{ textField: { size: "small" } }}
              className="datepicker"
              value={filters.nuevaCitacionDesde}
              label="Nueva Citación Desde"
            />
            <DatePicker
              onChange={(e) => handleChangeDates("nuevaCitacionHasta", e)}
              sx={{ width: "220px" }}
              slotProps={{ textField: { size: "small" } }}
              className="datepicker"
              label="Nueva Citación Hasta"
              value={filters.nuevaCitacionHasta}
            />
          </>
        )}
      </LocalizationProvider>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="tomada"
              onChange={handleChange}
              checked={filters.tomada}
            />
          }
          label="Tomada"
        />
      </FormGroup>
    </Box>
  );
}

export default Filters;
