/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import Menu from "../../audit/audit-menu";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllAuditProLicenciasAllHistoryAsync,
  fetchOneAuditProLicenciaAsync,
} from "../occupationalMedicineSlice";
import FiltersHistory from "./filters";
import { verificarObjetos } from "../../../utils";
import HistoryTable from "./table";

const initialFilters = {
  fechaInicio: null,
  fechaFin: null,
};
function AllHistory() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { oneLicence } = useSelector((state) => state.auditProLicence);
  const [filters, setFilters] = React.useState(initialFilters);
  const [activeButton, setActiveButton] = React.useState(0); 
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const licenseTypeId = queryParams.get("licenseTypeId");
  const handleBuscar = () => {
    const filterArray = [];
    for (const key in filters) {
      const value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        filterArray.push({ code: key, description: value });
      }
    }
    //{code:"status",description:pendientes}
    dispatch(
      fetchAllAuditProLicenciasAllHistoryAsync({
        id: oneLicence.idhr,
        filters: filterArray,
        pageNumber: 0,
        pageSize:rowsPerPage
      })
    ).then(x=>{
      setPage(0)
    });
  };

  const handleReset = () => {
    setFilters(initialFilters);
    dispatch(
      fetchAllAuditProLicenciasAllHistoryAsync({
        id: oneLicence.idhr,
        filters: [],
        pageNumber:page,
        pageSize:rowsPerPage
      })
    ).then(x=>{
      setPage(0)
    });
  };

  useEffect(() => {
    dispatch(fetchOneAuditProLicenciaAsync({id,licenseTypeId})).then((res) => {
      dispatch(
        fetchAllAuditProLicenciasAllHistoryAsync({
          id: res.payload.idhr,
          filters: [],
          pageNumber:0,
          pageSize:rowsPerPage
        })
      );
    });
  }, []);

  const [page, setPage] = React.useState(0);
  return (
    <div>
      <Menu
        title={"Otras licencias"}
        subtitle={oneLicence.firstName + " " + oneLicence.lastName}
        idHr={oneLicence.idhr}
        cuil={oneLicence.cuit}
      />
      <section className="px-4 py-2">
        <FiltersHistory
          id={id}
          filters={filters}
          setFilters={setFilters}
          activeButton={activeButton}
          handleBuscar={handleBuscar}
          disabled={verificarObjetos(filters, initialFilters)}
          handleReset={handleReset}
        />
        <HistoryTable idhr={oneLicence.idhr} filters={filters}           page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}/>
      </section>
    </div>
  );
}

export default AllHistory;
