import api, { APPCONFIG } from "../../api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allLicences: [],
  oneLicence: {},
  history: [],
  totalCount: 0,
  allHistoryLicences: [],
  allAuditHistoryLicences: [],
};

export const fetchAllAuditLicenciasAsync = createAsyncThunk(
  "auditLicense/getAllAuditLicenciasAsync",
  async (data) => {
    let sort = data.ord === 1 ? "desc" : "asc";
    const url = `${APPCONFIG.APIs.LicenseAPI}/AuditorART/licences?id=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
    const response = await api.post(url, {
      filter: data.filters,
      sort: { sort },
    });
    return response.data;
  }
);

export const fetchAllAuditLicenciasHistoryAsync = createAsyncThunk(
  "auditLicense/getAllAuditLicenciasHistoryAsync",
  async (data) => {
    let sort = data.ord === 1 ? "desc" : "asc";
    const url = `${APPCONFIG.APIs.LicenseAPI}/Auditor/licencesHistory?id=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
    const response = await api.post(url, {
      filter: data.filters,
      sort: { sort },
    });
    return response.data;
  }
);

export const fetchAllAuditLicenciasAllHistoryAsync = createAsyncThunk(
  "auditLicense/fetchAllAuditLicenciasAllHistoryAsync",
  async (data) => {
    let sort = data.ord === 1 ? "desc" : "asc";
    const url = `${APPCONFIG.APIs.LicenseAPI}/Auditor/allLicencesHistory?id=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
    const response = await api.post(url, {
      filter: data.filters,
      sort: { sort },
    });
    return response.data;
  }
);

export const putObservarFile = createAsyncThunk(
  "auditProLicence/putObservarFile",
  async (fileId) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Files/Observada?fileId=${fileId}`;
    const response = await api.put(url);
    return response.data;
  }
);

export const fetchOneArtLicenciaAsync = createAsyncThunk(
  "auditLicense/fetchOneArtLicenciaAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License?licenseId=${data.id}&licenseTypeId=${data.licenseTypeId}&includeFiles=true`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchTomarLicencia = createAsyncThunk(
  "auditLicense/fetchTomarLicencia",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Auditor/TomarLicencia?userId=${data.userId}&licenseId=${data.licenceId}`;
    const response = await api.post(url);
    return response.data;
  }
);

export const fetchLiberarLicencia = createAsyncThunk(
  "auditLicense/fetchLiberarLicencia",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Auditor/LiberarLicencia?userId=${data.userId}&licenseId=${data.licenceId}`;
    const response = await api.post(url);
    return response.data;
  }
);

export const fetchHistoryLicenseById = createAsyncThunk(
  "auditLicense/fetchHistoryLicenseById",
  async (licenceId) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/History?licenseId=${licenceId}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const postExecuteAction = createAsyncThunk(
  "auditLicense/ExecuteAction",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Auditor/Acciones?action=${data.action}&userId=${data.userId}&licenseId=${data.licenseId}`;
    const response = await api.post(url, data.data);
    return response.data;
  }
);

export const postNoAprobarLiceniaART = createAsyncThunk(
  "auditLicense/postNoAprobarLiceniaART",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Auditor/NoAprobar`;
    const response = await api.post(url, data);
    return response.data;
  }
);

export const postEditarLicenciaART = createAsyncThunk(
  "auditLicense/postEditarLicenciaART",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Auditor/EditarLicencia`;
    const response = await api.post(url, data);
    return response.data;
  }
);

export const admLicenceSlice = createSlice({
  name: "auditLicence",
  initialState,
  reducers: {
    removeHistory: (state) => {
      state.history = [];
    },
    removeOneLicense: (state) => {
      state.oneLicence = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAuditLicenciasAsync.fulfilled, (state, action) => {
        state.allLicences = action.payload.licenses;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(
        fetchAllAuditLicenciasHistoryAsync.fulfilled,
        (state, action) => {
          state.allHistoryLicences = action.payload.licenses;
          state.totalCount = action.payload.totalCount;
        }
      )
      .addCase(
        fetchAllAuditLicenciasAllHistoryAsync.fulfilled,
        (state, action) => {
          state.allAuditHistoryLicences = action.payload.licenses;
          state.totalCount = action.payload.totalCount;
        }
      )
      .addCase(fetchOneArtLicenciaAsync.fulfilled, (state, action) => {
        state.oneLicence = action.payload;
      })
      .addCase(fetchHistoryLicenseById.fulfilled, (state, action) => {
        state.history = action.payload;
      });
  },
});

export const { removeHistory, removeOneLicense } = admLicenceSlice.actions;

export default admLicenceSlice.reducer;
