/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import Menu from "../../audit/audit-menu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllAuditProLicenciasHistoryAsync,
  fetchOneAuditProLicenciaAsync,
} from "../occupationalMedicineSlice";
import FiltersHistory from "./filters";
import { Box, Button } from "@mui/material";
import { verificarObjetos } from "../../../utils";
import HistoryTable from "./table";
import { fetchAllTypeLicenciasAsync } from "../../license/licenseSlice";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const initialFilters = {
  tipoLicencia: "",
  fechaInicio: null,
  fechaFin: null,
};
function History() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { oneLicence } = useSelector((state) => state.auditProLicence);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [filters, setFilters] = React.useState(initialFilters);
  const [activeButton, setActiveButton] = React.useState(0);
  const handleBuscar = () => {
    const filterArray = [];
    for (const key in filters) {
      const value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        filterArray.push({ code: key, description: value });
      }
    }
    //{code:"status",description:pendientes}
    dispatch(
      fetchAllAuditProLicenciasHistoryAsync({
        id: oneLicence.idhr,
        filters: filterArray,
        pageNumber: 0,
        pageSize: rowsPerPage,
      })
    ).then((x) => {
      setPage(0);
    });
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const licenseTypeId = queryParams.get("licenseTypeId");
  const pageAct = queryParams.get("pageAct");
  const activeBtn = queryParams.get("activeBtn");
  const [page, setPage] = React.useState(0);
  const handleReset = () => {
    setFilters(initialFilters);
    dispatch(
      fetchAllAuditProLicenciasHistoryAsync({
        id: oneLicence.idhr,
        filters: [],
        pageNumber: 0,
        pageSize: rowsPerPage,
      })
    ).then((x) => {
      setPage(0);
    });
  };

  useEffect(() => {
    dispatch(fetchOneAuditProLicenciaAsync({ id, licenseTypeId })).then(
      (res) => {
        dispatch(
          fetchAllAuditProLicenciasHistoryAsync({
            id: res.payload.idhr,
            filters: [],
            pageNumber: 0,
            pageSize: 5,
          })
        );
      }
    );
  }, []);

  React.useEffect(() => {
    dispatch(fetchAllTypeLicenciasAsync());
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <Menu
        title={"Historial de licencia"}
        subtitle={oneLicence.firstName + " " + oneLicence.lastName}
        idHr={oneLicence.idhr}
        cuil={oneLicence.cuit}
      />
      <section className="px-4 py-2">
        <FiltersHistory
          filters={filters}
          setFilters={setFilters}
          activeButton={activeButton}
          handleBuscar={handleBuscar}
          disabled={verificarObjetos(filters, initialFilters)}
          handleReset={handleReset}
        />
        <div className="px-3 flex flex-col sm:flex-row gap-2">
          <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
            onClick={() =>
              navigate(
                `/occupational-medicine/${oneLicence.id}?pageAct=${pageAct}&activeBtn=${activeBtn}`
              )
            }
          >
            Volver
          </Button>
          <Button
            variant="outlined"
            endIcon={<ArrowForwardIosIcon />}
            onClick={() =>
              navigate(
                `/occupational-medicine/allhistory/${oneLicence.id}?pageAct=${pageAct}&activeBtn=${activeBtn}&licenseTypeId=${licenseTypeId}`
              )
            }
          >
            Otras licencias
          </Button>
        </div>
        <HistoryTable
          activeBtn={activeBtn}
          idhr={oneLicence.idhr}
          filters={filters}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
      </section>
    </div>
  );
}

export default History;
