import { createSlice } from "@reduxjs/toolkit";
import { loginAsync } from "../user/userSlice";
import { fetchAllAdmLicenciasAsync , fetchOneAdmLicenciaAsync , fetchAllAdmLicenciasAllHistoryAsync } from "../administrative/administrativeSlice";
import { fetchAllAdmSupLicenciasAsync } from "../administrative-superior/administrativeSuperiorSlice";

import { fetchDatosLicencia, fetchLicenciasAsync, fetchOneLicenciaAsync, fetchRegimenesAsync, fetchUnidadesOrganizativasAsync, getSolapamientoAusenteAsync, postCertificado, saveLicenseAsync, saveLicenseAusenteAsync , anularLicenseAsync , anularAusenteAsync , fetchAutorizadasPor , updateLicenceStatusAsync} from "../license/licenseSlice";
import { fetchAuditProLicenciasAsync  , postExecuteAction , fetchOneAuditProLicenciaAsync , fetchAllAuditProLicenciasHistoryAsync , fetchAllAuditProLicenciasAllHistoryAsync} from "../occupational-medicine/occupationalMedicineSlice";
import { fetchAllAuditLicenciasAsync  , fetchOneArtLicenciaAsync , fetchHistoryLicenseById, fetchAllAuditLicenciasAllHistoryAsync , fetchAllAuditLicenciasHistoryAsync , postExecuteAction as postExecuteActionAuditArt } from "../audit/auditSlice";
import { fetchAllPublicacionesAsync  , fetchPublicacionesRRHH} from "../foco/focoSlice";
import { exportExcelAsync } from "../files/filesSlice";
const initialState = false;
export const spinnerSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        toggleLoading : (state) => !state
    },
    extraReducers:{
        [updateLicenceStatusAsync.pending] : () => true,
        [updateLicenceStatusAsync.fulfilled] : () => false,
        [updateLicenceStatusAsync.rejected] : () => false,
        [fetchPublicacionesRRHH.pending] : () => true,
        [fetchPublicacionesRRHH.fulfilled] : () => false,
        [fetchPublicacionesRRHH.rejected] : () => false,
        [exportExcelAsync.pending] : () => true,
        [exportExcelAsync.fulfilled] : () => false,
        [exportExcelAsync.rejected] : () => false,
        [postExecuteActionAuditArt.pending] : () => true,
        [postExecuteActionAuditArt.fulfilled] : () => false,
        [postExecuteActionAuditArt.rejected] : () => false,
        [fetchOneAdmLicenciaAsync.pending] : () => true,
        [fetchOneAdmLicenciaAsync.fulfilled] : () => false,
        [fetchOneAdmLicenciaAsync.rejected] : () => false,
        [fetchAllAdmLicenciasAllHistoryAsync.pending] : () => true,
        [fetchAllAdmLicenciasAllHistoryAsync.fulfilled] : () => false,
        [fetchAllAdmLicenciasAllHistoryAsync.rejected] : () => false,
        [fetchOneAuditProLicenciaAsync.pending] : () => true,
        [fetchOneAuditProLicenciaAsync.fulfilled] : () => false,
        [fetchOneAuditProLicenciaAsync.rejected] : () => false,
        [fetchAllAuditProLicenciasHistoryAsync.pending] : () => true,
        [fetchAllAuditProLicenciasHistoryAsync.fulfilled] : () => false,
        [fetchAllAuditProLicenciasHistoryAsync.rejected] : () => false,
        [fetchAllAuditProLicenciasAllHistoryAsync.pending] : () => true,
        [fetchAllAuditProLicenciasAllHistoryAsync.fulfilled] : () => false,
        [fetchAllAuditProLicenciasAllHistoryAsync.rejected] : () => false,
        [fetchHistoryLicenseById.pending] : () => true,
        [fetchHistoryLicenseById.fulfilled] : () => false,
        [fetchHistoryLicenseById.rejected] : () => false,
        [fetchAllAuditLicenciasAllHistoryAsync.pending] : () => true,
        [fetchAllAuditLicenciasAllHistoryAsync.fulfilled] : () => false,
        [fetchAllAuditLicenciasAllHistoryAsync.rejected] : () => false,
        [fetchAllAuditLicenciasHistoryAsync.pending] : () => true,
        [fetchAllAuditLicenciasHistoryAsync.fulfilled] : () => false,
        [fetchAllAuditLicenciasHistoryAsync.rejected] : () => false,
        [fetchAllPublicacionesAsync.pending] : () => true,
        [fetchAllPublicacionesAsync.fulfilled] : () => false,
        [fetchAllPublicacionesAsync.rejected] : () => false,
        [fetchOneArtLicenciaAsync.pending] : () => true,
        [fetchOneArtLicenciaAsync.fulfilled] : () => false,
        [fetchOneArtLicenciaAsync.rejected] : () => false,
        [anularLicenseAsync.pending] : () => true,
        [anularLicenseAsync.fulfilled] : () => false,
        [anularLicenseAsync.rejected] : () => false,
        [postExecuteAction.pending] : () => true,
        [postExecuteAction.fulfilled] : () => false,
        [postExecuteAction.rejected] : () => false,
        [fetchAutorizadasPor.pending] : () => true,
        [fetchAutorizadasPor.fulfilled] : () => false,
        [fetchAutorizadasPor.rejected] : () => false,
        [anularAusenteAsync.pending] : () => true,
        [anularAusenteAsync.fulfilled] : () => false,
        [anularAusenteAsync.rejected] : () => false,
        [fetchAuditProLicenciasAsync.pending] : () => true,
        [fetchAuditProLicenciasAsync.fulfilled] : () => false,
        [fetchAuditProLicenciasAsync.rejected] : () => false,
        [loginAsync.pending] : () => true,
        [loginAsync.fulfilled] : () => false,
        [loginAsync.rejected] : () => false,
        [fetchAllAdmLicenciasAsync.pending] : () => true,
        [fetchAllAdmLicenciasAsync.fulfilled] : () => false,
        [fetchAllAdmLicenciasAsync.rejected] : () => false,
        [fetchAllAdmSupLicenciasAsync.pending] : () => true,
        [fetchAllAdmSupLicenciasAsync.fulfilled] : () => false,
        [fetchAllAdmSupLicenciasAsync.rejected] : () => false,
        [fetchRegimenesAsync.pending] : () => true,
        [fetchRegimenesAsync.fulfilled] : () => false,
        [fetchRegimenesAsync.rejected] : () => false,
        [fetchUnidadesOrganizativasAsync.pending] : () => true,
        [fetchUnidadesOrganizativasAsync.fulfilled] : () => false,
        [fetchUnidadesOrganizativasAsync.rejected] : () => false,
        [fetchDatosLicencia.pending] : () => true,
        [fetchDatosLicencia.fulfilled] : () => false,
        [fetchDatosLicencia.rejected] : () => false,
        [fetchLicenciasAsync.pending] : () => true,
        [fetchLicenciasAsync.fulfilled] : () => false,
        [fetchLicenciasAsync.rejected] : () => false,
        [saveLicenseAusenteAsync.pending] : () => true,
        [saveLicenseAusenteAsync.fulfilled] : () => false,
        [saveLicenseAusenteAsync.rejected] : () => false,
        [saveLicenseAsync.pending] : () => true,
        [saveLicenseAsync.fulfilled] : () => false,
        [saveLicenseAsync.rejected] : () => false,
        [getSolapamientoAusenteAsync.pending] : () => true,
        [getSolapamientoAusenteAsync.fulfilled] : () => false,
        [getSolapamientoAusenteAsync.rejected] : () => false,
        [fetchOneLicenciaAsync.pending] : () => true,
        [fetchOneLicenciaAsync.fulfilled] : () => false,
        [fetchOneLicenciaAsync.rejected] : () => false,
        [postCertificado.pending] : () => true,
        [postCertificado.fulfilled] : () => false,
        [postCertificado.rejected] : () => false,
        [fetchAllAuditLicenciasAsync.pending] : () => true,
        [fetchAllAuditLicenciasAsync.fulfilled] : () => false,
        [fetchAllAuditLicenciasAsync.rejected] : () => false,
    }
  });
  
export const {toggleLoading} = spinnerSlice.actions;
export default spinnerSlice.reducer;