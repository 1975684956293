import moment from "moment";
import api, { APPCONFIG } from "../../api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    stepComponents: ["Step1", "Step2"],
    stepFields: [["regimen", "tipoLicencia"], ["licencia"]],
    regimenes: [],
    tipoLicencias: [],
    licencias: [],
    diagnosticos: [],
    unidadesOrganizativas: [],
  },
  form: {
    regimen: "",
    tipoLicencia: "",
    licencia: {
      code: undefined,
      description: undefined,
    },
    unidadesOrganizativas: [],
    observaciones: "",
    diasNeonatologia: "1",
    cantidadHijos: "1",
    fechaInicio: undefined,
    fechaCertificado: undefined,
    avisoASuperior: false,
    confirmacion: false,
    diagnostico: {},
    certificado: [],
    primerInforme: [],
    ultimoInforme: [],
    horaSiniestro: undefined,
    jornadaLaboralDesde: undefined,
    jornadaLaboralHasta: undefined,
    otrosDocumentos: [],
    nroSiniestro: undefined,
    fechaDenuncia: moment(),
    familiares: [
      {
        nombre: "",
        apellido: "",
        tipoDocumento: "",
        documento: "",
        parentesco: "",
      },
    ],
  },
  completarAusencia: {},
  allLicences: [],
  allTypeLicences: [],
  oneLicence: {},
  title: "",
  subtitle: "",
  startDate: "",
  endDate: "",
  status: "",
  asignment: "",
  parameters: {},
};

export const fetchRegimenesAsync = createAsyncThunk(
  "license/getRegimenesAsync",
  async (idHr) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/regimenes?idHr=${idHr}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchDatosLicencia = createAsyncThunk(
  "license/fetchDatosLicencia",
  async (codLicencia) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/DatosLicencia?codLicencia=${codLicencia}`;
    const response = await api.get(url);
    return response.data;
  }
);
export const fetchAutorizadasPor = createAsyncThunk(
  "license/fetchAutorizadasPor",
  async (idhr) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/AutorizadasPor?id=${idhr}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchAllLicenciasAsync = createAsyncThunk(
  "license/getAllLicenciasAsync",
  async (data) => {
    let sort = data.ord === 1 ? "desc" : "asc";
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/all?id=${data.id}`;
    const response = await api.post(url, {
      filter:
        data?.filters?.length > 0
          ? data.filters
          : [{ id: "00", code: "ABI", description: "Licencias Abiertas" }],
      sort: { sort },
    });
    return response.data;
  }
);

export const fetchAllTypeLicenciasAsync = createAsyncThunk(
  "license/getAllTypeLicenciasAsync",
  async () => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/TiposLicencia/all`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchOneLicenciaAsync = createAsyncThunk(
  "license/getOneLicenciaAsync",
  async (licenceId) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License?licenseId=${licenceId}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchDiagnosticosAsync = createAsyncThunk(
  "license/getDiagnosticos",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/Diagnosticos?diagnostico=${data}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchCentrosMedicos = createAsyncThunk(
  "license/fetchCentrosMedicos",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/CentrosMedicos?centro=${data}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchDatosMedicos = createAsyncThunk(
  "license/fetchDatosMedicos",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/DatosMedicos?matricula=${data}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const postDatosMedicos = createAsyncThunk(
  "license/SaveMedicoAsync",
 
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Medicos/SaveMedico`;
    try {
    const response = await api.post(url,data);
    return response.data;
  } catch (error) {
    console.error('Error during API call:', error);
    throw error;
  }
});

export const postCentrosMedicos = createAsyncThunk(
  "license/postCentrosMedicos",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/CentrosMedicos`;
    const response = await api.post(url,data);
    return response.data;
  }
);

export const fetchSaldoDisponible = createAsyncThunk(
  "license/getSaldoDisponible",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/SaldoDisponible?idhr=${data.idhr}&codRegimen=${data.codRegimen}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchSteps = createAsyncThunk(
  "license/fetchSteps",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/Steps?idGrupoLicencias=${data.idGrupoLicencias}&licenseCode=${data.licenseCode}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchLicenciasAsync = createAsyncThunk(
  "license/getLicenciasAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/TiposLicencia?idHR=${data.idHr}&codRegimen=${data.codRegimen}&idGrupoLicencias=${data.idGrupoLicencias}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchUnidadesOrganizativasAsync = createAsyncThunk(
  "license/getUnidadesOrganizativasAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/UnidadesOrganizativas?idHr=${data.idHr}&codRegimen=${data.codRegimen}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchTopesSaldosAsync = createAsyncThunk(
  "license/getTopesSaldosAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/TopesSaldos?idHr=${data.idHr}&codRegimen=${data.codRegimen}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const postCertificado = createAsyncThunk(
  "license/saveCertificado",
  async (data, { rejectWithValue }) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Files/`;
    try {
      const key = data.get("key");
      const response = await api.post(url, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return { files: response.data, key };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postCertificadoMobile = createAsyncThunk(
  "license/postCertificadoMobile",
  async (data, { rejectWithValue }) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Files/Mobile`;
    try {
      const response = await api.post(url, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDocumentAsync = createAsyncThunk(
  "license/deleteDocumentAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Files?fileId=${data}`;
    const response = await api.delete(url);
    return response.data;
  }
);

export const fetchDocumentsAsync = createAsyncThunk(
  "license/fetchDocumentsAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Files/Documents?licenseId=${data}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const saveLicenseAsync = createAsyncThunk(
  "license/saveLicenseAsync",
  async (data, { rejectWithValue }) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License?userId=${data.user.datos.id}`;
    try {
      const response = await api.post(url, {
        ...data.license,
        usuario: data.user.datos,
        perfil: data.user.perfilActual,
        direccion: data.direccion,
        familiar: data.familiar,
        formDataCertificado: data.formDataCertificado
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveLicenseAsync2 = createAsyncThunk(
  "license/saveLicenseAsync2",
 
  async (data, { rejectWithValue }) => {
    console.log(data)
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/licenciasV2`;
    try {
      const response = await api.post(url, data,{
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const UpdateMedicoAsync =createAsyncThunk(
  "Medicos/UpdateMedicoAsync" ,
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Medicos/UpdateMedico`;
    try {
      const response = await api.put(url, data )
      return response.data;
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
  }
  }
);

export const updateLicenceStatusAsync = createAsyncThunk(
  "licence/updateLicenceStatusAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Administrative/UpdateStatus?autorizanteId=${data.userId}`;
    try {
      const response = await api.put(url, data.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateLicenseAutomaticAsync = createAsyncThunk(
  "licence/updateLicenseAutomaticAsync",
  async (licenseId) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Administrative/UpdateStatusAutomatic?licenseId=${licenseId}`;
    try {
      const response = await api.put(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateLicenceStatusAuSupAsync = createAsyncThunk(
  "licence/updateLicenceStatusAuSupAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Administrative/UpdateStatusAuSup?autorizanteId=${data.userId}`;
    try {
      const response = await api.put(url, data.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getSolapamientoAusenteAsync = createAsyncThunk(
  "licence/getSolapamientoAusenteAsync",
  async (data) => {
    let url = `${APPCONFIG.APIs.LicenseAPI}/License/LicenciasSolapadas?idHr=${data.idhr}`;
    if (data.fechaDesde) {
      url = url + `&fechaDesde=${data.fechaDesde}`;
    }
    if (data.fechaHasta) {
      url = url + `&fechaHasta=${data.fechaHasta}`;
    }
    try {
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const saveLicenseAusenteAsync = createAsyncThunk(
  "license/saveLicenseAusenteAsync",
  async (data, { rejectWithValue }) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/Medicas/ausencia`;
    try {
      const response = await api.post(url, {
        ...data.license,
        usuario: data.user.datos,
        perfil: data.user.perfilActual,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const anularAusenteAsync = createAsyncThunk(
  "license/anularAusenteAsync",
  async (data, { rejectWithValue }) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/anularAusencia`;
    try {
      const response = await api.post(url, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const anularLicenseAsync = createAsyncThunk(
  "license/anularLicenseAsync",
  async (data, { rejectWithValue }) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/anular`;
    try {
      const response = await api.post(url, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const licenseSlice = createSlice({
  name: "licencia",
  initialState,
  reducers: {
    ResetState: () => initialState,
    removeCertificado: (state, action) => {
      state.form.certificado = state.form.certificado.filter(
        (x) => x.id !== action.payload.id
      );
    },
    completarAusencia: (state, action) => {
      state.completarAusencia = action.payload;
    },
    removeOtrosDocumentos: (state, action) => {
      state.form.otrosDocumentos = state.form.otrosDocumentos.filter(
        (x) => x.id !== action.payload.id
      );
    },
    removePrimerInforme: (state, action) => {
      state.form.primerInforme = state.form.primerInforme.filter(
        (x) => x.id !== action.payload.id
      );
    },
    removeUltimoInforme: (state, action) => {
      state.form.ultimoInforme = state.form.ultimoInforme.filter(
        (x) => x.id !== action.payload.id
      );
    },
    setOtrosDocumentos: (state, action) => {
      state.form.otrosDocumentos = action.payload;
    },
    updateForm: (state, action) => {
      const data = {
        ...action.payload,
        unidadesOrganizativas: state.form.unidadesOrganizativas,
        primerInforme: state.form.primerInforme,
        ultimoInforme: state.form.ultimoInforme,
      };
      state.form = { ...state.form, ...data };
    },
    setUnidadesOrganizativas: (state, action) => {
      if (
        action.payload.checked &&
        !state.form.unidadesOrganizativas?.some(
          (x) => x.code === action.payload.code
        )
      ) {
        if (state.form.unidadesOrganizativas)
          state.form.unidadesOrganizativas.push(action.payload);
        else state.form.unidadesOrganizativas = [action.payload];
      } else {
        if (Array.isArray(action.payload))
          state.form.unidadesOrganizativas = action.payload;
        else
          state.form.unidadesOrganizativas =
            state.form.unidadesOrganizativas.filter(
              (x) => x.code !== action.payload.code
            );
      }
    },
    setPrimerInfome : (state, action) =>{
      state.form.primerInforme = state.form.primerInforme.concat(action.payload);
    },
    setUltimoInforme: (state, action) =>{
      state.form.ultimoInforme = state.form.ultimoInforme.concat(action.payload);
    }
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegimenesAsync.fulfilled, (state, action) => {
        if (action.payload.regimenes)
          state.data.regimenes = action.payload.regimenes;
        state.data.tipoLicencias = action.payload.tipoLicencias;
        if (state.data.regimenes?.length === 1) {
          state.form.regimen = state.data.regimenes[0].code;
        }
      })
      .addCase(fetchLicenciasAsync.fulfilled, (state, action) => {
        state.data.licencias = action.payload;
      })
      .addCase(fetchSteps.fulfilled, (state, action) => {
        const steps = JSON.parse(action.payload.value.replace(/\\"/g, '"'));
        state.data.stepComponents = steps.map((step) => step.name);
        state.data.stepFields = steps.map((step) => step["step-fields"]);
      })
      .addCase(getSolapamientoAusenteAsync.fulfilled, (state, action) => {
        state.isAusente = action.payload;
      })
      .addCase(fetchAllLicenciasAsync.fulfilled, (state, action) => {
        state.allLicences = action.payload;
      })
      .addCase(fetchDiagnosticosAsync.fulfilled, (state, action) => {
        state.data.diagnosticos = action.payload;
      })
      .addCase(fetchCentrosMedicos.fulfilled, (state, action) => {
        state.data.centrosMedicos = action.payload;
      })
      .addCase(fetchDatosMedicos.fulfilled, (state, action) => {
        state.data.datosMedicos = action.payload;
      })
      .addCase(fetchDatosLicencia.fulfilled, (state, action) => {
        state.parameters = action.payload;
      })
      .addCase(postCertificado.fulfilled, (state, action) => {
        if (action.payload.key === "primerInforme") {
          if (state.form.primerInforme.length > 0) {
            state.form.primerInforme = state.form.primerInforme.concat(
              action.payload.files
            );
          } else state.form.primerInforme = action.payload.files;
        } else if (action.payload.key === "ultimoInforme") {
          if (state.form.ultimoInforme.length > 0) {
            state.form.ultimoInforme = state.form.ultimoInforme.concat(
              action.payload.files
            );
          } else state.form.ultimoInforme = action.payload.files;
        }
      })

      .addCase(fetchOneLicenciaAsync.fulfilled, (state, action) => {
        state.oneLicence = action.payload;
      })
      .addCase(UpdateMedicoAsync.fulfilled, (state, action) => {
        state.oneLicence = action.payload;
       })
      .addCase(fetchAllTypeLicenciasAsync.fulfilled, (state, action) => {
        state.allTypeLicences = action.payload;
      })
      .addCase(fetchAutorizadasPor.fulfilled, (state, action) => {
        state.autorizadasPor = action.payload;
      })
      .addCase(fetchUnidadesOrganizativasAsync.fulfilled, (state, action) => {
        state.data.unidadesOrganizativas = action.payload;
        if (action.payload.length === 1)
          state.form.unidadesOrganizativas = action.payload;
      })
      .addCase(fetchSaldoDisponible.fulfilled, (state, action) => {
        state.data.saldoDisponible = action.payload;
      });
  },
});

export const {
  updateForm,
  ResetState,
  removeCertificado,
  removeOtrosDocumentos,
  removePrimerInforme,
  removeUltimoInforme,
  setOtrosDocumentos,
  clearOneLicence,
  setUnidadesOrganizativas,
  completarAusencia,
  changeLicenseType,
  setPrimerInfome,
  setUltimoInforme
} = licenseSlice.actions;
export default licenseSlice.reducer;
export const selectLicenses = (state) => state.licencia;
