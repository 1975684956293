import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDocuments,
  setDocuments,
  setDocumentsMobile,
  setRemoveDocumentsMobile,
} from "../../../files/filesSlice";
import { selectSettings } from "../../../parameters/parametersSlice";
import { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, Typography } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CustomAlert from "../../../alert";
import { sizeValidator } from "../../../../utils/helper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { cleanString } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { selectDatosPersonales } from "../../../user/userSlice";
import Camera from "./camera-requerido";
function obtenerFechaHoraActual() {
  const ahora = new Date();
  const dia = ahora.getDate().toString().padStart(2, "0");
  const mes = (ahora.getMonth() + 1).toString().padStart(2, "0"); // Los meses van de 0 a 11
  const año = ahora.getFullYear();
  const horas = ahora.getHours().toString().padStart(2, "0");
  const minutos = ahora.getMinutes().toString().padStart(2, "0");
  const segundos = ahora.getSeconds().toString().padStart(2, "0");

  const fechaHoraActual = `${año}-${mes}-${dia}-${horas}-${minutos}-${segundos}.jpeg`;
  return fechaHoraActual;
}
export default function DocRequerido({docRespaldatoria,  identificador , cameraStream , setCameraStream  , requestCameraPermission , creacion=true}) {
  const { extensions } = useSelector(selectSettings);
  const [error, setError] = useState({ show: false, data: "" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { documents, documentsMobile } = useSelector(selectDocuments);
  const { datos } = useSelector(selectDatosPersonales);
  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = [...documents, ...acceptedFiles];
      const filesLenght = [...files, ...documentsMobile];
      if (filesLenght.length > 3) {
        setError({
          show: true,
          data: `Solo se pueden adjuntar hasta 3 archivos como máximo.`,
        });
        return;
      }
      // Verificar si algún archivo nuevo ya existe en la lista actual
      const duplicateFile = acceptedFiles.find((newFile) => {
        return documents.some(
          (existingFile) => existingFile.name === newFile.name
        );
      });

      if (duplicateFile) {
        setError({
          show: true,
          data: `El archivo "${duplicateFile.name}" ya ha sido adjuntado.`,
        });
        return;
      }
      if (creacion) {
        setDialogOpen(true);
      }
      dispatch(setDocuments(files));
    },
    [documents]
  );

  const showCamera = () => {
    const cameraOptions = {
      quality: 50, // Calidad de la imagen (0-100)
      destinationType: 0, // Utilizando valor directo en lugar de Camera.DestinationType.FILE_URI
      sourceType: 1, // Utilizando valor directo en lugar de Camera.PictureSourceType.CAMERA
      encodingType: 0, // Utilizando valor directo en lugar de Camera.EncodingType.JPEG
      targetWidth: 800,
      targetHeight: 600,
    };
    try {
      navigator.camera.getPicture(
        (imageData) => {
          try {
            let photo = {
              CUIT: datos.cuit.toString(),
              Key: "certificado",
              Name: obtenerFechaHoraActual(),
              Image: imageData,
              UserId: datos.id,
              SendMeta4: false,
            };

            const imageFiles = [...documentsMobile, photo];
            const filesLenght = [...imageFiles, ...documents];
            if (filesLenght.length > 3) {
              setError({
                show: true,
                data: `Solo se pueden adjuntar hasta 3 archivos como máximo.`,
              });
              return;
            }
            setDialogOpen(true);

            dispatch(setDocumentsMobile(imageFiles));
          } catch (error) {
            console.log(error)
          }
        },
        (error) => {
          console.error("Error al abrir la cámara:", error);
        },
        cameraOptions
      );
    } catch (error) {
      console.log(error)
    }
  };

  const removeFile = (file) => () => {
    const newFiles = [...documents];
    newFiles.splice(newFiles.indexOf(file), 1);
    dispatch(setDocuments(newFiles));
  };

  const removeFileMobile = (file) => () => {
    const newFiles = [...documentsMobile];
    newFiles.splice(newFiles.indexOf(file), 1);
    dispatch(setRemoveDocumentsMobile(newFiles));
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: JSON.parse(extensions),
    validator: sizeValidator,
    capture: "camera",
  });

  useEffect(() => {
    const validExtns = JSON.parse(extensions);
    var propiedades = Object.getOwnPropertyNames(validExtns);
    let valid = "";
    propiedades.forEach((element) => {
      valid += validExtns[element];
    });
    fileRejections.map(({ errors }) => {
      setError({ show: true, data: errors[0]?.message });
    });
  }, [fileRejections]);

  const handleErrorClose = () => {
    setError({ show: false });
  };

  const [openCamera, setOpenCamera] = useState(false);

  return (
    <>
      <div>
        <div
          {...getRootProps({ className: "dropzone" })}
          className="border-2 rounded-md py-5 px-5"
          style={{ display: "grid" }}
        >
          <input {...getInputProps()} />
          <p className="text-center text-[#5836ff]">
            Arrastrar archivos para adjuntar ó
            <button className="p-2 bg-[#5836ff] text-white rounded-md shadow-sm cursor-pointer">
              Explorar en disco
            </button>
          </p>
        </div>
        <div
          className="border-2 rounded-md py-3 px-5 hideWeb"
          style={{ textAlign: "-webkit-center", marginTop: "10px" }}
        >
          <button
            className="p-2 bg-[#5836ff] text-white rounded-md shadow-sm cursor-pointer"
            onClick={() => setOpenCamera(true)}
          >
            Capturar Imagen
          </button>
        </div>
      </div>
      <Dialog open={openCamera} onClose={() => setOpenCamera(false)} sx={{ zIndex: 99999999}}>
        <Camera docRespaldatoria={docRespaldatoria} setError={setError} setOpenCamera={setOpenCamera} cameraStream={cameraStream} setCameraStream={setCameraStream} requestCameraPermission={requestCameraPermission}/>
      </Dialog>
      <div className="flex flex-col">
        {documents?.map((doc) => (
          <>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography key={doc.id}>
                <InsertDriveFileIcon />
                {doc.name}
              </Typography>
              <Button onClick={removeFile(doc)}>
                <DeleteForeverIcon sx={{ color: "#ff3d8b" }} />
              </Button>
            </Box>
          </>
        ))}
        {documentsMobile?.map((doc, index) => (
          <>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography key={index}>
                <InsertDriveFileIcon />
                {doc.name}
              </Typography>
              <Button onClick={removeFileMobile(doc)}>
                <DeleteForeverIcon sx={{ color: "#ff3d8b" }} />
              </Button>
            </Box>
          </>
        ))}
        {((documents?.length > 0 || documentsMobile?.length > 0) && creacion) && (
          <Button
            className="mx-auto mt-2"
            variant="outlined"
            onClick={() => setDialogOpen(true)}
            color="primary"
          >
            Ver certificados
          </Button>
        )}
      </div>
      <CustomAlert error={error} severity="error" onClose={handleErrorClose} />
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle className="text-center">Archivos Adjuntados</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Aquí están los certificados que has adjuntado:
          </DialogContentText>
          <div className="flex flex-col gap-4 justify-center items-center">
            {documents?.map((doc, index) => (
              <img
                key={index}
                src={URL.createObjectURL(doc)}
                alt={`Imagen ${index + 1}`}
                className="object-cover rounded-md w-[300px]"
              />
            ))}
            {documentsMobile?.map((doc, index) => (
              <img
                key={index}
                src={"data:image/jpeg;base64," + doc.Image}
                alt={`Imagen ${index + 1}`}
                className="object-cover rounded-md w-[300px]"
              />
            ))}
          </div>
          <div className="mt-2">
            <h1 className="text-lg font-semibold">
              Confirmo que es legible y contiene:
            </h1>
            <ul className="list-disc ml-4">
              <li>Membrete</li>
              <li>Nombre y apellido</li>
              <li>Diagnóstico médico</li>
              <li>Fecha del certificado</li>
              <li>Firma y sello del/la médico/a</li>
              <li>DNI del paciente</li>
              <li>Domicilio del lugar de atención</li>
            </ul>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="mx-auto"
            variant="outlined"
            onClick={() => setDialogOpen(false)}
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
