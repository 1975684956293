import api, { APPCONFIG } from "../../api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  perfilActual: {},
  perfiles: {},
  domicilio: {
    telefono:{
      countryCode:"+54",
      areaCode:"",
      phoneNumber:""
    },
    calle:"",
    altura:"",
    piso:"",
    depto:"",
    provincia:{
      id:"",
      descripcion:""
    },
    localidad:{
      idProvincia:"",
      id:"",
      descripcion:""
    },
    codPostal:"",
    observaciones:""
  },
  firstEmail:"",
  otrosDomicilios: null,
  datos: {
    idHR: "",
    email: "",
    fecNacimiento: "",
  },
  familiares: [],
  sesion: {
    token: "",
    expiration: "",
    invalidToken: false,
  },
  telefono: {
    countryCode: "+54",
    phoneNumber: "",
    areaCode: "",
  },
};

export const getTokenBukeala = createAsyncThunk(
  "license/getTokenBukeala", 
  async (data) => {
    const url = `${APPCONFIG.APIs.AuthUserAPI}/user/LoginAuthenticationEncript?cuit=${data.cuit}&rol=${data.rol}`;
    const response = await api.get(url);
    return response.data;
  }
)

export const deleteDomicilioAsync = createAsyncThunk(
  "license/deleteDomicilioAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/Domicilio?domicilioId=${data.direccionId}`;
    const response = await api.delete(url);
    return response.data;
  }
);
export const getDomicilioAsync = createAsyncThunk(
  "license/getDomicilioAsync",
  async (direccionId) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/Domicilio?domicilioId=${direccionId}`;
    const response = await api.get(url);
    return response.data;
  }
);
export const deleteFamiliarAsync = createAsyncThunk(
  "license/deleteFamiliarAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/Familiar?id=${data.id}`;
    const response = await api.delete(url);
    return response.data;
  }
);

export const saveDomicilioAsync = createAsyncThunk(
  "usuario/saveDomicilioAsync",
  async (data, { rejectWithValue }) => {
    if (data.areaCode || data.phoneNumber) {
      const telefono = {
        areaCode: data.areaCode,
        phoneNumber: data.phoneNumber,
        countryCode: data.countryCode,
      };
      data = { ...data, telefono };
    }
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/AgregarDomicilio`;
    try {
      const response = await api.post(url, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveFamiliarAsync = createAsyncThunk(
  "usuario/saveFamiliarAsync",
  async (data, { rejectWithValue }) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/Familiar`;
    try {
      const response = await api.post(url, data);
    return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateFamiliarAsync = createAsyncThunk(
  "usuario/updateFamiliarAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/Familiar`;
    const response = await api.put(url, data);
    return response.data;
  }
);
export const getFamiliaresAsync = createAsyncThunk(
  "usuario/getFamiliaresAsync",
  async (userId) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License/Familiar?userId=${userId}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const saveUserAsync = createAsyncThunk(
  "usuario/saveDatosPersonales",
  async (user, { rejectWithValue }) => {
    try {
      const url = `${APPCONFIG.APIs.AuthUserAPI}/AuthUser/Register`;
      const response = await api.post(url, user);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
   
  }
);

export const loginAsync = createAsyncThunk(
  "usuario/loginAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.AuthUserAPI}/AuthUser/Login`;
    const response = await api.post(url, data);
    return response.data;
  }
);

export const updateUserAsync = createAsyncThunk(
  "usuario/updateDatosPersonales",
  async (data) => {
    let user = {
      datosPersonales:data.datosPersonales,
      domicilio:data.domicilio,
      telefono:data.telefono,
    }
    const url = `${APPCONFIG.APIs.AuthUserAPI}/user?userToken=${data.token}&userId=${data.userId}`;
    const response = await api.put(url, user);
    if (response.data.content) {
      return {
        updated: response.data.content,
        user: user,
      };
    }
  }
);

export const checkEmail = async (email,idHR) => {
  const url = `${APPCONFIG.APIs.AuthUserAPI}/user/emailexists?email=${email}&idHR=${idHR}`;
  const response = await api.get(url);
  return response.data;
};

export const fetchValidacionDomicilio = createAsyncThunk(
  "usuario/ValidarDomicilio",
  async (data) => {
    const url = `${APPCONFIG.APIs.AuthUserAPI}/user/ValidarDomicilio?calle=${data.calle}&numero=${data.altura}&localidad=${data.localidad.descripcion}&provincia=${data.provincia.descripcion}`;
    const response = await api.get(url);
    const result = {
      ...data,
      validado:
        response.status === 200 &&
        (response.data?.results[0]?.geometry.location_type === "ROOFTOP" ||
          response.data?.results[0]?.geometry.location_type ===
          "RANGE_INTERPOLATED"),
    };
    return result;
  }
);

export const fetchValidacionDomicilioExtra = createAsyncThunk(
  "usuario/ValidarDomicilioExtra",
  async (data) => {
    const url = `${APPCONFIG.APIs.AuthUserAPI}/user/ValidarDomicilio?calle=${data.calle}&numero=${data.altura}&localidad=${data.localidad.descripcion}&provincia=${data.provincia.descripcion}`;
    const response = await api.get(url);
    const result = {
      ...data,
      validado:
        response.status === 200 &&
        (response.data?.results[0]?.geometry.location_type === "ROOFTOP" ||
          response.data?.results[0]?.geometry.location_type ===
          "RANGE_INTERPOLATED"),
    };
    return result;
  }
);

export const userSlice = createSlice({
  name: "usuario",
  initialState,
  reducers: {
    reset: () => initialState,
    setEmail: (state, action) => {
      state.datos.email = action.payload;
    },
    editDatos: (state, action) => {
      const { fecNacimiento } = action.payload;
      Object.assign(state.datos, { fecNacimiento });
    },
    setCountryCode: (state, action) => {
      state.domicilio.telefono.countryCode = action.payload;
    },
    setFirstEmail: (state, action) => {
      state.firstEmail = action.payload;
    },
    setAreaCode: (state, action) => {
      state.domicilio.telefono.areaCode = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.domicilio.telefono.phoneNumber = action.payload;
    },
    setCalle:(state,action) => {
      state.domicilio.calle = action.payload;
    },
    setAltura:(state,action) => {
      state.domicilio.altura = action.payload;
    },
    setPiso:(state,action) => {
      state.domicilio.piso = action.payload;
    },
    setDepto:(state,action) => {
      state.domicilio.depto = action.payload;
    },
    setProvincia:(state,action) => {
      state.domicilio.provincia = action.payload;
    },
    setLocalidad:(state,action) => {
      state.domicilio.localidad = action.payload;
    },
    setCodPostal:(state,action) => {
      state.domicilio.codPostal = action.payload;
    },
    setObservaciones:(state,action) => {
      state.domicilio.observaciones = action.payload;
    },
    setProfile: (state, action) => {
      state.perfilActual = action.payload;
    },
    validarDomicilio: (state, action) => {
      state.domicilio = { ...state.domicilio, validado: action.payload };
    },
    setTerminosYCondiciones: (state) => {
      state.datos.aceptaTerminosYCondiciones = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.datos = action.payload.user.datosPersonales;
        const telefono = {countryCode:action.payload.user.domicilio.countryCode,areaCode:action.payload.user.domicilio.areaCode,phoneNumber:action.payload.user.domicilio.phoneNumber}
        const domicilio = {...state.domicilio,...action.payload.user.domicilio,telefono}
        state.domicilio = domicilio;
      })
      .addCase(deleteDomicilioAsync.fulfilled, (state, action) => {
        state.otrosDomicilios = state.otrosDomicilios.filter(
          (x) => x.direccionId !== action.payload
        );
      })
      .addCase(deleteFamiliarAsync.fulfilled, (state, action) => {
        if(action.payload.success){
          state.familiares = state.familiares.filter(
            (x) => x.id !== action.payload.data
          );
        }
      })
      .addCase(fetchValidacionDomicilio.fulfilled, (state, action) => {
        const domicilio = {...state.domicilio,...action.payload}
        state.domicilio = domicilio;
      })
      .addCase(saveFamiliarAsync.fulfilled, (state, action) => {
        if (state.familiares?.length > 0)
          state.familiares.push(action.payload.data);
        else
          state.familiares = [action.payload.data];
      })
      .addCase(saveUserAsync.fulfilled, (state, action) => {
        let userToken = state.sesion.userToken;
        //api.defaults.headers.common['Authorization'] = `Bearer ${action.payload.sesion.token}`;
        state.sesion = {...action.payload.sesion,userToken};
        state.datos = { ...state.datos, id: action.payload.userId };
        state.domicilio = {...state.domicilio,direccionId:action.payload.direccionId}
      })
      .addCase(getFamiliaresAsync.fulfilled, (state, action) => {
        state.familiares = action.payload.data;
      })
      .addCase(saveDomicilioAsync.fulfilled, (state, action) => {
        if (state.otrosDomicilios?.length > 0)
          state.otrosDomicilios.push(action.payload.data);
        else state.otrosDomicilios = [action.payload.data];
      })
      .addCase(loginAsync.rejected, (state) => {
        state.sesion.invalidToken = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        //api.defaults.headers.common['Authorization'] = `Bearer ${action.payload.sesion.token}`;
        state.sesion = action.payload.sesion;
        state.firstEmail = action.payload.userData.datosPersonales.email;
        state.datos = action.payload.userData.datosPersonales;
        state.perfiles = action.payload.userData.perfiles;
        if (action.payload.userData.datosPersonales.registrado && action.payload.userData.domicilio) {
          state.domicilio = action.payload.userData.domicilio;
        }
        if(action.payload.userData.otrosDomicilios){
          state.otrosDomicilios = action.payload.userData.otrosDomicilios;
        }
      });
  },
});

export const {
  reset,
  editDatos,
  setProfile,
  setTerminosYCondiciones,
  setEmail,
  setPhoneNumber,
  setAreaCode,
  setCountryCode,
  validarDomicilio,
  setCalle,
  setAltura,
  setPiso,
  setDepto,
  setProvincia,
  setLocalidad,
  setCodPostal,
  setObservaciones,
  setFirstEmail
} = userSlice.actions;
export const selectDatosPersonales = (state) => state.usuario;
export default userSlice.reducer;
