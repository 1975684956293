/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Menu from "./menu";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAdmLicenciasAllHistoryAsync, fetchOneAdmLicenciaAsync } from "../administrativeSlice";
import FiltersHistory from "./filters";
import { verificarObjetos } from "../../../utils";
import HistoryTable from "./history-table";
const initialFilters = {
  fechaInicio: null,
  fechaFin: null,
};
function History() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { oneLicence } = useSelector((state) => state.admLicence);
  const [filters, setFilters] = React.useState(initialFilters);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleBuscar = () => {
    const filterArray = [];
    for (const key in filters) {
      const value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        filterArray.push({ code: key, description: value });
      }
    }
    //{code:"status",description:pendientes}
    dispatch(
      fetchAllAdmLicenciasAllHistoryAsync({ id: oneLicence.idhr, filters: filterArray , pageNumber:0,pageSize:rowsPerPage})
    ).then(res=>{
      setPage(0)
    });
  };

  const handleReset = () => {
    setFilters(initialFilters);
    dispatch(
      fetchAllAdmLicenciasAllHistoryAsync({
        id: oneLicence.idhr,
        filters: [], pageNumber:page,pageSize:rowsPerPage
      })
    ).then(res=>{
      setPage(0)
    });
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageAct = queryParams.get("pageAct");
  const licenseTypeId = queryParams.get("licenseTypeId");
  const [page, setPage] = React.useState(Number(pageAct));

  useEffect(() => {
    dispatch(fetchOneAdmLicenciaAsync({id,licenseTypeId})).then(res=>{
      dispatch(
        fetchAllAdmLicenciasAllHistoryAsync({
          id: res.payload.idhr,
          filters: [],
          pageNumber:0,
          pageSize:rowsPerPage
        })
      );
    });
  }, []);

  const activeBtn = queryParams.get("activeBtn");
  return (
    <div>
      <Menu
        title={"Historial de licencia"}
        subtitle={oneLicence.firstName + " " + oneLicence.lastName}
        idHr={oneLicence.idhr}
        cuil={oneLicence.cuit}
      />
      <section className="px-4 py-2">
      <FiltersHistory
        licenseTypeId={licenseTypeId}
        id={oneLicence.id}
        activeBtn={activeBtn}
        page={page}
        filters={filters}
        setFilters={setFilters}
        handleBuscar={handleBuscar}
        disabled={verificarObjetos(filters, initialFilters)}
        handleReset={handleReset}
      />

      <HistoryTable page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} idhr={oneLicence.idhr} filters={filters} />
      </section>
    </div>
  );
}

export default History;
