import React from "react";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Diagnostico from "./diagnostico";
import FechaCertificado from "./fechaCertificado";
import InformacionMedica from "./informacionMedica";
import DomicilioReposo from "./domicilioReposo";
import Certificado from "./certificado";
import CertificadosAccART from "./certificadosAccART";
import FechaCertificadoFamiliar from "./fechaCertificadoFamiliar";
import Familiar from "./familiar";
import MedResume from "./med-resume";
import ArtResume from "./art-resume";
import AdmUnidadesOrganizativas from "./admUnidadesOrganizativas";
import FechaNG from "./fechaNG";
import DomicilioMudanza from "./domicilioMudanza";
import FechaSiniestro from "./fechaSiniestro";
import Descripcion from "./Descripcion";
import DescripcionAcc from "./descripcionAcc";
import DomicilioTrabajo from "./domicilioTrabajo";
import EmbarazoResumen from "./embarazoResumen";
import FechaParto from "./fechaParto";
import Ecografia from "./ecografia";
import FechaAlumbramiento from "./fechaAlumbramiento";
import Neonatologia from "./neonatologia";
import FechaCertificadoHosp from "./fechaCertificadoHosp";
import AlumbramientoResumen from "./alumbramientoResumen";
import FechaVacaciones from "./fechaVacaciones";
import ARTUnidadesOrganizativas from "./ARTUnidadesOrganizativas";

const stepComponentsMap = {
  Step1: Step1,
  Step2: Step2,
  Step3: Step3,
  Step4: Step4,
  Step5: Step5,
  FechaSiniestro: FechaSiniestro,
  Diagnostico: Diagnostico,
  FechaCertificado: FechaCertificado,
  InformacionMedica: InformacionMedica,
  Domicilio: DomicilioReposo,
  Certificados: Certificado,
  FechaCertificadoFamiliar: FechaCertificadoFamiliar,
  InformacionFamiliar: Familiar,
  MedResume: MedResume,
  ArtResume: ArtResume,
  AdmUnidadesOrganizativas: AdmUnidadesOrganizativas,
  FechaNG: FechaNG,
  DomicilioMudanza: DomicilioMudanza,
  Descripcion: Descripcion,
  DomicilioTrabajo: DomicilioTrabajo,
  EmbarazoResumen: EmbarazoResumen,
  FechaParto: FechaParto,
  Ecografia: Ecografia,
  CertificadoART: CertificadosAccART,
  DescripcionAcc: DescripcionAcc,
  CertificadosAccART: CertificadosAccART,
  FechaAlumbramiento:FechaAlumbramiento,
  Neonatologia : Neonatologia,
  AlumbramientoResumen:AlumbramientoResumen,
  FechaCertificadoHosp : FechaCertificadoHosp,
  FechaVacaciones : FechaVacaciones,
  ARTUnidadesOrganizativas : ARTUnidadesOrganizativas,
};

export default function FormSteps(control, setValue, user, license) {
  const { data } = license;
  return data.stepComponents
    .map((stepName) => {
      const StepComponent = stepComponentsMap[stepName];
      if (StepComponent) {
        return (
          <StepComponent
            key={stepName}
            control={control}
            setValue={setValue}
            user={user}
          />
        );
      }
      return null;
    })
    .filter(Boolean);
}
