import moment from "moment";
import api, { APPCONFIG } from "../../api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allLicences: [],
  oneLicence: {},
  history: [],
  totalCount: 0,
  allAdmHistoryLicences:[],
  select:[]
};

export const fetchAllAdmLicenciasAsync = createAsyncThunk(
  "admLicense/getAllAdmLicenciasAsync",
  async (data) => {
    let sort = data.ord === 1 ? "desc" : "asc";
    const url = `${APPCONFIG.APIs.LicenseAPI}/Administrative/licences?id=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
    const response = await api.post(url, {
      filter: data.filters,
      sort: { sort },
    });
    return response.data;
  }
);

export const fetchOneAdmLicenciaAsync = createAsyncThunk(
  "admLicense/fetchOneAdmLicenciaAsync",
  async (data) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/License?licenseId=${data.id}&licenseTypeId=${data.licenseTypeId}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchAllAdmLicenciasAllHistoryAsync = createAsyncThunk(
  "admLicense/fetchAllAdmLicenciasAllHistoryAsync",
  async (data) => {
    let sort = data.ord === 1 ? "desc" : "asc";
    const url = `${APPCONFIG.APIs.LicenseAPI}/Administrative/allLicencesHistory?id=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`;
    const response = await api.post(url, {
      filter: data.filters,
      sort: { sort },
    });
    return response.data;
  }
);

export const fetchHistoryLicenseById = createAsyncThunk(
  "admLicense/fetchHistoryLicenseById",
  async (licenceId) => {
    const url = `${APPCONFIG.APIs.LicenseAPI}/History?licenseId=${licenceId}`;
    const response = await api.get(url);
    return response.data;
  }
);

export const admLicenceSlice = createSlice({
  name: "admLicence",
  initialState,
  reducers: {
    removeHistory: (state) => {
      state.history = [];
    },
    addSelect: (state,action) =>{
      state.select = [...state.select,action.payload]
    },
    removeSelect:(state,action)=>{
      state.select = state.select.filter((id) => id !== action.payload)
    },
    setSelect: (state,action)=>{
      state.select = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAdmLicenciasAsync.fulfilled, (state, action) => {
        state.allLicences = action.payload.licenses;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchOneAdmLicenciaAsync.fulfilled, (state, action) => {
        state.oneLicence = action.payload;
      })
      .addCase(fetchAllAdmLicenciasAllHistoryAsync.fulfilled, (state, action) => {
        state.allAdmHistoryLicences = action.payload.licenses;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchHistoryLicenseById.fulfilled, (state, action) => {
        state.history = action.payload;
      });;
  },
});
export const { removeHistory , addSelect , removeSelect , setSelect} = admLicenceSlice.actions;

export default admLicenceSlice.reducer;
