/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl } from "@mui/base";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { selectLicenses } from "../licenseSlice";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { Box, FormLabel, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function FechaCertificadoHosp({ control , setValue}) {
  const data = useSelector(selectLicenses);
  const { form } = useSelector(selectLicenses);
  const [open, setOpen] = useState(false);

  const getDate = (days, startDate) => {
    const currentDate = startDate ?? moment(new Date());
    const result = currentDate.add(days, "days");
    return result;
  };

  useEffect(() => {
    setValue("fechaInicio",form.idNotificacion && data.allLicences.find(e => e.idNotificacion == form.idNotificacion).statusId == 3? dayjs(new Date(data.allLicences.find(e => e.idNotificacion == form.idNotificacion).startDate)) :dayjs(new Date()), { shouldTouch: true });
    setValue("fechaCertificado", moment(), { shouldTouch: true });
    setValue("licencia",form.licencia,{shouldTouch:true})
}, [])

  //inicio ausentismo <= fecha certificado OK
  //inicio ausentismo > fecha certificado DESHABILITADO
  return (
    <div>
      <FormControl id="input-fecha-certificado">
        <Box mt={2} className="flex flex-col mx-auto justify-center gap-2">
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Fecha de certificado
          </FormLabel>
          <p>
          Es la fecha del certificado médico. Tené en cuenta que no puede ser anterior al primer día de la hospitalización y que su validez es de 7 días. Si el certificado que estás por cargar es de hace más de 7 días deberás solicitar otro.
          </p>
          <Controller
            name="fechaCertificado"
            control={control}
            render={({ field: { onChange } }) => (
              <LocalizationProvider
                adapterLocale="es"
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  open={open}
                  onClose={()=>setOpen(false)}
                  defaultValue={dayjs(form.fechaCertificado)}
                  onChange={onChange}
                  key="fechaCertificado"
                  renderInput={(props) => <TextField {...props} fullWidth />}
                  slotProps={{ textField: { size: "small", error: false, onClick: () => setOpen(true) } }}
                  maxDate={dayjs(moment(new Date()))}
                  minDate={dayjs(getDate(-7))}
                />
              </LocalizationProvider>
            )}
          />
        </Box>
      </FormControl>

      <FormControl fullWidth>
        <Box mt={2} className="flex flex-col mx-auto justify-center gap-2">
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Inicio del ausentismo laboral
          </FormLabel>
          <p>
            Es el primer día de ausencia por hospitalización. Contando desde la
            fecha de carga para atrás, no podrá superar los 30 días.
          </p>
          <Controller
            name="fechaInicio"
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider
                adapterLocale="es"
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  onChange={onChange}
                  key="fechaInicio"
                  // defaultValue={form.idNotificacion && data.allLicences.find(e => e.idNotificacion == form.idNotificacion).statusId == 3? dayjs(new Date(data.allLicences.find(e => e.idNotificacion == form.idNotificacion).startDate)) :dayjs(new Date())}
                  value={value}
                  renderInput={(props) => <TextField {...props} fullWidth />}
                  slotProps={{ textField: { size: "small", error: false } }}
                  maxDate={dayjs(moment(new Date()))}
                  minDate={dayjs(getDate(-30))}
                />
              </LocalizationProvider>
            )}
          />
        </Box>
      </FormControl>
    </div>
  );
}
