import { Alert, Snackbar, Stack } from "@mui/material";
import React from "react";
export default function CustomAlert({ error, onClose, severity }) {
    
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
                open={error.show}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{paddingTop:15, maxWidth:500}}
                autoHideDuration={6000}
                onClose={onClose}
            >
                <Alert onClose={onClose} severity={error.severity ?? severity} sx={{ width: '100%' }}>
                    {error.data}
                </Alert>
            </Snackbar>
        </Stack>

    )
}