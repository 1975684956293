import React from "react";

function Menu({ title, subtitle, idHr, cuil }) {
  return (
    <header className="flex flex-col bg-[#f5f5f5] p-[15px] gap-2 px-[30px]">
      <div className="w-fit">
        <h1 className="text-[#5836ff] font-medium text-3xl">{title}</h1>
        <hr className="my-1" />
        <div className="flex gap-3 items-center text-[#5836ff]">
          <h2 className="font-medium my-auto items-center text-xl">{subtitle}</h2>
          {idHr && (
            <>
              <p className="items-center flex my-auto">IDHR: {idHr}</p>
              <p className="items-center flex my-auto">
                CUIL:{" "}
                {`${cuil.toString().substring(0, 2)}-${cuil
                  .toString()
                  .substring(2, 10)}-${cuil.toString().substring(10)}`}
              </p>
            </>
          )}
        </div>
      </div>
    </header>
  );
}

export default Menu;
