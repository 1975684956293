/* eslint-disable no-unused-vars */
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Modal,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import {
  fetchOneAdmSupLicenciaAsync,
  fetchHistoryLicenseById,
  removeHistory,
} from "../administrativeSuperiorSlice";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import { formatDate } from "../../../utils";
import { updateLicenceStatusAuSupAsync } from "../../license/licenseSlice";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { onOpen } from "../../alerts/alertSlice";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  minHeight: 100,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  h2: {
    fontSize: 20,
  },
  padding: 3,
  borderRadius: "15px",
};
function Detail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = React.useState(false);
  const { datos } = useSelector((state) => state.usuario);
  const { oneLicence, history } = useSelector((state) => state.admSupLicence);
  useEffect(() => {
    dispatch(fetchOneAdmSupLicenciaAsync(id));
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action) => {
    const data = [{ id: oneLicence.id, action, reason: motivoRechazo }];
    dispatch(updateLicenceStatusAuSupAsync({ data, userId: datos.id }))
      .then((res) => {
        if (res.payload) {
          navigate("/administrative-superior");
          dispatch(
            onOpen({
              message:
                action === 1
                  ? "La licencia se autorizo con exito"
                  : "La licencia se rechazo con exito",
            })
          );
        } else {
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
          handleClose();
        }
      })
      .catch((err) => {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
        handleClose();
      });
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const pageAct = queryParams.get("pageAct");

  const handleModalAction = (e) => {
    switch (e) {
      case "autorizar":
        setModalAut(true);
        break;
      case "rechazar":
        setModalRech(true);
        break;
      default:
        break;
    }
  };
  const [modalAut, setModalAut] = React.useState(false);
  const [modalRech, setModalRech] = React.useState(false);
  const [openAut, setOpenAut] = React.useState(false);
  const [openRech, setOpenRech] = React.useState(false);
  const [motivoRechazo, setMotivoRechazo] = React.useState("");

  const [openInfoAuditoria, setOpenInfoAuditoria] = React.useState(false);

  return (
    <>
      <div>
        <header className="flex flex-wrap bg-[#f5f5f5] p-[15px] items-center justify-between px-[30px]">
          <h1 className="text-[#5836ff] font-medium text-2xl">
            Detalle de la Solicitud
          </h1>
          <Link to={`/administrative-superior?pageAct=${pageAct}`}>
            <Button variant="contained">Volver a licencias</Button>
          </Link>
        </header>
        <section className="text-end flex w-full justify-center sm:justify-end px-[30px] gap-2 py-[10px]">
          <Button
            disabled={
              oneLicence.isHistorical ||
              oneLicence.statusId !== 8 ||
              oneLicence.autorizadaSup
            }
            variant="outlined"
            onClick={handleClick}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Acciones
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              //onClick={() => handleAction(1)}
              onClick={() => handleModalAction("autorizar")}
            >
              <CheckIcon sx={{ mr: "8px" }} />
              Autorizar
            </MenuItem>
            <Divider />
            <MenuItem
              //onClick={() => handleAction(3)}
              onClick={() => handleModalAction("rechazar")}
            >
              <CloseIcon sx={{ mr: "8px" }} />
              Rechazar
            </MenuItem>
          </Menu>
          <Button
            variant="outlined"
            onClick={() =>
              navigate(
                `/administrative-superior/history/${id}?pageAct=${pageAct}`
              )
            }
          >
            Ver historial
          </Button>
        </section>
        <div className="w-full flex flex-col sm:flex-row px-[30px] py-[10px] ">
          <aside className="w-full">
            <h1 className="font-medium text-slate-400 mb-2">
              Información de la licencia
            </h1>
            <Divider />
            <section className="flex flex-wrap gap-5 py-4">
              <TextField
                label="ID"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.id}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Estado"
                id="outlined-disabled"
                disabled
                multiline
                maxRows={4}
                className="w-[40%]"
                defaultValue={oneLicence.status ?? ""}
                value={oneLicence.status ?? ""}
                variant="standard"
              />
              <TextField
                label="Licencia"
                id="outlined-disabled"
                disabled
                multiline
                maxRows={4}
                className="w-[40%]"
                defaultValue={oneLicence.title ?? ""}
                value={oneLicence.title ?? ""}
                variant="standard"
              />
              <TextField
                label="Fecha de inicio"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formatDate(oneLicence.startDate)}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Fecha de fin"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {formatDate(oneLicence.endDate)}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Regimen"
                id="outlined-start-adornment"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.regimen}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Unidades organizativas"
                id="outlined-start-adornment"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.unidades?.map((el) => el.description)}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              {oneLicence.statusId === 8 && (
                <>
                  <TextField
                    label="Motivo del rechazo"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {oneLicence.motivoNoAprobacion ?? "-"}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                  <TextField
                    label="Comentario del Revisor"
                    id="outlined-start-adornment"
                    disabled
                    className="w-[40%]"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {oneLicence.comentarioAnular ?? "-"}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                  />
                </>
              )}
            </section>
            <Box p={2}>
              <Button
                onClick={() => {
                  setOpenInfoAuditoria(true);
                  dispatch(fetchHistoryLicenseById(oneLicence.id));
                }}
                variant="outlined"
              >
                Ver Otra Información Auditoria
              </Button>
            </Box>
            <h1 className="font-medium text-slate-400 mb-2">
              Autorizaciones/Rechazos
            </h1>
            <Divider />
            <div className="h-12"></div>
            <h1 className="font-medium text-slate-400 mb-2">
              Historial de notas de observaciones
            </h1>
            <Divider />
            <div className="h-12"></div>
          </aside>
          <Divider orientation="vertical" flexItem />
          <aside className="w-full">
            <h1 className="sm:ml-[15px] font-medium text-slate-400 mb-2">
              Información personal
            </h1>
            <Divider />
            <section className="flex flex-wrap gap-5 py-4 sm:ml-[15px] ">
              <TextField
                label="ID HR"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.idhr}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Nombre"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.firstName}
                    </InputAdornment>
                  ),
                }}
                type="text"
                variant="standard"
              />
              <TextField
                label="Apellido"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.lastName}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Cuil"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.cuit}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Nro. Documento"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.documento}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Nro. Telefono"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.celular?.slice(0, 13)}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <TextField
                label="Dirección"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                value={oneLicence?.direccion ?? ""}
                variant="standard"
              />
              <TextField
                label="Email"
                id="outlined-start-adornment"
                disabled
                className="w-[40%]"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {oneLicence.email}
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </section>
          </aside>
        </div>
        <Snackbar open={error} autoHideDuration={3000}>
          <Alert severity="error">Ups.. Algo salio mal</Alert>
        </Snackbar>
      </div>
      <Modal
        open={modalAut | modalRech}
        onClose={
          modalAut ? () => setModalAut(false) : () => setModalRech(false)
        }
      >
        <Box sx={{ ...style, width: 350 }}>
          {modalAut ? (
            <h1 className="font-semibold text-xl text-center">
              Estás por autorizar una solicitud de licencia.
            </h1>
          ) : (
            <h1 className="font-semibold text-xl text-center">
              Estás por rechazar una solicitud de licencia.
            </h1>
          )}
          {modalAut ? (
            <p className="text-center">¿Deseás continuar? .</p>
          ) : (
            <div>
              <FormControl fullWidth>
                <Select size="small" defaultValue="Razones de servicio">
                  <MenuItem selected value="Razones de servicio">
                    Razones de servicio
                  </MenuItem>
                </Select>
              </FormControl>

              <p className="text-center mt-2">
                Escribí el motivo por el cual se rechaza la solicitud de
                licencia. Este texto será recibido por empleado solicitante.
                Recordá que esta acción no se puede revertir.
              </p>
            </div>
          )}
          {modalRech && (
            <TextField
              placeholder="Máximo 1000 caracteres"
              value={motivoRechazo}
              onChange={(e) => {
                if (e.target.value.length > 1000) {
                  // Trunca el valor a 250 caracteres
                  const truncatedText = e.target.value.slice(0, 1000);
                  setMotivoRechazo(truncatedText);
                } else {
                  setMotivoRechazo(e.target.value);
                }
              }}
            />
          )}
          <Box className="flex gap-2 w-full justify-center">
            <Button
              variant="outlined"
              onClick={
                modalAut ? () => setModalAut(false) : () => setModalRech(false)
              }
            >
              NO
            </Button>
            <Button
              variant="contained"
              color={modalAut ? "primary" : "error"}
              onClick={() => handleAction(modalAut ? 1 : 3)}
            >
              SI
            </Button>
          </Box>
        </Box>
      </Modal>
      <Dialog
        maxWidth="md"
        open={openInfoAuditoria}
        onClose={() => setOpenInfoAuditoria(false)}
      >
        <DialogContent>
          <h1 className="text-[#5836ff] font-medium text-xl text-center mb-1">
            Información de auditoria
          </h1>
          {history?.filter((item) => item.field !== "ACCION")?.length === 0 ? (
            <div>No existe información para esta licencia</div>
          ) : (
            <div className="flex flex-col gap-3">
              {history
                ?.filter((item) => item.field !== "ACCION")
                ?.map((item) => (
                  <div className="flex justify-between gap-3 border-b rounded-md text-xs items-center">
                    <p className="font-medium flex flex-col text-gray-700">
                      Auditor <span>{item.auditor}</span>{" "}
                    </p>
                    <div className="flex flex-col gap-2">
                      <p className="font-medium flex flex-col text-gray-700">
                        Campo <span>{item.field}</span>{" "}
                      </p>
                      <p className="font-medium flex flex-col text-gray-700">
                        Valor asignado <span>{item.newValue}</span>{" "}
                      </p>
                      {item.oldValue && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor previo <span>{item.oldValue}</span>{" "}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      {item.fieldTwo && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Campo <span>{item.fieldTwo}</span>{" "}
                        </p>
                      )}
                      {item.newValueTwo && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor asignado <span>{item.newValueTwo}</span>{" "}
                        </p>
                      )}
                      {item.oldValueTwo && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor previo <span>{item.oldValueTwo}</span>{" "}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      {item.fieldThree && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Campo <span>{item.fieldThree}</span>{" "}
                        </p>
                      )}
                      {item.newValueThree && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor asignado <span>{item.newValueThree}</span>{" "}
                        </p>
                      )}
                      {item.oldValueThree && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor previo <span>{item.oldValueThree}</span>{" "}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      {item.fieldFour && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Campo <span>{item.fieldFour}</span>{" "}
                        </p>
                      )}
                      {item.newValueFour && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor asignado <span>{item.newValueFour}</span>{" "}
                        </p>
                      )}
                      {item.oldValueFour && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor previo <span>{item.oldValueFour}</span>{" "}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      {item.fieldFive && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Campo <span>{item.fieldFive}</span>{" "}
                        </p>
                      )}
                      {item.newValueFive && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor asignado <span>{item.newValueFive}</span>{" "}
                        </p>
                      )}
                      {item.oldValueFive && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor previo <span>{item.oldValueFive}</span>{" "}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      {item.fieldSix && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Campo <span>{item.fieldSix}</span>{" "}
                        </p>
                      )}
                      {item.newValueSix && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor asignado <span>{item.newValueSix}</span>{" "}
                        </p>
                      )}
                      {item.oldValueSix && (
                        <p className="font-medium flex flex-col text-gray-700">
                          Valor previo <span>{item.oldValueSix}</span>{" "}
                        </p>
                      )}
                    </div>
                    <p className="font-medium flex flex-col text-gray-700">
                      Fecha{" "}
                      <span>
                        {dayjs(item.createdDate).format('YYYY-MM-DD HH:mm')}
                      </span>{" "}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "center", pb: 3 }}
        >
          <Button
            onClick={() => {
              removeHistory();
              setOpenInfoAuditoria(false);
            }}
            size="small"
            variant="outlined"
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openAut | openRech}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {openAut ? "Licencia autorizada éxito" : "Licencia Rechaza con éxito"}
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={3000}>
        <Alert severity="error">Ups.. Algo salio mal</Alert>
      </Snackbar>
    </>
  );
}

export default Detail;
