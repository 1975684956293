import * as React from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, Box, CircularProgress } from '@mui/material';

const Spinner = () => {
  const loading = useSelector(state => state.loading);

  return (
    (loading &&
      <Backdrop
        open={loading}
        sx={{ color: '#fff', zIndex: 99999999999 }}>
        <CircularProgress color="inherit" size={100} />
      </Backdrop>
    )
  );
};

export default Spinner;