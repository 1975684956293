import React, { useEffect, useRef, useState } from "react";
import Menu from "./menu";
import Filters from "./filters";
import AuditProTable from "./table";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTypeLicenciasAsync } from "../license/licenseSlice";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { verificarObjetos } from "../../utils";
import {
  auditadasBtnAction,
  fetchAuditProLicenciasAsync,
  removeLicenses,
} from "./occupationalMedicineSlice";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

const initialFilters = {
  id: "",
  idHr: "",
  tipoLicencia: "",
  diagnostico: "",
  apellido: "",
  cuil: "",
  ausentismo: null,
  nuevaCitacionDesde: null,
  nuevaCitacionHasta: null,
  estado: "",
  tomada: false,
};

function Index() {
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const filtersParsed = sessionStorage.getItem("filters");
  const objParsed = filtersParsed ? JSON.parse(filtersParsed) : null;
  if (objParsed && objParsed.hasOwnProperty('ausentismo') && objParsed.ausentismo) {
    objParsed.ausentismo = dayjs(objParsed.ausentismo);
  }
  if(objParsed && objParsed.hasOwnProperty('nuevaCitacionDesde') && objParsed.nuevaCitacionDesde){
    objParsed.nuevaCitacionDesde = dayjs(objParsed.nuevaCitacionDesde);
  }
  if(objParsed && objParsed.hasOwnProperty('nuevaCitacionHasta') && objParsed.nuevaCitacionHasta){
    objParsed.nuevaCitacionHasta = dayjs(objParsed.nuevaCitacionHasta);
  }
  const [filters, setFilters] = React.useState(filtersParsed ? objParsed : initialFilters);
  const { datos, perfilActual } = useSelector((state) => state.usuario);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeBtn = queryParams.get("activeBtn");
  const pageAct = queryParams.get("pageAct");
  const queryPendientes = queryParams.get("queryPendientes");
  const [pendientes, setPendientes] = useState(
    queryPendientes === "false" ? false : true
  );

  const handleBuscar = () => {
    setIsLoading(true);
    dispatch(auditadasBtnAction(false));
    const filterArray = [
      { code: "perfil", description: perfilActual.idPerfil },
    ];
    for (const key in filters) {
      let value = filters[key];
      if (value !== "" && value !== null && value !== false) {
        if (value === true) {
          value = "1";
        }
        if (key === "cuil") {
          value = value.replace(/[^0-9]/g, "");
        }
        filterArray.push({ code: key, description: value });
      }
    }
    dispatch(
      fetchAuditProLicenciasAsync({
        id: datos.id,
        filters: filterArray,
        btn: activeButton,
        pageNumber: 0,
        pageSize: rowsPerPage,
        pendientes,
      })
    ).then((x) => {
      setIsLoading(false);
      setPage(0);
    });
  };
  const handleReset = () => {
    setIsLoading(true);
    if (pendientes) {
      if (activeButton !== 1) {
        const ele = autocompleteRef.current.getElementsByClassName(
          "MuiAutocomplete-clearIndicator"
        )[0];
        if (ele) ele.click();
      }
      setFilters(initialFilters);

      dispatch(
        fetchAuditProLicenciasAsync({
          id: datos.id,
          filters: [{ code: "perfil", description: perfilActual.idPerfil }],
          btn: activeButton,
          pageNumber: 0,
          pageSize: rowsPerPage,
          pendientes,
        })
      ).then((x) => {
        setIsLoading(false);
        setPage(0);
      });
      dispatch(auditadasBtnAction(false));
    } else {
      if (activeButton !== 1) {
        const ele = autocompleteRef.current.getElementsByClassName(
          "MuiAutocomplete-clearIndicator"
        )[0];
        if (ele) ele.click();
      }
      sessionStorage.clear();
      setFilters(initialFilters);
      setIsLoading(false);
      dispatch(auditadasBtnAction(true));
      setPage(0);
    }
  };

  useEffect(() => {
    if (activeBtn) {
      setActiveButton(Number(activeBtn));
    }
    if (pageAct && !changeBtn) {
      setPage(Number(pageAct));
    }
  }, []);
  
  const [activeButton, setActiveButton] = React.useState(Number(activeBtn));

  React.useEffect(() => {
    setIsLoading(true);
    if (pendientes) {
      switch (activeButton) {
        case 0:
          setFilters(initialFilters);
          dispatch(
            fetchAuditProLicenciasAsync({
              id: datos.id,
              btn: activeButton,
              pageNumber: page,
              pageSize: rowsPerPage,
              pendientes,
            })
          ).then((x) => setIsLoading(false));
          break;
        case 1:
          setFilters(initialFilters);
          dispatch(
            fetchAuditProLicenciasAsync({
              id: datos.id,
              btn: activeButton,
              pageNumber: page,
              pageSize: rowsPerPage,
              pendientes,
            })
          ).then((x) => setIsLoading(false));
          break;
        case 2:
          setFilters(initialFilters);
          dispatch(
            fetchAuditProLicenciasAsync({
              id: datos.id,
              btn: activeButton,
              pageNumber: page,
              pageSize: rowsPerPage,
              pendientes,
            })
          ).then((x) => setIsLoading(false));
          break;
        case 3:
          setFilters(initialFilters);
          dispatch(
            fetchAuditProLicenciasAsync({
              id: datos.id,
              btn: activeButton,
              pageNumber: page,
              pageSize: rowsPerPage,
              pendientes,
            })
          ).then((x) => setIsLoading(false));
          break;
        default:
          break;
      }
      dispatch(auditadasBtnAction(true));
    } else {
      const filtersParsed = sessionStorage.getItem("filters");
      if(filtersParsed){
         const objParsed = JSON.parse(filtersParsed);
         if (objParsed.ausentismo) {
          objParsed.ausentismo = dayjs(objParsed.ausentismo);
        }
        if(objParsed.nuevaCitacionDesde){
          objParsed.nuevaCitacionDesde = dayjs(objParsed.nuevaCitacionDesde);
        }
        if(objParsed.nuevaCitacionHasta){
          objParsed.nuevaCitacionHasta = dayjs(objParsed.nuevaCitacionHasta);
        }
         setFilters(objParsed);
      }else{
        setFilters(initialFilters);
        dispatch(auditadasBtnAction(true));
      }

      setIsLoading(false);
    }
  }, [activeButton, pendientes]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(Number(pageAct));
  const autocompleteRef = React.useRef();
  const [changeBtn, setBtnChange] = useState(false);
  const handleChangeButtonPage = (btn) => {
    setPendientes(true);
    setActiveButton(btn);
    setBtnChange(true);
  };

  useEffect(() => {
    if (changeBtn) {
      setPage(0);
    }
  }, [changeBtn, activeButton]);

  return (
    <div>
      <Menu
        activeButton={activeButton}
        setActiveButton={handleChangeButtonPage}
      />
      <Filters
        pendientes={pendientes}
        filters={filters}
        setFilters={setFilters}
        activeButton={activeButton}
        autocompleteRef={autocompleteRef}
      />
      <Box className="d-flex justify-content-center w-100 gap-2">
        <Button
          variant={"contained"}
          onClick={handleBuscar}
          disabled={
            !pendientes ? verificarObjetos(filters, initialFilters) : false
          }
        >
          Buscar
        </Button>
        <Button
          disabled={verificarObjetos(filters, initialFilters)}
          variant="outlined"
          onClick={handleReset}
        >
          Limpiar
        </Button>
      </Box>
      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" size={100} />
        </Backdrop>
      ) : (
        <AuditProTable
          pendientes={pendientes}
          setPendientes={setPendientes}
          pageAct={pageAct}
          activeBtn={activeBtn}
          filters={filters}
          activeButton={activeButton}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
      )}
    </div>
  );
}

export default Index;
