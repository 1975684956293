import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {  useSelector } from "react-redux";
import { LicStatusEnum } from "../../../interfaces/LicStatusEnum";
function Filters({ filters, setFilters, onFilterChange }) {
  const { data } = useSelector((state) => state.licencia);

  const handleChange = (event) => {
    switch (event.target.name) {
      case "id":
        setFilters({ ...filters, id: event.target.value });
        break;
      case "idHr":
        setFilters({ ...filters, idHr: event.target.value });
        break;
      case "cuil":
        setFilters({ ...filters, cuil: event.target.value });
        break;
      case "apellido":
        setFilters({ ...filters, apellido: event.target.value });
        break;
      case "nombre":
        setFilters({ ...filters, nombre: event.target.value });
        break;
      case "tipoLicencia":
        setFilters({ ...filters, tipoLicencia: event.target.value });
        break;
      case "nroSiniestroOtorgado":
        setFilters({ ...filters, nroSiniestroOtorgado: event.target.value });
        break;
      case "nroSiniestroDefinitivo":
        setFilters({ ...filters, nroSiniestroDefinitivo: event.target.value });
        break;
      case "nroExpediente":
        setFilters({ ...filters, nroExpediente: event.target.value });
        break;
      case "estado":
        setFilters({ ...filters, estado: event.target.value });
        break;
      case "inaccRAP":
        setFilters({ ...filters, inaccRAP: event.target.checked });
        break;
      case "tomada":
        setFilters({ ...filters, tomada: event.target.checked });
        break;
      default:
        break;
    }
    if (event.target.value.length === 0)
      onFilterChange()
  };

  const handleChangeDates = (action, event) => {
    switch (action) {
      case "fechaDenuncia":
        setFilters({ ...filters, fechaDenuncia: event });
        break;
      case "fechaSiniestro":
        setFilters({ ...filters, fechaSiniestro: event });
        break;
      default:
        break;
    }
  };

  return (
    <Box className="p-3 flex gap-4 justify-center flex-wrap">
      <TextField
        name="id"
        value={filters.id}
        sx={{ width: "165px" }}
        size="small"
        id="outlined-basic"
        label="Licencia ID"
        variant="outlined"
        type="number"
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter')
            onFilterChange()
        }}
      />
      <TextField
        name="idHr"
        type="number"
        value={filters.idHr}
        sx={{ width: "165px" }}
        size="small"
        id="outlined-basic"
        label="ID HR"
        variant="outlined"
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter')
            onFilterChange()
        }}
      />
      <TextField
        name="cuil"
        value={filters.cuil}
        sx={{ width: "165px" }}
        size="small"
        id="outlined-basic"
        label="Cuil"
        variant="outlined"
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter')
            onFilterChange()
        }}
      />
      <TextField
        name="apellido"
        value={filters.apellido}
        sx={{ width: "165px" }}
        size="small"
        id="outlined-basic"
        label="Apellido"
        variant="outlined"
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter')
            onFilterChange()
        }}
      />
      <TextField
        name="nombre"
        value={filters.nombre}
        sx={{ width: "165px" }}
        size="small"
        id="outlined-basic"
        label="Nombre"
        variant="outlined"
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter')
            onFilterChange()
        }}
      />
      <FormControl size="small" sx={{ width: "165px" }}>
        <InputLabel id="demo-simple-select-label">Tipo Licencia</InputLabel>
        <Select
          name="tipoLicencia"
          value={filters.tipoLicencia}
          label="Tipo Licencia"
          onChange={handleChange}
        >
          <MenuItem value={""}>Quitar Filtro</MenuItem>
          {data.licencias.map((lc, key) => (
            <MenuItem key={key} value={lc.description}>
              {lc.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
        <DatePicker
          onChange={(e) => handleChangeDates("fechaDenuncia", e)}
          sx={{ width: "185px" }}
          slotProps={{ textField: { size: "small" } }}
          className="datepicker"
          value={filters.fechaDenuncia}
          label="Fecha Denuncia"
        />
        <DatePicker
          onChange={(e) => handleChangeDates("fechaSiniestro", e)}
          sx={{ width: "185px" }}
          slotProps={{ textField: { size: "small" } }}
          className="datepicker"
          value={filters.fechaSiniestro}
          label="Fecha Siniestro"
        />
      </LocalizationProvider>
      <div className="flex items-center gap-1">
        <span className="p-2 border border-slate-950 rounded-md font-semibold">
          B
        </span>
        <TextField
          name="nroSiniestroOtorgado"
          value={filters.nroSiniestroOtorgado}
          sx={{ width: "165px" }}
          size="small"
          id="outlined-basic"
          label="Nro Siniestro Otorgado"
          variant="outlined"
          type="number"
          onChange={handleChange}
        />
      </div>
      <TextField
        name="nroSiniestroDefinitivo"
        value={filters.nroSiniestroDefinitivo}
        sx={{ width: "165px" }}
        size="small"
        id="outlined-basic"
        label="Nro Siniestro Definitivo"
        variant="outlined"
        type="number"
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter')
            onFilterChange()
        }}
      />
      <TextField
        name="nroExpediente"
        value={filters.nroExpediente}
        sx={{ width: "165px" }}
        size="small"
        id="outlined-basic"
        label="Nro Expediente"
        variant="outlined"
        type="number"
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter')
            onFilterChange()
        }}
      />
      <FormControl size="small" sx={{ width: "165px" }}>
        <InputLabel id="demo-simple-select-label">Estado</InputLabel>
        <Select
          name="estado"
          value={filters.estado}
          label="Estado"
          onChange={handleChange}
        >
          <MenuItem value={""}>Quitar Filtro</MenuItem>
          <MenuItem value={`${LicStatusEnum.APROBADA}`}>Aprobada</MenuItem>
          <MenuItem value={`${LicStatusEnum.EN_REVISION}`}>En revisión</MenuItem>
          <MenuItem value={`${LicStatusEnum.RECHAZADA}`}>Rechazada</MenuItem>
          <MenuItem value={`${LicStatusEnum.RECHAZADAPORINCONSISTENCIA}`}>Rechazada por Inconsistencia</MenuItem>
          <MenuItem value={`${LicStatusEnum.NO_REGISTRADA}`}>No registrada</MenuItem>
          <MenuItem value={`${LicStatusEnum.REGISTRADAENPRESTADORA}`}>Registrada por Prestadora</MenuItem>
          <MenuItem value={`${LicStatusEnum.CERRADA}`}>Cerrada</MenuItem>
        </Select>
      </FormControl>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="inaccRAP"
              onChange={handleChange}
              checked={filters.inaccRAP}
            />
          }
          label="Innac. RAP"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="tomada"
              onChange={handleChange}
              checked={filters.tomada}
            />
          }
          label="Tomada"
        />
      </FormGroup>
    </Box>
  );
}

export default Filters;
