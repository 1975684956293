import * as React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { MenuItem, Grid, Select, InputLabel, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTokenBukeala,
  reset,
  selectDatosPersonales,
  setProfile,
} from "../userSlice";
import { Controller, useForm } from "react-hook-form";
import { persistor } from "../../../app/store.js";
const SelectProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const usuario = useSelector(selectDatosPersonales);

  const { control } = useForm({
    defaultValues: {},
  });

  const handleCancel = () => {
    // dispatch(reset());
    // persistor.purge();
    // navigate("/login");
    document.forms["cancel"].submit();
  };

  

  const goTo = () => {
    navigate(usuario.perfilActual.rootPath);
  };

  const [openBukeala, setOpenBukeala] = React.useState({
    url: null,
  });

  function generateRandomState() {
    const randomBytes = new Uint8Array(32);
    window.crypto.getRandomValues(randomBytes);
    return btoa(String.fromCharCode.apply(null, randomBytes));
  }
  
  // Uso de la función para obtener el valor state
  const stateValue = generateRandomState();
  const url = `${process.env.REACT_APP_DOMAIN_OAUTH}/authorize?client_id=${process.env.REACT_APP_CLIENTID_OAUTH}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=openid&state=${stateValue}`;

  return (
    <>
    <form id="cancel" action={url} method="post">
      <input type="hidden" name="authorize" value="0" />
      <input type="hidden" name="logout" value="1" />
    </form>
      <Button
        style={{ position: "absolute" }}
        className="absolute right-0"
        onClick={handleCancel}
      >
        <LogoutIcon />
        Salir
      </Button>
      <Grid
        container
        gap={3}
        direction="column"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        sx={{ minHeight: "80vh" }}
      >
        <h3 className="text-2xl font-semibold">
          Bienvenido <br />{" "}
          {usuario.datos.nombre + " " + usuario.datos.apellido}
        </h3>
        <Grid>
          <InputLabel id="simple-select-label" className="mb-2">
            Seleccioná tu perfil para continuar
          </InputLabel>
          <Controller
            name="company"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                sx={{ minWidth: { xs: 150, sm: 300 }, maxHeight: 100 }}
                labelId="Company"
                className="input-container"
                disabled={!usuario.perfiles?.length}
                value={value}
                onChange={onChange}
              >
                {usuario.perfiles.map((perfil, i) => (
                  <MenuItem
                    key={`perfil-${i}`}
                    onClick={() => {
                      if (
                        perfil.idPerfil === "TURNERA_NIVEL_01" ||
                        perfil.idPerfil === "TURNERA_NIVEL_02" ||
                        perfil.idPerfil === "TURNERA_NIVEL_03" ||
                        perfil.idPerfil === "TURNERA_NIVEL_04" ||
                        perfil.idPerfil === "AT_PROFESIONAL"
                      ) {
                        dispatch(
                          getTokenBukeala({
                            cuit: usuario.datos.cuit,
                            rol: perfil.idPerfil,
                          })
                        ).then((x) => {
                          setOpenBukeala({
                            url: x.payload.content,
                          });
                        });
                      } else {
                        setOpenBukeala({
                          url: null,
                        });
                        dispatch(setProfile(perfil));
                      }
                    }}
                    value={perfil.idPerfil}
                  >
                    {perfil.nombrePerfil}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid>
          <Button
            disabled={!usuario.perfilActual.rootPath}
            onClick={() => {
              if (openBukeala.url) {
                window.location.href = openBukeala.url;
              }else{
                goTo();
              }
            }}
            variant="contained"
          >
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default SelectProfile;
