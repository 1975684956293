import { FormControl } from "@mui/base";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormLabel,
  Typography,
} from "@mui/material";
import {
  deleteDomicilioAsync,
  fetchValidacionDomicilioExtra,
  saveDomicilioAsync,
  selectDatosPersonales,
} from "../../user/userSlice";
import { Localidades, Provincias } from "../../user/registration/locations";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Address from "../../user/address";
import { handleConfirmModal, selectModals } from "../../modals/modalSlice";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CustomAlert from "../../alert";
import { selectLicenses } from "../licenseSlice";

export default function DomicilioTrabajo({ control, setValue }) {
  const { confirmModal } = useSelector(selectModals);
  const { form } = useSelector(selectLicenses);
  const { domicilio, otrosDomicilios, datos } = useSelector(
    selectDatosPersonales
  );
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState({ show: false, data: "" });
  const validateOtrosDom = otrosDomicilios?.length > 0 && otrosDomicilios?.find(x=>x.direccionId)
  const defaultDomicilio = validateOtrosDom ? otrosDomicilios?.find(x=>x.direccionId === form.domicilio) : {}; 
  const addressForm = useForm({
    defaultValues: defaultDomicilio,
    rules: {
      codPostal: {
        required: true,
        pattern: /^[0-9]{1,4}$/,
      },
      localidad: {
        required: true,
      },
    },
  });
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getProvincia = (id) => {
    const result = Provincias.find((x) => x.id === id);
    return result.descripcion;
  };

  const getLocalidad = (id, idProvincia) => {
    const result = Localidades.find(
      (x) => x.id === id && x.idProvincia === idProvincia
    );
    return result.descripcion;
  };

  const [seeBtn,setSeeBtn] = React.useState(true);

  const saveDomicilio = (data) => {
    dispatch(fetchValidacionDomicilioExtra(data)).then((response) => {
      if (form.licencia.description === "Accidente In Itinere") {
        const usuarioId = datos.id;
        data = { ...data, usuarioId };
        dispatch(saveDomicilioAsync(data)).then(response=>{
          if(response.payload.success){
            setValue("domicilio", response.payload.data.direccionId);
            setSeeBtn(false);
            return
          }
        })
      }
      if (!response.payload.validado && !confirmModal.accepted) {
        dispatch(
          handleConfirmModal({
            open: true,
            title: `El domicilio no fue validado`,
            content: "¿Desea continuar con la actualización?",
            showCancel: true,
          })
        );
      } else {
        const usuarioId = datos.id;
        data = { ...data, usuarioId };
        dispatch(saveDomicilioAsync(data)).then((response) => {
          if (response.payload.success) {
            setOpen(false);
          } else {
            setOpen(false);
            setError({ show: true, data: response.payload.message });
          }
        });
      }
    });
  };
  const [openDeleteDomicilio, setOpenDeleteDomicilio] = React.useState({
    show: false,
    data: null,
  });
  const handleDeleteDomicilio = () => {
    dispatch(deleteDomicilioAsync(openDeleteDomicilio.data)).then(
      (_response) => {
        if (form.domicilio === openDeleteDomicilio.data.direccionId) {
          setValue("domicilio", null, { shouldTouch: true });
        }
        setOpenDeleteDomicilio({ show: false });
      }
    );
  };

  return (
    <>
      {form.licencia.description === "Accidente In Itinere" ? (
        <div className="flex flex-col">
          <h1 className="text-[#5836ff] font-bold mt-2 text-center">Lugar donde ocurrió el accidente</h1>
          <Address form={addressForm} defaultDomicilio={defaultDomicilio} isItinere={!form.licencia.description === "Accidente In Itinere"}></Address>
          {seeBtn && (
          <Button
            onClick={addressForm.handleSubmit((x) => saveDomicilio(x))}
            autoFocus
            className="mx-auto"
            variant="outlined"
          >
            Confirmar Domicilio
          </Button>
          )}
        </div>
      ) : (
        <>
          <FormControl id="input-diagnostico" fullWidth>
            <>
              <p className="text-[#5836ff] font-bold mt-2 text-center">
                Informá tu domiclio de trabajo
              </p>
              <Typography>
                Si te ocurrió en un domicilio cargado previamente en la
                aplicación, podrás seleccionarlo del siguiente listado:
              </Typography>
            </>
            <Divider sx={{ my: 2 }} />
            <Controller
              name="domicilio"
              control={control}
              render={({ field }) =>
              validateOtrosDom ? otrosDomicilios?.map((dm, i) => (
                  <FormControlLabel
                    style={{ width: "410px" }}
                    key={`domicilio-${i}`}
                    control={
                      <div
                        className="flex flex-col"
                        style={{ alignSelf: "stretch" }}
                      >
                        <Checkbox
                          {...field}
                          style={{marginRight:domicilio.direccionId === dm.direccionId ? "22px" : ""}}
                          value={dm} // Asigna el objeto completo a value
                          checked={field.value === dm.direccionId} // Comprueba si el objeto completo coincide con el valor actual
                          onChange={(e) => {
                            if (e.target.checked) {
                              field.onChange(dm.direccionId);
                            } else {
                              field.onChange(null);
                            }
                          }} // Actualiza el valor solo si el checkbox está marcado
                        />
                        <Button
                          hidden={domicilio.direccionId === dm.direccionId}
                          onClick={() =>
                            setOpenDeleteDomicilio({ show: true, data: dm })
                          }
                        >
                          <DeleteForeverIcon sx={{ color: "#ff3d8b" }} />
                        </Button>
                      </div>
                    }
                    label={
                      <>
                        <Container
                          style={{ marginLeft: "-30px", width: "380px" }}
                        >
                          <Box
                            pb={3}
                            className="grid grid-cols-2 w-full min-w-full gap-3 border p-2 rounded-md shadow-md"
                          >
                            <div className="flex flex-col">
                              <FormLabel
                                sx={{ color: "#5836ff", fontWeight: 600 }}
                              >
                                Calle:
                              </FormLabel>
                              <Typography
                                style={{
                                  width: "180px",
                                  overflowWrap: "break-word",
                                }}
                                variant="p"
                              >
                                {dm.calle} - {dm.altura}
                              </Typography>
                            </div>
                            <div
                              className="flex flex-col"
                              style={{ marginLeft: "40px" }}
                            >
                              <FormLabel
                                sx={{ color: "#5836ff", fontWeight: 600 }}
                              >
                                Número:
                              </FormLabel>
                              <Typography variant="p">{dm.altura}</Typography>
                            </div>
                            <div className="flex flex-col">
                              <FormLabel
                                sx={{ color: "#5836ff", fontWeight: 600 }}
                              >
                                Piso:
                              </FormLabel>
                              <Typography variant="p">{dm.piso}</Typography>
                            </div>
                            <div className="flex flex-col-2">
                              <div style={{ display: "grid" }}>
                                <FormLabel
                                  sx={{
                                    color: "#5836ff",
                                    fontWeight: 600,
                                    marginLeft: "-70px",
                                  }}
                                >
                                  Depto:
                                </FormLabel>
                                <Typography
                                  variant="p"
                                  style={{ marginLeft: "-70px" }}
                                >
                                  {dm.depto}
                                </Typography>
                              </div>
                              <div style={{ display: "grid" }}>
                                <FormLabel
                                  sx={{
                                    color: "#5836ff",
                                    fontWeight: 600,
                                    marginLeft: "40px",
                                  }}
                                >
                                  CP:
                                </FormLabel>
                                <Typography
                                  variant="p"
                                  style={{ marginLeft: "40px" }}
                                >
                                  {dm.codPostal}
                                </Typography>
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <FormLabel
                                sx={{ color: "#5836ff", fontWeight: 600 }}
                              >
                                Provincia:
                              </FormLabel>
                              <Typography variant="p">
                                {getProvincia(dm.provincia.id)}
                              </Typography>
                            </div>
                            <div
                              className="flex flex-col"
                              style={{ marginLeft: "40px" }}
                            >
                              <FormLabel
                                sx={{ color: "#5836ff", fontWeight: 600 }}
                              >
                                Localidad:
                              </FormLabel>
                              <Typography variant="p">
                                {getLocalidad(dm.localidad.id, dm.provincia.id)}
                              </Typography>
                            </div>
                          </Box>
                          {domicilio.direccionId === dm.direccionId && (
                            <p className="text-[#5836ff] font-bold mt-2 text-center">
                              No podés eliminar el domicilio seleccionado. Ingresá a “Mi Perfil” para poder modificarlo.
                            </p>
                          )}
                        </Container>
                        <Divider sx={{ my: 2 }} />
                      </>
                    }
                  />
                )) : null
              }
            />
          </FormControl>
          <div>
            <Button fullWidth onClick={handleClickOpen}>
              Agregar domicilio
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <Address form={addressForm}></Address>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center" }}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button
                  onClick={addressForm.handleSubmit((x) => saveDomicilio(x))}
                  autoFocus
                >
                  Guardar cambios
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDeleteDomicilio.show}
              onClose={() => setOpenDeleteDomicilio({ show: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <Typography>
                  ¿Seguro que desea eliminar el domicilio?
                </Typography>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center" }}>
                <Button onClick={() => setOpenDeleteDomicilio({ show: false })}>
                  Cancelar
                </Button>
                <Button onClick={handleDeleteDomicilio}>Eliminar</Button>
              </DialogActions>
            </Dialog>
            <CustomAlert
              error={error}
              onClose={() => setError({ show: false })}
              severity="error"
            ></CustomAlert>
          </div>
        </>
      )}
    </>
  );
}
/*
      <FormControl id="input-diagnostico" fullWidth>
        {form.licencia.description === "Accidente In Itinere" ?         <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
        Lugar donde ocurrió el accidente
        </FormLabel> : <>        
        <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
          Informá tu domiclio de trabajo
        </FormLabel>
        <Typography>
          Si te ocurrió en un domicilio cargado previamente en la aplicación,
          podrás seleccionarlo del siguiente listado:
        </Typography>
        </>}
        <Divider sx={{ my: 2 }} />
        <Controller
          name="domicilio"
          control={control}
          render={({ field }) =>
            otrosDomicilios?.map((dm, i) => (
              <FormControlLabel
              style={{width:'410px'}}
                key={`domicilio-${i}`}
                control={
                  <div className="flex flex-col"  style={{alignSelf: 'stretch'}}>
                    <Checkbox
                      {...field}
                      value={dm} // Asigna el objeto completo a value
                      checked={field.value === dm.direccionId} // Comprueba si el objeto completo coincide con el valor actual
                      onChange={(e) => {
                        if(e.target.checked){
                          field.onChange(dm.direccionId)
                        }else{
                          field.onChange(null)
                        }
                      }} // Actualiza el valor solo si el checkbox está marcado
                    />
                     <Button onClick={() => setOpenDeleteDomicilio({ show: true, data: dm })}>
                      <DeleteForeverIcon sx={{ color: "#ff3d8b" }} />
                    </Button>
                  </div>
                }
                label={
                  <>
                    <Container style={{marginLeft: '-30px', width:'380px'}}>
                      <Box
                        pb={3}
                        className="grid grid-cols-2 w-full min-w-full gap-3 border p-2 rounded-md shadow-md"
                      >
                        <div className="flex flex-col">
                          <FormLabel sx={{ color: "#5836ff", fontWeight: 600 }}>
                            Calle:
                          </FormLabel>
                          <Typography style={{width:'180px', overflowWrap:'break-word'}} variant="p">
                            {dm.calle} - {dm.altura}
                          </Typography>
                        </div>
                        <div className="flex flex-col" style={{marginLeft:'40px'}}>
                          <FormLabel sx={{ color: "#5836ff", fontWeight: 600 }}>
                            Número:
                          </FormLabel>
                          <Typography variant="p">{dm.altura}</Typography>
                        </div>
                        <div className="flex flex-col">
                          <FormLabel sx={{ color: "#5836ff", fontWeight: 600 }}>
                            Piso:
                          </FormLabel>
                          <Typography variant="p">{dm.piso}</Typography>
                        </div>
                        <div className="flex flex-col-2">
                          <div style={{display:'grid'}}>
                            <FormLabel sx={{ color: "#5836ff", fontWeight: 600, marginLeft:'-70px' }}>
                              Depto:
                            </FormLabel>
                            <Typography variant="p" style={{marginLeft:'-70px'}}>{dm.depto}</Typography>
                          </div>
                          <div style={{display:'grid'}}>
                            <FormLabel sx={{ color: "#5836ff", fontWeight: 600, marginLeft:'40px' }}>
                              CP:
                            </FormLabel>
                            <Typography variant="p" style={{marginLeft:'40px'}}>{dm.codPostal}</Typography>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <FormLabel sx={{ color: "#5836ff", fontWeight: 600 }}>
                            Provincia:
                          </FormLabel>
                          <Typography variant="p">
                            {getProvincia(dm.provincia.id)}
                          </Typography>
                        </div>
                        <div className="flex flex-col" style={{marginLeft:'40px' }}>
                          <FormLabel sx={{ color: "#5836ff", fontWeight: 600 }}>
                            Localidad:
                          </FormLabel>
                          <Typography variant="p">
                            {getLocalidad(dm.localidad.id,dm.provincia.id)}
                          </Typography>
                        </div>
                      </Box>
                      {i === 0 && (
                        <Typography
                          sx={{ fontWeight: "bold", color: "#5836ff" }}
                          variant="label"
                        >
                          Este domicilio no se puede eliminar, podrás
                          modificarlo desde tu perfil
                        </Typography>
                      )}
                    </Container>

                    <Divider sx={{ my: 2 }} />
                  </>
                }
              />
            ))
          }
        />
      </FormControl>
      <div>
        <Button fullWidth onClick={handleClickOpen}>
          Agregar domicilio
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Address form={addressForm}></Address>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              onClick={addressForm.handleSubmit((x) => saveDomicilio(x))}
              autoFocus
            >
              Guardar cambios
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDeleteDomicilio.show}
          onClose={() => setOpenDeleteDomicilio({ show: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography>¿Seguro que desea eliminar el domicilio?</Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={() => setOpenDeleteDomicilio({ show: false })}>Cancelar</Button>
            <Button
              onClick={handleDeleteDomicilio}
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
        <CustomAlert error={error} onClose={() => setError({ show: false })} severity='error'></CustomAlert>
      </div>
    </>
  );
}*/
