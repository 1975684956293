import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

export default function ConfirmData() {

    return (
        <React.Fragment>
            <Container sx={{ width: 0.6, py: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'center' }}>
                    <Typography variant="span" sx={{ mt: 2, color: '#1d5a87',textAlign:"center" }}><h5>Confirmá tu registro en el sistema</h5></Typography>
                </Box>
            </Container>
        </React.Fragment>
    );
}