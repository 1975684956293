import {
  Dialog,
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Container,
  ThemeProvider,
  createTheme,
  Divider,
  FormControl,
  TextField,
  Autocomplete,
  Modal,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  checkEmail,
  fetchValidacionDomicilio,
  selectDatosPersonales,
  setEmail,
  setFirstEmail,
  updateUserAsync,
} from "../userSlice";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Controller, useForm } from "react-hook-form";
import "dayjs/locale/es";
import {
  handleConfirmModal,
  handleProfileModal,
  selectModals,
} from "../../modals/modalSlice";
import { Localidades, Provincias } from "../registration/locations";
import React from "react";
import moment from "moment";
import { verificarObjetos } from "../../../utils/index";
import "./styles.scss";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  minWidth:300,
  minHeight:300,
  maxWidth: 400,
  maxHeight: 400
};
const Profile = ({ handleClose }) => {
  dayjs.extend(localizedFormat);

  const usuario = useSelector(selectDatosPersonales);
  const modals = useSelector(selectModals);
  const {
    control,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      calle: usuario.domicilio?.calle,
      altura: usuario.domicilio?.altura,
      depto: usuario.domicilio?.depto,
      piso: usuario.domicilio?.piso,
      observaciones: usuario.domicilio?.observaciones,
      provincia: Provincias.find(
        (x) => x.id === usuario.domicilio?.provincia?.id
      ),
      localidad: Localidades.find(
        (x) => x.id === usuario.domicilio.localidad?.id && x.idProvincia === usuario.domicilio?.provincia?.id
      ),
      countryCode: "+54",
      areaCode: usuario.domicilio?.telefono?.areaCode,
      phoneNumber: usuario.domicilio?.telefono?.phoneNumber,
    },
    rules: {
      codPostal: {
        required: true,
        pattern: /^[0-9]{1,4}$/,
      },
      observaciones: {
        required: true,
      },
      localidad: {
        required: true,
      },
    },
  });

  const [errEmail,setErrEmail] = React.useState(false)
  const [emailExist,setEmailExist] = React.useState(false)
  const [modal,setModal] = React.useState(false)

  const dispatch = useDispatch();
  const handleChangeEmail = (e) => {
    setErrEmail(false);
      dispatch(setEmail(e.target.value));
  };
  const handleBlurEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = emailRegex.test(usuario.datos?.email);
    if (!isValidEmail) {
      setErrEmail(true);
    } else {
      setErrEmail(false);
    }
  
    // Luego de la validación, puedes llamar a checkEmail aquí si es necesario
    if(usuario.datos?.email !== usuario.firstEmail){
      checkEmail(usuario.datos?.email,usuario.datos?.idHR).then((res) => {
        setEmailExist(res);
        if (res === true) {
          setModal(true);
        }
      });
    }else{
      setEmailExist(false)
    }
  };

  const updateUser = () => {
    let values = getValues();
    let telefono = {
      phoneNumber: values.phoneNumber,
      areaCode:values.areaCode,
      countryCode:values.areaCode
    }
    dispatch(fetchValidacionDomicilio(values)).then((response) => {
      // if(!response.payload){
      //   dispatch(
      //     handleConfirmModal({
      //       open: true,
      //       title: `El domicilio no fue validado`,
      //       content: "¿Desea continuar con la actualización?",
      //       showCancel: true,
      //     })
      //   );
      // }
      if (!response.payload && !modals.confirmModal.accepted) {
        dispatch(
          handleConfirmModal({
            open: true,
            title: `El domicilio no fue validado`,
            content: "¿Desea continuar con la actualización?",
            showCancel: true,
          })
        );
      } 
      else if (!response.payload?.validado && !modals.confirmModal.accepted) {
        dispatch(
          handleConfirmModal({
            open: true,
            title: `El domicilio no fue validado`,
            content: "¿Desea continuar con la actualización?",
            showCancel: true,
          })
        );
      }
      

      else {
        dispatch(
          updateUserAsync({ datosPersonales: usuario.datos, domicilio: values ,telefono,token:usuario?.sesion?.userToken,userId:usuario?.datos?.id})
        );
        dispatch(handleProfileModal({ open: false }));
        dispatch(setFirstEmail(usuario.datos.email))
      }
    });
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'Arial, sans-serif',
      h1: {
        fontSize: 24,
        fontWeight: 600,
        color: '#00D6D1',
      },
      h2: {
        fontSize: 20,
        color: "#333333"
      },
      label: {
        fontWeight: 500,
        color:"#5836ff"
      },
      p: {
        color: "#333333"
      }
    },
    palette: {
      primary: {
        main: '#556fff', // Cambia el color principal
      },
      secondary: {
        main: '#00D6D1', // Cambia el color secundario
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          // Personaliza el estilo de todos los botones
          root: {
            borderRadius: '4px', // Cambia el borde
            fontWeight: 'bold', // Cambia el peso de la fuente
          },
        },
      },
    },
  });

  const autocompleteRef = React.useRef();

  return (
    <>
          <Dialog
            open={modal}
            onClose={() => setModal(false)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            fullWidth
            maxWidth="sm"
            sx={{zIndex:2}}
            style={{zIndex:2}}
            
          >
            <Box className="box-modal">
              <p className="modal-contain">El correo electrónico {usuario?.datos.email} ya fue registrado en el perfil de otro/a empleado/a. Si te pertenece, escribe a foco_comunicacion@buenosaires.gob.ar con tus datos, teléfono y correo para realizar la denuncia correspondiente.</p>
                <Button variant="contained" onClick={()=>setModal(false)} className="mx-auto">
                  Aceptar
                </Button>
            </Box>
          </Dialog>
      <ThemeProvider theme={theme}>
        <Dialog
                    sx={{zIndex:1}}
                    style={{zIndex:1}}
          open={modals.profileModal.open}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h1">
              {usuario.datos.nombre + " " + usuario.datos.apellido}
            </Typography>
            <Typography variant="h2" sx={{mt:1}}>Editá tus datos personales</Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
          
            <DialogContentText>
              
              <Container
                sx={{ pb: 2, display: "flex", flexDirection: "column" }}
              >
                <Typography variant="h2" sx={{mb:1}}>Datos personales</Typography>
                <Divider />
                <Box sx={{ display: "flex", flexDirection: "column",mt:1 }}>
                  <FormControl>
                    <Typography variant="label">Nombre</Typography>
                    <Typography variant="p">
                      {usuario.datos.nombre + " " + usuario.datos.apellido}
                    </Typography>
                  </FormControl>

                </Box>
                <Box
                  sx={{
                    paddingTop: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl>
                    <Typography variant="label">Documento</Typography>
                    <Typography variant="p">
                      {usuario.datos.nroDocumento}
                    </Typography>
                  </FormControl>
                  <FormControl>
                    <Typography variant="label">CUIL</Typography>
                    <Typography variant="p">{usuario.datos.cuit}</Typography>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    pt: 2,
                    pb: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <FormControl>
                    <Typography variant="label">Fecha de nacimiento</Typography>
                    <Typography variant="p">
                      {moment(usuario.datos.fecNacimiento).format("D/MM/yyyy")}
                    </Typography>
                  </FormControl>
                  <FormControl>
                    <Typography variant="label">ID HR</Typography>
                    <Typography variant="p">{usuario.datos.idHR}</Typography>
                  </FormControl>
                </Box>
                <Typography variant="h2" sx={{mb:1}}>Datos de contacto</Typography>
                <Divider />
                <FormControl sx={{mt:1}}>
                    <Typography variant="label">Email</Typography>
                    <TextField
                      required
                      id="areaCode"
                      size="small"
                      value={usuario.datos.email}
                      onChange={(e)=>{
                        if(e.target.value.length > 100){
                          return
                        }
                        handleChangeEmail(e)}}
                        onBlur={handleBlurEmail}
                    />
                  </FormControl>
                  {errEmail && (<Typography sx={{ color: "#Ff0000" ,mt:1}}>
                        Formato invalido
                      </Typography>)}
                      <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                      pt: 2,
                      pb: 2,
                    }}
                  >
                    <div className="flex flex-col w-[70px]">
                      <FormControl
                      sx={{width:"70px"}}
                      >
                        <Typography variant="label">País</Typography>
                        <Controller
                          id="countryCode"
                          name="countryCode"
                          rules={{
                            required: true,
                            pattern:
                              /^[0-9!@#$%^&*()-_+=<>,.?/|[\]{}~`'":;\\]*$/,
                            maxLength: 4,
                          }}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              error={Boolean(errors.countryCode)}
                              size="small"
                              disabled
                              placeholder="+54"
                              inputProps={{
                                maxLength: 4,
                                inputMode: "numeric",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      {errors.countryCode?.type === "required" ? (
                        <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                          Campo requerido
                        </Typography>
                      ) : errors.countryCode?.type === "maxLength" ? (
                        <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                          Máximo 4 caracteres
                        </Typography>
                      ) : errors.countryCode?.type === "pattern" ? (
                        <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                          Solo números y/o caracteres especiales
                        </Typography>
                      ) : null}
                    </div>
                    <div className="flex flex-col w-[100px]">
                      <FormControl
                      sx={{width:"100px"}}
                      >
                        <Typography variant="label">Código Área</Typography>
                        <Controller
                          id="areaCode"
                          name="areaCode"
                          rules={{
                            required: true,
                            pattern: /^[0-9]*$/,
                            maxLength: 4,
                            minLength:2
                          }}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              error={Boolean(errors.areaCode)}
                              inputProps={{
                                maxLength: 4,
                                minLength:2,
                                inputMode: "numeric",
                              }}
                              size="small"
                            />
                          )}
                        />
                      </FormControl>
                      {errors.areaCode?.type === "required" ? (
                        <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                          Campo requerido
                        </Typography>
                      ) : errors.areaCode?.type === "maxLength" ? (
                        <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                          Máximo 4 caracteres
                        </Typography>
                      ) : errors.areaCode?.type === "pattern" ? (
                        <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                          Solo números
                        </Typography>
                      ) : errors.areaCode?.type === "minLength" ? (
                        <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                          Minimo 2 números
                        </Typography>
                      ) : null}
                    </div>
                  <div className="flex flex-col w-full hidenMobile">
                    <FormControl
                    >
                      <Typography variant="label">
                        Teléfono de Contacto
                      </Typography>
                      <Controller
                        id="phoneNumber"
                        name="phoneNumber"
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^[0-9]*$/,
                          maxLength: 10,
                          minLength: 6,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={Boolean(errors.phoneNumber)}
                            size="small"
                            inputProps={{
                              maxLength: 10,
                              minLength: 6,
                              inputMode: "numeric",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {errors.phoneNumber?.type === "required" ? (
                      <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                        Campo requerido
                      </Typography>
                    ) : errors.phoneNumber?.type === "minLength" ? (
                      <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                        Minimo 6 caracteres
                      </Typography>
                    ) : errors.phoneNumber?.type === "maxLength" ? (
                      <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                        Máximo 10 caracteres
                      </Typography>
                    ) : errors.phoneNumber?.type === "pattern" ? (
                      <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                        Solo números
                      </Typography>
                    ) : null}
                  </div>
                  </Box>
                  <Box>
                  <div className="flex flex-col w-full displayMobile">
                    <FormControl
                    >
                      <Typography variant="label">
                        Teléfono de Contacto
                      </Typography>
                      <Controller
                        id="phoneNumber"
                        name="phoneNumber"
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^[0-9]*$/,
                          maxLength: 10,
                          minLength: 6,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={Boolean(errors.phoneNumber)}
                            size="small"
                            inputProps={{
                              maxLength: 10,
                              minLength: 6,
                              inputMode: "numeric",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {errors.phoneNumber?.type === "required" ? (
                      <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                        Campo requerido
                      </Typography>
                    ) : errors.phoneNumber?.type === "minLength" ? (
                      <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                        Minimo 6 caracteres
                      </Typography>
                    ) : errors.phoneNumber?.type === "maxLength" ? (
                      <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                        Máximo 10 caracteres
                      </Typography>
                    ) : errors.phoneNumber?.type === "pattern" ? (
                      <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                        Solo números
                      </Typography>
                    ) : null}
                  </div>
                  </Box>
                <Box>
                <Typography variant="h2" sx={{mb:1}}>Dirección</Typography>
                <Divider />
                  <FormControl fullWidth sx={{mt:1}}>
                    <Typography variant="label">Calle</Typography>
                    <Controller
                      name="calle"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField {...field} size="small" />
                      )}
                    />
                    {errors.calle && (
                      <Typography sx={{ color: "#Ff0000", mt: 1 }}>
                        Campo requerido
                      </Typography>
                    )}
                  </FormControl>

                </Box>
                <Box
                  sx={{
                    paddingTop: 2,
                    display: "flex",
                    gap: 2,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl>
                    <Typography variant="label">Número</Typography>
                    <Controller
                      name="altura"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField {...field} size="small" />
                      )}
                    />
                    {errors.altura && (
                      <Typography sx={{ color: "#Ff0000" }}>
                        Campo requerido
                      </Typography>
                    )}
                  </FormControl>
                  <FormControl>
                    <Typography variant="label">Piso</Typography>
                    <Controller
                      name="piso"
                      control={control}
                      value={usuario.domicilio?.piso}
                      render={({ field }) => (
                        <TextField {...field} size="small" />
                      )}
                    />
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    paddingTop: 2,
                    gap: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl>
                    <Typography variant="label">Depto</Typography>
                    <Controller
                      name="depto"
                      control={control}
                      defaultValue={usuario.domicilio?.depto}
                      render={({ field }) => (
                        <TextField {...field} size="small" />
                      )}
                    />
                  </FormControl>
                  <FormControl>
                    <Typography variant="label">Código Postal</Typography>
                    <Controller
                      name="codPostal"
                      control={control}
                      rules={{ required: true, pattern: /^[0-9]{1,4}$/ }}
                      defaultValue={usuario.domicilio?.codPostal}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          error={Boolean(errors.codPostal)}
                          inputProps={{
                            maxLength: 4,
                            pattern: "[0-9]{1,4}",
                            inputMode: "numeric",
                          }}
                        />
                      )}
                    />
                    {errors.codPostal && (
                      <Typography sx={{ color: "#Ff0000" }}>
                        Campo requerido <br /> Solo números - Maximo 4
                      </Typography>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <FormControl fullWidth sx={{ pb: 2, pt: 2 }}>
                    <Typography variant="label">Provincia</Typography>
                    <Controller
                      name="provincia"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <Autocomplete
                          freeSolo
                          size="small"
                          options={Provincias}
                          defaultValue={Provincias.find(
                            (x) => x.id === usuario.domicilio?.provincia?.id
                          )}
                          getOptionLabel={(option) => option.descripcion}
                          onChange={(e, values) => {onChange(values)
                            const ele = autocompleteRef.current.getElementsByClassName(
                              "MuiAutocomplete-clearIndicator"
                            )[0];
                            if (ele) ele.click();
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: false }}
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ pb: 2 }}>
                    <label htmlFor="localidad" style={{ fontWeight: "bold" }}>
                      Localidad
                    </label>
                    <Controller
                      name="localidad"
                     
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange } }) => (
                        <Autocomplete
                          freeSolo
                          ref={autocompleteRef}
                          disabled={!watch("provincia")}
                          size="small"
                          error={Boolean(errors.localidad)}
                          options={Localidades.filter(
                            (x) => x.idProvincia === watch("provincia")?.id
                          )}
                          defaultValue={Localidades.find(
                            (x) => x.id === usuario.domicilio.localidad?.id && x.idProvincia === usuario.domicilio.provincia?.id
                          )}
                          getOptionLabel={(option) => option.descripcion}
                          onChange={(e, values) => onChange(values)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: false }}
                            />
                          )}
                        />
                      )}
                    />
                    {errors.localidad && (
                      <Typography sx={{ color: "#Ff0000" }}>
                        Campo requerido
                      </Typography>
                    )}
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    paddingTop: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl fullWidth>
                    <Typography variant="label">Observaciones</Typography>
                    <Controller
                      name="observaciones"
                      control={control}
                      rules={{ maxLength: 255 }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          inputProps={{ maxLength: 255 }}
                          error={Boolean(errors.observaciones)}
                        />
                      )}
                    />
                    {errors.observaciones && (
                      <Typography sx={{ color: "#Ff0000" }}>
                        Campo requerido <br /> Maximo 255 caracteres
                      </Typography>
                    )}
                  </FormControl>
                </Box>
              </Container>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center",py:2 }}>
            <Button
              onClick={() => dispatch(handleProfileModal({ open: false }))}
              sx={{ textTransform: "none", width: "200px" }}
              variant="outlined"
            >
              Cancelar
            </Button>
            <Button
              disabled={!verificarObjetos(errors, {}) || errEmail || emailExist}
              variant="outlined"
              onClick={handleSubmit(updateUser)}
              sx={{ textTransform: "none", width: "200px" }}
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};
export default Profile;
