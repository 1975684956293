import { FormControl } from "@mui/base";
import React from "react";
import { Controller } from "react-hook-form";
import { Box, FormLabel, TextField } from "@mui/material";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { selectLicenses } from "../licenseSlice";
import { useSelector } from "react-redux";
export default function DescripcionAcc({ control }) {
  const { form } = useSelector(selectLicenses);

  return (
    <>
      <FormControl>
        <Box mt={2} className="flex flex-col">
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Hora del Siniestro
          </FormLabel>
          <Controller
            name="horaSiniestro"
            control={control}
            render={({ field: { onChange } }) => (
              <LocalizationProvider
                adapterLocale="es"
                dateAdapter={AdapterDayjs}
              >
                <TimePicker
                  defaultValue={form.horaSiniestro}
                  onChange={onChange}
                />
              </LocalizationProvider>
            )}
          />
        </Box>
      </FormControl>
      <FormLabel sx={{ color: "#5836ff", fontWeight: "bold", marginTop: 1 }}>
        Jornada Laboral
      </FormLabel>
      <div className="flex sm:flex-row flex-col gap-3">
        <FormControl>
          <Box mt={1} className="flex flex-col">
            <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
              Desde
            </FormLabel>
            <Controller
              name="jornadaLaboralDesde"
              control={control}
              render={({ field: { onChange } }) => (
                <LocalizationProvider
                  adapterLocale="es"
                  dateAdapter={AdapterDayjs}
                >
                  <TimePicker
                    defaultValue={form.jornadaLaboralDesde}
                    onChange={onChange}
                  />
                </LocalizationProvider>
              )}
            />
          </Box>
        </FormControl>
        <FormControl>
          <Box mt={1} className="flex flex-col">
            <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
              Hasta
            </FormLabel>
            <Controller
              name="jornadaLaboralHasta"
              control={control}
              render={({ field: { onChange } }) => (
                <LocalizationProvider
                  adapterLocale="es"
                  dateAdapter={AdapterDayjs}
                >
                  <TimePicker
                    defaultValue={form.jornadaLaboralHasta}
                    onChange={onChange}
                  />
                </LocalizationProvider>
              )}
            />
          </Box>
        </FormControl>
      </div>
      <FormControl id="input-diagnostico" fullWidth>
        <Box mt={2}>
          <FormLabel sx={{ color: "#5836ff", fontWeight: "bold" }}>
            Descripción del accidente
          </FormLabel>
          <Controller
            name="descripcionAccidente"
            control={control}
            rules={{ required: true, minLength: 10, maxLength: 255 }}
            render={({ field: { onChange, value } }) => (
              <>
                <TextField
                  multiline
                  fullWidth
                  rows={3}
                  placeholder="Describí cómo, cuándo y dónde fue el accidente y qué consecuencia te trajo."
                  value={value}
                  onChange={(e) => {
                    if (e.target.value.length > 255) {
                      return;
                    } else {
                      onChange(e);
                    }
                  }}
                />
                {value?.length > 0 && value?.length > 255 && (
                  <p className="text-red-600 pt-2">Máximo 255 caracteres</p>
                )}
                {value?.length > 0 && value?.length < 10 && (
                  <p className="text-red-600 pt-2">Mínimo de 10 caracteres</p>
                )}
              </>
            )}
          />
        </Box>
      </FormControl>
    </>
  );
}
