import React from "react";
import { useLocation } from "react-router-dom";
import bsasImg from "../../assets/img/bsas.png";
import rhImg from "../../assets/img/rh.png";
import footerMobile from "../../assets/img/footer_mobile.svg";
import "./styles.scss";

const Footer = (props) => {
  const location = useLocation();

  if (
    location.pathname === "/login" ||
    location.pathname === "/select-profile" ||
    location.pathname === "/registration" || 
    location.pathname === "/mia-ios"
  ) {
    return null;
  }

  return (
    <img className="hidden sm:block" src={footerMobile} alt="footerMobile" />
  );
};

export default Footer;
